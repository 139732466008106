import React from 'react';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CloseButton from '../../components/CloseButton'
import LoginForm from './form';
import { goHomeWithResetLoginRedirect, submitLogin } from '../../modules/user';
import Modal from '../../components/modal';

import {
  GridContainer,
  LoginHeading,
  SmallLink,
  TextP,
  ErrorText,
  CloseButtonContainer,
  StyledLogoHorizontal
} from './Styled'

const Login = ({submitLogin, errorMessage, goBackWithResetLoginRedirect}) =>{

  return (
    <Modal>
      <Helmet>
        <title>Log In - OfficialVisit</title>
        <meta name="description" content="Sign in to your account to continue exploring colleges! OfficialVisit is the leading college and college football review platform." />
      </Helmet>
      <CloseButtonContainer><CloseButton onClick={ goBackWithResetLoginRedirect }/></CloseButtonContainer>
      <GridContainer>
        <div>
          <StyledLogoHorizontal/>
          <LoginHeading as="h1">Welcome back  -  log in!</LoginHeading>
          { errorMessage && <ErrorText>{errorMessage}</ErrorText> }
          <LoginForm onSubmit={data => submitLogin(data)} />
          <TextP><SmallLink to="/forgot-password">Forgot password?</SmallLink></TextP>
          <TextP>Don’t have an account? <SmallLink to="/signup">Sign up</SmallLink>.</TextP>
        </div>
      </GridContainer>
    </Modal>
  )
};

Login.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  submitLogin: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired
};

const mapStateToProps = ({ user }) => ({
  isFetching: user.isFetching,
  errorMessage: user.errorMessage
});

const mapDispatchToProps = dispatch => bindActionCreators({
  submitLogin,
  goBackWithResetLoginRedirect: goHomeWithResetLoginRedirect
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login)
