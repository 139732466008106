
import React, { Fragment, useState } from 'react';
import Search from '../../../containers/search';
import { ItemForm, Add, Cancel } from './Styled';


const Form = ({ emptyState, onAddSchool }) => {
  const [editing, toggleEditing] = useState(false);
  return (
    <ItemForm editing={editing}>
      {!editing
        ? (
          <Add onClick={() => toggleEditing(!editing)} emptyState={emptyState}/>
        )
        : (
          <Fragment>
            <Search onSelect={college => onAddSchool(college) || toggleEditing(!editing)}/>
            <Cancel onClick={() => toggleEditing(!editing)}>Cancel</Cancel>
          </Fragment>
        )
      }
    </ItemForm>
  );
}

export default Form;