import React from 'react';
import styled from 'styled-components';
import { color, typo, bp } from '../../../styles';
import Select from "react-select";

const HEIGHT = "32px";
let StyledSelectContainer = styled(Select)`
  .react-select__control {
    width: 100%;
    height: ${HEIGHT};
    padding: 5px;

    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 0.5px #707070;
    background-color: #ffffff;

    font-size: 18px;
    font-weight: 600;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #95989a;

    min-height: unset;
  }

  .react-select__control--is-disabled{
    border: solid 1px ${color.ovGrey};
    color: ${color.ovGrey};
    .react-select__indicator {
      color: ${color.ovGrey};
    }
  }

  .react-select__value-container {
    display: block;
    width: 100%;
    background-color: ${color.ovWhite};
    padding: 0;
    height: 100%;
    position: static;
  }

  .react-select__single-value {
    color: #777777;
    margin-left: 0;
    margin-right: 0;
    max-width: calc(100% - 50px);
  }

  .react-select {
    &__indicator {
      color: #777777;

      &-separator{
        display: none;
      }
    }

    &__dropdown-indicator {
      padding: 0;
    }
  }


  .react-select__menu {
    display: inline-block;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 0.5px #707070;

    width: 100%;
    top: ${HEIGHT};
    left: 0;
    background-color: ${color.ovWhite};

    @media ${bp.phoneOnly} {
      max-height: 150px;
    }
  }

  .react-select__menu-list{
    @media ${bp.phoneOnly} {
      max-height: 150px;
    }
  }

  .react-select__option {
    font-family: ${typo.ovFontFeature};
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: condensed;

    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;

    @media ${bp.phoneOnly} {
      max-height: 150px;
    }
  }
`;

const StyledSelect = ({ 
    children,
    name,
    value,
    options,
    onChange,
    placeholder,
    label,
    defaultValue,
    isDisabled,
    input,
    ...props
  }) =>{

  return (
    <StyledSelectContainer 
      value={value}
      options={options}
      onChange={(value) => onChange(name, value)}
      className="react-select-container"
      classNamePrefix="react-select"
      placeholder={placeholder}
      label={label}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      {...input}
      {...props}
    >
      {children}
    </StyledSelectContainer>
  )
} 

export default StyledSelect