import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import styled from 'styled-components';
import { OVButton } from '../../components';
import { Field, reduxForm } from 'redux-form';
import { Col } from 'react-bootstrap';
import { color, typo } from '../../styles'

let StyledInput = styled.input`
  display: inline-block;
  width: 100%;
  height: 35px;
  border: solid 3px ${color.ovBlue};
  padding-left: 10px;
  background-color: ${color.ovWhite};

  font-family: ${typo.ovFontFeature};
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlue};

  &::placeholder{
    font-family: ${typo.ovFontFeature};
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: ${color.ovGrey};
  }
`;

const inputField = ({ input, label, type, meta: { touched, active, error } }) => (
  <div >
    <StyledInput {...input} placeholder={label} type={type} />
    {touched && !active && error && <div>{error}</div>}
  </div>
);

let CollegeDetailsEditForm = props => {
  const { handleSubmit, onCancel } = props
  return <form id='college-details-edit-form' onSubmit={handleSubmit}>
    <Col sm={12}>
      <div>
        <div>
          <label htmlFor="name">Name: </label>
          <Field name="name" component={inputField} type="text" />
        </div>
        <div>
          <label htmlFor="nickname">Nickame: </label>
          <Field name="nickname" component={inputField} type="text" />
        </div>
        <div>
          <label htmlFor="formalName">Formal Name: </label>
          <Field name="formalName" component={inputField} type="text" />
        </div>
        <div>
          <label htmlFor="city">City: </label>
          <Field name="city" component={inputField} type="text" />
        </div>
        <div>
          <label htmlFor="state">State: </label>
          <Field name="state" component={inputField} type="text" />
        </div>
        <div>
          <label htmlFor="schoolDivision">School Division: </label>
          <Field name="schoolDivision" component={inputField} type="text" />
        </div>
        <div>
          <label htmlFor="teamDivision">Team Division: </label>
          <Field name="teamDivision" component={inputField} type="text" />
        </div>
        <div>
          <label htmlFor="conference">Conference: </label>
          <Field name="conference" component={inputField} type="text" />
        </div>
      </div>
      <OVButton onClick={handleSubmit}>Submit</OVButton>
      <OVButton onClick={onCancel}>Cancel</OVButton>
    </Col>
  </form>
}

CollegeDetailsEditForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};
CollegeDetailsEditForm = reduxForm({
  // a unique name for the form
  form: 'collegeDetailsEdit'
})(CollegeDetailsEditForm)

CollegeDetailsEditForm = connect(
  ({ college }) => ({
    initialValues: {
      name: college.college.name,
      nickname: college.college.nickname,
      formalName: college.college.formal_name,
      city: college.college.address.city,
      state: college.college.address.state,
      schoolDivision: college.college.division,
      teamDivision: college.college.teams[0].division,
      conference: college.college.conference
    } // pull initial values from account reducer
  }),
  { }
)(CollegeDetailsEditForm)

export default CollegeDetailsEditForm