import { apiEndpoint } from '../config/app';
import createRestApiClient from '../utils/createRestApiClient';

export default () => {
  // Adds token to client if it is passed in
  const token = localStorage.getItem('ovSessToken');
  const config = token ? {
    baseURL: apiEndpoint,
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  } : { baseURL: apiEndpoint };

  const client = createRestApiClient().withConfig(config);
  return {
    getColleges: () => client.request({
      method: 'GET',
      url: '/api/college'
    }),

    getCollege: (id) => client.request({
      method: 'GET',
      url: '/api/college/' + id
    }),

    getContent: (id) => client.request({
      method: 'GET',
      url: '/api/college/' + id + '/content'
    }),

    getApiReviews: (id) => client.request({
      method: 'GET',
      url: '/api/reviews/college/' + id
    }),

    getAuthReviews: (id) => client.request({
      method: 'GET',
      url: '/a/reviews/college/' + id
    }),

    getAverageCollege: (id) => client.request({
      method: 'GET',
      url: '/api/reviews/college/' + id + '/average'
    }),

    getAverageCollegeReviews: () => client.request({
      method: 'GET',
      url: '/api/reviews/average/college'
    }),

    getMajors: (id) => client.request({
      method: 'GET',
      url: '/api/majors/college/' + id
    }),

    getSports: () => client.request({
      method: 'GET',
      url: '/api/sports'
    }),

    getAttraction: id => client.request({
      method: 'GET',
      url: `/api/attraction/${id}`,
    }),

    getAttractions: (id, radius=10000) => client.request({
      method: 'GET',
      url: `/api/college/${id}/attractions?radius=${radius}`,
    }),

    getAttractionTags: () => client.request({
      method: 'GET',
      url: `/api/attactiontags`,
    }),

    // 
    // for admins only
    editCollege: (id, data) => client.request({
      method: 'PUT',
      url: '/aa/college/' + id,
      data
    }),

  };
};
