import styled from 'styled-components';
import { color, bp, typo } from '../../../styles';
import { User } from 'styled-icons/fa-solid/User';

export const CoachCardWrapper = styled.div`
  padding: 16px;
  margin-bottom: 10px;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.5px ${color.ovGreyDarker};
  background-color: ${color.ovWhite};

  @media ${bp.tabletPortUp}{
    :hover {
      transition: all .1s;
      transform: scale(1.04);
    }
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;

  min-width: 73px;
  width: 73px;
  height: 73px;

  border-radius: 50%;
  overflow: hidden;
  border: solid 1px ${color.ovGreyDarker};

  @media ${bp.tabletPortUp}{
    min-width: 98px;
    width: 98px;
    height: 98px;
  }

  > img {
    vertical-align: unset;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const CoachCardContainer = styled.div`
  display: flex;
`;

export const UserIcon = styled(User)`
  width: 64px;
  path{ 
    fill: ${color.ovBlue};;
  }
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-left: 26px;

  h3 {
    margin: 0;
    font-size: 35px;
    font-weight: 600;
    font-stretch: condensed;
    line-height: 48px;
    color: #000000;
    cursor: pointer;
  }

  h4 {
    margin: 0;
    line-height: 21px;
    font-size: 17px;
    font-weight: 600;
    font-stretch: condensed;
    color: ${color.ovGrey};
  }

  span {
    font-size: 13px;
    font-weight: 600;
    font-stretch: condensed;
    text-align: left;
    color: ${color.ovGrey};
  }

   @media ${bp.tabletPortUp}{
      h3 {
        font-size: 40px;
      }

      h4 {
        font-size: 15px;
      }
   }
`;

export const ReviewsWrapper = styled.div`
  margin-top: 5px;

`;

export const CoachHistoryContainer = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.14;
  color: #707070;

  > div {

  }
`;
