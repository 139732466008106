import { color, bp, typo } from '../../styles'
import styled from 'styled-components';

export const FiltersBox = styled.div`
  grid-column: span 12;
`;

export const FiltersColumns = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 8px 10px 0px 25px;
  @media ${bp.phoneOnly} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
  }
  > div{
    @media ${bp.phoneOnly} {
      padding-bottom: 7px;
    }
  }
`;

export const FilterType = styled.div`
  font-family: ${typo.ovFontFeature};
  font-size: 17px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlack};
  margin: 0 0 5px 0;
  @media ${bp.phoneOnly} {
    margin: 0 0 7px 0;
  }
  span{
    float: right;
    font-size: 12px;
    cursor: pointer;
    display: none;
    @media ${bp.phoneOnly} {
      display: inline;
    }
  }
`;

export const FilterTypeDropdown = styled(FilterType)`
  cursor: pointer;
  width: 100%;
  span{
    float: right;
    font-size: 8px;
    margin-top: 5px;
  }
  &:hover{
    opacity: .5;
  }
`;

export const FiltersTotal = styled.div`
  color: ${color.ovBlack};
  font-size: 12px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: right;
  font-family: ${typo.ovFontFeature};
  padding-right: 10px;
`;

export const CheckboxContainer = styled.input`
  margin: 0 .7rem 0 0 !important;
  border: solid 1px black;
  border-radius: none;
  @media ${bp.phoneOnly} {
    margin: 0 !important;
  }
`;

export const Filter = styled.div`
  display: grid;
  grid-template-columns: 20px auto;
  @media ${bp.phoneOnly} {
    grid-template-columns: auto 20px;
    div{
      grid-column-start: 2;
      text-align: right;
    }
  }
`;

export const FilterLabel = styled.span`
  font-size: 12px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  font-family: ${typo.ovFontFeature};
  color: ${color.ovBlack};
  display: inline-block;
  margin-top: 4px;
  @media ${bp.phoneOnly} {
    grid-row-start: 1;
    padding-left: 20px;
  }
`;

export const Filters = styled.div`
  max-height: none;
  overflow: visible;
  @media ${bp.phoneOnly} {
    max-height: 21px;
    overflow: hidden;
    transition: height 1s linear;
    &[data-show=true]{
      max-height: none;
      overflow: visible;
      transition: height 1s linear;
    }
  }
`;