import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';
import { SmallReviewItem } from '../../components';
import { UserCircle } from 'styled-icons/fa-solid/UserCircle';

import {
  ProfileIcon,
  ReviewBox,
  ReviewerPane,
  ReviewerName,
  ImageContainer,
  ReviewedAt,
  ExpandContainerCollege,
  ReviewHeading,
  OVRatingDisplay,
  ButtonContainer,
  OVButton,
  OVRatingNumber,
  BriefCollege,
  StyledCheck,
  SizedOne,
  SizedZero,
  Title,
  ReviewTypeLabel,
  ReviewDeleteButton
} from './StyledItem';

import ReviewerInfo from './ReviewerInfo';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { deleteAdminReview } from "../../modules/review";

const getRatingComponent = (rating, id) => {
  const wholeValue = parseInt(rating + "");
  let displayArr = [];
  for (var n = 0; n < wholeValue; n++) {
    displayArr[n] = <SizedOne key={id + "-rating1-" + n }/>
  }
  for (var m = 0; m < 5 - wholeValue; m++) {
    displayArr.push(<SizedZero key={id + "rating0"+ (wholeValue-m)}/>)
  }
  return displayArr.map(item => item);
}

const filterReviewsList = (reviews, filterWord) => {
  return reviews.filter(review => {
    return review.rating_key.includes(filterWord);
  })
};

const sortCollegeRatings = (reviews) => {
  const fiteredReview = filterReviewsList(reviews,'_college');
  return [
    fiteredReview.find(review => review.rating_key === "overall_college"),
    fiteredReview.find(review => review.rating_key === 'value_college_college'),
    fiteredReview.find(review => review.rating_key === 'value_major_college'),
    fiteredReview.find(review => review.rating_key === 'difficulty_major_college'),
    fiteredReview.find(review => review.rating_key === 'professors_college'),
    fiteredReview.find(review => review.rating_key === 'campus_college'),
    fiteredReview.find(review => review.rating_key === 'surrounding_area_college'),
    fiteredReview.find(review => review.rating_key === 'nightlife_college'),
    fiteredReview.find(review => review.rating_key === 'food_college'),
    fiteredReview.find(review => review.rating_key === 'dating_college'),
    fiteredReview.find(review => review.rating_key === 'social_college'),
    fiteredReview.find(review => review.rating_key === 'diversity_college'),
  ].filter(review => review);
}

const sortAthleticsRatings = (reviews) => {
  const fiteredReview = filterReviewsList(reviews,'_athletics');
  return [
    fiteredReview.find(review => review.rating_key === 'gameday_athletics'),
    fiteredReview.find(review => review.rating_key === 'academic_support_athletics'),
    fiteredReview.find(review => review.rating_key === 'fans_athletics'),
    fiteredReview.find(review => review.rating_key === 'facilities_athletics'),
    fiteredReview.find(review => review.rating_key === 'team_culture_athletics'),
  ].filter(review => review);
}

const getCollegeRatings = (reviews) => {
  return sortCollegeRatings(reviews).map((review,i) => {
    let label = review.rating_key.replace("_college", "").replace("_", " ");
    label = label.charAt(0).toUpperCase() + label.slice(1);

    // Special Case String Replace
    label = label.replace("college", "(college)");
    label = label.replace("major", "(major)");

    return <SmallReviewItem key={review+i} label={label} review={review}/>
  })
}

const getAthleticsRatings = (reviews) => {
  return sortAthleticsRatings(reviews).map((review,i) => {
    let label = review.rating_key.replace("_athletics", "").replace("_", " ");
    label = label.charAt(0).toUpperCase() + label.slice(1);

    return <SmallReviewItem key={review+i} label={label} review={review}/>
  })
}

////////////////////////////////////
/////////////////////////////////// 

class ReviewsItem extends Component{
  constructor() {
    super();
    this.state = {
      showMoreReviews: false
    }
  }

  handleShowMoreClick() {
    this.setState({
      showMoreReviews: !this.state.showMoreReviews
    });
  }

  render(){
    const {
      id,
      user,
      reviews,
      title,
      type,
      is_anonymous,
      currentUserRole,
      createdAt,
      onUserClick,
      deleteAdminReview
    } = this.props;

    const previewReview = reviews.find(review => review.rating_key === "overall_college" 
      || review.rating_key === "overall_attraction"
      || review.rating_key === (sortCollegeRatings(reviews)[0] || {}).rating_key
    ); // || reviews.find(review => review.rating_key === 'gameday_athletics') || sortCollegeRatings(reviews)[0] || sortAthleticsRatings(reviews)[0];

    return (
      <ReviewBox>
        <ReviewerPane onClick={() => !is_anonymous && onUserClick(user.ID)}>
          <ReviewerName is_anonymous={is_anonymous}>
            { !is_anonymous ?
            <span>{user.first_name} {user.last_name} {user.is_verified && <StyledCheck/>}</span> :
            <span>Anonymous Reviewer {user.is_verified && <StyledCheck/>}</span>
            }
          </ReviewerName>
          <ImageContainer is_anonymous={is_anonymous}>
            { user.avatar == null && 
              <ProfileIcon>
                <UserCircle />
              </ProfileIcon>
            }
            { user.avatar && user.avatar.src_th && 
              <ProfileIcon
                style={{
                  backgroundImage: `url(${user.avatar.src_th})`,
                }}
              >
              </ProfileIcon> 
            }
          </ImageContainer>

          <ReviewerInfo user={user} isCollegeReview/>
        </ReviewerPane>
        <OVRatingDisplay>
          {previewReview &&
            <> 
              <OVRatingNumber>{previewReview.rating}.0</OVRatingNumber>
              {getRatingComponent(previewReview.rating, previewReview.ID)}
            </>
          }
        </OVRatingDisplay>
        <ReviewedAt>Reviewed <TimeAgo live={false} date={createdAt}/></ReviewedAt>
        <Title>{title}</Title>
        {previewReview &&
          <BriefCollege>{previewReview.comment && `"${previewReview.comment}"`}</BriefCollege>
        }
        {type !== 'attraction_review' &&
          <ExpandContainerCollege>
            { this.state.showMoreReviews && (<div>
                <ReviewHeading>Reviews</ReviewHeading>
                <ReviewTypeLabel>General</ReviewTypeLabel>
                { getCollegeRatings(reviews) }
                <ReviewTypeLabel>Athletics</ReviewTypeLabel>
                { getAthleticsRatings(reviews) }
              </div>
            ) }
            <ButtonContainer>
              <OVButton onClick={ () => this.handleShowMoreClick() }>
                { this.state.showMoreReviews ? "Read Less" : "Read More" }
              </OVButton>
            </ButtonContainer>
          </ExpandContainerCollege>
        }
        {currentUserRole === 'admin' && <ReviewDeleteButton onClick={() => deleteAdminReview(id)}/>}
      </ReviewBox>
    )
  }
}

ReviewsItem.propTypes = {
  id: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  reviews: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  onUserClick: PropTypes.func.isRequired,
  currentUserRole: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  deleteAdminReview
}, dispatch);

export default connect(null, mapDispatchToProps)(ReviewsItem)