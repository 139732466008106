import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FloatRightCloseButton } from "../index";
import Modal from 'react-modal';
import { isDesktop, isMobile, isTabletLand, isWide } from "../../config/app";

// Styles
import { updateUploadPhotoAgreement } from "../../modules/user";
import {
	AgreeButton,
	Logo,
	ModalDiv, OVButton,
	PopupPageContainer,
	SubHeading,
	Text,
	UploadPhotosButtonContainer
} from "./Styled";

let modalStyles = {};

modalStyles.overlay = {
	position: 'fixed',
	top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
	zIndex: 999,
};

modalStyles.content = {
	position: 'relative',
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
  margin: 'auto',
	padding: 0,
};

if (isMobile) {
	modalStyles.content.overflowX = 'none';
} else {
	modalStyles.content.width = '579px';
}

if (isTabletLand || isDesktop || isWide) {
	modalStyles.content.width = '679px'
}

Modal.setAppElement('#root');

const UploadPhotosButtonWithAgreement = ({
	title,
	onAgree,
	hasUploadPhotoAgreement,
	updateUploadPhotoAgreement,
}) =>{
	const [ modalState, setModalState ] = useState(false);

  return (
		<UploadPhotosButtonContainer>
			<OVButton onClick={() =>
				hasUploadPhotoAgreement ?
					onAgree() :
					setModalState(true)
			}>{title}</OVButton>
			<Modal
				isOpen={modalState}
				style={modalStyles}
				onRequestClose={() => setModalState(false)}
				contentLabel="Upload Modal"
				shouldCloseOnOverlayClick={true}
				shouldCloseOnEsc={true}
			>
						<ModalDiv>
							<PopupPageContainer>
								<FloatRightCloseButton onClick={() => setModalState(false)}/>
								<Logo/>
								<SubHeading>OfficialVisit Photo Upload Policy</SubHeading>
								<Text>By clicking ‘I agree’ you agree to upload only photos
									owned by you or photos that have been granted explicit
									permission for use by the photo(s) owner to you.

									In addition, you agree to upload only photos that are
									suitable for all ages, including children, to see.
									Provocative or explicit photos are strictly prohibited.

									Photos that violate this policy will be removed.  Users
									who violate this policy may be suspended, banned,
									and/or prosecuted.</Text>

								<AgreeButton onClick={() => { setModalState(false); updateUploadPhotoAgreement(); onAgree(); }}>I agree</AgreeButton>

							</PopupPageContainer>
						</ModalDiv>
			</Modal>
		</UploadPhotosButtonContainer>
	)
};

const mapStateToProps = ({ user }) => ({
	hasUploadPhotoAgreement: user.hasUploadPhotoAgreement,
});

const mapDispatchToProps = dispatch => bindActionCreators({
	updateUploadPhotoAgreement
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UploadPhotosButtonWithAgreement)
