import React from 'react';
import PropTypes from 'prop-types';
// Rediux stuff
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { listCollegesIfEmpty } from "../../modules/college";
import { listMajors } from "../../modules/wizard";
import { connect } from "react-redux";
import { withLifecycleActions } from "react-redux-lifecycle";
import { bindActionCreators } from "redux";
// Components
import StyledSelect from '../../components/styledSelect';

import {
  ErrorMessage,
  TitleHeading,
  NextButton
} from './Styled';

// Select Field
const selectField = ({ input, options, clearable, meta: { touched, active, error } , label}) => (
  <div>
    <StyledSelect
      instanceId={input.name}
      {...input}
      options={options}
      clearable={clearable}
      onBlur={() => input.onBlur}
      placeholder={label}
    />
    {touched && !active && error && <ErrorMessage>{error}</ErrorMessage>}
  </div>
);
// end of Select Field
// 
// Validation
const required = value => value ? undefined : 'Required';

let Step1 = props => {

  const {
    handleSubmit, 
    colleges,
    currentStep,
    collegeValue,
    listMajors
  } = props;
  
  // Will hide the component if the current step is not equal to 1
  if (currentStep !== 1) { return null }

  const collegeOpts = colleges.map(e => ({ label: e.name, value: e.ID }));

  const handleClick = () => {
    listMajors(collegeValue.value);
  }

  return (
    <form onSubmit={handleSubmit}>
      <TitleHeading>Which college did you attend?</TitleHeading>
      <Field 
        name="college" 
        label="College" 
        component={selectField} 
        type="text" 
        options={collegeOpts}
        validate={[required]}
      />
      <div>
        <NextButton type="submit" onClick={handleClick}>Next</NextButton>
      </div>
    </form>
  )
}

Step1.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

const mapStateToProps = ({ college }) => ({
  colleges: college.colleges
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {listCollegesIfEmpty, listMajors},
    dispatch
  );

Step1 = reduxForm({
  // a unique name for the form
  form: 'StudentForm',
  destroyOnUnmount: false
})(Step1)

// Gets the college input value from Step 1 and the major input value from Step 3
const selector = formValueSelector('StudentForm');
Step1 = connect(state => {
  const collegeValue = selector(state, 'college');
  return {
    collegeValue
  };
})(Step1);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withLifecycleActions({
    componentWillMount: [listCollegesIfEmpty]
  })(Step1)
);