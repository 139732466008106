import styled from 'styled-components';
import { color, typo } from '../../../../styles'

export const FilterList = styled.ul`
  height: 30px;
  border-bottom: solid 0.5px #707070;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  padding: 0;
`;

export const Filter = styled.li`
  list-style: none;
  a{
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: ${color.ovBlack};
    font-family: ${typo.ovFontFeature};
    display: inline;
    padding: 0 2rem;
    list-style: none;
    cursor: pointer;
    &:hover{
      color: ${color.ovGrey};
      text-decoration: none;
    }
  }
`;