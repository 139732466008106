// eslint-disable-next-line
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  cleanDropState,
  handleDropPicture,
  handleDragEnter,
  handleDragLeave,
  removeDroppedFile
} from '../../modules/upload';

import CoachPictureContainer from './CoachPictureContainer';
import { submitCoachPhoto } from "../../modules/coach";

const pictureSelector = (state) => state.accepted && state.accepted[0] && state.accepted[0].preview;

const mapStateToProps = ({ upload }) => ({
  picture: pictureSelector(upload),
  dropzoneActive: upload.dropzoneActive,
  isUploading: upload.isUploading
});

const mapDispatchToProps = dispatch => bindActionCreators({
  cleanDropState,
  handleDropPicture,
  handleDragEnter,
  handleDragLeave,
  removeDroppedFile,
  submitCoachPhoto,
  push,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CoachPictureContainer)
