import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import CollegeHeaderLite from '../../components/CollegeHeader/CollegeHeaderLite';
import AttractionCard from '../../components/Attractions/AttractionsCard';
import AttractionGallery from '../../components/Attractions/AttractionsGallery';
import { FullWidthPageContainer, ReviewsList } from '../../components';
import CollegeCard from '../../components/College/CollegeCard';
import LeaveReviewBg from '../../assets/backgrounds/review_illustration.svg';

import {
  LeaveReviewContainer,
  BackToAttractions,
  LeaveReviewHeading,
  LeaveReviewButton,
  EighthPartPageContent,
  QuarterPartPageContent,
  ReviewsPartPageContent,
  SpinnerWrapper,
} from './Styled'

const AttractionDetails = ({
  isFetching,
  isFetchingReviews,
  isFetchingCollege,
  isSubmittingReview,
  requestLogin,

  match,
  college,
  colleges,
  averageReview,

  attraction,
  contents,
  reviews,

  getAttractionReviews,
  getAttractionContents,

  push,
  
  isAuthenticated,
  role,
}) => {
  useEffect(() => {
    if (!isFetchingReviews && colleges.length) {
      getAttractionReviews();
      getAttractionContents();
    }
  }, [colleges]);
	return(
    <div>
  		<CollegeHeaderLite
        isFetching={isFetching}

        college={college}
        averageReview={averageReview}

        role={role}
        push={push}
      />
      <BackToAttractions onClick={()=> push(`/college/${college.url_slug}/attractions`)}>
        <span>More {college && college.name} attractions</span>
      </BackToAttractions>
      {isFetchingCollege && <SpinnerWrapper bottom={false}><Spinner fadeIn="none" name="cube-grid" style={{width: "12px", height: "12px"}}/></SpinnerWrapper>}
      <AttractionGallery pictures={contents} match={match} onClick={() => { push(`${match.url}/content`); }}/>

      {isFetchingCollege && <SpinnerWrapper bottom={true}><Spinner fadeIn="none" name="cube-grid" style={{width: "17px", height: "17px"}}/></SpinnerWrapper>}
      <FullWidthPageContainer>
        <EighthPartPageContent>
          <AttractionCard
            attraction={ attraction }
            role={role}
          />
          {college && college.ID && reviews && !isFetchingCollege && (
            <CollegeCard collegeCardTitle="reviews">
              <ReviewsList
                reviews={reviews}
                count={attraction && attraction.average_review.reviews_count}
                onUserClick={id => console.log('push to ', `/user/${id}`) || push(`/user/${id}`)}
                name="attraction_reviews"
                type="attraction_review"
                paginateOnPage={3}
                isAuthenticated={isAuthenticated}
                currentUserRole={role || ''}
                isFetchingReviews={isFetchingReviews}
                match={match}
              />
            </CollegeCard>
          )}
        </EighthPartPageContent>
        <QuarterPartPageContent>
          {isFetchingCollege && <Spinner fadeIn="none" name="cube-grid" />}
          {college && college.ID && (
            <ReviewsPartPageContent>
              <LeaveReviewContainer bgUrl={LeaveReviewBg}>

                <LeaveReviewHeading>Been here before? Leave a review to help others learn more.</LeaveReviewHeading>
                  
                {!isSubmittingReview && (<LeaveReviewButton onClick={() => {
                  isAuthenticated ?
                  push(match.url + '/review') :
                  requestLogin(match.url + '/review', match.url)
                }}>
                  Leave Review
                </LeaveReviewButton>)}

                {isSubmittingReview && (<LeaveReviewButton>
                  Review is submitting...
                </LeaveReviewButton>)}

              </LeaveReviewContainer>
            </ReviewsPartPageContent>
          )}
        </QuarterPartPageContent>
      </FullWidthPageContainer>
    </div>
	)
};

AttractionDetails.propTypes = {
  college: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  averageReview: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

export default AttractionDetails;