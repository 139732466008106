import React, {useState} from 'react';
import InputRange from 'react-input-range';

import {
  OptionRangeBox,
  RangeSliderBox
} from './Styled';

const OptionRange = ({
  label,
  subfixUnit,
  prefixUnit,
  maxValue,
  minValue,
  filter,
  filterPath,
  formatLabel,
  parentCallback
}) => {
  const [range, setRange] = useState({min: minValue, max: maxValue, count: 0});
  const val = filter.range(filterPath);
  if (val === undefined && range.count > 1 && (range.min !== minValue || range.max !== maxValue)) {
    setRange({min: minValue, max: maxValue, count: 0});
  }
  // hack to prevent freeze on first click that is caused by setFilters debounce
  if (range.count === 1) range.count = 2;
  const [dirty, setDirty] = useState();
  return(
    <OptionRangeBox>
      {label &&
        <span>{label}</span>
      }
      <RangeSliderBox subfixUnit={subfixUnit} prefixUnit={prefixUnit}>
        <InputRange 
          maxValue={maxValue}
          minValue={minValue}
          formatLabel={formatLabel}
          value={range}
          onChange={(val) => {
            if (val.max > maxValue) val.max = maxValue;
            if (val.min < minValue) val.min = minValue;

            if (range.count < 5) range.count += 1;

            setRange({min: val.min, max: val.max, count: range.count});
            filter.onChangeRange(filterPath)(val);

            setDirty(val.min + val.max);
            parentCallback(dirty === (minValue + maxValue) ? false : true); 
          }}
        />
      </RangeSliderBox>
    </OptionRangeBox>
  )
};

export default OptionRange;
