import React from 'react';
import AverageReview from '../../reviews/AverageReview';
import default_bkg from '../../../assets/img/profile-bg.jpg';

import {
  CollegeCoachCardBox,
  CollegeCoachCardBoxPicture,
  CollegeCoachCardName,
  CollegeCoachCardTitle,
  CollegeCoachCardReviews,
  CollegeCoachCardSpan,
  CollegeCoachCardData
} from './Styled'

const CollegeCoachCard = ({
  photo,
  name,
  position,
  averageRating,
  handleClick,
  count
}) => {
  return(
    <CollegeCoachCardBox onClick={handleClick}>
      <CollegeCoachCardBoxPicture
        bgURL={photo ? photo : default_bkg}
      />
      <CollegeCoachCardData>
        <CollegeCoachCardName>{name}</CollegeCoachCardName>
        <CollegeCoachCardTitle>{position}</CollegeCoachCardTitle>
        <CollegeCoachCardReviews>
          <CollegeCoachCardSpan>Average player rating: </CollegeCoachCardSpan>
          <AverageReview
            averageReview={averageRating}
            count={count}
          />
        </CollegeCoachCardReviews>
      </CollegeCoachCardData>
    </CollegeCoachCardBox>
  )
}

export default CollegeCoachCard;