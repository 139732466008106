import styled from 'styled-components';
import { Search as SearchIcon } from 'styled-icons/material/Search'
import { Heading, Flex, Box } from 'rebass';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { color, typo, bp } from '../../styles'

export const Result = styled(Box)`
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
  &:last-child{
    margin-bottom: 0px;
  }
  &:hover {
    transition: .1s;
    padding-left: 2px
  };
`;

export const SearchContainer = styled.div`
  position: relative;
`;

export const ScrollbarContainer = styled(PerfectScrollbar)`
  max-height: 220px;
  overflow-y: hidden;
  .ps__rail-y{
    opacity: 1;
    .ps__thumb-y{
      background-color: ${color.ovYellow}
    }
  }
`;

export const ResultHeading = styled(Heading)`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontSerif};
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  padding: 10px 0;
`;

export const ResultTitle = styled(Heading)`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
  font-size: 25px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.04;
  letter-spacing: normal;
  text-align: left;
  padding: 10px 0 20px 0;
`;

export const ResultsContainer = styled(Flex)`
  display: inline-block;
  border: solid 2px ${color.ovBlue};
  padding: 10px 10px 10px 31px;
  margin-top: -7px;
  background-color: ${color.ovWhite};
  border-radius: 0 0 10px 10px;
  position: absolute;
  max-height: 250px;
  width: 70%;
  overflow-y: hidden;
  @media ${bp.tabletPortUp} {
    width: 80%;
  }
  @media ${bp.tabletLandUp} {
    width: 86%;
  }
  @media ${bp.desktopUp} {
    width: 90%;
  }
`;


export const InputContainer = styled.div`
  margin-bottom: -22px;
  float: left;
  width: 70%;
  @media ${bp.tabletPortUp} {
    width: 80%;
  }
  @media ${bp.tabletLandUp} {
    width: 86%;
  }
  @media ${bp.desktopUp} {
    width: 90%;
  }
`;

export const Button = styled.span`
  float: right;
`;

export const StyledSearchIcon = styled(SearchIcon)`
  position: relative;
  top: 0px;
  left: 2px;
  height: 32px;
  width: 32px;
  color: ${color.ovWhite};
`;

export const StyledInput = styled.input`
  display: inline-block;
  width: 100%;
  height: 76px;
  border: solid 2px ${color.ovGrey};
  padding: 22px 31px;
  background-color: ${color.ovWhite};
  font-family: ${typo.ovFontFeature};
  font-size: 25px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovGrey};
  border-radius: 10px;
  outline: none;
  &::placeholder{
    font-family: ${typo.ovFontFeature};
    font-size: 25px;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: ${color.ovGrey};
  }
  &:active, &:focus, &.filled{
    border-radius: 10px;
    border: solid 2px ${color.ovBlue};
  }
  &.filled{
    border-radius: 10px 10px 0 0;
  }
`;