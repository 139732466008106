import { color, bp } from '../../../styles'
import styled from 'styled-components';
import Modal from 'react-modal';

export const FiltersModal = styled(Modal)`
  @media ${bp.tabletLandUp}{
    width: 50% !important;
    top: 65px;
    height: calc(100vh - 65px);
  }
  &:focus{
    outline: none;
  }
`;

export const Overlay = {
  overlay: {
    'width': '100%',
    'zIndex' : '999',
    'backgroundColor': 'rgba(230,230,230,.8)'
  },
  content : {
    'top' : '0px',
    'left' : '0',
    'right' : 'auto',
    'bottom' : 'auto',
    'height': '100vh',
    'backgroundColor': 'white',
    'position': 'fixed',
    'width': '100%',
    'zIndex' : '999',
    'padding': '0',
    'border': 'none',
    'borderRadius': '0'
  }
};

export const LocationFilter = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: ${color.ovWhite};
  padding: 2.5rem 1.5rem 2rem 1.5rem;
  font-family: Molde;

  @media (max-width: 699px) { // looks good for 3 columns
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 80px;
  }
`;

export const LocationFilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  color: ${color.ovGreyDarker};
  height: 45px;
  font-family: Molde;

  h4 {
    margin: 0px;
    margin-bottom: 10px;
    font-size: 2.2rem;
    cursor: pointer;
  }

  @media ${bp.phoneOnly} {
    height: 70px;
    background-color: ${color.ovWhite};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 10px;
  }

  .btn-close {
    display: inline;
    color: #005587;
    width: 45px;
    cursor: pointer;
    z-index: 1;
    opacity: 1;
    padding: 10px;
    margin-bottom: 10px;
    -webkit-transition: all .1s;
    transition: all .1s;
  }
`;

export const ScrollableSections = styled.div`
  overflow-y: auto;
  flex-grow: 2;
  @media ${bp.phoneOnly} {
    padding-top: 60px;
  }
`;

export const CategorySection = styled.div`
  margin: 3rem 2rem 0 2rem;
  border-bottom: solid 1px ${color.ovGrey};

  &:last-child {
    border-bottom: none;
  }
  &:first-child {
    margin-top: 1.8rem;
  }

  @media ${bp.phoneOnly} {
    margin: 1rem 20px 1rem 0;
  }
`;

export const StyledButton = styled.button`
  padding: 0 .9rem;
  text-align: center;
  line-height: 22px;
  border-radius: 0.7rem;
  font-size: 1.8rem;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
  color: ${props => props.selected === "true" ? color.ovWhite : color.ovGreyDarker};
  background-color: ${props => props.selected === "true" ? color.ovBlue : color.ovWhite};
`;

export const CategorySectionHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1.8rem;

  h2 {
    margin: 0 2.4rem 0 0;
    color: ${color.ovBlueLight};
    @media ${bp.phoneOnly} {
      margin: 0 1rem 0 0;
    }
  }
  

`;

export const CategoryList = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  color: ${color.ovGreyDarker};

  padding-bottom: 4rem;

  label {
    font-weight: 500;
    font-size: 1.8rem;
  }
`;

export const CategoryItem3 = styled.div`
  width: 33%;
  line-height: 26px;
  label {
    input {
      margin-right: 0.5rem;
    }
  }
`;

export const CategoryItem2 = styled.div`
  width: 50%;
  line-height: 26px;
  label {
    input {
      margin-right: 0.5rem;
    }
  }
`;

export const CategoryFilterFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  .btn-cancel {
    border: none;
    margin-right: 1.7rem;
    font-size: 2.6rem;
    color: ${color.ovGreyDarker};
    @media ${bp.phoneOnly} {
      border: 1px solid ${color.ovBlue};
      width: 50%;
      text-align: center;
      border-radius: .6rem;
    }
  }

  .btn-apply {
    border-radius: .6rem;
    background-color: ${color.ovBlue};
    color: ${color.ovWhite};
    font-size: 2.6rem;
    padding: .2rem 2.8rem;
    @media ${bp.phoneOnly} {
      width: 50%;
      text-align: center;
    }
  }
  
  @media ${bp.phoneOnly} {
    padding: 10px; 
    position: absolute;
    top: 41px;
    left: 0;
    width: 100%;
    background-color: ${color.ovWhite};
  }
`;

