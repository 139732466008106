import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { submitRegister, submitLogin } from "../../modules/user";
import { push } from "connected-react-router";
import { ReactComponent as FanIcon } from '../../assets/icons/fan_icon.svg';
import { ReactComponent as StudentIcon } from '../../assets/icons/student_icon.svg';
import { ReactComponent as AthleteIcon } from '../../assets/icons/athlete_icon.svg';
import { ReactComponent as RecruitIcon } from '../../assets/icons/recruit_icon.svg';

import {
  TitleHeading,
  OVButton,
  IconContainerStudent,
  IconContainerFan,
  IconContainerRecruit,
  IconContainerAthlete,
  RoleLabel,
  NextButton
} from './Styled'

const RoleSelector = props => {
  const [role, setRole] = useState("");

  async function submit() {
    if (role) {
      await props.submitRegister({ ...props.registerData, role });
    }
  };

  return (
    <div>
      <TitleHeading> I am a ... </TitleHeading>
      <div>
        <OVButton
          selected={role === "athlete"}
          onClick={() => setRole("athlete")}
        >
          
          <IconContainerAthlete><AthleteIcon/></IconContainerAthlete>
          <RoleLabel>Current or former football student-athlete</RoleLabel>
        </OVButton>
      </div>
      <div>
        <OVButton
          selected={role === "student"}
          onClick={() => setRole("student")}
        >
          <IconContainerStudent><StudentIcon/></IconContainerStudent>
          <RoleLabel>Current or former college student</RoleLabel>
        </OVButton>
      </div>
      <div>
        <OVButton
          selected={role === "recruit"}
          onClick={() => setRole("recruit")}
        >
          
          <IconContainerRecruit><RecruitIcon/></IconContainerRecruit>
          <RoleLabel>Future college student or student-athlete</RoleLabel>
        </OVButton>
      </div>
      <div>
        <OVButton selected={role === "fan"} onClick={() => setRole("fan")}>
          <IconContainerFan><FanIcon/></IconContainerFan>
          <RoleLabel>Fan</RoleLabel>
        </OVButton>
      </div>
      <div style={{ marginTop: "3em" }}>
        <NextButton type="submit" onClick={submit}>
          Next
        </NextButton>
      </div>
    </div>
  );
};

RoleSelector.propTypes = {
  submitRegister: PropTypes.func.isRequired,
  registerData: PropTypes.object.isRequired
};

const mapStateToProps = ({ user, wizard }) => ({
  registerData: wizard.registration_data
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      submitRegister,
      submitLogin,
      nextStep: (e) => push(e)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleSelector);
