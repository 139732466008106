import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { listPosts, listCategories } from '../../../modules/blog/blog';
import { isFetchingSelector, postListSelector, categoriesSelector, categorySelector } from '../../../modules/blog/selectors';

import PostList from './PostList';

const mapStateToProps = (state) => ({
  isFetching: isFetchingSelector(state),
  posts: postListSelector(state),
  categories: categoriesSelector(state),
  category: categorySelector(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  listCategories,
  listPosts,
  push,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PostList)