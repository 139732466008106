import React from 'react';
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { openModal } from "../../../modules/contribute/actions";
import AttractionUploadModal from "../AttractionUploadModal";
import { openAttractionContentByID } from "../../../modules/attraction/attraction";

// Styles
import {
  AttractionGalleryBox,
  AttractionImagesBox,
  AttractionImage,
  AttractionImagesTotal,
  StyledCamera
} from './Styled';
import UploadPhotosButtonWithAgreement from "../../UploadPhotosButtonWithAgreement";
import { requestLogin } from "../../../modules/user";

const AttractionGallery = ({
  onClick,
  pictures,
  match,

  isAuthenticated,
  requestLogin,

  openModal,
  openAttractionContentByID,
}) => (
  <AttractionGalleryBox>
    <UploadPhotosButtonWithAgreement title='Upload Photos' onAgree={() =>
      isAuthenticated ?
        openModal() :
        requestLogin(match.url)} />
    <AttractionUploadModal/>

    <AttractionImagesBox>
      {pictures && pictures.slice(0,3).map(pic => (
        <AttractionImage bgUrl={pic.src_md} key={pic.ID} onClick={openAttractionContentByID(match.url, pic.ID)}/>
      ))}
    </AttractionImagesBox>

    <AttractionImagesTotal onClick={onClick}>
      <StyledCamera/> {`all photos (${(pictures && pictures.length) || 0})`}
    </AttractionImagesTotal>
  </AttractionGalleryBox>
);

AttractionGallery.propTypes = {
  requestLogin: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user }) => ({
  isAuthenticated: user.isAuthenticated,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  openModal,
  openAttractionContentByID,
  requestLogin
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AttractionGallery)
