import React from 'react';
import { Field, reduxForm } from 'redux-form';

import {
  InputContainer,
  StyledSearchIcon,
  StyledInput
} from './Styled'

const SearchInput = ({ value, onChange, placeholder }) => (
  <InputContainer>
    <StyledInput
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      type="text"
      autoFocus
    />
    <StyledSearchIcon />
  </InputContainer>
)

export default SearchInput;
