import React from 'react';

// Styles
import { 
	AuthorPicContainer
} from './Styled';

const PostAuthorPic = ({ 
	imgUrl,
	name
}) =>{
  return (
		<AuthorPicContainer>
			<img src={imgUrl} alt={name}/>
		</AuthorPicContainer>
	)
}

export default PostAuthorPic;