import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';
import CoachesSmallReviewItem from '../reviews/CoachSmallReviewItem';
import { UserCircle } from 'styled-icons/fa-solid/UserCircle';

import {
  ProfileIcon,
  ReviewBox,
  ReviewerPane,
  ReviewerName,
  ImageContainer,
  ReviewedAt,
  ExpandContainer,
  ReviewHeading,
  OVRatingDisplay,
  ButtonContainer,
  OVButton,
  OVRatingNumber,
  Brief,
  StyledCheck,
  SizedOne,
  SizedZero,
  ReviewDeleteButton
} from './StyledItem';

import ReviewerInfo from './ReviewerInfo';
import { bindActionCreators } from "redux";
import { deleteAdminReview } from "../../modules/review";
import { connect } from "react-redux";
import reviews from '../../modules/attraction/reviews';

const sortCoachRatings = (reviews) => {
  return [
    reviews.find(review => review.rating_key === 'overall_coach'),
    reviews.find(review => review.rating_key === 'style_coach'),
    reviews.find(review => review.rating_key === 'ability_coach'),
    reviews.find(review => review.rating_key === 'support_coach'),
    reviews.find(review => review.rating_key === 'integrity_coach'),
    reviews.find(review => review.rating_key === 'player_relationships_coach'),
  ].filter(review => review);
}

const getRatings = (reviews) => {
  return sortCoachRatings(reviews).map((review,i) => {
    let label = review.rating_key.replace("_coach", "").replace("_", " ");
    label = label.charAt(0).toUpperCase() + label.slice(1);

    return <CoachesSmallReviewItem key={i} label={label} review={review}/>
  })
};


const getRatingComponent = (rating, id) => {
  const wholeValue = parseInt(rating + "");
  let displayArr = [];
  for (var n = 0; n < wholeValue; n++) {
    displayArr[n] = <SizedOne key={id + "-rating1-" + n }/>
  }
  for (var m = 0; m < 5 - wholeValue; m++) {
    displayArr.push(<SizedZero key={id + "rating0"+ (wholeValue-m)}/>)
  }
  return displayArr.map(item => item);
}

////////////////////////////////////
///////////////////////////////////

class CoachReviewsItem extends Component{
  constructor() {
    super();
    this.state = {
      showMoreReviews: false
    }
  }

  handleShowMoreClick() {
    this.setState({
      showMoreReviews: !this.state.showMoreReviews
    });
  }

  render(){

    const {
      id,
      user,
      reviews,
      is_anonymous,
      currentUserRole,
      createdAt,
      onUserClick,
      deleteAdminReview
    } = this.props;

    const getAllCoachReviews = getRatings(reviews);
    const previewReview = reviews.find(review => review.rating_key === 'overall_coach');
    return (
      <ReviewBox>
        <ReviewerPane onClick={() => !is_anonymous ? onUserClick(user.ID) : null}>
          <ReviewerName is_anonymous={is_anonymous}>
            { !is_anonymous ?
            <span>{user.first_name} {user.last_name} {user.is_verified && <StyledCheck/>}</span> :
            <span>Anonymous Reviewer {user.is_verified && <StyledCheck/>}</span>
            }
          </ReviewerName>
          <ImageContainer is_anonymous={is_anonymous}>
            { user.avatar == null &&
              <ProfileIcon>
                <UserCircle />
              </ProfileIcon>
            }
            { user.avatar && user.avatar.src_th &&
              <ProfileIcon
                style={{
                  backgroundImage: `url(${user.avatar.src_th})`,
                }}
              >
              </ProfileIcon>
            }
          </ImageContainer>

          <ReviewerInfo user={user} is_anonymous={is_anonymous}/>
        </ReviewerPane>
       
        <OVRatingDisplay>
          {previewReview && 
            <>
              <OVRatingNumber>{previewReview.rating}.0</OVRatingNumber>
              {getRatingComponent(previewReview.rating, previewReview.ID)}
            </>
          }
        </OVRatingDisplay>
       
        <ReviewedAt>Reviewed <TimeAgo live={false} date={createdAt}/></ReviewedAt>
        { previewReview &&
          <Brief>{previewReview.comment && `"${previewReview.comment}"`}</Brief>
        }
        <ExpandContainer>
          { this.state.showMoreReviews && (
          <div>
            <ReviewHeading>Reviews</ReviewHeading>
            {getAllCoachReviews}
          </div>
          )}

          <ButtonContainer>
            <OVButton onClick={() => this.handleShowMoreClick()}>
              {this.state.showMoreReviews ? "Read Less" : "Read More"}
            </OVButton>
          </ButtonContainer>
        </ExpandContainer>
        {currentUserRole === 'admin' && <ReviewDeleteButton onClick={() => deleteAdminReview(id)}/>}
      </ReviewBox>
    )
  }
}

CoachReviewsItem.propTypes = {
  id: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  reviews: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  currentUserRole: PropTypes.string.isRequired,
  onUserClick: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => bindActionCreators({
  deleteAdminReview
}, dispatch);

export default connect(null, mapDispatchToProps)(CoachReviewsItem)
