import React from 'react';
import PropTypes from 'prop-types';
// Rediux stuff
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { listSportsIfEmpty, listPositions } from "../../modules/wizard";
import { connect } from "react-redux";
import { withLifecycleActions } from "react-redux-lifecycle";
import { bindActionCreators } from "redux";
// Components
import StyledSelect from '../../components/styledSelect';

import {
  TitleHeading,
  ErrorMessage,
  NextButton
} from './Styled';

// Select Field
const selectField = ({ input, options, isDisabled, clearable, meta: { touched, active, error } , label}) => (
  <div>
    <StyledSelect
      instanceId={input.name}
      {...input}
      options={options}
      clearable={clearable}
      onBlur={() => input.onBlur}
      placeholder={label}
      isDisabled={isDisabled}
    />
    {touched && !active && error && <ErrorMessage>{error}</ErrorMessage>}
  </div>
);
// end of Select Field

let Step2 = props => {

  const {
    handleSubmit,
    sports,
    currentStep,
    positionsListFiltered,
    listPositions,
    sportValue
  } = props;
  
  // Will hide the component if the current step is not equal to 3
  if (currentStep !== 2) { return null }

  const getSportValue = (e) => {
    listPositions(sports, e.label);
  }
  
  // Sports options
  const sportsNames = sports.map(e => ({
    label: `${e.sport}`,
    value: e.ID
  }));
  const sportsOpts = sportsNames.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj["label"]).indexOf(obj["label"]) === pos;
  });
  sportsOpts.unshift({label: "N/A", value: null});
  // 
  // Validation
  const required = value => value ? undefined : 'Required';
  
  return (
    <form onSubmit={handleSubmit}>
      <TitleHeading>What sport do you play?</TitleHeading>
      <Field 
        name="sport" 
        label="Sport" 
        component={selectField} 
        type="text"
        options={sportsOpts}
        validate={[required]}
        onChange={getSportValue}
      />
      {sportValue && sportValue.label !== 'N/A' &&
        <Field 
          name="position" 
          label="Position" 
          component={selectField}
          type="text"
          options={positionsListFiltered}
          validate={[required]}
        />
      }
      <div>
        <NextButton type="submit">Next</NextButton>
      </div>
    </form>
  )
}

Step2.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

const mapStateToProps = ({ wizard }) => ({
  sports: wizard.sports,
  positionsListFiltered: wizard.positions_list
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {listSportsIfEmpty, listPositions},
    dispatch
  );

Step2 = reduxForm({
  // a unique name for the form
  form: 'RecruitForm',
  destroyOnUnmount: false
})(Step2)

const selector = formValueSelector('RecruitForm') // <-- same as form name
Step2 = connect(
  state => {
    const sportValue = selector(state, 'sport')
    return {
      sportValue
    }
  }
)(Step2)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withLifecycleActions({
    componentWillMount: [listSportsIfEmpty]
  })(Step2)
);