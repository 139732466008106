import styled from 'styled-components';
import { color, typo, bp } from '../../../styles'
import { Heading } from 'rebass';

export const Body = styled.div`
	padding-bottom: 40px;
	border-bottom: 1px solid ${color.ovGrey};
	color: ${color.ovBlack};
	letter-spacing: normal;
	text-align: left;
	margin-bottom: 20px;
  p{
		font-size: 18px;
		font-weight: 300;
	  font-style: normal;
	  font-stretch: normal;
	  line-height: 1.61;
	  font-family: ${typo.ovFontSerif};
	  margin-bottom: 20px;
	  img{
	  	width: 100%;
	  }
  }
  h1{
  	font-size: 34px;
	  font-weight: 300;
	  font-style: normal;
	  font-stretch: condensed;
	  line-height: 0.74;
	  font-family: ${typo.ovFontFeature};
	  margin-bottom: 15px;
  }
  h3{
  	font-size: 24px;
	  font-weight: 300;
	  font-style: normal;
	  font-stretch: condensed;
	  line-height: 1;
	  font-family: ${typo.ovFontFeature};
	  margin-bottom: 25px;
	}
`;
 
export const Title = styled(Heading)`
	font-family: ${typo.ovFontFeature};
	color: ${color.ovBlack};
	font-size: 42px;
	font-weight: 300;
	font-style: normal;
	font-stretch: condensed;
	line-height: 0.6;
	letter-spacing: normal;
	text-align: left;
	margin-bottom: 5rem;
	@media ${bp.phoneOnly} {
		font-size: 34px;
		line-height: 0.74;
	}
`;

export const FeaturedImage = styled.div`
	width: 100%;
	height: 100%;
	margin-bottom: 65px;
	img{
		width: 100%;
	}
`;

export const Date = styled.p`
	font-size: 16px;
	font-weight: 300;
	font-style: normal;
	font-stretch: condensed;
	line-height: 1.56;
	letter-spacing: normal;
	text-align: left;
	font-family: ${typo.ovFontFeature};
	color: ${color.ovGrey};
	@media ${bp.phoneOnly} {
		font-size: 16px;
		line-height: 1.56;
	}
`;