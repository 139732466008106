import { color, bp, typo } from '../../../styles'
import styled from 'styled-components';

export const FiltersList = styled.div`
  max-height: 58px;
	border-top: 1px solid #707070;
	border-bottom: 1px solid #707070;
	background: ${color.ovWhite};
	margin: 0px 0 42px 0;
  padding: 11px 20px 0 20px;
  display: flex;
  @media ${bp.phoneOnly} {
    position: sticky;
    top: 63px;
    z-index: 998;
    padding: 11px 1rem 0 1rem;
    margin: ${props => props.openMap ? "-42px 0 42px 0" : "0px 0 42px 0" };
  }
`;

export const FiltersTop = styled.div`
  max-height: 40px;
  max-width: 980px;
  display: flex;
  overflow: hidden;
  flex-flow: wrap;
  flex-grow: 2;
  justify-content: space-around;

  @media ${bp.phoneOnly} {
    justify-content: space-between;
  }
`;

export const FilterItem = styled.span`
  display: inline-block;
  flex: ${({ isMore }) => isMore ? '0' : '1'};
  height: 34px;
  border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.5px ${color.ovGreyDarker};
  background-color: ${color.ovWhite};
  font-family: ${typo.ovFontFeature};
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: condensed;
  line-height: 32px;
  letter-spacing: normal;
  text-align: center;
  white-space: nowrap;
  color: ${color.ovGreyDarker};
  padding: 0 13px;
  cursor: pointer;
  transition: all .1s;
  margin: 0 15px 11px 0;

  &.hover, &.selected{
		background-color: ${color.ovBlue};
		color: ${color.ovWhite};
		transition: all .1s;
  }

  @media ${bp.phoneOnly} {
    font-size: 18px;

    margin: 0 1rem 11px 0;
    padding: 0 1rem;
  }
`;