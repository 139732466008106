import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { listCollegesIfEmpty } from '../../../modules/college';
import { rankByGraduationSuccessSpecialSelector, baseSelector, pageSelector } from '../../../modules/college/selectors';
import { getUserIfEmpty } from '../../../modules/user';
import CollegeBlackGraduationSuccess from "./CollegeBlackGraduationSuccess";

const mapStateToProps = (state) => {
  const { user } = state;
  const ranked = rankByGraduationSuccessSpecialSelector('black')(state);
  return {
    isFetching: baseSelector(state).isFetchingCollege,
    isAuthenticated: user.isAuthenticated,
    paginate: pageSelector(ranked),
    count: ranked.length,
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({
  listCollegesIfEmpty,
  getUserIfEmpty,
  push,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CollegeBlackGraduationSuccess)