// eslint-disable-next-line
import React from 'react';
import { bindActionCreators } from 'redux'; 
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withLifecycleActions } from 'react-redux-lifecycle';
import {
  getUserIfEmpty,
  getProfileIfEmpty,
  toggleEditUser,
  toggleEditProfile,
  toggleEditUserPicture,
  editUser,
  editProfile,
  getContributionsIfEmpty, requestLogin
} from '../../modules/user';
import { listCollegesIfEmpty } from '../../modules/college';

import Profile from './profileContainer';
import { getTargetAttractionsByContributionsIfEmpty } from "../../modules/attraction/attraction";
import { getTargetCoachesByContributionsIfEmpty } from "../../modules/coach";

const mapStateToProps = ({ user, attraction, coach, college }) => ({
  user: user.user,
  profile: user.profile,
  colleges: college.colleges,
  isAuthenticated: user.isAuthenticated,
  isFetching: user.isFetching,
  isEditingProfile: user.isEditingProfile,
  isEditingUser: user.isEditingUser,
  contributions: user.contributions,
  isFetchingReviews: college.isFetchingReviews,
  targetAttractions: attraction.targetAttractions,
  targetCoaches: coach.targetCoaches,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getUserIfEmpty,
  getProfileIfEmpty,
  getContributionsIfEmpty,
  toggleEditProfile,
  toggleEditUser,
  toggleEditUserPicture,
  editUser,
  editProfile,
  getTargetAttractionsByContributionsIfEmpty,
  getTargetCoachesByContributionsIfEmpty,
  push,
  requestLogin
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withLifecycleActions({
  componentWillMount: [getUserIfEmpty, getProfileIfEmpty, listCollegesIfEmpty],
  componentDidMount: [getContributionsIfEmpty]
})(Profile))