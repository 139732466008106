import React from 'react'

import {
  CollegeSmallCardBox
} from './Styled'

const CollegeSmallCard = ({
  children
}) => {
  return(
    <CollegeSmallCardBox>
      {children}
    </CollegeSmallCardBox>
  )
}

export default CollegeSmallCard;