import styled from 'styled-components';
import { color, typo, bp } from '../../styles'
import Select from 'react-select';

export let StyledSelect = styled(Select)`
  height: 39px;

  .react-select__control {
    border: solid 3px ${color.ovBlue};
    border-radius: 0px;

    font-family: ${typo.ovFontFeature};
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: ${color.ovBlue};
    
    height: 29px;
  }

  .react-select__value-container {
    display: inline-block;
    width: 100%;
    padding-left: 10px;
    background-color: ${color.ovWhite};

    padding-top: 0;
    padding-bottom: 0;

    height: 29px;

    position: static;
  }

  .react-select__single-value {
    color: ${color.ovBlue};
    margin-left: 0;
    margin-right: 0;
  }

  .react-select__indicator {
    color: ${color.ovBlue};
  }

  .react-select__menu {
    display: inline-block;
    border-radius: 0px;
    border: solid 3px ${color.ovBlue};
    width: 100%;
    background-color: ${color.ovWhite};
    z-index: 5;
  }

  .react-select__option {
    font-family: ${typo.ovFontFeature};
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
  }
`;

export const PageContent = styled.div`
  grid-column-start: 4;
  grid-column-end: span 6;
  
  @media ${bp.phoneOnly} {
    grid-column: span 12;
  }
`;