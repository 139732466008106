import React, {useState, useEffect, useRef} from 'react';
import { selectedFilter, toggleFilter } from "../../utils/filter";
import { applyFilterLoader } from '../../modules/college/college';

import {
  FiltersBox,
  FiltersColumns,
  FilterType,
  FiltersTotal,
  CheckboxContainer,
  Filter,
  FilterLabel,
  Filters,
  FilterTypeDropdown
} from './StyledFilters';

import {
  ReviewHr
} from './StyledList';

// Checkbox
const Checkbox = (
  {
    input,
    onShowLoader,
  }) => (
  <div className="flex items-center mv4 w-100">
    <CheckboxContainer
      { ...input }
      className="mr2"
      type="checkbox"
      checked={ input.filter.selected(input.value) }
      onChange={() => { input.filter.toggle(input.value) }}
      onClick={() => onShowLoader()}
    />
  </div>
);
// end of Checkbox

let ReviewsFilters = (
  {
    count,
    countTotal,

    reviewsFilter,
    setReviewsFilter,

    onChange,

    onShowLoader,
  }) => {

  const filter = {
    selected: selectedFilter(reviewsFilter),
    toggle: toggleFilter(reviewsFilter, setReviewsFilter, onChange)
  };

  const [ratingShow, setRatingShow] = useState(false);
	const [ratingHeight, setRatingHeight] = useState(0);
  const RatingFilterHeight = useRef(null);
  
  const [reviewerShow, setReviewerShow] = useState(false);
	const [reviewerHeight, setReviewerHeight] = useState(0);
  const ReviewerFilterHeight = useRef(null);
  
  const [statusShow, setStatusShow] = useState(false);
	const [statusHeight, setStatusHeight] = useState(0);
  const StatusFilterHeight = useRef(null);
  
  const [verificationShow, setVerificationShow] = useState(false);
	const [verificationHeight, setVerificationHeight] = useState(0);
  const VerificationFilterHeight = useRef(null);

	useEffect(() => {
    reviewerShow && onShowLoader();
    setRatingHeight(RatingFilterHeight.current && RatingFilterHeight.current.clientHeight);
    setReviewerHeight(ReviewerFilterHeight.current && ReviewerFilterHeight.current.clientHeight);
    setStatusHeight(StatusFilterHeight.current && StatusFilterHeight.current.clientHeight);
    setVerificationHeight(VerificationFilterHeight.current && VerificationFilterHeight.current.clientHeight);
  })

  return (
    <FiltersBox>
      <FiltersColumns>
      
        <Filters data-show={ratingShow} ref={RatingFilterHeight}>
          { ratingHeight <= 20 || ratingHeight === undefined ?
            <FilterTypeDropdown onClick={() => setRatingShow(!ratingShow)}>
              Rating <span>{!ratingShow ? `▼` : `▲`}</span>
            </FilterTypeDropdown>
            : <FilterType>Rating</FilterType>
          }
          <div>
            <Filter>
              <Checkbox
                onShowLoader={onShowLoader}
                id="five"
                name="five"
                input={{
                  filter: filter,
                  value: 'stars.5'}}
              />
              <FilterLabel>5 Stars</FilterLabel>
            </Filter>
            <Filter>
              <Checkbox
                onShowLoader={onShowLoader}
                id="four"
                name="four"
                input={{
                  filter: filter,
                  value: 'stars.4'}}
              />
              <FilterLabel>4 Stars</FilterLabel>
            </Filter>
            <Filter>
              <Checkbox
                onShowLoader={onShowLoader}
                id="three"
                name="three"
                input={{
                  filter: filter,
                  value: 'stars.3'}}
              />
              <FilterLabel>3 Stars</FilterLabel>
            </Filter>
            <Filter>
              <Checkbox
                onShowLoader={onShowLoader}
                id="two"
                name="two"
                input={{
                  filter: filter,
                  value: 'stars.2'}}
              />
              <FilterLabel>2 Stars</FilterLabel>
            </Filter>
            <Filter>
            <Checkbox
              onShowLoader={onShowLoader}
              id="one"
              name="one"
              input={{
                filter: filter,
                value: 'stars.1'}}
            />
            <FilterLabel>1 Star</FilterLabel>
          </Filter>
          </div>
        </Filters>

        <Filters data-show={reviewerShow} ref={ReviewerFilterHeight}>
          { reviewerHeight <= 20 || reviewerHeight === undefined ?
            <FilterTypeDropdown onClick={() => setReviewerShow(!reviewerShow)}>
              Reviewer type <span>{!reviewerShow ? `▼` : `▲`}</span>
            </FilterTypeDropdown>
            : <FilterType>Reviewer type</FilterType>
          }
          <div>
            <Filter>
              <Checkbox
                onShowLoader={onShowLoader}
                id="athlete"
                name="athlete"
                input={{
                  filter: filter,
                  value: 'reviewer_type.athlete'}}
              />
              <FilterLabel>Football student-athlete</FilterLabel>
            </Filter>
            <Filter>
              <Checkbox
                onShowLoader={onShowLoader}
                id="student"
                name="student"
                input={{
                  filter: filter,
                  value: 'reviewer_type.student'}}
              />
              <FilterLabel>Student</FilterLabel>
            </Filter>
            <Filter>
              <Checkbox
                onShowLoader={onShowLoader}
                id="fan"
                name="fan"
                input={{
                  filter: filter,
                  value: 'reviewer_type.fan'}}
              />
              <FilterLabel>Fan</FilterLabel>
            </Filter>
          </div>
        </Filters>

        <Filters data-show={statusShow} ref={StatusFilterHeight}>
          { statusHeight <= 20 || statusHeight === undefined ?
            <FilterTypeDropdown onClick={() => setStatusShow(!statusShow)}>
            Reviewer status <span>{!statusShow ? `▼` : `▲`}</span>
            </FilterTypeDropdown>
            : <FilterType>Reviewer status</FilterType>
          }
          <div>
            <Filter>
            <Checkbox
              onShowLoader={onShowLoader}
              id="current"
              name="current"
              input={{
                filter: filter,
                value: 'reviewer_status.current'}}
            />
            <FilterLabel>Current student</FilterLabel>
          </Filter>
            <Filter>
            <Checkbox
              onShowLoader={onShowLoader}
              id="alumni"
              name="alumni"
              input={{
                filter: filter,
                value: 'reviewer_status.alumni'}}
            />
            <FilterLabel>Alumni</FilterLabel>
          </Filter>
          </div>
        </Filters>

        <Filters data-show={verificationShow} ref={VerificationFilterHeight}>
          { verificationHeight <= 20 || verificationHeight === undefined ?
            <FilterTypeDropdown onClick={() => setVerificationShow(!verificationShow)}>
            Reviewer verification <span>{!verificationShow ? `▼` : `▲`}</span>
            </FilterTypeDropdown>
            : <FilterType>Reviewer verification</FilterType>
          }
          <div>
            <Filter>
              <Checkbox
                onShowLoader={onShowLoader}
                id="verified"
                name="verified"
                input={{
                  filter: filter,
                  value: 'verified'}}
              />
              <FilterLabel>Verified</FilterLabel>
            </Filter>
          </div>
        </Filters>

      </FiltersColumns>

      <FiltersTotal>
        { countTotal !== 0 ? (
          count > 1 ? (<div>
            <span>1</span> - <span>{count}</span> of <span>{countTotal}</span> reviews</div>) : (<div><span>{count}</span> of <span>{countTotal}</span> reviews</div>)
        ) :
          (<div>
            <span>0</span> reviews
          </div>)
        }

      </FiltersTotal>

      <ReviewHr/>
    </FiltersBox>
  )
};

export default ReviewsFilters