import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router'; 
import PostList from '../../../components/Blog/PostList';
import { Grid } from 'react-bootstrap';

const List = ({
  location,
  isFetching,
  listPosts,
  listCategories,
  posts,
  categories,
  category,
  push,
}) => {
  const slug = location.hash;

  useEffect(() => {
    if (!categories) {
      listCategories();
    }
  }, [categories]);

  useEffect(() => {
    if (!isFetching && (!posts || category !== slug) ){
      listPosts(1, slug);
    }
  }, [posts, slug]);

  return (
    <Grid>
      <Helmet>
        <title>Blog - OfficialVisit</title>
        <meta name="description" content="Read the latest articles about colleges, college football, branding, and much more!" />
      </Helmet>
      <PostList
        posts={posts}
        categories={categories}
        push={push}
      />
    </Grid>
  );
};

export default withRouter(List);