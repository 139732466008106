import * as types from './actions';
import { CONTENT_DELETED } from '../content/actions';
import { GET_ATTRACTION_CONTENT_SUCCESS } from "./actions";
import { GET_ATTRACTION_CONTENT_FAILURE } from "./actions";
import { GET_ATTRACTION_CONTENT_REQUESTED } from "./actions";
import { ATTRACTION_CONTRIBUTION_SUCCESS } from "../contribute/actionsTypes";
import { LOCATION_CHANGE } from 'react-router-redux';
import findIndex from "lodash/findIndex";
import { DELETE_ADMIN_REVIEW_SUCCESS } from "../review/actionsTypes";

const initialState = {
  attractions: {},
  allAttractions: {},
  isFetching: false,
  isFetchingReviews: false,
  isFetchingContent: false,
  isFetchingTargetAttractions: false,
  isFetchingAllAttractions: false,
  errorMessage: null,
  reviews: [],
  contents: [],
  selectedContentIndex: 0,
  targetAttractions: false, // false is here to manage this when it has not been loaded yet
  targetId: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.LIST_ATTRACTIONS_REQUESTED:
      return {
        ...state,
        errorMessage: null,
        isFetching: true,
        attractions: {
          ...state.attractions,
          [payload.collegeId]: [], // Empty array to indicate already requested
        },
      };

    case types.LIST_ATTRACTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        attractions: {
          ...state.attractions,
          [payload.collegeId]: payload.data,
        },
      };
    case types.LIST_ATTRACTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: payload.message,
      };

    case types.LIST_ATTRACTIONS_TAGS_SUCCESS:
      return {
        ...state,
        tags: payload,
      };
    case types.LIST_ATTRACTIONS_TAGS_FAILURE:
      return {
        ...state,
        errorMessage: payload.message,
      };

    case types.GET_ATTRACTION_REQUESTED:
      return {
        ...state,
        attraction: null,
      };
    case types.GET_ATTRACTION_SUCCESS:
      return {
        ...state,
        attraction: payload,
      };
    case types.GET_ATTRACTION_FAILURE:
      return {
        ...state,
        errorMessage: payload.message,
      };

    case GET_ATTRACTION_CONTENT_REQUESTED:
      return {
        ...state,
        isFetchingContent: true,
      };
    case GET_ATTRACTION_CONTENT_SUCCESS:
      return {
        ...state,
        isFetchingContent: false,
        contents: action.payload.map((content) => {
          return { ...content, src: content.src_lg, caption: content.contrib_item.description }
        })
      };
    case GET_ATTRACTION_CONTENT_FAILURE:
      return {
        ...state,
        isFetchingContent: false,
      };

    case ATTRACTION_CONTRIBUTION_SUCCESS:
      return {
        ...state,
        contents: [...state.contents, ...action.payload.contents.map((content) => {
          return { ...content, src: content.src_lg, caption: content.contrib_item.description }
        })],
      };

    case types.GET_ATTRACTION_REVIEWS_REQUESTED:
      return {
        ...state,
        isFetchingReviews: true,
        reviews: null,
      };

    case types.GET_ATTRACTION_REVIEWS_SUCCESS:
      return {
        ...state,
        isFetchingReviews: false,
        reviews: payload.data,
      };
    case types.GET_ATTRACTION_REVIEWS_FAILURE:
      return {
        ...state,
        isFetchingReviews: false,
        errorMessage: payload.message,
      };
    case types.SET_ATTRACTION_ABOUT:
      return {
        ...state,
        attraction: { ...state.attraction, about: action.payload.message }
      };

    case DELETE_ADMIN_REVIEW_SUCCESS:
      return {
        ...state,
        reviews: [...state.reviews.filter(el => el.ID !== action.payload)]
      };

    case types.CREATE_ATTRACTION_REQUESTED:
      return {
        ...state,
        errorMessage: null,
        isFetching: true,
      };
    case types.CREATE_ATTRACTION_SUCCESS:
      const { attractions: { [payload.college_refer]: prevAttraction }, allAttractions: prevAllAttractions} = state;
      return {
        ...state,
        attraction: payload,
        isFetching: false,
        attractions: {
          ...state.attractions,
          [payload.college_refer]: prevAttraction && [...prevAttraction, payload],
        },
        allAttractions: prevAllAttractions && { ...prevAllAttractions, payload}
      };
    case types.CREATE_ATTRACTION_FAILURE:
      return {
        ...state,
        errorMessage: payload.message,
        isFetching: false,
      };

    case types.DELETED_ATTRACTION: {
      const { attractions: { [payload.college_refer]: prevAttraction }, allAttractions: prevAllAttractions } = state;
      if (prevAttraction) {
        const resultAttractionList = prevAttraction.filter(attraction => attraction.ID !== payload.ID)
        return {
          ...state,
          attractions: {
            ...state.attractions,
            [payload.college_refer]: resultAttractionList && resultAttractionList,
          },
        }
      }
      if (prevAllAttractions) {
        delete prevAllAttractions[payload.ID];

        return {
          ...state,
          allAttractions: {
            ...prevAllAttractions,
          }
        }
      }
    }

    case types.UPDATE_ATTRACTION_REQUESTED:
      return {
        ...state,
        errorMessage: null,
        isFetching: true,
      };
    case types.UPDATE_ATTRACTION_SUCCESS:
      const { attractions: { [payload.college_refer]: previusAttraction } } = state;
      const filteredAttractions = previusAttraction && previusAttraction.filter(attraction => attraction.ID !== payload.ID);

      return {
        ...state,
        attraction: payload,
        isFetching: false,
        attractions: {
          ...state.attractions,
          [payload.college_refer]: filteredAttractions && [...filteredAttractions, payload],
        },
      };
    case types.UPDATE_ATTRACTION_FAILURE:
      return {
        ...state,
        errorMessage: payload.message,
        isFetching: false,
      };

    case types.ATTRACTION_CONTENT_INDEX_SELECTED:
      return {
        ...state,
        selectedContentIndex: action.payload
      };

    case types.GET_USER_BY_CONTENT_ID_REQUESTED:
      return {
        ...state,
        isFetchingContent: true,
        errorMessage: ''
      };

    case types.GET_USER_BY_CONTENT_ID_SUCCESS: {
      const { contentId: ID, user } = action.payload;
      const { contents } = state;
      const index = findIndex(contents, { ID });
      const withUser = { ...contents[index], user };

      return {
        ...state,
        user: action.payload,
        isFetchingContent: false,
        contents: [...contents.slice(0, index), withUser, ...contents.slice(index + 1)],
      }
    }

    case types.GET_USER_BY_CONTENT_ID_FAILURE:
      return {
        ...state,
        isFetchingContent: false,
        errorMessage: action.payload
      };

    case types.GET_TARGET_ATTRACTIONS_REQUESTED:
      return {
        ...state,
        isFetchingTargetAttractions: true,
        targetAttractions: false,
      };
    case types.GET_TARGET_ATTRACTIONS_SUCCESS:
      return {
        ...state,
        isFetchingTargetAttractions: false,
        targetAttractions: payload.attractions,
        targetId: payload.targetId,
      };
    case types.GET_TARGET_ATTRACTIONS_FAILURE:
      return {
        ...state,
        isFetchingTargetAttractions: false,
        errorMessage: payload.message,
      };

    case types.GET_ALL_ATTRACTIONS_REQUESTED:
      return {
        ...state,
        isFetchingAllAttractions: true,
        allAttractions: {},
      };
    case types.GET_ALL_ATTRACTIONS_SUCCESS:
      return {
        ...state,
        isFetchingAllAttractions: false,
        allAttractions: payload
      };
    case types.GET_ALL_ATTRACTIONS_FAILURE:
      return {
        ...state,
        isFetchingAllAttractions: false,
        errorMessage: payload.message,
      };

    // can process here because content model is common and it has unique ID
    case CONTENT_DELETED:
      return {
        ...state,
        contents: [...state.contents.filter((content) => content.ID !== action.payload.ID)]
      };

    case LOCATION_CHANGE:
      return action.payload.location.pathname.includes('content') ? {
        ...state
      } : {
        ...state,
        selectedContentIndex: 0
      };

    default:
      return state
  }
}