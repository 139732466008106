import styled from 'styled-components';
import { color, typo, bp } from '../../styles';
import MidTitle from '../../components/MidTitle'
import { Button, Heading } from 'rebass';
import { User } from 'styled-icons/fa-solid/User';
import { School } from 'styled-icons/fa-solid/School';

export const FullWithContent = styled.div`
  background-color: ${color.ovWhite};
  margin-top: -38px;
  min-height: 158px;
  border-bottom: solid 1px ${color.ovGreyDark};
  padding: 20px 20px 0 20px;
  margin-bottom: 42px;
`;
export const PageContent = styled.div`
  grid-column: span 12;
  background-color: ${color.ovWhite};
  border: 1px solid ${color.ovGrey};
  padding: 25px;
  margin: 10px 0;
`;
export const FilterContainer = styled.div`
  margin-bottom: 0px;
  position: relative;
`;
export const FilterNav = styled.div`
  margin: 30px 0 6px 0;
`;
export const MidTitleButton = styled(MidTitle)`
  display: inline;
`;

export const MidTitleButtonLink = styled.a`
  border-right: 1px solid ${color.ovBlue};
  padding: 0 15px;
  color: ${color.ovBlue};
  cursor: pointer;
  &.selected{
    border-bottom: 2px solid ${color.ovBlue};
  }
  &.disable{
    opacity: .5;
    cursor: default;
    border-bottom: none;
    &:hover{
      text-decoration: none;
      border-bottom: none;
    }
  }
  &:hover{
    text-decoration: none;
    color: ${color.ovBlue};
    border-bottom: 2px solid ${color.ovBlue};
  }
`;
export const TitleContainer = styled.div`
  grid-column: span 12;
`;
export const AboutHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: .8;
  letter-spacing: normal;
  text-align: left;
  padding: 0 10px;
  @media ${bp.phoneOnly} {
    font-size: 1.8rem;
  }
`;
export const NoResultsHeading = styled(AboutHeading)`
  margin: 2rem 0;
`;
export const AboutHr = styled.div`
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid ${color.ovGrey};
  margin: 5px 0 0;
  padding: 0;
`;
export const ResultsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  cursor: pointer;
  background-color: ${color.ovWhite};
  transition: all .1s;
`;


export const ResultList = styled.div`
  text-align: center;
`;

export const ResultItem = styled.div`
  width: 100%;
  height: 82px;
  border-bottom: 1px solid ${color.ovGrey};
  display: grid;
  grid-template-columns: 1fr 10fr;
  padding: 1rem 0;
  &:hover{
    background-color: ${color.ovGreyLight};
    transition: all .1s;
  }
`;

export const SearchItemIcon = styled.div`
  height: 60px;
  width: 60px;
  margin: 0 1rem;
  justify-content: center;
  background-color: ${color.ovWhite};
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid ${color.ovBlue};
  padding-top: 7px;
`;

export const StyledUser = styled(User)`
  width: 56px;
  padding: 8px 10px;
  path{ 
    fill: ${color.ovBlue};;
  }
`;

export const SearchItemMeta = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
`;

export const SearchTitle = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: .8;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 1rem;
`;

export const SearchType = styled(SearchTitle)`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontSerif};
  font-size: 18px;
`;

export const StyledSchool = styled(School)`
  width: 56px;
  padding: 14px 10px;
  path{ 
    fill: white;
  }
`;

export const OVButton = styled(Button)`
  display: inline-block;
  font-family: ${typo.ovFontFeature};
  background: ${color.ovYellow};
  color: ${color.ovWhite};
  cursor: pointer;
  padding: 2px 40px;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.95;
  letter-spacing: normal;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(5, 5, 5, 0.16);
  border: solid 1px ${color.ovGreyDark};
  transition: all .1s;
  &:hover, &:focus {
    text-decoration: none;
    background: ${color.ovBlueLight};
    border-color: ${color.ovBlueLight};
    transition: all .1s;
  }
`;

export const ImgStyle = {
  //objectFit: 'cover',
  height: '39px',
  width: '39px',
  imageOrientation: 'from-image',
};