import styled from 'styled-components';

import { Heading, Button } from 'rebass';
import { Edit } from 'styled-icons/fa-solid/Edit';

import { color, typo, bp } from '../../styles';

export const QuarterPartPageContent = styled.div`
  grid-column: span 12;
  @media ${bp.tabletLandUp} {
    grid-column: span 4;
  }
`;

export const EighthPartPageContent = styled.div`
  grid-column: span 12;
  @media ${bp.tabletLandUp} {
    grid-column: span 8;
  }
`;

export const ReviewsPartPageContent = styled(QuarterPartPageContent)`
  position: sticky;
  top: 85px;
  margin-bottom: 2rem;
  margin-top: -17px;
  @media ${bp.phoneOnly} {
    grid-column: span 12;
    position: relative;
    top: 0;
    grid-row: span 12;
  }
  @media ${bp.tabletLandUp} {
    margin-bottom: 0rem;
    margin-top: 0px;
  }
`;

export const MostPageContentLeft = styled.div`
  grid-column: span 12;

  @media ${bp.tabletLandUp} {
    grid-column: span 8;
  }
  > div{
     @media ${bp.phoneOnly} {
      border: none;
      border-top: solid 2px ${color.ovGrey};
    }
  }
`;

export const ReviewsBox = styled.div`
  grid-template-columns: 8fr 4fr;
  display: grid;
  grid-column: 12 span;
  grid-column-gap: 22px;
  @media ${bp.phoneOnly}{
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`;


export const ReviewsPageContent = styled.div`
  grid-column: span 12;
  text-align: center;
  position: relative;
  top: 0px;
  height: auto;
  @media ${bp.tabletLandUp} {
    grid-column: span 4;
    position: sticky;
    position: -webkit-sticky;
    top: 85px;
  }
`;

export const MapPageContent = styled(ReviewsPageContent)`
  @media ${bp.phoneOnly} {
    margin: -19px 0 0 0;
    padding: 10px 10px 0 10px;
  }
  @media ${bp.tabletLandUp} {
    grid-column: span 4;
    position: block;
    top: auto;
  }
`;

export const AboutContainer = styled.div`
  background: ${color.ovWhite};
  border: solid 2px ${color.ovGrey};
  padding: 14px 8px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 29px auto auto;
  position: relative;
  height: 100%;
  max-height: 736px;
  overflow: hidden;
  transition: height 1s linear;
  &[data-show=true]{
    max-height: none;
    overflow: visible;
    transition: height 1s linear;
    padding-bottom: 80px;
  }
  @media ${bp.phoneOnly} {
    border: none;
    margin-top: -20px;
    margin-bottom: -40px;
  }
`;

export const TitleContainer = styled.div`
  grid-column: span 12;
`;

export const AboutHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: .8;
  letter-spacing: normal;
  text-align: left;
  padding: 0 10px 10px 10px;
  @media ${bp.phoneOnly} {
    font-size: 1.8rem;
  }
`;

export const AboutHr = styled.div`
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid ${color.ovGrey};
  margin: 5px 0 0;
  padding: 0;
`;

export const PageContent = styled.div`
  grid-column: span 12;
`;

export const StyledPageContent = styled(PageContent)`
  cursor: pointer;
`;

export const StyledEdit = styled(Edit)`
  width: 90px;
  color: ${color.ovYellow};
  margin: 20px auto;
  position: relative;
  top: 0px;
  display: block;
  transition: all .1s;
  ${StyledPageContent}:hover &{
    color: ${color.ovBlue};
    transition: all .1s;
  }
`;

export const OVButtonRight = styled(Button)`
  border: 1px solid ${color.ovGreyDark};
  font-family: ${typo.ovFontFeature};
  font-size: 16px;
  background: ${color.ovYellow};
  border-radius: 4px;
  color: ${color.ovWhite};
  cursor: pointer;
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  box-shadow: 0 3px 6px 0 rgba(5, 5, 5, 0.16);
  padding: 5px 35px;
  margin: 10px 0 0 0;
  transition: all .1s;
  &:hover, &:focus {
    text-decoration: none;
    transition: all .1s;
    background: ${color.ovBlueLight};
    border-color: ${color.ovBlueLight};
  }
  ${StyledPageContent}:hover &{
    text-decoration: none;
    transition: all .1s;
    background: ${color.ovBlueLight};
    border-color: ${color.ovBlueLight};
  }
`;

export const BackToAttractions = styled.div`
  border-bottom: 1px solid ${color.ovGrey};
  background-color: ${color.ovWhite};
  height: 25px;
  padding: 0 10px;
  font-family: ${typo.ovFontFeature};
  color: ${color.ovBlack};
  font-size: 16px;
  span{
    cursor: pointer;
    &:hover{
      color: ${color.ovBlue};
    }
  }
`;

export const LeaveReviewContainer = styled.div`
  grid-template-rows: auto auto;
  min-height: 178px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: ${color.ovWhite};
  padding: 15px 11px;
  background-image: url(${props => props.bgUrl});
  background-size: 50%;
  background-position: right bottom;
  background-repeat: no-repeat;
`;

export const LeaveReviewHeading = styled(Heading)`
  font-family: ${typo.ovFontFeature};
  font-size: 20px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlack};
  padding: 0 30px 30px 0;
`;

export const LeaveReviewButton = styled.button`
  font-family: ${typo.ovFontFeature};
  height: 29px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(5, 5, 5, 0.16);
  background-color: ${color.ovYellow};
  font-size: 18px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${color.ovWhite};
`;

export const SpinnerWrapper = styled.div`
  margin: 5px ${({ bottom }) => bottom ? "20px 10px" : "15px -5px"};
`;
