import styled from 'styled-components';
import { Heading } from 'rebass';
import { color, typo, bp } from '../../styles';
import { ReactComponent as OVPlus } from '../../assets/icons/ov_plus.svg';

export const College = styled.div`
  min-height: 130px;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px ${color.ovGrey};
  background-color: ${color.ovWhite};
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: 170px auto;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 150px auto;
  padding: 5px;
  cursor: pointer;
  transition: all .1s;
  &:hover{
      transition: all .1s;
      transform: scale(1.04);
  }
  @media ${bp.tabletLandUp} {
    grid-template-columns: 170px auto;
    grid-template-rows: 1fr;
  }
  @media ${bp.phoneOnly} {
    grid-template-rows: 120px auto;
  }
`;

export const ResultHeading = styled(Heading)`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
  font-size: 40px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  line-height: 1;
`;

export const DotSeparator = styled.span`
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin: 4px 10px;
  display: inline-block;
  border-color: ${color.ovGrey};
  background: ${color.ovGrey};
`;

export const CollegePhoto = styled.div`
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  width: 100%;
  height: 100%;
  background-image: ${props => `url("${props.bgURL}")`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const CollegeInfo = styled.div`
  padding-left: 16px;
  @media ${bp.phoneOnly} {
    padding: 5px 5px 0 5px;
  }
`;

export const CollegeListTotal = styled.div`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
  font-size: 25px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  span{
    color: ${color.ovBlue};
  }
`;

export const CollegeLocation = styled.div`
  color: ${color.ovGrey};
  font-family: ${typo.ovFontFeature};
  font-size: 17px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
`;

export const CollegeStats = styled.div`
  font-family: ${typo.ovFontFeature};
  margin-bottom: 17px;
  font-size: 17px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovGrey};
  @media ${bp.phoneOnly} {
    margin-bottom: 12px;
  }
`;

export const CollegeStatsRank = styled.span`
  color: ${color.ovBlack};
`;
export const CollegeStatsAcceptance = styled.span``;
export const CollegeStatsType = styled.span``;

export const OVplusIcon = styled(OVPlus)`
  width: 19px;
  height: auto;
  margin-top: -7px;
  padding-right: 3px;
`;

export const AverageReviewBox = styled.div`
  display: flex;
  align-items: center;
`;

export const ReviewsCount = styled.span`
  font-family: ${typo.ovFontFeature};
  font-size: 13px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovGrey};
`;