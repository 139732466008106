import styled from 'styled-components';
import { color, bp } from '../../../styles'
import { Heading } from 'rebass';

export const MapCard = styled.div`
  /* height: calc(100vh - 287px); */

  @media ${bp.phoneOnly} {
    height: 100%;
  }
  @media ${bp.tabletPortUp} {
    height: 300px;
  }
  @media ${bp.tabletLandUp} {
    height: calc(100vh - 289px);
  }
`;

export const MapStyles = {
  height: '100%',
  width: '100%',
};

export const LocationsPopup = styled.div`
  .mapboxgl-popup {
    z-index: 10 !important;

    &.clickable {
      cursor: pointer;
    }
    
    &.top-label {
      z-index: 11 !important;
    }

    &-content {
      width: 166.5px;
      max-width: 166.5px;
      border: 2px solid ${color.ovBlue};
      border-radius: 0.6rem;
      background-color: #ffffff;
    }

    &-anchor {
      &-top-left,
      &-top-right,
      &-top {
        .mapboxgl-popup-tip {
          border-bottom-color: ${color.ovBlue};
        }
      }

      &-right {
        .mapboxgl-popup-tip {
          border-left-color: ${color.ovBlue};
        }
      }

      &-left {
        .mapboxgl-popup-tip {
          border-right-color: ${color.ovBlue};
        }
      }

      &-bottom-right,
      &-bottom-left,
      &-bottom {
        .mapboxgl-popup-tip {
          border-top-color: ${color.ovBlue};
        }
      }
    }
  }
`;

export const LocationsCardTitile = styled(Heading)`
  font-family: Molde;
	font-size: 15px;
  font-weight: 300;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlue};
`;

export const LocationsCardFilters = styled.p`
  font-family: Marbach;
	font-size: 9px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlue};
  padding-top: 3px;
`;

export const ExpandButton = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 0 .9rem;
  text-align: center;
  line-height: 26px;
  border-radius: 2px;
  font-size: 1.8rem;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
  color: ${color.ovGreyDarker};
`;