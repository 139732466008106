import styled from 'styled-components';
import { typo, color }from '../../../styles';
import { Link } from 'react-router-dom';

export const AttractionPreviewCardContainer = styled.div`
  width: 100%;
  height: 191.8px;
  margin: 23.5px 21.8px 23.5px 0px;
  border-radius: 4px;
  background-image: url(${({ image }) => image});  
  background-position: center; 
  background-repeat: no-repeat; 
  overflow: hidden;  
  background-size: cover; 
`;

export const AttractionPreviewCardContent = styled.div`
width: 100%;
height: 191.8px;
border-radius: 4px;
box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
background-color: rgba(0, 0, 0, 0.32);
padding: 22px 0px 0px 18.9px;
`

export const Text = styled.span`
  width: 230px;
  height: 24px;
  font-family: ${typo.ovFontFeature};
  font-size: 20px;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovWhite};
`;

export const NavLinkButton = styled.div`
  background-color: ${color.ovYellow};
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  color: ${color.ovWhite};
  cursor: pointer;
  font-family: ${typo.ovFontFeature};
  font-size: 18px;
  font-stretch: condensed;
  font-style: normal;
  font-weight: 300;
  letter-spacing: normal;
  margin: 12px 16px 8px 0px;
  padding: 3px 8.5px 4.2px 8.4px;;
  text-align: center;
  text-decoration: none;
  transition: all .1s;
  width: fit-content;
  &:hover, &:focus, &:active{
    text-decoration: none;
    transition: all .1s;
    color: ${color.ovWhite};
    background-color: ${color.ovBlue};
  }
`;