import * as types from './actions';

const initialState = {
  user: {},
  profile: {},
  contributions: false,
  isAuthenticated: localStorage.getItem('ovSessToken') ? true : false,
  hasUploadPhotoAgreement: localStorage.getItem('uploadPhotoAgreement') ? true : false,
  isFetching: false,
  isEditingUser: false,
  isEditingProfile: false,
  errorMessage: '',
  loginRedirect: false,
  loginBackRedirect: false,
  forgotPasswordSent: false,
  forgotTokenValid: false,
  resetPassMsg: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.REGISTER_REQUESTED:
    case types.LOGIN_REQUESTED:
    case types.FORGOT_PASSWORD_REQUESTED:
    case types.GET_USER_REQUESTED:
    case types.GET_PROFILE_REQUESTED:
    case types.EDIT_PROFILE_REQUESTED:
      return {
        ...state,
        isFetching: true,
        errorMessage: ''
      };

    case types.GET_USER_CONTRIBUTIONS_REQUESTED:
      return {
        ...state,
        isFetching: true,
        contributions: false,
        errorMessage: ''
      };

    case types.REGISTER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errorMessage: ''
      };

    case types.REQUEST_LOGIN_REDIRECT:
      return {
        ...state,
        loginRedirect: action.payload.redirect,
        loginBackRedirect: action.payload.backRedirect,
      };

    case types.RESET_LOGIN_REDIRECT:
      return {
        ...state,
        loginRedirect: false,
        loginBackRedirect: false,
      };

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isFetching: false
      };

    case types.FORGOT_PASSWORD_STATE_RESET:
      return {
        ...state,
        forgotPasswordSent: false,
      };

    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordSent: true,
        isFetching: false
      };

    case types.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isFetching: false
      };

    case types.GET_USER_CONTRIBUTIONS_SUCCESS:
      return {
        ...state,
        contributions: action.payload,
        isFetching: false
      };

    case types.EDIT_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isFetching: false,
        isEditingUser: false
      };

    case types.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        isFetching: false
      };

    case types.EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        isFetching: false,
        isEditingProfile: false
      };

    case types.LOGIN_FAILURE:
    case types.FORGOT_PASSWORD_FAILURE:
    case types.FORGOT_PASSWORD_VALIDATE_FAILURE:
    case types.FORGOT_PASSWORD_RESET_FAILURE:
    case types.REGISTER_FAILURE:
    case types.GET_USER_FAILURE:
    case types.GET_USER_CONTRIBUTIONS_FAILURE:
    case types.GET_PROFILE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      };

    case types.LOGOUT_SUBMITTED:
      return {
        user: {},
        profile: {},
        isAuthenticated: false,
        isFetching: false,
        isEditingUser: false,
        isEditingProfile: false,
        errorMessage: ''
      };

    case types.TOGGLE_EDIT_USER:
      return {
        ...state,
        isEditingUser: !state.isEditingUser
      };

    case types.TOGGLE_EDIT_PROFILE:
      return {
        ...state,
        isEditingProfile: !state.isEditingProfile
      };

    case types.LIST_USERS_REQUESTED:
      return {
        ...state,
        list: null,
        isFetchingList: true,
      };

    case types.LIST_USERS_SUCCESS:
      return {
        ...state,
        list: action.payload,
        isFetchingList: false,
      };

    case types.LIST_USERS_FAILURE:
      return {
        ...state,
        isFetchingList: false,
        errorMessage: action.payload
      };

    case types.TOGGLE_USER_VERIFIED_REQUESTED: {
      const { payload: { ID }} = action;
      const { list: src } = state;
      const list = src.map(user => user.ID === ID
        ? { ...user, is_verified: !user.is_verified }
        : user);

      return {
        ...state,
        list,
        isFetchingList: true,
      }
    }

    case types.TOGGLE_USER_VERIFIED_SUCCESS:
      return {
        ...state,
        isFetchingList: false,
      };

    case types.TOGGLE_USER_VERIFIED_FAILURE:
      return {
        ...state,
        isFetchingList: false,
        errorMessage: action.payload
      };

    case types.UPDATE_TOP_SCHOOLS_REQUESTED:
      return {
        ...state,
        isUpdatingTop: true,
      };

    case types.UPDATE_TOP_SCHOOLS_SUCCESS:
      return {
        ...state,
        isUpdatingTop: false,
      };

    case types.UPDATE_TOP_SCHOOLS_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
      };

    case types.SET_UPLOAD_PHOTOS_AGREEMENT:
      return {
        ...state,
        hasUploadPhotoAgreement: true,
      };

    case types.FORGOT_PASSWORD_VALIDATE_REQUESTED:
      return {
        ...state,
        isFetching: true,
        errorMessage: '',
        forgotTokenValid: false,
        resetPassMsg: ''
      };

    case types.FORGOT_PASSWORD_VALIDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        forgotTokenValid: true
      };

    case types.FORGOT_PASSWORD_RESET_REQUESTED:
      return {
        ...state,
        isFetching: true,
        errorMessage: '',
        resetPassMsg: ''
      };

    case types.FORGOT_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        resetPassMsg: action.payload
      };

    default:
      return state
  }
}
