import React from 'react';
import { Link } from 'react-router-dom';
// Styles stuff
import styled from 'styled-components';
import { color, typo, bp } from '../../styles';
import { Grid, Col, Row } from 'react-bootstrap';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as SnapChatIcon } from '../../assets/icons/snapchat.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';
// Components
import Logo from '../../components/Logo'

// Styled Components
const FooterContainer = styled.div`
	width: 100%;
	background-color: ${color.ovBlue};
	/* margin-top: 2rem; */
	color: ${color.ovWhite};
	display: flex;
	align-items: center;
	@media ${bp.tabletLandscape} {
		height: 120px;
	}
	@media ${bp.tabletLandUp} {
		height: 60px;
	}
	@media ${bp.phoneOnly} {
    height: 200px;
  }
`;
const FooterNav = styled.div`
	@media ${bp.tabletLandscape} {
		display: flex;
	    justify-content: center;
	    align-items: center;
	}
	@media ${bp.tabletLandUp} {
		display: block;
	}
	@media ${bp.phoneOnly} {
	  	display: block;
		margin-top: 2rem;
    }
`;
const SocialNav = styled.div`
	@media ${bp.tabletLandscape} {
		float: none;
		display: flex;
		justify-content: center;
		margin-top: 2rem;
	}
	@media ${bp.tabletLandUp} {
		float: right;
		margin-top: 0rem;
	}
	@media ${bp.phoneOnly} {
		float: none;
	  	display: flex;
		justify-content: center;
		margin-top: 0rem;
    }
`;
const IconContainer = styled.a`
	display: inline-block;
	width: 25px;
	height: 25px;
	margin: 0 1rem;
	opacity: 1;
	transition: all .1s;
	&:hover{
		opacity: .5;
		transition: all .1s;
	}
	@media ${bp.phoneOnly} {
		width: 45px;
		height: 45px;
		padding: 5px;
    }
`;
const StyledLogo = styled(Logo)`
	float: left;
	width: 28px;
	height: auto;
	path, polygon{
      fill: ${color.ovWhite};
    }
    @media ${bp.phoneOnly} {
      	float: none;
      	display: block;
      	margin: 0 auto;
    }
`;
const CopyRight = styled.span`
	display: inline-block;
	font-family: ${typo.ovFontFeature};
	font-weight: 900;
	float: left;
	font-size: 2rem;
	@media ${bp.phoneOnly} {
		display: none;
    }
`;
const CopyRightTwo = styled(CopyRight)`
	display: none;
	@media ${bp.phoneOnly} {
		display: block;
		float: none;
	  	display: flex;
		justify-content: center;
		font-size: 1.5rem;
		margin-top: 2rem;
    }
`;
const NavGroup = styled.div`
	float: left;
	padding: 7px 2rem 0;
	@media ${bp.phoneOnly} {
      	float: none;
      	display: flex;
		justify-content: center;
		padding: 0px 2rem 0;
    }
`;
const NavLink = styled(Link)`
	color: ${color.ovWhite};
	padding-right: 2rem;
	font-family: ${typo.ovFontSerif};
		&:last-child{
			padding-right: 0rem; 
		}
	&:hover, &:active, &:focus{
		text-decoration: underline;
		color: ${color.ovWhite};
	}
	@media ${bp.phoneOnly}{
		padding: 1.5rem 2rem 1.5rem 0;
	}
`;

// end of Styled Components


const FooterBar = () => {
	return(
		<FooterContainer>
			<Grid>
				<Row>
					<Col xs={12} sm={12} md={7} lg={6}>
						<FooterNav>
							<StyledLogo/>
							<CopyRight>© OfficialVisit Inc.</CopyRight>
							<NavGroup>
								<NavLink to="/terms">Terms</NavLink>
								<NavLink to="/privacy">Privacy</NavLink>
								<NavLink to="/contact">Contact us</NavLink>
								<NavLink to="/blog">Blog</NavLink>
							</NavGroup>
						</FooterNav>
					</Col>
					<Col xs={12} sm={12} md={5} lg={6}>
						<SocialNav>
							<IconContainer 
								href="https://twitter.com/officialvisit" 
								target="_blank"
							>
								<TwitterIcon/>
							</IconContainer>
							<IconContainer 
								href="https://instagram.com/officialvisit" 
								target="_blank"
							>
								<InstagramIcon/>
							</IconContainer>
							<IconContainer 
								href="https://snapchat.com/add/officialvisit" 
								target="_blank"
							>
								<SnapChatIcon/>
							</IconContainer>
							<IconContainer 
								href="https://facebook.com/officialvisit" 
								target="_blank"
							>
								<FacebookIcon/>
							</IconContainer>
						</SocialNav>
						<CopyRightTwo>© OfficialVisit Inc.</CopyRightTwo>
					</Col>
				</Row>
			</Grid>
		</FooterContainer>
	)
}

export default FooterBar