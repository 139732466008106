import React from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import CollegeSmallCard from '../../../components/College/CollegeSmallCard';

import {
  CollegeSmallCardRow,
  CollegeSmallCardTop,
  CollegeSmallCardTitleSmall,
  CollegeSmallCardValue,
  CollegeRankingPosition,
  CollegeRankingTotal,
  CollegeCompositeRating,
  CollegeSmallTableColumnRanking,
  CollegeRanking,
  CollegeSmallTableRow,
  CollegeSmallTable,
  CollegeSmallTableRight,
  CollegeSmallTableLeft
} from '../StyledUI';


const OutcomesStats = ({ outcomes, push }) => {

  function toLocaleString(value){
    return value ? value.toLocaleString('EN') : '';
  }
  
  return (
    <div>
      <CollegeSmallCardRow>

        <CollegeSmallCard>
          <CollegeSmallCardTop>
            
            <CollegeSmallCardTitleSmall>
              Median earnings 2 years after graduation
            </CollegeSmallCardTitleSmall>

            <CollegeSmallCardValue>
              <CollegeCompositeRating>${outcomes[0] ? toLocaleString(outcomes[0].data.median_earnings_2_years) : "-"}</CollegeCompositeRating>
              <CollegeRanking onClick={() => push('/highest-postgraduate-earnings')}>
                <CollegeRankingPosition>{outcomes[0] ? outcomes[0].data.median_earnings_2_years_rank : "-"}</CollegeRankingPosition>
                <CollegeRankingTotal> of 130</CollegeRankingTotal>
              </CollegeRanking>
            </CollegeSmallCardValue>

          </CollegeSmallCardTop>
        </CollegeSmallCard>

        <CollegeSmallCard>
          <CollegeSmallCardTop>
            
            <CollegeSmallCardTitleSmall>
              Median earnings 6 years after graduation
            </CollegeSmallCardTitleSmall>

            <CollegeSmallCardValue>
              <CollegeCompositeRating>${outcomes[0] ? toLocaleString(outcomes[0].data.median_earnings) : "-"}</CollegeCompositeRating>
              <CollegeRanking onClick={() => push('/highest-postgraduate-earnings')}>
                <CollegeRankingPosition>{outcomes[0] ? outcomes[0].data.median_earnings_rank : "-"}</CollegeRankingPosition>
                <CollegeRankingTotal> of 130</CollegeRankingTotal>
              </CollegeRanking>
            </CollegeSmallCardValue>

          </CollegeSmallCardTop>
        </CollegeSmallCard>

        <CollegeSmallCard>
          <CollegeSmallCardTop>
            
            <CollegeSmallCardTitleSmall>
              Graduation rate 
            </CollegeSmallCardTitleSmall>

            <CollegeSmallCardValue>
              <CollegeCompositeRating>{outcomes[0] ? outcomes[0].data.graduation_rate : "-"}%</CollegeCompositeRating>
            </CollegeSmallCardValue>

          </CollegeSmallCardTop>
        </CollegeSmallCard>

        <CollegeSmallTableRow>
          <div>

            <CollegeSmallCardTitleSmall>
              Student debt
            </CollegeSmallCardTitleSmall>

            <CollegeSmallTable>
              <CollegeSmallTableLeft>Average loan amount</CollegeSmallTableLeft>
              <CollegeSmallTableRight>${outcomes[0] ? toLocaleString(outcomes[0].data.average_loan) : "-"}</CollegeSmallTableRight>
            </CollegeSmallTable>

            <CollegeSmallTable>
              <CollegeSmallTableLeft>Loan default rate</CollegeSmallTableLeft>
              <CollegeSmallTableRight>
                {outcomes[0] ? outcomes[0].data.loan_default_rate : "-"}%
              </CollegeSmallTableRight>
              <CollegeSmallTableColumnRanking>
                <CollegeRankingPosition>{outcomes[0] ? outcomes[0].data.loan_default_rate_rank : "-"}</CollegeRankingPosition>
                <CollegeRankingTotal> of 130</CollegeRankingTotal>
              </CollegeSmallTableColumnRanking>
            </CollegeSmallTable>

          </div>

        </CollegeSmallTableRow>

      </CollegeSmallCardRow>
    </div>
  )
};


const mapDispatchToProps = dispatch => bindActionCreators({
  push
}, dispatch)

export default connect(null, mapDispatchToProps)(OutcomesStats)