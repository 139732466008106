import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { reduxForm, formValueSelector } from 'redux-form';

import { PageContainer, FloatRightCloseButton } from '../../components';
import ReviewTitle from './ReviewTitle';
import ReviewItem from './ReviewItem';

// Imported Styles
import {
  ReviewContextHeading,
  CloseBar,
  OVButtonRight,
  PageContent,
  CenterContent
} from './Styled';

import Scroll from 'react-scroll';
import Spinner from "react-spinkit";
const Element = Scroll.Element;

const useOnMount = (...gets) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      gets.forEach(fn => fn());
      didMountRef.current = true;
    }
  });
}

let AttractionReview = ({
  push,
  match,
  isAuthenticated,
  requestLogin,
  questions,
  setAnswer,
  answers,
  setComment,
  isCurrentCollegeStudent,
  isSubmittingReview,
  userRole,
  setTitle,
  setupReviewQuestions,
  submitAttractionReviewContribution,
  titleValue,
  college,
  getCollege,
  getAttractionForReview,
  attraction
}) =>{

  useOnMount(getCollege, getAttractionForReview);

  useEffect(() => {
    if (!isAuthenticated) {
      return requestLogin(match.url);
    }
    setupReviewQuestions(isCurrentCollegeStudent, userRole);
  }, [isCurrentCollegeStudent]);

  const reviewListItems = questions.map((q,i) => {
    return <ReviewItem
        key={i + '_'+ q.key}
        index={q.index}
        context={q.context}
        ratingKey={q.rating_key}
        text={q.text}
        values={[1,2,3,4,5]}
        onSelect={setAnswer}
        selectedValue={answers[q.index].rating}
        onInputChange={setComment}
        inputValue={answers[q.index].comment || ""}
      />
  });

  return (
    <div>
      <Element name="top" />
      <CloseBar>
        <FloatRightCloseButton onClick={() => push(`/college/${college.url_slug}/attractions/${attraction.ID}`)}/>
      </CloseBar>
      {!isSubmittingReview && (<form>
        <PageContainer>
            <PageContent>
              <ReviewContextHeading>{attraction && attraction.title} Review</ReviewContextHeading>
            </PageContent>
            <ReviewTitle />
            <PageContent>
              {reviewListItems}
            </PageContent>
            <PageContent>
              <OVButtonRight onClick={()=> {
                setTitle(titleValue);
                submitAttractionReviewContribution(attraction && attraction.ID)
                }}>Submit</OVButtonRight>
              <OVButtonRight onClick={() => push(`/college/${college.url_slug}/attractions/${attraction.ID}`)}>Cancel</OVButtonRight>
            </PageContent>
        </PageContainer>
      </form>)}
      {isSubmittingReview  && (<PageContainer>
        <PageContent>
          <ReviewContextHeading>Review is submitting...</ReviewContextHeading>
          <CenterContent>
            <Spinner fadeIn="none" name="cube-grid" />
          </CenterContent>
        </PageContent>
      </PageContainer>)}
    </div>
  )
}

AttractionReview.propTypes = {
  push: PropTypes.func.isRequired,
  requestLogin: PropTypes.func.isRequired
};

AttractionReview = reduxForm({
  // a unique name for the form
  form: 'AttractionReviewForm'
})(AttractionReview)

const selector = formValueSelector('AttractionReviewForm');
// Gets the college input value from Step 1
AttractionReview = connect(state => {
  const titleValue = selector(state, 'title');
  return {
    titleValue
  };
})(AttractionReview);

export default AttractionReview
