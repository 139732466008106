import React from 'react';
import styled from 'styled-components';
import { X } from 'styled-icons/feather/X';
import { color } from '../styles'


const AbsCloseButton = styled(X)`
  position: absolute;
  display: inline;
  left: 6px;
  top: 6px;
  color: ${color.ovBlue};
  width: 25px;
  cursor: pointer;
  z-index: 1;
`;

export default ({ onClick }) => <AbsCloseButton onClick={onClick} />;