import React from 'react';
import { ReactComponent as Checkmark } from '../../../assets/icons/checkmark.svg';

import {
  FilterHeaderBox,
  FilterTitle,
  FilterTitlePlus,
  FilterClose,
  checkmarkStyle
} from './Styled'

const FilterHeader = ({
  name,
  show,
  toggleShow,
  change
}) => {
  return(
    <FilterHeaderBox>
      <FilterTitle show={show} onClick={()=> toggleShow(!show)}>
        <FilterTitlePlus data-show={show}>
          {change ? <Checkmark style={checkmarkStyle}/> : "+"}
        </FilterTitlePlus>
        {name}
      </FilterTitle>
      <FilterClose show={show} onClick={()=> toggleShow(!show)}>Close</FilterClose>
    </FilterHeaderBox>
  )
}

export default FilterHeader;
