import { color, bp, typo } from '../../../styles'
import styled from 'styled-components';
import { Camera } from 'styled-icons/fa-solid/Camera';
import { UploadPhotosButtonContainer } from "../../UploadPhotosButtonWithAgreement/Styled";

export const AttractionGalleryBox = styled.div`
	position: relative;
	  ${UploadPhotosButtonContainer} {
    margin-bottom: 10px;
    position: absolute;
    top: 11px;
    left: 15px;
  }
`;

export const AttractionImagesBox = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	min-height: 219px;
	margin-bottom: 18px;
	grid-column-gap: 2px;
  @media ${bp.phoneOnly} {
    grid-template-columns: 100% 100% 100%;
    overflow: scroll;
  }
`;

export const AttractionImage = styled.div`
	width: 100%;
	background-color: ${color.ovBlue};
	border: 1px solid #707070;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${props => `url("${props.bgUrl}")`};
  cursor: pointer;
`;

export const AttractionImagesTotal = styled.div`
	position: absolute;
	bottom: 10px;
	left: 17px;
	font-family: ${typo.ovFontFeature};
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${color.ovWhite};
  text-transform: uppercase;
  transition: all .1s;
  cursor: pointer;
  opacity: 1;
  &:hover{
    opacity: .5;
    transition: all .1s;
  }
`;

export const StyledCamera = styled(Camera)`
  width: 20px;
  color: ${color.ovWhite};
  margin-right: 8px;
  position: relative;
  top: 0px;
`;
