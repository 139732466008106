import React from 'react';
import PropTypes from 'prop-types';
import CollegeLogo from '../../components/CollegeLogo';

import {
  ResultList,
  ResultItem,
  SearchItemIcon,
  StyledUser,
  SearchItemMeta,
  SearchTitle,
  SearchType,
  OVButton,
  ImgStyle
} from './Styled'

const ResultsPage = ({ list, push, onLoadNext, current, pages }) => {
  return (
    <div>
    {list.length !== 0 &&
      <ResultList>
        {list.length && list.map((item) => (
          <ResultItem
            key={`result-item-${item.type}-${item.ID}`}
            onClick={() => push(`/${item.type}/${item.url_slug}`)}
            width={1}
          >
            <SearchItemIcon>
              {item.type === 'coach'
                ? <StyledUser />
                : <CollegeLogo style={ImgStyle} name={item.name}/>
              }
            </SearchItemIcon>
            <SearchItemMeta>
              <SearchTitle>{item.type === 'coach' ? item.display_name : item.name}</SearchTitle>
              <SearchType>{item.type === 'coach' ? 'Coach' : 'College'}</SearchType>
            </SearchItemMeta>
          </ResultItem>
        ))}

        {current < pages && (
          <OVButton onClick={onLoadNext}>Load More</OVButton>
        )}
      </ResultList>
    }
    </div>
  );
}

ResultsPage.propTypes = {
  list: PropTypes.array.isRequired,
  current: PropTypes.number.isRequired, // Current page, starts with 0
  pages: PropTypes.number.isRequired,  // Total amount of pages, to check if the button has to be shown
  onLoadNext: PropTypes.func.isRequired, // To trigger the load more data
  push: PropTypes.func.isRequired,
};

export default ResultsPage;
