import styled from 'styled-components';
import { color, typo, bp } from '../../styles'
import { Heading } from 'rebass';
import { ReactComponent as CheckCircle } from '../../assets/icons/verified.svg';

// Styled components
export const PageContent = styled.div`
  grid-column: span 12;
  background-color: ${color.ovWhite};
  border: 1px solid ${color.ovGrey};
  padding: 25px;
  margin: 10px 0;
`;
export const TitleContainer = styled.div`
  grid-column: span 12;
  grid-template-columns: auto 180px;
  display: grid;
  grid-row: 1;
  span{
    text-align: center;
  }
  @media ${bp.phoneOnly} {
     grid-template-columns: auto 70px;
  }
`;
export const AboutHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: .8;
  letter-spacing: normal;
  text-align: left;
  padding: 0 10px;
  @media ${bp.phoneOnly} {
    font-size: 1.8rem;
  }
`;
export const AboutHr = styled.div`
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid ${color.ovGrey};
  margin: 5px 0 0;
  padding: 0;
  grid-row: 2;
  grid-column-end: 3;
  grid-column-start: 1;
`;
export const SearchResultContainer = styled.div`
  margin-top: 1rem;
`;
export const SearchResultItem = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: 140px auto 150px;
  grid-gap: 1rem;
  background-color: ${color.ovWhite};
  transition: all .1s;
  @media ${bp.phoneOnly} {
    grid-template-columns: auto auto;
    grid-template-rows: 1fr 1fr;
    padding-bottom: 4rem;
  }
`;
export const UserHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: .8;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 1rem;
`;

export const UserInfo = styled.div`
  
`;
export const TitleHeading = styled.span`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontSerif};
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: .8;
  letter-spacing: normal;
  text-align: left;
`;
export const UserProfilePic = styled.div`
  padding: 2rem;
  width: 140px;
  height: 140px;
  @media ${bp.phoneOnly} {
    width: 70px;
    height: 70px;
    padding: 0;
  }
`;
export const UserProfileMeta = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
  @media ${bp.phoneOnly} {
    grid-column-start: 1;
  }
`;
export const UserProfileVerified = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledCheck = styled(CheckCircle)`
  width: 27px;
  color: ${color.ovBlack};
  margin-right: 8px;
  position: relative;
  top: 4px;
  opacity: .5;
  transform: scale(1);
  transition: all .1s;
  cursor: pointer;
  &:hover{
    transform: scale(1.2);
    transition: all .1s;
  }
  ${props => (props.verified &&
    `opacity: 1; transition: all .1s;`
  )}
`;
export const ImgStyle = {
  objectFit: 'cover',
  height: '100%',
  width: '100%',
  imageOrientation: 'from-image',
}
export const LoadMoreButtonContainer = styled.div`
  grid-column: 1/-1;
  text-align: center;
  margin: 2rem 0;
`;
export const LoadMoreButton = styled.button`
  height: 59px;
  border-radius: 10px;
  border: solid 1px ${color.ovBlue};
  background-color: ${color.ovWhite};
  transition: all .1s;
  text-align: center;
  padding: 8px 30px;
  position: relative;
  margin: 0 0 17px 0;
  &:hover{
    background-color: ${color.ovBlue};
    color: ${color.ovWhite};
    transition: all .1s;
  }
`;

export const LoadMoreButtonLabel = styled.p`
  font-family: ${typo.ovFontFeature};
  font-size: 25px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: center;
  @media ${bp.phoneOnly} {
    font-size: 22px;
  }
`;
// end of Styled Components