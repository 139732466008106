import React, { Fragment } from 'react';
import { Category } from './Styled'

const Categories = ({
	categories,
}) => (
	<Fragment>
		{categories.map((category, i) => (
			<Category key={`${category}-${i}`}>
				{category.name}
			</Category>
		))}
	</Fragment>
);

export default Categories;