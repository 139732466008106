import styled from 'styled-components';
import { color, typo, bp } from '../../styles';
import { Heading } from 'rebass';

export const Container = styled.div`
	@media ${bp.phoneOnly} {
		margin-top: -2rem;
	}
`;

export const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 50px;

	@media ${bp.phoneOnly} {
		padding-bottom: 15px;
	}
`;

export const UserNameContainer = styled(Heading)`
	font-size: 50px;
	line-height: 1;
  font-weight: 300;
  font-style: normal;
  font-stretch: condensed;
  letter-spacing: normal;
  text-align: left;
  font-family: ${typo.ovFontFeature};
	color: ${color.ovBlue};
	@media ${bp.phoneOnly} {
		font-size: 2rem;
	}
`;

export const ListManager = styled.div`

`;

export const SizeSelector = styled.div`
	position: relative;
	height: 4rem;
	width: 15rem;
	border-radius: .5rem;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #707070;
	background-color: #ffffff;
  display: flex;
	justify-content: space-between;
	align-items: center;

	@media ${bp.phoneOnly} {
		height: 5.4rem;
		width: 12rem;
	}

	> label {
		font-weight: 600;
		font-family: Molde;
		font-size: 1.6rem;
		width: 50%;
		margin: 0;
		padding: 0 1rem;
		color: ${color.ovGrey};
		border-right: 1px solid ${color.ovGrey};
		
		@media ${bp.phoneOnly} {
			font-size: 1.5rem;
			padding: 0 .5rem;
		}
	}

	> select {
		display: block;
		width: 50%;
		font-family: Molde;
		font-size: 1.6rem;
		font-weight: 600;
		padding-left: 3rem;
		appearance: none;
		background-color: transparent;
		border: none;
		z-index: 1;
		outline: none;
		height: 100%;
		@media ${bp.phoneOnly} {
			font-size: 2rem;
			padding-left: .5rem;
		}
	}

	> svg {
		z-index: 0;
		position: absolute;
		right: .5rem;

		@media ${bp.phoneOnly} {
			right: 5px;
		}
	}
`;