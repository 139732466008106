import React from 'react';
import styled from 'styled-components'; // Styled components
import { Heading } from 'rebass';
import { color, typo } from '../../styles';
import SubmitButton from '../../components/SubmitButton'
import { UserCircle } from 'styled-icons/fa-solid/UserCircle';

const RegisterHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
  line-height: 24px;
`;

const ProfileIcon = styled.div`
  width: 200px;
  height: 200px;
  padding: 10px;
  margin-bottom: -20px;
  border-radius: 100px;
  border: 2px solid ${color.ovBlue};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: auto;

  ${UserCircle} {
    margin: -2px;
    height: 180px;
    width: 180px;
  }
`;

const CenterPane = styled.div`
  grid-column: span 12;
  padding: 88px 0;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  margin-top: 30px;
`;

const P = styled.p`
  font-family: ${typo.ovFontFeature};
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: ${color.ovGreyDark};
`;

const SmallLink = styled.a`
  cursor: pointer;
  color: ${color.ovBlue};
  &:hover{
    color: ${color.ovGrey};
  }
`;

const nextCaption = next => next
  ? `${next === '/profile' ? 'Upload' : 'Next'}`
  : 'Complete';

const skipCaption = next => `${next === '/profile' ? 'Cancel' : 'Or Add later'}`;

const RegisterPictureForm = ({ nextPath, onSkip, onSubmit, onToggleDropZone, picture }) => (
  <CenterPane>
    <div className="flex items-center mv4 w-100">
      <RegisterHeading as="h1">Upload Profile Picture</RegisterHeading>
      <ProfileIcon
        onClick={() => onToggleDropZone(true)}
        style={{
          backgroundImage: `url(${picture})`,
        }}
      >
        { !picture && <UserCircle /> }
      </ProfileIcon>

      <ButtonsContainer>
        {picture && (
          <SubmitButton type="button" onClick={onSubmit}>{nextCaption(nextPath)}</SubmitButton>
        )}
        <P>
          <SmallLink href='#' onClick={onSkip}>{skipCaption(nextPath)}</SmallLink>
        </P>
      </ButtonsContainer>
    </div>
  </CenterPane>
);

export default RegisterPictureForm;