import { FILES_DROP } from '../upload';
import { LOGOUT_SUBMITTED } from '../user';
import * as types from './actionsTypes';
// Capture Redux form actions
const CHANGE = '@@redux-form/CHANGE';

const initialState = {
  title: '',
  description: '',
  contrib_items: [],
  ready_contrib_items: [],
  content_tags: [],
  modalOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {

    case FILES_DROP:
      return {
        ...state,
        // adding which file in the accepted array maps to this contrib_item
        contrib_items: [...state.contrib_items, ...action.payload.accepted.map((image) => ({
          // accommodating for current items in contrib_items array
          description: '',
          contributable_id: action.payload.contributable_id,
          contributable_type: action.payload.contributable_type,
          selected_content_tags: []
        }))]
      }

    case types.OPEN_MODAL:
      return {
        ...state,
        modalOpen: true
      }

    case types.MODAL_CLOSE_AND_CONTRIBUTION_RESET:
      return {
        ...state,
        modalOpen: false,
        contrib_items: [],
        ready_contrib_items: []
      }

    case types.NEW_CONTRIBUTION:
      return {
        ...state,
        modalOpen: false,
        contrib_items: [],
        ready_contrib_items: []
      }

    case types.CONTRIB_ITEM_REMOVE:
      return {
        ...state,
        contrib_items: [
          ...state.contrib_items.slice(0, action.payload),
          ...state.contrib_items.slice(action.payload + 1)
        ]
      }

    case types.CONTRIB_ITEM_DESC_UPDATE:
      return {
        ...state,
        contrib_items: state.contrib_items.map((item, i) => i === action.payload.index ? {
          ...item,
          description: action.payload.value
        } : item)
      }

    case CHANGE:
      if (action.meta.form === 'contribute' && action.meta.field === 'title') {
        return {
          ...state,
          title: action.payload
        }
      }
      if (action.meta.form === 'contribute' && action.meta.field === 'description') {
        return {
          ...state,
          description: action.payload
        }
      }
      return state;

    case types.CONTRIBUTION_FILE_UPLOAD_FINISH:
      return {
        ...state,
        // add the corresponding contrib_item to ready_contrib_items and add the new content id and value
        ready_contrib_items: [...state.ready_contrib_items, {
          ...state.contrib_items[action.payload.index],
          value_id: action.payload.content.ID,
          value_type: 'contents'
        }]
      }

    case types.ATTRACTION_CONTRIBUTION_SUCCESS:
      return {
        ...state,
        // todo: update attraction contents, contents.contrib_items do not have contribution_referer
      };

    case types.LIST_CONTENT_TAGS:
      return {
        ...state,
        content_tags: action.payload
      }

    case types.CONTENT_TAG_SELECT:
      return {
        ...state,
        contrib_items: state.contrib_items.map((item, i) => i === action.payload.contribItemIndex ?
          { ...item, selected_content_tags: [...item.selected_content_tags, action.payload.contentTag] }
          : item
        )
      }

    case types.CONTENT_TAG_DESELECT:
      return {
        ...state,
        contrib_items: state.contrib_items.map((item, i) => i === action.payload.contribItemIndex ?
          {
            ...item, selected_content_tags: [
              ...item.selected_content_tags.slice(0, action.payload.index),
              ...item.selected_content_tags.slice(action.payload.index + 1)
            ]
          }
          : item
        )
      }

    case LOGOUT_SUBMITTED:
      return initialState;

    default:
      return state
  }
}