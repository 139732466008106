import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import { FullPageContainer } from '../../components';
import CollegeHeader from '../../components/CollegeHeader';
import CollegeCard from '../../components/College/CollegeCard';

import {
  MajorsContainer,
  MajorsListContainer,
  MajorLabel,
  SpecializationLabel,
  MajorMore,
  MajorsPageBox,
  MajorsHeading
} from './Styled';

const Department = ({ subjects, department }) => {
  const subjectKeys = Object
    .keys(subjects)
    .sort();

  return (
    <Fragment>
      <MajorsHeading>{department}</MajorsHeading>
      <MajorsListContainer>
        {subjectKeys.map(subject => (
          <Subject 
            key={`subject-${subject}`}
            subject={subject}
            specializations={subjects[subject]}
          />
        ))}
      </MajorsListContainer>
    </Fragment>
  );
};

const Subject = ({ subject, specializations }) => {
  const specializationKeys = Object
    .keys(specializations)
    .filter(spec => !spec.includes('N/A'))
    .sort();

  const [more, toggleMore] = useState(false);
  const degrees = Object
    .keys(specializations)
    .reduce((memo, key) => memo
      .includes(specializations[key])
        ? memo
        : `${memo && `${memo}, `}${specializations[key]}`
      , '');

  return (
    <div>
      <MajorLabel>
        <Fragment>
          {`${subject} ${degrees && `(${degrees})`}`}
          { 
            specializationKeys.length > 1 
            && (
            <MajorMore onClick={() => toggleMore(!more)}>
              {!more ? `▼` : `▲`}
            </MajorMore>
            )
          }
        </Fragment>
      </MajorLabel>

      { more
        && (
          <div>
            {specializationKeys.map(spec => (
              <SpecializationLabel key={spec}>
                {spec}
              </SpecializationLabel>
            ))}
          </div>
        )
      }
    </div>
  )
}

const useGetCollege = (getCollegeReviewsAndMajorsIfEmpty, college) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      getCollegeReviewsAndMajorsIfEmpty();
      didMountRef.current = true;
    }
  }, [college]);
}

const MajorsDetails = ({
  isEditing,
  toggleEdit,
  editCollege,

  role,
  college,
  majors,
  isFetchingCollege,
  averageReview,

  push,
  getCollegeReviewsAndMajorsIfEmpty,
}) => {

  useGetCollege(getCollegeReviewsAndMajorsIfEmpty, college);

  const departments = majors && Object.keys(majors).sort();

  const metaTitle = college.name + ' Majors - OfficialVisit';
  const metaDescription = 'Discover ' + college.name + ' majors, concentrations, and areas of study. Are any the right fit?';

  return (
    <div>
      {college.name && <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>}

      <CollegeHeader
        isFetching={isFetchingCollege}
        isEditing={isEditing}
        toggleEdit={toggleEdit}
        editCollege={editCollege}

        college={college}
        averageReview={averageReview}

        role={role}
        push={push}
      />

      <FullPageContainer>
        <MajorsPageBox>
          <CollegeCard collegeCardTitle="majors">
            <MajorsContainer>
              {departments && departments.map( department => (
                <Department 
                  key={`department-${department}`}
                  department={department}
                  subjects={majors[department]}
                />
              ))}
            </MajorsContainer>
          </CollegeCard>
        </MajorsPageBox>
      </FullPageContainer>
    </div>
  )
}

MajorsDetails.propTypes = {
  college: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isFetchingCollege: PropTypes.bool.isRequired,
  editCollege: PropTypes.func.isRequired,
  isFetchingReviews: PropTypes.bool.isRequired,
  getCollegeReviewsAndMajorsIfEmpty: PropTypes.func.isRequired,
  reviews: PropTypes.array,
  averageReview: PropTypes.object.isRequired
};

export default MajorsDetails;
