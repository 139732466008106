import styled from 'styled-components';
import { bp, color, typo } from '../../styles';
import { ReactComponent as OVPlus } from '../../assets/icons/ov_plus.svg';

export const TwelvePartPageContent = styled.div`
  grid-column: span 12;
  @media ${bp.tabletLandUp} {
    grid-column: span 12;
  }
`;

export const EighthPartPageContent = styled.div`
  grid-column: span 12;
  @media ${bp.tabletLandUp} {
    grid-column: span 8;
  }
`;

export const ThirdPartPageContent = styled.div`
  grid-column: span 12;
  @media ${bp.tabletLandUp} {
    grid-column: span 3;
  }
`;

export const QuarterPartPageContent = styled.div`
  grid-column: span 12;
  @media ${bp.tabletLandUp} {
    grid-column: span 4;
  }
`;

export const CollegeTable = styled.div`
  margin: 0 -14px;
  border-top: solid 1px #95989a;
`;

export const CollegeTableItem = styled.div`
  padding: 18px 24px;
  border-bottom: solid 1px #95989a;
  &:last-child{
    border-bottom: none;
  }
`;

export const CollegeTableTitle = styled.div`
  font-size: 16px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
  margin-bottom: 4px;
`;

export const CollegeTableRow = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  align-self: start;
  @media ${bp.phoneOnly} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto; 
  }
`;

export const CollegeTableScoresRowBox = styled(CollegeTableRow)`
  padding-top: 26px;
  @media ${bp.phoneOnly} {
    grid-template-columns: 60% 40%;
    grid-template-rows: 1fr;
  }
`;

export const TableScoresBarRowBox = styled.div`
  width: 100%;
  height: 18px;
  border-radius: 2px;
  border: solid 1px ${color.ovBlue};
  display: grid;
  grid-template-columns: repeat(160, 1fr);
  grid-template-rows: 1fr;
  margin: 6px 0 19px 0;
`;

export const TableScoresSatBar = styled(TableScoresBarRowBox)`
  grid-template-columns: repeat(997, 1fr);
`;
export const TableScoresActBar = styled(TableScoresBarRowBox)`
  grid-template-columns: repeat(35, 1fr);
`;

export const TableScoresBarValue = styled.div`
  grid-column-start: ${props => props.value1};
  grid-column-end: ${props => props.value2};
  background-color: ${color.ovBlue};
  height: 100%;
  border: 1px solid ${color.ovBlue};
`;

export const ReviewsPartPageContent = styled(QuarterPartPageContent)`
  position: sticky;
  top: 85px;
  margin-bottom: 2rem;
  margin-top: -17px;
  @media ${bp.phoneOnly} {
    grid-column: span 12;
    position: relative;
    top: 0;
    grid-row: span 12;
    
  }
  @media ${bp.tabletLandUp} {
    margin-bottom: 0rem;
    margin-top: 0px;
  }
`;

export const CollegeSmallCardRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 17px;
  grid-row-gap: 17px;
  margin-bottom: 17px;
  @media ${bp.phoneOnly} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    margin-bottom: 0;
  }
`;

export const CollegeSmallTableRow = styled(CollegeSmallCardRow)`
  grid-template-columns: 1fr;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(0, 0, 0, .1);
  padding: 11px 7px;
  margin-bottom: 0px;
  @media ${bp.phoneOnly} {
    &:last-child{
      margin-bottom: 17px;
    }
  }
`;

export const CollegeSmallTable = styled.div`
  grid-template-columns: 1fr 1fr;
  padding: 10px 0;
  border-bottom: 1px solid ${color.ovBlack};
  display: grid;
  color: ${color.ovBlack};
  &:last-child{
    border-bottom: none;
  }
`;

export const CollegeSmallTableLeft = styled.div`
  font-size: 16px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  font-family: ${typo.ovFontFeature};
  color: ${color.ovGrey};
`;
export const CollegeSmallTableRight = styled(CollegeSmallTableLeft)`
  text-align: right;
  color: ${color.ovBlack};
`;

export const CollegeSmallCardTop = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`;

export const CollegeSmallCardTitleSmall = styled.span`
  font-family: ${typo.ovFontFeature};
  font-size: 16px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlack};
  display: flex;
  align-items: flex-start;
`;

export const CollegeSmallCardTitleBig = styled.span`
  font-size: 25px;
  font-family: ${typo.ovFontFeature};
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlack};
`;

export const CollegeSmallCardTitleButtonLeft = styled.span`
  font-family: ${typo.ovFontFeature};
  font-size: 26px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovWhite};
  line-height: .8;
`;

export const CollegeSmallCardTitleButtonRight = styled(CollegeSmallCardTitleButtonLeft)`
  text-align: right;
`;
 
export const CollegeSmallCardValue = styled.div`
  text-align: right;
`;

export const CollegeTableColumn = styled.div`
  font-family: ${typo.ovFontSerif};
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
`;



export const CollegeTableColumnValue = styled(CollegeTableColumn)`
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${color.ovBlack};
  a{
    color: ${color.ovBlueLight};
  }
`;

export const CollegeTableColumnLabel = styled(CollegeTableColumn)`
  color: ${color.ovGreyDarker};
`;

export const CollegeCompositeRating = styled.div`
  font-family: ${typo.ovFontFeature};
  color: ${color.ovBlue};
  font-size: 26px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  line-height: 0.8;
`;



export const CollegeRankingPosition = styled.span`
  font-family: ${typo.ovFontFeature};  
  font-size: 16px;
  font-weight: 600;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
`;

export const CollegeRankingTotal = styled.span`
  color: ${color.ovGrey};
  font-family: ${typo.ovFontFeature};  
  font-size: 16px;
  font-weight: 600;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
`;

export const CollegeRanking = styled.div`
  text-align: right;
  padding: 5px 0 0 0;
  cursor: ${props => props.onClick ? "pointer" : "default" };
  ${CollegeRankingPosition}{
    font-size: 14px;
    color: ${props => props.onClick ? color.ovBlue : color.ovGrey };
  }
  ${CollegeRankingTotal}{
    font-size: 14px;
    &:hover{
      color: ${props => props.onClick ? color.ovBlue : color.ovGrey };
    }
  }
`;

export const CollegeTableColumnRanking = styled.div`
  grid-column-start: 2;
  text-align: left;
  padding: 0;
  margin-top: -2px;
  margin-bottom: 3px;
  cursor: ${props => props.onClick ? "pointer" : "default" };
  ${CollegeRankingPosition}{
    font-size: 14px;
    color: ${props => props.onClick ? color.ovBlue : color.ovGrey };
  }
  ${CollegeRankingTotal}{
    font-size: 14px;
    &:hover{
      color: ${props => props.onClick ? color.ovBlue : color.ovGrey };
    }
  }
`;

export const CollegeSmallTableColumnRanking = styled(CollegeTableColumnRanking)`
  text-align: right;
`;

export const CollegeItemLogo = styled.div`
  background: ${props => `url("${props.bgUrl}") no-repeat center`};
  height: 44px;
  width: 44px;
  background-size: cover;
  float: right;
  margin: -11px 4px;
`;

export const CollegeSmallCardContent = styled.div``;

export const YearUnit = styled.span`
  font-size: 10px; 
`;

export const OVplusIcon = styled(OVPlus)`
  width:31px;
  height: auto;
  margin-top: -7px;
`;


export const LoadMoreButtonLabel = styled.p`
  font-family: ${typo.ovFontFeature};
  font-size: 15px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding: 0 10px;
`;

export const LoadMoreButton = styled.button`
  border-radius: 6px;
  border: solid 1px ${color.ovBlue};
  background-color: ${color.ovWhite};
  transition: all .1s;
  text-align: center;
  position: relative;
  color: ${color.ovBlue};
  &:hover{
    background-color: ${color.ovBlue};
    color: ${color.ovWhite};
    transition: all .1s;
  }
`;

export const LoadMoreButtonContainer = styled.div`
  grid-column: 1/-1;
  text-align: center;
`;