import React, { useEffect } from 'react';
import { Field, reduxForm, clearFields } from 'redux-form';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'; 
import styled from 'styled-components';
import { color, typo } from '../../styles'

const InputContainer = styled.div`
  width: 100%;
`;

let StyledInput = styled.input`
  display: inline-block;
  width: 100%;
  height: 76px;
  border: solid 2px ${color.ovGrey};
  padding: 22px 31px;
  background-color: ${color.ovWhite};
  font-family: ${typo.ovFontFeature};
  font-size: 25px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovGrey};
  border-radius: 10px;
  outline: none;
  &::placeholder{
    font-family: ${typo.ovFontFeature};
    font-size: 25px;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: ${color.ovGrey};
  }
  &:active, &:focus, &.filled{
    border-radius: 10px;
    border: solid 2px ${color.ovBlue};
  }
`;


const inputField = ({ input, label, type, meta: { dirty, touched, active, error } }) => (
  <InputContainer >
    <StyledInput {...input} placeholder={label} type={type} className={dirty && 'filled'} autoComplete="off"/>
    {touched && !active && error && <div>{error}</div>}
  </InputContainer>
);

let CollegeCoachSearchFilterForm = ({ clearNavSearch }) => {
  useEffect(() => {
    clearNavSearch();
  });

  return(
    <form>
        <Field
          type="text"
          name="text"
          component={inputField}
          label="Search colleges or coaches"
        />
    </form>
  )
}

CollegeCoachSearchFilterForm = reduxForm({
  // a unique name for the form
  form: 'collegeCoachSearchFilter',
  enableReinitialize: true
})(CollegeCoachSearchFilterForm)

const mapStateToProps = (state) => ({
  initialValues: (state.form.collegeSearchNavbarFilter && state.form.collegeSearchNavbarFilter.values)
    || (state.form.collegeCoachSearchFilter && state.form.collegeCoachSearchFilter.values)
})

const mapDispatchToProps = dispatch => bindActionCreators({
  clearNavSearch: () => clearFields('collegeSearchNavbarFilter', false, false, 'text'),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CollegeCoachSearchFilterForm)
