import React, {Fragment, useCallback} from 'react';
import AverageReview from '../../reviews/AverageReview';

// Styles
import { 
  LocationsCardCointainer,
  LocationsCardTitile,
	LocationsCardFilters,
	ButtonsContainer,
	StyledEdit,
	StyledRemove,
  StyledButton,
	Buttons,
} from './Styled';

const LocationsCard = ({ 
	title, about, average_review, onClick, tags, onMouseEnter, onMouseLeave, role, onEdit, onDelete,
}) => {
	const tagsLength = tags && tags.length;
	// hack for prevent error while attraction is null
	if (!average_review) { average_review = {} }

	const handleEdit = useCallback((e) => {
		e.stopPropagation();
		onEdit();
	}, [onEdit])

	const handleDelete = useCallback((e) => {
		e.stopPropagation();
		onDelete();
	}, [onDelete])
	
	return(
		<Fragment>
	  <LocationsCardCointainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick} >
			{ role === 'admin' &&
				<ButtonsContainer>
					<Buttons>
						<StyledButton onClick={handleEdit} ><StyledEdit /></StyledButton>
						<StyledButton onClick={handleDelete}><StyledRemove/></StyledButton> 
					</Buttons>
				</ButtonsContainer>
			}
			
		  <LocationsCardTitile>{title}</LocationsCardTitile>
	    <LocationsCardFilters>
	    	{tags && tags.map((tag, index) => {
	    		return index + 1 === tagsLength ? tag.title : tag.title + ", " ;
	    	})}
	    </LocationsCardFilters>
			<AverageReview averageReview={average_review.average_rating} count={average_review.reviews_count} />
	  </LocationsCardCointainer>
		</Fragment>
	
  )
};

export default LocationsCard;
