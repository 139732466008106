import { LOCATION_CHANGE } from 'react-router-redux'
import { otherUserService } from '../services';
import { submitLogout } from './user';

export const GET_OTHER_USER_REQUESTED = 'user/GET_OTHER_USER_REQUESTED';
export const GET_OTHER_USER_SUCCESS = 'user/GET_OTHER_USER_SUCCESS';
export const GET_OTHER_USER_FAILURE = 'user/GET_OTHER_USER_FAILURE';

export const GET_OTHER_PROFILE_REQUESTED = 'user/GET_OTHER_PROFILE_REQUESTED';
export const GET_OTHER_PROFILE_SUCCESS = 'user/GET_OTHER_PROFILE_SUCCESS';
export const GET_OTHER_PROFILE_FAILURE = 'user/GET_OTHER_PROFILE_FAILURE';

export const GET_OTHER_USER_CONTRIBUTIONS_REQUESTED = 'user/GET_OTHER_USER_CONTRIBUTIONS_REQUESTED';
export const GET_OTHER_USER_CONTRIBUTIONS_SUCCESS   = 'user/GET_OTHER_USER_CONTRIBUTIONS_SUCCESS';
export const GET_OTHER_USER_CONTRIBUTIONS_FAILURE   = 'user/GET_OTHER_USER_CONTRIBUTIONS_FAILURE';

const initialState = {
  targetUser: {},
  targetProfile: {},
  isFetching: false,
  errorMessage: '',
  targetUserContributions: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OTHER_USER_REQUESTED:
    case GET_OTHER_PROFILE_REQUESTED:
    case GET_OTHER_USER_CONTRIBUTIONS_REQUESTED:
      return {
        ...state,
        isFetching: true,
        errorMessage: ''
      }

    case GET_OTHER_USER_SUCCESS:
      return {
        ...state,
        targetUser: action.payload,
        isFetching: false
      }

    case GET_OTHER_PROFILE_SUCCESS:
      return {
        ...state,
        targetProfile: action.payload,
        isFetching: false
      }

    case GET_OTHER_USER_CONTRIBUTIONS_SUCCESS:
      return {
        ...state,
        targetUserContributions: action.payload,
        isFetching: false
      }

    case GET_OTHER_USER_FAILURE:
    case GET_OTHER_PROFILE_FAILURE:
    case GET_OTHER_USER_CONTRIBUTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      }

    case LOCATION_CHANGE:
      return {
        ...state,
        errorMessage: ''
      }

    default:
      return state
  }
}

const requestGetOtherUser = () => ({
  type: GET_OTHER_USER_REQUESTED
})

const getOtherUserSuccess = (data) => ({
  type: GET_OTHER_USER_SUCCESS,
  payload: data
})

const getOtherUserFailure = (message) => ({
  type: GET_OTHER_USER_FAILURE,
  payload: message
})

export const getOtherUser = (userId) => {
  return async dispatch => {
    dispatch(requestGetOtherUser())

    try {
      const res = await otherUserService().getOtherUser(userId);

      if (res.status === 200) {
        return dispatch(getOtherUserSuccess(res.data.data));
      }
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401 || e.response.status === 404) {
        dispatch(getOtherUserFailure('There was a problem fetching the requested user'))
        return dispatch(submitLogout());
      } else{
        console.log(e.response.statusText)
      }
    }
  }
}

export const getOtherUserIfEmpty = () => {
  return (dispatch, getState) => {
    const { otherUser, router } = getState();
    const { location: { pathname } } = router;

    if (`/user/${otherUser.targetUser.ID}` !== pathname ) {
      dispatch(getOtherUser(pathname.split('/').pop()));
    }
  }
}

const requestGetOtherProfile = () => ({
  type: GET_OTHER_PROFILE_REQUESTED
})

const getOtherProfileSuccess = (data) => ({
  type: GET_OTHER_PROFILE_SUCCESS,
  payload: data
})

const getOtherProfileFailure = (message) => ({
  type: GET_OTHER_PROFILE_FAILURE,
  payload: message
})

export const getOtherProfile = (userId) => {
  return async dispatch => {
    dispatch(requestGetOtherProfile())

    try {
      const res = await otherUserService().getOtherUserProfile(userId);

      if (res.status === 200) {
        return dispatch(getOtherProfileSuccess(res.data.data));
      }
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401 || e.response.status === 404) {
        dispatch(getOtherProfileFailure('There was a problem fetching the requested profile'))
        return dispatch(submitLogout());
      } else{
        console.log(e.response.statusText)
      }
    }
  }
}

const requestGetOtherUserContributions = () => ({
  type: GET_OTHER_USER_CONTRIBUTIONS_REQUESTED
});

const getOtherUserContributionsSuccess = (data) => ({
  type: GET_OTHER_USER_CONTRIBUTIONS_SUCCESS,
  payload: data
});

const getOtherUserContributionsFailure = (message) => ({
  type: GET_OTHER_USER_CONTRIBUTIONS_FAILURE,
  payload: message
});

export const getOtherUserContributions = (userId) => {
  return async dispatch => {
    dispatch(requestGetOtherUserContributions());

    try {
      const reviews = await otherUserService().getOtherUserReviews(userId);
      const contents = await otherUserService().getOtherUserContents(userId);

      let contributionsArray = [];

      if (reviews.status === 200) contributionsArray = contributionsArray.concat(reviews.data.data);
      if (contents.status === 200) contributionsArray = contributionsArray.concat(contents.data.data);

      // sort the array descending
      contributionsArray = contributionsArray.sort((a,b) => new Date(b.created_at) - new Date(a.created_at));

      return dispatch(getOtherUserContributionsSuccess(contributionsArray));
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401 || e.response.status === 404) {
        dispatch(getOtherUserContributionsFailure('There was a problem fetching your profile'));
        // return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }
  }
};

export const getOtherProfileIfEmpty = () => {
  return (dispatch, getState) => {
    const { otherUser, router } = getState();
    if ('/user/' + otherUser.targetUser.ID !== router.location.pathname) {
      const UserID = router.location.pathname.replace("/user/", "");
      return dispatch(getOtherProfile(UserID))
    } 
  }
};

export const getOtherContributionsIfEmpty = () => {
  return (dispatch, getState) => {
    const { otherUser, router } = getState();
    if ('/user/' + otherUser.targetUser.ID !== router.location.pathname) {
      const UserID = router.location.pathname.replace("/user/", "");
      return dispatch(getOtherUserContributions(UserID));
    } 
  }
};
