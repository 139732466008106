import { contributeService, contentService, reviewService, attractionsService } from '../../services';
import { startUpload, uploadProgress } from '../upload';

import { submitLogout } from '../user';
import { push } from 'connected-react-router';
import FormData from "form-data";
import * as actions from './actions';

export const listContentTags = () => {
  return async (dispatch) => {
    try {
      const res = await contributeService().listContentTags();
      return dispatch(actions.receiveContentTags(res.data.data));
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    } finally {
      return;
    }
  }
}

export const listContentTagsIfEmpty = () => {
  return (dispatch, getState) => {
    return getState().contribute.content_tags.length === 0 && dispatch(listContentTags())
  }
}

/**
 * Contribution submission for photo upload
 * @return {[type]} [description]
 */
export const submitContentContribution = () => {
  return async (dispatch, getState) => {
    dispatch(actions.requestNewContribution())

    let { upload, college, contribute } = getState();

    // save football tag to add if team tags are selected
    //
    // set tag by expected index, check if it is the right tag before moving forward
    // we do this to avoid iterating the array
    let footballTag = contribute.content_tags[12];
    if (footballTag.name !== 'football_sports')
      footballTag = contribute.content_tags[contribute.content_tags.findIndex(tag => tag.name === 'football_sports')];

    for (var i = 0; i < upload.accepted.length; i++) {
      dispatch(startUpload());
      let res;
      try {
        // data needs to be in array format
        res = await contentService().uploadSingleFile(upload.accepted[i], uploadProgress);

        // if there is a team tag selected, add footballTag to the list to be submitted
        let tagsToSubmit = contribute.contrib_items[i].selected_content_tags;
        if (contribute.contrib_items[i].selected_content_tags.findIndex(tag => tag.tag_type === 'teams') >= 0) {
          tagsToSubmit = [...contribute.contrib_items[i].selected_content_tags, footballTag]
        }

        // associate content tags with newly created content object
        contributeService().tagContent(res.data.data[0].ID, tagsToSubmit, college.college.ID);
      } catch (e) {
        // log user out if unauthorized
        if (e.response.status === 401) {
          return dispatch(submitLogout());
        } else {
          console.log(e.response.statusText)
        }
      }
      dispatch(actions.finishContributionFileUpload(i, res.data.data[0]));
    }

    contribute = getState().contribute;


    let reqData = {
      title: contribute.title,
      description: contribute.description,
      contrib_items: contribute.ready_contrib_items
    };

    try {
      const res = await contributeService().newContribution(reqData);

      dispatch(actions.receiveNewContribution(res.data.data));
      return dispatch(actions.closeModalAndResetContribution());
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    } finally {
      return;
    }
  }
};

/**
 * Contribution submission for Attraction photo upload
 * @return {[type]} [description]
 */
export const submitAttractionContentContribution = () => {
  return async (dispatch, getState) => {
    dispatch(actions.AttractionContentUploadRequest());

    let { upload, attraction, contribute } = getState();

    // remap contrib_items here to attraction type and id to prevent refactoring of src/modules/upload.js
    if (contribute.contrib_items) {
      for (let i = 0; i < contribute.contrib_items.length; i++) {
        contribute.contrib_items[i].contributable_id = attraction.attraction.ID;
        contribute.contrib_items[i].contributable_type = 'attractions';
      }
    }

    const formData = new FormData();

    // append each file to multipart form
    for (let i = 0; i < upload.accepted.length; i++) {
      formData.append('files', upload.accepted[i]);
    }

    let reqData = {
      title: contribute.title,
      description: contribute.description,
      contrib_items: contribute.contrib_items
    };
    formData.append('data', JSON.stringify(reqData));

    dispatch(startUpload());
    let res;
    try {
      res = await contentService().AttractionsContentUpload(formData, uploadProgress);

      dispatch(actions.receiveNewContribution(res.data.data.contribution));
      dispatch(actions.closeModalAndResetContribution());
      return dispatch(actions.AttractionContentUploadSuccess(res.data.data));
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
      return dispatch(actions.newContributionFailure())
    }
  }
};




/**
 * Contribution submission for review
 * @return {[type]} [description]
 */
export const submitReviewContribution = () => {
  return async (dispatch, getState) => {
    dispatch(actions.requestCreateReview());

    let { college, review } = getState();
    const start = new Date().getTime(); // performance.now() is more accurate but this is more supported

    // filter out answers that were not answered
    const answeredAnswers = review.answers.filter(answer => answer.rating);

    // stop if no answers were answered
    if (answeredAnswers.length === 0) return dispatch(actions.createReviewFailure());

    review = getState().review;

    let reqData = {
      contribution: {
        title: review.title,
        is_anonymous: review.is_anonymous,
        description: '',
      },
      reviews: answeredAnswers.map((answer, i) => {
        let refer = college.college.ID;
        switch (answer.context) {
          case "Athletics":
            refer = college.college.teams[0].ID;
            break;
          case "Coach":
            refer = answer.coachID;
            break;
          default:
            // do nothing
        }
        return {
          rating_key: answer.rating_key,
          rating: answer.rating,
          comment: answer.comment,
          refer: refer,
        }
      })
    };

    try {
      const res = await reviewService().newReview(reqData);

      const end = new Date().getTime();
      const diff = (end - start) / 1000; // to ms
      if (diff < 1000) {
        // sleep
        await new Promise((resolve) => setTimeout(resolve, Math.round(1000 - diff)));
      }
      dispatch(actions.createReviewSuccess(res.data.data));
      return dispatch(push('/college/' + college.college.url_slug));
    } catch (e) {
      dispatch(actions.createReviewFailure());
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    } finally {
      return;
    }
  }
}



export const submitAttractionReviewContribution = (id) => {
  return async (dispatch, getState) => {
    dispatch(actions.requestCreateAttractionReview());

    let { college, attraction, attractionReview } = getState();
    const start = new Date().getTime(); // performance.now() is more accurate but this is more supported

    // filter out answers that were not answered
    const answeredAnswers = attractionReview.answers.filter(answer => answer.rating);

    // stop if no answers were answered
    if (answeredAnswers.length === 0) return dispatch(actions.createAttractionReviewFailure());

    let reqData = {
      contribution: {
        title: attractionReview.title,
        is_anonymous: false,
        description: '',
      },
      reviews: answeredAnswers.map((answer, i) => {
        let refer = attraction.attraction.ID;
        return {
          rating_key: answer.rating_key,
          rating: answer.rating,
          comment: answer.comment,
          refer: refer,
        }
      })
    };

    // console.log(reqData);

    try {
      const res = await attractionsService().newAttractionReview(id, reqData);

      const end = new Date().getTime();
      const diff = (end - start) / 1000; // to ms
      if (diff < 1000) {
        // sleep
        await new Promise((resolve) => setTimeout(resolve, Math.round(1000 - diff)));
      }
      dispatch(actions.createAttractionReviewSuccess(res.data.data));
      return dispatch(push('/college/' + college.college.url_slug + '/attractions/' + id));
    } catch (e) {
      dispatch(actions.createAttractionReviewFailure());
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    } finally {
      return;
    }
  }
}
