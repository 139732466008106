import * as types from './actions';
import { DELETE_ADMIN_REVIEW_SUCCESS } from "../review/actionsTypes";

const initialState = {
  coaches: [],
  titles: [],
  coachProfile: {},
  averageVerifiedReviews: [],
  averageVerifiedReview: {},
  isFetching: false,
  isFetchingCoaches: false,
  isFetchingCoachesTitles: false,
  errorMessage: '',
  coachReviews: [],
  isFetchingReviews: false,
  isFetchingAverageVerifiedReview: false,
  isFetchingAverageVerifiedReviews: false,
  coachHistory: [],
  newCoach: {},
  targetCoaches: false, // false is here to manage this when it has not been loaded yet
  targetId: null,
  isFetchingTargetCoaches: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_COACH_REQUESTED:
    case types.UPDATE_COACH_REQUESTED:
    case types.LIST_COACHES_REQUESTED:
      return {
        ...state,
        isFetchingCoaches: true
      }
    case types.LIST_COACHES_SUCCESS:
      return {
        ...state,
        coaches: action.payload,
        isFetchingCoaches: false
      }
    case types.LIST_COACHES_FAILURE:
      return {
        ...state,
        isFetchingCoaches: false
      }

    // SEARCH COACHES REDUCER
    case types.SEARCH_COACHES_REQUESTED:
      return {
        ...state,
        filters: action.payload,
        isFetchingCoaches: true
      };
    case types.SEARCH_COACHES_SUCCESS:
      return {
        ...state,
        coaches: action.payload.data,
        coachesCount: action.payload.count,
        isFetchingCoaches: false
      };
    case types.SEARCH_COACHES_FAILURE:
      return {
        ...state,
        error: action.error,
        coaches: [],
        isFetchingCoaches: false
      };

    // GET COACH TITLES REDUCER
    case types.GET_COACH_TITLES_REQUESTED:
      return {
        ...state,
        isFetchingCoachesTitles: true
      };
    case types.GET_COACH_TITLES_SUCCESS:
      return {
        ...state,
        titles: action.payload,
        isFetchingCoachesTitles: false
      };
    case types.GET_COACH_TITLES_FAILURE:
      return {
        ...state,
        error: action.error,
        titles: [],
        isFetchingCoaches: false
      };

    case types.GET_COACH_PROFILE_REQUESTED:
      return {
        ...state,
        isFetching: true,
        errorMessage: ''
      }

    case types.GET_COACH_PROFILE_SUCCESS:
      return {
        ...state,
        coachProfile: action.payload,
        isFetching: false
      }

    case types.GET_COACH_PROFILE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      }

    case types.GET_COACH_REVIEW_REQUESTED:
      return {
        ...state,
        isFetchingReviews: true
      }

    case types.GET_COACH_REVIEW_SUCCESS:
      return {
        ...state,
        coachReviews: action.payload,
        isFetchingReviews: false
      }

    case types.GET_COACH_REVIEW_FAILURE:
      return {
        ...state,
        isFetchingReviews: false
      }


    case DELETE_ADMIN_REVIEW_SUCCESS:
      return {
        ...state,
        coachReviews: [...state.coachReviews.filter(el => el.ID !== action.payload)]
      };

    case types.CREATE_COACH_FAILURE:
    case types.UPDATE_COACH_FAILURE:
    case types.GET_COACH_HISTORY:
      return {
        ...state,
        coachHistory: action.payload
      }

    case types.UPDATE_COACH_SUCCESS:
      return {
        ...state,
        coachProfile: {
          ...action.payload,
          coached_seasons: state.coachProfile.coached_seasons
        },
        isFetchingCoaches: false
      }

    case types.CREATE_COACH_SUCCESS:
      return {
        ...state,
        coachProfile: action.payload,
        isFetchingCoaches: false
      }

    case types.GET_COACH_AVERAGE_VERIFIED_REVIEW_REQUESTED:
      return {
        ...state,
        isFetchingAverageVerifiedReview: true
      }

    case types.GET_COACH_AVERAGE_VERIFIED_REVIEW_SUCCESS:
      return {
        ...state,
        averageVerifiedReview: action.payload,
        isFetchingAverageVerifiedReview: false
      }

    case types.GET_COACH_AVERAGE_VERIFIED_REVIEW_FAILURE:
      return {
        ...state,
        isFetchingAverageVerifiedReview: false
      }

    case types.GET_COACH_AVERAGE_VERIFIED_REVIEWS_REQUESTED:
      return {
        ...state,
        isFetchingAverageVerifiedReviews: true
      }

    case types.GET_COACH_AVERAGE_VERIFIED_REVIEWS_SUCCESS:
      return {
        ...state,
        averageVerifiedReviews: action.payload,
        isFetchingAverageVerifiedReviews: false
      }

    case types.GET_COACH_AVERAGE_VERIFIED_REVIEWS_FAILURE:
      return {
        ...state,
        isFetchingAverageVerifiedReviews: false
      }

    case types.UPDATE_COACH_DEFAULT_PHOTO:
      return {
        ...state,
        coachProfile: {
          ...state.coachProfile,
          default_photo: action.payload.default_photo
        }
      }

    case types.GET_TARGET_COACHES_REQUESTED:
      return {
        ...state,
        isFetchingTargetCoaches: true,
        targetCoaches: false,
      };
    case types.GET_TARGET_COACHES_SUCCESS:
      return {
        ...state,
        isFetchingTargetCoaches: false,
        targetCoaches: action.payload.coaches,
        targetId: action.payload.targetId,
      };
    case types.GET_TARGET_COACHES_FAILURE:
      return {
        ...state,
        isFetchingTargetCoaches: false,
        errorMessage: action.payload.message,
      };

    default:
      return state
  }
}
