import styled from 'styled-components';
import { Button } from 'rebass';
import { color, bp, typo } from '../../styles';
import Logo from '../../components/Logo'

export const HomeBigCardContainer = styled.div`
	border-radius: 6px;
	height: 300px;
	margin-bottom: 2rem;
	position: relative;
	overflow: hidden;
	padding: 20px 24px;
	background: ${props => `url("${props.bgUrl}") no-repeat`};
	background-position: center;
	background-size: cover;
	@media ${bp.phoneOnly} {
		height: 350px;
		background-position: top;
    }
`;

export const HomeBigCardContent = styled.div`
	position: absolute;
	z-index: 2;
`;

export const PhotoGradient = styled.div`
	background: -moz-linear-gradient(left, rgba(0,0,0,.9) 0%, rgba(255,255,255,0.3) 57%);
	background: -webkit-linear-gradient(left, rgba(0,0,0,.9) 0%,rgba(255,255,255,0.3) 57%);
	background: linear-gradient(to right, rgba(0,0,0,.9) 0%,rgba(255,255,255,0.3) 57%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#80ffffff',GradientType=1 );
	position: absolute;
	z-index: 0;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
`;

export const HomeBigCardSubtitle = styled.h4`
	font-family: ${typo.ovFontFeature};
	font-size: 28px;
	font-weight: 100;
	font-style: normal;
	font-stretch: condensed;
	line-height: 0.93;
	letter-spacing: normal;
	text-align: left;
	color: ${color.ovWhite};
	span{
		display: block;
	    padding-top: 4px;
	    padding-left: 43px;
	}
	@media ${bp.phoneOnly} {
	    font-size: 25px;
    }
`;

export const HomeBigCardTitle = styled.h3`
	font-family: ${typo.ovFontFeature};
	font-size: 40px;
	font-weight: 100;
	font-style: normal;
	font-stretch: condensed;
	line-height: 0.88;
	letter-spacing: normal;
	text-align: left;
	color: #fcd068;
	padding: 20px 24px 20px 0;
	@media ${bp.phoneOnly} {
	    font-size: 24px;
	    padding-top: 130px;
    }
`;

export const HomeBigCardButton = styled(Button)`
	font-family: ${typo.ovFontFeature};
	font-size: 28px;
	font-weight: 100;
	font-style: normal;
	font-stretch: condensed;
	line-height: 0.93;
	letter-spacing: normal;
	text-align: left;
	color: ${color.ovWhite};
	background: transparent;
	padding: 0;
	cursor: pointer;
	transition: all .1s;
	@media ${bp.phoneOnly} {
	    font-size: 24px;
    }
	span{
		display: block;
	    margin: 3px 0 0 10px;
	    float: right;
	    color: ${color.ovWhite};
	    transition: all .1s;
	}
	&:hover{
		color: #fcd068;
		transition: all .1s;
		span{
			color: #fcd068;
			transition: all .1s;
		}
	}
`;

export const StyledLogo = styled(Logo)`
	float: left;
	width: 35px;
	height: auto;
	path, polygon{
      fill: ${color.ovWhite};
    }
`;