import * as types from './actions';

const initialState = {
  posts: null,
  categories: null,
  post: null,
  isFetching: false,
  errorMessage: null,
}

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.LIST_CATEGORIES_REQUESTED:
      return {
        ...state,
      };

    case types.LIST_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: payload,
      };
    case types.LIST_CATEGORIES_FAILURE:
      return {
        ...state,
        categories: [payload.message],
      };

    case types.LIST_POSTS_REQUESTED:
      return {
        ...state,
        isFetching: true,
        errorMessage: null,
        category: payload.cagetory,
      };

    case types.LIST_POSTS_SUCCESS:
      return {
        ...state,
        posts: payload,
        isFetching: false,
      };
    case types.LIST_POSTS_FAILURE:
      return {
        ...state,
        errorMessage: payload.message,
        isFetching: false,
      };

    case types.GET_POST_REQUESTED:
      return {
        ...state,
        isFetching: true,
        errorMessage: null,
        postSlug: payload.slug,
      }

    case types.GET_POST_SUCCESS:
      return {
        ...state,
        post: payload,
        isFetching: false
      }

    case types.GET_POST_FAILURE:
      return {
        ...state,
        post: null,
        isFetching: false,
        errorMessage: payload.message,
      }

    default:
      return state
  }
}