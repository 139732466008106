import { apiEndpoint } from '../config/app';
import createRestApiClient from '../utils/createRestApiClient';
import FormData from "form-data";
import { COACH_TITLES } from './fixtures/coachPositions';

export default () => {
  // Adds token to client if it is passed in
  const token = localStorage.getItem('ovSessToken');
  const config = token ? {
    baseURL: apiEndpoint,
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  } : { baseURL: apiEndpoint };
  const client = createRestApiClient().withConfig(config);


  const updateCoachedSeason = (id, data) => client.request({
    method: 'PUT',
    url: `/aa/coach/season/${id}`,
    data
  });

  const createCoachedSeason = (data) => client.request({
    method: 'POST',
    url: `/aa/coach/season`,
    data
  });

  return {
    getCoaches: () => client.request({
      method: 'GET',
      url: '/api/coach'
    }),

    searchCoaches: ({ year, title, college, name }, currentPage = 1, pageSize = 20, sortBy = 'reviews_count', sortDir = 'DESC') => client.request({
      method: 'GET',
      url: `/api/coach`,
      params: { currentPage, pageSize, year, title, college, name, sortBy, sortDir },
    }),

    getCoachSeasons: (id) => {
      return client.request({
        method: 'GET',
        url: '/api/coach/' + id + '/seasons'
      })
    },

    getCoachTitles: () => {
      return client.request({
        method: 'GET',
        url: `/api/coach-titles`
      });
      //return Promise.resolve({ data: { data: COACH_TITLES }});
    },

    getCoachProfileBySlug: (slug) => {
      return client.request({
        method: 'GET',
        url: '/api/coach-slug/' + slug
      })
    },
    getCoachProfileByID: (id) => {
      return client.request({
        method: 'GET',
        url: '/api/coach/' + id
      })
    },

    getApiReviews: (id) => client.request({
      method: 'GET',
      url: '/api/reviews/coach/' + id
    }),
    getAuthReviews: (id) => client.request({
      method: 'GET',
      url: '/a/reviews/coach/' + id
    }),

    getAverageReviews: () => client.request({
      method: 'GET',
      url: '/api/reviews/average_verified/coach'
    }),

    getAverageReview: (id) => client.request({
      method: 'GET',
      url: `/api/reviews/coach/${id}/average_verified`
    }),

    // for admins only
    updateCoach: (id, data) => client.request({
      method: 'PUT',
      url: `/aa/coach/profile/${id}`,
      data
    }),

    // for admins only
    createCoach: (data) => client.request({
      method: 'POST',
      url: `/aa/coach/profile`,
      data
    }),

    // for admins only
    saveCoachedSeason: (id, data) => id
      ? updateCoachedSeason(id, data)
      : createCoachedSeason(data),

    deleteCoachedSeason: (id) => client.request({
      method: 'DELETE',
      url: `/aa/coach/season/${id}`
    }),

    // for admins only
    uploadCoachPhoto: (id, file, updateUploadStatus) => {
      const data = new FormData();
      // append each file to multipart form
      data.append('files', file);
      return client.request({
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        url: `/aa/coach/profile/${id}/photo-upload`,
        onUploadProgress: updateUploadStatus,
        data
      })
    },
  };
};
