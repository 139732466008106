import React, { useCallback } from "react";
import { StyledSelectedList } from "./Styled";

const ListSelected = ({ selected, onChange }) => {
  const handleRemove = useCallback((item) => {
    const result = selected.filter(({ value }) => value !== item.value);
    onChange(result);
  }, [selected, onChange]);

  return (
    <StyledSelectedList>
      {selected && selected.map((item) => (
        <span>{item.label}<i onClick={() => handleRemove(item)}/></span>
      ))}
    </StyledSelectedList>
  )
};

export default ListSelected;
