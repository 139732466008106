import React, { useEffect, Fragment, useState } from 'react';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';

// Components
import Spinner from 'react-spinkit';
import { PageContainer } from '../../../components';
import CollegeList from '../../../components/CollegeList'

import { PageContent, BigTitleGrey, DetailsSubHeading, FullWithContent, LoadMoreButtonContainer, LoadMoreButton, LoadMoreButtonLabel } from '../Styled';

const AveragePostgraduateEarnings = ({ rate }) => (
  <div>
    <DetailsSubHeading as="h3">Median Earnings: ${rate.toLocaleString('EN')} </DetailsSubHeading>
  </div>
);

const CollegePostgraduateEarnings = ({ isFetching, listCollegesIfEmpty, paginate, count, push }) => {

  const [page, setPage] = useState(1);
  const colleges = paginate(page);
  
  useEffect(() => {
    if (!colleges || !colleges.length) listCollegesIfEmpty();
  }, [colleges]);

  

  return (
    <div>
      <Helmet>
        <title>HIGHEST Postgraduate Earnings</title>
        <meta name="description" content="Discover FBS colleges with the highest postgraduate earnings (salaries). Colleges are listed by highest 6-year postgraduate earnings. Stanford, Duke, Georgia Tech, Notre Dame, USC, Boston College..." />
      </Helmet>
      {isFetching
        ? (
          <PageContainer>
            <PageContent>
              <Spinner fadeIn="none" name="cube-grid" />
            </PageContent>
          </PageContainer>
        )

        : (
          <Fragment>
            <FullWithContent>
              <BigTitleGrey bigTitleLabel="Highest Postgraduate Earnings" />
              <DetailsSubHeading as="h2">
                Colleges are listed from highest to lowest median earnings six years after graduation
                </DetailsSubHeading>
            </FullWithContent>
            <PageContainer>
              <PageContent>
                <CollegeList colleges={colleges} rankingComponent={(college) => <AveragePostgraduateEarnings {...college} />} push={push} />

                {colleges && colleges.length < count && (
                  <LoadMoreButtonContainer>
                    <LoadMoreButton onClick={() => setPage(page + 1)}>
                      <LoadMoreButtonLabel>Load More</LoadMoreButtonLabel>
                    </LoadMoreButton>
                  </LoadMoreButtonContainer>
                )}
              </PageContent>
            </PageContainer>
          </Fragment>
        )
      }
    </div>
  )
}

CollegePostgraduateEarnings.propTypes = {
  colleges: PropTypes.array,
  isFetching: PropTypes.bool,
  listCollegesIfEmpty: PropTypes.func,
  paginate: PropTypes.func,
  count: PropTypes.number,
  push: PropTypes.func
}

export default CollegePostgraduateEarnings;
