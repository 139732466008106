import { collegeService, attractionsService, contentService } from '../../services';
import { toast } from "react-toastify";
import { submitLogout } from "../user";

import {
  listAttractionsRequest,
  listAttractionsSuccess,
  listAttractionsFailure,

  listAttractionsTagsSuccess,

  getAttractionRequest,
  getAttractionSuccess,
  getAttractionFailure,

  getAttractionReviewsRequest,
  getAttractionReviewsSuccess,
  getAttractionReviewsFailure,
  requestCreateAttraction,
  createAttractionSuccess,
  createAttractionFailure,
  updateAttractionRequest,
  updateAttractionSuccess,
  updateAttractionFailure,
  getAttractionContentRequest,
  getAttractionContentSuccess,
  getAttractionContentFailure,
  getTargetAttractionsRequest,
  getTargetAttractionsSuccess,
  getTargetAttractionsFailure,
  getAllAttractionsRequest,
  getAllAttractionsFailure,
  getAllAttractionsSuccess,
} from './actions';
import * as actions from "../attraction/actions";
import * as contentActions from "../content/actions";
import { push } from "connected-react-router";
import find from "lodash/find";
import { mapReviewsWithUserCollege } from "../college";

export const getAttractions = (collegeId) => {
  return async (dispatch, getState) => {

    dispatch(listAttractionsRequest(collegeId));
    const { attraction: { tags } } = getState();

    try {
      if (!tags) {
        const { data: { data: tagList } } = await collegeService().getAttractionTags();
        dispatch(listAttractionsTagsSuccess(tagList));
      }

      const { data: { data: attractions } } = await collegeService().getAttractions(collegeId);
      dispatch(listAttractionsSuccess(collegeId, attractions));
    } catch (e) {
      console.error(e);
      return dispatch(listAttractionsFailure(e));
    }
  }
};

export const getAttraction = () => {
  return async (dispatch, getState) => {
    const { router } = getState();
    const attractionId = router.location.pathname.split('/').pop();

    dispatch(getAttractionRequest(attractionId));

    try {

      const { data: { data: attraction } } = await collegeService().getAttraction(attractionId);
      dispatch(getAttractionSuccess(attraction));

    } catch (e) {
      console.error(e);
      return dispatch(getAttractionFailure(e));
    }
  }
};

export const getAttractionForReview = () => {
  return async (dispatch, getState) => {

    const { router } = getState();
    const attractionId = router.location.pathname.split('/').splice(2, 3).pop();

    dispatch(getAttractionRequest(attractionId));

    try {

      const { data: { data: attraction } } = await collegeService().getAttraction(attractionId);
      dispatch(getAttractionSuccess(attraction));

    } catch (e) {
      console.error(e);
      return dispatch(getAttractionFailure(e));
    }
  }
};

export const getAttractionReviews = () => {
  return async (dispatch, getState) => {
    // need to get list of colleges first
    // await dispatch(listCollegesIfEmpty()); not work here

    const { router, user, college: { colleges } } = getState();
    const attractionId = router.location.pathname.split('/').pop();

    dispatch(getAttractionReviewsRequest(attractionId));

    try {
      const res = user.isAuthenticated
        ? await attractionsService().getAuthAttractionReviews(attractionId)
        : await attractionsService().getApiAttractionReviews(attractionId);

      const reviews = mapReviewsWithUserCollege(res.data.data, colleges);
      dispatch(getAttractionReviewsSuccess(attractionId, reviews));
    } catch (e) {
      console.error(e);
      return dispatch(getAttractionReviewsFailure(e));
    }
  }
};

export const getAttractionContents = () => {
  return async (dispatch, getState) => {
    const { router } = getState();
    const attractionId = router.location.pathname.split('/').pop();

    dispatch(getAttractionContentRequest());
    try {
      const res = await attractionsService().getContent(attractionId);
      dispatch(getAttractionContentSuccess(res.data.data));
    } catch (e) {
      dispatch(getAttractionContentFailure());
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }
  }
};

export const createAttraction = (data) => {
  return async (dispatch) => {
    dispatch(requestCreateAttraction());
    try {
      const { data: { data: attraction } } = await attractionsService().newAttraction(data);
      dispatch(createAttractionSuccess(attraction));
      window.history.back()
      toast('Attraction created!', { type: toast.TYPE.SUCCESS });
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText);
        toast('There was an error creating the attraction', { type: toast.TYPE.ERROR });
        return dispatch(createAttractionFailure(e.response.statusText));
      }
    }
  }
}

export const updateAttraction = (item) => {
  return async (dispatch) => {
    dispatch(updateAttractionRequest());

    try {
      const { data: { data: attraction } } = await attractionsService().updateAttraction(item.ID, item);
      dispatch(updateAttractionSuccess(attraction));
      window.history.back()
      toast('Attraction updated!', { type: toast.TYPE.SUCCESS });
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText);
        toast('There was an error updating the attraction', { type: toast.TYPE.ERROR });
        return dispatch(updateAttractionFailure(e.response.statusText));
      }
    }
  }
};

export const saveAttraction = (id, data) => {
  return async (dispatch) => {
    if (id) dispatch(updateAttraction({ID: id, ...data}));
    else dispatch(createAttraction(data));
  }
}

export const getUserByAttractionContentId = (contentId) => {
  return async (dispatch, getState) => {
    if (!contentId) return;
    const { attraction: { contents } } = getState();

    // First check that the item has no user so far
    const item = find(contents, { ID: contentId });
    if (item.user) return;

    dispatch(actions.requestGetUserId());
    try {
      const { status, data: { data: user } } = await contentService().getUserByContentId(contentId);
      if (status === 200) {
        return dispatch(actions.getUserIdSuccess({ contentId, user }));
      }
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401 || e.response.status === 404) {
        dispatch(actions.getUserIdFailure('There was a problem fetching the requested profile'))
        // return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }
  }
};

const selectAttractionContent = direction => (dispatch, getState) => {
  const { attraction: { contents, selectedContentIndex } } = getState();
  const index = (selectedContentIndex + direction) % contents.length;
  dispatch(actions.selectAttractionContentIndex(index));
  dispatch(getUserByAttractionContentId(contents[index].ID));
};

export const selectNextAttractionContentIndex = () => selectAttractionContent(1);

export const selectPrevAttractionContentIndex = () => selectAttractionContent(-1);

export const openAttractionContentByID = (base, id) => (dispatch, getState) => e => {
  const { attraction: { contents } } = getState();
  if (!contents || !contents.length) return;

  dispatch(push(`${ base }/content`));
  dispatch(contentActions.toggleLightboxOpen());

  let ID = contents && contents[0].ID;
  let index = 0;

  if (id) {
    index = contents.map(content => content.ID).indexOf(id);
    ID = index >= 0 ? contents[index].ID : ID;
    index = index >= 0 ? index : 0;
  }

  dispatch(actions.selectAttractionContentIndex(index));
  dispatch(getUserByAttractionContentId(ID));
};

export const getTargetAttractionsByContributionsIfEmpty = (targetID, contributionsArray) => {
  return async (dispatch, getState) => {
    const { attraction } = getState();

    if (attraction.isFetchingTargetAttractions || targetID === undefined || (Object.keys(attraction.targetAttractions).length > 0 && attraction.targetId === targetID))
      return;

    dispatch(getTargetAttractionsRequest(targetID));

    // Gets all the attractions IDs
    const ids =
      // eslint-disable-next-line
      contributionsArray.map((contribution) => {
        if (contribution.contributable_type === "attractions") {
          return contribution.contributable_id
        }
      }).filter((v, i, a) => a.indexOf(v) === i).filter(Boolean);

    try {
      const promiseAttractions = ids.map((id) => {
        return attractionsService().getAttraction(id);
      });

      const res = await Promise.all(promiseAttractions);

      const attractionsResult = {};

      res.forEach((attraction) => {
        if (attraction.status === 200) {
          attractionsResult[attraction.data.data.ID] = attraction.data.data;
        }
      });

      return dispatch(getTargetAttractionsSuccess({ attractions: attractionsResult, targetId: targetID }));

    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401 || e.response.status === 404) {
        dispatch(getTargetAttractionsFailure('There was a problem fetching the requested attraction'))
        console.log(e.response.status, e.response.statusText)
        // return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }
  }
};

// might be need but it downloads much value of data
export const getAllAttractionsIfEmpty = () => {
  return async (dispatch, getState) => {
    const { attraction } = getState();

    if (attraction.isFetchingAllAttractions)
      return;

    dispatch(getAllAttractionsRequest());
    try {
      const res = await attractionsService().getAllAttractions();
      const result = {};
      if (res.data && res.data.data && res.data.data.length) {
        res.data.data.forEach(el => {
          result[el.ID] = el;
        })
      }

      dispatch(getAllAttractionsSuccess(result));
    } catch (e) {
      console.error(e);
      return dispatch(getAllAttractionsFailure(e));
    }
  }
};

export const getAttractionsTags = () => {
  return async (dispatch, getState) => {

    //dispatch(getAllAttractionsRequest());
    const { attraction: { tags } } = getState();

    try {
      if (!tags) {
        const { data: { data: tagList } } = await collegeService().getAttractionTags();
        dispatch(listAttractionsTagsSuccess(tagList));
      }
    } catch (e) {
      console.error(e);
      return dispatch(listAttractionsFailure(e));
    }
  }
};

export const deleteAttraction = (attraction) => {
  return async (dispatch, getState) => {
    try {
      const { user: { user: { role } } } = getState();
      await attractionsService().deleteAttraction(attraction.ID, role);
      return dispatch(actions.deleteAttractionSuccess(attraction));
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }
  }
};

