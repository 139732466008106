// eslint-disable-next-line
import { bindActionCreators } from 'redux'; 
import { connect } from 'react-redux';
import { listCollegesIfEmpty } from "../../modules/college";
import { getAttractionsTags, saveAttraction, getAttraction } from '../../modules/attraction/attraction';
import { attractionSelectorByRouter } from '../../modules/attraction/selectors'
 
import AttractionsForm from './AttractionsForm';
import { requestLogin } from "../../modules/user";

const mapStateToProps = (state) => {
  const { user, college, attraction, router } = state;
  return {
    isAuthenticated: user.isAuthenticated,
    colleges: college.colleges || [],
    tags: attraction.tags,
    attraction: attraction.attraction,
    router: router.location.pathname,
    initialValues: attractionSelectorByRouter(state)
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  listCollegesIfEmpty,
  requestLogin,
  getAttractionsTags,
  saveAttraction,
  getAttraction,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AttractionsForm)