import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import styled from 'styled-components';
// import Select from 'react-select';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { OVButton } from '../../components';
import { Field, reduxForm } from 'redux-form';
import { color, typo, bp } from '../../styles'


// let StyledInput = styled.input`
//   display: inline-block;
//   width: 100%;
//   height: 35px;
//   border: solid 3px ${color.ovBlue};
//   padding-left: 10px;
//   background-color: ${color.ovWhite};
// 
//   font-family: ${typo.ovFontFeature};
//   font-size: 18px;
//   font-weight: 400;
//   font-style: normal;
//   font-stretch: condensed;
//   line-height: 1.4;
//   letter-spacing: normal;
//   text-align: left;
//   color: ${color.ovBlue};
// 
//   &::placeholder{
//     font-family: ${typo.ovFontFeature};
//     font-size: 18px;
//     font-weight: 400;
//     font-style: normal;
//     font-stretch: condensed;
//     line-height: 1.55;
//     letter-spacing: normal;
//     text-align: left;
//     color: ${color.ovGrey};
//   }
// `;

let StyledTextArea = styled.textarea`
  display: inline-block;
  width: 100%;
  height: 140px;
  border: solid 3px ${color.ovBlue};
  padding-left: 10px;
  background-color: ${color.ovWhite};

  font-family: ${typo.ovFontFeature};
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlue};

  &::placeholder{
    font-family: ${typo.ovFontFeature};
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: ${color.ovGrey};
  }
`;

// let StyledSelect = styled(Select)`
//   height: 39px;
// 
//   .react-select__control {
//     border: solid 3px ${color.ovBlue};
//     border-radius: 0px;
// 
//     font-family: ${typo.ovFontFeature};
//     font-size: 18px;
//     font-weight: 400;
//     font-style: normal;
//     font-stretch: condensed;
//     line-height: 1.4;
//     letter-spacing: normal;
//     text-align: left;
//     color: ${color.ovBlue};
//     
//     height: 29px;
//   }
// 
//   .react-select__value-container {
//     display: inline-block;
//     width: 100%;
//     padding-left: 10px;
//     background-color: ${color.ovWhite};
// 
//     padding-top: 0;
//     padding-bottom: 0;
// 
//     height: 29px;
// 
//     position: static;
//   }
// 
//   .react-select__single-value {
//     color: ${color.ovBlue};
//     margin-left: 0;
//     margin-right: 0;
//   }
// 
//   .react-select__indicator {
//     color: ${color.ovBlue};
//   }
// 
//   .react-select__menu {
//     display: inline-block;
//     border-radius: 0px;
//     border: solid 3px ${color.ovBlue};
//     width: 100%;
//     background-color: ${color.ovWhite};
//   }
// 
//   .react-select__option {
//     font-family: ${typo.ovFontFeature};
//     font-size: 18px;
//     font-weight: 400;
//     font-style: normal;
//     font-stretch: condensed;
//     line-height: 1.4;
//     letter-spacing: normal;
//     text-align: left;
//   }
// `;

const PageContent = styled.div`
  grid-column: span 6;

  @media ${bp.phoneOnly} {
    grid-column: span 12;
  }
`;

const ButtonMarginTop = styled.div`
  margin-top: 10px;
`;

const ButtonMarginRight = styled.span`
  margin-right: 10px;
`;

// const inputField = ({ input, label, type, meta: { touched, active, error } }) => (
//   <div >
//     <StyledInput {...input} placeholder={label} type={type} />
//     {touched && !active && error && <div>{error}</div>}
//   </div>
// );

const textAreaField = ({ input, label, type, meta: { touched, active, error } }) => (
  <div >
    <StyledTextArea {...input} placeholder={label} type={type} maxlength="2000" />
    {touched && !active && error && <div>{error}</div>}
  </div>
);

// const selectField = ({ input, options, clearable, meta: { touched, active, error } }) => (
//   <div>
//     <StyledSelect
//       instanceId={input.name}
//       {...input}
//       className="react-select-container"
//       classNamePrefix="react-select"
//       options={options}
//       clearable={clearable}
//       onBlur={() => input.onBlur}
//     />
//     {touched && !active && error && <div>{error}</div>}
//   </div>
// );

const toggleField = (field) => (
  <div>
    <Toggle checked={field.input.value} onChange={field.input.onChange} icons={true} />
  </div>
);

let FanProfileEditForm = props => {
  const { handleSubmit, onCancel } = props
  // const options = colleges.map((college) => {
  //   return { value: college, label: college.name }
  // })

  return <PageContent>
    <form id='user-edit-form' onSubmit={handleSubmit}>
      <div>
        <div>
          <label htmlFor="about_me">Bio: </label>
          <Field name="about_me" component={textAreaField} type="text" />
        </div>
        {/* <div> */}
        {/*   <label>Affiliation: </label> */}
        {/*   <Field */}
        {/*     id="affiliation" */}
        {/*     name="affiliation" */}
        {/*     component={selectField} */}
        {/*     options={options} */}
        {/*     clearable={false} */}
        {/*   /> */}
        {/* </div> */}
        <div>
          <label htmlFor="is_ticket_holder">Ticket Holder: </label>
          <Field
            name="is_ticket_holder"
            id="is_ticket_holder"
            component={toggleField}
          />
        </div>
        <div>
          <label htmlFor="is_donor">Donor: </label>
          <Field
            name="is_donor"
            id="is_donor"
            component={toggleField}
          />
        </div>
      </div>
      <ButtonMarginTop>
        <ButtonMarginRight><OVButton onClick={handleSubmit}>Submit</OVButton></ButtonMarginRight>
        <OVButton onClick={onCancel}>Cancel</OVButton>
      </ButtonMarginTop>
    </form>
  </PageContent>
}

FanProfileEditForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  colleges: PropTypes.array.isRequired
};
FanProfileEditForm = reduxForm({
  // a unique name for the form
  form: 'fanProfileEdit'
})(FanProfileEditForm)

FanProfileEditForm = connect(
  ({ user }) => ({
    initialValues: {
      about_me: user.profile.about_me,
      // affiliation: user.profile.affiliation && {value: user.profile.affiliation[0], label: user.profile.affiliation[0].name },
      is_ticket_holder: user.profile.is_ticket_holder,
      is_donor: user.profile.is_donor,
    } // pull initial values from account reducer
  }),
  { }
)(FanProfileEditForm)

export default FanProfileEditForm