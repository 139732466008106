import { LOGOUT_SUBMITTED } from '../user';

export const SET_ANSWER = 'attractionReview/SET_ANSWER';
export const SET_COMMENT = 'attractionReview/SET_COMMENT';
export const SET_TITLE = 'attractionReview/SET_TITLE';

export const SETUP_REVIEW_QUESTIONS = 'attractionReview/SETUP_REVIEW_QUESTIONS';

export const CREATE_ATTRACTION_REVIEW_REQUESTED = 'attractionReview/CREATE_ATTRACTION_REVIEW_REQUESTED';
export const CREATE_ATTRACTION_REVIEW_SUCCESS = 'attractionReview/CREATE_ATTRACTION_REVIEW_SUCCESS';
export const CREATE_ATTRACTION_REVIEW_FAILURE = 'attractionReview/CREATE_ATTRACTION_REVIEW_FAILURE';

const attractionQuestions = [
  {
    context: 'Attraction',
    rating_key: 'overall_attraction',
    text: 'Overall Attraction Experience'
  }
];

const ROLE_QUESTIONS = {
  contributor: [...attractionQuestions],
  fan: [...attractionQuestions],
  recruit: [...attractionQuestions],
  student: [...attractionQuestions],
  athlete: [...attractionQuestions],
  admin: [...attractionQuestions],
};

const getQuestionStateByRole = (isCurrentCollegeStudent, role = 'fan') => {
  role = isCurrentCollegeStudent || role === 'admin' ? role : 'fan';
  const questions = ROLE_QUESTIONS[role];

  const state = {
    title: '',
    questions: questions.map((question, i) => ({
      index: i,
      ...question
    })),
    answers: questions.map((question, i) => ({ index: i })),
    userRole: role,
    reviews: []
  };

  return state;
};

const initialState = {
  title: '',
  questions: [],
  answers: [],
  userRole: 'fan',
  reviews:[],
  isSubmittingReview: false,
}

export default (state = initialState, action) => {
  switch (action.type) {

    case SET_ANSWER:
      return {
        ...state,
        answers: state.answers.map((item, i) => i === action.payload.index ? { ...item, ...action.payload }  : item)
      }

    case SET_COMMENT:
      return {
        ...state,
        answers: state.answers.map((item, i) => i === action.payload.index ? {...item, comment: action.payload.comment} : item)
      }

    case SET_TITLE:
      return {
        ...state,
        title: action.payload
      }

    case SETUP_REVIEW_QUESTIONS: {
      const { isCurrentCollegeStudent, role } = action.payload;
      return {
        ...state,
        ...getQuestionStateByRole(isCurrentCollegeStudent, role),
      };
    }

    case CREATE_ATTRACTION_REVIEW_REQUESTED:
      return {
        ...state,
        isSubmittingReview: true,
      };

    case CREATE_ATTRACTION_REVIEW_SUCCESS:
      return {
        ...state,
        isSubmittingReview: false,
      };

    case CREATE_ATTRACTION_REVIEW_FAILURE:
      return {
        ...state,
        isSubmittingReview: false,
      };

    case LOGOUT_SUBMITTED:
      return initialState;

    default:
      return state
  }
}

export const getPageTitle = (stage) => {
  switch (stage) {
    default:
      return "Name your Review"
  }
}

export const setupReviewQuestions = (isCurrentCollegeStudent, role) => ({
  type: SETUP_REVIEW_QUESTIONS,
  payload: {
    isCurrentCollegeStudent,
    role
  },
})

export const setTitle = (data) => ({
  type: SET_TITLE,
  payload: data
})

export const setAnswer = (answer) => ({
  type: SET_ANSWER,
  payload: answer
})

export const setComment = (commentAndIndex) => ({
  type: SET_COMMENT,
  payload: commentAndIndex
})
