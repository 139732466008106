import React from 'react';
import AverageReview from '../../components/reviews/AverageReview';

import {
  College,
  CollegePhoto,
  CollegeInfo,
  ResultHeading,
  CollegeLocation,
  DotSeparator,
  CollegeStats,
  CollegeStatsType,
  CollegeStatsRank,
  OVplusIcon,
  CollegeStatsAcceptance,
  AverageReviewBox,
  ReviewsCount
} from './Styled';

const ExploreCollegesCard = ({ 
  handleSubmit,
  bgURL,
  name,
  formal_name,
  city,
  state,
  admissionsRate,
  ranksOv,
  fundingRate,
  averageReview,
  count
}) => (
  <College onClick={handleSubmit}>

    <CollegePhoto bgURL={bgURL}/>

    <CollegeInfo>
      <ResultHeading as='h5'>{name}</ResultHeading>
      <CollegeLocation>{formal_name} <DotSeparator/> {city}, {state}</CollegeLocation>
      <CollegeStats>
        <CollegeStatsRank>
          <OVplusIcon/>
          {ranksOv}
        </CollegeStatsRank>
        <DotSeparator/>
        <CollegeStatsAcceptance>{admissionsRate}% Acceptance Rate</CollegeStatsAcceptance>
        <DotSeparator/>
        <CollegeStatsType>{fundingRate}</CollegeStatsType>
      </CollegeStats>
      <AverageReviewBox>
        <AverageReview averageReview={averageReview} count={count}/>
        <ReviewsCount>{count} reviews</ReviewsCount>
      </AverageReviewBox>
    </CollegeInfo>
    
  </College>
);

export default ExploreCollegesCard;