import React from 'react';
import Filters from './Filters'

import{	
	Header,
	LogoContainer,
	StyledLogo,
	BlogName
} from './Styled'

const BlogHeader = ({
	categories
}) => (
	<Header>
		<LogoContainer>
			<StyledLogo/>
			<BlogName>Blog</BlogName>
		</LogoContainer>
		<Filters categories={categories}/>
	</Header>
);

export default BlogHeader;