import React from 'react'
import PropTypes from 'prop-types';
// Redux stuff
import { Field, reduxForm } from 'redux-form'
import { listConcentrations } from "../../modules/wizard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// Components
import StyledSelect from '../../components/styledSelect';

import {
  ErrorMessage,
  TitleHeading,
  NextButton
} from './Styled'

// Select Field
const selectField = ({ input, options, clearable, meta: { touched, active, error } , label}) => (
  <div>
    <StyledSelect
      instanceId={input.name}
      {...input}
      options={options}
      clearable={clearable}
      onBlur={() => input.onBlur}
      placeholder={label}
    />
    {touched && !active && error && <ErrorMessage>{error}</ErrorMessage>}
  </div>
);
// end of Select Field
// 
// Validation
const required = value => value ? undefined : 'Required';

let Step3 = props => {

  const {
    handleSubmit,
    majors,
    currentStep,
    concentrationsListFiltered,
    listConcentrations
  } = props;

  // Will hide the component if the current step is not equal to 3
  if (currentStep !== 3) { return null }

  const getMajorValue = (e) => {
    listConcentrations(majors, e.label);
  }

  // Majors options
  const majorSubjects = props.majors.map(e => ({
    label: `${e.subject} (${e.degree_abbr})`,
    value: e.ID
  }));
  const majorOpts = majorSubjects.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj["label"]).indexOf(obj["label"]) === pos;
  });
  
  return (
    <form onSubmit={handleSubmit}>
      <TitleHeading>What did you study?</TitleHeading>
      <Field 
        name="major" 
        label="Major" 
        component={selectField} 
        type="text"
        options={majorOpts}
        validate={[required]}
        onChange={getMajorValue}
      />
      <Field 
        name="concentration" 
        label="Concentration" 
        component={selectField}
        type="text"
        options={concentrationsListFiltered}
        validate={[required]}
      />
      <div>
        <NextButton type="submit">Next</NextButton>
      </div>
    </form>
  )
}

Step3.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

const mapStateToProps = ({ wizard, form }) => ({
  majors: wizard.majors,
  concentrationsListFiltered: wizard.concentrations_list
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {listConcentrations},
    dispatch
  );

Step3 = reduxForm({
  // a unique name for the form
  form: 'AthleteForm',
  destroyOnUnmount: false
})(Step3)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step3);