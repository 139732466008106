import React from 'react';
import Modal from 'react-modal';
import { ModalTitle, ModalText, CloseButtonContainer } from './Styled';
import CloseButton from '../../CloseButton';
import { isMobile, isDesktop, isTabletLand, isTabletPort } from '../../../config/app';

let customStyles;

if (isDesktop || isTabletLand){
  customStyles = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.35)',
      zIndex: '9999999',
    },
    content: {
      width: '703.2px',
      height: 'fit-content',
      marginLeft: 'calc(50% - 351.6px)',
      marginTop: '15%',
      inset: '0',
      padding: '5px 5px 0 36.2px',
      borderRadius: '5px',
      boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.16)',
      background: '#ffffff',
      display: 'flex',
      flexDirection: 'column',
    }
  };
};

if (isMobile || isTabletPort){ 
  customStyles = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.35)',
      zIndex: '9999999',
    },
    content: {
      width: '100%',
      height: '100%',
      inset: '0',
      padding: '5px 5px 0 36.2px',
      borderRadius: '5px',
      boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.16)',
      background: '#ffffff',
      display: 'flex',
      flexDirection: 'column',
    }
  };
};

const  CoachProfileModal = ({isClaimCoachProfileOpen, onToogleClaimCoachProfile, isCoachReviewOpen, onToogleCoachReview}) => {
  return (
    <Modal
    isOpen={isClaimCoachProfileOpen || isCoachReviewOpen}
    style={customStyles}
    contentLabel="Example Modal"
  >
    <CloseButtonContainer>
      {isClaimCoachProfileOpen && <CloseButton onClick={() => onToogleClaimCoachProfile(false)}/>}
      {isCoachReviewOpen && <CloseButton onClick={() => onToogleCoachReview(false)}/>}
    </CloseButtonContainer>
    {isClaimCoachProfileOpen && (
      <>
        <ModalTitle>
          Claim your coach profile
        </ModalTitle>
        <ModalText>
            To begin customizing your profile, viewing
            profile analytics, and setting up custom
            alerts, we must first verify you are the coach
            that this page profiles.<br /> <br />
            Contact <a href="mailto:coaches@officialvisit.com">coaches@officialvisit.com</a> and our
            support team will assist you!
        </ModalText>
      </>
    )}
    {isCoachReviewOpen && (
      <>
        <ModalTitle>
         Leave a coach review
        </ModalTitle>
        <ModalText>
          If you currently or previously play(ed)* for this coach and would like to
          leave a review:
          <br/><br/>
          1. Go to YOUR college’s OfficialVisit profile.<br/>
          2. Click the “Leave Review” button to leave a COLLEGE review.<br/>
          3. You will be asked to review any of your coaches who were at your 
          college at the same time as you.<br/>
          4. Verify your profile. Only verified reviews contribute to a coach’s 
            average rating. Contact <a href="mailto:support@officialvisit.com">support@officialvisit.com</a> if your profile is
          not already verified.
          <br/><br/>
          *You must have played for this coach for at least one season to leave a
          review.
        </ModalText>
     </>
    )}
  
  </Modal>
  );
};

export default CoachProfileModal;