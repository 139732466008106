import FormData from 'form-data';
import { apiEndpoint } from '../config/app';
import createRestApiClient from '../utils/createRestApiClient';

export default () => {
  // Adds token to client if it is passed in
  const token = localStorage.getItem('ovSessToken');
  const config = token ? { 
    baseURL: apiEndpoint, 
    headers: {
      'Authorization': 'Bearer ' + token
    } 
  } : { baseURL: apiEndpoint };

  const client = createRestApiClient().withConfig(config);
  return {
    getContentById: (content_id) => client.request({
      method: 'GET',
      url: '/api/content/' + content_id 
    }),
    getUserByContentId: (content_id) => client.request({
      method: 'GET',
      url: '/api/contentuser/' + content_id 
    }),
    uploadFiles: (files, ContributableId, updateUploadStatus) => {
      const data = new FormData();
      // append Id
      data.append('id', ContributableId);
      // append each file in array of files
      for( var i = 0; i < files.length; i++ ){
        let file = files[i];
        data.append('files', file);
      }
      return client.request({
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        url: '/a/content/upload',
        onUploadProgress: updateUploadStatus,
        data
      })
    },
    uploadSingleFile: (file, updateUploadStatus) => {
      const data = new FormData();
      // append each file to multipart form
      data.append('files', file);
      return client.request({
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        url: '/a/content/upload',
        onUploadProgress: updateUploadStatus,
        data
      })
    },
    AttractionsContentUpload: (formData, updateUploadStatus) => {
      return client.request({
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        url: '/a/attraction/content/upload',
        onUploadProgress: updateUploadStatus,
        data: formData,
      })
    },
    deleteContent: (contentId, role) => client.request({
      method: 'DELETE',
      url: `/${role === 'admin' ? 'aa' : 'a'}/content/${contentId}`,
    })
  };
};
