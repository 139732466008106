import Air_Force from '../../../assets/MapPictures/Air-Force.png';
import Akron from '../../../assets/MapPictures/Akron.png';
import Alabama from '../../../assets/MapPictures/Alabama.png';
import Appalachian_State from '../../../assets/MapPictures/Appalachian-State.png';
import Arizona_State from '../../../assets/MapPictures/Arizona-State.png';
import Arizona from '../../../assets/MapPictures/Arizona.png';
import Arkansas_State from '../../../assets/MapPictures/Arkansas-State.png';
import Arkansas from '../../../assets/MapPictures/Arkansas.png';
import Army_West_Point from '../../../assets/MapPictures/Army-West-Point.png';
import Auburn from '../../../assets/MapPictures/Auburn.png';
import Ball_State from '../../../assets/MapPictures/Ball-State.png';
import Baylor from '../../../assets/MapPictures/Baylor.png';
import Boise_State from '../../../assets/MapPictures/Boise-State.png';
import Boston_College from '../../../assets/MapPictures/Boston-College.png';
import Bowling_Green from '../../../assets/MapPictures/Bowling-Green.png';
import Buffalo from '../../../assets/MapPictures/Buffalo.png';
import BYU from '../../../assets/MapPictures/BYU.png';
import California from '../../../assets/MapPictures/California.png';
import Central_Michigan from '../../../assets/MapPictures/Central-Michigan.png';
import Charlotte from '../../../assets/MapPictures/Charlotte.png';
import Cincinnati from '../../../assets/MapPictures/Cincinnati.png';
import Clemson from '../../../assets/MapPictures/Clemson.png';
import Coastal_Carolina from '../../../assets/MapPictures/Coastal-Carolina.png';
import Colorado_State from '../../../assets/MapPictures/Colorado-State.png';
import Colorado from '../../../assets/MapPictures/Colorado.png';
import Duke from '../../../assets/MapPictures/Duke.png';
import East_Carolina from '../../../assets/MapPictures/East-Carolina.png';
import Eastern_Michigan from '../../../assets/MapPictures/Eastern-Michigan.png';
import FIU from '../../../assets/MapPictures/FIU.png';
import Florida_Atlantic from '../../../assets/MapPictures/Florida-Atlantic.png';
import Florida_State from '../../../assets/MapPictures/Florida-State.png';
import Florida from '../../../assets/MapPictures/Florida.png';
import Fresno_State from '../../../assets/MapPictures/Fresno-State.png';
import Georgia_Southern from '../../../assets/MapPictures/Georgia-Southern.png';
import Georgia_State from '../../../assets/MapPictures/Georgia-State.png';
import Georgia_Tech from '../../../assets/MapPictures/Georgia-Tech.png';
import Georgia from '../../../assets/MapPictures/Georgia.png';
import Hawaii from '../../../assets/MapPictures/Hawaii.png';
import Houston from '../../../assets/MapPictures/Houston.png';
import Illinois from '../../../assets/MapPictures/Illinois.png';
import Indiana from '../../../assets/MapPictures/Indiana.png';
import Iowa_State from '../../../assets/MapPictures/Iowa-State.png';
import Iowa from '../../../assets/MapPictures/Iowa.png';
import Kansas_State from '../../../assets/MapPictures/Kansas-State.png';
import Kansas from '../../../assets/MapPictures/Kansas.png';
import Kent_State from '../../../assets/MapPictures/Kent-State.png';
import Kentucky from '../../../assets/MapPictures/Kentucky.png';
import Liberty from '../../../assets/MapPictures/Liberty.png';
import Louisiana_Tech from '../../../assets/MapPictures/Louisiana-Tech.png';
import Louisiana_Monroe from '../../../assets/MapPictures/Louisiana-Monroe.png';
import Louisiana from '../../../assets/MapPictures/Louisiana.png';
import Louisville from '../../../assets/MapPictures/Louisville.png';
import LSU from '../../../assets/MapPictures/LSU.png';
import Marshall from '../../../assets/MapPictures/Marshall.png';
import Maryland from '../../../assets/MapPictures/Maryland.png';
import Memphis from '../../../assets/MapPictures/Memphis.png';
import Miami_Fl from '../../../assets/MapPictures/Miami-Fl.png';
import Miami_OH from '../../../assets/MapPictures/Miami-OH.png';
import Michigan_State from '../../../assets/MapPictures/Michigan-State.png';
import Michigan from '../../../assets/MapPictures/Michigan.png';
import Middle_Tennessee from '../../../assets/MapPictures/Middle-Tennessee.png';
import Minnesota from '../../../assets/MapPictures/Minnesota.png';
import Mississippi_State from '../../../assets/MapPictures/Mississippi-State.png';
import Missouri from '../../../assets/MapPictures/Missouri.png';
import Navy from '../../../assets/MapPictures/Navy.png';
import Nebraska from '../../../assets/MapPictures/Nebraska.png';
import Nevada from '../../../assets/MapPictures/Nevada.png';
import New_Mexico_State from '../../../assets/MapPictures/New-Mexico-State.png';
import New_Mexico from '../../../assets/MapPictures/New-Mexico.png';
import North_Carolina_State from '../../../assets/MapPictures/North-Carolina-State.png';
import North_Carolina from '../../../assets/MapPictures/North-Carolina.png';
import North_Texas from '../../../assets/MapPictures/North-Texas.png';
import Northern_Illinois from '../../../assets/MapPictures/Northern-Illinois.png';
import Northwestern from '../../../assets/MapPictures/Northwestern.png';
import Notre_Dame from '../../../assets/MapPictures/Notre-Dame.png';
import Ohio_State from '../../../assets/MapPictures/Ohio-State.png';
import Ohio from '../../../assets/MapPictures/Ohio.png';
import Oklahoma_State from '../../../assets/MapPictures/Oklahoma-State.png';
import Oklahoma from '../../../assets/MapPictures/Oklahoma.png';
import Old_Dominion from '../../../assets/MapPictures/Old-Dominion.png';
import Ole_Miss from '../../../assets/MapPictures/Ole-Miss.png';
import Oregon_State from '../../../assets/MapPictures/Oregon-State.png';
import Oregon from '../../../assets/MapPictures/Oregon.png';
import Penn_State from '../../../assets/MapPictures/Penn-State.png';
import Pittsburgh from '../../../assets/MapPictures/Pittsburgh.png';
import Purdue from '../../../assets/MapPictures/Purdue.png';
import Rice from '../../../assets/MapPictures/Rice.png';
import Rutgers from '../../../assets/MapPictures/Rutgers.png';
import San_Diego_State from '../../../assets/MapPictures/San-Diego-State.png';
import San_Jose_State from '../../../assets/MapPictures/San-Jose-State.png';
import SMU from '../../../assets/MapPictures/SMU.png';
import South_Alabama from '../../../assets/MapPictures/South-Alabama.png';
import South_Carolina from '../../../assets/MapPictures/South-Carolina.png';
import South_Florida from '../../../assets/MapPictures/South-Florida.png';
import Southern_Miss from '../../../assets/MapPictures/Southern-Miss.png';
import Stanford from '../../../assets/MapPictures/Stanford.png';
import Syracuse from '../../../assets/MapPictures/Syracuse.png';
import TCU from '../../../assets/MapPictures/TCU.png';
import Temple from '../../../assets/MapPictures/Temple.png';
import Tennessee from '../../../assets/MapPictures/Tennessee.png';
import Texas_AM from '../../../assets/MapPictures/Texas-AM.png';
import Texas_State from '../../../assets/MapPictures/Texas-State.png';
import Texas_Tech from '../../../assets/MapPictures/Texas-Tech.png';
import Texas from '../../../assets/MapPictures/Texas.png';
import Toledo from '../../../assets/MapPictures/Toledo.png';
import Troy from '../../../assets/MapPictures/Troy.png';
import Tulane from '../../../assets/MapPictures/Tulane.png';
import Tulsa from '../../../assets/MapPictures/Tulsa.png';
import UAB from '../../../assets/MapPictures/UAB.png';
import UCF from '../../../assets/MapPictures/UCF.png';
import UCLA from '../../../assets/MapPictures/UCLA.png';
import UConn from '../../../assets/MapPictures/UConn.png';
import UMass from '../../../assets/MapPictures/UMass.png';
import UNLV from '../../../assets/MapPictures/UNLV.png';
import USC from '../../../assets/MapPictures/USC.png';
import Utah_State from '../../../assets/MapPictures/Utah-State.png';
import Utah from '../../../assets/MapPictures/Utah.png';
import UTEP from '../../../assets/MapPictures/UTEP.png';
import UTSA from '../../../assets/MapPictures/UTSA.png';
import Vanderbilt from '../../../assets/MapPictures/Vanderbilt.png';
import Virginia_Tech from '../../../assets/MapPictures/Virginia-Tech.png';
import Virginia from '../../../assets/MapPictures/Virginia.png';
import Wake_Forest from '../../../assets/MapPictures/Wake-Forest.png';
import Washington_State from '../../../assets/MapPictures/Washington-State.png';
import Washington from '../../../assets/MapPictures/Washington.png';
import West_Virginia from '../../../assets/MapPictures/West-Virginia.png';
import Western_Kentucky from '../../../assets/MapPictures/Western-Kentucky.png';
import Western_Michigan from '../../../assets/MapPictures/Western-Michigan.png';
import Wisconsin from '../../../assets/MapPictures/Wisconsin.png';
import Wyoming from '../../../assets/MapPictures/Wyoming.png';


const images = {
  Air_Force,
  Akron,
  Alabama,
  Appalachian_State,
  Arizona_State,
  Arizona,
  Arkansas_State,
  Arkansas,
  Army_West_Point,
  Auburn,
  Ball_State,
  Baylor,
  Boise_State,
  Boston_College,
  Bowling_Green,
  Buffalo,
  BYU,
  California,
  Central_Michigan,
  Charlotte,
  Cincinnati,
  Clemson,
  Coastal_Carolina,
  Colorado_State,
  Colorado,
  Duke,
  East_Carolina,
  Eastern_Michigan,
  FIU,
  Florida_Atlantic,
  Florida_State,
  Florida,
  Fresno_State,
  Georgia_Southern,
  Georgia_State,
  Georgia_Tech,
  Georgia,
  Hawaii,
  Houston,
  Illinois,
  Indiana,
  Iowa_State,
  Iowa,
  Kansas_State,
  Kansas,
  Kent_State,
  Kentucky,
  Liberty,
  Louisiana_Tech,
  Louisiana_Monroe,
  Louisiana,
  Louisville,
  LSU,
  Marshall,
  Maryland,
  Memphis,
  Miami_Fl,
  Miami_OH,
  Michigan_State,
  Michigan,
  Middle_Tennessee,
  Minnesota,
  Mississippi_State,
  Missouri,
  Navy,
  Nebraska,
  Nevada,
  New_Mexico_State,
  New_Mexico,
  North_Carolina_State,
  North_Carolina,
  North_Texas,
  Northern_Illinois,
  Northwestern,
  Notre_Dame,
  Ohio_State,
  Ohio,
  Oklahoma_State,
  Oklahoma,
  Old_Dominion,
  Ole_Miss,
  Oregon_State,
  Oregon,
  Penn_State,
  Pittsburgh,
  Purdue,
  Rice,
  Rutgers,
  San_Diego_State,
  San_Jose_State,
  SMU,
  South_Alabama,
  South_Carolina,
  South_Florida,
  Southern_Miss,
  Stanford,
  Syracuse,
  TCU,
  Temple,
  Tennessee,
  Texas_AM,
  Texas_State,
  Texas_Tech,
  Texas,
  Toledo,
  Troy,
  Tulane,
  Tulsa,
  UAB,
  UCF,
  UCLA,
  UConn,
  UMass,
  UNLV,
  USC,
  Utah_State,
  Utah,
  UTEP,
  UTSA,
  Vanderbilt,
  Virginia_Tech,
  Virginia,
  Wake_Forest,
  Washington_State,
  Washington,
  West_Virginia,
  Western_Kentucky,
  Western_Michigan,
  Wisconsin,
  Wyoming,
};

export default images;