import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';

import { withLifecycleActions } from 'react-redux-lifecycle';

import UserContent from './userContent';
import ProfileContent from './profileContent';
import Loader from './loader';
import UserContributions from "../users/UserContributions";

const manageInitialState = ({
  isAuthenticated,
  requestLogin,
  match
}) => {

  if (!isAuthenticated) {
    return requestLogin(match.url);
  }

  return { type: 'NOOP' };
};

const Profile = ({
  profile,
  user,
  isFetching,
  isAuthenticated,
  isEditingUser,
  isEditingProfile,
  toggleEditProfile,
  toggleEditUser,
  toggleEditUserPicture,
  editUser,
  editProfile,
  colleges,
  contributions,
  targetAttractions,
  targetCoaches,
  getTargetAttractionsByContributionsIfEmpty,
  getTargetCoachesByContributionsIfEmpty,
  isFetchingReviews,
  push
}) => {
  // Loader
  if ( isFetching ) {
    return (
      <Loader />
    )
  }

  const [isLoaded, setIsLoaded] = useState(false);

  // load attractions and coaches on contributions loaded
  useEffect(() => {
    if (contributions && contributions.length) {
      getTargetAttractionsByContributionsIfEmpty(user.ID, contributions);
      getTargetCoachesByContributionsIfEmpty(user.ID, contributions);
    } else if(contributions !== false) {
      setIsLoaded(true);
    }
  }, [contributions]);

  useEffect(() => {
    if(targetAttractions !== false && targetCoaches !== false) {
      setIsLoaded(true);
    }
  }, [targetAttractions, targetCoaches]);

  return (
    <div>
      <Helmet>
        <title>My Profile - OfficialVisit</title>
        <meta name="description" content="Manage your OfficialVisit profile from your own profile page." />
      </Helmet>
      <UserContent
        isEditingUser={isEditingUser}
        user={user}
        profile={profile}
        editUser={editUser}
        toggleEditUser={toggleEditUser}
        toggleEditUserPicture={toggleEditUserPicture}
      />

      <ProfileContent
        isEditingProfile={isEditingProfile}
        user={user}
        profile={profile}
        colleges={colleges}
        editProfile={editProfile}
        toggleEditProfile={toggleEditProfile}
      />

      { !isLoaded && <Loader/> }
      { isLoaded &&
      <UserContributions
        user={ user }
        contributions={ contributions }
        push={ push }
        isAuthenticated={ isAuthenticated }
        isFetchingReviews={ isFetchingReviews }
        targetAttractions={ targetAttractions }
        colleges={ colleges }
        targetCoaches={ targetCoaches }
      /> }
    </div>
  )
};

Profile.propTypes = {
  user: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  requestLogin: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isEditingUser: PropTypes.bool.isRequired,
  isEditingProfile: PropTypes.bool.isRequired,
  getUserIfEmpty: PropTypes.func.isRequired,
  getProfileIfEmpty: PropTypes.func.isRequired,
  toggleEditProfile: PropTypes.func.isRequired,
  toggleEditUser: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  editProfile: PropTypes.func.isRequired,
  getTargetAttractionsByContributionsIfEmpty: PropTypes.func.isRequired,
  getTargetCoachesByContributionsIfEmpty: PropTypes.func.isRequired,
};

export default withLifecycleActions({ 
  componentDidMount: [manageInitialState]
})(Profile);