import React, {useState, Fragment, useEffect, useRef} from 'react';
import AverageReview from '../../reviews/AverageReview';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setAttractionAbout } from "../../../modules/attraction/actions";
import { OVButtonRight } from "../../../containers/attractionReview/Styled";
import { updateAttraction } from "../../../modules/attraction/attraction";

// Styles
import {
	AttractionCardCointainer,
	AttractionCardTitile,
	AttractionCardTitleBox,
	AttractionCardItemsContainer,
	AttractionCardBodyContainer,
	AttractionCardSubTitle,
	CategoryTag,
	SubCategoryTag,
	AttractionCardBody,
	ShowMoreButton,
	AttractionCardInput,
	AttractionAddress,
  StyledMarker
} from './Styled';

const topTags = tags => tags.map(tag => tag.title);
const subTags = tags => tags.reduce((memo, tag) => {
	if (tag.subTags) memo.push(tag.subTags);
	return memo;
}, []);

const AttractionCard = ({
	attraction,

	role,
	setAttractionAbout,
	updateAttraction,
}) =>{

	const [show, setShow] = useState(false);
	const [height, setHeight] = useState(0);
	const StatsContainerHeight = useRef(null);

	useEffect(() => {
    setHeight(StatsContainerHeight.current && StatsContainerHeight.current.clientHeight);
  })

	// hack for prevent error while attraction is null
	if (!attraction) attraction = { average_review: {}};

	const onChange = (e) => {
		return setAttractionAbout(e.target.value)
	};

	const { location } = attraction;

  return (
		<AttractionCardCointainer>
			<AttractionCardTitleBox>
				<AttractionCardTitile>{attraction.title}</AttractionCardTitile>
				<AverageReview big averageReview={attraction.average_review.average_rating} count={attraction.average_review.reviews_count} />
			</AttractionCardTitleBox>
			<AttractionCardItemsContainer>
				<AttractionAddress>
					<StyledMarker /> 
					{
						location && location.address && (
							`${location.address.address_line_1}, ${location.address.city}, ${location.address.state} ${location.address.postal_code}`
						)
					}
				</AttractionAddress>
				{ attraction.tags && (
					<Fragment>
						<AttractionCardSubTitle>Tags</AttractionCardSubTitle>	
						<div>
							{topTags(attraction.tags).map(tag => (
								<CategoryTag key={`tag-${tag}`}>{tag}</CategoryTag>
							))}
						</div>
						<div>
							{subTags(attraction.tags).map(tag => (
								<SubCategoryTag key={`subtag-${tag}`}>{tag}</SubCategoryTag>
							))}
						</div>
					</Fragment>
				)}
      </AttractionCardItemsContainer>

			{ role === "admin" && (
				<AttractionCardBodyContainer className={'editable'} data-show={true}>
					<AttractionCardSubTitle>About</AttractionCardSubTitle>
					<AttractionCardInput
						type="text"
						name="text"
						paceholder="Attraction about text"
						autoComplete="off"
						value={attraction.about}
					  onChange={onChange}/>
					<OVButtonRight onClick={() => updateAttraction(attraction)}>Submit</OVButtonRight>
				</AttractionCardBodyContainer>
			)}

			{ role !== "admin" && (
				<AttractionCardBodyContainer data-show={show}>
					<AttractionCardSubTitle>About</AttractionCardSubTitle>
					<div ref={StatsContainerHeight}>
						<AttractionCardBody>
							{attraction.about}
						</AttractionCardBody>
					</div>
        </AttractionCardBodyContainer>
			)}

      { role !== "admin" && height > 99 &&
        <ShowMoreButton onClick={() => setShow(!show)}>
        	{!show ? `Read more ▼` : `Read less ▲`}
      	</ShowMoreButton>
    	}
  	</AttractionCardCointainer>

  )
};

const mapDispatchToProps = dispatch => bindActionCreators({
	setAttractionAbout,
	updateAttraction,
}, dispatch);

export default connect(null, mapDispatchToProps)(AttractionCard);