import React from 'react';

import { PageContainer } from '../../components';
import UserEditForm from './editUser';
import { UserCircle } from 'styled-icons/fa-solid/UserCircle';

import {
  ProfileHeadingCenter,
  ProfileSubHeadingCenter,
  ProfileEditLink,
  PageContent,
  StyledCheck,
  UserHeader,
  UserAvatar,
  UserName,

} from './styled';

const User = ({ user, profile, toggleEditUser, toggleEditUserPicture }) => (
  <PageContainer>
    {toggleEditUser && <ProfileEditLink onClick={toggleEditUser}>Edit</ProfileEditLink>}
    <PageContent>
      <UserHeader>
        <UserAvatar
          avatar={user.avatar && user.avatar.src_md}
          data-blocked={!toggleEditUserPicture}
          onClick={toggleEditUserPicture}
        >
          <UserCircle />
        </UserAvatar>
      </UserHeader>

      <UserName>
        <ProfileHeadingCenter as="h1">
          {user.display_name || `${user.first_name} ${user.last_name}`} 
          {user.is_verified === true && <StyledCheck/>}
        </ProfileHeadingCenter>
      </UserName>
      {profile.about_me && <ProfileSubHeadingCenter as="h2">{profile.about_me}</ProfileSubHeadingCenter>}
    </PageContent>
  </PageContainer>
);

const UserContent = ({ isEditingUser, user, profile, editUser, toggleEditUser, toggleEditUserPicture }) => {
  // User Edition mode
  if (isEditingUser) {
    return (
      <PageContainer>
        <UserEditForm onSubmit={data => editUser(data)} onCancel={toggleEditUser} />
      </PageContainer>
    )

  // User content
  } else if ((user.email || user.display_name) && profile.ID) {
    return (
      <User 
        user={user}
        profile={profile}
        toggleEditUser={toggleEditUser}
        toggleEditUserPicture={toggleEditUserPicture}
      />
    )
  }
  
  return null;
};

export default UserContent;
