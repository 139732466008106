import React from 'react';
import styled from 'styled-components';


const Container12 = styled.div`

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 20px;
  padding: 0 20px;
  width: 100%;
`;

export default ({children}) => (
  <Container12>
    { children }
  </Container12>
)