import styled from 'styled-components';
import { color, typo} from "../../styles";

export const PhotoBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  > span {
    padding: 10px;
  }
`;

export const PhotoMoreImgContainer = styled.div`
  position: relative;
`;

export const PhotoMoreImgCount = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  cursor: pointer;
  
  span {
    font-size: 60px;
    font-weight: bold;
    font-family: ${typo.ovFontAlt};
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: ${color.ovWhite};
    text-transform: uppercase;
    text-align: center;
  }
`;

export const PhotoImg = styled.div`
  width: 246px;
  height: 168px;
  border: solid 1px #95989a;
  cursor: pointer;
  background-image: url(${props => props.imgUrl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const PhotoTitle = styled.p`
  font-size: 23px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  margin: 0;
  padding: 0 0 5px 0;
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
`;

export const PhotoDescription = styled.p`
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  margin: 0;
  padding: 0 0 10px 0;
  color: ${color.ovBlack};
  font-family: ${typo.ovFontSerif};
`;