import React, { useEffect } from 'react';

import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import { getLargestStadiums } from '../../modules/home';
import { listCollegesIfEmpty } from '../../modules/college';
import { baseSelector } from '../../modules/college/selectors';
import HomeCollegesSlider from '../HomeCollegesSlider';

const Wrapper = ({ isFetching, colleges, topColleges, listCollegesIfEmpty, getLargestStadiums, push }) => {
  useEffect(() => {

    if (!colleges || !colleges.length) {
      listCollegesIfEmpty();

    } else if (!topColleges) {
      getLargestStadiums();
    };

  }, [colleges, topColleges]);

  return (
    <HomeCollegesSlider
      topColleges={topColleges}
      isFetching={isFetching}
      push={push}
    />
  )
}

const mapStateToProps = (state) => {
  const { user, home: { largestStadiums: topColleges }, college: { colleges } } = state;

  return {
    isFetching: baseSelector(state).isFetchingCollege,
    isAuthenticated: user.isAuthenticated,
    colleges,
    topColleges,
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({
  push,
  listCollegesIfEmpty,
  getLargestStadiums: () => getLargestStadiums(['michigan', 'penn-state', 'ohio-state', 'texas-am', 'tennessee']),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper)