import styled from 'styled-components';
import { color, bp } from '../../../styles';

export const CollegeSmallCardBox = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0, 0.16);
  border: solid 1px rgba(0,0,0, 0.16);
  background-color: ${color.ovWhite};
  padding: 11px 7px;
  min-height: 45px;
  align-self: start;
  @media ${bp.phoneOnly} {
    &:last-child{
      margin-bottom: 17px;
    }
  }
`;

export const CollegeSmallCardTitle = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`;