import React, {useEffect} from 'react'
// Redux stuff
import { Field, reduxForm, formValueSelector, change } from 'redux-form'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// Components
import StyledSelect from '../../components/styledSelect';

import {
  ErrorMessage,
  TitleHeading,
  NextButton
} from './Styled'

// Select Field
const selectField = ({ input, options, clearable, defaultValue, value, meta: { touched, active, error } , label}) => (
  <div>
    <StyledSelect
      instanceId={input.name}
      {...input}
      options={options}
      clearable={clearable}
      onBlur={() => input.onBlur}
      placeholder={label}
      defaultValue={defaultValue}
      value={value}
    />
    {touched && !active && error && <ErrorMessage>{error}</ErrorMessage>}
  </div>
);
// end of Select Field
// 
// Validation
const required = value => value ? undefined : 'Required';

let Step5 = props => {

  const {
    handleSubmit,
    colleges,
    currentStep,
    collegeValue,
    change
  } = props;

  useEffect(() => {
    change('favoriteteam', collegeValue)
  });

  // Will hide the component if the current step is not equal to 3
  if (currentStep !== 5) { return null }

  // Maps the colleges to create the Select Inpit Options
  const collegeOpts = colleges.map(e => ({ label: e.name, value: e.ID }));
  
  return (
    <form onSubmit={handleSubmit}>
      <TitleHeading>Who is your favorite football team?</TitleHeading>
      <Field 
        name="favoriteteam" 
        label="Favorite Team" 
        component={selectField} 
        type="text"
        options={collegeOpts}
        validate={[required]}
        defaultValue={collegeValue ? collegeValue : 'Favorite Team:'}
      />
      <div>
        <NextButton type="submit">Sign Up</NextButton>
      </div>
    </form>
  )
}

const mapStateToProps = ({ college }) => ({
  colleges: college.colleges
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {change},
    dispatch
  );

Step5 = reduxForm({
  // a unique name for the form
  form: 'AthleteForm',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(Step5)

const selector = formValueSelector('AthleteForm');
// Gets the college input value from Step 1
Step5 = connect(state => {
  const collegeValue = selector(state, 'college');
  return {
    collegeValue
  };
})(Step5);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step5);