import Butter from 'buttercms';
import { CMS_KEY } from '../config/env';

const client = Butter(CMS_KEY);
const PAGE_SIZE = 20;
export default {

  listCategories() {
    return client.category.list();
  },

  listPosts(page = 1, category) {
    let categoryHash = category
      ? { category_slug: category }
      : null;

    return client.post.list({ page, page_size: PAGE_SIZE, ...categoryHash });
  },

  getPost(slug) {
    return client.post.retrieve(slug);
  }
};