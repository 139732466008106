import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { listCollegesIfEmpty } from '../../../modules/college';
import { rankByWeatherSelector, baseSelector, pageSelector } from '../../../modules/college/selectors';
import { getUserIfEmpty } from '../../../modules/user';
import CollegeWarmest from "./CollegeWarmest";


const mapStateToProps = (state) => {
  const ranked = rankByWeatherSelector(state);
  return {
    isFetching: baseSelector(state).isFetchingCollege,
    paginate: pageSelector(ranked),
    count: ranked.length,
  }
};


const mapDispatchToProps = dispatch => bindActionCreators({
  listCollegesIfEmpty,
  getUserIfEmpty,
  push,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CollegeWarmest)