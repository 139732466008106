import styled from 'styled-components';
import { bp } from '../../styles'

export const Wrapper = styled.div`
	overflow-y: hidden;
	margin-bottom: 1rem;
	position: relative;
`;

export const ContainerStyle = {
	'overflowX': 'scroll',
	'overflowY': 'hidden',
	'scrollBehavior': 'smooth',
	'scrollSnapType': 'x mandatory',
	'padding': '5px 0 20px 0',
	'WebkitOverflowScrolling': 'touch',
	'scrollSnapPointsX': 'repeat(33.3%)',
};

export const ListContainer = styled.div`
	display: flex;
	width: calc(33.3% * ${props => props.length});
	@media ${bp.phoneOnly} {
		width: calc(45% * ${props => props.length});
	}
`;

export const CardItem = styled.div`
	flex-basis: 33.3%;
	padding: 0 15px;
	scroll-snap-align: start;
	min-width: 243px;
	@media ${bp.phoneOnly} {
		flex-basis: 45%;
		min-width: 169px;
	}
`;

export const Handler = styled.div`
	z-index: 9;
	position: absolute;
	width: 2.3rem;
	height: 2.3rem;
	border-radius: 2rem;
	background-color: white;
	top: calc(50% - 2rem);
	box-shadow: 0 5px 7px 0 rgba(0,0,0,0.16);
	text-align: center;

	@media ${bp.phoneOnly} {
		display: none;
	}
`;

export const HandlerLeft = styled(Handler)`
	left: 5px;
	::after {
		display: block;
		content: '<';
	}
`;

export const HandlerRight = styled(Handler)`
	right: 5px;
	::after {
		display: block;
		content: '>';
	}
`;