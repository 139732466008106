import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Container12 } from '../../components';

import PictureForm from './RegisterPictureForm';
import PictureDropZone from './RegisterPictureDropZone';

import './register-picture.css';

const cleanDropStateEffect = ({ cleanDropState }) => () => {
  cleanDropState();
};

// Clears the drop state and toogles dropzone
// Useful in case there is already a picture in store
const toggleDropZoneEffect = ({ toggleDropZone, cleanDropState }) => () => {
  toggleDropZone(true);
  cleanDropState();
}

const submitUserAvatarEffect = ({ submitUserAvatar, push, nextPath}) => () => {
  submitUserAvatar();
  push(nextPath || '/');
}

const skipEffect = ({ push, nextPath }) => () => {
  push(nextPath || '/');
}

const RegisterPictureContainer = props => {
  const { picture } = props;
  const [showDropZone, toggleDropZone] = useState(false);

  useEffect(cleanDropStateEffect(props), []); // Empty array will use effect only on component did mount
  return (
    <Modal
      className='RegistrationPicture'
      isOpen={true}
    >
      <Container12>
        { !showDropZone
          ? <PictureForm
              onToggleDropZone={toggleDropZoneEffect({ ...props, toggleDropZone })}
              onSubmit={submitUserAvatarEffect(props)}
              onSkip={skipEffect(props)}
              picture={picture}
              nextPath={props.nextPath}
            />
          : <PictureDropZone
              {...props}
              onToggleDropZone={toggleDropZone}
            />
        }
      </Container12>
    </Modal>
  )
};

export default RegisterPictureContainer;
