import React from 'react';
import CollegeLogo from '../../CollegeLogo';

import {
  ItemContainer,
  SchoolDataContainer,
  SchoolImg,
  SchoolName,
  SchoolPhoto,
  RemoveButton
} from './Styled'

// TopSchools Backgrounds
import default_bkg from '../../../assets/img/profile-bg.jpg'

// TopSchools Backgrounds
const bkgContext = require.context('../../../assets/topschools_backgrounds', true, /\.jpg$/);
const bkgPaths = bkgContext.keys();

export const Bkg = bkgPaths.reduce((memo, key) => {
  memo[key.replace(/(\.\/)|(\.jpg)/g, '')] = bkgContext(key);
  return memo;
}, { default_bkg });

const Item = ({
  id,
  name,
  onRemove,
}) => (
    <ItemContainer draggable={true}>
      <SchoolDataContainer>
        <SchoolImg>
          <CollegeLogo name={name} alt={name} />
        </SchoolImg>
        <SchoolName>{name}</SchoolName>
      </SchoolDataContainer>
      <SchoolPhoto bgUrl={(Bkg[name.replace(/[\s-\n]/gi, "_").replace(/['&()]/gi, "").toLowerCase()] || Bkg["default_bkg"])} />
      <RemoveButton onClick={() => onRemove(id)} />
    </ItemContainer>
  )

export default Item;
