import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import App from './containers/app'

import './index.css'

const target = document.querySelector('#root')

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        {/* Adding id for react-modal - http://reactcommunity.org/react-modal/accessibility/ */}
        <App id="root" />
      </div>
    </ConnectedRouter>
  </Provider>,
  target
)