import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import { Bkg } from '../../components/CollegeLogo';
import Filters from '../../components/ExploreCoaches/Filters';
import SearchBar from '../../components/ExploreCoaches/SearchBar';
import 'react-input-range/lib/css/index.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import CoachCard from '../../components/ExploreCoaches/CoachCard';
import Pagination from '../../components/ExploreCoaches/Pagination';

import {
  EighthPartPageContent,
  QuarterPartPageContent,
  ExploreCollegesBox,
  CollegeListTotal,
  PageContainer,
  PageTitle,
} from './Styled'

const INITIAL_FILTERS = {
  year: [],
  title: [],
  college: [],
  query: '',
};

const SORT_OPTIONS = [
  { value: 'reviews_count&DESC', label: 'Most reviews' },
  { value: 'average_rating&DESC', label: 'Average rating ▼' },
  { value: 'average_rating&ASC', label: 'Average rating ▲' }
];

const getSorting = (option, def) => (option || def).value.split('&');

const Coaches = ({ colleges, listCollegesIfEmpty, isFetchingCollege, coaches, count, isFetchingCoaches, searchCoaches, titles, getCoachTitles, push }) => {
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [sorting, setSorting] = useState(SORT_OPTIONS[0]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const [sortBy, sortDir] = getSorting(sorting);
    searchCoaches({}, page, sortBy, sortDir);
    getCoachTitles();
    listCollegesIfEmpty();
  }, []);

  const [show, setShowFilters] = useState(false);

  const handleFilters = useCallback(({ year, title, college, query }, selectedSorting, selectedPage) => {
    setFilters({ year, title, college, query });
    const [sortBy, sortDir] = getSorting(selectedSorting, sorting);
    const pageNumber = selectedPage || page;
    searchCoaches({
        year: year && year.map(({value}) => value),
        title: title && title.map(({ value }) => value),
        college: college && college.map(({ value }) => value),
        name: query,
      },
      pageNumber,
      sortBy,
      sortDir
    );
  }, [coaches, setFilters, sorting, page]);

  const handleChangeQuery = useCallback((query) => {
    setPage(1);
    handleFilters({ ...filters, query }, sorting, 1);
  }, [coaches, filters, sorting, handleFilters]);

  const handleSorting = useCallback((value) => {
    setSorting(value);
    setPage(1);
    handleFilters(filters, value, 1);
  }, [coaches, filters, handleFilters, setSorting]);

  const handleClearFilters = useCallback(() => {
    setPage(1);
    handleFilters(INITIAL_FILTERS, sorting, 1);
  }, [sorting, handleFilters]);

  const handleChangePage = useCallback((page) => {
    setPage(page);
    handleFilters(filters, sorting, page);
  }, [filters, sorting, handleFilters]);

  const handleToggleFilters = useCallback(() => {
    setShowFilters(!show);
  }, [show, setShowFilters]);

  return (
    <div>
      <Helmet>
        <title>Explore Colleges - OfficialVisit</title>
        <meta name="description" content="Explore and filter colleges by size, weather, rankings, admissions, outcomes, and football metrics. Find the right college for you!" />
      </Helmet>

      <PageContainer>
        <QuarterPartPageContent data-show={show}>
          <PageTitle>Explore Coaches</PageTitle>
        </QuarterPartPageContent>
        <EighthPartPageContent data-show={show}>
          <SearchBar 
            query={filters.query}
            onShowFilters={handleToggleFilters}
            onChangeQuery={handleChangeQuery}
            onClearFilters={handleClearFilters}
            sortOptions={SORT_OPTIONS}
            onChangeSorting={handleSorting}
          />
        </EighthPartPageContent>
      </PageContainer>

      <PageContainer>
          <QuarterPartPageContent data-show={show}>
            <Filters 
              colleges={colleges}
              titles={titles}
              filters={filters}
              onChange={handleFilters}
              onClearFilters={handleClearFilters}
              onToggleFilters={handleToggleFilters}
            />
          </QuarterPartPageContent>
          <EighthPartPageContent data-show={show}>
            <ExploreCollegesBox>

              {isFetchingCoaches 
                ? <Spinner fadeIn="none" name="cube-grid" />
                : coaches.map((coach) => (
                  <CoachCard coach={coach}/>
                )
              )}
            
              {(coaches && !!coaches.length)
                ? <Pagination count={count} current={page} onChange={handleChangePage} />
                : <h4>No coaches found</h4>
              }
             
            </ExploreCollegesBox>
          </EighthPartPageContent>
      </PageContainer>
    </div>
  )
};

Coaches.propTypes = {
  colleges: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  listCollegesIfEmpty: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

export default Coaches;
