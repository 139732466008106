import styled from 'styled-components';
import { color, typo } from '../../styles';

export const ContactPageBox = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(${color.ovBlack}, 0.16);
  background-color: ${color.ovWhite};
  overflow: hidden;
  margin-bottom: 25px;
  grid-column: 12 span;
`;

export const ContactPageContent = styled.div`
  padding: 18px 14px;
`;

export const ContactPageBarBox = styled.div`
  height: 52px;
  border-bottom: solid 0.5px ${color.ovGreyDarker};
`;

export const ContactPageTitle = styled.div`
  font-family: ${typo.ovFontFeature};
  font-size: 28px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlack};
  margin: 0;
  padding-left: 17px;
  display: flex;
  align-content: center;
  line-height: 1.7;
  text-transform: capitalize;
`;

export const ContactParagraph = styled.p`
  font-size: 21px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlack};
  font-family: ${typo.ovFontSerif};
`;
export const ContactTitle = styled.h2`
  font-size: 20px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
`;