import styled from 'styled-components';
import { Col } from 'react-bootstrap'
import { Button } from 'rebass';
import { color, typo, bp } from '../../styles'

export const TopListContainer = styled.div`
  margin: 62px 0;
`;

export const StyledColContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const StyledCol = styled(Col)`
  flex-basis: 33.33%;
  padding: 15px;
  @media ${bp.phoneOnly} {
  flex-basis: 50%;
   &:last-child{
    display: none;
   }
  }
`;

export const ImageInfo = styled.div`
  text-align: right !important;
  padding: 24px !important;
  position: absolute !important;
  bottom: 0px !important;
  right: 0px !important;
`;

export const ImageText = styled.p`
  text-decoration: none;
  display: block;
  color: ${color.ovWhite};
  font-size: ${typo.navLinkSize};
  font-family: ${typo.ovFontFeature};
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
`;

export const HeaderHome = styled.div`
  width: 100%;
  height: calc(100vh - 132px);
  background: ${color.ovGrey};
  margin-top: -104px;
  display: flex;
  padding-top: 35vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${props => props.bgUrl});
`;

export const ExploreContainer = styled.div`
  padding: 46px 0;
`;

export const BigButtonCol = styled(Col)`
  display: flex;
  flex-direction: row;
  a{
    margin-right: 30px;
    &:last-child{
      margin-right: 0;
    }
  }
`;

export const TopRanksContainer = styled.div`
  margin: 62px 0;
`;

export const HomeBigCardButton = styled(Button)`
	font-family: ${typo.ovFontFeature};
	font-size: 28px;
	font-weight: 100;
	font-style: normal;
	font-stretch: condensed;
	line-height: 0.93;
	letter-spacing: normal;
	text-align: left;
	color: ${color.ovBlack};
	background: transparent;
	padding: 0;
	cursor: pointer;
	transition: all .1s;
	@media ${bp.phoneOnly} {
	    font-size: 24px;
    }
	span{
		display: block;
	    margin: 3px 0 0 10px;
	    float: right;
	    color: ${color.ovBlack};
	    transition: all .1s;
	}
	&:hover{
		color: #fcd068;
		transition: all .1s;
		span{
			color: #fcd068;
			transition: all .1s;
		}
	}
`;