import React from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import CollegeSmallCard from '../../../components/College/CollegeSmallCard';
import CollegeAbout from '../../../components/College/CollegeAbout';

import {
  CollegeSmallCardRow,
  CollegeSmallCardTop,
  CollegeSmallCardTitleSmall,
  CollegeSmallCardValue,
  CollegeRankingPosition,
  CollegeRankingTotal,
  CollegeItemLogo,
  CollegeTableColumnRanking,
  CollegeTableColumnValue,
  CollegeTableColumnLabel,
  CollegeTable,
  CollegeTableItem,
  CollegeTableRow,
} from '../StyledUI';

import { Brands } from '../StatsIcons';

const TeamStats = ({ header, about, stadium, graduation, nfl, web, college, push }) => {

  function toLocaleString(value){
    return value ? value.toLocaleString('EN') : '-';
  }

  return (
    <div>
      <CollegeSmallCardRow>

        <CollegeSmallCard>
          <CollegeSmallCardTop>
            <CollegeSmallCardTitleSmall>
              Conference
            </CollegeSmallCardTitleSmall>
            <CollegeSmallCardValue>
              <CollegeItemLogo bgUrl={Brands[header.conference.value]}/>
            </CollegeSmallCardValue>
          </CollegeSmallCardTop>
        </CollegeSmallCard>

        <CollegeSmallCard>
          <CollegeSmallCardTop>
            <CollegeSmallCardTitleSmall>
              Apparel Brand
            </CollegeSmallCardTitleSmall>
            <CollegeSmallCardValue>
              <CollegeItemLogo bgUrl={Brands[header.apparel.value]} />
            </CollegeSmallCardValue>
          </CollegeSmallCardTop>
        </CollegeSmallCard>

      </CollegeSmallCardRow>

      {about[0] &&
        <CollegeAbout 
          content={about[0].data[0].value} 
          title={`${college.name} ${college.nickname} football`} 
          team={college.name}
        />
      }

      <CollegeTable>

        {/* stadium */}
        <CollegeTableItem>

          <CollegeTableRow>
            <CollegeTableColumnLabel>Stadium</CollegeTableColumnLabel>
            <CollegeTableColumnValue>{stadium[0] ? stadium[0].data[0].name : "-"}</CollegeTableColumnValue>
          </CollegeTableRow>

          <CollegeTableRow>
            <CollegeTableColumnLabel>Capacity</CollegeTableColumnLabel>
            <CollegeTableColumnValue>
              {stadium[0] ? toLocaleString(stadium[0].data[0].value) : "-"}
              <CollegeTableColumnRanking onClick={() => push('/largest-cfb-stadiums')}>
                <CollegeRankingPosition>{stadium[0] ? stadium[0].data[0].rank : "-"}</CollegeRankingPosition>
                <CollegeRankingTotal> of {stadium[0] ? stadium[0].data[0].total : "-"}</CollegeRankingTotal>
              </CollegeTableColumnRanking>
            </CollegeTableColumnValue>
          </CollegeTableRow>

        </CollegeTableItem>

        {/* graduation */}
        <CollegeTableItem>

          <CollegeTableRow>
            <CollegeTableColumnLabel>Graduation Success Rate</CollegeTableColumnLabel>
            <CollegeTableColumnValue>
              {graduation[0] ? graduation[0].data[0].value : "-"}%
              <CollegeTableColumnRanking onClick={() => push('/graduation-success-rates')}>
                <CollegeRankingPosition>{graduation[0] ? graduation[0].data[0].rank : "-"}</CollegeRankingPosition>
                <CollegeRankingTotal> of {graduation[0] ? graduation[0].data[0].total : "-"}</CollegeRankingTotal>
              </CollegeTableColumnRanking>
            </CollegeTableColumnValue>    
          </CollegeTableRow>

          <CollegeTableRow>
            <CollegeTableColumnLabel>Black Graduation Success Rate</CollegeTableColumnLabel>
            <CollegeTableColumnValue>
              {graduation[0] ? graduation[0].data[1].value : "-"}%
              <CollegeTableColumnRanking onClick={() => push('/black-graduation-success-rates')}>
                <CollegeRankingPosition>{graduation[0] ? graduation[0].data[1].rank : "-"}</CollegeRankingPosition>
                <CollegeRankingTotal> of {graduation[0] ? graduation[0].data[1].total : "-"}</CollegeRankingTotal>
              </CollegeTableColumnRanking>
            </CollegeTableColumnValue>
          </CollegeTableRow>

          <CollegeTableRow>
            <CollegeTableColumnLabel>
              White Graduation Success Rate
            </CollegeTableColumnLabel>
            <CollegeTableColumnValue>
              {graduation[0] ? graduation[0].data[2].value : "-"}%
              <CollegeTableColumnRanking onClick={() => push('/white-graduation-success-rates')}>
                <CollegeRankingPosition>{graduation[0] ? graduation[0].data[2].rank : "-"}</CollegeRankingPosition>
                <CollegeRankingTotal> of {graduation[0] ? graduation[0].data[2].total : "-"}</CollegeRankingTotal>
              </CollegeTableColumnRanking>
            </CollegeTableColumnValue>
            
          </CollegeTableRow>

          <CollegeTableRow>
            <CollegeTableColumnLabel>Academic Progress Rate</CollegeTableColumnLabel>
            <CollegeTableColumnValue>
              {graduation[0] ? graduation[0].data[3].value : "-"}
              <CollegeTableColumnRanking>
                <CollegeRankingPosition>{graduation[0] ? graduation[0].data[3].rank : "-"}</CollegeRankingPosition>
                <CollegeRankingTotal> of {graduation[0] ? graduation[0].data[3].total : "-"}</CollegeRankingTotal>
              </CollegeTableColumnRanking>
            </CollegeTableColumnValue>
            
          </CollegeTableRow>

        </CollegeTableItem>

        {/* nfl */}
        {nfl[0] &&
        <CollegeTableItem>
          {nfl[0].data.map((item, index)=>{
            return(
              <CollegeTableRow key={index}>
                <CollegeTableColumnLabel>{item.label}</CollegeTableColumnLabel>
                <CollegeTableColumnValue>
                  {item.value}
                  <CollegeTableColumnRanking>
                    <CollegeRankingPosition>{item.rank}</CollegeRankingPosition>
                    <CollegeRankingTotal> of {item.total}</CollegeRankingTotal>
                  </CollegeTableColumnRanking>
                </CollegeTableColumnValue>
              </CollegeTableRow>
            )
          })}
        </CollegeTableItem>
        }

        {/* website */}
        {web[0] &&
        <CollegeTableItem>
          {web[0].data.map((item, index)=>{
            return(
              <CollegeTableRow key={index}>
                <CollegeTableColumnLabel>{item.label}</CollegeTableColumnLabel>
                <CollegeTableColumnValue>
                  <a target="_blank" href={item.value} rel="noopener noreferrer">
                    {item.label === "Twitter" && "View on Twitter"}
                    {item.label === "Facebook" && "View on Facebook"}
                    {item.label === "LinkedIn" && "View on Linkedin"}
                    {item.label === "Official athletic site" && item.value}
                  </a></CollegeTableColumnValue>
              </CollegeTableRow>
            )
          })}
        </CollegeTableItem>
        }

      </CollegeTable>
      
    </div>
  )
};


const mapDispatchToProps = dispatch => bindActionCreators({
  push
}, dispatch)

export default connect(null, mapDispatchToProps)(TeamStats)