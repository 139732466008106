export const LIST_CATEGORIES_REQUESTED = 'blog/LIST_CATEGORIES_REQUESTED';
export const LIST_CATEGORIES_SUCCESS = 'blog/LIST_CATEGORIES_SUCCESS';
export const LIST_CATEGORIES_FAILURE = 'blog/LIST_CATEGORIES_FAILURE';

export const LIST_POSTS_REQUESTED = 'blog/LIST_POSTS_REQUESTED';
export const LIST_POSTS_SUCCESS = 'blog/LIST_POSTS_SUCCESS';
export const LIST_POSTS_FAILURE = 'blog/LIST_POSTS_FAILURE';

export const GET_POST_REQUESTED = 'blog/GET_POST_REQUESTED';
export const GET_POST_SUCCESS = 'blog/GET_POST_SUCCESS';
export const GET_POST_FAILURE = 'blog/GET_POST_FAILURE';


export const listCategoriesRequest = (page) => ({
  type: LIST_CATEGORIES_REQUESTED,
  payload: { page },
});

export const listCategoriesSuccess = (data) => ({
  type: LIST_CATEGORIES_SUCCESS,
  payload: data,
});

export const listCategoriesFailure = (message) => ({
  type: LIST_CATEGORIES_FAILURE,
  payload: { message },
});


export const listPostsRequest = (page, cagetory) => ({
  type: LIST_POSTS_REQUESTED,
  payload: { page, cagetory },
});

export const listPostsSuccess = (data) => ({
  type: LIST_POSTS_SUCCESS,
  payload: data,
});

export const listPostsFailure = (message) => ({
  type: LIST_POSTS_FAILURE,
  payload: { message },
});

export const getPostRequest = (slug) => ({
  type: GET_POST_REQUESTED,
  payload: { slug },
});

export const getPostSuccess = (data) => ({
  type: GET_POST_SUCCESS,
  payload: data,
});

export const getPostFailure = (message) => ({
  type: GET_POST_FAILURE,
  payload: { message },
});
