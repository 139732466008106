import styled from 'styled-components';
import { color} from '../../styles';

export const DropzoneContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-color: transparent;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  outline: none;
  transition: border .24s ease-in-out, background .24s ease-in-out;
  
  &.active {
   background-color: ${color.ovTransparentBlue};
   border-color: ${color.ovBlue};
  }
`;

export const EditBox = styled.div`
  position: absolute;
  cursor: pointer;
  z-index: 99;
  width: 180px;
  height: 180px;
  border: none;
  top: -140px;
  opacity: 0;
  background: ${color.ovGreyLight};
  border-radius: 50%;
  padding: 20px;
  &:hover{
    opacity: .8;
  }
`;

export const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 100%;
`;