
export const LOGIN_REQUESTED = 'user/LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'user/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'user/LOGIN_FAILURE';

export const loginRequest = () => ({
  type: LOGIN_REQUESTED
});

export const loginSuccess = () => ({
  type: LOGIN_SUCCESS
});

export const loginFailure = (message) => ({
  type: LOGIN_FAILURE,
  payload: message
});

export const FORGOT_PASSWORD_STATE_RESET = 'user/FORGOT_PASSWORD_STATE_RESET';
export const FORGOT_PASSWORD_REQUESTED = 'user/FORGOT_PASSWORD_REQUESTED';
export const FORGOT_PASSWORD_SUCCESS = 'user/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'user/FORGOT_PASSWORD_FAILURE';
export const FORGOT_PASSWORD_VALIDATE_REQUESTED = 'user/FORGOT_PASSWORD_VALIDATE_REQUESTED';
export const FORGOT_PASSWORD_VALIDATE_SUCCESS = 'user/FORGOT_PASSWORD_VALIDATE_SUCCESS';
export const FORGOT_PASSWORD_VALIDATE_FAILURE = 'user/FORGOT_PASSWORD_VALIDATE_FAILURE';
export const FORGOT_PASSWORD_RESET_REQUESTED = 'user/FORGOT_PASSWORD_RESET_REQUESTED';
export const FORGOT_PASSWORD_RESET_SUCCESS = 'user/FORGOT_PASSWORD_RESET_SUCCESS';
export const FORGOT_PASSWORD_RESET_FAILURE = 'user/FORGOT_PASSWORD_RESET_FAILURE';

export const forgotPasswordStateReset = () => ({
  type: FORGOT_PASSWORD_STATE_RESET
});

export const forgotPasswordRequest = () => ({
  type: FORGOT_PASSWORD_REQUESTED
});

export const forgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_SUCCESS
});

export const forgotPasswordFailure = (message) => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: message
});

export const forgotPasswordValidateRequest = () => ({
  type: FORGOT_PASSWORD_VALIDATE_REQUESTED
});

export const forgotPasswordValidateSuccess = () => ({
  type: FORGOT_PASSWORD_VALIDATE_SUCCESS
});

export const forgotPasswordValidateFailure = (message) => ({
  type: FORGOT_PASSWORD_VALIDATE_FAILURE,
  payload: message
});

export const forgotPasswordResetRequest = () => ({
  type: FORGOT_PASSWORD_RESET_REQUESTED
});

export const forgotPasswordResetSuccess = (message) => ({
  type: FORGOT_PASSWORD_RESET_SUCCESS,
  payload: message
});

export const forgotPasswordResetFailure = (message) => ({
  type: FORGOT_PASSWORD_RESET_FAILURE,
  payload: message
});

export const GET_USER_REQUESTED = 'user/GET_USER_REQUESTED';
export const GET_USER_SUCCESS = 'user/GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'user/GET_USER_FAILURE';

export const requestGetUser = () => ({
  type: GET_USER_REQUESTED
});

export const getUserSuccess = (data) => ({
  type: GET_USER_SUCCESS,
  payload: data
});

export const getUserFailure = (message) => ({
  type: GET_USER_FAILURE,
  payload: message
});

export const GET_PROFILE_REQUESTED = 'user/GET_PROFILE_REQUESTED';
export const GET_PROFILE_SUCCESS = 'user/GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'user/GET_PROFILE_FAILURE';

export const requestGetProfile = () => ({
  type: GET_PROFILE_REQUESTED
});

export const getProfileSuccess = (data) => ({
  type: GET_PROFILE_SUCCESS,
  payload: data
});

export const getProfileFailure = (message) => ({
  type: GET_PROFILE_FAILURE,
  payload: message
});

export const GET_USER_CONTRIBUTIONS_REQUESTED = 'user/GET_USER_CONTRIBUTIONS_REQUESTED';
export const GET_USER_CONTRIBUTIONS_SUCCESS = 'user/GET_USER_CONTRIBUTIONS_SUCCESS';
export const GET_USER_CONTRIBUTIONS_FAILURE = 'user/GET_USER_CONTRIBUTIONS_FAILURE';

export const requestGetUserContributions = () => ({
  type: GET_USER_CONTRIBUTIONS_REQUESTED
});

export const getUserContributionsSuccess = (data) => ({
  type: GET_USER_CONTRIBUTIONS_SUCCESS,
  payload: data
});

export const getUserContributionsFailure = (message) => ({
  type: GET_USER_CONTRIBUTIONS_FAILURE,
  payload: message
});

export const LOGOUT_SUBMITTED = 'user/LOGOUT_SUBMITTED';

export const logoutSubmitted = () => ({
  type: LOGOUT_SUBMITTED
});

export const REGISTER_REQUESTED = 'user/REGISTER_REQUESTED';
export const REGISTER_SUCCESS = 'user/REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'user/REGISTER_FAILURE';

export const requestRegister = () => ({
  type: REGISTER_REQUESTED
});

export const registerSuccess = () => ({
  type: REGISTER_SUCCESS
});

export const registerFailure = (message) => ({
  type: REGISTER_FAILURE,
  payload: message
});

export const TOGGLE_EDIT_PROFILE = 'user/TOGGLE_EDIT_PROFILE';
export const EDIT_PROFILE_REQUESTED = 'user/EDIT_PROFILE_REQUESTED';
export const EDIT_PROFILE_SUCCESS = 'user/EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAILURE = 'user/EDIT_PROFILE_FAILURE';

export const toggleEditProfile = () => ({
  type: TOGGLE_EDIT_PROFILE
});

export const requestEditProfile = () => ({
  type: EDIT_PROFILE_REQUESTED
});

export const editProfileSuccess = (data) => ({
  type: EDIT_PROFILE_SUCCESS,
  payload: data
});

export const editProfileFailure = (message) => ({
  type: EDIT_PROFILE_FAILURE,
  payload: message
});

export const TOGGLE_EDIT_USER = 'user/TOGGLE_EDIT_USER';
export const EDIT_USER_REQUESTED = 'user/EDIT_USER_REQUESTED';
export const EDIT_USER_SUCCESS = 'user/EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'user/EDIT_USER_FAILURE';

export const toggleEditUser = () => ({
  type: TOGGLE_EDIT_USER
});

export const requestEditUser = () => ({
  type: EDIT_USER_REQUESTED
});

export const editUserSuccess = (data) => ({
  type: EDIT_USER_SUCCESS,
  payload: data
});

export const editUserFailure = (message) => ({
  type: EDIT_USER_FAILURE,
  payload: message
});


export const LIST_USERS_REQUESTED = 'user/LIST_USERS_REQUESTED';
export const LIST_USERS_SUCCESS = 'user/LIST_USERS_SUCCESS';
export const LIST_USERS_FAILURE = 'user/LIST_USERS_FAILURE';

export const requestListUsers = () => ({
  type: LIST_USERS_REQUESTED
});

export const listUsersSuccess = (data) => ({
  type: LIST_USERS_SUCCESS,
  payload: data
});

export const listUsersFailure = (message) => ({
  type: LIST_USERS_FAILURE,
  payload: message
});

export const TOGGLE_USER_VERIFIED_REQUESTED = 'user/TOGGLE_USER_VERIFIED_REQUESTED';
export const TOGGLE_USER_VERIFIED_SUCCESS = 'user/TOGGLE_USER_VERIFIED_SUCCESS';
export const TOGGLE_USER_VERIFIED_FAILURE = 'user/TOGGLE_USER_VERIFIED_FAILURE';

export const requestToggleUserVerified = (ID) => ({
  type: TOGGLE_USER_VERIFIED_REQUESTED,
  payload: { ID },
});

export const toggleUserVerifiedSuccess = (data) => ({
  type: TOGGLE_USER_VERIFIED_SUCCESS,
  payload: data
});

export const toggleUserVerifiedFailure = (message) => ({
  type: TOGGLE_USER_VERIFIED_FAILURE,
  payload: message
});

export const UPDATE_TOP_SCHOOLS_REQUESTED = 'user/UPDATE_TOP_SCHOOLS_REQUESTED';
export const UPDATE_TOP_SCHOOLS_SUCCESS = 'user/UPDATE_TOP_SCHOOLS_SUCCESS';
export const UPDATE_TOP_SCHOOLS_FAILURE = 'user/UPDATE_TOP_SCHOOLS_FAILURE';

export const requestUpdateTopSchools = (top_schools, is_private_top_schools = true) => ({
  type: UPDATE_TOP_SCHOOLS_REQUESTED,
  payload: { top_schools, is_private_top_schools },
});

export const updateTopSchoolsSuccess = (data) => ({
  type: UPDATE_TOP_SCHOOLS_SUCCESS,
  payload: data
});

export const updateTopSchoolsFailure = (message) => ({
  type: UPDATE_TOP_SCHOOLS_FAILURE,
  payload: message
});

export const SET_UPLOAD_PHOTOS_AGREEMENT = 'user/SET_UPLOAD_PHOTOS_AGREEMENT';

export const setUploadPhotoAgreement = () => {
  localStorage.setItem('uploadPhotoAgreement', 'checked');
  return {
    type: SET_UPLOAD_PHOTOS_AGREEMENT,
  }
};

export const REQUEST_LOGIN_REDIRECT = 'user/REQUEST_LOGIN_REDIRECT';
export const RESET_LOGIN_REDIRECT = 'user/RESET_LOGIN_REDIRECT';

export const requestLoginRedirect = (redirect, backRedirect) => ({
  type: REQUEST_LOGIN_REDIRECT,
  payload: {redirect: redirect, backRedirect: backRedirect}
});

export const resetLoginRedirect = () => ({
  type: RESET_LOGIN_REDIRECT
});
