import styled from 'styled-components';
import { color, typo, bp } from '../../styles';
import { Heading } from 'rebass';

export const MajorsPageBox = styled.div`
  grid-column: span 12;
  padding-top: 12px;
`;
export const MajorsContainer = styled.div`
  padding-top: 1rem;
`;
export const MajorsListContainer = styled.div`
  padding: 30px 20px;
  @media ${bp.phoneOnly}{
    padding: 30px 0px;
  }
`;
export const MajorLabel = styled.span`
  display: inline-block;
  color: ${color.ovBlack};
  font-family: ${typo.ovFontSerif};
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 1rem;
  position: relative;
`;

export const SpecializationLabel = styled(MajorLabel)`
  padding-left: 2rem;
  display: block;

  & + div {
    padding-top: 1rem;
  }
`;

export const MajorMore = styled.span`

  font-size: 9px;
  padding: 1rem;
  cursor: pointer;
  opacity: 1;
  &:hover{
    opacity: .5;
  }
`;

export const MajorsHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  padding: 0 10px;
  @media ${bp.phoneOnly} {
    padding: 0 0px;
  }
`;