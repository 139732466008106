import TopSchools from './TopSchools';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getUserIfEmpty, updateTopSchools } from '../../modules/user/user';
import { userSelector, isFetchingTopCollegesSelector, profileTopCollegesSelector } from '../../modules/user/selectors';

import { listCollegesIfEmpty } from '../../modules/college/college';

const mapStateToProps = (state) => ({
  isFetching: isFetchingTopCollegesSelector(state),
  user: userSelector(state),
  profile: profileTopCollegesSelector(state),
});

const fetchProfile = () => (dispatch, getState) => {
  getUserIfEmpty()(dispatch, getState);
  listCollegesIfEmpty()(dispatch, getState);
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchProfile,
  updateProfile: updateTopSchools,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TopSchools);