import React from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import AverageRating from '../reviews/AverageRating'

import { Logos, Bkg } from '../CollegeLogo'

// Styles
import {
	CardContainer,
	ItemPhoto,
	PhotoGradient,
	ItemLogo,
	ItemInfo,
	ItemName,
	ItemRating
} from './Styled';

const HomeCard = ({
	name,
	logo,
	bkg,
	url_slug,
	type,
	average_rating,
	push
}) =>{
  return (
  	<CardContainer onClick={() => push('/'+ type +'/' + url_slug)}>
      <ItemPhoto bgUrl={(Bkg[bkg.replace(" ", "_").replace(/-|'| |\(|\)/gi, "").toLowerCase()] || Bkg["default_bkg"])}>
        <ItemLogo><img alt={url_slug} src={Logos[logo.replace(" ", "_").replace(/-|'| |\(|\)/gi, "").toLowerCase()]}/></ItemLogo>
  			<PhotoGradient/>
  		</ItemPhoto>
  		<ItemInfo>
  			<ItemName>{name}</ItemName>
  			<ItemRating>
          <AverageRating average_rating={average_rating}/>
  			</ItemRating>
  		</ItemInfo>
  	</CardContainer>

  )
 }

const mapDispatchToProps = dispatch => bindActionCreators({
  push
}, dispatch)

export default connect(null, mapDispatchToProps)(HomeCard)
