import React from 'react';

import { PageContainer } from '../../components';
import FanProfileEditForm from './editFanProfile';

import {
  ProfileHeadingCenter,
  ProfileSubHeadingCenter,
  ProfileEditLink,
  ContentSpacer,
  PageContent
} from './styled';

const ProfileContent = ({ isEditingProfile, user, profile, colleges, editProfile, toggleEditProfile }) => {
  // Profile Edition Mode
  if (isEditingProfile) {
    return (
      <PageContainer>
        <FanProfileEditForm onSubmit={data => editProfile(data)} onCancel={toggleEditProfile} colleges={colleges} />
      </PageContainer>
    )
  } else if (user.email && profile.ID) {
    return (
      <PageContainer>
        <ProfileEditLink onClick={toggleEditProfile}>Edit</ProfileEditLink>
        <PageContent>
          <ProfileHeadingCenter as="h1">Profile</ProfileHeadingCenter>
          <ContentSpacer />
          <ProfileSubHeadingCenter as="h2">{profile.about_me}</ProfileSubHeadingCenter>
          <ContentSpacer />
          <ProfileSubHeadingCenter as="h2">Ticket Holder: {profile.is_ticket_holder === true ? "Yes" : "No"}</ProfileSubHeadingCenter>
          <ProfileSubHeadingCenter as="h2">Team Donor: {profile.is_donor === true ? "Yes" : "No"}</ProfileSubHeadingCenter>
        </PageContent>
      </PageContainer>
    )
  }

  return null;
}

export default ProfileContent;