import styled from 'styled-components';
import { Heading, Text } from 'rebass';
import { Link } from 'react-router-dom';
import { color, typo, bp } from '../../styles';
import LogoHorizontal from '../../components/LogoHorizontal';

export const StyledLogoHorizontal = styled(LogoHorizontal)`
  width: 187px;
  justify-content: left;
  display: flex;
`;

export const GridContainer = styled.div`
  text-align: center;
  padding: 63px 111px;
  @media ${bp.phoneOnly}{
    padding: 40px;
  }
`;

export const LoginHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 30px;
  text-align: left;
  margin: 23px 0 30px 0;
  font-weight: 300;
`;

export const SmallLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  color: ${color.ovBlue};
  font-size: ${typo.pSize};
  font-family: ${typo.ovFontFeature};
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  font-size: 20px;

  &:hover, &:focus {
    text-decoration: none;
    color: ${color.ovBlueLight};
  }
`;

export const TextP = styled(Text)`
  text-align: left;
  text-decoration: none;
  color: ${color.ovGrey};
  font-size: ${typo.pSize};
  font-family: ${typo.ovFontFeature};
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  font-size: 20px;
  margin-top: 23px;
`;

export const ErrorText = styled(Text)`
  text-align: left;
  text-decoration: none;
  color: ${color.ovRed};
  font-size: ${typo.pSize};
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  padding-bottom: 10px;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 99;
  top: 30px;
  right: 30px;
  height: 22px;
  width: 22px;
`;

export const LogInButton = styled.button`
  height: 59px;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px ${color.ovBlue};
  background-color:  ${color.ovBlue};
  color: ${color.ovWhite};
  width: 100%;
  font-family: ${typo.ovFontFeature};
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: center;
  font-size: 25px;
  @media ${bp.phoneOnly} {
    font-size: 22px;
  }
`;