import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import StyledInput from '../../components/styledInput';

import {
  LogInButton
} from './Styled';

const inputField = ({ input, label, type, meta: { touched, active, error } }) => (
  <div >
    <StyledInput {...input} placeholder={label} type={type} />
    {touched && !active && error && <div>{error}</div>}
  </div>
);

let LoginForm = props => {
  const { handleSubmit } = props;
  return <form id='college-details-edit-form' onSubmit={handleSubmit}>
    <div>
      <div>
        <Field name="email" label="Email" component={inputField} type="email" />
      </div>
      <div>
        <Field name="password" label="Password" component={inputField} type="password" />
      </div>
    </div>
    <LogInButton type="submit" >Submit</LogInButton>
  </form>
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

LoginForm = reduxForm({
  // a unique name for the form
  form: 'login'
})(LoginForm)

export default LoginForm;
