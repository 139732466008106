import React, {useState, useEffect} from 'react';
import FilterHeader from './FilterHeader';
import OptionCheckbox from './OptionCheckbox';
import OptionRange from './OptionRange';

import {
  FilterBox,
  OptionsBox
} from './Styled'

const Filters = ({
  name,
  props,
  array,
  type,
  filter,
  formatLabel,
  clear,
  setClear
}) => {

  const [show, toggleShow] = useState(false);
  const [change, setChange] = useState(false);

  useEffect(() => {
    clear === true && setChange(false)
  })

  function callback(value) {
    setChange(value);
    clear === true && setClear(false);
  }

  return(
    <FilterBox {...props}>
      <FilterHeader
        name={name}
        show={show}
        toggleShow={toggleShow}
        change={change}
      />
      <OptionsBox data-show={show}>
        {array && type === "checkbox" &&
          array.map((item, index)=>{
            return(
              <OptionCheckbox key={index}
                value={item.value} 
                label={item.label} 
                filter={filter} 
                filterPath={item.filterPath}
                setChange={setChange}
                change={change}
                parentCallback={callback}
              />
            )
          })}

          {array && type === "range" &&
            array.map((item, index)=>{
              return(
                <OptionRange
                  key={index}
                  label={item.label}
                  maxValue={item.maxValue}
                  minValue={item.minValue}
                  filter={filter}
                  filterPath={item.filterPath}
                  subfixUnit={item.subfixUnit}
                  prefixUnit={item.prefixUnit}
                  setChange={setChange}
                  change={change}
                  parentCallback={callback}
                  formatLabel={formatLabel}
                />
              )
            })
          }
      </OptionsBox>
    </FilterBox>
  )
}

export default Filters;
