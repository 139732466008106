import { debounce } from "lodash-es";
import React, { useState, useCallback, useEffect } from "react";
import Button from '../Button';
import Select from '../Select';
import SearchInput from './SearchInput';
import { SearchBarContainer, FiltersActions, SearchFilter } from "./Styled";

const SearchBar = ({ query, sortOptions, onShowFilters, onChangeQuery, onClearFilters, onChangeSorting }) => {
  const [value, setValue] = useState(query);
  const handleChangeQuery = useCallback(debounce(onChangeQuery, 800), [onChangeQuery]);

  const handleChange = ({ target }) => {
    setValue(target.value);
    handleChangeQuery(target.value);
  };

  useEffect(() => {
    if (value !== query) {
      setValue(query);
    }
  }, [query, setValue]);

  return (
    <SearchBarContainer>
      <SearchFilter>
        <SearchInput value={value} onChange={handleChange}/>
      </SearchFilter>
      <FiltersActions>
        <Button onClick={onClearFilters} data-clear-filters>Clear Filters</Button>
        <Button onClick={onShowFilters} data-show-filters>Filters</Button>
        <Select placeholder="Sort" options={sortOptions} isSearchable={false} onChange={(name, value) => onChangeSorting(value)}/>
      </FiltersActions>
    </SearchBarContainer>
  )
};

export default SearchBar;
