import React from 'react';
import CollegeCardBarIcon from '../CollegeCardBarIcon'

import {
  CollegeCardBarBox,
  CollegeCardTitle
} from './Styled';

const CollegeCardBar = ({
  collegeCardTitle,
  type,
  name, 
  handleClick
}) => {
  return(
    <CollegeCardBarBox>
      <CollegeCardBarIcon collegeCardTitle={collegeCardTitle} type={type}/>
      <CollegeCardTitle>
        {collegeCardTitle} <span onClick={handleClick}>{name}</span>
      </CollegeCardTitle>
    </CollegeCardBarBox>
  )
}

export default CollegeCardBar;