import styled from 'styled-components';
import { bp, color, typo } from "../../styles";
import { Button, Heading } from "rebass";
import { LogoHorizontal } from "../index";


export const UploadPhotosButtonContainer = styled.div`
  display: inline-block;
`;

export const OVButton = styled(Button)`
  display: inline-block;
  font-family: ${typo.ovFontFeature};
  background: ${color.ovYellow};
  color: ${color.ovWhite};
  cursor: pointer;
  padding: 2px 40px;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.95;
  letter-spacing: normal;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(5, 5, 5, 0.16);
  border: solid 1px ${color.ovGreyDark};
  transition: all .1s;
  &:hover, &:focus {
    text-decoration: none;
    background: ${color.ovBlueLight};
    border-color: ${color.ovBlueLight};
    transition: all .1s;
  }
`;

export const ModalDiv = styled.div`
  background-color: ${color.ovWhite};
  padding: 15px;
  position: relative;
`;

export const PopupPageContainer = styled.div`
  display: block;
  
  @media ${bp.tabletPortUp} {
    padding: 50px;
  }
`;

export const AgreeButton = styled(OVButton)`
  display: block;
  width: 100%;
  font-size: 25px;
  font-weight: 300;
  border-radius: 10px;
  padding: 7px 0;
  background: ${color.ovBlue};
`;

export const Logo = styled(LogoHorizontal)`
  display: inline-block;
  position: relative;
  width: 187px;
  height: 100%;
  cursor: pointer;
`;

export const SubHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 30px;
  font-weight: normal;
  margin-top: 23px;
  margin-bottom: 12px;

  @media ${bp.phoneOnly} {
    font-size: 20px;
  }
`;

export const Text = styled.div`
  color: ${color.ovBlack};
  font-family: Marbach;
  font-size: 19px;
  font-weight: normal;
  margin-bottom: 40px;

  @media ${bp.phoneOnly} {
    font-size: 14px;
  }
`;


