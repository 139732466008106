// eslint-disable-next-line
import React from 'react';
import { bindActionCreators } from 'redux'; 
import { connect } from 'react-redux';
import { getAdminCoachProfileIfEmpty, saveCoach } from '../../modules/coach';
import { listCollegesIfEmpty } from "../../modules/college";
import { currentCoachSelector, currentCoachSeasonsSelector } from '../../modules/coach/selectors';

import CoachFrom from './CoachForm';
import { requestLogin } from "../../modules/user";

const mapStateToProps = (state) => {
  const { user, coach, college } = state;
  return {
    isAuthenticated: user.isAuthenticated,
    isFetching: coach.isFetching,
    colleges: college.colleges || [],
    coachProfile: currentCoachSelector(state),
    initialValues: {
      ...currentCoachSelector(state),
      coachedSeasons: currentCoachSeasonsSelector(state),
    }
  };
}

const mapDispatchToProps = dispatch => bindActionCreators({
  getAdminCoachProfileIfEmpty,
  listCollegesIfEmpty,
  saveCoach,
  requestLogin
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CoachFrom)