import React from 'react';
import PropTypes from 'prop-types';

// Imported Styles
import {
  PageContent,
  StyledSelect
} from './StyledDropdown';

const CoachDropdown = ({ coaches, selectedCoachID, onSelect}) => {

  const onChange = (selected) => {
    return onSelect(selected);
  }

  return (
    <PageContent>
      <StyledSelect
        instanceId={"coaches_dropdown"}
        className="react-select-container"
        classNamePrefix="react-select"
        value={coaches.filter(option => option.value === selectedCoachID)}
        options={coaches}
        clearable={false}
        onChange={onChange}
      />
    </PageContent>
  );
};

CoachDropdown.propTypes = {
  coaches: PropTypes.array.isRequired,
  selectedCoachID: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired

};

export default CoachDropdown;
