import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux'; 
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Heading, Flex, Box } from 'rebass';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import CoachesSearchFilterForm from './searchFilter';
import { getAdminCoachProfileIfEmpty, getCoachProfileByID, visibleCoaches } from '../../modules/coach';
import { color, typo, bp } from '../../styles'

const Result = styled(Box)`
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
  &:last-child{
    margin-bottom: 0px;
  }
  &:hover {
    transition: .1s;
    padding-left: 2px
  };
`;

const SearchContainer = styled.div`
  position: relative;
`;

const ScrollbarContainer = styled(PerfectScrollbar)`
  max-height: 220px;
  overflow-y: hidden;
  .ps__rail-y{
    opacity: 1;
    .ps__thumb-y{
      background-color: ${color.ovYellow}
    }
  }
`;

const ResultHeading = styled(Heading)`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontSerif};
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  padding: 10px 0;
`;

const ResultTitle = styled(Heading)`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
  font-size: 25px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.04;
  letter-spacing: normal;
  text-align: left;
  padding: 10px 0 20px 0;
`;

const ResultsContainer = styled(Flex)`
  display: inline-block;
  border: solid 2px ${color.ovBlue};
  padding: 10px 10px 10px 31px;
  margin-top: -7px;
  background-color: ${color.ovWhite};
  border-radius: 0 0 10px 10px;
  position: absolute;
  max-height: 250px;
  width: 70%;
  overflow-y: hidden;
  @media ${bp.tabletPortUp} {
    width: 80%;
  }
  @media ${bp.tabletLandUp} {
    width: 86%;
  }
  @media ${bp.desktopUp} {
    width: 90%;
  }
`;

class SearchCoaches extends Component {
  render(){
    const {coaches, searchForm, push} = this.props;
    return(
      <SearchContainer>
        <CoachesSearchFilterForm/>
        { searchForm && searchForm.values ? 
          <ResultsContainer> 
            <ScrollbarContainer>
              { coaches.length ? '' : <ResultTitle as='h3'>No matching results</ResultTitle> }
              { coaches.map((coach) => {
                return (
                  <Result 
                    key={coach.ID} 
                    onClick={ () => push('/admin/coaches/' + coach.ID)}
                    width={1} 
                  >
                    <span><ResultHeading as='h3'>{coach.display_name}</ResultHeading></span>
                  </Result>
                );
              })}
            </ScrollbarContainer>
          </ResultsContainer> : ""}
      </SearchContainer>
    )
  }
}

SearchCoaches.propTypes = {
  coaches: PropTypes.array.isRequired,
  getCoachProfile: PropTypes.func.isRequired,
  getAdminCoachProfileIfEmpty: PropTypes.func.isRequired,
};

const mapStateToProps = ({ form, home, coach}) => ({
  searchForm: form.coachSearchFilter,
  coaches: visibleCoaches(coach.coaches, form.coachSearchFilter)
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getAdminCoachProfileIfEmpty,
  getCoachProfile: getCoachProfileByID,
  push
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SearchCoaches)