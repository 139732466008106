import React, { useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withLifecycleActions } from 'react-redux-lifecycle';
import Spinner from 'react-spinkit';
import { PageContainer } from '../../components';
import CoachReviewsList from '../../components/reviews/CoachList'
import { getCoachProfileIfEmpty } from '../../modules/coach';
import { getUserIfEmpty } from "../../modules/user";
import CoachHeader from '../../components/CoachHeader'
import { totalReviewCountSelector } from '../../modules/coach/selectors';

import CollegeCard from '../../components/College/CollegeCard';

import {
  PageContent,
  CoachContainer,
  CoachItem,
  HiddenSpan,
  CoachTable,
  CoachLabel,
  TwelvePartPageContent
} from './Styled';

const PAGE_SIZE = 3;
const showCurrentYear = () => {
  return new Date().getFullYear();
};

const useOnMount = (...gets) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      gets.forEach(fn => fn());
      didMountRef.current = true;
    }
  });
};

const Coaches = ({
  getUserIfEmpty,

  coachProfile,
  isAuthenticated,
  isFetching,
  coachReviews,
  isFetchingReviews,
  coachHistory,
  push,
  role,
  match
}) => {
  useOnMount(getUserIfEmpty);
  const currentYear = showCurrentYear();

  const metaTitle = coachProfile.display_name + ' - OfficialVisit';
  const metaDescription = 'Read reviews of ' + coachProfile.display_name + ' from real, verified current and former players. Find out what these players say on his style, ability, support, integrity, and relationships.';
  return (
    <div>
      {coachProfile.display_name && <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>}

      { isFetching &&
        <PageContainer>
          <PageContent>
            <Spinner fadeIn="none" name="cube-grid" />
          </PageContent>
        </PageContainer>
      }

      { !isFetching && coachProfile.display_name && coachProfile.ID &&
        <PageContainer>

          <CoachHeader
            coachName={coachProfile.display_name}
            coachProfilePicture={coachProfile.default_photo ? coachProfile.default_photo.src_md : null}
            coachHistoryTitle={coachHistory.length > 0 && coachHistory[0].title0}
            coachHistoryName={coachHistory.length > 0 && coachHistory[0].name}
            coachHistoryEnd={coachHistory.length > 0 && coachHistory[0].end < currentYear ? "Former" : ""}
            averageReview={coachProfile.average_verified_review}
            role={role}
          />

          <TwelvePartPageContent>
            <CollegeCard collegeCardTitle="coaching history">
              <CoachContainer>
                <CoachTable>
                  <p>Year</p>
                  <p>Team</p>
                  <p>Job Title</p>
                </CoachTable>

                {coachHistory.length > 0 &&
                  coachHistory.map((item, index) => (
                    <CoachItem key={item.name+index}>
                      <p><HiddenSpan>Year: </HiddenSpan><CoachLabel>
                        { item.start === item.end ? item.start : '' }
                        { item.start !== item.end ? `${item.start} - ${item.end >= currentYear ? 'Present' : item.end }` : '' }
                      </CoachLabel></p>
                      <p><HiddenSpan>Team: </HiddenSpan><CoachLabel>{item.name}</CoachLabel></p>
                      <p><HiddenSpan>Job Title: </HiddenSpan><CoachLabel>{item.title1 ? item.title0 + ", " + item.title1 : item.title0}</CoachLabel></p>
                    </CoachItem>
                  ))}
              </CoachContainer>
            </CollegeCard>
          </TwelvePartPageContent>
          <TwelvePartPageContent>
            <CollegeCard collegeCardTitle="reviews">
              <CoachReviewsList
                reviews={coachReviews}
                isFetchingReviews={isFetchingReviews}
                onUserClick={id => push('/user/' + id)}
                count={totalReviewCountSelector(coachProfile) || coachReviews.length}
                paginate={PAGE_SIZE}
                isAuthenticated={isAuthenticated}
                currentUserRole={role || ''}
                match={match}
              />
            </CollegeCard>
          </TwelvePartPageContent>
        </PageContainer>
      }
    </div>
  )
};

Coaches.propTypes = {
  coachProfile: PropTypes.object.isRequired,
  coachReviews: PropTypes.array.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isFetchingReviews: PropTypes.bool.isRequired,
  getCoachProfileIfEmpty: PropTypes.func.isRequired
};

const mapStateToProps = ({ user, coach }) => ({
  coachProfile: coach.coachProfile,
  isAuthenticated: user.isAuthenticated,
  isFetching: coach.isFetching,
  coachReviews: coach.coachReviews,
  isFetchingReviews: coach.isFetchingReviews,
  coachHistory: coach.coachHistory,
  role: user.user.role,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getUserIfEmpty,
  getCoachProfileIfEmpty,
  push,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withLifecycleActions({ componentWillMount: [getCoachProfileIfEmpty]})(Coaches))
