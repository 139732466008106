import React from 'react';
import styled from 'styled-components';
import { ChevronLeft } from 'styled-icons/fa-solid/ChevronLeft';
import { color } from '../styles';


const BackButton = styled(ChevronLeft)`
  display: inline;
  color: ${color.ovBlue};
  width: 15px;
  cursor: pointer;
  z-index: 1;
  opacity: 1;
  transition: all .1s;
  &:hover{
    opacity: .5;
    transition: all .1s;
  }
`;

export default ({ onClick }) => <BackButton onClick={onClick} />;