
import { reviewService } from '../../services';
import * as actions from './actions'

const fanRecruitCollegeQuestions = [
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'surrounding_area_college',
    text: 'Town/Surrounding Area'
  }
];

const fanRecruitAthleticsQuestions = [
  {
    questionStage: 2,
    context: 'Athletics',
    rating_key: 'gameday_athletics',
    text: 'Gameday Experience'
  }
];

const studentCollegeQuestions = [
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'overall_college',
    text: 'Overall College Experience'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'value_college_college',
    text: 'Value of Degree - School'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'value_major_college',
    text: 'Value of Degree - Major'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'difficulty_major_college',
    text: 'Degree Difficulty'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'professors_college',
    text: 'Professors and Faculty'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'campus_college',
    text: 'Campus'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'surrounding_area_college',
    text: 'Town/Surrounding Area'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'nightlife_college',
    text: 'Nightlife'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'food_college',
    text: 'Food'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'dating_college',
    text: 'Dating Scene'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'social_college',
    text: 'Social Life'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'diversity_college',
    text: 'Diversity'
  },
];

const studentAthleticsQuestions = [
  {
    questionStage: 2,
    context: 'Athletics',
    rating_key: 'gameday_athletics',
    text: 'Gameday Experience'
  },
];

const athleteCollegeQuestions = [
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'overall_college',
    text: 'Overall College Experience'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'value_college_college',
    text: 'Value of Degree - School'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'value_major_college',
    text: 'Value of Degree - Major'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'difficulty_major_college',
    text: 'Degree Difficulty'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'professors_college',
    text: 'Professors and Faculty'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'campus_college',
    text: 'Campus'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'surrounding_area_college',
    text: 'Town/Surrounding Area'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'nightlife_college',
    text: 'Nightlife'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'food_college',
    text: 'Food'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'dating_college',
    text: 'Dating Scene'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'social_college',
    text: 'Social Life'
  },
  {
    questionStage: 1,
    context: 'College',
    rating_key: 'diversity_college',
    text: 'Diversity'
  },
];

const athleteAthleticsQuestions = [
  {
    questionStage: 2,
    context: 'Athletics',
    rating_key: 'gameday_athletics',
    text: 'Gameday Experience'
  },
  {
    questionStage: 2,
    context: 'Athletics',
    rating_key: 'academic_support_athletics',
    text: 'Academic Support'
  },
  {
    questionStage: 2,
    context: 'Athletics',
    rating_key: 'fans_athletics',
    text: 'Fans'
  },
  {
    questionStage: 2,
    context: 'Athletics',
    rating_key: 'facilities_athletics',
    text: 'Facilities'
  },
  {
    questionStage: 2,
    context: 'Athletics',
    rating_key: 'team_culture_athletics',
    text: 'Team Culture'
  },
];

export const athleteCoachQuestions = [
  {
    questionStage: 3,
    context: 'Coach',
    rating_key: 'overall_coach',
    text: 'Overall'
  },
  {
    questionStage: 3,
    context: 'Coach',
    rating_key: 'style_coach',
    text: 'Coaching Style'
  },
  {
    questionStage: 3,
    context: 'Coach',
    rating_key: 'ability_coach',
    text: 'Coaching Ability'
  },
  {
    questionStage: 3,
    context: 'Coach',
    rating_key: 'support_coach',
    text: 'Support'
  },
  {
    questionStage: 3,
    context: 'Coach',
    rating_key: 'integrity_coach',
    text: 'Integrity'
  },
  {
    questionStage: 3,
    context: 'Coach',
    rating_key: 'player_relationships_coach',
    text: 'Player Relationships'
  },
];

const ROLE_QUESTIONS = {
  contributor: [...fanRecruitCollegeQuestions, ...fanRecruitAthleticsQuestions],
  fan: [...fanRecruitCollegeQuestions, ...fanRecruitAthleticsQuestions],
  recruit: [...fanRecruitCollegeQuestions, ...fanRecruitAthleticsQuestions],

  student: [...studentCollegeQuestions, ...studentAthleticsQuestions],

  athlete: [...athleteCollegeQuestions, ...athleteAthleticsQuestions, ...athleteCoachQuestions],
  admin: [...athleteCollegeQuestions, ...athleteAthleticsQuestions, ...athleteCoachQuestions],
};

export const getQuestionStateByRole = (isCurrentCollegeStudent, role = 'fan') => {
  role = isCurrentCollegeStudent || role === 'admin' ? role : 'fan';
  const questions = ROLE_QUESTIONS[role];
  const maxStage = role === 'admin' || role === 'athlete' ? 3 : 2;
  console.log('getQuestionStateByRole', role, maxStage);

  const state = {
    stage: 0,
    maxStage,
    title: '',
    questions: questions.map((question, i) => ({
      index: i,
      ...question
    })),
    answers: questions.map((question, i) => ({ index: i })),
    userRole: role,
    reviews: []
  };

  return state;
};


export const getPageTitle = (stage) => {
  switch (stage) {
    case 0:
      return "Name your Review"
    case 1:
      return "College"
    case 2:
      return "Athletics"
    case 3:
      return "Coach"

    default: 
      return "Coach"
  };
};

/**
 * Get a list of coaches for this review based on teamID
 */
export const getReviewCoaches = () => {
  return async (dispatch, getState) => {

    dispatch(actions.getReviewCoachesRequest())

    const teamID = getState().college.college.teams[0].ID;

    try {
      const res = await reviewService().getReviewCoaches(teamID);

      //format and make unique list of coaches
      const formatted = res.data.data.map((coach) => ({value: coach.ID,  label: coach.display_name}));

      const coaches = [];
      const map = new Map();
      for (const item of formatted) {
        if(!map.has(item.value)){
          map.set(item.value, true);    // set any value to Map
          coaches.push({
            value: item.value,
            label: item.label
          });
        }
      }

      return dispatch(actions.getReviewCoachesSuccess(coaches));
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status) {
        console.log(e.response.statusText)
      }
    } finally {
      return;
    }
  }
};



export const deleteAdminReview = (id) => {
  return async (dispatch) => {

    dispatch(actions.deleteAdminReviewRequest());

    try {
      await reviewService().deleteAdminReview(id);

      return dispatch(actions.deleteAdminReviewSuccess(id));
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status) {
        console.log(e.response.statusText)
      }
      dispatch(actions.deleteAdminReviewFailure());
    }
  }
};
