import styled from 'styled-components';
import { color } from '../../../styles';

export const CollegeSmallCardButtonBox = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0, 0.16);
  border: solid 1px rgba(0,0,0, 0.16);
  background-color: ${color.ovYellow};
  color: ${color.ovWhite};
  padding: 11px 7px;
  min-height: 45px;
  align-self: start;
  cursor: pointer;
  transition: all .1s;
  &:hover{
      transition: all .1s;
      transform: scale(1.04);
  }
`;