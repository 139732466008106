import React from "react";
import styled from 'styled-components';
import { color, bp, typo } from '../../styles';
import { Heading } from 'rebass';
import { CloseButton, OVButton } from '../../components';

import ReactModal from 'react-modal';
import { X } from "styled-icons/feather/X";

export const GalleryPageContainer = styled.div`
  @media ${ bp.phoneOnly } {
    width: 90%;
    margin: 0 5%;
  }
  @media ${ bp.tabletPortUp } {
    width: 570px;
    margin: 0 auto;
  }
  @media ${ bp.tabletLandUp } {
    width: 870px;
    margin: 0 auto;
  }
  @media ${ bp.desktopUp } {
    width: 992px;
    margin: 0 auto;
  }
  @media ${ bp.wideUp } {
    width: 1280px;
    margin: 0 auto;
  }
  
  padding-top: 50px;
  padding-bottom: 20px;
  height:100%;
  overflow: auto;
`;

const Container12 = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 20px;  
`;

export const GalleryGridContainer = ({ children }) => (
  <Container12>
    { children }
  </Container12>
);

export const ImageContainer = styled.div`
  grid-column: span 3;

  background: ${ color.ovWhite };
  vertical-align : top;
  overflow: hidden;
  display: inline-block;
  border-radius: 1px;
  text-align: center;
  border: 2px solid ${ color.ovWhite };
  position: relative;
  cursor: pointer;

  @media ${ bp.phoneOnly } {
    grid-column: span 6;

    height: 100px;
    width: 100%;
    border: 2px solid ${ color.ovGreyLight };
  }
  @media ${ bp.tabletPortUp } {
    height: 100px;
    width: 130px;
    margin-bottom: 14px;
  }
  @media ${ bp.tabletLandUp } {
    height: 154px;
    width: 200px;
    margin-bottom: 18px;
  }
  @media ${ bp.desktopUp } {
    height: 176px;
    width: 230px;
    margin-bottom: 18px;
  }
  @media ${ bp.wideUp } {
    height: 230px;
    width: 300px;
    margin-bottom: 20px;
  }

`;

export const ImgStyle = {
  objectFit: 'cover',
  height: '100%',
  width: '100%',
  imageOrientation: 'from-image'
};


//////////////////////////////////////////

export const captionContainer = {
  // cursor: 'pointer'
};

export const CloseBar = styled.div`
  background: ${ color.ovGreyDark };
  width: 100%;
  position: fixed;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ModalCloseButtonStyle = styled(X)`
  color: ${ color.ovBlue };
  height: 32px;
  padding: 3px;
`;

export const ModalCloseButton = ({ onClick }) => <ModalCloseButtonStyle onClick={ onClick }/>;

export const MarginRightContainer = styled.span`
  margin-right: 20px;
`;

export const OVButtonMarginRight = ({ onClick, children }) => <MarginRightContainer
  onClick={ onClick }><OVButton>{ children }</OVButton></MarginRightContainer>

export const MarginBottomContainer = styled.div`
  margin-bottom: 10px;
`;

export const OVButtonMarginBottom = ({ onClick, children }) => <MarginBottomContainer
  onClick={ onClick }><OVButton>{ children }</OVButton></MarginBottomContainer>

export const AbsBackgroundContainer = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  background: ${ color.ovWhite };
`;

export const ImageCloseButton = ({ onClick, children }) => <AbsBackgroundContainer
  onClick={ onClick }><CloseButton>{ children }</CloseButton></AbsBackgroundContainer>

export const SubHeading = styled(Heading)`
  color: ${ color.ovBlue };
  font-family: ${ typo.ovFontFeature };
  font-size: 24px;
  margin-bottom: 40px;

  @media ${ bp.phoneOnly } {
    font-size: 18px;
  }
`;

export const Modal = styled.div`
  background-color: ${ color.ovWhite };
  padding: 15px;
  border: 3px solid ${ color.ovBlue };
`;

export const GalleryModal = styled(ReactModal)`
  background: gray;
  position: relative;
  // overflow-y: auto;
  top: 63px;
  height: calc(100vh - 63px);
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  // overflow-x: none;
  
  &:focus{
    outline: none;
  }
`;

// GalleryModal.setAppElement('#root');
