import React from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import CollegeSmallCard from '../../../components/College/CollegeSmallCard';

import otherCostsGroupLabelMap from './CostsLabelMap';

import {
  CollegeSmallCardRow,
  CollegeSmallCardTop,
  CollegeSmallCardTitleSmall,
  CollegeSmallCardValue,
  CollegeRankingPosition,
  CollegeRankingTotal,
  CollegeCompositeRating,
  CollegeRanking,
  CollegeTable,
  CollegeTableItem,
  CollegeTableRow,
  CollegeTableColumnLabel,
  CollegeTableColumnValue,
  CollegeSmallTableRow,
  CollegeSmallTable,
  CollegeSmallTableRight,
  CollegeSmallTableLeft,
  YearUnit
} from '../StyledUI';


const CostStats = ({ cost, detailed_cost, push }) => {

  const otherCostsGroup = ["housing_cost", "meal_plan_cost", "books_supplies_cost"]
  
  const detailedCostArray = detailed_cost[0] ? Object.entries(detailed_cost[0].data).map(function(key) {
    return {"label" : key[0], "value": key[1] ? key[1] : "-"};
  }) : [];

  function filterArray(filter) {
    // eslint-disable-next-line
    const GroupArray = detailedCostArray.filter((item)=>{
      let iterador = filter.values();
      for (let value of iterador) {
        if( item.label === value ){
          return item
        }
      }
    })
    return GroupArray;
  }

  function toLocaleString(value){
    return value ? value.toLocaleString('EN') : '-';
  }

  const otherCostsGroupArray = otherCostsGroup && filterArray(otherCostsGroup);
  
  return (
    <div>
      <CollegeSmallCardRow>

        <CollegeSmallCard>
          <CollegeSmallCardTop>
            
            <CollegeSmallCardTitleSmall>
              In-state tuition
            </CollegeSmallCardTitleSmall>

            <CollegeSmallCardValue>
              <CollegeCompositeRating>${cost[0] ? toLocaleString(cost[0].data[0].value) : "-"}</CollegeCompositeRating>
              <CollegeRanking onClick={() => push('/most-affordable-in-state-colleges')}>
                <CollegeRankingPosition>{cost[0] ? cost[0].data[0].rank : "-"}</CollegeRankingPosition>
                <CollegeRankingTotal> of {cost[0] ? cost[0].data[0].total : "-"}</CollegeRankingTotal>
              </CollegeRanking>
            </CollegeSmallCardValue>

          </CollegeSmallCardTop>
        </CollegeSmallCard>

        <CollegeSmallCard>
          <CollegeSmallCardTop>
            
            <CollegeSmallCardTitleSmall>
              Out-of-state tuition
            </CollegeSmallCardTitleSmall>

            <CollegeSmallCardValue>
              <CollegeCompositeRating>${cost[0] ? toLocaleString(cost[0].data[1].value) : "-"}</CollegeCompositeRating>
              <CollegeRanking onClick={() => push('/most-affordable-out-of-state-colleges')}>
                <CollegeRankingPosition>{cost[0] ? cost[0].data[1].rank : "-"}</CollegeRankingPosition>
                <CollegeRankingTotal> of {cost[0] ? cost[0].data[1].total : "-"}</CollegeRankingTotal>
              </CollegeRanking>
            </CollegeSmallCardValue>

          </CollegeSmallCardTop>
        </CollegeSmallCard>

        <CollegeSmallCard>
          <CollegeSmallCardTop>
            
            <CollegeSmallCardTitleSmall>
              Net price (all students)
            </CollegeSmallCardTitleSmall>

            <CollegeSmallCardValue>
              <CollegeCompositeRating>${detailed_cost[0] ? toLocaleString(detailed_cost[0].data.net_price) : "-"}</CollegeCompositeRating>
            </CollegeSmallCardValue>

          </CollegeSmallCardTop>
        </CollegeSmallCard>

        <CollegeSmallCard>
          <CollegeSmallCardTop>
            
            <CollegeSmallCardTitleSmall>
              Students receiving aid
            </CollegeSmallCardTitleSmall>

            <CollegeSmallCardValue>
              <CollegeCompositeRating>{detailed_cost[0] ? detailed_cost[0].data.percent_aid : "-"}%</CollegeCompositeRating>
            </CollegeSmallCardValue>

          </CollegeSmallCardTop>
        </CollegeSmallCard>

        <CollegeSmallTableRow>
          <div>

            <CollegeSmallCardTitleSmall>
              Net price by household income (all students)
            </CollegeSmallCardTitleSmall>

            <CollegeSmallTable>
              <CollegeSmallTableLeft>&lt;$30k</CollegeSmallTableLeft>
              <CollegeSmallTableRight>${detailed_cost[0] ? toLocaleString(detailed_cost[0].data.aid_30k) : "-"}</CollegeSmallTableRight>
            </CollegeSmallTable>

            <CollegeSmallTable>
              <CollegeSmallTableLeft>$30-48k</CollegeSmallTableLeft>
              <CollegeSmallTableRight>${detailed_cost[0] ? toLocaleString(detailed_cost[0].data.aid_48k) : "-"}</CollegeSmallTableRight>
            </CollegeSmallTable>

            <CollegeSmallTable>
              <CollegeSmallTableLeft>$49-75k</CollegeSmallTableLeft>
              <CollegeSmallTableRight>${detailed_cost[0] ? toLocaleString(detailed_cost[0].data.aid_75k) : "-"}</CollegeSmallTableRight>
            </CollegeSmallTable>

            <CollegeSmallTable>
              <CollegeSmallTableLeft>$76-110k</CollegeSmallTableLeft>
              <CollegeSmallTableRight>${detailed_cost[0] ? toLocaleString(detailed_cost[0].data.aid_under_110k) : "-"}</CollegeSmallTableRight>
            </CollegeSmallTable>

            <CollegeSmallTable>
              <CollegeSmallTableLeft>$110k+</CollegeSmallTableLeft>
              <CollegeSmallTableRight>${detailed_cost[0] ? toLocaleString(detailed_cost[0].data.aid_over_110k) : "-"}</CollegeSmallTableRight>
            </CollegeSmallTable>

          </div>

        </CollegeSmallTableRow>
        
      </CollegeSmallCardRow>

      

      <CollegeTable>

        <CollegeTableItem>
          {otherCostsGroupArray && otherCostsGroupArray.map((item, index)=>{
            return(
              <CollegeTableRow key={index}>
                <CollegeTableColumnLabel>{otherCostsGroupLabelMap[item.label]}</CollegeTableColumnLabel>
                <CollegeTableColumnValue>
                  ${toLocaleString(item.value)}
                  <YearUnit>/year</YearUnit>
                </CollegeTableColumnValue>
              </CollegeTableRow>
            )
          })}
        </CollegeTableItem>

      </CollegeTable>

    </div>
  )
};


const mapDispatchToProps = dispatch => bindActionCreators({
  push
}, dispatch)

export default connect(null, mapDispatchToProps)(CostStats)