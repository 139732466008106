import React, { useEffect, useState } from 'react';

import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { isMobile } from '../../config/app';
import { getTopConference } from '../../modules/home';
import { listCollegesIfEmpty } from '../../modules/college';
import { baseSelector } from '../../modules/college/selectors';
import HomeTopConference from './HomeTopConference';

const TAB_LIST = ['ACC', 'BIG TEN', 'BIG 12', 'PAC-12', 'SEC'];
const acceptable = ['boston-college', 'clemson', 'duke', 'florida-state', 'fsu', 'georgia-tech', 'illinois', 'indiana', 'iowa', 'maryland', 'michigan', 'baylor', 'iowa-state', 'kansas', 'kansas-state', 'oklahoma', 'arizona', 'arizona-state', 'california', 'colorado', 'oregon', 'alabama', 'arkansas', 'auburn', 'florida', 'georgia'];

const defaultList = isMobile
  ? TAB_LIST
  : ['ACC'];

const Wrapper = ({ isFetching, colleges, topColleges, listCollegesIfEmpty, getTopConference, push }) => {
  useEffect(() => {
    if (!colleges || !colleges.length) {
      listCollegesIfEmpty();
    } else if (!topColleges) {
      getTopConference(defaultList, acceptable);
    }

  }, [colleges, topColleges]);

  const [ selected, setSelected ] = useState(isMobile ? '' : 'ACC');

  return (
    <HomeTopConference
      tabs={TAB_LIST}
      topColleges={topColleges}
      isFetching={isFetching}
      getTopConference={which => getTopConference([which], acceptable) && setSelected(which)}
      selected={selected}
      listCollegesIfEmpty={listCollegesIfEmpty}
      colleges={colleges}
      push={push}
    />
  )
};

const mapStateToProps = (state) => {
  const { user, home: { topConferences: topColleges }, college: { colleges } } = state;

  return {
    isFetching: baseSelector(state).isFetchingCollege,
    isAuthenticated: user.isAuthenticated,
    colleges,
    topColleges,
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({
  push,
  listCollegesIfEmpty,
  getTopConference,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper)
