import first from 'lodash/first';

/**
 * Returns true if the content is tagged with tag
 */
export const contentHasTag = (content, tag) => content.tags && content.tags.some(t => t.ID === tag.ID || t.name === tag.name);

/**
 * Returns an array of content object given an array of tags. Any content that has a tag will be returned.
 */
export const taggedContent = (contents, tags) => {
  return tags.length ?
    contents.filter(content => {
      for (var i = 0; i < tags.length; i++) {
        if (contentHasTag(content, tags[i]))
          return true;
      }
      return false;
    }) : contents;
};

export const getFirstByTag = (contents, tag) => {
  const tagged = taggedContent(contents, [tag]);
  return first(tagged) || {};
};

export const getContributionTags = (tags, user) => {
  if (!tags) tags = [];

  if (user && user.user && user.user.role === 'admin') {
    return getSortedTags(tags);
  }

  return tags.filter(el => !el.restricted);
};

export const getSortedTags = (tags) => {
  if (!tags) tags = [];

  return tags.sort((a) => (a.restricted) ? -1 : 1);
};
