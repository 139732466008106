import React from "react";
import styled from "styled-components";
import { Button, Panel} from "react-bootstrap";
import { X } from "styled-icons/feather/X";
import { color } from "../styles";

const CloseImg = styled(X)`
  display: inline;
  color: ${color.ovBlue};
  width: 25px;
  cursor: pointer;
  z-index: 1;
`;

const StyledPanelBody = styled(Panel.Body)`
 border-radius: 10px; 
 background-color: white;
 border: solid 2px #a8a8a8;
`;

const StyledButton = styled(Button)`
  float: right;
  border: 0 !important;
  &:hover,
  &:active,
  &:focus {
    background: transparent !important;
    box-shadow: none !important;
  }
`;

const TextPanel = ({ children, title, onClick }) => {
  return (
    <Panel style={{ marginTop: "20px", borderRadius: "12px" }}>
      <StyledPanelBody>
        <div>
          <StyledButton
            onClick={onClick}
          >
            <CloseImg />
          </StyledButton>
          <h4>{title}</h4>
        </div>
        <div>
          {children}
        </div>
      </StyledPanelBody>
    </Panel>
  );
};

export default TextPanel;
