import styled from 'styled-components';
import { color, bp } from '../../styles';

export const BigButtonContainer = styled.a`
  max-width: 283px;
  height: 92px;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0,0,0, 0.16);
  border: solid 1px ${color.ovGreyDarker};
  background-color: ${color.ovWhite};
  cursor: pointer;
  transition: all .1s;
  display: grid;
  grid-template-columns: 115px auto;
  grid-template-rows: 1fr;
  &:hover{
    transition: all .1s;
    transform: scale(1.04);
    text-decoration: none;
  }
  @media ${bp.phoneOnly} {
    grid-template-columns: 100%;
    grid-template-rows: 63px auto;
    max-width: 50%;
    width: 50%;
    border: none;
  }
`;

export const BigButtonPhoto = styled.div`
  background: ${props => `url("${props.bgUrl}") no-repeat top`};
  background-size: cover;
`;

export const BigButtonLabel = styled.span`
  min-width: 168px;
  width: 60%;
  height: 100%;
  font-family: Molde;
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 0.5;
  letter-spacing: normal;
  text-align: left;
  float: left;
  padding: 0 0 3px 42px;
  color: ${color.ovBlue};
  display: flex;
  align-items: center;
  @media ${bp.phoneOnly} {
    min-width: auto;
    font-size: 18px;
    padding: 0;
    text-align: center;
    justify-content: center;
    width: 100%;
  }
`;