import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { connect } from "react-redux";

import { Marker, Popup } from 'react-mapbox-gl';
import LocationIcon from './LocationIcon';
import AverageReview from '../../reviews/AverageReview';

import {
  LocationsPopup,
  LocationsCardTitile,
  LocationsCardFilters
} from './Styled';

import {
  mapTagstoIcon
} from './TagMapping';

const LocationMarker = ({
  ID,
  location,
  title,
  average_review = {},
  tags,
  clickable,
  college_url_slug,

  lastOpened,
  setLastOpened,
  hoverCard,
  push,
}) => {
  if (!location || !location.geo_json) {
    return null;
  }

  const { geo_json: { coordinates } } = location;
  const [showPopup, showPopups] = useState(false);
  const classes = [];
  if (clickable) classes.push('clickable');
  if (lastOpened === ID) classes.push('top-label');

  const iconName = mapTagstoIcon(tags);
  return (
    <Fragment>
      <LocationsPopup>
        {lastOpened === ID &&
          <Popup
            anchor={'bottom'}
            coordinates={coordinates}
            offset={{
              'bottom': [0, -44]
            }}
            className={classes.join(' ')}
            onClick={() => clickable ? push(`/college/${college_url_slug}/attractions/${ID}`) : null}
          >
            <LocationsCardTitile>{`${title}`}</LocationsCardTitile>
            <LocationsCardFilters>
              {tags.map((tag) => tag.title).join(', ')}
            </LocationsCardFilters>
            <AverageReview averageReview={average_review.average_rating} count={average_review.reviews_count} />

          </Popup>}
      </LocationsPopup>
      <Marker
        onClick={() => { showPopups(!showPopup); if (lastOpened === ID) setLastOpened(0); else setLastOpened(ID); }}
        style={
         hoverCard === title ? 
          { color: '#ff44ff', cursor: 'pointer', zIndex: '9999'} : 
          { color: '#ff44ff', cursor: 'pointer', zIndex: '9' }
        }
        coordinates={coordinates}
      >
        <LocationIcon
          name={iconName.tag} 
          highlight={hoverCard}
          title={title}
        />
      </Marker>
    </Fragment>
  )
};

LocationMarker.propTypes = {
  ID: PropTypes.number,
  location: PropTypes.shape({
    geo_json: PropTypes.object,
  }),
};

const mapDispatchToProps = dispatch => bindActionCreators({
  push,
}, dispatch);

export default connect(null, mapDispatchToProps)(LocationMarker);