import React from 'react';
import styled from 'styled-components';
import { bp } from '../../styles';


const Container12 = styled.div`

  @media ${bp.phoneOnly} {
    width: 90%;
    margin: 0 5%;
  }
  @media ${bp.tabletPortUp} {
    width: 570px;
    margin: 0 auto;
  }
  @media ${bp.tabletLandUp} {
    width: 870px;
    margin: 0 auto;
  }
  @media ${bp.desktopUp} {
    width: 992px;
    margin: 0 auto;
  }
  @media ${bp.wideUp} {
    width: 1280px;
    margin: 0 auto;
  }

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 20px;
  padding-bottom: 20px;
`;

export default ({children}) => (
  <Container12>
    { children }
  </Container12>
)