import React from 'react';
import images from './images'
import { 
  AttractionPreviewCardContainer,
  AttractionPreviewCardContent,
  Text,
  NavLinkButton,
} from './Styled';


const AttractioPreviewCard = ({ collegeName, college, push }) => {
  const imageName = collegeName && collegeName.replace(/[\s\-]/g, "_").replace(/[\'()&]/g, '');
  return (
    <AttractionPreviewCardContainer image={images[imageName]}>
      <AttractionPreviewCardContent>
        <Text> 
          Explore where students and locals go
        </Text>
        <NavLinkButton onClick={() => push(`/college/${college && college.url_slug}/attractions`)}>View attractions</NavLinkButton>
      </AttractionPreviewCardContent>
    </AttractionPreviewCardContainer>
  )
}

export default AttractioPreviewCard;