import styled from 'styled-components';
import { color, typo, bp } from '../../styles'
import { Heading, Button } from 'rebass';
import BigTitle from '../../components/bigTitle'

// Styled components
export const PageContent = styled.div`
  grid-column: span 12;
  background-color: ${color.ovWhite};
  border: 1px solid ${color.ovGrey};
  margin: 10px 0;
  min-height: 160px;
`;

export const BigTitleGrey = styled(BigTitle)`
  font-size: 45px;
  font-family: Molde;
  font-weight: 500;
  color: ${ color.ovBlack};
  margin-bottom: 5px;
`;

export const DetailsSubHeading = styled(Heading)`
  font-family: ${typo.ovFontFeature};
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovGreyDarker};
`;

export const DetailsSubHeadingLight = styled(DetailsSubHeading)`
  color: ${color.ovGreyDark};
`;

export const FullWithContent = styled.div`
  background-color: ${color.ovWhite};
  margin-top: -38px;
  border-bottom: solid 1px ${color.ovGreyDark};
  padding: 20px;
  margin-bottom: 42px;
`;

export const LoadMoreButtonLabel = styled.p`
  font-family: ${typo.ovFontFeature};
  font-size: 25px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  @media ${bp.phoneOnly} {
    font-size: 22px;
  }
`;

export const LoadMoreButton = styled.button`
  height: 59px;
  border-radius: 10px;
  border: solid 1px #707070;
  background-color: #fcd068;
  transition: all .1s;
  text-align: center;
  padding: 8px 30px;
  position: relative;
  height: 45px;
  width: 156px;
  &:hover{
    background-color: ${color.ovBlue};
    color: ${color.ovWhite};
    transition: all .1s;
  }
`;

export const LoadMoreButtonContainer = styled.div`
  grid-column: 1/-1;
  text-align: center;
  margin: 2rem 0;
`;

export const HeaderDescription = styled.div`
  padding: 2rem;
  line-height: 1.5;
  color: ${color.ovBlack};
  font-family: ${typo.ovFontSerif};
`;

export const ShowMoreButton = styled(Button)` 
  height: 14px;
  box-shadow: 0 3px 6px 0 rgba(3, 3, 3, 0.16);
  border: solid 1px ${color.ovBlack};
  background-color: ${color.ovWhite};
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 0;
  letter-spacing: normal;
  text-align: center;
  color: ${color.ovBlack};
  cursor: pointer;
  display: block;
  width: 220px;
  margin: auto;

  &:hover{
    background-color: ${color.ovBlack};
    color: ${color.ovWhite};
  }
`;