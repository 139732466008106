import { color, typo } from '../../../styles'
import styled from 'styled-components';
import { Heading, Button } from 'rebass';
import { MapMarkerAlt } from 'styled-icons/fa-solid/MapMarkerAlt';

export const AttractionCardCointainer = styled.div`
  background-color: ${color.ovWhite};
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 17px;
  padding-bottom: 10px;
`;

export const AttractionCardTitile = styled(Heading)`
	font-family: ${typo.ovFontFeature};
  font-size: 40px;
  font-weight: 300;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlack};
`;

export const AttractionCardTitleBox = styled.div`
  border-bottom: 1px solid ${color.ovGreyLight};
  padding: 7px 23px;
`;

export const AttractionCardItemsContainer = styled.div`
	padding: 1rem 23px 0 23px;
  margin-bottom: 1rem;
`;

export const AttractionCardBodyContainer = styled.div`
	padding: 0 23px;
	max-height: 120px;
	overflow: hidden;
  transition: height 1s linear;
  margin-bottom: 2rem;
	&[data-show=true]{
    max-height: none;
    overflow: visible;
    transition: height 1s linear;
    margin-bottom: 1rem;
  }
  &.editable{
    padding-bottom: 3rem
  }
`;

export const AttractionCardSubTitle = styled.h2`
	font-family: ${typo.ovFontFeature};
  font-size: 28px;
  font-weight: 300;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlue};
  margin: 0;
  padding: 0 0 1rem 0;
`;

export const CategoryTag = styled.span`
	height: 23px;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px ${color.ovBlue};
  background-color: ${color.ovWhite};
  font-family: ${typo.ovFontFeature};
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlue};
  padding: 3px 10px;
  margin: 0 10px 10px 0;
  display: inline-block;
`;

export const SubCategoryTag = styled(CategoryTag)`
	background-color: ${color.ovBlue};
	color: ${color.ovWhite};
`;

export const AttractionCardBody = styled.p`
	font-family: ${typo.ovFontSerif};
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlack};
`;

export let AttractionCardInput = styled.textarea`
  display: inline-block;
  width: 100%;
  height: 235px;
  border: solid 3px ${color.ovBlue};
  padding-left: 10px;
  background-color: ${color.ovWhite};
  margin-bottom: 20px;
  resize: none;
  font-family: ${typo.ovFontFeature};
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlue};

  &::placeholder{
    font-family: ${typo.ovFontFeature};
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: ${color.ovGrey};
  }
`;

export const ShowMoreButton = styled(Button)` 
  height: 14px;
  box-shadow: 0 3px 6px 0 rgba(3, 3, 3, 0.16);
  border: solid 1px ${color.ovBlack};
  background-color: ${color.ovWhite};
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 0;
  letter-spacing: normal;
  text-align: center;
  color: ${color.ovBlack};
  cursor: pointer;
  display: block;
  width: 220px;
  margin: auto;

  &:hover{
    background-color: ${color.ovBlack};
    color: ${color.ovWhite};
  }
`;

export const StyledMarker = styled(MapMarkerAlt)`
	color: ${color.ovBlue};
	height: 15px;
	padding-right: 5px;
`;
export const AttractionAddress = styled.div`
	text-align: left; 
	font-family: ${typo.ovFontSerif};
	font-size: 15px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${color.ovBlack};
  margin: 8px 0;
`;

