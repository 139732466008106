export const LIST_ATTRACTIONS_REQUESTED = 'attractions/LIST_ATTRACTIONS_REQUESTED';
export const LIST_ATTRACTIONS_SUCCESS = 'attractions/LIST_ATTRACTIONS_SUCCESS';
export const LIST_ATTRACTIONS_FAILURE = 'attractions/LIST_ATTRACTIONS_FAILURE';

export const listAttractionsRequest = (collegeId) => ({
  type: LIST_ATTRACTIONS_REQUESTED,
  payload: { collegeId },
});

export const listAttractionsSuccess = (collegeId, data) => ({
  type: LIST_ATTRACTIONS_SUCCESS,
  payload: { collegeId, data },
});

export const listAttractionsFailure = (message) => ({
  type: LIST_ATTRACTIONS_FAILURE,
  payload: { message },
});

export const LIST_ATTRACTIONS_TAGS_REQUESTED = 'attractions/LIST_ATTRACTIONS_TAGS_REQUESTED';
export const LIST_ATTRACTIONS_TAGS_SUCCESS = 'attractions/LIST_ATTRACTIONS_TAGS_SUCCESS';
export const LIST_ATTRACTIONS_TAGS_FAILURE = 'attractions/LIST_ATTRACTIONS_TAGS_FAILURE';

export const listAttractionsTagsRequest = () => ({
  type: LIST_ATTRACTIONS_TAGS_REQUESTED,
});

export const listAttractionsTagsSuccess = (data) => ({
  type: LIST_ATTRACTIONS_TAGS_SUCCESS,
  payload: data,
});

export const listAttractionsTagsFailure = (message) => ({
  type: LIST_ATTRACTIONS_TAGS_FAILURE,
  payload: { message },
});

export const GET_ATTRACTION_REQUESTED = 'attractions/GET_ATTRACTION_REQUESTED';
export const GET_ATTRACTION_SUCCESS = 'attractions/GET_ATTRACTION_SUCCESS';
export const GET_ATTRACTION_FAILURE = 'attractions/GET_ATTRACTION_FAILURE';

export const getAttractionRequest = (ID) => ({
  type: GET_ATTRACTION_REQUESTED,
  payload: { ID }
});

export const getAttractionSuccess = (data) => ({
  type: GET_ATTRACTION_SUCCESS,
  payload: data,
});

export const getAttractionFailure = (message) => ({
  type: GET_ATTRACTION_FAILURE,
  payload: { message },
});

export const GET_ATTRACTION_CONTENT_REQUESTED = 'attractions/GET_ATTRACTION_CONTENT_REQUESTED';
export const GET_ATTRACTION_CONTENT_SUCCESS = 'attractions/GET_ATTRACTION_CONTENT_SUCCESS';
export const GET_ATTRACTION_CONTENT_FAILURE = 'attractions/GET_ATTRACTION_CONTENT_FAILURE';

export const getAttractionContentRequest = () => ({
  type: GET_ATTRACTION_CONTENT_REQUESTED
});

export const getAttractionContentSuccess = (data) => ({
  type: GET_ATTRACTION_CONTENT_SUCCESS,
  payload: data
});

export const getAttractionContentFailure = () => ({
  type: GET_ATTRACTION_CONTENT_FAILURE
});

export const GET_ATTRACTION_REVIEWS_REQUESTED = 'attractions/GET_ATTRACTION_REVIEWS_REQUESTED';
export const GET_ATTRACTION_REVIEWS_SUCCESS = 'attractions/GET_ATTRACTION_REVIEWS_SUCCESS';
export const GET_ATTRACTION_REVIEWS_FAILURE = 'attractions/GET_ATTRACTION_REVIEWS_FAILURE';

export const getAttractionReviewsRequest = (ID) => ({
  type: GET_ATTRACTION_REVIEWS_REQUESTED,
  payload: {ID}
});

export const getAttractionReviewsSuccess = (ID, data) => ({
  type: GET_ATTRACTION_REVIEWS_SUCCESS,
  payload: { ID, data },
});

export const getAttractionReviewsFailure = (message) => ({
  type: GET_ATTRACTION_REVIEWS_FAILURE,
  payload: { message },
});

export const SET_ATTRACTION_ABOUT = 'attractions/SET_ATTRACTION_ABOUT';

export const setAttractionAbout = (message) => ({
  type: SET_ATTRACTION_ABOUT,
  payload: { message },
});

export const CREATE_ATTRACTION_REQUESTED = 'attractions/CREATE_ATTRACTION_REQUESTED';
export const CREATE_ATTRACTION_SUCCESS = 'attractions/CREATE_ATTRACTION_SUCCESS';
export const CREATE_ATTRACTION_FAILURE = 'attractions/CREATE_ATTRACTION_FAILURE';

export const requestCreateAttraction = () => ({
  type: CREATE_ATTRACTION_REQUESTED,
});

export const createAttractionSuccess = (data) => ({
  type: CREATE_ATTRACTION_SUCCESS,
  payload: data,
});

export const createAttractionFailure = (message) => ({
  type: CREATE_ATTRACTION_FAILURE,
  payload: { message },
});


export const UPDATE_ATTRACTION_REQUESTED = 'attractions/UPDATE_ATTRACTION_REQUESTED';
export const UPDATE_ATTRACTION_SUCCESS = 'attractions/UPDATE_ATTRACTION_SUCCESS';
export const UPDATE_ATTRACTION_FAILURE = 'attractions/UPDATE_ATTRACTION_FAILURE';

export const updateAttractionRequest = (data) => ({
  type: UPDATE_ATTRACTION_REQUESTED,
  payload: data
});

export const updateAttractionSuccess = (data) => ({
  type: UPDATE_ATTRACTION_SUCCESS,
  payload: data,
});

export const updateAttractionFailure = (message) => ({
  type: UPDATE_ATTRACTION_FAILURE,
  payload: { message },
});

export const GET_USER_BY_CONTENT_ID_REQUESTED = 'attractions/GET_USER_BY_CONTENT_ID_REQUESTED';
export const GET_USER_BY_CONTENT_ID_SUCCESS = 'attractions/GET_USER_BY_CONTENT_ID_SUCCESS';
export const GET_USER_BY_CONTENT_ID_FAILURE = 'attractions/GET_USER_BY_CONTENT_ID_FAILURE';

export const requestGetUserId = () => ({
  type: GET_USER_BY_CONTENT_ID_REQUESTED
});

export const getUserIdSuccess = (data) => ({
  type: GET_USER_BY_CONTENT_ID_SUCCESS,
  payload: data
});

export const getUserIdFailure = (message) => ({
  type: GET_USER_BY_CONTENT_ID_FAILURE,
  payload: message
});

export const ATTRACTION_CONTENT_INDEX_SELECTED = 'attractions/ATTRACTION_CONTENT_INDEX_SELECTED';

export const selectAttractionContentIndex = (contentIndex) => ({
  type: ATTRACTION_CONTENT_INDEX_SELECTED,
  payload: contentIndex
});

export const GET_TARGET_ATTRACTIONS_REQUESTED = 'attractions/GET_TARGET_ATTRACTIONS_REQUESTED';
export const GET_TARGET_ATTRACTIONS_SUCCESS   = 'attractions/GET_TARGET_ATTRACTIONS_SUCCESS';
export const GET_TARGET_ATTRACTIONS_FAILURE   = 'attractions/GET_TARGET_ATTRACTIONS_FAILURE';

export const getTargetAttractionsRequest = (ID) => ({
  type: GET_TARGET_ATTRACTIONS_REQUESTED,
  payload: { ID }
});

export const getTargetAttractionsSuccess = (data) => ({
  type: GET_TARGET_ATTRACTIONS_SUCCESS,
  payload: data,
});

export const getTargetAttractionsFailure = (message) => ({
  type: GET_TARGET_ATTRACTIONS_FAILURE,
  payload: { message },
});

export const GET_ALL_ATTRACTIONS_REQUESTED = 'attractions/GET_ALL_ATTRACTIONS_REQUESTED';
export const GET_ALL_ATTRACTIONS_SUCCESS   = 'attractions/GET_ALL_ATTRACTIONS_SUCCESS';
export const GET_ALL_ATTRACTIONS_FAILURE   = 'attractions/GET_ALL_ATTRACTIONS_FAILURE';

export const getAllAttractionsRequest = () => ({
  type: GET_ALL_ATTRACTIONS_REQUESTED,
});

export const getAllAttractionsSuccess = (data) => ({
  type: GET_ALL_ATTRACTIONS_SUCCESS,
  payload: data,
});

export const getAllAttractionsFailure = (message) => ({
  type: GET_ALL_ATTRACTIONS_FAILURE,
  payload: { message },
});

export const DELETED_ATTRACTION = 'attractions/DELETED_ATTRACTION';

export const deleteAttractionSuccess = (attraction) => ({
  type: DELETED_ATTRACTION,
  payload: attraction,
});
