import { apiEndpoint } from '../config/app';
import createRestApiClient from '../utils/createRestApiClient';

export default () => {
  // Adds token to client if it is passed in
  const token = localStorage.getItem('ovSessToken');
  const config = token ? {
    baseURL: apiEndpoint,
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  } : { baseURL: apiEndpoint };

  const client = createRestApiClient().withConfig(config);
  return {

    newAttractionReview: (id, review) => client.request({
      method: 'POST',
      url: '/a/review/attraction/' + id,
      data: review
    }),
    
    getAuthAttractionReviews: id => client.request({
      method: 'GET',
      url: `/a/reviews/attraction/${id}`
    }),

    getApiAttractionReviews: id => client.request({
      method: 'GET',
      url: `/api/reviews/attraction/${id}`
    }),

    getContent: (id) => client.request({
      method: 'GET',
      url: '/api/attraction/' + id + '/content'
    }),

    getAttraction: (id) => client.request({
      method: 'GET',
      url: '/api/attraction/' + id
    }),

    getAllAttractions: () => client.request({
      method: 'GET',
      url: '/api/attractions'
    }),

    //
    // for admins only
    updateAttraction: (id, data) => client.request({
      method: 'PUT',
      url: `/aa/attraction/${id}`,
      data
    }),

    newAttraction: (data) => client.request({
      method: 'POST',
      url: '/aa/attraction',
      data
    }),

    deleteAttraction: (attractionId) => client.request({
      method: 'DELETE',
      url: `/aa/attraction/${attractionId}`,
    })
  };
};
