// eslint-disable-next-line
import filter from 'lodash/filter';
import find from 'lodash/find';

import { contentService, collegeService } from '../services';
import { getAverageReviews } from "./college";

export const GET_CONTENT_REQUESTED = 'home/GET_CONTENT_REQUESTED';
export const GET_CONTENT_SUCCESS = 'home/GET_CONTENT_SUCCESS';
export const GET_CONTENT_FAILURE = 'home/GET_CONTENT_FAILURE';

export const GET_COLLEGE_REQUESTED = 'home/GET_COLLEGE_REQUESTED';
export const GET_COLLEGE_SUCCESS = 'home/GET_COLLEGE_SUCCESS';
export const GET_COLLEGE_FAILURE = 'home/GET_COLLEGE_FAILURE';

export const GET_AVERAGE_COLLEGE_REQUESTED = 'home/GET_AVERAGE_COLLEGE_REQUESTED';
export const GET_AVERAGE_COLLEGE_SUCCESS = 'home/GET_AVERAGE_COLLEGE_SUCCESS';
export const GET_AVERAGE_COLLEGE_FAILURE = 'home/GET_AVERAGE_COLLEGE_FAILURE';

export const GET_LARGEST_STADIUMS_REQUESTED = 'home/GET_LARGEST_STADIUMS_REQUESTED';
export const GET_LARGEST_STADIUMS_SUCCESS = 'home/GET_LARGEST_STADIUMS_SUCCESS';
export const GET_LARGEST_STADIUMS_FAILURE = 'home/GET_LARGEST_STADIUMS_FAILURE';

export const GET_TOP_CONFERENCES_REQUESTED = 'home/GET_TOP_CONFERENCES_REQUESTED';
export const GET_TOP_CONFERENCES_SUCCESS = 'home/GET_TOP_CONFERENCES_SUCCESS';
export const GET_TOP_CONFERENCES_FAILURE = 'home/GET_TOP_CONFERENCES_FAILURE';

export const GET_WARMEST_CLIMATES_REQUESTED = 'home/GET_WARMEST_CLIMATES_REQUESTED';
export const GET_WARMEST_CLIMATES_SUCCESS = 'home/GET_WARMEST_CLIMATES_SUCCESS';
export const GET_WARMEST_CLIMATES_FAILURE = 'home/GET_WARMEST_CLIMATES_FAILURE';

export const BACKGROUND_SET = 'home/BACKGROUND_SET';
export const BACKGROUND_CLEARED = 'home/BACKGROUND_CLEARED';

export const ADD_RECENT_SEARCH = 'home/ADD_RECENT_SEARCH';

const initialState = {
  backgroundSrc: '',
  collegeName: '',
  collegeCity: '',
  collegeState: '',
  recentSearches: [],
  isMainSearchFocused: false,
  isFetching: false,
  isFetchingAverageColleges: false,
  topAverageColleges: []
}

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_CONTENT_REQUESTED:
      return {
        ...state,
        isFetching: true
      }

    case GET_CONTENT_SUCCESS:
      return {
        ...state,
        backgroundSrc: action.payload,
        isFetching: false
      }

    case GET_CONTENT_FAILURE:
      return {
        ...state,
        isFetching: false
      }

    case GET_AVERAGE_COLLEGE_REQUESTED:
      return {
        ...state,
        isFetchingAverageColleges: true
      }

    case GET_AVERAGE_COLLEGE_SUCCESS:
      return {
        ...state,
        topAverageColleges: removeDuplicates([action.payload, ...state.topAverageColleges], 'name'),
        isFetchingAverageColleges: false
      }

    case GET_AVERAGE_COLLEGE_FAILURE:
      return {
        ...state,
        isFetchingAverageColleges: false
      }

    case GET_LARGEST_STADIUMS_REQUESTED:
      return {
        ...state,
        isFetchingLargestStadiums: true,
        largestStadiums: null,
        largestStadiumsError: null,
      }

    case GET_LARGEST_STADIUMS_SUCCESS:
      return {
        ...state,
        isFetchingLargestStadiums: false,
        largestStadiums: action.payload,
      }

    case GET_LARGEST_STADIUMS_FAILURE:
      return {
        ...state,
        isFetchingLargestStadiums: false,
        largestStadiumsError: action.payload,
      }

    case GET_TOP_CONFERENCES_REQUESTED:
      return {
        ...state,
        isFetchingTopConferences: true,
        topConferencesError: null,
      }

    case GET_TOP_CONFERENCES_SUCCESS:
      return {
        ...state,
        isFetchingTopConferences: false,
        topConferences: action.payload,
      }

    case GET_TOP_CONFERENCES_FAILURE:
      return {
        ...state,
        isFetchingTopConferences: false,
        topConferencesError: action.payload,
      }
    case GET_WARMEST_CLIMATES_REQUESTED:
      return {
        ...state,
        isFetchingWarmestClimates: true,
        warmestClimates: null,
        warmestClimatesError: null,
      }

    case GET_WARMEST_CLIMATES_SUCCESS:
      return {
        ...state,
        isFetchingWarmestClimates: false,
        warmestClimates: action.payload,
      }

    case GET_WARMEST_CLIMATES_FAILURE:
      return {
        ...state,
        isFetchingWarmestClimates: false,
        warmestClimatesError: action.payload,
      }

    case GET_COLLEGE_REQUESTED:
      return {
        ...state,
        isFetching: true
      }

    case GET_COLLEGE_SUCCESS:
      return {
        ...state,
        collegeName: action.payload.name,
        collegeCity: action.payload.address.city,
        collegeState: action.payload.address.state,
        isFetching: false
      }

    case GET_COLLEGE_FAILURE:
      return {
        ...state,
        isFetching: false
      }

    case BACKGROUND_SET:
      return {
        ...state
      }

    case BACKGROUND_CLEARED:
      return {
        ...state,
        isMainSearchFocused: false
      }

    case ADD_RECENT_SEARCH:
      return {
        ...state,
        isMainSearchFocused: false,
        recentSearches: removeDuplicates([action.payload, ...state.recentSearches], 'name').filter((search, i) => (i < 6 && search))
      }

    case '@@redux-form/FOCUS':
      return {
        ...state,
        isMainSearchFocused: action.meta.form === 'collegeSearchFilter' && action.meta.field === 'text'
      }

    case '@@redux-form/CHANGE':
      return {
        ...state,
        isMainSearchFocused: false
      }

    default:
      return state
  }
}

const removeDuplicates = (myArr, prop) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

export const addRecentSearch = (collegeName, url_slug) => ({
  type: ADD_RECENT_SEARCH,
  payload: {
    url: url_slug,
    name: collegeName
  }
})

const requestContent = () => ({
  type: GET_CONTENT_REQUESTED
})

const contentSuccess = (content) => ({
  type: GET_CONTENT_SUCCESS,
  payload: content.src_lg
})

const contentFailure = () => ({
  type: GET_CONTENT_FAILURE
})

export const setBackgroundIfEmpty = () => {
  return async (dispatch, getState) => {
    const { home } = getState();
    if (!home.backgroundSrc || !home.collegeName) {
      dispatch(requestContent())

      // USE THIS TO SET WHICH BACKGROUND TO USE
      const contentId = 670;

      try {
        const res = await contentService().getContentById(contentId);

        if (res.status === 200) {
          // success in registering and redirect to login
          dispatch(contentSuccess(res.data.data));
          dispatch(setCollegeInfo(res.data.data.contributable_id))
        }

      } catch (e) {
        return dispatch(contentFailure());
      }
    }

    return dispatch(setBackground());
  }
}

const requestCollege = () => ({
  type: GET_COLLEGE_REQUESTED
})

const collegeSuccess = (college) => ({
  type: GET_COLLEGE_SUCCESS,
  payload: college
})

const collegeFailure = () => ({
  type: GET_COLLEGE_FAILURE
})

const setCollegeInfo = (collegeId) => {
  return async (dispatch) => {
    dispatch(requestCollege())

    try {
      const res = await collegeService().getCollege(collegeId);

      if (res.status === 200) {
        // success in registering and redirect to login
        return dispatch(collegeSuccess(res.data.data));
      }

    } catch (e) {
      return dispatch(collegeFailure());
    }
  }
}


export const setBackground = () => {
  return (dispatch) => {
    return dispatch({
      type: BACKGROUND_SET
    })
  }
}

export const clearBackground = () => {
  return (dispatch) => {
    document.body.style.background = "";
    return dispatch({
      type: BACKGROUND_CLEARED
    })
  }
}

const requestAverageCollege = () => ({
  type: GET_AVERAGE_COLLEGE_REQUESTED
})

const collegeAverageSuccess = (data) => ({
  type: GET_AVERAGE_COLLEGE_SUCCESS,
  payload: data
})

const collegeAverageFailure = () => ({
  type: GET_AVERAGE_COLLEGE_FAILURE
})

export const getAverageCollege = (collegeIds) => {
  return async (dispatch, getState) => {
    dispatch(requestAverageCollege());

    let { college } = getState();

    if (!college.averageReviews.length) {
      await dispatch(getAverageReviews());
      college = getState().college;
    }

    try {
      const res = collegeIds.map(
        id => college.averageReviews.find(({ ID }) => ID === id)
      ).filter(el => el);

      res.map((el) => {
        return dispatch(collegeAverageSuccess(el));
      })
    } catch (e) {
      console.log(e);
      return dispatch(collegeAverageFailure());
    }
  }
}

const requestLargestStadium = () => ({
  type: GET_LARGEST_STADIUMS_REQUESTED
})

const largestStadiumSuccess = (data) => ({
  type: GET_LARGEST_STADIUMS_SUCCESS,
  payload: data
})

const largestStadiumFailure = () => ({
  type: GET_LARGEST_STADIUMS_FAILURE
})


export const getLargestStadiums = (collegeSlugs) => {
  return async (dispatch, getState) => {
    let { college } = getState();

    if (!college.colleges || !college.colleges.length) return;

    dispatch(requestLargestStadium());

    const filtered = collegeSlugs.map(url_slug => find(college.colleges, { url_slug }));

    if (!college.averageReviews.length) {
      await dispatch(getAverageReviews())
      college = getState().college;
    }

    try {
      const data = filtered.map(
        el => college.averageReviews.find(({ ID }) => ID === el.ID)
      );

      const largest = filtered.map(college => ({
        ...college,
        ...find(data, { ID: college.ID }),
      }));

      return dispatch(largestStadiumSuccess(largest));

    } catch (e) {
      console.log(e)
      return dispatch(largestStadiumFailure());
    }
  }
};


const requestWarmestClimates = () => ({
  type: GET_WARMEST_CLIMATES_REQUESTED
})

const warmestClimatesSuccess = (data) => ({
  type: GET_WARMEST_CLIMATES_SUCCESS,
  payload: data
})

const warmestClimatesFailure = () => ({
  type: GET_WARMEST_CLIMATES_FAILURE
})


export const getWarmestClimates = (collegeSlugs) => {
  return async (dispatch, getState) => {
    let { college } = getState();

    if (!college.colleges || !college.colleges.length) return;

    dispatch(requestWarmestClimates());

    const filtered = collegeSlugs.map(url_slug => find(college.colleges, { url_slug }));

    if (!college.averageReviews.length) {
      await dispatch(getAverageReviews())
      college = getState().college;
    }

    try {
      const data = filtered.map(
        el => college.averageReviews.find(({ ID }) => ID === el.ID)
      );
      const warmest = filtered.map(college => ({
        ...college,
        ...find(data, { ID: college.ID }),
      }));

      return dispatch(warmestClimatesSuccess(warmest));

    } catch (e) {
      console.log(e)
      return dispatch(warmestClimatesFailure());
    }
  }
};

const requestTopConference = () => ({
  type: GET_TOP_CONFERENCES_REQUESTED
});

const topConferenceSuccess = (data) => ({
  type: GET_TOP_CONFERENCES_SUCCESS,
  payload: data
});

const topConferenceFailure = () => ({
  type: GET_TOP_CONFERENCES_FAILURE
});

export const getTopConference = (confNames, slugFilter = []) => {
  return async (dispatch, getState) => {
    let { college } = getState();

    if (!college.colleges || !college.colleges.length) return;

    dispatch(requestTopConference());

    // prepare confNames
    confNames = confNames.map(el => el.toLowerCase().replace(" ", "_").replace("-", "_").replace(/[-' ()]/gi, ""));

    let filtered = [];
    confNames.forEach(conf => {
      college.colleges.filter((el) => {
        if (!el.teams || !el.teams[0] || !el.teams[0].stats) return false;

        // apply filter
        if (slugFilter.length) {
          if (!slugFilter.some(v => el.url_slug === v)) return false;
        }

        const miscIndex = el.teams[0].stats.findIndex(({ title }) => title === 'misc');
        const miscItem = miscIndex > -1 && el.teams[0].stats[miscIndex];

        if (miscItem) {
          const conference = (find(miscItem.data, { label: 'Conference' }) || {});
          return conference.value === conf
        }
        return false;
      }).forEach(el => filtered.push(el))
    });
    filtered = filtered.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

    if (!college.averageReviews.length) {
      await dispatch(getAverageReviews());
      college = getState().college;
    }

    try {
      const data = filtered.map(
        el => college.averageReviews.find(({ ID }) => ID === el.ID)
      );

      const conferences = filtered.map(college => ({
        ...college,
        ...find(data, { ID: college.ID }),
      }));

      return dispatch(topConferenceSuccess(conferences));

    } catch (e) {
      console.log(e);
      return dispatch(topConferenceFailure());
    }
  }
};


