import styled from 'styled-components';
import { Search as SearchIcon } from 'styled-icons/material/Search';
import { color, typo, bp } from '../../../styles';

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;

  > a {
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 22px;
    font-weight: 600;
    line-height: 1;
    color: ${color.ovBlack};

    &[data-active=true] {
      background-color: ${color.ovBlue};
      color: ${color.ovWhite};
    }

    &[data-prev] {
      margin-right: 10px;
    }
    &[data-next] {
      margin-left: 10px;
    }
  }
 
  > span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 22px;
    font-weight: 600;
    line-height: 1;
    color: ${color.ovBlack};
  }
`;
