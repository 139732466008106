import styled from "styled-components";
import { Heading } from 'rebass';
import { color, typo, bp } from "../../styles";

export const GridContainer = styled.div`
  text-align: center;
  padding: 63px 111px;
  @media ${bp.phoneOnly}{
    padding: 40px;
  }
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 99;
  top: 30px;
  right: 30px;
  height: 22px;
  width: 22px;
`;

export const TitleHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 30px;
  text-align: left;
  margin-bottom: 20px;
  font-weight: 400;
`;

export const OVButton = styled.button`
  display: flex;
  flex-direction: row;
  min-height: 82px;
  border-radius: 10px;
  border: solid 1px ${color.ovBlue};
  transition: all .1s;
  text-align: center;
  padding: 10px 70px 10px 20px;
  position: relative;
  width: 100%;
  margin: 0 0 30px 0;
  background: ${props => (props.selected ? color.ovBlue : color.ovWhite)};
  cursor: pointer;
  font-family: ${typo.ovFontFeature};
  font-size: 25px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  align-items: center;
  &:hover,
  &:focus {
    text-decoration: none;
    background: ${props =>
      props.selected ? color.ovBlue : color.ovWhite};
    border-color: ${color.ovBlueLight};
  }
  @media ${bp.phoneOnly} {
    height: auto;
    padding: 0 10px;
  }
  span{    
    &:last-child{
      color: ${props => (props.selected ? color.ovWhite : color.ovGrey)};
    }
    &:first-child{
      svg{
        path, circle, polygon{
          fill: ${props => (props.selected ? color.ovWhite : color.ovBlue)} !important;
        }
        text{
          fill: ${props => (props.selected ? color.ovBlue : color.ovWhite)} !important;
        }
      }
    }
  }
`;
export const IconContainerStudent = styled.span`
  float: left; 
  width: 78px;
  height: 55px;
  margin-left: 0px;
`;

export const IconContainerFan = styled.span`
  float: left;
  width: 25px;
  height: 55px;
  margin-left: 30px;
`;

export const IconContainerRecruit = styled.span`
  float: left;
  width: 44px;
  height: 55px;
  margin-left: 18px;
`;

export const IconContainerAthlete = styled.span`
  float: left;
  width: 40px;
  height: 55px;
  margin-left: 20px;
`;

export const RoleLabel = styled.span`
  float: right;
  width: 80%;
  padding-left: 30px;
`;

export const NextButton = styled.button`
  height: 59px;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px ${color.ovBlue};
  background-color:  ${color.ovBlue};
  color: ${color.ovWhite};
  width: 100%;
  font-family: ${typo.ovFontFeature};
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: center;
  font-size: 25px;
  transition: all .1s;
  &:hover{
    background-color:  ${color.ovWhite};
    color: ${color.ovBlue};
    transition: all .1s;
  }
  @media ${bp.phoneOnly} {
    font-size: 22px;
  }
`;