import React, { Fragment } from 'react';

import Header from './Header';
import Item from './Item';
const PostList = ({
  categories,
  posts,
  push,
}) => (
  <Fragment>
    <Header categories={categories} />

    {posts && posts.map(post => (
      <Item key={`post-${post.slug}`} {...post} push={push} />
    ))}

  </Fragment>
);

export default PostList;