import React from 'react';
import { Heading } from 'rebass';
// Styles stuff
import styled from 'styled-components';
import { color, typo, bp } from '../../styles';
// Components
import { PageContainer } from '../../components';
import { Helmet } from "react-helmet";

// Styled Components
const PageContent = styled.div`
  grid-column: span 12;
  background-color: ${color.ovWhite};
  border: 1px solid ${color.ovGrey};
  padding: 25px;
  margin: 10px 0;
`;
const TitleContainer = styled.div`
  grid-column: span 12;
`;
const AboutHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: .8;
  letter-spacing: normal;
  text-align: left;
  padding: 0 10px;
  @media ${bp.phoneOnly} {
    font-size: 1.8rem;
  }
`;
const AboutHr = styled.div`
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid ${color.ovGrey};
  margin: 5px 0 0;
  padding: 0;
`;
const PrivacyContainer = styled.div`
  padding: 1rem;
  font-family: ${typo.ovFontSerif};
  font-size: 20px;
  color: ${color.ovBlack};
`;
// end of Styled Components


const Privacy = () => {
	return(
		<PageContainer>
      <Helmet>
        <title>Privacy Policy - OfficialVisit</title>
        <meta name="description" content="Read OfficialVisit's privacy policy." />
      </Helmet>
	  		<PageContent>
	            <TitleContainer>
	              <AboutHeading>Privacy Policy</AboutHeading>
	              <AboutHr />
	            </TitleContainer>
	            <PrivacyContainer>
	            	<p>OfficialVisit is an online service that provides reviews, ratings, information, and recommendations about colleges, their athletic programs, and their employees for the informational use of fans and prospective college students. Our website, mobile properties, and related applications (collectively, our “Website”) are owned and operated by OfficialVisit Inc.</p>

					     <p>We understand that providing information online involves a great deal of trust on your part. We take this trust very seriously, and make it a high priority to ensure the security and confidentiality of the personal information you provide to us when you visit our Website or use our services. Before submitting your personal information to us, please read this Policy carefully to learn about our privacy practices. By visiting OfficialVisit’s website, www.officialvisit.com, or in connection with other sites or services, you are accepting the practices described herein.</p>

               <ul>
                 <li>What information we collect from you</li>
                 <li>How we use your information</li>
                 <li>With whom we share your information</li>
                 <li>How you can access your information</li>
                 <li>Your choices with respect to the collection and use of your information</li>
                 <li>How we protect your information</li>
                 <li>Children's privacy</li>
                 <li>External links</li>
                 <li>Visiting our Website from outside the United States</li>
                 <li>Your California privacy rights</li>
                 <li>Changes to this Privacy Policy</li>
                 <li>How you can contact us</li>
               </ul>

               <h3><strong>What information we collect from you</strong></h3>
               <p><strong>In General.</strong></p>
               <p>We receive and store any information you enter on our Website or give us in any other way. This includes information that can identify you ("personal information"), including your first and last name, telephone number, postal and email addresses, user name and password, the schools you attended, and the activities and subjects you participated in while attending those schools.  We may also request information about your college plans, interests, and preferences.  You can choose not to provide information to us, but in general some information about you is required in order for you to receive relevant offerings from OfficialVisit and its partners and enjoy other benefits of being a registered member. For example, only registered members are able to post college reviews, email OfficialVisit content to themselves and others, access members-only newsletters and college and college athletics planning content, and enter surveys, contests, or sweepstakes. In addition, some information is also required in order for you to ask us a question or initiate other transactions on our site.</p>
               <p><strong>Information About Others.</strong></p>
               <p>You will have the opportunity to provide contact and school-related information for other people through our Website. If you submit a review for someone else through OfficialVisit, you will need to submit that individual’s personal information. You must obtain the consent of other individuals prior to providing us with their personal information, as any access to view or modify their information will be available only through your account.</p>
               <p><strong>Information from Other Sources.</strong></p>
               <p>We also may periodically obtain both personal and non-personal information about you from affiliated entities, business partners and other independent third-party sources and add it to other information about you. For example, if you visit OfficialVisit by “clicking through” from a site operated by one of our business partners, and you have registered with that partner, then information about you that you have provided to that partner may be shared with us, such as contact information and demographic information. As another example, if you access third party services, such as social media services, through our Website or before coming to our Website, we may collect information such as your user name, password, and other information made available to us through those services. We will work with our existing partners and potential future partners to improve and personalize your use of our Website in accordance with the practices outlined in this section and Policy.</p>
               <p><strong>Automatic Information.</strong></p>
               <p>We automatically collect some information from your computer or Device when you visit OfficialVisit. For example, we will collect session data, including your IP address, Web browser software, and referring website. We also may collect information about your online activity, such as content viewed, pages visited, and searches and/or reservations facilitated or made. One of our goals in collecting this automatic information is to help us understand the interests and preferences of our users and customize your user experience.</p>
               <p><strong>Cookies and Other Web Technologies.</strong></p>
               <p>Cookies are small data text files and can be stored on your computer’s hard drive or your Device (if your Web browser permits). OfficialVisit’s website uses cookies for the following general purposes:</p>
               <ul>
                 <li>To help us recognize your browser as a previous visitor and save and remember any preferences that may have been set while your browser was visiting our site. For example, if you register on our site, we may use cookies to remember your registration information, so you do not need to log into our site each time you visit. We also may record your password in a cookie, if you checked the box entitled "Save this password for automatic sign-in." Please note that member IDs, passwords, and any other account-related data included in such cookies are encrypted for security purposes.</li>
                 <li>To help us customize the content, experience, and advertisements provided to you on our websites and Apps and on other websites across the Internet. For example, when you access a web page, a cookie is automatically set by us, our service providers, or our partners to recognize your browser as you navigate on the Internet and to present you with information and advertising based on your apparent interests.</li>
                 <li>To help measure and research the effectiveness of features and offerings, advertisements, and email communications (by determining which emails you open and act upon).</li>
               </ul>
               <p>The Help portion of the toolbar on most browsers should tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable most types of cookies. Please note that if you refuse to accept cookies, you may not be able to access many of the tools offered on our sites.</p>
               <p>Our sites also may use Web beacons (also known as clear gifs, pixel tags or Web bugs), which are tiny graphics with a unique identifier, similar in function to cookies, that are placed in the code of a Web page. We use Web beacons to monitor the traffic patterns of users from one page within our sites to another, to deliver or communicate with cookies, to understand whether you have come to our site from an online advertisement displayed on a third-party website, and to improve site performance. We also may allow our service providers to use Web beacons to help us understand which emails have been opened by recipients and to track the visitor traffic and actions on our site. This helps us measure the effectiveness of our content and other offerings.</p>
               <p><strong>Display of Advertising/Your Choices.</strong></p>
               <p>Data collected to serve you with relevant advertising OfficialVisit is committed to providing you with relevant content and information. To do this, we may collect information about your college-related searches and other site activities and use this information to serve you with ads on our sites or elsewhere online that match your apparent interests. So, for example, if you search for a college in Florida on OfficialVisit, you may see an ad for a Florida-based college on OfficialVisit or on another website you visit.</p>
               <p>We do not share your personal information (such as email addresses) with unaffiliated third parties so they can serve you with advertisements</p>
               <p>Data collected by business partners and ad networks to serve you with relevant advertising Many of the advertisements you see on OfficialVisit are served by OfficialVisit or its service providers. But we also allow third parties to collect information about your online activities through cookies and other technologies. These third parties include (1) business partners, who collect information when you view or interact with one of their advertisements on our sites, and (2) advertising networks, who collect information about your interests when you view or interact with one of the advertisements they place on many different websites on the Internet. The information gathered by these third parties is used to make predictions about your characteristics, interests or preferences and to display advertisements on our sites and across the Internet tailored to your apparent interests.</p>
               <p>Please note that we do not have access to or control over cookies or other technologies these third parties may use to collect information about your interests, and the information practices of these third parties are not covered by this Privacy Policy. Some of these companies are members of the <a target="_blank" rel="noopener noreferrer" href="https://www.networkadvertising.org/">Network Advertising Initiative</a>, which offers a single location to opt out of ad targeting from member companies.</p>
               <h3>Response to Do Not Track signals</h3>
               <p>Your web browser may transmit a “Do Not Track” (“DNT”) signal to OfficialVisit and other websites. Because of differences in web browsers’ default settings and the manner in which browsers incorporate DNT signals, it is sometimes unclear to site operators whether site visitors have selected the DNT setting themselves, or whether they are aware that they have a choice in determining the setting. OfficialVisit does not currently respond to DNT signals. Once the Internet industry has settled on standards related to this issue and OfficialVisit recognizes such standards, we may reevaluate this approach.</p>
               <h3>Your Choices</h3>
               <p>If you wish to limit or modify a particular way in which we use your information outlined in this privacy policy, you may email <a href="mailto:mike@officialvisit.com">mike@officialvisit.com</a> to request a change.</p>
               <h3><strong>How we use your information</strong></h3>
               <p>OfficialVisit uses the information we collect about you for the following general purposes: for registration and to manage your account, including to allow your access to and use of our site; to communicate with you in general, including to provide information about OfficialVisit and invitations from the OfficialVisit community; to enable us to publish your reviews, pictures, and other content to the OfficialVisit community; to respond to your questions and comments; to measure interest in and improve our products, services, and Website; to notify you about special offers and products or services available from us or our partners that may be of interest to you; to otherwise customize your experience with OfficialVisit, including customizing the ads shown to you; to solicit information from you, including through surveys; to resolve disputes or troubleshoot problems; to prevent potentially prohibited or illegal activities; to enforce our Terms of Service; and as otherwise described to you at the point of collection. Please review "Your choices with respect to the collection and use of your information" below.</p>
               <p><strong>With whom we share your information</strong></p>
               <p>OfficialVisit may share your information with the following entities:</p>
               <ul>
                 <li>Collegiate Entities, including but not limited to colleges, college athletic departments, and their employees.</li>
                 <li>athletic departments, and their employees. Third-party vendors who provide services or functions on our behalf, including business analytics, customer service, marketing, public relations, distribution of surveys or sweepstakes programs, and fraud prevention. We may also authorize third-party vendors to collect information on our behalf, including, for example, as necessary to operate features of our Website or to facilitate the delivery of online advertising tailored to your interests. Third-party vendors have access to and may collect information only as needed to perform their functions and are not permitted to share or use the information for any other purpose.</li>
                 <li>Business partners with whom we may offer products or services in conjunction. You can tell when a third party is involved in a product or service you have requested because their name will appear either with ours or separately. If you choose to access these optional services, we may share information about you, including your personal information, with those partners. Please note that we do not control the privacy practices of these third-party business partners.</li>
                 <li>Referring Websites. If you were referred to OfficialVisit from another website, we may share your registration information, such as your name, email address, and educational background, about you with that referring website. We have not placed limitations on the referring websites’ use of personal information and we encourage you to review the privacy policies of any website that referred you to OfficialVisit.</li>
                 <li>Social Media Services. You can choose to access certain third party social media websites and services through our site (such as Twitter). When you do so, you are sharing information with those sites, and the information you share will be governed by their privacy policies. You may also be able to modify your privacy settings with these third party social media websites.</li>
               </ul>
               <p>We also may share your information if we believe, in our sole discretion, that such disclosure is necessary…</p>
               <ul>
                 <li>To comply with legitimate and enforceable subpoenas, court orders, or other legal process; to establish or exercise our legal rights; to defend against legal claims; or as otherwise required by law. In such cases we reserve the right to raise or waive any legal objection or right available to us.</li>
                 <li>To investigate, prevent, or take action regarding illegal or suspected illegal activities; to protect and defend the rights, property, or safety of OfficialVisit, our customers, or others; and in connection with our Terms of Service and other agreements.</li>
                 <li>In connection with a corporate transaction, such as a divestiture, merger, consolidation, or asset sale, or in the unlikely event of bankruptcy.</li>
               </ul>

               <p>If you choose to create a profile on OfficialVisit, certain information in your profile will be publicly viewable and identifiable via your display name. You will have the ability to view your profile as members of the public will see it.</p>

               <p>Other than as set out above, you will be notified when personal information about you will be shared with third parties, and you will have an opportunity to choose not to have us share such information.</p>
               <p>We also may share aggregate or anonymous information with third parties, including advertisers and investors. For example, we may tell our advertisers the number of visitors our Website receives or the most popular hotels and vacation destinations. This information does not contain any personal information and is used to develop content and services we hope you will find of interest.</p>

               <h3><strong>How you can access your information</strong></h3>
               <p>We take reasonable steps to ensure that your information is relevant to its intended use, accurate, and complete. You can access and update your contact information by visiting the Profile page on the OfficialVisit Website. You can close your OfficialVisit account by emailing mike@officialvisit.com. We will send you an email to confirm your request. Please note that after you close an account, you will not be able to sign in or access any of your personal information. However, you can open a new account at any time. Please also note that we may retain certain information associated with your account in our archives, including for analytical purposes as well as for recordkeeping integrity.</p>
               <h3><strong>Your choices with respect to the collection and use of your information</strong></h3>
               <ul>
                 <li>As discussed above, you can choose not to provide us with any information, although it may be needed to take advantage of certain features offered on OfficialVisit.</li>
                 <li>You also can add or update information and close your account as described above. If you decide to close your OfficialVisit account, we will deactivate your account and remove your profile information from active view. Please note, however, that we may retain in our files some information associated with your account and past transactions for purposes including backups, fraud prevention, dispute resolution, investigations, and compliance with legal requirements.</li>
                 <li>As a registered OfficialVisit member, you can modify your email subscription choices at any time by emailing mike@officialvisit.com. Please note that we reserve the right to send you other communications, including service announcements, administrative messages, and surveys relating either to your OfficialVisit account, without offering you the opportunity to opt out of receiving them.</li>
                 <li>Email Communications. OfficialVisit wants to make it easy for you to take advantage of college-related information on our Website. One way we do this is by sending you email messages that contain information about your apparent interests. We believe these email messages will provide you with useful information about special updates available on OfficialVisit.</li>
                 <li>The Help portion of the toolbar on most browsers will tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether. Please note that if you refuse to accept cookies from OfficialVisit, you may not be able to access portions of our site.</li>
               </ul>
               <h3><strong>How we protect your information</strong></h3>
               <p>We want you to feel confident about using OfficialVisit, and we are committed to protecting the information we collect. While no website can guarantee security, we have implemented appropriate administrative, technical, and physical security procedures to help protect the personal information you provide to us. For example, only authorized employees are permitted to access personal information, and they only may do so for permitted business functions. In addition, we use encryption when transmitting your personal information between your system and ours, and between our system and those of the parties with whom we share sensitive information.</p>
               <h3><strong>Children's privacy</strong></h3>
               <p>OfficialVisit is a general audience site and does not offer services directed to children. Should an individual whom we know to be a child under age 13 send personal information to us, we will delete or destroy such information as soon as reasonably possible.</p>
               <p>If any part of the OfficialVisit Website links you to other websites, those external websites do not operate under this Privacy Policy. We recommend that you examine the privacy statements posted on those other websites to understand their procedures for collecting, using, and disclosing personal information.</p>
               <h3><strong>Visiting our Website from outside the United States</strong></h3>
               <p>If you are visiting our Website from outside the United States, please be aware that your information may be transferred to, stored, and processed in the United States where our servers are located and our central database is operated. The data protection and other laws of the United States and other countries might not be as comprehensive as those in your country, but please be assured that OfficialVisit does take steps pursuant to laws in the United States to ensure that your privacy is protected. By using our services, you understand that your information may be transferred to our facilities and those third parties with whom we share it as described in this Policy.</p>
               <h3><strong>Your California privacy rights</strong></h3>
               <p>California Civil Code Section 1798.83 permits California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please contact us at the address provided below in the “How you can contact us” section.</p>
               <h3><strong>Changes to this Privacy Policy</strong></h3>
               <p>OfficialVisit may change or modify this Privacy Policy in the future. We will note the date that revisions were last made to this Privacy Policy at the bottom of this page, and any revisions will take effect upon posting. We will notify our members of material changes to this Privacy Policy by either sending a notice to the email address you provided to us or by placing a notice on our Website. We encourage you to check this Privacy Policy from time to time to review the most current version.</p>
               <h3><strong>How you can contact us</strong></h3>
                <p>If you have questions about this Privacy Policy, please email us at <a href="mailto:mike@officialvisit.com">mike@officialvisit.com</a>.</p>
                <p>©2019 OfficialVisit Inc. All rights reserved.</p>
                <p>Privacy Policy last updated on April 30th, 2019</p>

	            </PrivacyContainer>
	        </PageContent>
		</PageContainer>
	)
}

export default Privacy