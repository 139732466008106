import React, { useEffect, Fragment, useState } from 'react';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';

// Components
import Spinner from 'react-spinkit';
import { PageContainer } from '../../components';
import CollegeList from '../../components/CollegeList'

import { PageContent, BigTitleGrey, DetailsSubHeading, FullWithContent, LoadMoreButtonContainer, LoadMoreButton, LoadMoreButtonLabel, ShowMoreButton, HeaderDescription } from './Styled';

const CollegeAddress = ({ address }) => (
  <DetailsSubHeading as="h3">{address.city}, {address.state}</DetailsSubHeading>
);

const CollegeRankings = ({ isFetching, listCollegesIfEmpty, paginate, count, push }) => {

  const [more, toggleMore] = useState(false);
  const [page, setPage] = useState(1);
  const colleges = paginate(page);

  useEffect(() => {
    if (!colleges || !colleges.length) listCollegesIfEmpty();
  }, [colleges]);

  const year = new Date().getFullYear() + 1;

  return (
    <div>
      <Helmet>
        <title>{`${year} HIGHEST Ranked Undergraduate Colleges`}</title>
        <meta name="description" content="Discover the best division 1 undergraduate colleges in America. Stanford, Duke, Northwestern, Rice, Notre Dame, USC, Vanderbilt, Michigan, California, UCLA, Virginia…" />
      </Helmet>
      {isFetching
        ? (
          <PageContainer>
            <PageContent>
              <Spinner fadeIn="none" name="cube-grid" />
            </PageContent>
          </PageContainer>
        )

        : (

          <Fragment>
            <FullWithContent>
              <BigTitleGrey bigTitleLabel="OfficialVisit College Rankings" />
              <DetailsSubHeading as="h2">
                The top colleges and universities
              </DetailsSubHeading>

              {more && (
                <HeaderDescription>
                  <p>The OV+ Composite Academic Rating is a propriety algorithm that compiles college and university rankings listed in the public domain by the leading and most trusted publications and services.  It converts average industry rankings into a linear composite index capping at 1.0000, which indicates a consensus number one college or university across all publications and services.</p>
                  <p>While not every college is listed on OfficialVisit, the OV+ Composite Academic Rating factors every 4-year college and university in America.  For presentation purposes, we have ranked the colleges listed on OfficialVisit by OV+ Composite Academic Rating, but there are many other colleges and universities rated above, below, and in between the colleges and universities listed on OfficialVisit.</p>
                  <p>The OV+ Composite Academic Rating is the industry’s most comprehensive and unbiased college rating.</p>
                  <p>All major publications share an equal percentage in the OV+ Composite Academic Rating.</p>
                  <p>The composite index equally weights this percentage among the publications that participate in a ranking for the specific college or university.</p>
                  <p>OfficialVisit produces rankings that also contribute to the OV+ Composite Academic Rating.</p>
                  <p>The OfficialVisit College Rankings are the result of an arduous, data-driven analysis that is used to evaluate the merits of America’s top colleges.  We use a holistic set of criteria that focuses on the degree to which a college can forge positive academic, financial, and social outcomes for its students.</p>
                  <p>The OfficialVisit College Rankings are a measure of a college’s ability to develop all of its students, not just student-athletes.  While athletic data is factored into the OfficialVisit College Ranking, athletics are weighted less than 1.0% of a college’s overall rating.</p>
                  <p>Here is where we source our data:</p>

                  <ol>
                    <li>Integrated Postsecondary Education Data System (IPEDS) – The Institute of Education Sciences (IES) is the statistics, research, and evaluation arm of the U.S. Department of Education.</li>
                    <li>College Scorecard – Data on college costs, graduation, and post-college earnings from the U.S. Department of Education.</li>
                    <li>National Science Foundation (NSF) – An independent federal agency created by Congress in 1950 “to promote the progress of science; to advance health, prosperity, and welfare; to secure the national defense…”.  The NSF is the funding source for approximately 24% of all federally supported basic research conducted by America’s colleges and universities.</li>
                    <li>Federal Student Aid – An office of the U.S. Department of Education that is the largest provider of student financial aid in the nation.</li>
                    <li><a href="http://payscale.com" targe="_blank">Payscale.com</a> is a crowdsourced data platform that provides early career and mid-career salaries of college graduates.</li>
                  </ol>
                </HeaderDescription>
              )}
              <ShowMoreButton onClick={() => toggleMore(!more)}>
                {!more ? `Read more about the rankings ▼` : `Read less ▲`}
              </ShowMoreButton>
            </FullWithContent>
            <PageContainer>
              <PageContent>
                <CollegeList type="ovranking" colleges={colleges} rankingComponent={(college) => <CollegeAddress address={college.address} />} push={push} />

                {colleges && colleges.length < count && (
                  <LoadMoreButtonContainer>
                    <LoadMoreButton onClick={() => setPage(page + 1)}>
                      <LoadMoreButtonLabel>Load More</LoadMoreButtonLabel>
                    </LoadMoreButton>
                  </LoadMoreButtonContainer>
                )}
              </PageContent>
            </PageContainer>
          </Fragment>
        )
      }
    </div>
  )
}

CollegeRankings.propTypes = {
  colleges: PropTypes.array,
  isFetching: PropTypes.bool,
  listCollegesIfEmpty: PropTypes.func,
  paginate: PropTypes.func,
  count: PropTypes.number,
  push: PropTypes.func
}

export default CollegeRankings;
