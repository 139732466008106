const sizeArray = [
    {
      "value": "large", 
      "label": "Large: more than 25,000 undergrads", 
      "filterPath": "size.large"
    },
    {
      "value": "medium", 
      "label": "Medium: 10,000 - 25,000 undergrads", 
      "filterPath": "size.medium"
    },
    {
      "value": "small", 
      "label": "Small: less than 10,000 undergrads", 
      "filterPath": "size.small"
    },
  ]

  export const temperatureArray = [
    {
      "label": "Average high temperature",
      "maxValue": 80,
      "minValue": 40,
      "filterPath": "weather.avr_high_temp",
      "subfixUnit": "°F"
    }
  ]

  export const acceptanceRateArray = [
    {
      "maxValue": 100,
      "minValue": 0,
      "filterPath": "acceptance_rate" ,
      "subfixUnit": "%"
    }
  ]

  export const fundingArray = [
    {
      "value": "private", 
      "label": "Private", 
      "filterPath": "funding.private"
    },
    {
      "value": "public", 
      "label": "Public", 
      "filterPath": "funding.public"
    }
  ]

  export const satRangeArray = [
    {
      "maxValue": 1600,
      "minValue": 0,
      "filterPath": "sat_scores"
    }
  ]

  export const actRangeArray = [
    {
      "maxValue": 36,
      "minValue": 0,
      "filterPath": "act_scores"
    }
  ]

  export const compositeRangeArray = [
    {
      "maxValue": 130,
      "minValue": 1,
      "filterPath": "ov_composite_rank"
    }
  ]

  export const outStateTuitionArray = [
    {
      "maxValue": 60000,
      "minValue": 0,
      "filterPath": "out_of_state_tuition",
      "prefixUnit": "$"
    }
  ]

  export const inStateTuitionArray = [
    {
      "maxValue": 60000,
      "minValue": 0,
      "filterPath": "in_state_tuition",
      "prefixUnit": "$"
    }
  ]

  export const earnings2Array = [
    {
      "maxValue": 100000,
      "minValue": 20000,
      "filterPath": "median_earnings_2",
      "prefixUnit": "$"
    }
  ]

  export const earnings6Array = [
    {
      "maxValue": 100000,
      "minValue": 20000,
      "filterPath": "median_earnings",
      "prefixUnit": "$"
    }
  ]

  export const conferenceArray = [
    {
      "value": "acc", 
      "label": "ACC", 
      "filterPath": "conference.acc"
    },{
      "value": "big12", 
      "label": "Big 12", 
      "filterPath": "conference.big12"
    },{
      "value": "bingten", 
      "label": "Big Ten", 
      "filterPath": "conference.bingten"
    },{
      "value": "pac12", 
      "label": "Pac 12", 
      "filterPath": "conference.pac12"
    },{
      "value": "sec", 
      "label": "SEC", 
      "filterPath": "conference.sec"
    },{
      "value": "american", 
      "label": "American", 
      "filterPath": "conference.american"
    },{
      "value": "conferenceusa", 
      "label": "Conference USA", 
      "filterPath": "conference.conferenceusa"
    },{
      "value": "mac", 
      "label": "MAC", 
      "filterPath": "conference.mac"
    },{
      "value": "mountainwest", 
      "label": "Mountain West", 
      "filterPath": "conference.mountainwest"
    },{
      "value": "sunbelt", 
      "label": "Sun Belt", 
      "filterPath": "conference.sunbelt"
    },{
      "value": "independent", 
      "label": "Independent", 
      "filterPath": "conference.independent"
    }
  ]

  export const nflArray = [
    {
      "maxValue": 50,
      "minValue": 0,
      "filterPath": "nfl"
    }
  ]

  export const stadiumArray = [
    {
      "maxValue": 110000,
      "minValue": 10000,
      "filterPath": "stadium_size"
    }
  ]

  export const apparelArray = [
    {
      "value": "adidas", 
      "label": "Adidas", 
      "filterPath": "apparel_brand.adidas"
    },{
      "value": "jordan", 
      "label": "Jordan", 
      "filterPath": "apparel_brand.jordan"
    },{
      "value": "nike", 
      "label": "Nike", 
      "filterPath": "apparel_brand.nike"
    },{
      "value": "underarmour", 
      "label": "Under Armour", 
      "filterPath": "apparel_brand.underarmour"
    },
  ]

  export const graduationRateArray = [
    {
      "maxValue": 100,
      "minValue": 0,
      "filterPath": "graduation_success_rate",
      "subfixUnit": "%"
    }
  ]

  export const blackGraduationRateArray = [
    {
      "maxValue": 100,
      "minValue": 0,
      "filterPath": "black_graduation_success_rate",
      "subfixUnit": "%"
    }
  ]

  export const whiteGraduationRateArray = [
    {
      "maxValue": 100,
      "minValue": 0,
      "filterPath": "white_graduation_success_rate",
      "subfixUnit": "%"
    }
  ]

  export const academicProgressRateArray = [
    {
      "maxValue": 999,
      "minValue": 850,
      "filterPath": "academic_progress_rate"
    }
  ]

  export default sizeArray;