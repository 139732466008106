import React from 'react';
import { Field } from 'redux-form';

// Imported Styles
import {
  StyledInputSmall,
  PageContent
} from './StyledItem';


const inputField = ({ input, label, type, meta: { touched, active, error } }) => (
  <div >
    <StyledInputSmall {...input} placeholder={label} type={type} />
    {touched && !active && error && <div>{error}</div>}
  </div>
);

let ReviewTitle = props => {
  return <PageContent>
    <div>
      <label htmlFor="title">Title: </label>
      <Field label="Great all-around place" name="title" component={inputField} type="text" />
    </div>
  </PageContent>
}

export default ReviewTitle