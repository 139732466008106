import React from 'react';
import { PropTypes } from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {withRouter} from 'react-router';
import {Helmet} from "react-helmet";

import ScrollToTop from './ScrollToTop.js';

import Home from '../home';
import Colleges from '../colleges';
import SearchPage from '../searchResultsPage';
import Navbar from '../navbar';
import FooterBar from '../footer';
import CollegeDetails from '../collegeDetails';
import CollegeRankings from '../collegeRankings';
import CollegeWarmest from '../collegeRankings/warmest';
import CollegeLargest from '../collegeRankings/largest';
import CollegeGraduationSuccess from '../collegeRankings/graduationSuccess';
import CollegeBlackGraduationSuccess from '../collegeRankings/blackGraduationSuccess';
import CollegeWhiteGraduationSuccess from '../collegeRankings/whiteGraduationSuccess';
import CollegePostgraduateEarnings from '../collegeRankings/highestPostgraduateEarnings';
import CollegeInStateTuition from '../collegeRankings/inStateTuition';
import CollegeOutStateTuition from '../collegeRankings/outStateTuition';
import CollegeLargestStadiums from '../collegeRankings/largestStadiums';
import TopSchools from '../topSchools';
import ContactPage from '../contact';
import ClaimPage from '../claim';

import Profile from '../profile';
import Users from '../users';
import CoachDetails from '../coachDetails';
import Coaches from '../coaches';
import Content from '../content';
import AttractionContent from '../attractionContent';
import Majors from '../majors'
import Attractions from '../attractions'
import AttractionDetails from '../attractionDetails'
import AttractionReview from '../attractionReview';
import Review from '../review';
import Login from '../login';
import TestLogin from '../testlogin';
import TestLogout from '../testlogout';
import Terms from '../terms';
import Privacy from '../privacy';
import Register from '../register';
import RegisterPicture from '../registerPicture';
import RoleSelector from '../rolesel';
import styled from 'styled-components';
import AthleteReg from '../athletereg';
import StudentReg from '../studentreg';
import FanReg from '../fanreg';
import RecruitReg from '../recruitreg';
import adminCoaches from '../adminCoaches';
import adminUsers from '../adminUsers';
import editCoach from '../editCoach';
import createAttraction from '../createAttractions'

// Blog
import PostList from '../blog/PostList';
import Post from '../blog/Post';

// import styled from 'styled-components';
import ReactGA from 'react-ga';
import { trackingID } from '../../config/app'
import { bp } from '../../styles';
import ForgotPassword from "../forgotPassword";
import adminAttractions from '../adminAttractions';

// Styled components
const Main = styled.main`
  padding-top: 104px;
  min-height: calc(100vh - 60px);
  @media ${bp.tabletLandscape} {
    min-height: calc(100vh - 120px);
  }
  @media ${bp.tabletLandUp} {
    min-height: calc(100vh - 60px);
  }
  @media ${bp.phoneOnly} {
    min-height: calc(100vh - 200px);
  }
`;

const PageContainter = styled.div`
  background: ${props => props.blog && 'white'};
`;

// Initialize GA
// it's possible to use now, they are set in gitlab-ci.yml:
// $GOOGLE_ANALYTICS_TRACKING_CODE_DEV
// $GOOGLE_ANALYTICS_TRACKING_CODE_STAG
// $GOOGLE_ANALYTICS_TRACKING_CODE_PROD
trackingID && ReactGA.initialize(trackingID);

class GAListener extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    if(trackingID) {
      this.sendPageView(this.context.router.history.location);
      this.context.router.history.listen(this.sendPageView);
    }
  }

  sendPageView(location) {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }

  render() {
    return this.props.children;
  }
}



const App = (location) => {

  const locationSlugforNavBar = location.location.pathname !== "/signup" && location.location.pathname !== "/login";
  const locationSlugforFooter = locationSlugforNavBar && location.location.pathname !== "/colleges";

  return(
    <PageContainter blog={location.location.pathname.indexOf("/blog") === 0}>
      <Helmet>
        <title>OfficialVisit</title>
        <meta name="description" content="OfficialVisit" />
      </Helmet>
      <GAListener>
        {locationSlugforNavBar && <Navbar/>}
        <Main>
          <ScrollToTop>
            <Switch>
              <Route exact path="/user/:user_id" component={Users} />
              <Route exact path="/coach/:coach_slug" component={CoachDetails} />
              <Route exact path="/coaches" component={Coaches} />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/profile-picture" component={RegisterPicture} />
              <Route exact path="/college/:url_slug" component={CollegeDetails} />
              <Route exact path="/college/:url_slug#reviews" component={CollegeDetails} />
              <Route exact path="/college/:url_slug/content" component={Content} />
              <Route exact path="/college/:url_slug/review" component={Review} />
              <Route exact path="/college/:url_slug/majors" component={Majors} />
              <Route exact path="/colleges" component={Colleges} />
              <Route exact path="/college-rankings" component={CollegeRankings} />
              <Route exact path="/warmest-colleges" component={CollegeWarmest} />
              <Route exact path="/largest-colleges" component={CollegeLargest} />
              <Route exact path="/graduation-success-rates" component={CollegeGraduationSuccess} />
              <Route exact path="/black-graduation-success-rates" component={CollegeBlackGraduationSuccess} />
              <Route exact path="/white-graduation-success-rates" component={CollegeWhiteGraduationSuccess} />
              <Route exact path="/highest-postgraduate-earnings" component={CollegePostgraduateEarnings} />
              <Route exact path="/most-affordable-in-state-colleges" component={CollegeInStateTuition} />
              <Route exact path="/most-affordable-out-of-state-colleges" component={CollegeOutStateTuition} />
              <Route exact path="/largest-cfb-stadiums" component={CollegeLargestStadiums} />
              <Route exact path="/search" component={SearchPage} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/restore_password" component={ForgotPassword} />
              <Route exact path="/register" component={CollegeDetails} />
              <Route exact path="/signup" component={Register} />
              <Route exact path="/signup-picture" component={RegisterPicture} />
              <Route exact path="/signup-role" component={RoleSelector} />
              <Route exact path="/signup-athlete" component={AthleteReg} />
              <Route exact path="/signup-student" component={StudentReg} />
              <Route exact path="/signup-fan" component={FanReg} />
              <Route exact path="/signup-recruit" component={RecruitReg} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/test-login" component={TestLogin} />
              <Route exact path="/test-logout" component={TestLogout} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/admin/coaches" component={adminCoaches} />
              <Route exact path="/admin/users" component={adminUsers} />
              <Route exact path="/admin/coaches/new" component={editCoach} />
              <Route exact path="/admin/coaches/:coach_id" component={editCoach} />
              <Route exact path="/admin/attractions" component={adminAttractions} />
              <Route exact path="/admin/attractions/new" component={createAttraction} />
              <Route exact path="/admin/attractions/:attraction_id" component={createAttraction} />
              <Route exact path="/blog/:post_slug" component={Post} />
              <Route exact path="/blog" component={PostList} />
              <Route exact path="/contact" component={ContactPage} />
              <Route exact path="/claim" component={ClaimPage} />
              <Route exact path="/top-schools" component={TopSchools} />
              <Route exact path="/college/:url_slug/attractions" component={Attractions} />
              <Route exact path="/college/:url_slug/attractions/:id" component={AttractionDetails} />
              <Route exact path="/college/:url_slug/attractions/:id/review" component={AttractionReview} />
              <Route exact path="/college/:url_slug/attractions/:id/content" component={AttractionContent} />
              <Route exact path="/" component={Home} />
              <Redirect from='*' to='/' />
            </Switch>
          </ScrollToTop>
        </Main>
        {locationSlugforFooter && <FooterBar/>}
        <ToastContainer />
      </GAListener>
    </PageContainter>
  )
}

export default withRouter(App)
