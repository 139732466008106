export const LIST_COACHES_REQUESTED = 'coach/LIST_COACHES_REQUESTED';
export const LIST_COACHES_SUCCESS = 'coach/LIST_COACHES_SUCCESS';
export const LIST_COACHES_FAILURE = 'coach/LIST_COACHES_FAILURE';

export const GET_COACH_PROFILE_REQUESTED = 'coach/GET_COACH_PROFILE_REQUESTED';
export const GET_COACH_PROFILE_SUCCESS = 'coach/GET_COACH_PROFILE_SUCCESS';
export const GET_COACH_PROFILE_FAILURE = 'coach/GET_COACH_PROFILE_FAILURE';

export const GET_COACH_REVIEW_REQUESTED = 'coach/GET_COACH_REVIEW_REQUESTED';
export const GET_COACH_REVIEW_SUCCESS = 'coach/GET_COACH_REVIEW_SUCCESS';
export const GET_COACH_REVIEW_FAILURE = 'coach/GET_COACH_REVIEW_FAILURE';

export const GET_COACH_AVERAGE_VERIFIED_REVIEW_REQUESTED = 'coach/GET_COACH_AVERAGE_VERIFIED_REVIEW_REQUESTED';
export const GET_COACH_AVERAGE_VERIFIED_REVIEW_SUCCESS   = 'coach/GET_COACH_AVERAGE_VERIFIED_REVIEW_SUCCESS';
export const GET_COACH_AVERAGE_VERIFIED_REVIEW_FAILURE   = 'coach/GET_COACH_AVERAGE_VERIFIED_REVIEW_FAILURE';

export const GET_COACH_AVERAGE_VERIFIED_REVIEWS_REQUESTED = 'coach/GET_COACH_AVERAGE_VERIFIED_REVIEW_REQUESTED';
export const GET_COACH_AVERAGE_VERIFIED_REVIEWS_SUCCESS   = 'coach/GET_COACH_AVERAGE_VERIFIED_REVIEW_SUCCESS';
export const GET_COACH_AVERAGE_VERIFIED_REVIEWS_FAILURE   = 'coach/GET_COACH_AVERAGE_VERIFIED_REVIEW_FAILURE';

export const GET_COACH_HISTORY = 'coach/GET_COACH_HISTORY';

export const UPDATE_COACH_REQUESTED = 'coach/UPDATE_COACH_REQUESTED';
export const UPDATE_COACH_SUCCESS = 'coach/UPDATE_COACH_SUCCESS';
export const UPDATE_COACH_FAILURE = 'coach/UPDATE_COACH_FAILURE';

export const CREATE_COACH_REQUESTED = 'coach/CREATE_COACH_REQUESTED';
export const CREATE_COACH_SUCCESS = 'coach/CREATE_COACH_SUCCESS';
export const CREATE_COACH_FAILURE = 'coach/CREATE_COACH_FAILURE';

export const UPDATE_COACH_DEFAULT_PHOTO = 'coach/UPDATE_COACH_DEFAULT_PHOTO';

export const GET_TARGET_COACHES_REQUESTED = 'coach/GET_TARGET_COACHES_REQUESTED';
export const GET_TARGET_COACHES_SUCCESS   = 'coach/GET_TARGET_COACHES_SUCCESS';
export const GET_TARGET_COACHES_FAILURE   = 'coach/GET_TARGET_COACHES_FAILURE';

export const SEARCH_COACHES_REQUESTED = 'coach/SEARCH_COACHES_REQUESTED';
export const SEARCH_COACHES_SUCCESS = 'coach/SEARCH_COACHES_SUCCESS';
export const SEARCH_COACHES_FAILURE = 'coach/SEARCH_COACHES_FAILURE';

export const GET_COACH_TITLES_REQUESTED = 'coach/GET_COACH_TITLES_REQUESTED';
export const GET_COACH_TITLES_SUCCESS = 'coach/GET_COACH_TITLES_SUCCESS';
export const GET_COACH_TITLES_FAILURE = 'coach/GET_COACH_TITLES_FAILURE';

export const searchCoachesRequest = ({ year, title, college, query }) => ({
  type: SEARCH_COACHES_REQUESTED,
  payload: { year, title, college, query },
})

export const searchCoachesSuccess = (data) => ({
  type: SEARCH_COACHES_SUCCESS,
  payload: data
})

export const searchCoachesFailure = (error) => ({
  type: SEARCH_COACHES_FAILURE,
  error
})

export const getCoachTitlesRequest = () => ({
  type: GET_COACH_TITLES_REQUESTED,
})

export const getCoachTitlesSuccess = (data) => ({
  type: GET_COACH_TITLES_SUCCESS,
  payload: data
})

export const getCoachTitlesFailure = (error) => ({
  type: GET_COACH_TITLES_FAILURE,
  error
})

export const listCoachesRequest = () => ({
  type: LIST_COACHES_REQUESTED
})

export const listCoachesSuccess = (data) => ({
  type: LIST_COACHES_SUCCESS,
  payload: data
})

export const listCoachesFailure = (data) => ({
  type: LIST_COACHES_FAILURE
})

export const requestGetCoachProfile = () => ({
  type: GET_COACH_PROFILE_REQUESTED
})

export const getCoachProfileSuccess = (data) => ({
  type: GET_COACH_PROFILE_SUCCESS,
  payload: data
})

export const getCoachProfileFailure = (message) => ({
  type: GET_COACH_PROFILE_FAILURE,
  payload: message
})

export const getCoachReviewsRequest = () => ({
  type: GET_COACH_REVIEW_REQUESTED
})

export const getCoachReviewsSuccess = (data) => ({
  type: GET_COACH_REVIEW_SUCCESS,
  payload: data
})

export const getCoachReviewsFailure = () => ({
  type: GET_COACH_REVIEW_FAILURE
})

export const getCoachAverageVerifiedReviewsRequest = () => ({
  type: GET_COACH_AVERAGE_VERIFIED_REVIEWS_REQUESTED
})

export const getCoachAverageVerifiedReviewsSuccess = (data) => ({
  type: GET_COACH_AVERAGE_VERIFIED_REVIEWS_SUCCESS,
  payload: data
})

export const getCoachAverageVerifiedReviewsFailure = () => ({
  type: GET_COACH_AVERAGE_VERIFIED_REVIEWS_FAILURE
})

export const getCoachAverageVerifiedReviewRequest = () => ({
  type: GET_COACH_AVERAGE_VERIFIED_REVIEW_REQUESTED
})

export const getCoachAverageVerifiedReviewSuccess = (data) => ({
  type: GET_COACH_AVERAGE_VERIFIED_REVIEW_SUCCESS,
  payload: data
})

export const getCoachAverageVerifiedReviewFailure = () => ({
  type: GET_COACH_AVERAGE_VERIFIED_REVIEW_FAILURE
})

export const getCoachHistory = (data) => ({
  type: GET_COACH_HISTORY,
  payload: data
})

export const requestUpdateCoach = () => ({
  type: UPDATE_COACH_REQUESTED
})

export const updateCoachSuccess = (data) => ({
  type: UPDATE_COACH_SUCCESS,
  payload: data
})

export const updateCoachFailure = (error) => ({
  type: UPDATE_COACH_FAILURE,
  payload: error
})

export const requestCreateCoach = () => ({
  type: CREATE_COACH_REQUESTED
})

export const createCoachSuccess = (data) => ({
  type: CREATE_COACH_SUCCESS,
  payload: data
})

export const createCoachFailure = (error) => ({
  type: CREATE_COACH_FAILURE,
  payload: error
})

export const updateCoachDefaultPhoto = (data) => ({
  type: UPDATE_COACH_DEFAULT_PHOTO,
  payload: data
})

export const getTargetCoachesRequest = (ID) => ({
  type: GET_TARGET_COACHES_REQUESTED,
  payload: { ID }
});

export const getTargetCoachesSuccess = (data) => ({
  type: GET_TARGET_COACHES_SUCCESS,
  payload: data,
});

export const getTargetCoachesFailure = (message) => ({
  type: GET_TARGET_COACHES_FAILURE,
  payload: { message },
});
