import styled from 'styled-components';
import { color, typo, bp } from '../../styles'
import { Heading } from 'rebass';
import { Plus } from 'styled-icons/fa-solid/Plus';
import Scroll from 'react-scroll';
var Link = Scroll.Link;

// Styled components
export const PageContent = styled.div`
  grid-column: span 12;
  background-color: ${color.ovWhite};
  border: 1px solid ${color.ovGrey};
  padding: 25px;
  margin: 10px 0;
`;

export const PageContentButton = styled.div`
  grid-column: span 12;
  background-color: ${color.ovWhite};
  padding: 25px;
  margin: 10px 0;
`;

export const TitleContainer = styled.div`
  grid-column: span 12;
`;
export const AboutHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: .8;
  letter-spacing: normal;
  text-align: left;
  padding: 0 10px;
  @media ${bp.phoneOnly} {
    font-size: 1.8rem;
  }
`;
export const AboutHr = styled.div`
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid ${color.ovGrey};
  margin: 5px 0 0;
  padding: 0;
`;
export const SearchResultContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 1rem;
  @media ${bp.tabletLandUp} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${bp.phoneOnly} {
    grid-template-columns: 1fr;
  }
`;
export const SearchResultItem = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  cursor: pointer;
  background-color: ${color.ovWhite};
  transition: all .1s;
  &:hover{
    background-color: ${color.ovGreyLight};
    transition: all .1s;
  }
  @media ${bp.tabletLandUp} {
    grid-template-columns: 1fr 1fr;
  }
`;
export const CoachHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: .8;
  letter-spacing: normal;
  text-align: center;
  padding-bottom: 1rem;
  @media ${bp.tabletLandUp} {
    text-align: left;
  }
`;
export const TitleHeading = styled(Heading)`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontSerif};
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: .8;
  letter-spacing: normal;
  text-align: center;
  @media ${bp.tabletLandUp} {
    text-align: left;
  }
`;
export const CoachProfilePic = styled.div`
  padding: 2rem;
`;
export const CoaachProfileMeta = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
`;
export const ImgStyle = {
  objectFit: 'cover',
  height: '100%',
  width: '100%',
  imageOrientation: 'from-image',
}
export const AddCoachButton = styled.button`
  height: 76px;
  border-radius: 10px;
  border: solid 1px ${color.ovYellow};
  background-color: ${color.ovRed};
  transition: all .1s;
  text-align: center;
  padding: 4px 50px;
  font-size: 30px;
  color: ${color.ovWhite};
  margin-bottom: 10px;
  min-width: 210px;
  position: sticky;
  position: -webkit-sticky;
  bottom: 5vh;
  float: right;
  margin-right: -50px;
  /*transform: translate(-50%, -50%);*/
  &:hover{
    background-color: ${color.ovBlue};
    color: ${color.ovWhite};
    transition: all .1s;
  }

  @media ${bp.phoneOnly} {
    height: 48px;
  }

  &:focus {
    outline: none !important;
  }
`;
export const StyledPlus = styled(Plus)`
  width: 13px;
  color: ${color.ovWhite};
  margin-right: 8px;
  position: absolute;
  right: 28px;
  top: 28px;
  ${AddCoachButton}:hover &{
    color: ${color.ovWhite};
  }
  @media ${bp.phoneOnly} {
    top: 17px;
  }
`;
export const AddCoachButtonLabel = styled.p`
  font-family: ${typo.ovFontFeature};
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: center;
  padding: 0 20px 0 0;
  @media ${bp.phoneOnly} {
    font-size: 22px;
  }
`;
export const LoadMoreButtonContainer = styled.div`
  grid-column: 1/-1;
  text-align: center;
  margin: 2rem 0;
`;
export const LoadMoreButton = styled.button`
  height: 59px;
  border-radius: 10px;
  border: solid 1px ${color.ovBlue};
  background-color: ${color.ovWhite};
  transition: all .1s;
  text-align: center;
  padding: 8px 30px;
  position: relative;
  margin: 0 0 17px 0;
  &:hover{
    background-color: ${color.ovBlue};
    color: ${color.ovWhite};
    transition: all .1s;
  }
`;

export const LoadMoreButtonLabel = styled.p`
  font-family: ${typo.ovFontFeature};
  font-size: 25px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: center;
  @media ${bp.phoneOnly} {
    font-size: 22px;
  }
`;

export const ModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 50vh;
  box-shadow: 0 3px 6px 0 rgba(0,0,0, 0.16);
  border: solid 1px #777777;
  background-color: ${color.ovWhite}; 
  padding: 7px 16px;
  cursor: pointer;
`;

export const AttractionCardTitile = styled(Heading)`
	font-family: ${typo.ovFontFeature};
  font-size: 40px;
  font-weight: 300;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: ${color.ovGreyDarker};
`;

export const AttractionCardText = styled.div`
  height: 8vh;
	font-family: ${typo.ovFontFeature};
  font-size: 25px;
  font-weight: 300;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovGreyDarker};
`;

export const AttractionCardTitleBox = styled.div`
  border-bottom: 1px solid ${color.ovGreyLight};
  padding: 15px 23px;
`;

export const AttractionCardTextBox = styled.div`
  border-bottom: 1px solid ${color.ovGreyLight};
`;

export const OVButton = styled(Link)`
  border: 3px solid ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 16px;
  background: ${color.ovBlue};
  border-radius: 0px;
  color: ${color.ovWhite};
  cursor: pointer;
  padding: 2px 40px;
  margin: 0px 10px;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.22;
  letter-spacing: 1px;
  &:hover, &:focus {
    text-decoration: none;
    background: ${color.ovBlueLight};
    border-color: ${color.ovBlueLight};
  }
`;

export const OVButtonRight = styled(OVButton)`
  float: right;
`;

export const OVButtonleft = styled(OVButton)`
  float: right;
  background: ${color.ovRedLight}
  border: 3px solid ${color.ovRedLight};
  &:hover, &:focus {
    text-decoration: none;
    background: ${color.ovRed};
    border-color: ${color.ovRed};
  }
`;
// end of Styled Components