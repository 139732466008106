import { createSelector } from 'reselect';

export const coachSelector = ({ coach }) => coach;

export const currentCoachSelector = createSelector(
  coachSelector,
  ({ coachProfile: { ID, display_name: coachName, url_slug: coachUrlSlug, coached_seasons: coachedSeasons=[] } }) => ({ ID, coachName, coachUrlSlug, coachedSeasons })
);

export const currentCoachSeasonsSelector = createSelector(
  currentCoachSelector,
  ({ coachedSeasons }) => coachedSeasons
    ? coachedSeasons.map(({ id, year, team_refer: teamId, team_name: teamName, formal_name: formalName, title_0: title0, title_1: title1}) => ({
        id,
        year,
        teamId,
        teamName: formalName || teamName,
        title0,
        title1
      })).sort((a, b) => (a.year < b.year) ? 1 : -1)
    : []
);

export const averageVerifiedReviewByCoachId = (id) => createSelector(
  coachSelector,
  ({ averageVerifiedReviews }) => {
          if (!averageVerifiedReviews || !averageVerifiedReviews.length) return null;
          const res = averageVerifiedReviews.find(item => item.coach_refer === id);
          return res;
  }
);

export const jobOrderedCoachesSelector = (list) => {
  if (!list) return list;
  const groupNames = ["head coach", "defensive coordinator", "offensive coordinator", "co-defensive coordinator", "co-offensive coordinator", "special teams coordinator", "pass game coordinator", "run game coordinator"];

  const groups = [];
  list.forEach((v) => {
    const found = groupNames.some((gv, gi) => { // some breaks the loop after return true
      if(gv === v.title_0.toLowerCase().trim()) {
        if (!groups[gi]) groups[gi] = [];
        groups[gi].push(v);
        return true;
      }
      return false;
    });

    // unsorted
    if (!found) {
      if (!groups[groupNames.length]) groups[groupNames.length] = [];
      groups[groupNames.length].push(v)
    }
  });

  let result = [];
  groups.forEach((v) => {
    result = result.concat(v);
  });

  return result;
};

export const totalReviewCountSelector = coachProfile => {
  if (!coachProfile) return null;

  if (coachProfile.average_review) {
    const { average_review: { reviews_count } } = coachProfile;
    
    if (reviews_count > 0){
      return reviews_count;
    }
  };
  
  if (coachProfile.coachReviews) {
    return coachProfile.coachReviews.length;
  };
};

export const averageReviewRatingSelector = coachProfile => {
  if (!coachProfile) return null;

  if (coachProfile.average_review) {
    const { average_review: { average_rating } } = coachProfile;

    return average_rating;
  };
};

export const averageVerifiedReviewRatingSelector = coachProfile => {
  if (!coachProfile) return null;

  if (coachProfile.average_verified_review) {
    const { average_verified_review: { average_rating } } = coachProfile;

    return average_rating;
  };
};

export const coachTargetSelector = (contribution, targetCoaches) => {
  if (!targetCoaches) return;
  const { contributable_type: type, contributable_id: id} = contribution;
  if (type === 'coaches' && targetCoaches[id]) {
    return targetCoaches[contribution.contributable_id]
  };
};

export const coachNameSelector = (contribution, targetCoaches) => {
  const coach = coachTargetSelector(contribution, targetCoaches);
  if (coach) {
    return coach.display_name;
  };
};

export const coachSlugSelector = (contribution, targetCoaches) => {
  const coach = coachTargetSelector(contribution, targetCoaches);
  if (coach) {
    return coach.url_slug;
  };
};