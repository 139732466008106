import CostIcon from '../../../assets/icons/cost-b.svg';
import AdmissionsIcon from '../../../assets/icons/admissions.svg';
import CoachingIcon from '../../../assets/icons/coaching.svg';
import FootballIcon from '../../../assets/icons/football.svg';
import OutcomesIcon from '../../../assets/icons/outcomes.svg';
import OverviewIcon from '../../../assets/icons/overview.svg';
import ReviewsIcon from '../../../assets/icons/reviews.svg';
import HistoryIcon from '../../../assets/icons/history.svg';
import MajorsIcon from '../../../assets/icons/majors.svg';
import CameraIcon from '../../../assets/icons/camera.svg';

export const Icons = {
  cost: CostIcon,
  admissions: AdmissionsIcon,
  coaching_staff: CoachingIcon,
  football_program: FootballIcon,
  outcomes: OutcomesIcon,
  overview: OverviewIcon,
  reviews: ReviewsIcon,
  review: ReviewsIcon,
  majors: MajorsIcon,
  coaching_history: HistoryIcon,
  photos: CameraIcon
};

export default Icons;