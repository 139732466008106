import findIndex from 'lodash/findIndex';

import { LOCATION_CHANGE } from 'react-router-redux';
import { GET_CONTENT_SUCCESS } from '../college';
import { LOGOUT_SUBMITTED } from '../user';

import * as types from './actions';

const initialState = {
  contents: [],
  selectedFilters: [],
  selectedContentIndex: 0,
  showFilters: false,
  lightboxOpen: false,
  isManaging: false,
  isFetching: false,
  errorMessage: '',
  user: []
}

export default (state = initialState, action) => {
  switch (action.type) {

    case GET_CONTENT_SUCCESS:
      return {
        ...state,
        contents: action.payload.map((content) => { return { ...content, src: content.src_lg, caption: content.contrib_item.description } })
      }
    case types.CONTENTS_SET:
      return {
        ...state,
        contents: action.payload.map((content) => { return { ...content, src: content.src_lg } })
      }

    case types.FILTER_ADD:
      return {
        ...state,
        selectedFilters: [...state.selectedFilters, action.payload]
      }

    case types.FILTER_REMOVE:
      return {
        ...state,
        selectedFilters: [...state.selectedFilters.filter((tag) => tag.ID !== action.payload.ID)]
      }

    case types.FILTERS_SHOW_TOGGLE:
      return {
        ...state,
        showFilters: !state.showFilters
      }

    case LOCATION_CHANGE:
      return action.payload.location.pathname.includes('content') ? {
        ...state,
        showFilters: false,
      } : {
          ...state,
          selectedFilters: [],
          selectedContentIndex: 0,
          showFilters: false,
          lightboxOpen: false,
          isManaging: false
        };

    case types.LIGHTBOX_TOGGLE_OPEN:
      return {
        ...state,
        lightboxOpen: !state.lightboxOpen
      }

    case types.CONTENT_INDEX_SELECTED:
      return {
        ...state,
        selectedContentIndex: action.payload
      }

    case types.MANAGE_TOGGLE:
      return {
        ...state,
        isManaging: !state.isManaging
      }
    case types.CONTENT_DELETED:
      return {
        ...state,
        contents: [...state.contents.filter((content) => content.ID !== action.payload.ID)]
      }

    case types.GET_USER_BY_CONTENT_ID_REQUESTED:
      return {
        ...state,
        isFetching: true,
        errorMessage: ''
      }

    case types.GET_USER_BY_CONTENT_ID_SUCCESS: {
      const { contentId: ID, user } = action.payload;
      const { contents } = state;
      const index = findIndex(contents, { ID });
      const withUser = { ...contents[index], user };

      return {
        ...state,
        user: action.payload,
        isFetching: false,
        contents: [...contents.slice(0, index), withUser, ...contents.slice(index + 1)],
      }
    }

    case types.GET_USER_BY_CONTENT_ID_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      }

    case LOGOUT_SUBMITTED:
      return initialState;

    default:
      return state
  }
}
