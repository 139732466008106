import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { withLifecycleActions } from 'react-redux-lifecycle';
import { PageContainer, FloatRightCloseButton } from '../../components';
import ReviewItem from './ReviewItem';
import ReviewTitle from './ReviewTitle';
import CoachDropdown from './CoachDropdown';

import { nextStage, previousStage, setAnswer, setComment, setTitle, setAnonymous, reviewAnotherCoach, setCoach, setupReviewQuestions } from '../../modules/review/actions';
import {  getReviewCoaches, getPageTitle } from '../../modules/review';
import { getUserIfEmpty, requestLogin } from '../../modules/user';
import { userRoleSelector, isCurrentCollegeStudentSelector } from '../../modules/user/selectors';

import { getCollegeByURLIfEmpty } from '../../modules/college';
import { submitReviewContribution } from '../../modules/contribute';

// Imported Styles
import {
  ReviewContextHeading,
  CloseBar,
  List,
  OVButtonRight,
  OVButton,
  PageContent,
  CenterContent
} from './Styled';

import Scroll from 'react-scroll';
import Spinner from "react-spinkit";
const Element = Scroll.Element;

const Review = ({
  match,
  push,
  collegeId,
  stage,
  maxStage,
  questions,
  answers,
  coachID,
  nextStage,
  previousStage,
  isAuthenticated,
  isSubmittingReview,
  requestLogin,
  setAnswer,
  setComment,
  setTitle,
  setAnonymous,
  reviewAnotherCoach,
  submitReviewContribution,
  getReviewCoaches,
  setCoach,
  coaches,

  isCurrentCollegeStudent,
  userRole,
  setupReviewQuestions,
}) =>{

  useEffect(() => {
    if (!isAuthenticated) {
      return requestLogin(match.url);
    }

    setupReviewQuestions(isCurrentCollegeStudent, userRole);
  }, [collegeId, isCurrentCollegeStudent]);

  const reviewListItems = questions.filter(q => q.questionStage === stage).map((q,i) => {
    return stage >= 3 ? (
      <ReviewItem
        key={i + '_'+ q.key}
        disabled={coachID < 0}
        index={q.index}
        context={q.context}
        ratingKey={q.rating_key}
        text={q.text}
        values={[1,2,3,4,5]}
        onSelect={(answer) => setAnswer({...answer, coachID})}
        selectedValue={answers[q.index].rating}
        onInputChange={setComment}
        inputValue={answers[q.index].comment || ""}
      />
    ) : (
      <ReviewItem
        key={i + '_'+ q.key}
        index={q.index}
        context={q.context}
        ratingKey={q.rating_key}
        text={q.text}
        values={[1,2,3,4,5]}
        onSelect={setAnswer}
        selectedValue={answers[q.index].rating}
        onInputChange={setComment}
        inputValue={answers[q.index].comment || ""}
      />
    );
  });

  return (
    <div>
      <Element name="top" />
      <CloseBar>
        <FloatRightCloseButton onClick={() => push('/college/' + match.params.url_slug)}/>
      </CloseBar>
      { !isSubmittingReview && (<PageContainer>
        <PageContent>
          <ReviewContextHeading>{getPageTitle(stage)}</ReviewContextHeading>
        </PageContent>

        {/* Review back button */}
        { stage > 0 && (
          <PageContent>
            <OVButton to='' onClick={previousStage}>Back</OVButton>
          </PageContent>
        )}

        {/* The review title */}
        { stage === 0 && (
          <ReviewTitle onSubmit={data => {setTitle(data); setAnonymous(data); nextStage();}} onCancel={() => push('/college/' + match.params.url_slug)} />
        )}

        {/* The coach dropdown */}
        { stage >= 3 && (
          <CoachDropdown coaches={coaches} selectedCoachID={coachID} onSelect={data => {setCoach(data);}} />
        )}

        {/* The review questions */}
        { stage > 0 && (
          <List>
            {reviewListItems}
          </List>
        )}

        {/* Review Another Coach Button */}
        { stage >= 3 && maxStage === stage && (
          <PageContent>
            <OVButtonRight smooth={false} to="top" onClick={reviewAnotherCoach}>Review Another Coach</OVButtonRight>
          </PageContent>
        )}

        {/* Review next button */}
        { stage > 0 && maxStage > stage && (
          <PageContent>
            {/* We are calling to get the coaches list JUST IN TIME for the coaches drop down to be display */}
            <OVButtonRight smooth={false} to="top" onClick={()=>{nextStage(); if(stage === 1) getReviewCoaches();}}>Next</OVButtonRight>
          </PageContent>
        )}

        {/* Submit button */}
        { stage > 0 && maxStage === stage && (
          <PageContent>
            <OVButtonRight to='' onClick={submitReviewContribution}>Submit</OVButtonRight>
          </PageContent>
        )}
      </PageContainer>)}
      { isSubmittingReview  && (<PageContainer>
        <PageContent>
          <ReviewContextHeading>Review is submitting...</ReviewContextHeading>
          <CenterContent>
            <Spinner fadeIn="none" name="cube-grid" />
          </CenterContent>
        </PageContent>
      </PageContainer>)}
    </div>
  )
}

Review.propTypes = {
  stage: PropTypes.number.isRequired,
  questions: PropTypes.array.isRequired,
  answers: PropTypes.array.isRequired,
  coachID: PropTypes.number.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isSubmittingReview: PropTypes.bool.isRequired,
  nextStage: PropTypes.func.isRequired,
  previousStage: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  requestLogin: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  setAnonymous: PropTypes.func.isRequired,
  setAnswer: PropTypes.func.isRequired,
  reviewAnotherCoach: PropTypes.func.isRequired,
  getCollegeByURLIfEmpty: PropTypes.func.isRequired,
  getUserIfEmpty: PropTypes.func.isRequired,
  getReviewCoaches: PropTypes.func.isRequired,
  submitReviewContribution: PropTypes.func.isRequired,
  coaches: PropTypes.array.isRequired,
  setCoach:PropTypes.func.isRequired,
  userRole: PropTypes.string,
  isCurrentCollegeStudent: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  collegeId: state.college.college.ID,
  stage: state.review.stage,
  maxStage: state.review.maxStage,
  questions: state.review.questions,
  answers: state.review.answers,
  coachID: state.review.coachID,
  isSubmittingReview: state.review.isSubmittingReview,
  isAuthenticated: state.user.isAuthenticated,
  coaches: state.review.coaches,
  userRole: userRoleSelector(state),
  isCurrentCollegeStudent: isCurrentCollegeStudentSelector(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  nextStage,
  previousStage,
  setAnswer,
  setComment,
  setTitle,
  setAnonymous,
  reviewAnotherCoach,
  push,
  getCollegeByURLIfEmpty,
  getUserIfEmpty,
  submitReviewContribution,
  getReviewCoaches,
  setCoach,
  setupReviewQuestions,
  requestLogin
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withLifecycleActions({ componentWillMount: [getUserIfEmpty, getCollegeByURLIfEmpty]})(Review))
