import './fonts/fonts.css';
/**
 * Fonts
 */

const ovFontFeature = 'Molde';

const ovFontSerif = 'marbachlig';

const ovFontAlt = "'Titillium Web', sans-serif";


/**
 * Font Sizes
 */
const navLinkSize = '18px';
const pSize = '18px';


export default { 
  navLinkSize,
  pSize,
  ovFontFeature,
  ovFontAlt,
  ovFontSerif
};
