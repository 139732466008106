import React from 'react';
import styled from 'styled-components';
import { X } from 'styled-icons/feather/X';
import { color } from '../styles';


const CloseButton = styled(X)`
  display: inline;
  color: ${color.ovBlue};
  width: 25px;
  cursor: pointer;
  z-index: 1;
  opacity: 1;
  transition: all .1s;
  &:hover{
    opacity: .5;
    transition: all .1s;
  }
`;

export default ({ onClick, className }) => <CloseButton onClick={onClick} className={className} />;