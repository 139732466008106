import React, { useState } from 'react';

import { PhotoImg, PhotoBox, PhotoMoreImgContainer, PhotoMoreImgCount } from './Styled'
import PhotoGallery from "../../components/PhotoGallery";

const UserContributionPhotoBox = ({
  contents = [],
  user
}) => {
  const moreCount = contents.length - 2;
  const hasMore = moreCount > 1;

  const photos = contents.slice(0, 3);

  const [showGallery, setShowGallery] = useState(false);
  const [contentIndex, setContentIndex] = useState(-1);

  return (
    <PhotoBox>
      <PhotoGallery
        contents={ contents }
        user={user}
        show={ showGallery }
        setShow={ setShowGallery }
        contentIndex={ contentIndex }
        setContentIndex={ setContentIndex }
      />
      { photos.map((photo, index) => {
        if (!hasMore || index < 2) {
          return (
            <span key={ index }>
              <PhotoImg imgUrl={ photo.src_md } onClick={ () => {
                setContentIndex(index);
                setShowGallery(true);
              } }/>
            </span>
          )
        } else {
          return (
            <span key={ index }>
              <PhotoMoreImgContainer onClick={ () => {
                setShowGallery(true);
              } }>
                <PhotoImg imgUrl={ photo.src_md }/>
                <PhotoMoreImgCount>
                  <span>+{ moreCount }<br/>more</span>
                </PhotoMoreImgCount>
              </PhotoMoreImgContainer>
            </span>
          )
        }
      }) }
    </PhotoBox>
  );
};

export default UserContributionPhotoBox;