import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import {
  Result,
  SearchContainer,
  ScrollbarContainer,
  ResultHeading,
  ResultTitle,
  ResultsContainer,
  StyledInput
} from './Styled';

const SearchFilterForm = ({ shouldRenderItems, onQuery, placeholder }) => {
  const [query, setQuery] = useState('');
  useEffect(() => onQuery(query));

  return (
    <Row>
      <Col xs={12}>
        <StyledInput 
          className={shouldRenderItems && query && 'filled'}
          type="text"
          name="text"
          placeholder={placeholder}
          autoComplete="off"
          value={query}
          onChange={({ currentTarget: current }) => setQuery(current.value)}
        />
      </Col>
    </Row>
  )
};

const SearchBox = ({ query, results, onQuery, onSelect, renderItem, placeholder }) => (
  <SearchContainer>
    <SearchFilterForm
      onQuery={onQuery}
      placeholder={placeholder}
      shouldRenderItems={!!renderItem}
    />

    { (renderItem && query) && (
      <ResultsContainer> 
        <ScrollbarContainer>
          { !results.length && <ResultTitle as='h3'>No matching results</ResultTitle> }
          { results.map((result) => (
              <Result 
                key={result.ID} 
                onClick={onSelect}
                width={1} 
              >
                <span><ResultHeading as='h3'>{renderItem()}</ResultHeading></span>
              </Result>
          ))}
        </ScrollbarContainer>
      </ResultsContainer>
    )}
  </SearchContainer>
);

SearchBox.propTypes = {
  placeholder: PropTypes.string,
  query: PropTypes.string,
  onQuery: PropTypes.func.isRequired,
  results: PropTypes.array,

  onSelect: PropTypes.func,
  renderItem: PropTypes.func,
};

export default SearchBox;