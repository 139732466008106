import React, { useState } from 'react';
import find from 'lodash/find';

// Styles
import { 
  FiltersList,
  FiltersTop,
  FilterItem,
} from './Styled';

const selected = (filters, {ID}) => !!find(filters, {ID});

const Filter = ({ onClick, title, selected, isMore, support_touch }) => {
  const [ hover, setHover ] = useState(false);

  const classes = [];
  if (selected) classes.push('selected');
  if (hover) classes.push('hover');

  return (
    <FilterItem onClick={onClick} onMouseEnter={() => !support_touch && setHover(true)} onMouseLeave={() => !support_touch && setHover(false)} className={classes.join(' ')} isMore={isMore}>
      {title}
    </FilterItem>
  )
};

const FiltersListComponent = ({
  tags,
  filters,
  onToggle,
  onToggleShow,
  openMap
}) => {
  const isTouchDev = function () {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    const mq = function (query) {
      return window.matchMedia(query).matches;
    };

    if (('ontouchstart' in window) || window.DocumentTouch/* && document instanceof DocumentTouch*/) {
      return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
  }();

  return (<FiltersList openMap={openMap}>
    <FiltersTop>
      {tags && tags.map(tag => (
        <Filter 
          key={`tag-${tag.ID}`}
          title={tag.title}
          onClick={() => onToggle(tag)} 
          selected={selected(filters, tag)}
          support_touch={isTouchDev}
        />
      ))}
    </FiltersTop>
    <Filter
      title='More Filters'
      onClick={() => onToggleShow(true)}
      isMore={true}
    />

  </FiltersList>
)};

export default FiltersListComponent;
