export const NEXT_STAGE = 'review/NEXT_STAGE';
export const PREV_STAGE = 'review/PREV_STAGE';
export const SET_ANSWER = 'review/SET_ANSWER';
export const SET_COMMENT = 'review/SET_COMMENT';
export const SET_TITLE = 'review/SET_TITLE';
export const SET_ANONYMOUS = 'review/SET_ANONYMOUS';
export const SET_COACH = 'review/SET_COACH';
export const REVIEW_ANOTHER_COACH = 'review/REVIEW_ANOTHER_COACH';

export const SETUP_REVIEW_QUESTIONS = 'review/SETUP_REVIEW_QUESTIONS';

export const CREATE_REVIEW_REQUESTED = 'review/CREATE_REVIEW_REQUESTED';
export const CREATE_REVIEW_SUCCESS = 'review/CREATE_REVIEW_SUCCESS';
export const CREATE_REVIEW_FAILURE = 'review/CREATE_REVIEW_FAILURE';

export const GET_REVIEW_COACHES_REQUESTED = 'review/GET_REVIEW_COACHES_REQUESTED';
export const GET_REVIEW_COACHES_SUCCESS = 'review/GET_REVIEW_COACHES_SUCCESS';
export const GET_REVIEW_COACHES_FAILURE = 'review/GET_REVIEW_COACHES_FAILURE';

export const DELETE_ADMIN_REVIEW_REQUESTED = 'review/DELETE_ADMIN_REVIEW_REQUESTED';
export const DELETE_ADMIN_REVIEW_SUCCESS = 'review/DELETE_ADMIN_REVIEW_SUCCESS';
export const DELETE_ADMIN_REVIEW_FAILURE = 'review/DELETE_ADMIN_REVIEW_FAILURE';