import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

// Redux stuff
import { withLifecycleActions } from 'react-redux-lifecycle'
import { Field, reduxForm } from 'redux-form'
import moment from 'moment';
import {
  inputField,
  textArea,
  selectField,
  placesField,
  parseLocation,
  collegeField,
  findCollegeRefer,
  fixAddress,
} from './utils';

// Components
import Spinner from 'react-spinkit';
import { PageContainer } from '../../components';
import { 
PageContent,
ButtonLabel,
SaveButton,
ProfileHeading,
EditButton,
ProfileSubHeading,
StyledEdit,
LocationsCardFilters,
CategoryTag,
StyledRemove,
} from './Styled';

// Validation
const required = value => value ? undefined : 'Required';

let CreateAttractions = ({
  isAuthenticated,
  isFetching,
  handleSubmit,
  attraction,
  router,
  tags,
  colleges,
  initialValues,
  saveAttraction,
  getAttractionsTags,
  getAttraction,
  listCollegesIfEmpty,
}) => {
  const [taglist, setTag] = useState([]);
  const ID = initialValues && initialValues.ID;
  const routerId = /\d+/.test(router) && parseInt(router.match(/\d+/)[0]);
  const tagsOptions = tags && tags.map(item => ({label: item.title, value: item.ID}));
  const collegeOptions = colleges && colleges.map(item => ({label: item.formal_name, value: item.ID}));
  const collegeRefer = attraction && attraction.college_refer && colleges.length && findCollegeRefer(attraction.college_refer, colleges);

  useEffect(() => {
    if ((!attraction && /\d+/.test(router)) || (attraction && attraction.ID !== routerId)) {
      getAttraction();
    }

    if (!colleges.length) {
      listCollegesIfEmpty();
    }

    if (!tags) {
      getAttractionsTags();
    };
    if (!initialValues && taglist.length > 0) {
      setTag([]);
    }
    if (initialValues && initialValues.tags && taglist.length < 1) {
      setTag([...taglist, ...initialValues.tags]);
    };
  }, [attraction, router, colleges, tags, getAttraction, listCollegesIfEmpty, getAttractionsTags, initialValues]);
  
  const handleTaglist = (value) => {
    const result = tags.find(item => item.ID === value);
    setTag([...taglist, result])
    return taglist;
  };
  
  const deleteTag = id => {
    const result = taglist.filter(tag => tag.ID !== id);
    setTag(result);
  };
  const handleData = ({ title, address, about, college_refer }) => {
    const coordinates = attraction && attraction.location.geo_json.coordinates
    const attractionAddress = attraction && attraction.location.address
    const location = address.value && address.coordinates ? address : {coordinates: coordinates, value: fixAddress(attractionAddress)};
    const longitude = address.value && address.coordinates ? address.coordinates[0] : attraction.location.geo_json.coordinates[0];
    const latitude = address.value && address.coordinates ? address.coordinates[1] : attraction.location.geo_json.coordinates[1];
    const collegeRefer = college_refer ? college_refer : attraction && attraction.college_refer;
    const { address: parseAddress } = parseLocation(location, moment().format())
    
    const result = {
      ...parseAddress,
      about,
      CreatedAt: moment().format(),
      UpdatedAt: null,
      DeletedAt: null,
      location: parseLocation(location, moment().format()),
      tags: taglist,
      title,
      college_refer: collegeRefer,
      latitude: latitude.toString(),
      longitude: longitude.toString(),
    }
    return result
  }
  return (
    <div>
      {isFetching &&
        <PageContainer>
          <PageContent>
            <Spinner fadeIn="none" name="cube-grid" />
          </PageContent>
        </PageContainer>
      }
      {!isFetching &&
        <form onSubmit={handleSubmit((data) => saveAttraction(ID, handleData(data)))}>
          <PageContainer>
            <PageContent>
              <ProfileHeading as="h1" >
                <Field
                  name="title"
                  label="Attraction title..."
                  component={inputField}
                  type="text"
                  validate={[required]}
                />
                <EditButton><StyledEdit /></EditButton>
              </ProfileHeading>
              
              <ProfileHeading as="h1" >
                <Field
                  name='address'
                  label='Address'
                  component={placesField} 
                  initialValues={initialValues && initialValues.address}
                  type="text"
                  validate={[required]}
                />
              </ProfileHeading>

              <ProfileHeading as="h1" >
                <Field
                  collegeRefer={collegeRefer}
                  name="college_refer"
                  label="College reference..."
                  component={collegeField}
                  type="text"
                  options={collegeOptions}
                  normalize={({value}) => value}
                  //validate={[required]}
                />
              </ProfileHeading>

              <ProfileHeading as="h1">
                  <Field
                    name="about"
                    label="Attraction about text..."
                    component={textArea}
                    type="object"
                    validate={[required]}
                  />
                <EditButton><StyledEdit /></EditButton>
              </ProfileHeading>
             
              <ProfileHeading as="h1">
                <Field
                  setTag={setTag}
                  taglist={taglist}
                  name="tags"
                  label="Tags..."
                  component={selectField}
                  type="text"
                  options={tagsOptions}
                  normalize={({value}) => handleTaglist(value)}
                  validate={[required]}
                />
              </ProfileHeading>
             
              <ProfileHeading as="h1">
                <LocationsCardFilters>
                  Tags selected:{' '} 
                  {taglist && taglist.map((tag, index) => {
                    return <CategoryTag key={index} onClick={() => deleteTag(tag.ID)}> {tag.title}<StyledRemove/> </CategoryTag>;
                  })}
                </LocationsCardFilters>
              </ProfileHeading>
             
              <ProfileSubHeading as="h1">
                <SaveButton type="submit">
                  <ButtonLabel>Save Attraction</ButtonLabel>
                </SaveButton>
              </ProfileSubHeading>
            
            </PageContent>
          </PageContainer>
        </form>
      }
    </div>
  )
}

CreateAttractions.propTypes = {
  isFetching: PropTypes.bool,
  handleSubmit: PropTypes.func,
  attraction: PropTypes.shape({
    ID: PropTypes.number,
    about: PropTypes.string,
    average_review: PropTypes.shape({
      attraction_refer: PropTypes.number,
      average_rating: PropTypes.number,
      reviews_count: PropTypes.number,  
    }),
    college_refer: PropTypes.number,
    location: PropTypes.shape({
      CreatedAt: PropTypes.string,
      DeletedAt: PropTypes.string,
      ID: PropTypes.number,
      UpdatedAt: PropTypes.string,
      address: PropTypes.shape({
        CreatedAt: PropTypes.string,
        DeletedAt: PropTypes.oneOfType([PropTypes.any, PropTypes.string]),
        ID: PropTypes.number,
        UpdatedAt: PropTypes.string,
        address_line_1: PropTypes.oneOfType([PropTypes.any, PropTypes.string]),
        address_line_2: PropTypes.oneOfType([PropTypes.any, PropTypes.string]),
        city: PropTypes.oneOfType([PropTypes.any, PropTypes.string]),
        country: PropTypes.oneOfType([PropTypes.any, PropTypes.string]),
        postal_code: PropTypes.oneOfType([PropTypes.any, PropTypes.string]),
        state: PropTypes.oneOfType([PropTypes.any, PropTypes.string]),
      }),
      address_refer: PropTypes.number,
      geo_json: PropTypes.shape({
        type: PropTypes.string,
        coordinates: PropTypes.array,
      }),
      target_id: PropTypes.number,
      target_type: PropTypes.string,
    }),
    tags: PropTypes.array,
    title: PropTypes.string,
  }),
  router: PropTypes.string,
  tags: PropTypes.array,
  colleges: PropTypes.array,
  initialValues: PropTypes.object,
  saveAttraction: PropTypes.func,
  getAttractionsTags: PropTypes.func,
  getAttraction: PropTypes.func,
  listCollegesIfEmpty: PropTypes.func,
};

CreateAttractions = reduxForm({
  // a unique name for the form
  form: 'CreateAttractions',
  enableReinitialize: true
})(CreateAttractions)

const checkSession = ({ isAuthenticated, requestLogin, match }) => {
  if (!isAuthenticated) {
    return requestLogin(match.url);
  };

  return {type: 'NOOP'};
};

export default withLifecycleActions({ componentDidMount: [checkSession] })(CreateAttractions);
