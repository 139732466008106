import styled from 'styled-components';
import { color, typo, bp } from '../../../styles';
import { Heading } from 'rebass';

export const ItemContainer = styled.div`
	width: 100%;
  height: 12rem;
  position: relative;
	overflow: hidden;
	border-radius: 1.2rem;
	margin-bottom: 1.5rem;
	box-shadow: 0 2px 12px rgba(0,0,0,.3);

	:hover > i {
		display: flex;
	}
	@media ${bp.phoneOnly} {
		height: 5.5rem;
	}
`;

export const SchoolDataContainer = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	align-items: center;
`;

export const SchoolImg = styled.div`
	background-image: linear-gradient(to left, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.67) 61%, #ffffff);
	width: 14rem;
	height: 14rem;
	display: flex;
	justify-content: center;
	align-items: center;
	@media ${bp.phoneOnly} {
		width: 10rem;
		height: 5.5rem;
	}
	img{
		height: 60%;
		width: auto;	
		max-width: 100px;
		@media ${bp.phoneOnly} {
			max-width: 50px;
		}
	}
`;

export const SchoolName = styled(Heading)`
	font-family: ${typo.ovFontFeature};
	color: ${color.ovWhite};
  font-size: 5.5rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  @media ${bp.phoneOnly} {
		font-size: 3rem;
	}
`;

export const ImgStyle = {
  height: '60%',
  width: 'auto',
  maxWidth: '100px',
  imageOrientation: 'from-image'
};

export const SchoolPhoto = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	background: ${props => `url("${props.bgUrl}") no-repeat center`};
  background-size: cover;
`;

export const RemoveButton = styled.i`
	position: absolute;
	z-index: 3;
	top: calc(50% - 2rem);
	right: 1.5rem;

	display: none;
	justify-content: center;
	align-items: center;
	
	border: 2px dashed;
	background-color: rgba(255,255,255,.5);
	width: 4rem;
	height: 4rem;
	border-radius: 1rem;
	cursor: pointer;

	::after {
		font-style: normal;
		font-weight: bold;
		display: block;
		content: 'X';
		font-size: 2rem;
	}
`;
