import { apiEndpoint } from '../config/app';
import createRestApiClient from '../utils/createRestApiClient';

export default () => {
  // Adds token to client if it is passed in
  const token = localStorage.getItem('ovSessToken');
  const config = token ? {
    baseURL: apiEndpoint,
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  } : { baseURL: apiEndpoint };

  const client = createRestApiClient().withConfig(config);
  return {
    newReview: (review) => client.request({
      method: 'POST',
      url: '/a/review',
      data: review
    }),

    getReviewCoaches: (id) => client.request({
      method: 'GET',
      url: '/a/review/coaches/' + id
    }),

    deleteAdminReview: (id) => client.request({
      method: 'DELETE',
      url: '/aa/review/' + id
    })
    
  };
};
