import React, {useState} from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import CollegeCoachCard from '../../../components/College/CollegeCoachCard';


import {
  CollegeSmallCardRow,
  LoadMoreButtonContainer,
  LoadMoreButton,
  LoadMoreButtonLabel
} from '../StyledUI';


const CoachesStats = ({ coaches, push, paginate }) => {

  const [page, setPage] = useState(1);
  const coachesPaginated = paginate(page);
  return (
    <div>
      <CollegeSmallCardRow>
        {coachesPaginated.map((coach, index)=>{
          const { average_review: {average_rating, reviews_count} } = coach;
          return(
            <CollegeCoachCard
              key={index}
              name={coach.display_name}
              averageRating={average_rating}
              photo={coach.photo.src_lg}
              position={coach.title_0}
              count={reviews_count}
              handleClick={()=> push('/coach/' + coach.url_slug)}
            />
          )
        })}
        {coachesPaginated && coachesPaginated.length < coaches.length && (
          <LoadMoreButtonContainer>
            <LoadMoreButton onClick={() => setPage(page + 1)}>
              <LoadMoreButtonLabel>Show More</LoadMoreButtonLabel>
            </LoadMoreButton>
          </LoadMoreButtonContainer>
        )}
      </CollegeSmallCardRow>
    </div>
  )
};


const mapDispatchToProps = dispatch => bindActionCreators({
  push,
}, dispatch)

export default connect(null, mapDispatchToProps)(CoachesStats)
