import debounce from "lodash/debounce";

function getLastObjKey(ob, keys) {
  if (keys.length === 1) return ob;

  const key = keys.shift();
  if (typeof ob[key] !== 'object') ob[key] = {};

  return getLastObjKey(ob[key], keys)
}

function getKeys(path) {
  if (!path) path = '';
  return path.split('.');
}

export function selectedFilter(filter) {
  return (path) => {
    const keys = getKeys(path);

    switch (keys.length) {
      case 0:
        return false;
      case 1:
        return !!filter[keys[0]];
      default:
        const last = getLastObjKey(filter, keys);
        return !!last[keys[0]] // 0 always, it's the last one after getLastObjKey
    }
  };
}

export function toggleFilter(filter, setFilters, onChange) {
  return (path) => {
    const keys = getKeys(path);

    if (!keys.length) return;

    const firstKey = keys[0];

    const last = getLastObjKey(filter, keys);
    last[keys[0]] = !last[keys[0]];

    setFilters(prevState => {
      return { ...prevState, [firstKey]: filter[firstKey] }
    });
    if (typeof onChange === "function") onChange();
  };
}

export function rangeFilter(filter) {
  return (path, minValue, maxValue) => {
    const keys = getKeys(path);

    const getVal = (obj, key) => {
      if (obj[key] === undefined && minValue !== undefined && maxValue !== undefined) {
        obj[key] = { min: minValue, max: maxValue }
      }

      return obj[key]
    };

    switch (keys.length) {
      case 0:
        return false;
      case 1:
        return getVal(filter, keys[0]);
      default:
        const last = getLastObjKey(filter, keys);
        return getVal(last, keys[0]) // 0 always, it's the last one after getLastObjKey
    }
  };
}

export function onChangeRangeFilter(filter, setFilters, onChange) {
  return (path) => debounce((value) => {
    const keys = getKeys(path);

    const updateVal = (obj, key) => {
      obj[key] = value;
      return obj[key]
    };

    const firstKey = keys[0];

    if (keys.length === 1) {
      updateVal(filter, keys[0]);
    } else if (keys.length > 1) {
      const last = getLastObjKey(filter, keys);
      updateVal(last, keys[0]) // 0 always, it's the last one after getLastObjKey
    }

    setFilters(prevState => {
      return { ...prevState, [firstKey]: filter[firstKey] }
    });
    if (typeof onChange === "function") onChange();
  }, 500);
}