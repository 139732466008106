import React from 'react';
import PropTypes from 'prop-types';

// Imported Styles
import {
  Item,
  Question,
  Selection,
  SelectionBar,
  LabelBar,
  StyledInput
} from './StyledItem';

const ReviewItem = ({
  index,
  context,
  ratingKey,
  text,
  values,
  selectedValue,
  onSelect,
  onInputChange,
  inputValue="",
  disabled
}) => {

  const onChange = (e) => {
    return onInputChange({ index: index, comment: e.target.value })
  }

  return (
    <Item>
      <Question>{text}</Question>
      <Selection>
        <SelectionBar>
          {values.map((value, i) => {
            // decide if first or last element and apply correct style
            let positionClass = '';
            if (i === 0) {
              positionClass = 'first';
            } else if (i === values.length - 1) {
              positionClass = 'last';
            }

            let activeClass = '';
            if (value === selectedValue) {
              activeClass = 'selected';
            }

            const select = () => {
              !disabled && onSelect({index, context, rating_key: ratingKey, rating: value});
            };

            return (<li className={`${positionClass} ${activeClass}`} onClick={select} key={'values_list_'+ i + '_' + ratingKey}>
              <div className={'selection-wrapper'} >
                <div className={'label'} >{value}</div>
                <div className={'line'} />
                <div className={'aux'} >
                  <div className={'bg'} />
                  <div className={'bd'} />
                  <div className={'overlay'} />
                </div>
              </div>
            </li>);
          })}
        </SelectionBar>
        <LabelBar >
          <li className={'first'} >{text === "Degree Difficulty" ? 'Easiest' : 'Worst'}</li>
          <li className={'middle'} />
          <li className={'last'} >{text === "Degree Difficulty" ? 'Hardest' : 'Best'}</li>
        </LabelBar>
        <label htmlFor="title">Comments: </label>
        <StyledInput disabled={disabled} value={inputValue} placeholder={"Optional"} onChange={onChange}/>
      </Selection>
    </Item>
  );
};

ReviewItem.propTypes = {
  ratingKey: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  selectedValue: PropTypes.number,
  context: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default ReviewItem;
