import styled from 'styled-components';
import { color, typo, bp } from '../../../styles';
import { Search as SearchIcon } from 'styled-icons/material/Search';
import PerfectScrollbar from 'react-perfect-scrollbar';


// FILTER
export const InputContainer = styled.div`
  margin-bottom: -22px;
`;

export const StyledSearchIcon = styled(SearchIcon)`
  position: relative;
  top: -26px;
  left: 6px;
  height: 22px;
  width: 22px;
  color: ${color.ovBlue};
`;

export let StyledInput = styled.input`
  display: inline-block;
  width: 100%;
  height: 32px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.5px ${color.ovGreyLight};
  background-color: ${color.ovWhite};
  padding-left: 32px;
  padding-top: 3px;
  outline: none;
  font-size: 16px;
  &::placeholder{
    font-family: ${typo.ovFontFeature};
    font-size: 18px;
    font-weight: 300;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #95989a;
  }
`;

export const FiltersContainer = styled.div`
  @media ${bp.tabletPortUp}{
    padding: 0 20px;
  }
`;

export const FiltersGroupBox = styled.div`
  padding: 11px;
  border-bottom: solid 1px #707070;

  :last-child {
    border: none;
  }
`;

export const FiltersGroupTitle = styled.div`
  font-size: 18px;
  color: ${color.ovBlack};
  padding-left: 5px;
  padding-bottom: 10px;
`;

export const FiltersBox = styled.div`
  font-family: ${typo.ovFontFeature};
  font-weight: 300;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;

  @media ${bp.tabletPortUp}{
    padding: 11.6px 13px 22.5px 7px;
    border-radius: 5px;
    border: solid 0.5px #707070;
  }
`;

export const FiltersBoxHeader = styled(FiltersBox)`
  display: none;
  border-bottom: solid 1px #707070;
  @media ${bp.phoneOnly}{
    display: block;
  }

  ${FiltersGroupBox} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    a {
      font-size: 22px;
      font-weight: 600;
    }
    svg {
      height: 25px;
      fill: ${color.ovBlue};
    }
  }
`;

export const ScrollbarContainer = styled(PerfectScrollbar)`
  max-height: calc(100vh - 66px);
  overflow-y: hidden;
  padding: 0 10px 20px 0;

  .ps__rail-y{
    opacity: 1;
    .ps__thumb-y{
      background-color: ${color.ovGrey};
    }
  }
`;


export const FiltersBoxHeaderMobile = styled.div`
  padding: 10px 0px 19px 0;
  text-align: right;
  display: none;
  grid-template-columns: 130px 130px;
  grid-column-gap: 10px;
  justify-content: end;
  @media ${bp.phoneOnly}{
    display: grid;
    justify-content: start;
  }
`;

export const FiltersBoxHeaderTitle = styled.div`
  font-size: 25px;
  color: ${color.ovBlack};
  @media ${bp.phoneOnly}{
    display: none;
  }
`;


