import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import Spinner from 'react-spinkit';
import { listCollegesIfEmpty, visibleColleges } from '../../modules/college';
import CollegeListFilterForm from './filter';
import { Bkg } from '../../components/CollegeLogo';
import ExploreCollegesCard from '../../components/ExploreColleges';
import 'react-input-range/lib/css/index.css';
import Filters from '../../components/ExploreColleges/Filters/Filters';
import { onChangeRangeFilter, rangeFilter, selectedFilter, toggleFilter } from "../../utils/filter";
import find from "lodash/find";
import debounce from "lodash/debounce";
import { X } from 'styled-icons/feather/X';
import 'react-perfect-scrollbar/dist/css/styles.css';

import sizeArray, {temperatureArray, acceptanceRateArray, fundingArray, satRangeArray, actRangeArray, compositeRangeArray, outStateTuitionArray, inStateTuitionArray, earnings2Array, earnings6Array, conferenceArray, nflArray, stadiumArray, apparelArray, graduationRateArray, blackGraduationRateArray, whiteGraduationRateArray, academicProgressRateArray} from './filtersMap';

import {
  EighthPartPageContent,
  QuarterPartPageContent,
  ExploreCollegesBox,
  CollegeListTotal,
  ExporeCollegeFilter,
  FiltersBox,
  FiltersBoxHeader,
  FiltersBoxFooter,
  FiltersBoxHeaderMobile,
  FiltersBoxHeaderTitle,
  FiltersBoxClearButton,
  FiltersBoxCloseButton,
  FiltersBoxCancelButton,
  FiltersBoxApplyButton,
  FiltersGroupBox,
  FiltersGroup,
  FiltersGroupTitle,
  PageContainer,
  ScrollbarContainer
} from './Styled'


const applyFilters = (list) => filter => list && list.reduce((memo, item) => {
  let matches = [];
  let hasAnyFilter = [];

  const stats = item.filterStats;
  const teamStats = item.filterTeamStats;

  if (filter.size) {
    hasAnyFilter.push(filter.size.large || filter.size.medium || filter.size.small);

    let gmatch = false;
    gmatch |= filter.size.large && stats.enrollment["Undergraduate Enrollment"] > 25000;
    gmatch |= filter.size.medium && stats.enrollment["Undergraduate Enrollment"] >= 10000 && stats.enrollment["Undergraduate Enrollment"] <= 25000;
    gmatch |= filter.size.small && stats.enrollment["Undergraduate Enrollment"] < 10000;

    if (gmatch) matches.push(gmatch);
  }

  if (filter.funding) {
    hasAnyFilter.push(filter.funding.private || filter.funding.public);

    let gmatch = false;
    if (stats.funding) {
      gmatch |= filter.funding.private && stats.funding["Funding"] === "Private not-for-profit";
      gmatch |= filter.funding.public && stats.funding["Funding"] === "Public";
    }

    if (gmatch) matches.push(gmatch);
  }

  if (filter.weather && filter.weather.avr_high_temp) {
    hasAnyFilter.push(true);
    if (filter.weather.avr_high_temp && stats.weather["Average Temperature"] >= filter.weather.avr_high_temp.min && stats.weather["Average Temperature"] <= filter.weather.avr_high_temp.max) {
      matches.push(true);
    }
  }

  if (filter.acceptance_rate) {
    hasAnyFilter.push(true);
    if (filter.acceptance_rate && stats.admissions && stats.admissions.acceptance_rate >= filter.acceptance_rate.min && stats.admissions.acceptance_rate <= filter.acceptance_rate.max) {
      matches.push(true);
    }
  }

  if (filter.sat_scores) {
    hasAnyFilter.push(true);
    let range = stats.books && stats.books["SAT Range"] ? stats.books["SAT Range"].split("-") : [0,0];
    if (range.length !== 2) range = [0,0];
    if (filter.sat_scores &&
      range[0] <= filter.sat_scores.max && // filter out colleges when it goes lower than the college’s 25% median score
      range[1] >= filter.sat_scores.min) // filters out colleges when it exceeds a college’s 75% median score
    {
      matches.push(true);
    }
  }

  if (filter.act_scores) {
    hasAnyFilter.push(true);
    let range = stats.books && stats.books["ACT Range"] ? stats.books["ACT Range"].split("-") : [0,0];
    if (range.length !== 2) range = [0,0];
    if (filter.act_scores &&
      range[0] <= filter.act_scores.max && // filter out colleges when it goes lower than the college’s 25% median score
      range[1] >= filter.act_scores.min) // filters out colleges when it exceeds a college’s 75% median score
    {
      matches.push(true);
    }
  }

  if (filter.ov_composite_rank) {
    hasAnyFilter.push(true);
    if (filter.ov_composite_rank && stats.ranks && stats.ranks["OfficialVisit College Ranking"] >= filter.ov_composite_rank.min && stats.ranks["OfficialVisit College Ranking"] <= filter.ov_composite_rank.max) {
      matches.push(true);
    }
  }

  if (filter.out_of_state_tuition) {
    hasAnyFilter.push(true);
    if (filter.out_of_state_tuition && stats.cost && stats.cost["Tuition (Out-of-State)"] >= filter.out_of_state_tuition.min && stats.cost["Tuition (Out-of-State)"] <= filter.out_of_state_tuition.max) {
      matches.push(true);
    }
  }

  if (filter.in_state_tuition) {
    hasAnyFilter.push(true);
    if (filter.in_state_tuition && stats.cost && stats.cost["Tuition (In-State)"] >= filter.in_state_tuition.min && stats.cost["Tuition (In-State)"] <= filter.in_state_tuition.max) {
      matches.push(true);
    }
  }

  if (filter.median_earnings_2) {
    hasAnyFilter.push(true);
    if (filter.median_earnings_2 && stats.outcomes && stats.outcomes.median_earnings_2_years >= filter.median_earnings_2.min && stats.outcomes.median_earnings_2_years <= filter.median_earnings_2.max) {
      matches.push(true);
    }
  }

  if (filter.median_earnings) {
    hasAnyFilter.push(true);
    if (filter.median_earnings && stats.cost && stats.cost["Earnings after College"] >= filter.median_earnings.min && stats.cost["Earnings after College"] <= filter.median_earnings.max) {
      matches.push(true);
    }
  }

  if (filter.conference) {
    const f = filter.conference;
    hasAnyFilter.push(f.acc || f.big12 || f.bingten || f.pac12 || f.sec || f.american || f.conferenceusa || f.mac || f.mountainwest || f.sunbelt || f.independent);

    let gmatch = false;
    if (teamStats.misc) {
      gmatch |= f.acc && teamStats.misc.conference.value === "acc";
      gmatch |= f.big12 && teamStats.misc.conference.value === "big_12";
      gmatch |= f.bingten && teamStats.misc.conference.value === "big_ten";
      gmatch |= f.pac12 && teamStats.misc.conference.value === "pac_12";
      gmatch |= f.sec && teamStats.misc.conference.value === "sec";
      gmatch |= f.american && teamStats.misc.conference.value === "american";
      gmatch |= f.conferenceusa && teamStats.misc.conference.value === "conference_usa";
      gmatch |= f.mac && teamStats.misc.conference.value === "mac";
      gmatch |= f.mountainwest && teamStats.misc.conference.value === "mountain_west";
      gmatch |= f.sunbelt && teamStats.misc.conference.value === "sun_belt";
      gmatch |= f.independent && teamStats.misc.conference.value === "independent";
    }
    if (gmatch) matches.push(gmatch);
  }

  if (filter.nfl) {
    hasAnyFilter.push(true);
    if (filter.nfl && teamStats.nfl && teamStats.nfl["NFL Draft picks - last 5 years"] >= filter.nfl.min && teamStats.nfl["NFL Draft picks - last 5 years"] <= filter.nfl.max) {
      matches.push(true);
    }
  }

  if (filter.stadium_size) {
    hasAnyFilter.push(true);
    if (filter.stadium_size && teamStats.stadium && teamStats.stadium["Stadium Capacity"] >= filter.stadium_size.min && teamStats.stadium["Stadium Capacity"] <= filter.stadium_size.max) {
      matches.push(true);
    }
  }

  if (filter.apparel_brand) {
    const f = filter.apparel_brand;
    hasAnyFilter.push(f.adidas || f.jordan || f.nike || f.underarmour);

    let gmatch = false;
    if (teamStats.misc) {
      gmatch |= f.adidas && teamStats.misc.apparel.value === "adidas";
      gmatch |= f.jordan && teamStats.misc.apparel.value === "jordan";
      gmatch |= f.nike && teamStats.misc.apparel.value === "nike";
      gmatch |= f.underarmour && teamStats.misc.apparel.value === "under_armour";
    }
    if (gmatch) matches.push(gmatch);
  }

  if (filter.graduation_success_rate) {
    hasAnyFilter.push(true);
    if (filter.graduation_success_rate && teamStats.graduation && teamStats.graduation["Overall Graduation Success Rate"] >= filter.graduation_success_rate.min && teamStats.graduation["Overall Graduation Success Rate"] <= filter.graduation_success_rate.max) {
      matches.push(true);
    }
  }

  if (filter.black_graduation_success_rate) {
    hasAnyFilter.push(true);
    if (filter.black_graduation_success_rate && teamStats.graduation && teamStats.graduation["Black Graduation Success Rate"] >= filter.black_graduation_success_rate.min && teamStats.graduation["Black Graduation Success Rate"] <= filter.black_graduation_success_rate.max) {
      matches.push(true);
    }
  }

  if (filter.white_graduation_success_rate) {
    hasAnyFilter.push(true);
    if (filter.white_graduation_success_rate && teamStats.graduation && teamStats.graduation["White Graduation Success Rate"] >= filter.white_graduation_success_rate.min && teamStats.graduation["White Graduation Success Rate"] <= filter.white_graduation_success_rate.max) {
      matches.push(true);
    }
  }

  if (filter.academic_progress_rate) {
    hasAnyFilter.push(true);
    if (filter.academic_progress_rate && teamStats.graduation && teamStats.graduation["Academic Progress Rate"] >= filter.academic_progress_rate.min && teamStats.graduation["Academic Progress Rate"] <= filter.academic_progress_rate.max) {
      matches.push(true);
    }
  }


  const hasAnyFilterTrueCount = hasAnyFilter.filter(item => !!item).length;
  if (!hasAnyFilterTrueCount) return list;

  if (hasAnyFilterTrueCount === matches.length) {
    memo.push(item)
  }

  return memo;
}, []);

const Colleges = ({ colleges, listCollegesIfEmpty, isFetchingCollege, push }) => {
  useEffect(() => {

    if (!colleges || !colleges.length) {
      listCollegesIfEmpty();
    }

  }, [colleges]);

  const [filters, setFilters] = useState({});

  // prepare stats for filter
  if (colleges.length) {
    colleges.forEach(college => {
      college['filterStats'] = {};
      college['filterTeamStats'] = {};

      college.Stats.forEach(item => {
        if (!college['filterStats'][item.title]) college['filterStats'][item.title] = {};
        if (item.data.length) {
          item.data.forEach(d => {
            college['filterStats'][item.title][d.label] = d.value
          })
        } else college['filterStats'][item.title] = item.data || {}
      });

      const main = college.teams[0];
      // If not main team, return empty
      if (!main) return;

      const { stats: list } = main;
      const miscIndex = list.findIndex(({ title }) => title === 'misc');
      const miscItem = miscIndex > -1 && list[miscIndex];

      const header = { conference: {}, apparel: {} };
      if (miscItem) {
        header.conference = (find(miscItem.data, { label: 'Conference' }) || {});
        header.apparel = (find(miscItem.data, { label: 'Apparel Provider' }) || {});
      }

      list.filter(({ title }) => title !== 'misc').forEach(item => {
        if (!college['filterTeamStats'][item.title]) college['filterTeamStats'][item.title] = {};
        if (item.data.length) {
          item.data.forEach(d => {
            college['filterTeamStats'][item.title][d.label] = d.value
          })
        } else college['filterTeamStats'][item.title] = item.data || {}
      });
      college['filterTeamStats']['misc'] = header
    });
  }
  
  // Apply filters
  const filtered = applyFilters(colleges)(filters);
  const sortedColleges = filtered.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

  const debouncedSetFilters = debounce(setFilters, 500);

  const filter = {
    filters: filters,
    selected: selectedFilter(filters),
    toggle: toggleFilter(filters, debouncedSetFilters),
    range: rangeFilter(filters),
    onChangeRange: onChangeRangeFilter(filters, debouncedSetFilters),
  };

  const initialValue = {
    size: undefined
  };

  const [show, setShow] = useState(false);
  const [clear, setClear] = useState(false);

  return(
  <PageContainer>
    <Helmet>
      <title>Explore Colleges - OfficialVisit</title>
      <meta name="description" content="Explore and filter colleges by size, weather, rankings, admissions, outcomes, and football metrics. Find the right college for you!" />
    </Helmet>
    <QuarterPartPageContent data-show={show}>
      <FiltersBox>
        <ScrollbarContainer>
          <FiltersBoxHeader>
            <FiltersBoxHeaderTitle>College Filters</FiltersBoxHeaderTitle>
            <FiltersBoxClearButton
              onClick={()=>{debouncedSetFilters(initialValue); setClear(true)}}
            >
              Clear Filters
            </FiltersBoxClearButton>
            <FiltersBoxCloseButton onClick={() => setShow(false)}><X/></FiltersBoxCloseButton>
          </FiltersBoxHeader>

          <FiltersGroupBox>
            <FiltersGroup>
              <FiltersGroupTitle>College Characteristics</FiltersGroupTitle>
              <Filters name="Size" type="checkbox" array={sizeArray} setClear={setClear} clear={clear} filter={filter}/>
              <Filters name="Weather" filter={filter} type="range" setClear={setClear} clear={clear} array={temperatureArray}/>
              <Filters name="Acceptance Rate" filter={filter} type="range" setClear={setClear} clear={clear} array={acceptanceRateArray}/>
              <Filters name="Funding" type="checkbox" array={fundingArray} setClear={setClear} clear={clear} filter={filter}/>
            </FiltersGroup>
          </FiltersGroupBox>

          <FiltersGroupBox>
            <FiltersGroup>
              <FiltersGroupTitle>Admissions</FiltersGroupTitle>
              <Filters name="SAT Scores" filter={filter} type="range" setClear={setClear} clear={clear} array={satRangeArray}/>
              <Filters name="ACT scores" filter={filter} type="range" setClear={setClear} clear={clear} array={actRangeArray}/>
              <Filters name="OV+ Composite Rank" filter={filter} type="range" setClear={setClear} clear={clear} array={compositeRangeArray}/>
            </FiltersGroup>
          </FiltersGroupBox>

          <FiltersGroupBox>
            <FiltersGroup>
              <FiltersGroupTitle>Cost</FiltersGroupTitle>
              <Filters name="Out-of-state Tuition" filter={filter} type="range" setClear={setClear} clear={clear} array={outStateTuitionArray} formatLabel={value => value.toLocaleString('EN')}/>
              <Filters name="In-state Tuition" filter={filter} type="range" setClear={setClear} clear={clear} array={inStateTuitionArray} formatLabel={value => value.toLocaleString('EN')} />
            </FiltersGroup>
          </FiltersGroupBox>

          <FiltersGroupBox>
            <FiltersGroup>
              <FiltersGroupTitle>Outcomes</FiltersGroupTitle>
              <Filters name="Median Earnings (2 years after graduation)" setClear={setClear} clear={clear} filter={filter} type="range" array={earnings2Array} formatLabel={value => value.toLocaleString('EN')} />
              <Filters name="Median Earnings (6 years after graduation)" setClear={setClear} clear={clear} filter={filter} type="range" array={earnings6Array} formatLabel={value => value.toLocaleString('EN')} />
            </FiltersGroup>
          </FiltersGroupBox>

          <FiltersGroupBox>
            <FiltersGroup>
              <FiltersGroupTitle>Football Program</FiltersGroupTitle>
              <Filters name="Conference" array={conferenceArray} setClear={setClear} clear={clear} type="checkbox" filter={filter}/>
              <Filters name="NFL Draft Picks (Last 5 years)" setClear={setClear} clear={clear} filter={filter} type="range" array={nflArray}/>
              <Filters name="Stadium Size" filter={filter} setClear={setClear} clear={clear} type="range" array={stadiumArray} formatLabel={value => value.toLocaleString('EN')}/>
              <Filters name="Apparel Brand" type="checkbox" setClear={setClear} clear={clear} array={apparelArray} filter={filter}/>
              <Filters name="Graduation Success Rate" setClear={setClear} clear={clear} filter={filter} type="range" array={graduationRateArray}/>
              <Filters name="Black Graduation Success Rate" setClear={setClear} clear={clear} filter={filter} type="range" array={blackGraduationRateArray}/>
              <Filters name="White Graduation Success Rate"filter={filter} setClear={setClear} clear={clear} type="range" array={whiteGraduationRateArray}/>
              <Filters name="Academic Progress Rate" setClear={setClear} clear={clear} filter={filter} type="range" array={academicProgressRateArray}/>
            </FiltersGroup>
          </FiltersGroupBox>

          <FiltersBoxFooter>
            <FiltersBoxCancelButton onClick={() => {debouncedSetFilters(initialValue); setShow(false)}}>Cancel</FiltersBoxCancelButton>
            <FiltersBoxApplyButton onClick={() => setShow(false)}>Apply Filters</FiltersBoxApplyButton>
          </FiltersBoxFooter>
        </ScrollbarContainer>
      </FiltersBox>
    </QuarterPartPageContent>
    <EighthPartPageContent data-show={show}>
      <FiltersBoxHeaderMobile>
        <FiltersBoxClearButton onClick={()=>{debouncedSetFilters(initialValue); setClear(true)}}>Clear Filters</FiltersBoxClearButton>
        <FiltersBoxClearButton onClick={() => setShow(!show)}>Filters</FiltersBoxClearButton>
      </FiltersBoxHeaderMobile>
      <ExporeCollegeFilter>
        <CollegeListFilterForm />
      </ExporeCollegeFilter>
      <CollegeListTotal>
        <span>{sortedColleges.length}</span> colleges reviewed by real students, student-athletes, and alumni
      </CollegeListTotal>

      <ExploreCollegesBox>

        {isFetchingCollege ? <Spinner fadeIn="none" name="cube-grid" /> : sortedColleges.map((college) => {

          // Get College Ranks
          const collegeRanks = college.Stats.filter(item => item.title === "ranks");
          const collegeRanksOv = collegeRanks[0].data[1] && collegeRanks[0].data[1].value;

          // Get college admissions
          const collegeAdmissions = college.Stats.filter(item => item.title === "admissions");
          const collegeAdmissionsRate = collegeAdmissions[0] && collegeAdmissions[0].data["acceptance_rate"];

          // Get college funding
          const collegeFunding = college.Stats.filter(item => item.title === "funding");
          const collegeFundingRate = collegeFunding[0] && collegeFunding[0].data[0].value;

          return (
            <ExploreCollegesCard
              key={college.ID}
              handleSubmit={() => { push('/college/' + college.url_slug); }}
              bgURL={(college.default_pic && college.default_pic.src_md) || Bkg["default_bkg"]}
              name={college.name}
              formal_name={college.formal_name}
              city={college.address.city}
              state={college.address.state}
              ranksOv={collegeRanksOv}
              admissionsRate={collegeAdmissionsRate}
              fundingRate={collegeFundingRate}
              averageReview={college.average_review.average_rating}
              count={college.average_review.reviews_count}
            />
          );
        })}

      </ExploreCollegesBox>
    </EighthPartPageContent>
  </PageContainer>
  )
};

Colleges.propTypes = {
  colleges: PropTypes.array.isRequired,
  isFetchingCollege: PropTypes.bool.isRequired,
  listCollegesIfEmpty: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = ({ college, form, user }) => ({
  colleges: visibleColleges(college.colleges, form.collegeListFilter),
  isFetchingCollege: college.isFetchingCollege,
  isAuthenticated: user.isAuthenticated
});

const mapDispatchToProps = dispatch => bindActionCreators({
  listCollegesIfEmpty,
  push,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Colleges)
