import { ReactComponent as EnrollmentIcon } from '../../assets/icons/enrollment.svg';
import { ReactComponent as BooksIcon } from '../../assets/icons/books.svg';
import { ReactComponent as WeatherIcon } from '../../assets/icons/weather.svg';
import { ReactComponent as CostIcon } from '../../assets/icons/cost.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/Info.svg';
import { ReactComponent as StadiumIcon } from '../../assets/icons/stadium.svg';
import { ReactComponent as GradIcon } from '../../assets/icons/grad.svg';

// Apparel Icons
import NikeIcon from '../../assets/img/nike.png'
import AdidasIcon from '../../assets/img/adidas.png'
import JordanIcon from '../../assets/img/jordan.png'
import UnderArmourIcon from '../../assets/img/under-armour.png'
// Conference Icons
import SecIcon from '../../assets/img/sec.png'
import SunBeltIcon from '../../assets/img/sun-belt.png'
import Pac12Icon from '../../assets/img/pac-12.png'
import MountainWestIcon from '../../assets/img/mountain-west.png'
import MacIcon from '../../assets/img/mac.png'
import IndependentIcon from '../../assets/img/independent.png'
import ConferenceUSAIcon from '../../assets/img/conference-usa.png'
import Big10Icon from '../../assets/img/big-10.png'
import Big12Icon from '../../assets/img/big-12.png'
import AmericanIcon from '../../assets/img/american.png'
import ACCIcon from '../../assets/img/acc.png'

export const Brands = {
  nike: NikeIcon,
  adidas: AdidasIcon,
  jordan: JordanIcon,
  under_armour: UnderArmourIcon,
  sec: SecIcon,
  sun_belt: SunBeltIcon,
  pac_12: Pac12Icon,
  mountain_west: MountainWestIcon,
  mac: MacIcon,
  independent: IndependentIcon,
  conference_usa: ConferenceUSAIcon,
  big_ten: Big10Icon,
  big_12: Big12Icon,
  american: AmericanIcon,
  acc: ACCIcon,
};

export const Icons = {
  weather: WeatherIcon,
  enrollment: EnrollmentIcon,
  books: BooksIcon,
  cost: CostIcon,
  info: InfoIcon,
  fallback: InfoIcon,
  stadium: StadiumIcon,
  graduation: GradIcon
};

export default Icons;