import React, { useEffect, createRef, useRef } from 'react';
import Dropzone from 'react-dropzone';
import Spinner from "react-spinkit";
import { Edit } from 'styled-icons/material/Edit';

import {
  DropzoneContainer,
  EditBox,
  LoadingOverlay
} from './Styled';

const useOnMount = (...gets) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      gets.forEach(fn => fn());
      didMountRef.current = true;
    }
  });
};

const CoachPictureContainer = ({ picture, isUploading, dropzoneActive, handleDropPicture, handleDragEnter, handleDragLeave, removeDroppedFile, submitCoachPhoto }) => {
  const dropzoneRef = createRef();

  useOnMount(removeDroppedFile);

  useEffect(() => {
    if (picture) { // upload picture if type is blob
      submitCoachPhoto();
    }
  }, [picture]);

  return (
      <Dropzone
        disableClick
        ref={dropzoneRef}
        className={"dropzonec"}
        accept="image/jpeg, image/png"
        maxSize={8500000} // 4MB

        onDrop={(accepted, rejected) => !isUploading ? handleDropPicture(accepted, rejected) : false}

        onDragEnter={() => !isUploading ? handleDragEnter() : false}
        onDragLeave={handleDragLeave}
      >
        <DropzoneContainer className={dropzoneActive ? "active" : ""}>
          <EditBox 
            onClick={() => !isUploading ? dropzoneRef.current.open() : false}
          >
          {!isUploading &&
            <Edit/>
          }
          { isUploading &&
            <LoadingOverlay>
              <Spinner fadeIn="none" name="cube-grid" />
            </LoadingOverlay>
          }
          </EditBox>
        </DropzoneContainer>
      </Dropzone>
  )
};

export default CoachPictureContainer;
