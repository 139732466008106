import { removePreviewLink } from '../upload';
import * as types from './actionsTypes';
import { CREATE_ATTRACTION_REVIEW_REQUESTED, CREATE_ATTRACTION_REVIEW_SUCCESS, CREATE_ATTRACTION_REVIEW_FAILURE } from '../attraction/reviews';
import { CREATE_REVIEW_REQUESTED, CREATE_REVIEW_SUCCESS, CREATE_REVIEW_FAILURE } from '../review/actionsTypes';
// Contribution functions

export const requestNewContribution = () => ({
  type: types.NEW_CONTRIBUTION_REQUESTED
})

export const receiveNewContribution = (data) => ({
  type: types.NEW_CONTRIBUTION,
  payload: data
})

export const newContributionFailure = () => ({
 type: types.NEW_CONTRIBUTION_FAILURE
})

export const finishContributionFileUpload = (fileIndex, contentObject) => ({
  type: types.CONTRIBUTION_FILE_UPLOAD_FINISH,
  payload: {
    index: fileIndex,
    content: contentObject
  }
});

export const openModal = () => ({
  type: types.OPEN_MODAL
})

export const closeModalAndResetContribution = () => {
  return (dispatch, getState) => {

    const { upload } = getState();

    for (const file in upload.accepted) {
      removePreviewLink(file);
    }

    return dispatch({
      type: types.MODAL_CLOSE_AND_CONTRIBUTION_RESET
    });
  }
}

export const AttractionContentUploadRequest = () => ({
  type: types.ATTRACTION_CONTRIBUTION_REQUEST
});

export const AttractionContentUploadSuccess = (data) => ({
  type: types.ATTRACTION_CONTRIBUTION_SUCCESS,
  payload: {
    contents: data.contents,
    contribution: data.contribution
  }
});

export const AttractionContentUploadFailure = () => ({
    type: types.ATTRACTION_CONTRIBUTION_FAILURE
  });

// Contribitem updates

export const handleContribItemDescriptionUpdate = (value, index) => ({
  type: types.CONTRIB_ITEM_DESC_UPDATE,
  payload: { value, index }
});

export const receiveContentTags = (data) => ({
  type: types.LIST_CONTENT_TAGS,
  payload: data
})

export const toggleContentTag = (contentTag, contribItemIndex) => {
  return (dispatch, getState) => {
    const { contribute } = getState();
    const index = contribute.contrib_items[contribItemIndex].selected_content_tags.findIndex(tag => tag.ID === contentTag.ID);

    return index >= 0 ? dispatch({
      type: types.CONTENT_TAG_DESELECT,
      payload: { index, contribItemIndex }
    }) : dispatch({
      type: types.CONTENT_TAG_SELECT,
      payload: { contentTag, contribItemIndex }
    });
  }
}

export const requestCreateAttractionReview = () => ({
  type: CREATE_ATTRACTION_REVIEW_REQUESTED
})

export const createAttractionReviewSuccess = (data) => ({
  type: CREATE_ATTRACTION_REVIEW_SUCCESS,
  payload: data
})

export const createAttractionReviewFailure = (message) => ({
  type: CREATE_ATTRACTION_REVIEW_FAILURE,
  payload: message
})

export const requestCreateReview = () => ({
  type: CREATE_REVIEW_REQUESTED
})

export const createReviewSuccess = (data) => ({
  type: CREATE_REVIEW_SUCCESS,
  payload: data
})

export const createReviewFailure = (message) => ({
  type: CREATE_REVIEW_FAILURE,
  payload: message
})