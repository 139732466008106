import { color, bp, typo } from '../../styles'
import styled from 'styled-components';
import { Heading } from 'rebass';

export const ListContainer = styled.div`
  background: ${color.ovWhite};
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  margin: 0 -14px;
`;

export const ListContainerCoaches = styled(ListContainer)`
  grid-template-columns: none;
  grid-template-rows: none;
  grid-column: span 12;
`;

export const ReviewsHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: .8;
  letter-spacing: normal;
  text-align: left;
  padding: 0 10px 10px 10px;
  @media ${bp.phoneOnly} {
    font-size: 1.8rem;
  }
`;

export const ReviewHr = styled.div`
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid ${color.ovGrey};
  margin: 5px 0 0;
  padding: 0;
`;

export const TitleContainer = styled.div`
  grid-column: span 12;
`;

export const PageContent = styled.div`
  grid-column: span 12;
`;

export const LoadMoreButtonContainer = styled.div`
  grid-column: 1/-1;
  text-align: center;
  margin: 1rem 0 -7px 0;
`;

export const LoadMoreButton = styled.button`
  height: 26.4px;
  border-radius: 3px;
  box-shadow: 0 2px 2px 0 rgba(5, 5, 5, 0.16);
  border: solid 0.5px #95989a;
  background-color: ${color.ovWhite};
  color: ${color.ovBlue};
  width: 349px;
  &:hover{
    background-color: ${color.ovBlue};
    color: ${color.ovWhite};
    transition: all .1s;
  }
  @media ${bp.phoneOnly} {
    width: 90%;
  }
`;

export const LoadMoreButtonLabel = styled.p`
  font-family: ${typo.ovFontFeature};
  font-size: 20px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  line-height: 1;
`;

export const ReviewsFiltersHeader = styled.div`
  grid-template-columns: auto 80px;
  grid-column: span 12;
  padding: 0 10px;
  display: none;
  align-items: center;
  @media ${bp.phoneOnly} {
    display: grid;
    margin-top: -10px;
  }
`;

export const ReviewsFiltersHeaderTitle = styled.p`
  font-family: ${typo.ovFontFeature};
  font-size: 24px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlack};
  margin: 0;
  padding: 0 0 0 1.4rem;
`;

export const ReviewsFiltersHeaderButton = styled.div`
  cursor: pointer;
  height: 21px;
  border-radius: 3px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px ${color.ovGreyLight};
  background-color: ${color.ovWhite};
  padding: 0 15px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  font-family: ${typo.ovFontFeature};
  color: ${color.ovGrey};
  &:hover{
    background-color: ${color.ovBlue};
    color: ${color.ovWhite};
  }
`;