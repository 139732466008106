import styled from "styled-components";
import { color, typo, bp } from "../../styles";
import { Heading } from 'rebass';

export const GridContainer = styled.div`
  text-align: center;
  padding: 63px 111px;
  @media ${bp.phoneOnly}{
    padding: 40px;
  }
`;

export const BackButtonContainer = styled.div`
  position: absolute;
  z-index: 99;
  top: 30px;
  left: 30px;
  height: 22px;
  width: 22px;
`;
// *Steps indicators
export const StepContainer = styled.div`
  margin-bottom: 3rem;
`;
export const StepIndicatorContainer = styled.div`
  display: inline-block;
`;
export const StepIndicatorLabel = styled.div`
  font-family: ${typo.ovFontFeature};
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.73;
  letter-spacing: normal;
  text-align: center;
  color: ${color.ovGrey};
  @media ${bp.phoneOnly} {
    display: none;
  }
`;
export const StepIndicator = styled.span`
  width: 15px;
  height: 15px;
  border-radius: 15px;
  border: 1px solid ${color.ovBlue};
  display: inline-block;
  position: relative;
  &.active{
    background-color: ${color.ovBlue};
  }
`;
export const StepDivider = styled.span`
  display: inline-block;
  width: 50px;
  height: 1px;
  background-color: ${color.ovBlue};
  margin-bottom: 7px;
  @media ${bp.phoneOnly} {
    width: 10px;
  }
`;

export const ErrorMessage = styled.div`
  display: block;
  color: ${color.ovRed};
  margin-top: -17px;
  text-align: left;
  padding: 10px 0;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 99;
  top: 30px;
  right: 30px;
  height: 22px;
  width: 22px;
`;

export const TitleHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 30px;
  text-align: left;
  margin-bottom: 20px;
  font-weight: 400;
`;

export const NextButton = styled.button`
  height: 59px;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px ${color.ovBlue};
  background-color:  ${color.ovBlue};
  color: ${color.ovWhite};
  width: 100%;
  font-family: ${typo.ovFontFeature};
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: center;
  font-size: 25px;
  transition: all .1s;
  &:hover{
    background-color:  ${color.ovWhite};
    color: ${color.ovBlue};
    transition: all .1s;
  }
  @media ${bp.phoneOnly} {
    font-size: 22px;
  }
`;

export const P = styled.p`
  font-family: ${typo.ovFontFeature};
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovGreyDark};
`;

export const CheckboxContainer = styled.input`
  transform: scale(2);
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
`;

export const CheckboxWarning = styled.div`
  left: 14px;
  position: absolute;
  bottom: -42px;
`;

export const CheckboxLabel = styled(P)`
  margin-left: 0px;
  margin-top: -3px;
  @media ${bp.phoneOnly} {
    margin-left: 0px;
  }
`;

export const TermsAndConditionsBox = styled.div`
  display: grid;
  grid-template-columns: 25px auto;
  grid-column-gap: 10px;
`;