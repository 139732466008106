import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, bp } from '../../styles'

const H2 = styled.h1`
  font-family: Molde;
  font-size: 27px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 0.93;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlue};
  margin: 0 0 17px 0;
  @media ${bp.phoneOnly} {
    font-size: 20px;
  }
`;

const MidTitle = ({
  midTitleLabel,
  className,
  children
}) => {
  return(
    <H2 className={className}>{midTitleLabel}{children}</H2>
  )
}

MidTitle.propTypes = {
  midTitleLabel: PropTypes.string
};

export default MidTitle