import { LOGOUT_SUBMITTED } from '../user';
import { getQuestionStateByRole, athleteCoachQuestions } from './review'
import * as types from './actionsTypes';

const initialState = {
  stage: 0,
  maxStage: 2,
  title: '',
  questions: [],
  answers: [],
  userRole: 'fan',
  reviews:[],
  coachID: -1,
  coaches: [],
  is_anonymous: false,
  isSubmittingReview: false,
}

export default (state = initialState, action) => {
  switch (action.type) {

    case  types.NEXT_STAGE:
      return state.stage >= state.maxStage ? { ...state, stage: state.maxStage } : { ...state, stage: state.stage + 1 }

    case  types.PREV_STAGE:
      return state.stage <= 0 ? { ...state, stage: 0 } : { ...state, stage: state.stage - 1 }

    case types.SET_ANSWER:
      return {
        ...state,
        answers: state.answers.map((item, i) => i === action.payload.index ? { ...item, ...action.payload }  : item)
      }

    case types.SET_COMMENT:
      return {
        ...state,
        answers: state.answers.map((item, i) => i === action.payload.index ? {...item, comment: action.payload.comment} : item)
      }

    case types.SET_TITLE:
      return {
        ...state,
        title: action.payload
      }

    case types.SET_ANONYMOUS:
      return {
        ...state,
        is_anonymous: action.payload
      }

    case types.SET_COACH:
      return {
        ...state,
        coachID: action.payload
      }

    case types.REVIEW_ANOTHER_COACH:
      switch (state.userRole) {
        case "contributor":
        case "fan":
        case "recruit":
        case "student":
          return state

        case "athlete":
        case "admin":
          return {
            ...state,
            stage: state.maxStage + 1,
            maxStage: state.maxStage + 1,
            coachID: -1,
            questions: [...state.questions, ...(athleteCoachQuestions.map(q =>({...q, questionStage: state.maxStage + 1})))].map((question,i) => ({
              index: i,
              ...question
            })),
            answers: [...state.answers, ...(athleteCoachQuestions.map((content) => ({})))].map((answer,i) => ({
              index: i, 
              ...answer
            }))
          }

        default:
          return state
      }

    case types.SETUP_REVIEW_QUESTIONS: {
      const { isCurrentCollegeStudent, role } = action.payload;
      return {
        ...state,
        ...getQuestionStateByRole(isCurrentCollegeStudent, role),
      };
    }

    case types.CREATE_REVIEW_REQUESTED:
      return {
        ...state,
        isSubmittingReview: true,
      };
      
    case types.CREATE_REVIEW_SUCCESS:
      return {
        ...state,
        isSubmittingReview: false,
      };

    case types.CREATE_REVIEW_FAILURE:
      return {
        ...state,
        isSubmittingReview: false,
      };

    case types.GET_REVIEW_COACHES_SUCCESS:
      return {
        ...state,
        // receive the recently created reviews
        coaches: action.payload
      };

    case types.DELETE_ADMIN_REVIEW_SUCCESS:
      return {
        ...state
      };

    case LOGOUT_SUBMITTED:
      return initialState;

    default:
      return state
  }
}