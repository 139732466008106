import styled from 'styled-components';
import { Search as SearchIcon } from 'styled-icons/material/Search';
import { color, typo, bp, settings } from '../../../styles';

export const SearchBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 19px;
`;

export const FiltersActions = styled.div`
  display: flex;
  justify-content: space-between;
  order: 1;
  width: 100%;
  margin-bottom: 19px;

  .react-select {
    &__control {
      font-size: 22px;
      font-weight: 600;
      font-stretch: condensed;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: normal;
      color: #777777;
    }

    &-container {
      width: calc(50% - 3px);
    }
  }

  @media ${bp.tabletPortUp} {
    order: 2;
    margin-bottom: 0;
    margin-top: 19px;
  }

  @media ${bp.tabletLandUp} {
    width: unset;
    max-width: 50%;
    margin-top: 0;

    .react-select-container {
      min-width: 180px;
    }
  }

  [data-show-filters] {
    display: block;
  }
  [data-clear-filters] {
    display: none;
  }

  @media (min-width: ${settings.size.tabletPortrait}) {
    [data-show-filters] {
      display: none;
    }
    [data-clear-filters] {
      display: block;
    }
  }
`;

export const SearchFilter = styled.div`
  width: 100%;
  order: 2;

  @media ${bp.tabletPortUp} {
    order: 1;
  }

  @media ${bp.tabletLandUp} {
    max-width: 50%;
    order: 1;
  }


`;

export const InputContainer = styled.div`
  position: relative;
`;

export const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  top: 5px;
  left: 6px;
  height: 22px;
  width: 22px;
  color: ${color.ovBlue};
`;

export let StyledInput = styled.input`
  display: inline-block;
  width: 100%;
  height: 32px;

  padding: 5px 5px 5px 30px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.5px #707070;
  background-color: #ffffff;

  outline: none;
  font-size: 16px;

  &::placeholder{
    font-size: 18px;
    font-weight: 600;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #95989a;
  }

  @media ${bp.phoneOnly} {
    margin-bottom: 20px;
  }
`;
