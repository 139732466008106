import styled from 'styled-components';
import { color, typo, bp } from '../../styles';
import { Search as SearchIcon } from 'styled-icons/material/Search';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const EighthPartPageContent = styled.div`
  grid-column: span 12;
  grid-row-start: 1;
  @media ${bp.phoneOnly} {
    grid-row-start: 1;
    grid-column-start: 1;
    width: 93%;
    margin: 10px auto;
    height: auto;
    &[data-show=true]{
      height: 93vh;
      overflow: hidden;
    }
  }
  @media ${bp.tabletPortUp} {
    grid-column-start: 6;
    grid-column-end: 13;
    margin-right: 30px;
  }
  @media ${bp.tabletLandUp} {
    grid-column: span 7;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-column-end: 13;
    margin-right: 30px;
  }
  @media ${bp.desktopUp} {
    grid-column-start: 4;
    grid-column-end: 12;
  }
`;

export const QuarterPartPageContent = styled.div`
  grid-column: span 12;
  @media ${bp.phoneOnly} {
    width: 100%;
    margin: 103px auto 0px auto;
    position: absolute;
    top: 0;
    left: -110vw;
    z-index: 999;
    transition: left .2s linear;
    &[data-show=true]{
      left: 0;
      transition: left .2s linear;
    }
  }
  @media ${bp.tabletPortUp} {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 6;
    margin-right: 30px;
  }
  @media ${bp.tabletLandUp} {
    grid-column: span 5;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 5;
    margin-right: 30px;
  }
  @media ${bp.desktopUp} {
    grid-column-start: 1;
    grid-column-end: 4;
    margin-right: 30px;
  }
`;

export const PageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 0px;
  padding-bottom: 0px;
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
  @media ${bp.phoneOnly} {
    grid-template-columns: auto;
    margin-top: -42px;
    height: auto;
  }
  
`;

export const ExploreCollegesBox = styled.div`
  margin: 10px 0;
`;

export const CollegeListTotal = styled.div`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
  font-size: 25px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  span{
    color: ${color.ovBlue};
  }
  @media ${bp.phoneOnly} {
    font-size: 16px;
  }
`;

export const ExporeCollegeFilter = styled.div`
  max-width: 60%;
  margin-bottom: 10px;
  @media ${bp.phoneOnly} {
    max-width: 100%;
  }
`;

// FILTER
export const InputContainer = styled.div`
  margin-bottom: -22px;
`;

export const StyledSearchIcon = styled(SearchIcon)`
  position: relative;
  top: -26px;
  left: 6px;
  height: 22px;
  width: 22px;
  color: ${color.ovBlue};
`;

export let StyledInput = styled.input`
  display: inline-block;
  width: 100%;
  height: 32px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.5px ${color.ovGreyLight};
  background-color: ${color.ovWhite};
  padding-left: 32px;
  padding-top: 3px;
  outline: none;
  font-size: 16px;
  &::placeholder{
    font-family: ${typo.ovFontFeature};
    font-size: 18px;
    font-weight: 300;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #95989a;
  }
`;

export const FiltersBox = styled.div`
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #777777;
  background-color: #ffffff;
  margin-top: -40px;
  font-family: ${typo.ovFontFeature};
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  position: sticky;
  top: 65px;
  padding-bottom: 20px;
`;

export const ScrollbarContainer = styled(PerfectScrollbar)`
  max-height: calc(100vh - 66px);
  overflow-y: hidden;
  padding: 0 10px 20px 0;

  .ps__rail-y{
    opacity: 1;
    .ps__thumb-y{
      background-color: ${color.ovGrey};
    }
  }
`;

export const FiltersBoxHeader = styled.div`
   border-bottom: solid 1px #707070;
   padding: 11px;
   display: grid;
   grid-template-columns: auto auto;
   @media ${bp.phoneOnly}{
    grid-template-columns: 130px 40px;
    justify-content: space-between;
  }
`;

export const FiltersBoxFooter = styled.div`
   display: none;
   @media ${bp.phoneOnly}{
    display: grid;
    grid-template-columns: auto 153px;
    justify-content: end;
    padding: 11px;
    grid-column-gap:  20px;
  }
`;

export const FiltersBoxHeaderMobile = styled.div`
  padding: 10px 0px 19px 0;
  text-align: right;
  display: none;
  grid-template-columns: 130px 130px;
  grid-column-gap: 10px;
  justify-content: end;
  @media ${bp.phoneOnly}{
    display: grid;
    justify-content: start;
  }
`;

export const FiltersBoxHeaderTitle = styled.div`
  font-size: 25px;
  color: ${color.ovBlack};
  @media ${bp.phoneOnly}{
    display: none;
  }
`;

export const FiltersBoxCancelButton = styled.button`
  font-size: 26px;
  background: transparent;
  border: none;
`;
export const FiltersBoxApplyButton = styled.button`
  font-size: 26px;
  color: ${color.ovWhite};
  background-color: ${color.ovBlue};
  border-radius: 4px;
`;

export const FiltersBoxClearButton = styled.button`
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.5px #707070;
  background-color: #ffffff;
  font-size: 22px;
  text-align: center;
  color: #777777;
  cursor: pointer;
`;

export const FiltersBoxCloseButton = styled.button`
  display: none;
  font-size: 22px;
  text-align: center;
  color: #777777;
  cursor: pointer;
  background: transparent;
  border: none;
  svg{
    width: 22px;
  }
  @media ${bp.phoneOnly}{
    display: block;
  }
`;

export const FiltersGroupBox = styled.div`
  padding: 11px;
  border-bottom: solid 1px #707070;
  @media ${bp.phoneOnly}{
    padding: 11px 50px;
  }
`;

export const FiltersGroup = styled.div``;

export const FiltersGroupTitle = styled.div`
  font-size: 18px;
  color: ${color.ovBlack};
`;