import React from 'react'

import {
  CollegeSmallCardButtonBox
} from './Styled'

const CollegeSmallCardButton = ({
  children,
  handleSubmit
}) => {
  return(
    <CollegeSmallCardButtonBox onClick={handleSubmit}>
      {children}
    </CollegeSmallCardButtonBox>
  )
}

export default CollegeSmallCardButton;