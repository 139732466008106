import styled from 'styled-components';
import { color, typo } from '../../styles';
import Bubbles from '../../assets/img/bubbles.svg';

export const ClaimHeader = styled.div`
  width: 100%;
  height: 405px;
  margin-top: -40px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: ${props => `url("${props.bgUrl}")`};
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
`;

export const ClaimHeaderTitle = styled.div`
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovWhite};
  font-family: ${typo.ovFontFeature};
  max-width: 238px;
  align-self: center;
  font-size: 35px;
  padding-top: 120px;
  margin-left: calc(50% - 238px);
  span{
    display: block;
    font-size: 28px;
  }
`;

export const ClaimBar = styled.div`
  width: 100%;
  height: 50px;
  background-color: ${color.ovBlue};
  padding: 0 50px;
  background-image: url(${Bubbles});
  background-position: calc(100% - -4px);
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: 140px;
`;

export const ClaimBarTitle = styled.h1`
  font-size: 28px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovWhite};
  font-family: ${typo.ovFontFeature};
  margin: 0;
`;

export const ClaimContent = styled.div`
  padding: 1px 50px 20px 50px;
  background-color: ${color.ovWhite};
  margin-bottom: -2rem;
  min-height: calc(100vh - 579px);
`;

export const ClaimContentTitle = styled.h2`
  font-size: 20px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
`;

export const ClaimContentParagraph = styled.p`
  font-size: 21px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlack};
  font-family: ${typo.ovFontSerif};
`;