import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import Spinner from 'react-spinkit';
import ListResult from "./ListResult";
import ListSelected from "./ListSelected";
import { StyledInput } from "./Styled";

const ListInput = ({ name, value = [], onChange, placeholder, isFetching, items }) => {
  const selected = value;
  const [ query, setQuery ] = useState(''); 
  const [ filtered, setFiltered] = useState([]);

  const handleQuery = useCallback(({ target }) => {
    const value = target.value;
    setQuery(value);

    if (value.length > 1) {
      const re = new RegExp(value, 'gi')
      setFiltered(items.filter((item) => item.label.match(re)));
    } else if (!value) {
      setFiltered([]);
    }
  });

  const handleChange = useCallback((values) => {
    onChange(name, { value: values });
  }, [selected, onChange]);

  return (
    <>
      <ListSelected selected={selected} onChange={handleChange}/>
      <StyledInput placeholder={placeholder} value={query} onChange={handleQuery}/>
      <ListResult items={filtered} selected={selected} onSelect={handleChange}/>
      {isFetching && (<Spinner name="cube-grid" />)}
    </> 
  )
};

export default ListInput;