import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import styled from 'styled-components';
import { OVButton } from '../../components';
import { Field, reduxForm } from 'redux-form';
import { color, typo, bp } from '../../styles'


let StyledInput = styled.input`
  display: inline-block;
  width: 100%;
  height: 35px;
  border: solid 3px ${color.ovBlue};
  padding-left: 10px;
  background-color: ${color.ovWhite};

  font-family: ${typo.ovFontFeature};
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlue};

  &::placeholder{
    font-family: ${typo.ovFontFeature};
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: ${color.ovGrey};
  }
`;

const PageContent = styled.div`
  grid-column: span 6;

  @media ${bp.phoneOnly} {
    grid-column: span 12;
  }
`;

const ButtonMarginTop = styled.div`
  margin-top: 10px;
`;

const ButtonMarginRight = styled.span`
  margin-right: 10px;
`;

const inputField = ({ input, label, type, meta: { touched, active, error } }) => (
  <div >
    <StyledInput {...input} placeholder={label} type={type} />
    {touched && !active && error && <div>{error}</div>}
  </div>
);

let UserEditForm = props => {
  const { handleSubmit, onCancel } = props
  return <PageContent>
    <form id='user-edit-form' onSubmit={handleSubmit}>
      <div>
        <div>
          <label htmlFor="first_name">First Name: </label>
          <Field name="first_name" component={inputField} type="text" />
        </div>
        <div>
          <label htmlFor="last_name">Last Name: </label>
          <Field name="last_name" component={inputField} type="text" />
        </div>
        <div>
          <label htmlFor="display_name">Display Name: </label>
          <Field name="display_name" component={inputField} type="text" />
        </div>
        <div>
          <label htmlFor="email">Email: </label>
          <Field name="email" component={inputField} type="email" />
        </div>
      </div>
      <ButtonMarginTop>
        <ButtonMarginRight><OVButton onClick={handleSubmit}>Submit</OVButton></ButtonMarginRight>
        <OVButton onClick={onCancel}>Cancel</OVButton>
      </ButtonMarginTop>
    </form>
  </PageContent>
}

UserEditForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};
UserEditForm = reduxForm({
  // a unique name for the form
  form: 'userEdit'
})(UserEditForm)

UserEditForm = connect(
  ({ user }) => ({
    initialValues: {
      email: user.user.email,
      display_name: user.user.display_name,
      first_name: user.user.first_name,
      last_name: user.user.last_name,
    } // pull initial values from account reducer
  }),
  { }
)(UserEditForm)

export default UserEditForm