import { color, typo, bp } from '../../styles';
import styled from 'styled-components';
import Background from '../../assets/img/claim-banner-bg.jpg';
import BackgroundMobile from '../../assets/img/claim-bg-mb.jpg';

export const ClaimBannerBox = styled.div`
  height: 129px;
  width: 100%;
  border-radius: 4px;
  background: ${color.ovBlueLight};
  margin-bottom: 17px;
  padding: 25px 19px;
  background-image: url(${Background});
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: cover;
  @media ${bp.phoneOnly} {
    height: 202px;
    padding: 15px 19px;
    background-image: url(${BackgroundMobile});
  }
`;

export const ClaimBannerTitle = styled.h2`
  color: ${color.ovWhite};
  font-size: 20px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  font-family: ${typo.ovFontFeature};
  margin: 0;
`;

export const ClaimBannerButton = styled.button`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(5, 5, 5, 0.16);
  background-color: ${color.ovYellow};
  color: ${color.ovWhite};
  font-size: 18px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  font-family: ${typo.ovFontFeature};
  padding: 5px 17px;
  margin-top: 12px;
  transition: all .1s;
  &:hover{
    background-color: ${color.ovBlue};
    transition: all .1s;
  }
`;