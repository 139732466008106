import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import { ReviewsItem, ReviewsFilters } from '../../components';
import { pageSelector } from "../../modules/college/selectors";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { requestLogin } from "../../modules/user";
import {applyFilterShowLoader, applyFilterShowLoaderSucces} from '../../modules/college/college'

import {
  ListContainer,
  PageContent,
  LoadMoreButtonContainer,
  LoadMoreButton,
  LoadMoreButtonLabel,
  ReviewsFiltersHeader,
  ReviewsFiltersHeaderTitle,
  ReviewsFiltersHeaderButton
} from './StyledList'

const applyFilters = list => filter => list && list.reduce((memo, item) => {
  let matches = [];
  let hasAnyFilter = [];
  

  // filter structure
  // { stars: {'1': false, '2': false, '3': false, '4': false, '5': true},
  //   reviewer_type: {athlete: false, student: false, fan: false},
  //   reviewer_status: {current: false, alumni: false},
  //   verified: false},

  if (filter['stars']) {
    hasAnyFilter.push(filter['stars']['1'] || filter['stars']['2'] || filter['stars']['3'] || filter['stars']['4'] || filter['stars']['5']);

    let gmatch = false;
    gmatch |= filter['stars']['1'] && item.reviews[0].rating >= 0 && item.reviews[0].rating <= 1;
    gmatch |= filter['stars']['2'] && item.reviews[0].rating > 1 && item.reviews[0].rating <= 2;
    gmatch |= filter['stars']['3'] && item.reviews[0].rating > 2 && item.reviews[0].rating <= 3;
    gmatch |= filter['stars']['4'] && item.reviews[0].rating > 3 && item.reviews[0].rating <= 4;
    gmatch |= filter['stars']['5'] && item.reviews[0].rating > 4 && item.reviews[0].rating <= 5;

    if (gmatch) matches.push(gmatch);
  }

  if (filter['reviewer_type']) {
    hasAnyFilter.push(filter['reviewer_type']['athlete'] || filter['reviewer_type']['student'] || filter['reviewer_type']['fan']);
    let gmatch = false;

    gmatch |= filter['reviewer_type']['athlete'] && item.user.role === 'athlete';
    gmatch |= filter['reviewer_type']['student'] && item.user.role === 'student';
    gmatch |= filter['reviewer_type']['fan'] && item.user.role === 'fan';

    if (gmatch) matches.push(gmatch);
  }

  if (filter['reviewer_status']) {
    hasAnyFilter.push(filter['reviewer_status']['current'] || filter['reviewer_status']['alumni']);
    let gmatch = false;

    gmatch |= filter['reviewer_status']['current'] && item.user.is_student;
    gmatch |= filter['reviewer_status']['alumni'] && item.user.is_alumni;

    if (gmatch) matches.push(gmatch);
  }

  hasAnyFilter.push(filter['verified']);
  if (filter['verified'] && item.user.is_verified) {
    matches.push(true);
  }

  const hasAnyFilterTrueCount = hasAnyFilter.filter(item => !!item).length;
  if (!hasAnyFilterTrueCount) return list;

  if (hasAnyFilterTrueCount === matches.length) {
    memo.push(item)
  }

  return memo;
}, []);

const ReviewsList = ({
  applyFilterShowLoader,
  applyFilterShowLoaderSucces,
  match,
  reviews,
  count = 0, // for !isAuthenticated
  currentUserRole,
  isFetchingReviews,
  isAuthenticated,
  onUserClick,
  paginateOnPage,
  type,
  requestLogin
}) =>{
  let [filters, setFilters] = useState({});

  const [page, setPage] = useState(1);
  let filtered = applyFilters(reviews)(filters);
  const reviewsPaginated = pageSelector(filtered, paginateOnPage, true)(page);

  if (!isAuthenticated) {
    setFilters = function () {
      return requestLogin(match.url);
    }
  }

  const initialValue = {
    stars: {},
    reviewer_type: {},
    reviewer_status: {},
    filter: {
      stars: {},
      reviewer_type: {},
      reviewer_status: {}
    }
  };
  const showLoader = () => {
    applyFilterShowLoader();
    setTimeout(() => applyFilterShowLoaderSucces(), 500)
  }
  
  return (
    <ListContainer>
      <ReviewsFiltersHeader>
        <ReviewsFiltersHeaderTitle>Review Filters</ReviewsFiltersHeaderTitle>
        <ReviewsFiltersHeaderButton onClick={()=>{setFilters(initialValue)}}>Clear All</ReviewsFiltersHeaderButton>
      </ReviewsFiltersHeader>
      <ReviewsFilters
        onShowLoader={showLoader}
        reviewsFilter={filters}
        setReviewsFilter={setFilters}
        onChange={() => { setPage(1); }}
        count={reviewsPaginated ? reviewsPaginated.length : 0}
        countTotal={isAuthenticated ? (filtered ? filtered.length : 0) : count}
        isAuthenticated={isAuthenticated}
      />
      { !isFetchingReviews && reviews.length && <PageContent>
        { reviewsPaginated.map((review,i) => {
          return (
            <ReviewsItem
              key={"reviewitem-"+i}
              id={review.ID}
              user={review.user}
              currentUserRole={currentUserRole}
              reviews={review.reviews}
              title={review.title}
              type={type}
              is_anonymous={review.is_anonymous}
              createdAt={review.created_at}
              onUserClick={onUserClick}
            />
          )
        })}
        {!isAuthenticated &&
          <LoadMoreButtonContainer>
              <LoadMoreButton onClick={() => requestLogin(match.url)}>
                <LoadMoreButtonLabel>See more reviews</LoadMoreButtonLabel>
              </LoadMoreButton>
            </LoadMoreButtonContainer>
        }
        {reviewsPaginated && filtered && reviewsPaginated.length < filtered.length && (
            <LoadMoreButtonContainer>
              <LoadMoreButton onClick={() => setPage(page + 1)}>
                <LoadMoreButtonLabel>See more reviews</LoadMoreButtonLabel>
              </LoadMoreButton>
            </LoadMoreButtonContainer>
          )}
      </PageContent> }
      { isFetchingReviews  && <PageContent>
        <Spinner fadeIn="none" name="cube-grid" />
      </PageContent> }
    </ListContainer>
  )
};

ReviewsList.propTypes = {
  reviews: PropTypes.array.isRequired,
  isFetchingReviews: PropTypes.bool.isRequired,
  onUserClick: PropTypes.func.isRequired,
  currentUserRole: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  requestLogin,
  applyFilterShowLoader,
  applyFilterShowLoaderSucces,
}, dispatch);

export default connect(null, mapDispatchToProps)(ReviewsList)
