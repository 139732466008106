import React from 'react';
import PropTypes from 'prop-types';
// Redux stuff
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withLifecycleActions } from 'react-redux-lifecycle'
import { listCoachesIfEmpty } from '../../modules/coach';
// Styles stuff
import styled from 'styled-components';
import { color, typo, bp } from '../../styles'
// Components
import { Heading } from 'rebass';
import Img from 'react-image'
import Spinner from 'react-spinkit';
import { PageContainer } from '../../components';
import SearchCoaches from '../searchCoaches';
import { Plus } from 'styled-icons/fa-solid/Plus';
import { requestLogin } from "../../modules/user";

// Styled components
const PageContent = styled.div`
  grid-column: span 12;
  background-color: ${color.ovWhite};
  border: 1px solid ${color.ovGrey};
  padding: 25px;
  margin: 10px 0;
`;
const TitleContainer = styled.div`
  grid-column: span 12;
`;
const AboutHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: .8;
  letter-spacing: normal;
  text-align: left;
  padding: 0 10px;
  @media ${bp.phoneOnly} {
    font-size: 1.8rem;
  }
`;
const AboutHr = styled.div`
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid ${color.ovGrey};
  margin: 5px 0 0;
  padding: 0;
`;
const SearchResultContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 1rem;
  @media ${bp.tabletLandUp} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
const SearchResultItem = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  cursor: pointer;
  background-color: ${color.ovWhite};
  transition: all .1s;
  &:hover{
    background-color: ${color.ovGreyLight};
    transition: all .1s;
  }
  @media ${bp.tabletLandUp} {
    grid-template-columns: 1fr 1fr;
  }
`;
const CoachHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: .8;
  letter-spacing: normal;
  text-align: center;
  padding-bottom: 1rem;
  @media ${bp.tabletLandUp} {
    text-align: left;
  }
`;
const TitleHeading = styled(Heading)`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontSerif};
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: .8;
  letter-spacing: normal;
  text-align: center;
  @media ${bp.tabletLandUp} {
    text-align: left;
  }
`;
const CoachProfilePic = styled.div`
  padding: 2rem;
`;
const CoaachProfileMeta = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
`;
const ImgStyle = {
  objectFit: 'cover',
  height: '100%',
  width: '100%',
  imageOrientation: 'from-image',
}
const AddCoachButton = styled.button`
  height: 76px;
  border-radius: 10px;
  border: solid 1px ${color.ovYellow};
  background-color: ${color.ovRed};
  transition: all .1s;
  text-align: center;
  padding: 4px 50px;
  font-size: 30px;
  color: ${color.ovWhite};
  margin-bottom: 10px;
  min-width: 210px;
  position: sticky;
  position: -webkit-sticky;
  bottom: 5vh;
  float: right;
  margin-right: -50px;
  /*transform: translate(-50%, -50%);*/
  &:hover{
    background-color: ${color.ovBlue};
    color: ${color.ovWhite};
    transition: all .1s;
  }

  @media ${bp.phoneOnly} {
    height: 48px;
  }

  &:focus {
    outline: none !important;
  }
`;
const StyledPlus = styled(Plus)`
  width: 13px;
  color: ${color.ovWhite};
  margin-right: 8px;
  position: absolute;
  right: 28px;
  top: 28px;
  ${AddCoachButton}:hover &{
    color: ${color.ovWhite};
  }
  @media ${bp.phoneOnly} {
    top: 17px;
  }
`;
const AddCoachButtonLabel = styled.p`
  font-family: ${typo.ovFontFeature};
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: center;
  padding: 0 20px 0 0;
  @media ${bp.phoneOnly} {
    font-size: 22px;
  }
`;
const LoadMoreButtonContainer = styled.div`
  grid-column: 1/-1;
  text-align: center;
  margin: 2rem 0;
`;
const LoadMoreButton = styled.button`
  height: 59px;
  border-radius: 10px;
  border: solid 1px ${color.ovBlue};
  background-color: ${color.ovWhite};
  transition: all .1s;
  text-align: center;
  padding: 8px 30px;
  position: relative;
  margin: 0 0 17px 0;
  &:hover{
    background-color: ${color.ovBlue};
    color: ${color.ovWhite};
    transition: all .1s;
  }
`;

const LoadMoreButtonLabel = styled.p`
  font-family: ${typo.ovFontFeature};
  font-size: 25px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: center;
  @media ${bp.phoneOnly} {
    font-size: 22px;
  }
`;
// end of Styled Components

const AdminCoaches = ({
  match,
  isAuthenticated,
  isFetchingCoaches,
  requestLogin,
  coaches,
  push
}) =>{

  if (!isAuthenticated){
    return requestLogin(match.url)
  }

  return (
    <div>
      { isFetchingCoaches &&
        <PageContainer>
          <PageContent>
            <Spinner fadeIn="none" name="cube-grid" />
          </PageContent>
        </PageContainer>
      }
      { !isFetchingCoaches &&
        <PageContainer>

          <PageContent>
            <SearchCoaches />
          </PageContent>

          <PageContent>
            <TitleContainer>
              <AboutHeading>Search results</AboutHeading>
              <AboutHr />
            </TitleContainer>
            <div>
              <SearchResultContainer>

                { coaches && coaches.map((coach, i)=>{
                  return(
                    <SearchResultItem key={i} onClick={() => push('/admin/coaches/' + coach.ID)}>
                      <CoachProfilePic>
                        <Img
                          key={'img-sm0'}
                          style={ImgStyle}
                          src={['https://i.imgur.com/bb2ETd2.png?1']}
                          loader={<Spinner fadeIn="none" name="cube-grid" />}
                        />
                      </CoachProfilePic>
                      <CoaachProfileMeta>
                        <CoachHeading>{coach.display_name}</CoachHeading>
                        <TitleHeading>Coach title</TitleHeading>
                      </CoaachProfileMeta>
                    </SearchResultItem>
                    )
                  })}


                <LoadMoreButtonContainer>
                  <LoadMoreButton>
                    <LoadMoreButtonLabel>Load More</LoadMoreButtonLabel>
                  </LoadMoreButton>
                </LoadMoreButtonContainer>

              </SearchResultContainer>
            </div>
            <AddCoachButton onClick={() => push('/admin/coaches/new')}>
              <AddCoachButtonLabel>Add coach <StyledPlus/></AddCoachButtonLabel>
            </AddCoachButton>
          </PageContent>
        </PageContainer>
      }
    </div>
  )
}

AdminCoaches.propTypes = {
  listCoachesIfEmpty: PropTypes.func.isRequired
};

const mapStateToProps = ({ user, coach }) => ({
  isAuthenticated: user.isAuthenticated,
  isFetchingCoaches: coach.isFetchingCoaches,
  coaches: coach.coaches
})

const mapDispatchToProps = dispatch => bindActionCreators({
  listCoachesIfEmpty,
  push,
  requestLogin
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withLifecycleActions({ componentWillMount: [listCoachesIfEmpty]})(AdminCoaches))
