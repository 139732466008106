import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import Spinner from 'react-spinkit';
import Select from "../Select";
import ListSelected from "./ListSelected";

const SelectMulti = ({ name, value = [], onChange, placeholder, isSearchable, isFetching, options }) => {
  const selected = value;

  const handleChange = useCallback((values) => {
    onChange(name, { value: values });
  }, [selected, onChange]);

  const handleAdd = useCallback((name, value) => {
    if (selected && selected.find((item) => item.value == value.value)) return;

    const values = [...selected, value];
    onChange(name, { value: values });
  }, [selected, onChange]);
  return (
    <>
      <ListSelected selected={selected} onChange={handleChange}/>
      <Select
        name={name}
        placeholder={placeholder}
        isSearchable={isSearchable}
        onChange={handleAdd}
        options={options}
      />
      {isFetching && (<Spinner name="cube-grid" />)}
    </> 
  )
};

export default SelectMulti;