export const TAG_TITLES_MAP = {
  "campus buildings": "Campus buildings",
  "fitness": "Campus buildings",
  "lecture halls/classrooms": "Campus buildings",
  "leisure/recreation": "Campus buildings",
  "places to study": "Campus buildings",
  "campus": "landmarks",
  "football facilities": "Football facilities",
  "local attractions": "Off-campus Local attractions",
  "action/adventures": "Off-campus Local attractions",
  "concerts/shows": "Off-campus Local attractions",
  "museums": "Off-campus Local attractions",
  "nature/parks": "Off-campus Local attractions",
  "tours": "Off-campus Local attractions",
  "lodging": "Off-campus Lodging",
  "bed & breakfasts": "Off-campus Lodging",
  "hotels": "Off-campus Lodging",
  "motels": "Off-campus Lodging",
  "nightlife": "Nightlife",
  "over-21 nightlife": "Nightlife",
  "under-21 nightlife": "Nightlife",
  "off-campus": "Off-campus",
  "african": "Off-campus food/drink",
  "american": "Off-campus food/drink",
  "asian": "Off-campus food/drink",
  "bar": "Off-campus food/drink",
  "barbecue": "Off-campus food/drink",
  "breakfast": "Off-campus food/drink",
  "buffet": "Off-campus food/drink",
  "cafe": "Off-campus food/drink",
  "chinese": "Off-campus food/drink",
  "deli": "Off-campus food/drink",
  "dessert": "Off-campus food/drink",
  "european": "Off-campus food/drink",
  "fast food": "Off-campus food/drink",
  "fine dining": "Off-campus food/drink",
  "indian": "Off-campus food/drink",
  "italian": "Off-campus food/drink",
  "mediterranean": "Off-campus food/drink",
  "mexican": "Off-campus food/drink",
  "middle eastern": "Off-campus food/drink",
  "pizza": "Off-campus food/drink",
  "restaurant": "Off-campus food/drink",
  "seafood": "Off-campus food/drink",
  "south american": "Off-campus food/drink",
  "steakhouse": "Off-campus food/drink",
  "tex-mex": "Off-campus food/drink",
  "on-campus food/drink": "On-campus food/drink",
  "food/drink": "On-campus food/drink",
  "dining hall": "On-campus food/drink",
  "housing": "on-campus housing",
  "houses": "Off-campus housing",
  "on-campus": "On-campus",
  "apartments": "On-campus housing",
  "condominiums": "On-campus housing",
  "dormitories": "On-campus housing",
  "suites": "On-campus housing",
  "services": "Services",
  "shops": "Shops",
  "traditions": "Traditions",
  "college traditions": "Traditions",
  "football traditions": "Traditions",
};

export const TAG_ICON_MAP = {
  "on-campus": "on_campus_location",
  "off-campus": "off_campus_location",
  "campus buildings": "on_campus_location",
  "landmarks": "on_campus_location",
  "football facilities": "on_campus_location",
  "local attractions": "off_campus_location",
  "on-campus local attractions": "on_campus_location",
  "off-campus local attractions": "off_campus_location",
  "lodging": "off_campus_lodging",
  "on-campus lodging": "on_campus_lodging",
  "off-campus lodging": "off_campus_lodging",
  "nightlife": "off_campus_location",
  "food/drink": "on_campus_dining",
  "housing": "on_campus_housing",
  "on-campus food/drink": "on_campus_dining",
  "off-campus food/drink": "off_campus_dining",
  "on-campus housing": "on_campus_housing",
  "off-campus housing": "off_campus_housing",
  "services": "off_campus_location",
  "shops": "off_campus_location",
  "traditions": "on_campus_location"
};

export const ICON_PRIORITY_MAP = {
  "on_campus_location": "7",
  "off_campus_location": "8",
  "on_campus_dining": "5",
  "off_campus_dining": "3",
  "on_campus_housing": "1",
  "off_campus_housing": "1",
  "on_campus_lodging": "2",
  "off_campus_lodging": "2",
};

export const mapTagstoIcon = tags => {
  if (!tags || !tags.length) return 'on_campus_location';

  /**
   * remove parents if sub cats presented
   */
  // get list of the parent ids that are present on tags in the list
  const childrenParentsIds = tags.filter(tag => tag.parent_id !== 0).map(tag => tag.parent_id);
  let filteredTags = tags;
  // if there are tags that have parent ids
  if (childrenParentsIds.length) {
    // filter the list to only include tags that are subtags (have a parent id) or
    // tags that are parents for which there are no other tags in the list that have it
    // as a parent tag
    filteredTags = tags.filter(tag => tag.parent_id !== 0 || (tag.parent_id === 0 && childrenParentsIds.indexOf(tag.ID) < 0));
  }

  let tagTitleMapped = filteredTags.map(tag => {
    let t = TAG_TITLES_MAP[tag.title.toLowerCase()];
      if (typeof t === "string") return t.toLowerCase();
      return "";
  });

  // if there is on-campus or off-campus tag turn all tags to this orientation
  const tagOrientation = tagTitleMapped.filter(tag => tag === "on-campus" || tag === "off-campus");
  // there cannot be two orientations, so choose the first one
  if (tagOrientation.length) {
    tagTitleMapped = tagTitleMapped
      .filter(tag => tag !== "on-campus" && tag !== "off-campus" && tag !== "")
      .map(tag => tag.replace("on-campus", tagOrientation[0]).replace("off-campus", tagOrientation[0]));
  }

  let tagMapped = tagTitleMapped.map(tag => TAG_ICON_MAP[tag]);

  const iconName = tagMapped.reduce((acumulator, tag) => {
    const tagValue = { tag, value: ICON_PRIORITY_MAP[tag] };

    if (!tag || acumulator.value < tagValue.value) {
      return acumulator
    } else { acumulator = tagValue }

    return acumulator;
  }, {tag: tagOrientation.length && tagOrientation[0] === "on-campus" ? "on_campus_location" : "off_campus_location", value: "8"});

  return iconName;
};
