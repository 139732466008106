import React, { Component } from "react";
import { push } from "connected-react-router";
// Redux stuffs
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createProfile} from "../../modules/wizard";
// Components
import Modal from '../../components/modal';
import CloseButton from '../../components/CloseButton';
import BackButton from '../../components/BackButton';
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'

import {
  GridContainer,
  CloseButtonContainer,
  BackButtonContainer,
  StepContainer,
  StepIndicatorContainer,
  StepIndicatorLabel,
  StepIndicator,
  StepDivider
} from './Styled'

class StudentReg extends Component {
  constructor(props) {
    super(props)
    this.nextStep = this.nextStep.bind(this)
    this.prevStep = this.prevStep.bind(this)
    this.state = {
      currentStep: 1, // Default is Step 1
    }
  }

  nextStep() {
    this.setState({ currentStep: this.state.currentStep + 1 })
  }

  prevStep() {
    this.setState({ currentStep: this.state.currentStep - 1 })
  }

  render() {
    const { createProfile } = this.props
    return(
      <Modal>
        <CloseButtonContainer><CloseButton onClick={this.props.backLogin}/></CloseButtonContainer>
        {this.state.currentStep !== 1 && 
          <BackButtonContainer><BackButton onClick={this.prevStep}/></BackButtonContainer>
        }
          <GridContainer>
            <div>
              {/*  */}
              <StepContainer>
                <StepIndicatorContainer>
                  <StepIndicatorLabel>College</StepIndicatorLabel>
                  <StepIndicator className={this.state.currentStep === 1 && 'active'}/>
                </StepIndicatorContainer>
                <StepDivider/>
                <StepIndicatorContainer>
                  <StepIndicatorLabel>Attendance</StepIndicatorLabel>
                  <StepIndicator className={this.state.currentStep === 2 && 'active'}/>
                </StepIndicatorContainer>
                <StepDivider/>
                <StepIndicatorContainer>
                  <StepIndicatorLabel>Academics</StepIndicatorLabel>
                  <StepIndicator className={this.state.currentStep === 3 && 'active'}/>
                </StepIndicatorContainer>
                <StepDivider/>
                <StepIndicatorContainer>
                  <StepIndicatorLabel>Favorite Team</StepIndicatorLabel>
                  <StepIndicator className={this.state.currentStep === 4 && 'active'}/>
                </StepIndicatorContainer>
              </StepContainer>
              {/* FORM */}
              <Step1 currentStep={this.state.currentStep} onSubmit={this.nextStep}/>
              <Step2 currentStep={this.state.currentStep} onSubmit={this.nextStep}/>
              <Step3 currentStep={this.state.currentStep} onSubmit={this.nextStep}/>
              <Step4 currentStep={this.state.currentStep} onSubmit={createProfile}/>
            </div>
          </GridContainer>
        </Modal>
      )
  }
}

const mapStateToProps = ({ user }) => ({
  isFetching: user.isFetching,
  errorMessage: user.errorMessage
})


const mapDispatchToProps = dispatch => bindActionCreators({
  createProfile,
  push,
  backLogin: () => push("/login")
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(StudentReg)
