import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import StyledInput from '../../components/styledInput';

import {
  LogInButton
} from './Styled';

const inputField = ({ input, label, type, meta: { touched, active, error } }) => (
  <div >
    <StyledInput {...input} placeholder={label} type={type} />
    {touched && !active && error && <div>{error}</div>}
  </div>
);

let ResetPasswordForm = props => {
  const { handleSubmit } = props;
  return <form id='college-details-edit-form' onSubmit={handleSubmit}>
    <div>
      <div>
        <Field name="password" label="New Password" component={inputField} type="password" />
      </div>
      <div>
        <Field name="confirm_password" label="Confirm new Password" component={inputField} type="password" />
      </div>
    </div>
    <LogInButton type="submit" >Submit password</LogInButton>
  </form>
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

ResetPasswordForm = reduxForm({
  // a unique name for the form
  form: 'reset-password'
})(ResetPasswordForm);

export default ResetPasswordForm;
