import styled from 'styled-components';
import { color, bp, typo } from '../../../../styles';

export const CoachHistoryContainer = styled.div`
  @media ${bp.phoneOnly}{
    display:  ${({ isBottom }) => isBottom ? 'flex' : 'none'};
  }
  
  @media ${bp.tabletPortUp}{
    display:  ${({ isBottom }) => !isBottom ? 'flex' : 'none'};
  }

  flex-direction: column;
  width: 100%;

  a[data-action] {
    line-height: 21px;
    font-size: 17px;
    font-weight: 600;
    font-stretch: condensed;
    color: ${color.ovBlue};
    cursor: pointer;

    ${({ isBottom, isExpanded }) => isBottom && `
      @media ${bp.phoneOnly}{
        padding-top: 14px
        display: block;
        width: 100;
        text-align: center;

        ${!isExpanded && `
          margin-top: 11px;
          margin-left: -14px;
          margin-right: -14px;
          border-top: 1px solid #707070;
        `}
      }
    `};
  }

  > div {

  }
`;

export const HisotryItem = styled.div`
  display: flex;
  font-size: 14px;
  font-style: normal;
  line-height: 1.14;
  color: #707070;

  @media ${bp.phoneOnly}{
    color: ${color.ovBlack};
  } 

  :first-child {
    margin-top: 10px;
  }

  > div {
    display: flex;
    label {
      font-weight: 600;
      display: none;
  
      @media ${bp.phoneOnly}{
        display: inline-block;
      }
    }
  
    span {
      :after {
        display: inline-block;
        content: "·";
        padding: 0 5px;
      }
    }

    :last-child {
      span:after {
        display: none;
      }
    }
  }

  @media ${bp.phoneOnly}{
    display: block;
    padding: 8px 14px;
    margin-left: -14px;
    margin-right: -14px;
    border-bottom: 1px solid #707070;
    label, span {
      font-size: 15px !important;
    }

    span {
      font-family: Marbach;
      padding-left: 10px;

      :after {
        display: none !important;
      }
    }
  }
`;
