// eslint-disable-next-line
import React from 'react';
import { bindActionCreators } from 'redux';
import { push, goBack } from 'connected-react-router';
import { connect } from 'react-redux';
import AttractionContent from './Content';

import {
  toggleLightboxOpen,
  toggleManage,
  deleteContent,
} from '../../modules/content';

import { selectAttractionContentIndex } from "../../modules/attraction/actions";
import {
  getUserByAttractionContentId,
  selectNextAttractionContentIndex,
  selectPrevAttractionContentIndex
} from "../../modules/attraction/attraction";

const mapStateToProps = ({ user, content, attraction }) => ({
  contents: attraction.contents,
  lightboxOpen: content.lightboxOpen,
  selectedContentIndex: attraction.selectedContentIndex,
  isManaging: content.isManaging,
  user: content.user,
  isFetching: attraction.isFetchingContent,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleLightboxOpen,
  toggleManage,
  selectNextIndex: selectNextAttractionContentIndex,
  selectPrevIndex: selectPrevAttractionContentIndex,
  selectContentIndex: selectAttractionContentIndex,
  push,
  goBack,
  deleteContent,
  getUserId: getUserByAttractionContentId,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AttractionContent);
