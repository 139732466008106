import { apiEndpoint } from "../config/app";
import createRestApiClient from "../utils/createRestApiClient";

export default () => {
  // Adds token to client if it is passed in
  const token = localStorage.getItem("ovSessToken");
  const config = token
    ? {
        baseURL: apiEndpoint,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    : { baseURL: apiEndpoint };

  const client = createRestApiClient().withConfig(config);
  return {
    finishRegister: data =>
      client.request({
        method: "POST",
        url: "/a/user/profile",
        data
      })
  };
};
