import React from 'react';
import styled from 'styled-components';
import { X } from 'styled-icons/feather/X';
import { color, bp } from '../styles';


const FloatRightCloseButton = styled(X)`
  position: relative;
  display: inline;
  right: 4px;
  top: 5px;
  color: ${color.ovBlue};
  width: 25px;
  cursor: pointer;
  z-index: 1;
  float: right;

  @media ${bp.phoneOnly} {
    margin-left: -25px;
    right: -25px;
    top: -125px;
  }
`;

export default ({ onClick }) => <FloatRightCloseButton onClick={onClick} />;