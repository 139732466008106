// eslint-disable-next-line
import React from 'react';
import { bindActionCreators } from 'redux'; 
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { listUsers, requestLogin, toggleUserVerified } from '../../modules/user';
import { baseSelector, userListSelector, isFetchingListSelector, filterSelector, pageSelector } from '../../modules/user/selectors';
import AdminUserList from './AdminUserList';

const mapStateToProps = (state) => ({
  isAuthenticated: baseSelector(state).isAuthenticated,
  isFetching: isFetchingListSelector(state),
  users: userListSelector(state),

  filter: filterSelector(userListSelector(state)),
  paginate: pageSelector(userListSelector(state)),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  listUsers,
  toggleUserVerified,
  push,
  requestLogin
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserList);