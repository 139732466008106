import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { getAllAttractionsIfEmpty, getAttractionsTags, deleteAttraction } from '../../modules/attraction/attraction';
import { requestLogin, getUserIfEmpty } from "../../modules/user";
import AdminAttractions from './AdminAttractions'

const mapStateToProps = ({ user, attraction}) => ({
  isAuthenticated: user.isAuthenticated,
  attractions: attraction.allAttractions,
  role: user.user.role,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  push,
  requestLogin,
  deleteAttraction,
  getAllAttractionsIfEmpty,
  getAttractionsTags,
  getUserIfEmpty,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AdminAttractions);
