import React from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { Container12 } from '../../components';
import { color, typo, bp } from '../../styles';

const InputContainer = styled.div`
  margin-bottom: 18px;
`;

let StyledInput = styled.input`
  display: inline-block;
  width: 100%;
  height: 35px;
  border: solid 3px ${color.ovBlue};
  padding-left: 13px;
  background-color: ${color.ovWhite};

  font-family: ${typo.ovFontFeature};
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlue};

  &::placeholder{
    font-family: ${typo.ovFontFeature};
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: ${color.ovGrey};
  }
`;

const FormContainer = styled.div`

/*  @media ${bp.tabletLandUp} {
    grid-column: span 4;
  }

  @media ${bp.phoneOnly} {
    grid-column: span 12;
  }*/
  grid-column: span 12;
`;

const inputField = ({ input, label, type, meta: { touched, active, error } }) => (
  <InputContainer>
    <StyledInput {...input} placeholder={label} type={type} />
    {touched && !active && error && <div>{error}</div>}
  </InputContainer>
);

let ContributeForm = ({ handleSubmit, pristine }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Container12>
        <FormContainer>
          <Field
            type="text"
            name="title"
            component={inputField}
            label="Title" />
          <Field
            type="text"
            name="description"
            component={inputField}
            label="Description" />
        </FormContainer>
      </Container12>
    </form>
  );
};

ContributeForm = reduxForm({
  // a unique name for the form
  form: 'contribute'
})(ContributeForm)

export default ContributeForm;
