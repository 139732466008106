import React from 'react';
import styled from 'styled-components';

const P = styled.p`
  img {
    max-width: 100%;
  }
`;

const createMarkup = (markup) => ({ __html: markup });

const Interpolate = ({ body }) => (
  <P dangerouslySetInnerHTML={createMarkup(body)} />
);

export default Interpolate;