import React from 'react';
import { Field, reduxForm } from 'redux-form';

import {
  InputContainer,
  StyledSearchIcon,
  StyledInput
} from './Styled'

const inputField = ({ input, label, type, meta: { touched, active, error } }) => (
  <InputContainer>
    <StyledInput {...input} placeholder={label} type={type} autoFocus/>
    <StyledSearchIcon />
    {touched && !active && error && <div>{error}</div>}
  </InputContainer>
);

let CollegeListFilterForm = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
      type="text"
      name="text"
      component={inputField}
      label="Search" />
    </form>
  );
};

CollegeListFilterForm = reduxForm({
  // a unique name for the form
  form: 'collegeListFilter'
})(CollegeListFilterForm)

export default CollegeListFilterForm;
