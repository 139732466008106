
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Img from 'react-image';

// Components
import Spinner from 'react-spinkit';
import { PageContainer } from '../../components';
import SearchBox from '../../components/SearchBox';

import {
  PageContent,
  TitleContainer,
  AboutHeading,
  AboutHr,
  SearchResultContainer,
  SearchResultItem,
  UserHeading,
  TitleHeading,
  UserProfilePic,
  UserProfileMeta,
  ImgStyle,
  LoadMoreButtonContainer,
  LoadMoreButton,
  LoadMoreButtonLabel,
  UserInfo,
  UserProfileVerified,
  StyledCheck
} from './Styled';

const AdminUserList = ({
  isAuthenticated,
  isFetching,
  requestLogin,
  listUsers,
  toggleUserVerified,
  users,
  filter,
  paginate,
  match
}) => {

  useEffect(() => {
    if (!isAuthenticated) {
      return requestLogin(match.url);
    }

    if (!users || !users.length) listUsers();
  }, [users]);

  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);

  const filtered = query
    ? filter(query)
    : paginate(page);

  return (
    <div>
      {isFetching
        ? (
          <PageContainer>
            <PageContent>
              <Spinner fadeIn="none" name="cube-grid" />
            </PageContent>
          </PageContainer>
        )

        : (
          <PageContainer>

            <PageContent>
              <SearchBox
                placeholder='Search users by name, display name or email'
                onQuery={query => (setQuery(query))}
              />
            </PageContent>

            <PageContent>
              <TitleContainer>
                <AboutHeading>Search results</AboutHeading>
                <TitleHeading>Verified</TitleHeading>
                <AboutHr />
              </TitleContainer>
              <div>
                <SearchResultContainer>

                  {filtered && filtered.map((user, i) => {
                    return (
                      <SearchResultItem key={i}>
                        <UserProfilePic>
                          <Img
                            key={'img-sm0'}
                            style={ImgStyle}
                            src={[user.avatar, 'https://i.imgur.com/bb2ETd2.png?1']}
                            loader={<Spinner fadeIn="none" name="cube-grid" />}
                          />
                        </UserProfilePic>
                        <UserProfileMeta>
                          <UserHeading>{user.first_name} {user.last_name}</UserHeading>
                          <UserInfo>
                            <TitleHeading>ID {user.ID} | </TitleHeading>
                            <TitleHeading>{user.role} | </TitleHeading>
                            <TitleHeading>{user.email}</TitleHeading>
                          </UserInfo>
                        </UserProfileMeta>
                        <UserProfileVerified onClick={() => toggleUserVerified(user.ID)}>
                          <StyledCheck verified={user.is_verified ? 1 : 0} />
                        </UserProfileVerified>
                      </SearchResultItem>
                    )
                  })}

                  {(users && !query && users.length > filtered.length) && (
                    <LoadMoreButtonContainer>
                      <LoadMoreButton onClick={() => setPage(page + 1)}>
                        <LoadMoreButtonLabel>Load More</LoadMoreButtonLabel>
                      </LoadMoreButton>
                    </LoadMoreButtonContainer>
                  )}

                </SearchResultContainer>
              </div>
            </PageContent>
          </PageContainer>
        )
      }
    </div>
  )
}

AdminUserList.propTypes = {
  filter: PropTypes.func.isRequired
};

export default AdminUserList;
