import styled from 'styled-components';
import { Heading } from 'rebass';
import { color, typo, bp } from '../../styles'

export const ProfileHeading = styled(Heading)`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};

  font-size: 40px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.78;
  letter-spacing: normal;
  text-align: center;

  margin-bottom: 2rem;
`;

export const PageContent = styled.div`
  grid-column: span 12;
  background-color: ${color.ovWhite};
  border: 1px solid ${color.ovGrey};
  padding: 25px;
  margin: 10px 0;
`;

export const TwelvePartPageContent = styled.div`
  grid-column: span 12;
  @media ${bp.tabletLandUp} {
    grid-column: span 12;
  }
`;

// About and Coaches display

export const TitleContainer = styled.div`
  grid-column: span 12;
`;

export const AboutHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: .8;
  letter-spacing: normal;
  text-align: left;
  padding: 0 10px;
  @media ${bp.phoneOnly} {
    font-size: 1.8rem;
  }
`;

export const AboutHr = styled.div`
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid ${color.ovGrey};
  margin: 5px 0 0;
  padding: 0;
`;

export const CoachContainer = styled.div`
  display:block;
`;

export const CoachItem = styled.div`
  display:grid;
  padding: 0rem 1rem 0.2rem;
  border-radius: .2rem;
  grid-template-columns: 20% 30% 50%;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  p{
    padding: 0 10px 0 0;
    margin: 0 0 5px 0;
  }
  @media ${bp.phoneOnly} {
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    border-bottom: 1px solid ${color.ovGrey};
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }
`;

export const HiddenSpan = styled.span`
  display: none;
  @media ${bp.phoneOnly} {
    display: inline;
    color: ${color.ovBlue};
    font-size: 20px;
    color: ${color.ovBlack};
    font-family: ${typo.ovFontFeature};
    line-height: 1;
  }
`;

export const CoachTable = styled(CoachItem)`
  cursor: default;
  color: ${color.ovBlue};
  font-size: 20px;
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
  line-height: 1;
  margin-bottom: 5px;
  &:hover{
    background: transparent;
  }
  @media ${bp.phoneOnly} {
    display: none;
  }
`;

export const CoachLabel = styled.span`
  display: inline;
  color: ${color.ovBlack};
  font-family: ${typo.ovFontSerif};
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
`;

export const ProfileSubHeading = styled(CoachLabel)`
  text-align: center;
  display: block;
`;