import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import Post from '../../../components/Blog/Post';
import { Grid } from 'react-bootstrap';

const PostDetail = ({
  match,
  getPost,
  post,
}) => {
  const { params: { post_slug: slug} } = match;

  useEffect(() => {
    getPost(slug)
  }, [post]);

  return (
    <Grid>
      {post && (
        <Post
          {...post}
        />
      )}
    </Grid>
  );
};

export default withRouter(PostDetail);
