import { cmsService } from '../../services';
import {
  listCategoriesRequest,
  listCategoriesSuccess,
  listCategoriesFailure,

  listPostsRequest,
  listPostsSuccess,
  listPostsFailure,

  getPostRequest,
  getPostSuccess,
  getPostFailure,
} from './actions';

export const listCategories = () => {
  return async (dispatch) => {
    dispatch(listCategoriesRequest())

    try {
      const { data: { data } } = await cmsService.listCategories();
      return dispatch(listCategoriesSuccess(data));

    } catch (e) {
      return dispatch(listCategoriesFailure(e));
    }
  }
};


export const listPosts = (page, cagetory='') => {
  return async (dispatch) => {
    dispatch(listPostsRequest(page, cagetory))

    try {
      const { data: { data } } = await cmsService.listPosts(page, cagetory.replace(/#/, ''));
      return dispatch(listPostsSuccess(data));

    } catch (e) {
      return dispatch(listPostsFailure(e));
    }
  }
};

export const getPost = (slug) => {
  return async (dispatch, getState) => {
    const { blog: { postSlug } } = getState();
    if (slug === postSlug) return;

    dispatch(getPostRequest(slug))

    try {
      const { data: { data } } = await cmsService.getPost(slug);
      return dispatch(getPostSuccess(data));

    } catch (e) {
      return dispatch(getPostFailure(e));
    }
  }
}