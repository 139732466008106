import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import StyledInput from '../../components/styledInput';

import {
  LogInButton
} from './Styled';

const inputField = ({ input, label, type, meta: { touched, active, error } }) => (
  <div >
    <StyledInput {...input} placeholder={label} type={type} />
    {touched && !active && error && <div>{error}</div>}
  </div>
);

let ForgotPasswordForm = props => {
  const { handleSubmit } = props;
  return <form id='college-details-edit-form' onSubmit={handleSubmit}>
    <div>
      <div>
        <Field name="email" label="Email address" component={inputField} type="email" />
      </div>
    </div>
    <LogInButton type="submit" >Send link</LogInButton>
  </form>
};

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

ForgotPasswordForm = reduxForm({
  // a unique name for the form
  form: 'forgot-password'
})(ForgotPasswordForm);

export default ForgotPasswordForm;
