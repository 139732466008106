import { apiEndpoint } from '../config/app';
import createRestApiClient from '../utils/createRestApiClient';

export default () => {
  // Adds token to client if it is passed in
  const token = localStorage.getItem('ovSessToken');
  const config = token ? { 
    baseURL: apiEndpoint, 
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/x-www-form-urlencoded'
    } 
  } : { baseURL: apiEndpoint };
  const client = createRestApiClient().withConfig(config);
  return {
    getUser: () => {
      return client.request({
        method: 'GET',
        url: '/a/user'
      })
    },
    getUserProfile: () => {
      return client.request({
        method: 'GET',
        url: '/a/user/profile'
      })

      // Mock top Schools... REMOVE THIS 💣💣💣💣💣💣💣
      .then(response => {

        if (response.data && response.data.data && !response.data.data.top_schools) {
          response.data.data = {
            ...response.data.data,
            top_schools: [
              { college_refer: 83, order: 1 },
              { college_refer: 94, order: 2 },
            ]
          };
        }
        return response;
      })
    },
    editUser: (data) => client.request({
      method: 'PUT',
      url: '/a/user',
      data
    }),
    editUserProfile: (data) => client.request({
      method: 'PUT',
      url: '/a/user/profile',
      data
    }),

    /**
      top_schools: [
      {
        id: 1,
        college_refer: 1,
        order: 1
      }
      ],
      is_private_top_schools: false
     */
    updateProfileTopSchools: ({ top_schools, is_private_top_schools = true}) => client.request({
      method: 'POST',
      url: '/a/top_schools',
      data: { top_schools, is_private_top_schools}
    }),

    setUserAvatar: (contentId) => client.request({
      method: 'PUT',
      url: `/a/content/${contentId}/avatar`,
    }),

    setUserVerified: (ID, is_verified) => client.request({
      method: 'PUT',
      url: `/aa/user/${ID}`,
      data: { is_verified },
    }),

    listUsers: () => client.request({
      method: 'GET',
      url: '/aa/users'
    }),

    getUserReviews: () => {
      return client.request({
        method: 'GET',
        url: '/a/user/reviews'
      })
    },
    getUserContents: () => {
      return client.request({
        method: 'GET',
        url: '/a/user/contents'
      })
    },
  };
};
