import styled from 'styled-components';
import { color, typo, bp } from '../../../../styles';
import { Heading } from 'rebass';

export const PostItem = styled.div`
	display: grid;
	grid-template-columns: 229px auto;
 	grid-template-rows: 1fr;
  padding: 0 0 31px 0;
  max-width: 646px;
  margin: 0 auto;
  @media ${bp.phoneOnly} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    border-bottom: solid 0.5px #707070;
  }
`;

export const PostItemData = styled.div`
  padding: 0 0 0 27px;
  @media ${bp.phoneOnly} {
    padding:0 27px; 
  }
`;

export const FeaturedImage = styled.div`
  width: 100%;
  height: 100%;
  @media ${bp.phoneOnly} {
    padding:0 27px 27px 27px; 
  }
  img {
    width: 100%;
  }
`;

export const Title = styled(Heading)`
	font-size: 30px;
	font-weight: 600;
	font-style: normal;
	font-stretch: condensed;
	line-height: 0.83;
	letter-spacing: normal;
	text-align: left;
	font-family: ${typo.ovFontFeature};
	color: ${color.ovBlack};
  padding-bottom: 5px;
`;

export const Author = styled.p`
	font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  font-family: ${typo.ovFontFeature};
	color: ${color.ovBlack};
`;

export const PostButton = styled.div`
  width: 100px;
  height: 23px;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(${color.ovBlack}, 0.16);
  border: solid 1px ${color.ovBlack};
  background-color: ${color.ovWhite};
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  font-family: ${typo.ovFontFeature};
	color: ${color.ovBlack};
  margin: 0 auto;
  cursor: pointer;
  &:hover{
    background-color: ${color.ovBlack};
    color: ${color.ovWhite};
  }
`;

export const Excerpt = styled.div`
  font-family: Marbach;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  font-family: ${typo.ovFontSerif};
  color: ${color.ovBlack};
  padding-bottom: 27px;
`;
