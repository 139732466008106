import { apiEndpoint } from '../config/app';
import createRestApiClient from '../utils/createRestApiClient';

export default () => {
  const config = {
    baseURL: apiEndpoint,
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // }
  };
  const client = createRestApiClient().withConfig(config);
  return {
    register: (role,username, password, first_name, last_name, month, day, year, notificationsEnabled) => {
      return client.request({
        method: 'POST',
        url: '/api/register',
        data: { role,username, password, first_name, last_name, month, day, year, notificationsEnabled }
      })
    },
    login: (username, password) => {
      return client.request({
        method: 'POST',
        url: '/api/login',
        data: { username, password }
      })
    },
    forgotPassword: (email) => {
      return client.request({
        method: 'GET',
        url: `/api/forget-password?email=${email}`
      })
    },
    forgotPasswordValidateToken: (token) => {
      return client.request({
        method: 'GET',
        url: `/api/check-reset-password-token?token=${token}`
      })
    },
    forgotPasswordResetPassword: (password, token) => {
      return client.request({
        method: 'POST',
        url: '/api/reset-password',
        data: { password, token }
      })
    }
  };
};
