import React, { useEffect, Fragment, useState } from 'react';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';

// Components
import Spinner from 'react-spinkit';
import { PageContainer } from '../../../components';
import CollegeList from '../../../components/CollegeList'

import { PageContent, BigTitleGrey, DetailsSubHeading, DetailsSubHeadingLight, FullWithContent, LoadMoreButtonContainer, LoadMoreButton, LoadMoreButtonLabel } from '../Styled';

const AverageGraduationSuccess = ({ rate, black, white }) => (
  <div>
    <DetailsSubHeading as="h3">Black Graduation Success Rate (GSR): {black.rate}% </DetailsSubHeading>
    <DetailsSubHeadingLight as="h3">Overall GSR: {rate}% | White GSR: {white.rate}% </DetailsSubHeadingLight>
  </div>
);

const CollegeBlackGraduationSuccess = ({ isFetching, listCollegesIfEmpty, paginate, count, push }) => {

  const [page, setPage] = useState(1);
  const colleges = paginate(page);
  
  useEffect(() => {
    if (!colleges || !colleges.length) listCollegesIfEmpty();
  }, [colleges]);

  return (
    <div>
      <Helmet>
        <title>HIGHEST Black Graduation Success Rates - OfficialVisit</title>
        <meta name="description" content="Discover the college football programs with the highest graduation success rates (GSR) among black student-athletes. The GSR is an alternative measure of graduation rates that accounts for high athlete mobility." />
      </Helmet>
      {isFetching
        ? (
          <PageContainer>
            <PageContent>
              <Spinner fadeIn="none" name="cube-grid" />
            </PageContent>
          </PageContainer>
        )
        : (
          <Fragment>
            <FullWithContent>
              <BigTitleGrey bigTitleLabel="Highest Black Graduation Success Rates" />
              <DetailsSubHeading as="h2">
                Football programs are listed from highest to lowest Graduation Success Rates among black football players from the 2011 freshman cohort.
                Graduation Success Rates were created as an alternative to normal Federal Graduation Rates to account for typical college athlete mobility.
                The Federal Graduation Success Rate does not account for students who leave an institution in good academic standing (i.e. transfer to another
                college for more playing time, different major, or to go pro) or students who transfer into a college and successfully graduate.
              </DetailsSubHeading>
            </FullWithContent>
            <PageContainer>
              <PageContent>
                <CollegeList colleges={colleges} rankingComponent={(college) => <AverageGraduationSuccess {...college} />} push={push} />

                {colleges && colleges.length < count && (
                  <LoadMoreButtonContainer>
                    <LoadMoreButton onClick={() => setPage(page + 1)}>
                      <LoadMoreButtonLabel>Load More</LoadMoreButtonLabel>
                    </LoadMoreButton>
                  </LoadMoreButtonContainer>
                )}
              </PageContent>
            </PageContainer>
          </Fragment>
        )
      }
    </div>
  )
}

CollegeBlackGraduationSuccess.propTypes = {
  colleges: PropTypes.array,
  isFetching: PropTypes.bool.isRequired,
  listCollegesIfEmpty: PropTypes.func.isRequired,
  paginate: PropTypes.func,
  count: PropTypes.number,
  push: PropTypes.func,
}

export default CollegeBlackGraduationSuccess;
