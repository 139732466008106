import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, typo, bp } from '../../styles';

const SubmitButtonContainer = styled.button`
  height: 76px;
  border-radius: 10px;
  border: solid 1px ${color.ovBlue};
  background-color: ${color.ovYellow};
  transition: all .1s;
  text-align: center;
  padding: 4px 50px;
  font-size: 40px;
  color: ${color.ovWhite};
  margin-bottom: 10px;
  &:hover{
    background-color: ${color.ovBlue};
    color: ${color.ovWhite};
    transition: all .1s;
  }

  @media ${bp.phoneOnly} {
    height: 48px;
  }

  &:focus {
    outline: none !important;
  }
`;

const SubmitButtonLabel = styled.p`
  font-family: ${typo.ovFontFeature};
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: center;
  @media ${bp.phoneOnly} {
    font-size: 22px;
  }
`;

const SubmitButton = ({ 
    submitButtonLabel,
    onClick,
    children
  }) =>{

  return (
    <SubmitButtonContainer type="submit" onClick={onClick}>
      <SubmitButtonLabel>
        {submitButtonLabel}
        {children}
      </SubmitButtonLabel>
    </SubmitButtonContainer>
  )
} 

SubmitButton.propTypes = {
  onClick: PropTypes.func,
  submitButtonLabel: PropTypes.string,
};

export default SubmitButton