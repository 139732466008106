import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isMobile, isTabletPort, isTabletLand, isDesktop, isWide } from '../../../config/app';

import Dropzone from 'react-dropzone';
import Img from 'react-image';
import Spinner from 'react-spinkit';
import { Container12, AbsCloseButton, ContributeFloatRightCloseButton } from '../../../components';
import ContributeForm from './contributeForm';

import { handleDrop, removeDroppedFile, handleDragEnter, handleDragLeave, fileCountLimit } from '../../../modules/upload';
import {
  closeModalAndResetContribution,
  handleContribItemDescriptionUpdate,
} from '../../../modules/contribute/actions';
 
import { submitAttractionContentContribution } from '../../../modules/contribute';
import {
  CenterPane,
  LeftPane,
  RightPane,
  ContributeHeading,
  DropzoneOverlay,
  OVButton,
  OVModalButton,
  FileItem,
  BaseFileItem,
  ImageContainer,
  ImgStyle,
  InputContainer,
  InputSet,
  StyledInput,
  FileHeading,
  BaseFileHeading,
  FileItemFormContainer,
  FileCloseButtonContainer
} from './Styled'

let modalStyles;

if (isMobile)
  modalStyles = {
    content : {
      top: '10%',
      left: '5%',
      right: '5%',
      bottom: '10%',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: '0',
      overflowX: 'none',
    }
  };

if (isTabletPort)
  modalStyles = {
    content : {
      top: '10%',
      left: '5%',
      right: '5%',
      bottom: '10%',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: '0',
      overflowX: 'none',
    }
  };

if (isTabletLand)
  modalStyles = {
    content : {
      top: '10%',
      left: '5%',
      right: '5%',
      bottom: '10%',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: '0',
      overflowX: 'none',
    }
  };

if (isDesktop)
  modalStyles = {
    content : {
      top: '10%',
      left: '15%',
      right: '15%',
      bottom: '10%',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: '0',
      overflowX: 'none',
    }
  };

if (isWide)
  modalStyles = {
    content : {
      top: '10%',
      left: '15%',
      right: '15%',
      bottom: '10%',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: '0',
      overflowX: 'none',
    }
  };

Modal.setAppElement('#root');

const AttractionUploadModal = ({
  contribItems,
  accepted,
  isUploading,
  handleDrop,
  handleDragEnter,
  handleDragLeave,
  dropzoneActive,
  removeDroppedFile,
  modalOpen,
  closeModalAndResetContribution,
  handleContribItemDescriptionUpdate,
  submitAttractionContentContribution,
}) => {
  let dropzoneRef;

  return (
      <Modal
          isOpen={modalOpen}
          onRequestClose={closeModalAndResetContribution}
          style={modalStyles}
          contentLabel="Upload Modal"
          shouldCloseOnOverlayClick={!isUploading}
          shouldCloseOnEsc={!isUploading}
        >
        <Dropzone
          disableClick
          style={{position: 'relative', height: '100%'}}
          ref={(node) => { dropzoneRef = node; }}
          accept="image/jpeg, image/png"
          maxSize={8500000} // 4MB
          onDrop={handleDrop}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
        >
          <Container12>
            { dropzoneActive && <DropzoneOverlay /> }
            { contribItems.length > 0 &&
              <LeftPane>
                <AbsCloseButton onClick={!isUploading ? closeModalAndResetContribution : undefined} />
                <ContributeHeading>Add photos</ContributeHeading>
                { isUploading && <Spinner fadeIn="none" name="cube-grid" /> }
                { !isUploading &&
                  <div>
                    <ContributeForm />
                    { contribItems.length < fileCountLimit && <OVModalButton onClick={()=>{ if (isUploading) return;  dropzoneRef.open(); }}>Select Images</OVModalButton> }
                    <OVModalButton onClick={!isUploading ? submitAttractionContentContribution : undefined}>Submit</OVModalButton>
                  </div>
                }
              </LeftPane>
            }
            { contribItems.length > 0 &&
              <RightPane>
                { contribItems.map((item, contribItemIndex)=> <FileItem key={'file_item_' + contribItemIndex} >
                  <ImageContainer key={'image_cont_' + contribItemIndex}>
                    <Img
                      key={'img_' + contribItemIndex}
                      src={accepted[contribItemIndex].preview}
                      alt={item.title}
                      style={ImgStyle}
                      loader={<Spinner fadeIn="none" name="cube-grid" />}
                    />
                  </ImageContainer>
                  <FileItemFormContainer>
                    <InputSet>
                      <FileHeading>Description</FileHeading>
                      <InputContainer key={'desc_input_cont_' + contribItemIndex}>
                        <StyledInput
                          key={'desc_input_' + contribItemIndex}
                          defaultValue={item.description}
                          placeholder={"Description"}
                          type="text"
                          onChange={(e) => handleContribItemDescriptionUpdate(e.target.value, contribItemIndex)} />
                      </InputContainer>
                    </InputSet>
                    <FileCloseButtonContainer>
                      <ContributeFloatRightCloseButton key={'close_' + contribItemIndex} onClick={()=>{ if (isUploading) return undefined; removeDroppedFile(contribItemIndex);}}/>
                    </FileCloseButtonContainer>
                  </FileItemFormContainer>
                </FileItem>)}
                { contribItems.length < fileCountLimit &&
                  <BaseFileItem key={'file_item_base'} >
                    <BaseFileHeading>Drag and drop at anytime</BaseFileHeading>
                  </BaseFileItem>
                }
              </RightPane>
            }
            { contribItems.length === 0 &&
              <CenterPane>
                { !isMobile && <ContributeHeading>Drag and Drop</ContributeHeading> }
                { !isMobile && <ContributeHeading>- or -</ContributeHeading> }
                <OVButton onClick={()=>{ if (isUploading) return;  dropzoneRef.open(); }}>Select Images</OVButton>
              </CenterPane>
            }
          </Container12>
        </Dropzone>
      </Modal>
  )
};

AttractionUploadModal.propTypes = {
  accepted: PropTypes.array.isRequired,
  isUploading: PropTypes.bool.isRequired,
  handleDrop: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  dropzoneActive: PropTypes.bool.isRequired,
  contribItems: PropTypes.array.isRequired,
  handleContribItemDescriptionUpdate: PropTypes.func.isRequired,
  submitAttractionContentContribution: PropTypes.func.isRequired,
};

const mapStateToProps = ({ upload, contribute, user }) => ({
  accepted: upload.accepted,
  dropzoneActive: upload.dropzoneActive,
  isUploading: upload.isUploading,
  modalOpen: contribute.modalOpen,
  contribItems: contribute.contrib_items,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  handleDrop,
  handleDragEnter,
  handleDragLeave,
  removeDroppedFile,
  closeModalAndResetContribution,
  handleContribItemDescriptionUpdate,
  submitAttractionContentContribution,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AttractionUploadModal)
