import React from 'react';
import Img from 'react-image';
import Spinner from 'react-spinkit';

// College Backgrounds
import default_bkg from '../../assets/img/profile-bg.jpg'

// College Logos
const svgsContext = require.context('../../assets/logos', true, /\.svg$/);
const svgPaths = svgsContext.keys();

export const Logos = svgPaths.reduce((memo, key) => {
  memo[key.replace(/(\.\/)|(\.svg)/g, '')] = svgsContext(key);
  return memo;
}, {});

// College Backgrounds
const bkgContext = require.context('../../assets/backgrounds', true, /\.jpg$/);
const bkgPaths = bkgContext.keys();

export const Bkg = bkgPaths.reduce((memo, key) => {
  memo[key.replace(/(\.\/)|(\.jpg)/g, '')] = bkgContext(key);
  return memo;
}, { default_bkg });

const CollegeLogo = ({ name, style }) => (
  <Img
    style={style}
    src={[Logos[name.replace(/\s/gi, '_').replace(/[()']/gi, '').toLowerCase()], 'https://i.imgur.com/bb2ETd2.png?1']}
    loader={<Spinner fadeIn="none" name="cube-grid" />}
    decode={false}
  />
);

export default CollegeLogo;
