import React, {useEffect} from 'react';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Contribute from '../contribute';
import { ImagePane, FullWidthPageContainer, ReviewsList } from '../../components';
import {
  toggleEdit,
  editCollege,
  getCollegeReviewsAndMajorsIfEmpty,
  collegeStatsSelector,
  teamStatsSelector
} from '../../modules/college';
import { listContentTagsIfEmpty } from '../../modules/contribute';
import { openContentByFilter, addFilter } from '../../modules/content';
import { getFirstByTag, taggedContent } from '../../modules/content/selectors';
import { pageSelector } from '../../modules/college/selectors';
import { jobOrderedCoachesSelector } from "../../modules/coach/selectors";

import Scroll from 'react-scroll';
import { withRouter } from 'react-router-dom';

import CollegeStats from './CollegeStats';
import TeamStats from './TeamStats';
import CostStats from './CostStats';
import CoachesStats from './CoachesStats';
import AdmissionStats from './AdmissionStats';
import OutcomesStats from './OutcomesStats';

import CollegeHeader from '../../components/CollegeHeader';

import CollegeCard from '../../components/College/CollegeCard';

import LeaveReviewBg from '../../assets/backgrounds/review_illustration.svg';

import ClaimBanner from '../../components/ClaimBanner';

import {
  PageContent,
  LeaveReviewContainer,
  LeaveReviewHeading,
  LeaveReviewButton
} from './Styled';

import {
  EighthPartPageContent,
  ReviewsPartPageContent,
  QuarterPartPageContent
} from './StyledUI';
import { getUserIfEmpty, requestLogin } from "../../modules/user";
import { withLifecycleActions } from "react-redux-lifecycle";
import AttractionPreviewCard from '../../components/Attractions/AttractionPreviewCard';


const ing = "UTEP.png"

// Preset Filters
const filter0 = {
  name: "on_campus_colleges",
};

const filter1 = {
  name: "gameday_teams",
};

const filter2 = {
  name: "facilities_teams",
};

const filterProfColleges = {
  name: "professional_colleges",
};

const filterProfTeams = {
  name: "professional_colleges",
};

const scroll = Scroll.scroller;
const Element = Scroll.Element;

const CollegeDetails = ({
  match,
  location,
  college,
  collegeStats,
  teamStats,
  role,
  isEditing,
  isFetchingCollege,
  isFetchingReviews,
  isSubmittingReview,
  toggleEdit,
  editCollege,
  isAuthenticated,
  requestLogin,
  addFilter,
  push,
  contents,
  reviews,
  averageReview,
  openContentByFilter,
  getCollegeReviewsAndMajorsIfEmpty,
  listContentTagsIfEmpty,
  coaches = []
}) => {
  useEffect(() => {
     if (location.hash === "#reviews") {
      scroll.scrollTo('reviews', { duration: 1500, smooth: true })
    }

    listContentTagsIfEmpty();
    getCollegeReviewsAndMajorsIfEmpty();
  }, []);

  if (coaches.length > 0) {
    coaches = jobOrderedCoachesSelector(coaches);
  }

  const metaTitle = college.name + ' - OfficialVisit';
  const metaDescription = 'Explore ' + college.name + ' reviews, rankings, football, coaches, and campus life.  Is this your college?';

  const profPhotos = taggedContent(contents, [filterProfColleges, filterProfTeams]);
  const hasProfPhotos = profPhotos && profPhotos.length;
  const mainPhoto = hasProfPhotos ? profPhotos[0] : contents && contents[0] ? contents[0] : {};
  const contentLargeLinkText = hasProfPhotos ? 'Professional' : 'All Photos';
  return (
    <div>
      {college.name && <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>}
      <CollegeHeader
        isFetching={isFetchingCollege}
        isEditing={isEditing}
        toggleEdit={toggleEdit}
        editCollege={editCollege}

        college={college}
        averageReview={averageReview}

        role={role}
        push={push}
      />

      {/* PHOTOS*/}
      <PageContent>
        <Contribute match={match} />
        <ImagePane
          contentLarge={mainPhoto}
          contentSmall0={getFirstByTag(contents, filter0)}
          contentSmall1={getFirstByTag(contents, filter1)}
          contentSmall2={getFirstByTag(contents, filter2)}
          contentLargePhotoOnClick={openContentByFilter(match.url)}
          contentLargeLinkOnClick={() => { if (hasProfPhotos) addFilter(filterProfColleges, filterProfTeams); push(`${match.url}/content`); }}

          contentSmall0PhotoOnClick={openContentByFilter(match.url, filter0)}
          contentSmall0LinkOnClick={() => { openContentByFilter(match.url, filter0) }}

          contentSmall1PhotoOnClick={openContentByFilter(match.url, filter1)}
          contentSmall1LinkOnClick={() => { openContentByFilter(match.url, filter1) }}

          contentSmall2PhotoOnClick={openContentByFilter(match.url, filter2)}
          contentSmall2LinkOnClick={() => { openContentByFilter(match.url, filter2) }}

          contentLargeLinkText={contentLargeLinkText}
          contentSmall0LinkText={"On Campus"}
          contentSmall1LinkText={"Gameday"}
          contentSmall2LinkText={"Facilities"}
        />
      </PageContent>

      <FullWidthPageContainer>

        <EighthPartPageContent>

          {!isFetchingCollege && !isEditing && college.ID && (
            <CollegeCard collegeCardTitle="overview">
              <CollegeStats
                header={collegeStats.header}
                college={college}
                ranks={college.stats.filter(s => ["ranks"].indexOf(s.title) >= 0)}
                funding={collegeStats.items.filter(s => ["funding"].indexOf(s.title) >= 0)}
                enrollment={collegeStats.items.filter(s => ["enrollment"].indexOf(s.title) >= 0)}
                weather={collegeStats.items.filter(s => ["weather"].indexOf(s.title) >= 0)}
                web={collegeStats.items.filter(s => ["web"].indexOf(s.title) >= 0)}
                averageReview={averageReview}
                about={collegeStats.items.filter(s => ["about"].indexOf(s.title) >= 0)}
              />
            </CollegeCard>
          )}

          {/* FOOTBALL PROGRAM */}
          {!isFetchingCollege && !isEditing && college.ID && (
            <CollegeCard collegeCardTitle="football program">
              <TeamStats
              header={teamStats.header}
              college={college}
              conference={teamStats.header.conference}
              apparel={teamStats.header.apparel}
              about={teamStats.items.filter(s => ["about"].indexOf(s.title) >= 0)}
              stadium={teamStats.items.filter(s => ["stadium"].indexOf(s.title) >= 0)}
              graduation={teamStats.items.filter(s => ["graduation"].indexOf(s.title) >= 0)}
              nfl={teamStats.items.filter(s => ["nfl"].indexOf(s.title) >= 0)}
              web={teamStats.items.filter(s => ["web"].indexOf(s.title) >= 0)}
            />
            </CollegeCard>
          )}

          {/* Admissions */}
          {!isFetchingCollege && !isEditing && college.ID && (
            <CollegeCard collegeCardTitle="coaching staff">
              <CoachesStats
                coaches={coaches}
                paginate={pageSelector(coaches, 8)}
              />
            </CollegeCard>
          )}
          {/* Attraction Preview Card */}

          <AttractionPreviewCard
            collegeName={college.name}
            college={college}
            push={push}
          />
          
          {/* Admissions */}
          {!isFetchingCollege && !isEditing && college.ID && (
            <CollegeCard collegeCardTitle="admissions">
              <AdmissionStats
                admissions={collegeStats.items.filter(s => ["admissions"].indexOf(s.title) >= 0)}
                books={collegeStats.items.filter(s => ["books"].indexOf(s.title) >= 0)}
              />
            </CollegeCard>
          )}

          <ClaimBanner handleSubmit={() => push('/claim')} college={college.name}/>

          {/* COST */}
          {!isFetchingCollege && !isEditing && college.ID && (
            <CollegeCard collegeCardTitle="cost">
              <CostStats
                cost={collegeStats.items.filter(s => ["cost"].indexOf(s.title) >= 0)}
                detailed_cost={collegeStats.items.filter(s => ["detailed-cost"].indexOf(s.title) >= 0)}
              />
            </CollegeCard>
          )}

          {/* OUTCOMES */}
          {!isFetchingCollege && !isEditing && college.ID && (
            <CollegeCard collegeCardTitle="outcomes">
              <OutcomesStats
                outcomes={collegeStats.items.filter(s => ["outcomes"].indexOf(s.title) >= 0)}
              />
            </CollegeCard>
          )}

          {/* REVIEWS */}
          <Element name="reviews"/>
          {!isFetchingCollege && !isEditing && college.ID && (
            <CollegeCard collegeCardTitle="reviews">
              <ReviewsList
                reviews={reviews}
                count={averageReview && averageReview.count}
                isFetchingReviews={isFetchingReviews}
                onUserClick={id => push(`/user/${id}`)}
                name="test"
                paginateOnPage={3}
                isAuthenticated={isAuthenticated}
                currentUserRole={role || ''}
                match={match}
              />
            </CollegeCard>
          )}

        </EighthPartPageContent>

        <QuarterPartPageContent>

          {/* LEAVE REVIEWS  */}
          {!isFetchingCollege && !isEditing && college.ID && (
            <ReviewsPartPageContent>
              <LeaveReviewContainer bgUrl={LeaveReviewBg}>

                <LeaveReviewHeading>Been to {college.name} before? Leave a review to help others learn more.</LeaveReviewHeading>

                {!isSubmittingReview && (<LeaveReviewButton onClick={() => {
                  isAuthenticated ?
                  push(match.url + '/review') :
                  requestLogin(match.url + '/review', match.url)
                }}>
                  Leave Review
                </LeaveReviewButton>)}

                {isSubmittingReview && (<LeaveReviewButton>
                  Review is submitting...
                </LeaveReviewButton>)}

              </LeaveReviewContainer>
            </ReviewsPartPageContent>
          )}

        </QuarterPartPageContent>

      </FullWidthPageContainer>
    </div>
  )
};

CollegeDetails.propTypes = {
  college: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isFetchingCollege: PropTypes.bool.isRequired,
  isSubmittingReview: PropTypes.bool.isRequired,
  editCollege: PropTypes.func.isRequired,
  getCollegeReviewsAndMajorsIfEmpty: PropTypes.func.isRequired,
  listContentTagsIfEmpty: PropTypes.func.isRequired,
  requestLogin: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  addFilter: PropTypes.func.isRequired,
  contents: PropTypes.array,
  isFetchingContent: PropTypes.bool.isRequired,
  isFetchingReviews: PropTypes.bool.isRequired,
  reviews: PropTypes.array,
  averageReview: PropTypes.object.isRequired,
};

const mapStateToProps = ({ college, user, review, content }) => ({
  college: college.college,
  isEditing: college.isEditing,
  isFetchingCollege: college.isFetchingCollege,
  isSubmittingReview: review.isSubmittingReview,
  isAuthenticated: user.isAuthenticated,
  contents: content.contents,
  reviews: college.reviews,
  averageReview: college.averageReview,
  isFetchingContent: college.isFetchingContent,
  isFetchingReviews: college.isFetchingReviews,
  collegeStats: collegeStatsSelector(college),
  teamStats: teamStatsSelector(college),
  coaches: college.college.coaches,
  role: user.user.role,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getUserIfEmpty,
  toggleEdit,
  editCollege,
  getCollegeReviewsAndMajorsIfEmpty,
  listContentTagsIfEmpty,
  addFilter,
  openContentByFilter,
  push,
  requestLogin
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLifecycleActions({ componentWillMount: [getUserIfEmpty] })(CollegeDetails)))
