import React, {useState} from 'react';

import {
  OptionCheckbox,
  CheckboxContainer
} from './Styled';

// Checkbox
const Checkbox = (
  {
    checked,
    value,
    onChange
  }) => (
  <div className="flex items-center mv4 w-100">
    <CheckboxContainer
      className="mr2"
      type="checkbox"
      value={value}
      checked={checked}
      onChange={onChange}
    />
  </div>
);
// end of Checkbox

const OptionCheck = ({
  label,
  value,
  filter,
  filterPath,
  parentCallback
}) => {
  const [dirty, setDirty] = useState(false);
  return(
    <OptionCheckbox>
      {label &&
        <span>{label}</span>
      }
      <span>
        <Checkbox
          value={value}
          checked={filter.selected(filterPath)}
          onChange={() => {
            filter.toggle(filterPath); 
            parentCallback(dirty === true ? false : true); 
            setDirty(!dirty)
          }}
          isDirty={dirty}
        />
      </span>
    </OptionCheckbox>
  )
}

export default OptionCheck;
