import React from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import Bkg from './HomeBigCardBackgrounds'
import { Col, Row } from 'react-bootstrap';

// Styles
import { 
	HomeBigCardContainer,
	HomeBigCardContent,
	HomeBigCardSubtitle,
	HomeBigCardTitle,
	HomeBigCardButton,
	StyledLogo,
	PhotoGradient
} from './Styled';

const HomeBigRankingCard = ({ 
	push,
	type,
	title,
	bkg
}) =>{
  return (
  	<HomeBigCardContainer bgUrl={(Bkg[bkg] || Bkg["default_bkg"])}>
  		<HomeBigCardContent>		
	  		<HomeBigCardSubtitle>
	  			<StyledLogo/>
	  			<span>The OfficialVisit {type}</span>
	  		</HomeBigCardSubtitle>
	  		<Row>
	  			<Col xs={10} sm={10} md={7}><HomeBigCardTitle>{title}</HomeBigCardTitle></Col>
	  		</Row>
	  		<HomeBigCardButton onClick={() => push('/'+ type.replace(" ", "-").toLowerCase())}>
	  			View the rankings 
	  			<span> ></span>
	  		</HomeBigCardButton>
  		</HomeBigCardContent>
  		<PhotoGradient/>
  	</HomeBigCardContainer>
  	)
}

const mapDispatchToProps = dispatch => bindActionCreators({
  push
}, dispatch)

export default connect(null, mapDispatchToProps)(HomeBigRankingCard)