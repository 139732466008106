import React from 'react';
import PropTypes from 'prop-types'

import { TabButton, ButtonContainer } from './Styled'
import HomeCollegesSlider from '../HomeCollegesSlider';

const HomeTopConference = ({ tabs, isFetching, selected, topColleges, getTopConference, push }) => {
  return (
    <div>
      <ButtonContainer>
        {tabs.map(tab => (
          <TabButton key={tab} selected={selected === tab} onClick={() => getTopConference(tab)}>{tab}</TabButton>
        ))}
      </ButtonContainer>
      <HomeCollegesSlider
        topColleges={topColleges}
        isFetching={isFetching}
        push={push}
      />
    </div>
  )
};

HomeTopConference.propTypes = {
  topColleges: PropTypes.array,
  isFetching: PropTypes.bool,
  getTopConference: PropTypes.func,
  push: PropTypes.func,
};

export default HomeTopConference;