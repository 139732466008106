import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import Bkg from './BigButtonBackgrounds'

import {
  BigButtonContainer,
  BigButtonPhoto, 
  BigButtonLabel
} from './Styled.js'

const BigButton = ({
    bigButtonType,
    bigButtonLabel,
    push
  }) =>{

  const link = bigButtonType; //=== 'colleges' ? '/colleges' : '/search/#'+ bigButtonType;

  return (
    <BigButtonContainer onClick={() => push(link)}>
      <BigButtonPhoto bgUrl={(Bkg[bigButtonType])}/>
      <BigButtonLabel>
        {bigButtonLabel}
      </BigButtonLabel>
    </BigButtonContainer>
  )
} 

BigButton.propTypes = {
  bigButtonLinkOnClick: PropTypes.func,
  bigButtonImageSrc: PropTypes.string,
  bogButtonLabel: PropTypes.string,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  push
}, dispatch)

export default connect(null, mapDispatchToProps)(BigButton)