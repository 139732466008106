import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux'; 
import { connect } from 'react-redux';
import { submitLogin } from '../../modules/user';

const TestLogin = ({submitLogin}) =>{

  useEffect(() => {
    submitLogin({ email: "test1@test.com", password: "test"})
  }, []);

  return (<text>ok</text>)
};

TestLogin.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  submitLogin: PropTypes.func.isRequired
};

const mapStateToProps = ({ user }) => ({
  isFetching: user.isFetching,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  submitLogin
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TestLogin)