import React from 'react';

import{
	FilterList,
	Filter,
} from './Styled'

const Filters = ({
	categories
}) =>  (
	<FilterList>
		{categories && categories.map((category, i) => (
			<Filter key={i}>
				<a href={`#${category.slug}`}>
					{category.name}
				</a>
			</Filter>
		))}
	</FilterList>
);

export default Filters;