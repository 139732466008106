import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { onComponentWillMount } from 'react-redux-lifecycle';
import { isMobile, isTabletPort, isTabletLand, isDesktop, isWide } from '../../config/app';

import Dropzone from 'react-dropzone';
import Img from 'react-image';
import Spinner from 'react-spinkit';
import { Container12, AbsCloseButton, ContributeFloatRightCloseButton } from '../../components';
import ContributeForm from './contributeForm';

import { handleDrop, removeDroppedFile, handleDragEnter, handleDragLeave, fileCountLimit } from '../../modules/upload';
import { openModal, closeModalAndResetContribution, handleContribItemDescriptionUpdate, toggleContentTag } from '../../modules/contribute/actions';
import { listContentTagsIfEmpty, submitContentContribution } from '../../modules/contribute';

import UploadPhotosButtonWithAgreement from "../../components/UploadPhotosButtonWithAgreement";
import { requestLogin } from "../../modules/user";
import { getContributionTags } from "../../modules/content/selectors";

import {
  CenterPane,
  LeftPane,
  RightPane,
  ContributeHeading,
  DropzoneOverlay,
  OVButton,
  OVModalButton,
  FileItem,
  BaseFileItem,
  ImageContainer,
  ImgStyle,
  InputContainer,
  InputSet,
  StyledInput,
  FileHeading,
  BaseFileHeading,
  TagList,
  Tag,
  SelectedTag,
  FileItemFormContainer,
  FileCloseButtonContainer,
  Container,
} from './Styled'

let modalStyles;

if (isMobile)
  modalStyles = {
    content : {
      top: '10%',
      left: '5%',
      right: '5%',
      bottom: '10%',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: '0',
      overflowX: 'none',
    }
  };

if (isTabletPort)
  modalStyles = {
    content : {
      top: '10%',
      left: '5%',
      right: '5%',
      bottom: '10%',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: '0',
      overflowX: 'none',
    }
  };

if (isTabletLand)
  modalStyles = {
    content : {
      top: '10%',
      left: '5%',
      right: '5%',
      bottom: '10%',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: '0',
      overflowX: 'none',
    }
  };

if (isDesktop)
  modalStyles = {
    content : {
      top: '10%',
      left: '15%',
      right: '15%',
      bottom: '10%',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: '0',
      overflowX: 'none',
    }
  };

if (isWide)
  modalStyles = {
    content : {
      top: '10%',
      left: '15%',
      right: '15%',
      bottom: '10%',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: '0',
      overflowX: 'none',
    }
  };

Modal.setAppElement('#root')

/**
 * Used only in College Details
 **/
const Contribute = ({
  contribItems,
  accepted,
  uploading,
  isUploading,
  handleDrop,
  handleDragEnter,
  handleDragLeave,
  dropzoneActive,
  removeDroppedFile,
  modalOpen,
  openModal,
  closeModalAndResetContribution,
  handleContribItemDescriptionUpdate,
  submitContentContribution,
  contentTags,
  toggleContentTag,
  isAuthenticated,
  requestLogin,
  match
}) => {
  let dropzoneRef;

  return (
    <Container>
      <UploadPhotosButtonWithAgreement title='Upload Photos' onAgree={() =>
        isAuthenticated ?
          (!isUploading ? openModal() : undefined) :
          requestLogin(match.url)} />
      <Modal
          isOpen={modalOpen}
          onRequestClose={closeModalAndResetContribution}
          style={modalStyles}
          contentLabel="Upload Modal"
          shouldCloseOnOverlayClick={!isUploading}
          shouldCloseOnEsc={!isUploading}
        >
        <Dropzone
          disableClick
          style={{position: 'relative', height: '100%'}}
          ref={(node) => { dropzoneRef = node; }}
          accept="image/jpeg, image/png"
          maxSize={8500000} // 4MB
          onDrop={handleDrop}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
        >
          <Container12>
            { dropzoneActive && <DropzoneOverlay /> }
            { contribItems.length > 0 &&
              <LeftPane>
                <AbsCloseButton onClick={!isUploading ? closeModalAndResetContribution : undefined} />
                <ContributeHeading>Add photos</ContributeHeading>
                { isUploading && <Spinner fadeIn="none" name="cube-grid" /> }
                { !isUploading &&
                  <div>
                    <ContributeForm />
                    { contribItems.length < fileCountLimit && <OVModalButton onClick={()=>{ if (isUploading) return;  dropzoneRef.open(); }}>Select Images</OVModalButton> }
                    <OVModalButton onClick={!isUploading ? submitContentContribution : undefined}>Submit</OVModalButton>
                  </div>
                }
              </LeftPane>
            }
            { contribItems.length > 0 &&
              <RightPane>
                { contribItems.map((item, contribItemIndex)=> <FileItem key={'file_item_' + contribItemIndex} >
                  <ImageContainer key={'image_cont_' + contribItemIndex}>
                    <Img
                      key={'img_' + contribItemIndex}
                      src={accepted[contribItemIndex].preview}
                      alt={item.title}
                      style={ImgStyle}
                      loader={<Spinner fadeIn="none" name="cube-grid" />}
                    />
                  </ImageContainer>
                  <FileItemFormContainer>
                    <InputSet>
                      <FileHeading>Description</FileHeading>
                      <InputContainer key={'desc_input_cont_' + contribItemIndex}>
                        <StyledInput
                          key={'desc_input_' + contribItemIndex}
                          defaultValue={item.description}
                          placeholder={"Description"}
                          type="text"
                          onChange={(e) => handleContribItemDescriptionUpdate(e.target.value, contribItemIndex)} />
                      </InputContainer>
                    </InputSet>
                    <FileCloseButtonContainer>
                      <ContributeFloatRightCloseButton key={'close_' + contribItemIndex} onClick={()=>{ if (isUploading) return undefined; removeDroppedFile(contribItemIndex);}}/>
                    </FileCloseButtonContainer>
                    <TagList>
                      <FileHeading>College Tags</FileHeading>
                      { /* List all the content tags for colleges */
                        contentTags.map((tag, tagIndex) => {
                          return tag.tag_type === 'colleges' && (
                            // Check if this tag is contained in this contrib_item's selected tags and display accordingly
                            item.selected_content_tags.findIndex(selectedTag => tag.ID === selectedTag.ID) >= 0 ?
                            <SelectedTag onClick={()=> toggleContentTag(tag, contribItemIndex)} key={'college_tag_' + tagIndex} >#{tag.display_name}</SelectedTag> :
                            <Tag onClick={()=> toggleContentTag(tag, contribItemIndex)} key={'college_tag_' + tagIndex} >#{tag.display_name}</Tag>
                          )
                        })
                      }
                    </TagList>
                    <TagList>
                      <FileHeading>Team Tags</FileHeading>
                      { /* List all the content tags for teams */
                        contentTags.map((tag, tagIndex) => {
                          return tag.tag_type === 'teams' && (
                            // Check if this tag is contained in this contrib_item's selected tags and display accordingly
                            item.selected_content_tags.findIndex(selectedTag => tag.ID === selectedTag.ID) >= 0 ?
                            <SelectedTag onClick={()=> toggleContentTag(tag, contribItemIndex)} key={'team_tag_' + tagIndex} >#{tag.display_name}</SelectedTag> :
                            <Tag onClick={()=> toggleContentTag(tag, contribItemIndex)} key={'team_tag_' + tagIndex} >#{tag.display_name}</Tag>
                          )
                        })
                      }
                    </TagList>
                  </FileItemFormContainer>
                </FileItem>)}
                { contribItems.length < fileCountLimit &&
                  <BaseFileItem key={'file_item_base'} >
                    <BaseFileHeading>Drag and drop at anytime</BaseFileHeading>
                  </BaseFileItem>
                }
              </RightPane>
            }
            { contribItems.length === 0 &&
              <CenterPane>
                { !isMobile && <ContributeHeading>Drag and Drop</ContributeHeading> }
                { !isMobile && <ContributeHeading>- or -</ContributeHeading> }
                <OVButton onClick={()=>{ if (isUploading) return;  dropzoneRef.open(); }}>Select Images</OVButton>
              </CenterPane>
            }
          </Container12>
        </Dropzone>
      </Modal>
    </Container>
  )
};

Contribute.propTypes = {
  accepted: PropTypes.array.isRequired,
  isUploading: PropTypes.bool.isRequired,
  handleDrop: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  dropzoneActive: PropTypes.bool.isRequired,
  contribItems: PropTypes.array.isRequired,
  handleContribItemDescriptionUpdate: PropTypes.func.isRequired,
  submitContentContribution: PropTypes.func.isRequired,
  listContentTagsIfEmpty: PropTypes.func.isRequired,
  contentTags: PropTypes.array.isRequired,
  toggleContentTag: PropTypes.func.isRequired,
  requestLogin: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = ({ upload, contribute, user }) => ({
  accepted: upload.accepted,
  dropzoneActive: upload.dropzoneActive,
  isUploading: upload.isUploading,
  modalOpen: contribute.modalOpen,
  contribItems: contribute.contrib_items,
  contentTags: getContributionTags(contribute.content_tags, user),
  isAuthenticated: user.isAuthenticated
});

const mapDispatchToProps = dispatch => bindActionCreators({
  handleDrop,
  handleDragEnter,
  handleDragLeave,
  removeDroppedFile,
  openModal,
  closeModalAndResetContribution,
  handleContribItemDescriptionUpdate,
  submitContentContribution,
  listContentTagsIfEmpty,
  toggleContentTag,
  requestLogin
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(onComponentWillMount(listContentTagsIfEmpty)(Contribute))
