// eslint-disable-next-line
import React from 'react';
import { bindActionCreators } from 'redux';
import { push, goBack } from 'connected-react-router';
import { connect } from 'react-redux';

import {
  removeFilter,
  toggleShowFilters,
  toggleLightboxOpen,
  selectNextIndex,
  selectPrevIndex,
  selectContentIndex,
  toggleManage,
  deleteContent,
  getUserId,
  addFilter
} from '../../modules/content';

import {
  getSortedTags,
  taggedContent
} from '../../modules/content/selectors';

import Content from './Content';

const mapStateToProps = ({ content, contribute }) => ({
  contents: content.contents,
  filteredContent: taggedContent(content.contents, content.selectedFilters),
  selectedFilters: content.selectedFilters,
  filters: getSortedTags(contribute.content_tags),
  showFilters: content.showFilters,
  lightboxOpen: content.lightboxOpen,
  selectedContentIndex: content.selectedContentIndex,
  isManaging: content.isManaging,
  user: content.user,
  isFetching: content.isFetching,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  addFilter,
  removeFilter,
  toggleShowFilters,
  toggleLightboxOpen,
  toggleManage,
  selectNextIndex,
  selectPrevIndex,
  selectContentIndex,
  push,
  goBack,
  deleteContent,
  getUserId,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Content);
