import styled from 'styled-components';
import { color, typo, bp } from '../../styles';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const EighthPartPageContent = styled.div`
  grid-column: span 12;
  grid-row-start: 1;
  @media ${bp.phoneOnly} {
    grid-row-start: 1;
    grid-column-start: 1;
    width: 93%;
    margin: 10px auto;
    height: auto;
    &[data-show=true]{
      height: 93vh;
      overflow: hidden;
    }
  }
  @media ${bp.tabletPortUp} {
    grid-column-start: 6;
    grid-column-end: 13;
    margin-right: 30px;
  }
  @media ${bp.tabletLandUp} {
    grid-column: span 7;
    grid-row-start: 1;
    grid-column-start: 5;
    grid-column-end: 13;
    margin-right: 30px;
  }
  @media ${bp.desktopUp} {
    grid-column-start: 4;
    grid-column-end: 12;
  }
`;

export const QuarterPartPageContent = styled.div`
  grid-column: span 12;
  @media ${bp.phoneOnly} {
    width: 100%;
    margin: 64px auto 0px auto;
    position: absolute;
    top: 0;
    left: -110vw;
    z-index: 999;
    transition: left .2s linear;
    &[data-show=true]{
      left: 0;
      transition: left .2s linear;
    }
  }
  @media ${bp.tabletPortUp} {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 6;
    margin-right: 30px;
  }
  @media ${bp.tabletLandUp} {
    grid-column: span 5;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 5;
    margin-right: 30px;
  }
  @media ${bp.desktopUp} {
    grid-column-start: 1;
    grid-column-end: 4;
    margin-right: 30px;
  }
`;

export const PageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 0px;
  padding-bottom: 0px;
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
  @media ${bp.phoneOnly} {
    grid-template-columns: auto;
    margin-top: -42px;
    height: auto;
  }
  
`;

export const ExploreCollegesBox = styled.div`
`;

export const CollegeListTotal = styled.div`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
  font-size: 25px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  span{
    color: ${color.ovBlue};
  }
  @media ${bp.phoneOnly} {
    font-size: 16px;
  }
`;

export const ScrollbarContainer = styled(PerfectScrollbar)`
  max-height: calc(100vh - 66px);
  overflow-y: hidden;
  padding: 0 10px 20px 0;

  .ps__rail-y{
    opacity: 1;
    .ps__thumb-y{
      background-color: ${color.ovGrey};
    }
  }
`;

export const PageTitle = styled.h1`
  font-size: 45px;
  margin: -12px 20px 0 20px;

  @media ${bp.phoneOnly} {
    display: none;
  }
`;

