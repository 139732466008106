import { push } from 'connected-react-router';
import { LOCATION_CHANGE } from 'react-router-redux'
import find from 'lodash/find';

import { collegeService } from '../../services';
import { LOGOUT_SUBMITTED, submitLogout } from '../user';
import { DELETE_ADMIN_REVIEW_SUCCESS } from "../review/actionsTypes";

export const REQUEST_SUCCESS = 'college/REQUEST_SUCCESS';

export const LIST_COLLEGES_REQUESTED = 'college/LIST_COLLEGES_REQUESTED';
export const LIST_COLLEGES_SUCCESS = 'college/LIST_COLLEGES_SUCCESS';
export const LIST_COLLEGES_FAILURE = 'college/LIST_COLLEGES_FAILURE';

export const GET_COLLEGE_REQUESTED = 'college/GET_COLLEGE_REQUESTED';
export const GET_COLLEGE_SUCCESS = 'college/GET_COLLEGE_SUCCESS';
export const GET_COLLEGE_FAILURE = 'college/GET_COLLEGE_FAILURE';

export const TOGGLE_EDIT_COLLEGE = 'college/TOGGLE_EDIT_COLLEGE';
export const EDIT_COLLEGE_REQUESTED = 'college/EDIT_COLLEGE_REQUESTED';
export const EDIT_COLLEGE_SUCCESS = 'college/EDIT_COLLEGE_SUCCESS';
export const EDIT_COLLEGE_FAILURE = 'college/EDIT_COLLEGE_FAILURE';

export const GET_CONTENT_REQUESTED = 'college/GET_CONTENT_REQUESTED';
export const GET_CONTENT_SUCCESS = 'college/GET_CONTENT_SUCCESS';
export const GET_CONTENT_FAILURE = 'college/GET_CONTENT_FAILURE';

export const GET_REVIEWS_REQUESTED = 'college/GET_REVIEWS_REQUESTED';
export const GET_REVIEWS_SUCCESS = 'college/GET_REVIEWS_SUCCESS';
export const GET_REVIEWS_FAILURE = 'college/GET_REVIEWS_FAILURE';

export const GET_AVERAGE_REVIEW_REQUESTED = 'home/GET_AVERAGE_REVIEW_REQUESTED';
export const GET_AVERAGE_REVIEW_SUCCESS = 'home/GET_AVERAGE_REVIEW_SUCCESS';
export const GET_AVERAGE_REVIEW_FAILURE = 'home/GET_AVERAGE_REVIEW_FAILURE';

export const GET_AVERAGE_REVIEWS_REQUESTED = 'home/GET_AVERAGE_REVIEWS_REQUESTED';
export const GET_AVERAGE_REVIEWS_SUCCESS = 'home/GET_AVERAGE_REVIEWS_SUCCESS';
export const GET_AVERAGE_REVIEWS_FAILURE = 'home/GET_AVERAGE_REVIEWS_FAILURE';

export const GET_MAJORS_REQUESTED = "wizard/GET_MAJORS_REQUESTED";
export const GET_MAJORS_SUCCESS = "wizard/GET_MAJORS_SUCCESS";
export const GET_MAJORS_FAILURE = "wizard/GET_MAJORS_FAILURE";

export const APPLY_REVIEWS_FILTER = 'APPLY_REVIEWS_FILTER'
export const APPLY_REVIEWS_FILTER_SUCCES = 'APPLY_REVIEWS_FILTER_SUCCES'

const initialState = {
  colleges: [],
  college: {},
  reviews: [],
  averageReviews: [],
  averageReview: {},
  isFetchingCollege: false,
  isFetchingContent: false,
  isFetchingReviews: false,
  isFetchingAverageReview: false,
  isFetchingAverageReviews: false,
  isEditing: false,
  majors: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LIST_COLLEGES_REQUESTED:
    case GET_COLLEGE_REQUESTED:
    case EDIT_COLLEGE_REQUESTED:
      return {
        ...state,
        isFetchingCollege: true,
        college: {},
      }

    case GET_CONTENT_REQUESTED:
      return {
        ...state,
        isFetchingContent: true
      }

    case GET_CONTENT_SUCCESS:
      return {
        ...state,
        isFetchingContent: false
      }

    case GET_CONTENT_FAILURE:
      return {
        ...state,
        isFetchingContent: false
      }

    case GET_REVIEWS_REQUESTED:
      return {
        ...state,
        isFetchingReviews: true
      }

    case GET_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.payload,
        isFetchingReviews: false
      }

    case GET_REVIEWS_FAILURE:
      return {
        ...state,
        isFetchingReviews: false
      }


    case DELETE_ADMIN_REVIEW_SUCCESS:
      return {
        ...state,
        reviews: [...state.reviews.filter(el => el.ID !== action.payload)]
      };

    case GET_AVERAGE_REVIEW_REQUESTED:
      return {
        ...state,
        isFetchingAverageReview: true
      }

    case GET_AVERAGE_REVIEW_SUCCESS:
      return {
        ...state,
        averageReview: action.payload,
        averageReviews: [...state.averageReviews, action.payload],
        isFetchingAverageReview: false
      }

    case GET_AVERAGE_REVIEW_FAILURE:
      return {
        ...state,
        isFetchingAverageReview: false
      }

    case GET_AVERAGE_REVIEWS_REQUESTED:
      return {
        ...state,
        isFetchingAverageReviews: true
      }

    case GET_AVERAGE_REVIEWS_SUCCESS:
      return {
        ...state,
        averageReviews: action.payload,
        isFetchingAverageReviews: false
      }

    case GET_AVERAGE_REVIEWS_FAILURE:
      return {
        ...state,
        isFetchingAverageReviews: false
      }

    case LIST_COLLEGES_SUCCESS:
      return {
        ...state,
        colleges: action.payload,
        isFetchingCollege: false
      }

    case GET_COLLEGE_SUCCESS:
      return {
        ...state,
        college: action.payload,
        isFetchingCollege: false
      }

    case TOGGLE_EDIT_COLLEGE:
      return {
        ...state,
        isEditing: !state.isEditing
      }

    case EDIT_COLLEGE_SUCCESS:
      return {
        ...state,
        college: action.payload,
        isEditing: false,
        isFetchingCollege: false
      }

    case LIST_COLLEGES_FAILURE:
    case GET_COLLEGE_FAILURE:
    case EDIT_COLLEGE_FAILURE:
      return {
        ...state,
        isFetchingCollege: false
      }

    case LOCATION_CHANGE:
      return {
        ...state,
        isEditing: false
      }

    case GET_MAJORS_SUCCESS:
      return {
        ...state,
        majors: action.payload
      };

    case LOGOUT_SUBMITTED:
      return initialState;
    
    case APPLY_REVIEWS_FILTER:
    return {
      ...state,
      isFetchingReviews: true,
    };

    case APPLY_REVIEWS_FILTER_SUCCES:
      return {
        ...state,
        isFetchingReviews: false,
      }

    default:
      return state
  }
}

const applyShowLoader = () => ({
  type: APPLY_REVIEWS_FILTER,
});
const applyShowLoaderSucces = () => ({
  type: APPLY_REVIEWS_FILTER_SUCCES,
});
export const applyFilterShowLoader = () => {
  return applyShowLoader();
};

export const applyFilterShowLoaderSucces = () => {
  return applyShowLoaderSucces();
};

const listCollegesRequest = () => ({
  type: LIST_COLLEGES_REQUESTED
})

const listCollegesSuccess = (data) => ({
  type: LIST_COLLEGES_SUCCESS,
  payload: data
})

const listCollegesFailure = (data) => ({
  type: LIST_COLLEGES_FAILURE
})

/**
 * Filters array with items only containing search query
 * @param  {[Object]} commits Array of commit objects
 * @param  {Object} filter  Object containing search query
 * @return {[Object]}         Array of filtered commit objects
 */
export const visibleColleges = (colleges, filter) => {
  if (filter && filter.values && filter.values.text) {
    return colleges.filter(college => {
      return college.formal_name.toLowerCase().includes(filter.values.text.toLowerCase()) ||
        college.name.toLowerCase().includes(filter.values.text.toLowerCase()) ||
        college.nickname.toLowerCase().includes(filter.values.text.toLowerCase()) ||
        college.address.city.toLowerCase().includes(filter.values.text.toLowerCase()) ||
        college.address.state.toLowerCase().includes(filter.values.text.toLowerCase()) ||
        college.conference.toLowerCase().includes(filter.values.text.toLowerCase());
    });
  }

  return colleges;
}

export const listColleges = () => {
  return async (dispatch) => {
    dispatch(listCollegesRequest())

    try {
      const res = await collegeService().getColleges();
      return dispatch(listCollegesSuccess(res.data.data));
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }
  }
};

export const listCollegesIfEmpty = () => {
  return (dispatch, getState) => {
    const { college } = getState();
    // do not continue if colleges are loading or have been already loaded
    if (college.isFetchingCollege || college.colleges.length) return;
    
    return dispatch(listColleges())
  }
};

const requestAverageReview = () => ({
  type: GET_AVERAGE_REVIEW_REQUESTED
});

const averageReviewSuccess = (data) => ({
  type: GET_AVERAGE_REVIEW_SUCCESS,
  payload: data
});

const averageReviewFailure = () => ({
  type: GET_AVERAGE_REVIEW_FAILURE
});

export const getAverageReview = (collegeID) => {
  return async (dispatch, getState) => {
    dispatch(requestAverageReview())
    try {
      const res = await collegeService().getAverageCollege(collegeID);
      dispatch(averageReviewSuccess(res.data.data));
    } catch (e) {
      console.log(e);
      return dispatch(averageReviewFailure());
    }
  }
};

const requestAverageReviews = () => ({
  type: GET_AVERAGE_REVIEWS_REQUESTED
});

const averageReviewsSuccess = (data) => ({
  type: GET_AVERAGE_REVIEWS_SUCCESS,
  payload: data
});

const averageReviewsFailure = () => ({
  type: GET_AVERAGE_REVIEWS_FAILURE
});

export const getAverageReviews = () => {
  return async (dispatch) => {
    dispatch(requestAverageReviews())
    try {
      const res = await collegeService().getAverageCollegeReviews();
      dispatch(averageReviewsSuccess(res.data.data));
    } catch (e) {
      console.log(e);
      return dispatch(averageReviewsFailure());
    }
  }
};

export const collegeStatsSelector = (state = {}) => {
  const list = (state.college || {}).stats || [];
  const rankIndex = list.findIndex(({ title }) => title === 'ranks');
  const rankItem = rankIndex > -1 && list[rankIndex];
  const header = (rankItem && rankItem.data[0]) || {};
  const items = list.filter(({ title }) => title !== 'ranks');

  return {
    header,
    items,
  };
}


export const teamStatsSelector = (state = {}) => {
  const teams = (state.college || {}).teams || [];
  const main = teams[0];

  // If not main team, return empty
  if (!main) return { header: {}, items: [] };
  const { stats: list } = main;
  const miscIndex = list.findIndex(({ title }) => title === 'misc');
  const miscItem = miscIndex > -1 && list[miscIndex];

  const header = { conference: {}, apparel: {} };
  if (miscItem) {
    header.conference = (find(miscItem.data, { label: 'Conference' }) || {});
    header.apparel = (find(miscItem.data, { label: 'Apparel Provider' }) || {});
  }

  const items = list.filter(({ title }) => title !== 'misc');

  return {
    header,
    items,
  };
}

const getCollegeRequest = () => ({
  type: GET_COLLEGE_REQUESTED
})

const getCollegeSuccess = (data) => ({
  type: GET_COLLEGE_SUCCESS,
  payload: data
})

const getCollegeFailure = () => ({
  type: GET_COLLEGE_FAILURE
})

/**
 * Get a college by id. Accept an id and a college object
 * If the object has the right id, it skips the request to server.
 * @param  {[type]} id   [description]
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const getCollegeByID = (id) => {
  return async (dispatch) => {

    dispatch(getCollegeRequest())

    try {
      const res = await collegeService().getCollege(id);
      return dispatch(getCollegeSuccess(res.data.data));
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    } finally {
      return;
    }
  }
}

export const getCollegeByURL = (url) => {
  return async (dispatch, getState) => {
    dispatch(getCollegeRequest())

    const { college } = getState();

    if (college.colleges.length === 0) {
      try {
        const res = await collegeService().getColleges();
        dispatch(listCollegesSuccess(res.data.data));
      } catch (e) {
        // log user out if unauthorized
        if (e.response.status === 401) {
          return dispatch(submitLogout());
        } else {
          console.log(e.response.statusText)
        }
      }
    }

    const collegeMatch = getState().college.colleges.filter(college => {
      return url.split('/')[2] === college.url_slug;
    });


    if (collegeMatch.length === 0) {
      return dispatch(push('/'));
    }

    return dispatch(getCollegeByID(collegeMatch[0].ID))
  }
}

export const getCollegeByURLIfEmpty = () => {
  return (dispatch, getState) => {
    const { college, router } = getState();
    return router.location.pathname.split('/')[2] !== college.college.url_slug && dispatch(getCollegeByURL(router.location.pathname))
  }
}

export const toggleEdit = () => ({
  type: TOGGLE_EDIT_COLLEGE
})

const requestEditCollege = () => ({
  type: EDIT_COLLEGE_REQUESTED
})

const editCollegeSuccess = (data) => ({
  type: EDIT_COLLEGE_SUCCESS,
  payload: data
})

export const editCollege = (id, data) => {
  return async (dispatch) => {
    dispatch(requestEditCollege())

    const dataTranslate = {
      name: data.name,
      nickname: data.nickname,
      formal_name: data.formalName,
      address: {
        city: data.city,
        state: data.state
      },
      division: data.schoolDivision,
      teams: [{
        division: data.teamDivision
      }],
      conference: data.conference
    };

    try {
      const res = await collegeService().editCollege(id, dataTranslate);
      dispatch(listColleges());
      return dispatch(editCollegeSuccess(res.data.data));
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    } finally {
      return;
    }
  }
}

const getContentRequest = () => ({
  type: GET_CONTENT_REQUESTED
})

const getContentSuccess = (data) => ({
  type: GET_CONTENT_SUCCESS,
  payload: data
})

const getContentFailure = () => ({
  type: GET_CONTENT_FAILURE
})

const getReviewsRequest = () => ({
  type: GET_REVIEWS_REQUESTED
})

const getReviewsSuccess = (data) => ({
  type: GET_REVIEWS_SUCCESS,
  payload: data
})

const getReviewsFailure = () => ({
  type: GET_REVIEWS_FAILURE
})

// To map user reviews with its college
export const mapReviewsWithUserCollege = (reviews, colleges) => reviews.map(review => ({
  ...review,
  user: {
    ...review.user,
    college: colleges.find(college => college.ID === review.user.college_refer),
  }
}));

/**
 * Get a list of reviews by college id grouped by contribution
 * @param  {[type]} id   [description]
 * @return {[type]}      [description]
 */
export const getReviewsByCollegeID = (id) => {
  return async (dispatch, getState) => {
    const { user, college: { colleges } } = getState();

    dispatch(getReviewsRequest())
    try {
      const res = user.isAuthenticated
        ? await collegeService().getAuthReviews(id)
        : await collegeService().getApiReviews(id);
      
      const reviews = mapReviewsWithUserCollege(res.data.data, colleges);
      return dispatch(getReviewsSuccess(reviews));
    } catch (e) {
      dispatch(getReviewsFailure())
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }
  }
}

export const getCollegeReviewsAndMajors = (url) => {
  return async (dispatch, getState) => {

    let { college, user } = getState();
    let { colleges } = college;

    // Gets the College List
    if (colleges.length === 0) {
      dispatch(listCollegesRequest())
      try {
        const res = await collegeService().getColleges();
        colleges = res.data.data;
        dispatch(listCollegesSuccess(colleges));
      } catch (e) {
        dispatch(listCollegesFailure())
        // log user out if unauthorized
        if (e.response.status === 401) {
          return dispatch(submitLogout());
        } else {
          console.log(e.response.statusText)
        }
      }
    }
    // Gets the College by ID
    dispatch(getCollegeRequest())

    // college = getState().college;

    // Gets the the ID of the college
    const collegeMatch = colleges.filter(collegeMatchItem => {
      return collegeMatchItem && url.split('/')[2] === collegeMatchItem.url_slug;
    });

    if (collegeMatch.length === 0) {
      dispatch(getCollegeFailure())
      return dispatch(push('/'));
    }

    try {
      const res = await collegeService().getCollege(collegeMatch[0].ID);
      dispatch(getCollegeSuccess(res.data.data));
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }

    // Gets average reviews
    dispatch(getAverageReview(collegeMatch[0].ID));

    // Gets the Content
    dispatch(getContentRequest())
    try {
      const res = await collegeService().getContent(collegeMatch[0].ID);
      dispatch(getContentSuccess(res.data.data));
    } catch (e) {
      dispatch(getContentFailure())
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }

    // Gets the Reviews
    dispatch(getReviewsRequest())
    try {
      const res = await (user.isAuthenticated
        ? collegeService().getAuthReviews(collegeMatch[0].ID)
        : collegeService().getApiReviews(collegeMatch[0].ID));

      const reviews = mapReviewsWithUserCollege(res.data.data, colleges);
      dispatch(getReviewsSuccess(reviews));
    } catch (e) {
      dispatch(getReviewsFailure())
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }
    // Gets the Majors
    dispatch(getMajorsRequest())
    try {
      const res = await collegeService().getMajors(collegeMatch[0].ID);
      return dispatch(getMajorsSuccess(res.data.data));
    } catch (e) {
      dispatch(getMajorsFailure());
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText);
      }
    }

  }
}

export const getCollegeReviewsAndMajorsIfEmpty = () => {
  return (dispatch, getState) => {
    const { college, router } = getState();

    // If we dont have the correct college
    if (router.location.pathname.split('/')[2] !== college.college.url_slug) {
      return dispatch(getCollegeReviewsAndMajors(router.location.pathname))
    } else {
      // if we dont have the reviews
      return dispatch(getReviewsByCollegeID(college.college.ID)).then(() => {
        return dispatch(getMajorsByCollegeID(college.college.ID))
      })
    }
  }
}

// MAJORS GET
export const getMajorsByCollegeID = id => {
  return async dispatch => {
    dispatch(getMajorsRequest());

    try {
      const res = await collegeService().getMajors(id);
      return dispatch(getMajorsSuccess(res.data.data));
    } catch (e) {
      dispatch(getMajorsFailure());
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText);
      }
    } finally {
      return;
    }
  };
};

const getMajorsRequest = () => ({
  type: GET_MAJORS_REQUESTED
});

const getMajorsSuccess = data => ({
  type: GET_MAJORS_SUCCESS,
  payload: data
});

const getMajorsFailure = data => ({
  type: GET_MAJORS_FAILURE
});

