import styled from 'styled-components';
import { color, typo, bp } from '../../../../styles'

export const Category = styled.span`
	padding: 0 38px;
	height: 24px;
	background-color: #e0e0e0;
	font-size: 18px;
	font-weight: 600;
	font-style: normal;
	font-stretch: condensed;
	line-height: 1.39;
	letter-spacing: normal;
	text-align: center;
	display: inline;
	color: ${color.ovBlack};
	font-family: ${typo.ovFontFeature};
	margin-right: 30px;
	@media ${bp.phoneOnly} {
		padding: 0 15px;
	}
`;