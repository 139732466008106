import { bindActionCreators } from 'redux'; 
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import { setAnswer, setComment, setTitle, setupReviewQuestions } from '../../modules/attraction/reviews';
import { getUserIfEmpty, requestLogin } from '../../modules/user';
import { userRoleSelector, isCurrentCollegeStudentSelector } from '../../modules/user/selectors';
import { getCollegeReviewsAndMajorsIfEmpty } from '../../modules/college';
import { getAttractionForReview } from '../../modules/attraction/attraction';

import { getCollegeByURLIfEmpty } from '../../modules/college';
import { submitAttractionReviewContribution } from '../../modules/contribute';

import AttractionReview from './AttractionReview'

const mapStateToProps = (state) => ({
  college: state.college.college,
  attraction: state.attraction.attraction,
	collegeId: state.college.college.ID,
  isAuthenticated: state.user.isAuthenticated,
  isSubmittingReview: state.attractionReview.isSubmittingReview,
  questions: state.attractionReview.questions,
  answers: state.attractionReview.answers,
  userRole: userRoleSelector(state),
  isCurrentCollegeStudent: isCurrentCollegeStudentSelector(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({
	setAnswer,
  setComment,
  setTitle,
  getUserIfEmpty,
  getCollegeByURLIfEmpty,
  submitAttractionReviewContribution,
  setupReviewQuestions,
  push,
  getAttractionForReview,
  requestLogin,
  getCollege: getCollegeReviewsAndMajorsIfEmpty
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AttractionReview)