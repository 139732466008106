import styled from 'styled-components';
import { color, typo, bp } from '../../styles'

export const Item = styled.li`
  list-style-type: none;
  margin-top: 20px;
`;

export const Question = styled.div`
  font-family: ${typo.ovFontAlt};
  font-size: 24px;
  font-weight: 600;
  padding: 0;
`;

export const Selection = styled.div`
  position: relative;
  padding: 37px 0 0 0;
  font-size: 14px;

  @media ${bp.phoneOnly} {
    padding: 37px 0 0;
  }
`;

export const SelectionBar = styled.ul`
  display: table;
  width: 100%;
  padding: 0;
  margin: 0;
  table-layout: fixed;
  list-style: none;

  & > li {
    display: table-cell;
    width: 2%;
    word-break: break-word;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
    text-align: center;
    cursor: pointer;
  }

  & > li.first {
    border-radius: 2px 0 0 2px;
  }

  & > li.last {
    border-radius: 0 2px 2px 0;
  }

  & > li:hover{
    background: ${color.ovGrey};
  }

  & .selection-wrapper {
    position: relative;
    display: block;
    padding: 14px 0 13px;
  }

  & .first .selection-wrapper .line {
    display: block;
    opacity: .15;
    position: absolute;
    height: 10px;
    bottom: -10px;
    width: 1px;
    border-left: 1px solid ${color.ovBlack};
    left: 50%;
  }

  & .last .selection-wrapper .line {
    display: block;
    opacity: .15;
    position: absolute;
    height: 10px;
    bottom: -10px;
    width: 1px;
    border-left: 1px solid ${color.ovBlack};
    left: 50%;
  }

  & .selection-wrapper > .label {
    position: relative;
    z-index: 1;
    color: var(--colorDarkGray);
    font-size: var(--fontMedium);
    font-weight: var(--fontLightBold);
  }

  & .selection-wrapper .aux div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 2px;
  }

  & .selection-wrapper .aux .bg {
    opacity: .03;
    z-index: 2;
    background: ${color.ovBlack};
  }

  & .selection-wrapper .aux .bd {
    border-radius: 0;
    opacity: .15;
    border-left: none;
    z-index: 4;
    border: 1px solid ${color.ovBlack};
  }

  & .first .selection-wrapper .aux .bd {
    border-radius: 2px 0 0 2px;
  }

  & .last .selection-wrapper .aux .bd {
    border-radius: 0 2px 2px 0;}

  & .selection-wrapper .aux .overlay {
    z-index: 1;
    background-color: rgba(0,0,0,0);
  }

  & > li.selected {
    background: ${color.ovBlue};
  }

  & > li.selected > .selection-wrapper > .label {
    color: ${color.ovWhite};
  }
`;

export const LabelBar = styled.ul`
  display: table;
  width: 100%;
  padding: 0;
  margin: 0;
  table-layout: fixed;
  list-style: none;

  & > li {
    padding-top: 17px;
    padding-bottom: 17px;
    word-break: break-word;
    display: table-cell;
    width: 2%;
  }

  & > li.first{ 
    padding-right: 10px;
    text-align: left;
  }

  & > li.middle { 
    text-align: center;
  }

  & > li.last{ 
    padding-left: 10px;
    text-align: right;
  }
`;

export let StyledInput = styled.textarea`
  display: inline-block;
  width: 100%;
  height: 235px;
  border: solid 3px ${color.ovBlue};
  padding-left: 10px;
  background-color: ${color.ovWhite};
  margin-bottom: 20px;
  resize: none;
  font-family: ${typo.ovFontFeature};
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlue};

  &::placeholder{
    font-family: ${typo.ovFontFeature};
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: ${color.ovGrey};
  }
`;

export const StyledInputSmall = styled(StyledInput)`
  height: 35px;
`;

export const PageContent = styled.div`
  grid-column-start: 4;
  grid-column-end: span 6;
  
  @media ${bp.phoneOnly} {
    grid-column: span 12;
  }
`;

export const ButtonMarginTop = styled.div`
  margin-top: 10px;
`;

export const ButtonMarginRight = styled.span`
  margin-right: 10px;
`;

export const ErrorMessage = styled.div`
  display: block;
  color: ${color.ovRed};
  margin-top: -17px;
  text-align: left;
  padding: 10px 0;
`;

const P = styled.p`
  font-family: ${typo.ovFontFeature};
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlue};
`;

export const CheckboxContainer = styled.input`
  transform: scale(2);
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  margin-left: 7px !important;
`;

export const CheckboxLabel = styled(P)`
  margin-left: -3px;
  margin-top: -3px;
  @media ${bp.phoneOnly} {
    margin-left: 0px;
  }
`;

export const CheckboxWarning = styled.div`
  left: 14px;
  position: absolute;
  bottom: -42px;
`;