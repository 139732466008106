import React from 'react';
import PropTypes from 'prop-types';

import {
  LogInButton, TextLabel
} from './Styled';

let TodoView = props => {
  const { onClick } = props;

  return <div>
    <TextLabel>If an account exists for this email address, you will receive an email from
      us shortly.
      <br/><br/>
      1. Open the email.<br/>
      2. Click the link in the email to reset your password.</TextLabel>
    <LogInButton onClick={onClick}>Done</LogInButton>
  </div>
};

TodoView.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default TodoView;
