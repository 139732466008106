import React from 'react';

import {
  ClaimBannerBox,
  ClaimBannerTitle,
  ClaimBannerButton
} from './Styled';

const ClaimBanner = ({handleSubmit, college}) => {
  return(
    <ClaimBannerBox>
      <ClaimBannerTitle>Work at {college}?</ClaimBannerTitle>
      <ClaimBannerTitle>Claim your free profile and start telling your story.</ClaimBannerTitle>
      <ClaimBannerButton onClick={handleSubmit}>Learn More</ClaimBannerButton>
    </ClaimBannerBox>
  )
}

export default ClaimBanner;