import styled from 'styled-components';
import { color } from '../../../styles';

export const CollegeCardBarIconBox = styled.div`
  width: 54px;
  height: 52px;
  background-color: ${color.ovBlue};
  background-color: ${props => props.color === 'football program' && '#624a2e'};
  background-color: ${props => props.color === 'admissions' && '#0f9fd6'};
  background-color: ${props => props.color === 'cost' && '#00a82e'};
  background-color: ${props => props.color === 'outcomes' && '#c6d6eb'};
  background-color: ${props => props.color === 'coaching staff' && '#dd6159'};
  background-color: ${props => props.color === 'coaching history' && '#dd6159'};
  background-color: ${props => props.type === 'photos' && '#dd6159'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardBarIcon = styled.img`
  max-height: 32px;
  max-width: 44px;
`;