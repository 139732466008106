import React, { useState, useCallback } from "react";
import { StyledSelectedList } from "./Styled";

const ListSelected = ({ selected, onChange }) => {
  const handleRemove = useCallback((item) => {
    const result = selected
      ? selected.filter(({ value }) => value !== item.value)
      : [item];

    onChange(result);
  }, [selected, onChange]);

  return (
    <StyledSelectedList>
      {selected && selected.map((item) => (
        <span>{item.label}<i onClick={() => handleRemove(item)}/></span>
      ))}

    </StyledSelectedList>
  )
};

export default ListSelected;
