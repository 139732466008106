import React from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import CollegeSmallCard from '../../../components/College/CollegeSmallCard';
import CollegeSmallCardButton from '../../../components/College/CollegeSmallCardButton';

import { acceptanceRateGroupLabelMap, deadlineGroupLabelMap, appGroupLabelMap, considereationsGroupLabelMap, earlyDeadlineGroupLabelMap } from './AdmissionLabelMap'

import {
  CollegeSmallCardRow,
  CollegeSmallCardTop,
  CollegeSmallCardTitleSmall,
  CollegeSmallCardTitleBig,
  CollegeSmallCardValue,
  CollegeSmallCardTitleButtonLeft,
  CollegeSmallCardTitleButtonRight,
  CollegeCompositeRating,
  CollegeSmallCardContent,
  CollegeTableColumnLabel,
  CollegeTableColumnValue,
  CollegeTable,
  CollegeTableItem,
  CollegeTableRow,
  CollegeTableTitle,
  CollegeTableScoresRowBox,
  TableScoresBarValue,
  TableScoresSatBar,
  TableScoresActBar
} from '../StyledUI';

const AdmissionStats = ({ admissions, books }) => {

  const acceptanceRateGroup = ["acceptance_rate"];
  const deadlineGroup = ["early_action_deadline", "application_deadline"];
  const appGroup = ["common_app_accept", "coalition_app_accept"];
  const considereationsGroup = ["hs_gpa", "hs_rank", "hs_transcript"];
  const earlyDeadlineGroup = ["early_action_deadline", "early_decision_deadline"];
  
  const admissionsArray = admissions[0] ? Object.entries(admissions[0].data).map(function(key) {
    return {"label" : key[0], "value": key[1] ? key[1] : "-"};
  }) : [];

  function filterArray(filter) {
    // eslint-disable-next-line
    const GroupArray = admissionsArray.filter((item)=>{
      let iterador = filter.values();
      for (let value of iterador) {
        if( item.label === value ){
          return item
        }
      }
    })
    return GroupArray;
  }

  function toLocaleString(value){
    return value ? value.toLocaleString('EN') : '-';
  }

  const applyLink = admissions[0] ? admissions[0].data.application_url : "-";

  const satValue = books[0] && books[0].data[0].value;
  const actValue = books[0] && books[0].data[1].value;
  const satSplittedValues = satValue && satValue.split("-");
  const actSplittedValues = actValue && actValue.split("-");

  function convertSatValues(value){
    // 999 is the max grid-template-columns for Chrome
    // 1600 is the max value for the SAT range
    const simpleRuleOfThree = value * 999 / 1600;
    // Removes the decimals
    return Math.floor(simpleRuleOfThree);
  }
  
  const deadlineGroupArray = deadlineGroup && filterArray(deadlineGroup);
  const acceptanceGroupArray = acceptanceRateGroup && filterArray(acceptanceRateGroup);
  const appGroupArray = appGroup && filterArray(appGroup);
  const considereationsGroupArray = considereationsGroup && filterArray(considereationsGroup);
  const earlyDeadlineGroupArray = earlyDeadlineGroup && filterArray(earlyDeadlineGroup);
  
  return (
    <div>
      <CollegeSmallCardRow>

        <CollegeSmallCardButton handleSubmit={()=> window.open(applyLink)}>
          <CollegeSmallCardTop>
            <CollegeSmallCardTitleButtonLeft>
              Apply Now
            </CollegeSmallCardTitleButtonLeft>
            <CollegeSmallCardTitleButtonRight>
              >
            </CollegeSmallCardTitleButtonRight>
          </CollegeSmallCardTop>
        </CollegeSmallCardButton>
        
        {acceptanceGroupArray && acceptanceGroupArray.map((item, index)=>{
          return(
            <CollegeSmallCard key={index}>
              <CollegeSmallCardTop>
                <CollegeSmallCardTitleSmall>
                  {acceptanceRateGroupLabelMap[item.label]}
                </CollegeSmallCardTitleSmall>
                <CollegeSmallCardValue>
                  <CollegeCompositeRating>
                    {item.value}%
                  </CollegeCompositeRating>
                </CollegeSmallCardValue>
              </CollegeSmallCardTop>
            </CollegeSmallCard>
          )
        })}
        
        {deadlineGroupArray && deadlineGroupArray.map((item, index)=>{
          return(
            <CollegeSmallCard key={index}>
              <CollegeSmallCardTop>
                <CollegeSmallCardTitleSmall>
                  {deadlineGroupLabelMap[item.label]}
                </CollegeSmallCardTitleSmall>
                <CollegeSmallCardValue>
                  <CollegeCompositeRating>
                    {item.value}
                  </CollegeCompositeRating>
                </CollegeSmallCardValue>
              </CollegeSmallCardTop>
            </CollegeSmallCard>
          )
        })}

        {/* SAT Scores */}
        <CollegeSmallCard>
          <CollegeSmallCardTop>
            <CollegeSmallCardTitleBig>
              SAT Scores
            </CollegeSmallCardTitleBig>
          </CollegeSmallCardTop>
          <CollegeSmallCardContent>

            <CollegeTableColumnLabel>
              Submitted by {admissions[0] ? admissions[0].data["sat_submission_percent"]: "-"}% of applicants
            </CollegeTableColumnLabel>

            <CollegeTableScoresRowBox>

              <CollegeTableColumnLabel>25th to 75th percentile</CollegeTableColumnLabel>

              <CollegeSmallCardValue>
                <CollegeCompositeRating>
                  {books[0] ? books[0].data[0].value : "-"}
                </CollegeCompositeRating>
              </CollegeSmallCardValue>

            </CollegeTableScoresRowBox>

            <TableScoresSatBar>
              {/* TODO: Change for data*/}
              <TableScoresBarValue 
                value1={satSplittedValues && convertSatValues(satSplittedValues[0])} 
                value2={satSplittedValues && convertSatValues(satSplittedValues[1])}
              />
            </TableScoresSatBar>

            <CollegeTableRow>
              <CollegeTableColumnLabel>SAT Math</CollegeTableColumnLabel>
              <CollegeTableColumnValue>
                {admissions[0] ? admissions[0].data["25_sat_math"]: "-"} 
                - 
                {admissions[0] ? admissions[0].data["75_sat_math"] : "-"}
              </CollegeTableColumnValue>  
            </CollegeTableRow>

            <CollegeTableRow>
              <CollegeTableColumnLabel>SAT Reading</CollegeTableColumnLabel>
              <CollegeTableColumnValue>
                {admissions[0] ? admissions[0].data["25_sat_reading"] : "-"} 
                - 
                {admissions[0] ? admissions[0].data["75_sat_reading"] : "-"}
              </CollegeTableColumnValue>  
            </CollegeTableRow>

          </CollegeSmallCardContent>
        </CollegeSmallCard>
        
        {/* ACT Scores */}
        <CollegeSmallCard>
          <CollegeSmallCardTop>
            <CollegeSmallCardTitleBig>
              ACT Scores
            </CollegeSmallCardTitleBig>
          </CollegeSmallCardTop>
          <CollegeSmallCardContent>

            <CollegeTableColumnLabel>
              Submitted by {admissions[0] ? admissions[0].data["act_submission_percent"] : "-"}% of applicants
            </CollegeTableColumnLabel>

            <CollegeTableScoresRowBox>

              <CollegeTableColumnLabel>25th to 75th percentile</CollegeTableColumnLabel>

              <CollegeSmallCardValue>
                <CollegeCompositeRating>
                {books[0] ? books[0].data[1].value : "-"}
                </CollegeCompositeRating>
              </CollegeSmallCardValue>

            </CollegeTableScoresRowBox>
            
            <TableScoresActBar>
              <TableScoresBarValue value1={actSplittedValues && actSplittedValues[0]} value2={actSplittedValues && actSplittedValues[1]}/>
            </TableScoresActBar>

            <CollegeTableRow>
              <CollegeTableColumnLabel>ACT Math</CollegeTableColumnLabel>
              <CollegeTableColumnValue>
                {admissions[0] ? admissions[0].data["25_act_math"] : "-"} - {admissions[0] ? admissions[0].data["75_act_math"] : "-"}
              </CollegeTableColumnValue>  
            </CollegeTableRow>
            <CollegeTableRow>
              <CollegeTableColumnLabel>ACT Reading</CollegeTableColumnLabel>
              <CollegeTableColumnValue>
                {admissions[0] ? admissions[0].data["25_act_reading"] : "-"} - {admissions[0] ? admissions[0].data["75_act_reading"] : "-"}
              </CollegeTableColumnValue>  
            </CollegeTableRow>

          </CollegeSmallCardContent>
        </CollegeSmallCard>

      </CollegeSmallCardRow>

      <CollegeTable>
        <CollegeTableItem>

          <CollegeTableRow>
            <CollegeTableColumnLabel>Application fee</CollegeTableColumnLabel>
            <CollegeTableColumnValue>${admissions[0] ? admissions[0].data["application_fee"] : "-"}</CollegeTableColumnValue>
          </CollegeTableRow>

          {appGroupArray && appGroupArray.map((item, index)=>{
            return(
              <CollegeTableRow key={index}>
                <CollegeTableColumnLabel>{appGroupLabelMap[item.label]}</CollegeTableColumnLabel>
                <CollegeTableColumnValue>{item.value}</CollegeTableColumnValue>
              </CollegeTableRow>
            )
          })}

        </CollegeTableItem>
        <CollegeTableItem>

          <CollegeTableRow>
            <CollegeTableColumnLabel>Acceptance Rate</CollegeTableColumnLabel>
            <CollegeTableColumnValue>{admissions[0] ? admissions[0].data["acceptance_rate"] : "-"}%</CollegeTableColumnValue>
          </CollegeTableRow>

          <CollegeTableRow>
            <CollegeTableColumnLabel>Applicants</CollegeTableColumnLabel>
            <CollegeTableColumnValue>{admissions[0] ? toLocaleString(admissions[0].data["applicant_quantity"]): "-"}</CollegeTableColumnValue>
          </CollegeTableRow>

          <CollegeTableRow>
            <CollegeTableColumnLabel>Offers early action</CollegeTableColumnLabel>
            <CollegeTableColumnValue>{admissions[0] ? admissions[0].data["early_action_boolean"] : "-"}</CollegeTableColumnValue>
          </CollegeTableRow>

          <CollegeTableRow>
            <CollegeTableColumnLabel>Offers early decision</CollegeTableColumnLabel>
            <CollegeTableColumnValue>{admissions[0] ? admissions[0].data["early_decision_boolean"] : "-"}</CollegeTableColumnValue>
          </CollegeTableRow>

          {earlyDeadlineGroupArray && earlyDeadlineGroupArray.map((item, index)=>{
            return(
              <CollegeTableRow key={index}>
                <CollegeTableColumnLabel>{earlyDeadlineGroupLabelMap[item.label]}</CollegeTableColumnLabel>
                <CollegeTableColumnValue>{item.value}</CollegeTableColumnValue>
              </CollegeTableRow>
            )
          })}

          <CollegeTableRow>
            <CollegeTableColumnLabel>Early decision acceptance rate</CollegeTableColumnLabel>
            <CollegeTableColumnValue>{admissions[0] ? admissions[0].data["early_decision_rate"] : "-"}%</CollegeTableColumnValue>
          </CollegeTableRow>
          
        </CollegeTableItem>

        <CollegeTableItem>
          <CollegeTableTitle>Admissions Considerations</CollegeTableTitle>
          {considereationsGroupArray && considereationsGroupArray.map((item, index)=>{
            return(
              <CollegeTableRow key={index}>
                <CollegeTableColumnLabel>{considereationsGroupLabelMap[item.label]}</CollegeTableColumnLabel>
                <CollegeTableColumnValue>{item.value}</CollegeTableColumnValue>
              </CollegeTableRow>
            )
          })}

          <CollegeTableRow>
            <CollegeTableColumnLabel>College prep courses</CollegeTableColumnLabel>
            <CollegeTableColumnValue>
              {admissions[0] ? 
              admissions[0].data["college_prep_courses"] : 
              "-"}
              </CollegeTableColumnValue>
          </CollegeTableRow>

          <CollegeTableRow>
            <CollegeTableColumnLabel>SAT/ACT</CollegeTableColumnLabel>
            <CollegeTableColumnValue>
              {admissions[0] ? 
              admissions[0].data["sat_act_submission"] : 
              "-"}
              </CollegeTableColumnValue>
          </CollegeTableRow>

          <CollegeTableRow>
            <CollegeTableColumnLabel>Recommendations</CollegeTableColumnLabel>
            <CollegeTableColumnValue>
              {admissions[0] ? 
              admissions[0].data["recommendations"] : 
              "-"}
              </CollegeTableColumnValue>
          </CollegeTableRow>
          
        </CollegeTableItem>

        <CollegeTableItem>
          
          <CollegeTableRow>
            <CollegeTableColumnLabel>Website</CollegeTableColumnLabel>
            {admissions[0] &&
              <CollegeTableColumnValue>
                <a rel="noopener noreferrer" href={admissions[0].data["application_url"]}>
                  {admissions[0].data["application_url"]}
                </a>
              </CollegeTableColumnValue>
            }
          </CollegeTableRow>
            
        </CollegeTableItem>

      </CollegeTable>

    </div>
  )
};


const mapDispatchToProps = dispatch => bindActionCreators({
  push
}, dispatch)

export default connect(null, mapDispatchToProps)(AdmissionStats)