import styled from 'styled-components';
import { Search as SearchIcon } from 'styled-icons/material/Search';
import { color, typo, bp, settings } from '../../../styles';

export const FiltersButton = styled.button`
  height: 32px
  padding: 1px 10px 5px 11px;

  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.5px #707070;
  background-color: #ffffff;

  font-size: 22px;
  font-weight: 600;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #777777;

  width: calc(50% - 3px);
  @media ${bp.tabletPortUp} {
    margin-right: 17px;
    wdith: unset;
  }
`;