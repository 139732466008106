import React from 'react';
import PropTypes from 'prop-types';

// Redux stuff
import { withLifecycleActions } from 'react-redux-lifecycle'
import { Field, FieldArray, reduxForm } from 'redux-form'
import { getAdminCoachProfileIfEmpty } from '../../modules/coach';
import { listCollegesIfEmpty } from "../../modules/college";

// Styles stuff
import styled from 'styled-components';
import { color, typo, bp } from '../../styles';
import { Plus } from 'styled-icons/fa-solid/Plus';
import { X } from 'styled-icons/feather/X';
import { PencilAlt } from 'styled-icons/fa-solid/PencilAlt';

// Components
import { Heading } from 'rebass';
import Spinner from 'react-spinkit';
import { PageContainer } from '../../components';
import SmallStyledInput from '../../components/smallStyledInput'
import StyledInput from '../../components/styledInput'
import StyledSelect from '../../components/styledSelect/SmallSelect'

// Styled components
const PageContent = styled.div`
  grid-column: span 12;
  background-color: ${color.ovWhite};
  border: 1px solid ${color.ovGrey};
  padding: 25px;
  margin: 10px 0;
`;
const TitleContainer = styled.div`
  grid-column: span 12;
`;
const AboutHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: .8;
  letter-spacing: normal;
  text-align: left;
  padding: 0 10px;
  @media ${bp.phoneOnly} {
    font-size: 1.8rem;
  }
`;
const AboutHr = styled.div`
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid ${color.ovGrey};
  margin: 5px 0 0;
  padding: 0;
`;
const ActionButtonsContainer = styled.div`
  position: sticky;
  position: -webkit-sticky;
  bottom: 5vh;
  float: right;
  margin-right: -50px;
`;
const DiscardButton = styled.button`
  height: 76px;
  border-radius: 10px;
  border: solid 1px ${color.ovYellow};
  background-color: ${color.ovRed};
  transition: all .1s;
  text-align: center;
  padding: 4px 50px;
  font-size: 30px;
  color: ${color.ovWhite};
  margin-bottom: 10px;
  min-width: 210px;
  margin-left: 1rem;
  &:hover{
    background-color: ${color.ovBlue};
    color: ${color.ovWhite};
    transition: all .1s;
  }

  @media ${bp.phoneOnly} {
    height: 48px;
  }

  &:focus {
    outline: none !important;
  }
`;
const ButtonLabel = styled.p`
  font-family: ${typo.ovFontFeature};
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: center;
  padding: 0;
  @media ${bp.phoneOnly} {
    font-size: 22px;
  }
`;
const SaveButton = styled(DiscardButton)`
  border: solid 1px ${color.ovGrey};
  background-color: ${color.ovYellow};
  color: ${color.ovBlue};
`;
const ProfileHeading = styled.div`
  position: relative; 
  width: 50%;
  margin: 0 auto;
  input{
    text-align: center;
    padding: 15px 25px;
    &::placeholder{
      text-align: center;
    }
  }
`;
const EditButton = styled.button`
  cursor: pointer;
  align-self: center;
  text-align: center;
  height: 38px;
  background-color: transparent;
  border: none;
  ${ProfileHeading} &{
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 15px;
    height: 58px;
  }
`;
const CoachLabel = styled.span`
  display: inline;
  color: ${color.ovBlack};
  font-family: ${typo.ovFontSerif};
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  align-self: center;
`;
const CoachSeason = styled(Heading)`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
  font-size: 1.6rem;
  line-height: 1;
  padding: 0 1rem;
  margin: 1rem 0;

  @media ${bp.phoneOnly} {
    font-size: 2rem;
  }
`;

const CoachContainer = styled.div`
  display:block;
`;

const CoachItem = styled.div`
  display:grid;
  padding: 1rem 1rem 0.2rem;
  border-radius: .2rem;
  grid-template-columns: 10% 35% 50% 5%;
  p{
    padding: 0 10px 0 0;
  }
`;
const CoachTable = styled(CoachItem)`
  cursor: default;
  color: ${color.ovBlue};
  font-size: 20px;
  &:hover{
    background: transparent;
  }
`;
const ProfileSubHeading = styled(CoachLabel)`
  text-align: center;
  display: block;
`;
const ErrorMessage = styled.div`
  display: block;
  color: ${color.ovRed};
  margin-top: -17px;
  text-align: left;
  padding: 10px 0;
`;
const NewCoachItem = styled.div`
  padding: 0rem 1rem 0 1rem;
  display: grid;
  grid-template-columns: 10% 35% 22% 22% 6%;
  div {
    padding: 1rem 1rem 1rem 0;
  }
`;
const AddRowButton = styled.button`
  height: 39px;
  border-radius: 10px;
  border: solid 1px ${color.ovBlue};
  background-color: ${color.ovWhite};
  transition: all .1s;
  text-align: center;
  padding: 8px 20px;
  position: relative;
  margin: 1rem 1rem 1rem 0;
  width: 130px;
  &:hover{
    background-color: ${color.ovBlue};
    color: ${color.ovWhite};
    transition: all .1s;
  }
`;
const AddRowButtonLabel = styled.p`
  font-family: ${typo.ovFontFeature};
  color: ${color.ovBlue};
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: .9;
  letter-spacing: normal;
  text-align: center;
  ${AddRowButton}:hover &{
    color: ${color.ovWhite};
  }
  @media ${bp.phoneOnly} {
    font-size: 22px;
  }
`;
const StyledPlus = styled(Plus)`
  width: 13px;
  color: ${color.ovBlue};
  margin-right: 8px;
  position: absolute;
  right: 7px;
  top: 12px;
  ${AddRowButton}:hover &{
    color: ${color.ovWhite};
  }
  @media ${bp.phoneOnly} {
    top: 17px;
  }
`;
const StyledClose = styled(X)`
  width: 13px;
  color: ${color.ovBlue};
  margin-right: 8px;
  position: absolute;
  right: 7px;
  top: 12px;
  ${AddRowButton}:hover &{
    color: ${color.ovWhite};
  }
  @media ${bp.phoneOnly} {
    top: 17px;
  }
`;
const StyledEdit = styled(PencilAlt)`
  width: 13px;
  color: ${color.ovGrey};
  @media ${bp.phoneOnly} {
    top: 17px;
  }
`;
const DeleteButton = styled(AddRowButton)`
  width: auto;
`;
// end of Styled Components
// InputField
const smallInputField = ({ input, label, type, defaultValue, readOnly, value, meta: { touched, active, error, warning } }) => (
  <div>
    <SmallStyledInput
      {...input}
      placeholder={label}
      type={type}
      readOnly={readOnly}
    />
    {touched && ((error && <ErrorMessage>{error}</ErrorMessage>) || (warning && <ErrorMessage>{warning}</ErrorMessage>))}
  </div>
);
const inputField = ({ input, label, type, meta: { touched, active, error, warning } }) => (
  <div>
    <StyledInput
      {...input}
      placeholder={label}
      type={type}
    />
    {touched && ((error && <ErrorMessage>{error}</ErrorMessage>) || (warning && <ErrorMessage>{warning}</ErrorMessage>))}
  </div>
);
const selectField = ({ input, options, clearable, meta: { touched, active, error }, label }) => {
  const selected = options.find(o => o.value === input.value);

  return (
    <div>
      <StyledSelect
        instanceId={input.name}
        {...input}
        value={selected}
        options={options}
        clearable={clearable}
        onBlur={() => input.onBlur}
        placeholder={label}
      />
      {touched && !active && error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  )
};
// end of InputField

const renderSeasons = ({ fields, options, meta: { touched, error } }) => {
  return (
    <div>
      {fields.map((coachedSeason, index) =>
        <NewCoachItem key={`${coachedSeason.teamName} ${index}`}>
          <Field
            name={`${coachedSeason}.year`}
            label="Year"
            component={smallInputField}
            type="number"
            validate={[required]}
          />
          <Field
            name={`${coachedSeason}.teamId`}
            label="Team"
            component={selectField}
            type="text"
            options={options}
            normalize={({value}) => value}
            validate={[required]}
          />
          <Field
            name={`${coachedSeason}.title0`}
            label="Job title 1"
            component={smallInputField}
            type="text"
            validate={[required]}
          />
          <Field
            name={`${coachedSeason}.title1`}
            label="Job title 2"
            component={smallInputField}
            type="text"
          />
          <DeleteButton
            type="button"
            title="Remove Member"
            onClick={() => fields.remove(index)}
          >
            <StyledClose />
          </DeleteButton>
        </NewCoachItem>
      )}
      <AddRowButton type="button" onClick={() => fields.push({})}>
        <AddRowButtonLabel>Add row<StyledPlus /></AddRowButtonLabel>
      </AddRowButton>
      {touched && error && <span>{error}</span>}
    </div>
  )
}
// Validation
const required = value => value ? undefined : 'Required';

let EditCoach = ({
  isAuthenticated,
  isFetching,

  handleSubmit,
  initialValues,
  colleges,
  saveCoach
}) => {
  const collegeOpts = colleges.map(e => ({ label: e.name, value: e.ID }));
  const { ID, coachedSeasons } = initialValues;

  return (
    <div>

      {isFetching &&
        <PageContainer>
          <PageContent>
            <Spinner fadeIn="none" name="cube-grid" />
          </PageContent>
        </PageContainer>
      }
      {!isFetching &&
        <form onSubmit={handleSubmit((data) => { saveCoach(ID, data, coachedSeasons) })}>
          <PageContainer>
            <PageContent>
              <ProfileHeading as="h1">
                <Field
                  name="coachName"
                  label="Coach Name"
                  component={inputField}
                  type="text"
                  validate={[required]}
                />
                <EditButton><StyledEdit /></EditButton>
              </ProfileHeading>
              <ProfileHeading as="h3">
                <Field
                  name="coachUrlSlug"
                  label="Coach URL slug"
                  component={inputField}
                  type="text"
                  validate={[required]}
                />
                <EditButton><StyledEdit /></EditButton>
              </ProfileHeading>
              <ProfileSubHeading as="h1">
                {coachedSeasons.length > 0 &&
                  `${coachedSeasons[0].title0} - ${coachedSeasons[0].teamName}`
                }
              </ProfileSubHeading>
            </PageContent>

            <PageContent>
              <TitleContainer>
                <AboutHeading>Coaching History</AboutHeading>
                <AboutHr />
              </TitleContainer>
              <div>
                <CoachSeason>
                  <CoachContainer>
                    <CoachTable>
                      <p>Year</p>
                      <p>Team</p>
                      <p>Job Title</p>
                    </CoachTable>

                    <FieldArray name="coachedSeasons" options={collegeOpts} component={renderSeasons} />

                  </CoachContainer>
                </CoachSeason>
              </div>
              <ActionButtonsContainer>
                {/* <DiscardButton> */}
                {/*   <ButtonLabel>Discard Changes</ButtonLabel> */}
                {/* </DiscardButton>  */}
                <SaveButton type="submit">
                  <ButtonLabel>Save Changes</ButtonLabel>
                </SaveButton>
              </ActionButtonsContainer>
            </PageContent>
          </PageContainer>
        </form>
      }
    </div>
  )
}

EditCoach.propTypes = {
  getAdminCoachProfileIfEmpty: PropTypes.func.isRequired,
  listCollegesIfEmpty: PropTypes.func.isRequired,
  saveCoach: PropTypes.func.isRequired
};

EditCoach = reduxForm({
  // a unique name for the form
  form: 'editCoach',
  enableReinitialize: true
})(EditCoach)

const checkSession = ({ isAuthenticated, requestLogin, match }) => {
  if (!isAuthenticated) {
    return requestLogin(match.url);
  }

  return {type: 'NOOP'};
};

export default withLifecycleActions({ componentDidMount: [checkSession, getAdminCoachProfileIfEmpty, listCollegesIfEmpty] })(EditCoach);
