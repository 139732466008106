import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import moment from 'moment'
import { Field, reduxForm } from 'redux-form';
import { Col, Row } from 'react-bootstrap';
import StyledInput from '../../components/styledInput'
import StyledSelect from '../../components/styledSelect';

import {
  TitleHeading,
  P,
  TermsAndPrivacyLabel,
  CheckboxContainer,
  ErrorMessage,
  CheckboxWarning,
  TermsAndPrivacyLinks,
  NextButton,
  TermsAndConditionsBox
} from './Styled'

// Checkbox
const Checkbox = ({ input, label, meta: { touched, active, error, warning }}) => (
    <div className="flex items-center mv4 w-100">
      <CheckboxContainer
        {...input}
        className="mr2"
        type="checkbox"
        checked={input.value}
      />
      <div className="sans-serif">{label}</div>
      <CheckboxWarning>
        {touched && ((error && <ErrorMessage>{error}</ErrorMessage>) || (warning && <ErrorMessage>{warning}</ErrorMessage>))}
      </CheckboxWarning>
    </div>
);
// end of Checkbox
// 
// InputField
const inputField = ({ input, label, type, meta: { touched, active, error, warning } }) => (
  <div >
    <StyledInput {...input} placeholder={label} type={type} />
    {touched && ((error && <ErrorMessage>{error}</ErrorMessage>) || (warning && <ErrorMessage>{warning}</ErrorMessage>))}
  </div>
);

const selectField = ({ input, label, options, meta: { touched, active, error, warning } }) => (
  <Fragment>
    <StyledSelect
      {...input} placeholder={label}
      options={options}
    >

    </StyledSelect>
    {touched && ((error && <ErrorMessage>{error}</ErrorMessage>) || (warning && <ErrorMessage>{warning}</ErrorMessage>))}
  </Fragment>
);
// end of InputField
// 

/////////////////////////////////////////////////////////
/// Validations
/////////////////////////////////////////////////////////

const normalizeDate = (value, previousValue, allValues) => {
  const month = allValues.birthmonth || {value: 0};
  const lastDay = moment().month(month.value).endOf('month').get('date');
  return value.value < lastDay 
    ? value
    : { value: lastDay, label: lastDay };
};

const required = value => value ? undefined : 'Required';

const format = value => {
  let validDate = moment(value, "YYYY-M-D", true).isValid();
  let validDateResult = validDate ? undefined : 'Please, enter a valid date'
  return validDateResult;
}

const validate = values => {
  if (!values.birthyear || !values.birthmonth || !values.birthdate) return;
  const { value: year } = values.birthyear;
  const { value: month } = values.birthmonth;
  const { value: day } = values.birthdate;

  const birthdate = moment({ year, month, day});
  const invalidDate = format(birthdate.format('YYYY-M-D'));
  if (invalidDate) {
    return { birthdate: invalidDate};
  }
  
  if (birthdate.clone().add(13, 'y').isAfter()) {
    return { birthyear: 'You must be 13 or older to register'};
  }
}

/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////

let RegisterForm = props => {
  const { handleSubmit} = props;

  return <form id='college-details-edit-form' onSubmit={handleSubmit}>
    <div>
      <div>
        <Field 
          name="email" 
          label="Email" 
          component={inputField} 
          type="email" 
          validate={[required]}
        />
      </div>
      <div>
        <Field 
          name="password" 
          label="Password" 
          component={inputField} 
          type="password" 
          validate={[required]}/>
      </div>
      <Row>
        <Col xs={6}>
          <Field 
            name="firstName" 
            label="First Name" 
            component={inputField} 
            type="text" 
            validate={[required]}
          />
        </Col>
        <Col xs={6}>
          <Field 
            name="lastName" 
            label="Last Name" 
            component={inputField} 
            type="text" 
            validate={[required]}
          />
        </Col>
      </Row>
      <div>
        <TitleHeading>Birthday</TitleHeading>
        <P>To sign up, you must be 13 or older. Other people won’t see your birthday.</P>

        <Row>
          <Col xs={12} sm={4}>
            <Field
              name="birthmonth"
              component={selectField}
              type="select"
              options={range(0, 12).map(item => ({ value: item, label: moment().month(item).format('MMMM')}))}
              validate={[required]}
              label="Month"
            />
          </Col>
          <Col xs={6} sm={4}>
            <Field
              name="birthdate"
              component={selectField}
              type="select"
              options={range(1, 32).map(item => ({ value: item, label: item }))}
              validate={[required]}
              normalize={normalizeDate}
              label="Day"
            />
          </Col>
          <Col xs={6} sm={4}>
            <Field
              name="birthyear"
              component={selectField}
              type="select"
              options={range(moment().get('y') - 13, moment().get('y') - 100).map(item => ({ value: item, label: item }))}
              validate={[required]}
              label="Year"
            />
          </Col>
        </Row>
      </div>
    </div>
    <TermsAndConditionsBox>
      <Field
        id="acceptedTermsAndConditions"
        name="acceptedTermsAndConditions"
        component={Checkbox}
        type="checkbox"
        validate={[required]}
      />
      <TermsAndPrivacyLabel>
        By checking this box, I agree to OfficialVisit’s <TermsAndPrivacyLinks onClick={()=>window.open("/terms")}>terms and conditions</TermsAndPrivacyLinks> and <TermsAndPrivacyLinks onClick={()=>window.open("/privacy")}>privacy policy</TermsAndPrivacyLinks>.
      </TermsAndPrivacyLabel>
    </TermsAndConditionsBox>
    <NextButton type="submit" onClick={handleSubmit}>Next</NextButton>
  </form>
}

RegisterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

RegisterForm = reduxForm({
  // a unique name for the form
  form: 'register',
  validate,
})(RegisterForm)

export default RegisterForm;
