import React from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import CoachCard from './CoachCard';

const mapDispatchToProps = dispatch => bindActionCreators({
  push,
}, dispatch);

export default connect(null, mapDispatchToProps)(CoachCard);

