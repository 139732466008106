import sortBy from 'lodash/fp/sortBy';
import orderBy from 'lodash/fp/orderBy';
import map from 'lodash/fp/map';
import compose from 'lodash/fp/compose';
import find from 'lodash/find';
import filter from 'lodash/filter';
import { createSelector } from 'reselect';

export const baseSelector = ({ college }) => college;

export const collegesSelector = createSelector(
  baseSelector,
  ({ colleges }) => colleges,
);

const rank = compose(
  sortBy(['college_rank', 'formal_name']),
  map(college => {
    const stat = find(college.Stats, {title: 'ranks'});
    const { data: [value]} = stat;
    const { value: college_rank} = value;
    return { ...college, college_rank};
  })
);

const toRankNumber = value => {
  let num = value || 999; // Otherwise it is too long
  if (typeof value === 'string') {
    // eslint-disable-next-line
    num = /^[0-9\.,]+$/.test(value) ? parseInt(value) : 999;
  }

  return num;
};


export const rankByOfficialRankingSelector = createSelector(
  collegesSelector,
  (colleges) => {
    if (!colleges || !colleges.length) return [];
    const ranked = rank(colleges);
    return ranked;
  }
);

const weatherRank = compose(
  sortBy(['college_rank', 'formal_name']),
  map(college => {
    const stat = find(college.Stats, {title: 'weather'});
    const { data: [value]} = stat;
    let { rank: college_rank, value: temperature } = value;
    college_rank = toRankNumber(college_rank);
    return { ...college, college_rank, temperature };
  })
);

export const rankByWeatherSelector = createSelector(
  collegesSelector,
  (colleges) => {
    if (!colleges || !colleges.length) return [];
    const ranked = weatherRank(colleges);
    return ranked;
  }
);

const largestRank = compose(
  sortBy(['college_rank', 'formal_name']),
  map(college => {
    const stat = find(college.Stats, {title: 'enrollment'});
    const { data: [value]} = stat;
    let { rank: college_rank, value: enrollment } = value;
    college_rank = toRankNumber(college_rank);
    return { ...college, college_rank, enrollment };
  })
);

export const rankByLargestSelector = createSelector(
  collegesSelector,
  (colleges) => {
    if (!colleges || !colleges.length) return [];
    const ranked = largestRank(colleges);
    return ranked;
  }
);

const graduationSuccessRate = compose(
  sortBy(['college_rank', 'formal_name']),
  map(college => {
    const { teams: [ team ]} = college;

    const { data: [ gsr, black, white ]} = find(team.stats, { title: 'graduation'} );

    return {
      ...college,
      college_rank: toRankNumber(gsr.rank),
      rate: gsr.value,
      
      black: {
        college_rank: toRankNumber(black.rank),
        rate: black.value,
      },

      white: {
        college_rank: toRankNumber(white.rank),
        rate: white.value,
      },
    };
  })
);

export const rankByGraduationSuccessSelector = createSelector(
  collegesSelector,
  (colleges) => {
    if (!colleges || !colleges.length) return [];
    const ranked = graduationSuccessRate(colleges);
    return ranked;
  }
);

const postgraduateEarningsRate = compose(
  sortBy(['college_rank', 'formal_name']),
  map(college => {

    const filtered = find(college.Stats, { title: 'cost' });
    if (!filtered) return {
      ...college,
      college_rank: 999,
      rate: 0
    }
    // eslint-disable-next-line
    const { data: [tis, tos, eac] } = filtered;
    return {
      ...college,
      college_rank: toRankNumber(eac.rank),
      rate: eac.value
    };
    
  })
);

export const rankByHighestPostgraduateEarningsSelector = createSelector(
  collegesSelector,
  (colleges) => {
    if (!colleges || !colleges.length) return [];
    const ranked = postgraduateEarningsRate(colleges);
    return ranked;
  }
);

const graduationSuccessRateSpecial = (race) => compose(
  sortBy(['college_rank', 'formal_name']),
  map(college => ({
    ...college,
    college_rank: toRankNumber(college[race].college_rank),
  })),
  graduationSuccessRate,
);

export const rankByGraduationSuccessSpecialSelector = (race) => createSelector(
  collegesSelector,
  (colleges) => {
    if (!colleges || !colleges.length) return [];
    const ranked = graduationSuccessRateSpecial(race)(colleges);
    return ranked;
  }
);

const inStateTuitionRank = compose(
  sortBy(['college_rank', 'formal_name']),
  map(college => {
    const stat = find(college.Stats, {title: 'cost'});
    if ( !stat ) {
      // eslint-disable-next-line
      return { ...college, college_rank, inStateTuition: 0};
    }
    // eslint-disable-next-line
    const { data: [ tis, tos, eac ]} = stat;
    let { rank: college_rank, value: inStateTuition} = tis;
    college_rank = toRankNumber(college_rank);
    return { ...college, college_rank, inStateTuition };
  })
);

export const inStateTuitionSelector = createSelector(
  collegesSelector,
  (colleges) => {
    if (!colleges || !colleges.length) return [];
    const ranked = inStateTuitionRank(colleges);
    return ranked;
  }
);

const outStateTuitionRank = compose(
  sortBy(['college_rank', 'formal_name']),
  map(college => {
    const stat = find(college.Stats, {title: 'cost'});
    if ( !stat ) {
      // eslint-disable-next-line
      return { ...college, college_rank, outStateTuition: 0};
    }
    // eslint-disable-next-line
    const { data: [ tis, tos, eac ]} = stat;
    let { rank: college_rank, value: outStateTuition} = tos;

    college_rank = toRankNumber(college_rank);
    return { ...college, college_rank, outStateTuition };
  })
);

export const outStateTuitionSelector = createSelector(
  collegesSelector,
  (colleges) => {
    if (!colleges || !colleges.length) return [];
    const ranked = outStateTuitionRank(colleges);
    return ranked;
  }
);

const largestStadiumsRank = compose(
  sortBy(['college_rank', 'formal_name']),
  map(college => {
    const { teams: [ team ]} = college;

    let { data: [{ rank: college_rank, value: largestStadium }]} = find(team.stats, { title: 'stadium'} );
    college_rank = toRankNumber(college_rank);
    return {
      ...college,
      college_rank,
      largestStadium,
    };
  })
);

export const rankByLargestStadiumsSelector = createSelector(
  collegesSelector,
  (colleges) => {
    if (!colleges || !colleges.length) return [];
    const ranked = largestStadiumsRank(colleges);
    return ranked;
  }
);

export const collegeByIdSelector = (id) => createSelector(
  collegesSelector,
  (colleges) => {
    if (!colleges || !colleges.length) return null;
    const college = colleges.find(college => college.id === id);
    return college;
  }
);

const orderByDateDesc = compose(
  orderBy(['created_at'], ['desc'])
)

const SIZE = 30;
export const pageSelector = (list, size=SIZE, order) => (page) => {
  if (!list) return list;
  const orderedList = orderByDateDesc(list)
  const total = page * size;
  if (order){
    return orderedList.slice(0, total);
  }else{
    return list.slice(0, total);
  }
};

export const groupedMajorsSelector = createSelector(
  baseSelector,
  ({ majors }) => {
    if (!majors) return null;
    const mapped = 
      filter(majors, { is_available: true })
      .reduce((memo, major) => {
        const { department: departmentKey, subject: subjectKey, specialization: specializationKey, degree_abbr: degreeKey } = major;
        const department = memo[departmentKey] = memo[departmentKey] || {};
        const subject = department[subjectKey] = department[subjectKey] || {};
        subject[`${specializationKey} (${degreeKey})`] = degreeKey;

        return memo;
      }, {});

    return mapped;
  }  
)

export const collegeSelector = (contribution, colleges) => {
  const { contributable_type: type, contributable_id: id } = contribution;
  if (colleges && colleges.length && type === 'colleges') {
    const college = colleges.find(college => college.ID === id);
    return college;
  };
};

export const collegeNameSelector = (contribution, colleges) => {
  const college = collegeSelector(contribution, colleges);
  if (college) {
    return college.name;
  };
};

export const collegeSlugSelector = (contribution, colleges) => {
  const college = collegeSelector(contribution, colleges);
  if (college) {
    return college.url_slug;
  };
};

export const collegeTeamSelector = (contribution, colleges) => {
  const { contributable_type: type, contributable_id: id } = contribution;
  if (colleges && colleges.length && type === 'teams') {
    const college = colleges.find(college => college.ID === id);
    if (college) {
      return `${college.name} ${college.nickname}`;
    };
  };
};

export const collegeTeamSlugSelector = (contribution, colleges) => {
  const { contributable_type: type, contributable_id: id } = contribution;
  if (colleges.length && type === 'teams') {
    const college = colleges.find(college => college.ID === id);
    if (college) {
      return college.url_slug;;
    };
  };
};