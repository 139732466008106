import { Heading } from 'rebass';
import styled, { css } from 'styled-components';
import { UserCircle } from 'styled-icons/fa-solid/UserCircle';
import { ReactComponent as CheckCircle } from '../../assets/icons/verified.svg';
import { color, typo } from '../../styles'

import backgroundImg from '../../assets/img/profile-bg.jpg';

export const ProfileHeadingCenter = styled(Heading)`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontAlt};

  font-size: 27px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.78;
  letter-spacing: normal;
  text-align: center;

  margin-bottom: 20px;
  text-transform: uppercase;
`;

export const ProfileSubHeadingCenter = styled(Heading)`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontAlt};

  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;

  text-transform: uppercase;
`;

export const ProfileSubHeadingSerif = styled(Heading)`
  font-family: ${typo.ovFontSerif};
  font-size: 18px;
  color: ${color.ovBlack};
  text-align: center;
`

export const ProfileEditLink = styled(Heading)`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
`;

export const ContentSpacer = styled.div`
  background-color: transparent;
  margin: 20px 0;
`;

export const PageContent = styled.div`
  grid-column: span 12;

  background-color: ${color.ovWhite};
  border: 1px solid ${color.ovGrey};
  padding: 25px;

  margin: 10px 0;
`;

export const UserHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: -25px -25px 20px -25px ;
  height: 180px;
  border-bottom: 1px solid ${color.ovGrey};
  background-image: url(${backgroundImg});
  background-size: cover;
`;

export const UserAvatar = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 160px;
  margin-bottom: -15px;
  border: 6px solid ${color.ovWhite};
  background-color: ${color.ovGreyLight};
  border-radius: 40px;
  background-size: cover;
  background-position: center;
  box-shadow: 0 4px 10px rgba(0,0,0,.2);

  ${UserCircle} {
    color: ${color.ovGrey};
    height: 140px;
  }

  ${({ avatar }) => avatar && css`
    background-image: url(${avatar});
    ${UserCircle} {
      display: none;
      height: 140px;
    }
  `};

  :not([data-blocked=true]):hover:after {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    content: 'Change picture';
    color: ${color.ovWhite};
    background: rgba(0,0,0,.3);
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

export const UserName = styled.div`
  padding: 20px;
  :not(:last-child) {
    margin-bottom: 20px;
    border-bottom: 2px dashed ${color.ovGrey};
  }
`;

export const StyledCheck = styled(CheckCircle)`
  width: 27px;
  color: ${color.ovBlack};
  margin-right: 8px;
  position: relative;
  top: 4px;
  margin-left: 8px;
`;