import find from 'lodash/find';
import compose from 'lodash/fp/compose';
import sortBy from 'lodash/fp/sortBy';
import map from 'lodash/fp/map';
import { groupBy, uniqBy } from 'lodash';

import { createSelector } from 'reselect';

import { collegesSelector } from '../college/selectors';

export const baseSelector = ({ user }) => user;

export const userSelector = createSelector(
  baseSelector,
  ({ user }) => user,
);

export const profileSelector = createSelector(
  baseSelector,
  ({ profile }) => profile,
);

export const isCurrentCollegeStudentSelector = createSelector(
  userSelector,
  profileSelector,
  ({ college }) => {
    if (! college ) return {};

    return college.college;
  },

  (user, profile, college) => {
    if (!user.role || !profile.semesters ) return;
    const [firstElement = {}] = profile.semesters
    if (firstElement.college_refer && firstElement.college_refer === college.ID) {
      return true;
    };
  },
);

export const userRoleSelector = createSelector(
  userSelector,
  ({ role }) => role,
);

export const userListSelector = createSelector(
  baseSelector,
  ({ list }) => list
);

export const isFetchingListSelector = createSelector(
  baseSelector,
  ({ isFetchingList }) => isFetchingList
);

export const baseTopColleges = createSelector(
  userSelector,
  profileSelector,
  collegesSelector,

  (user, profile, colleges) => ({ user, profile, colleges}),
);

export const isFetchingTopCollegesSelector = createSelector(
  baseTopColleges,
  ({user, profile, colleges}) => !user.ID || !profile.ID || !colleges.length,
);

const mergeTopSchools = (colleges) => compose(
  map(top => ({
    ...top,
    ...find(colleges, { ID: top.college_refer }),
  })),
  sortBy(['order']),
);

export const profileTopCollegesSelector = createSelector(
  isFetchingTopCollegesSelector,
  baseTopColleges,
  (isFetching, {profile, colleges} ) => {
    if (isFetching) return profile;
    
    return {
      ...profile,
      top_schools: mergeTopSchools(colleges)(profile.top_schools),
    }
  }
);

export const filterSelector = (list) => (filter) => {
  if (filter) {
    const query = filter.toLowerCase();
    return list.filter(user => {
      return (
        user.display_name.toLowerCase().includes(query)
        || user.first_name.toLowerCase().includes(query)
        || user.last_name.toLowerCase().includes(query)
        || user.email.toLowerCase().includes(query)
      );
    });
  }

  return list;
};

const SIZE = 30;
export const pageSelector = (list) => (page) => {
  if (!list) return list;
  const total = page * SIZE;
  return list.slice(0, total);
};


export const userByIdSelector = ID => createSelector(
  userListSelector,
  list => list && find(list, { ID }),
);

export const contributionsByTypeSelector = contributions => {
  if (contributions.length) {
    const normalizeReviewList = contributions.map(contribution => {
      if (contribution.reviews) {
        return { ...contribution, reviews: uniqBy(contribution.reviews, 'rating_key')}
      };
      return contributions;
    });
    return groupBy(normalizeReviewList, 'contributable_type');
  }
};
