import React from 'react'
import PropTypes from 'prop-types';
import moment from "moment";
// Rediux stuff
import { Field, reduxForm } from 'redux-form'
import { Col, Row } from "react-bootstrap";
// Components
import StyledSelect from '../../components/styledSelect';

import {
  ErrorMessage,
  TitleHeading,
  TermsAndConditionsBox,
  CheckboxContainer,
  CheckboxLabel,
  CheckboxWarning,
  NextButton
} from './Styled'

// Select Field
const selectField = ({ input, options, clearable, meta: { touched, active, error } , label}) => (
  <div>
    <StyledSelect
      instanceId={input.name}
      {...input}
      options={options}
      clearable={clearable}
      onBlur={() => input.onBlur}
      placeholder={label}
    />
    {touched && !active && error && <ErrorMessage>{error}</ErrorMessage>}
  </div>
);
// end of Select Field
// 
// Checkbox
const Checkbox = ({ input, label, meta: { touched, active, error, warning }}) => (
    <div className="flex items-center mv4 w-100">
      <CheckboxContainer
        {...input}
        className="mr2"
        type="checkbox"
        checked={input.value}
      />
      <div className="sans-serif">{label}</div>
      <CheckboxWarning>
        {touched && ((error && <ErrorMessage>{error}</ErrorMessage>) || (warning && <ErrorMessage>{warning}</ErrorMessage>))}
      </CheckboxWarning>
    </div>
);
// end of Checkbox
// 
// Validation
const required = value => value ? undefined : 'Required';

let Step2 = props => {
  const {
    handleSubmit,
    currentStep
  } = props;

  // Will hide the component if the current step is not equal to 2
  if (currentStep !== 2) { return null }

    // Semesters Options
  const semestersOpts = [
    { label: "Fall", value: "Fall" },
    { label: "Spring", value: "Spring" },
    { label: "Summer", value: "Summer" },
    { label: "Winter", value: "Winter" }
  ];
  // Years Options
  const plusYears = 9;
  const year = moment().year() + plusYears;
  const numYears = 50 + plusYears;
  const yearsOpts = Array.from(Array(numYears).keys()).map(el => ({
    value: year - el,
    label: year - el
  }));

  return (
    <form onSubmit={handleSubmit}>
      <TitleHeading>When was your first semester?</TitleHeading>
      <Row>
        <Col md={12} sm={6}>
          <Field 
            name="first_semester" 
            label="Semester" 
            component={selectField} 
            type="text" 
            options={semestersOpts}
            validate={[required]}
          />
        </Col>
        <Col md={12} sm={6}>
          <Field 
            name="first_year" 
            label="Year" 
            component={selectField} 
            type="text" 
            options={yearsOpts}
            validate={[required]}
          />
        </Col>
      </Row>
      <TitleHeading>When was/is your last semester?</TitleHeading>
      <Row>
        <Col md={12} sm={6}>
          <Field 
            name="second_semester" 
            label="Semester" 
            component={selectField} 
            type="text" 
            options={semestersOpts}
            validate={[required]}
          />
        </Col>
        <Col md={12} sm={6}>
          <Field 
            name="second_year" 
            label="Year" 
            component={selectField} 
            type="text" 
            options={yearsOpts}
            validate={[required]}
          />
        </Col>
      </Row>
      <TermsAndConditionsBox>
        <Field
          id="currentStudent"
          name="currentStudent"
          component={Checkbox}
          type="checkbox"
        />
        <CheckboxLabel>
          I am currently a student
        </CheckboxLabel>
      </TermsAndConditionsBox>
      <TermsAndConditionsBox>
        <Field
          id="graduated"
          name="graduated"
          component={Checkbox}
          type="checkbox"
        />
        <CheckboxLabel>
          I graduated
        </CheckboxLabel>
      </TermsAndConditionsBox>
      <div>
        <NextButton type="submit">Next</NextButton>
      </div>
    </form>
  )
}

Step2.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

Step2 = reduxForm({
  // a unique name for the form
  form: 'AthleteForm',
  destroyOnUnmount: false
})(Step2)

export default Step2