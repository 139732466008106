import React from 'react';
import Icons from './CardIcons';

import {
  CollegeCardBarIconBox,
  CardBarIcon
} from './Styled'

const CollegeCardBarIcon = ({
  collegeCardTitle,
  type
}) => {
  return(
    <CollegeCardBarIconBox color={collegeCardTitle} type={type}>
      <CardBarIcon src={Icons[type ? type.replace(/\s/gi, '_') : collegeCardTitle.replace(/\s/gi, '_')]}/>
    </CollegeCardBarIconBox>
  )
}

export default CollegeCardBarIcon;
