import React from 'react';
import styled from 'styled-components';
import { X } from 'styled-icons/feather/X';
import { color } from '../styles';


const FloatRightCloseButton = styled(X)`
  position: relative;
  display: inline;
  right: 4px;
  top: 5px;
  color: ${color.ovBlue};
  width: 25px;
  cursor: pointer;
  z-index: 1;
  float: right;
`;

export default ({ onClick }) => <FloatRightCloseButton onClick={onClick} />;