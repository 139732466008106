import styled from 'styled-components';
import { Heading } from 'rebass';
import { color, typo } from '../../styles';
import Scroll from 'react-scroll';
var Link = Scroll.Link;

export const ReviewContextHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};

  font-size: 36px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.78;
  letter-spacing: normal;
  text-align: center;

  margin-top: 40px;
  margin-bottom: 40px;

`;

export const CloseBar = styled.div`
  background: ${color.ovGreyDark};
  height: 36px;
  width: 100%;
  position: absolute;
  top: 62px;
`;

export const List = styled.ul`
  grid-column: span 12;
  padding: 0;
  margin: 0;
`;

export const OVButton = styled(Link)`
  border: 3px solid ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 16px;
  background: ${color.ovBlue};
  border-radius: 0px;
  color: ${color.ovWhite};
  cursor: pointer;
  padding: 2px 40px;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.22;
  letter-spacing: 1px;
  &:hover, &:focus {
    text-decoration: none;
    background: ${color.ovBlueLight};
    border-color: ${color.ovBlueLight};
  }
`;

export const OVButtonRight = styled(OVButton)`
  float: right;
`;

export const PageContent = styled.div`
  grid-column: span 12;
`;

export const CenterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
