import React from 'react';
import CollegeSearchNavbarFilterForm from './searchNavbarFilter'
import styled from 'styled-components';
// Redux stuff
import { bindActionCreators } from 'redux'; 
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

const SearchNavbarFilterContainer = styled.div`
  padding: 68px 30px 30px 30px;
  height: 175px;
  background-color: white;
  position: relative;
`;

const SearchNavbar = ({
  push,
  focused,
}) => (
    <SearchNavbarFilterContainer>
      <CollegeSearchNavbarFilterForm 
        onSubmit={() => push('/search')}
        focused={focused}
      />
    </SearchNavbarFilterContainer>
);

const mapDispatchToProps = dispatch => bindActionCreators({
  push
}, dispatch)

export default connect(null, mapDispatchToProps)(SearchNavbar)