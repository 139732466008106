import styled from 'styled-components';
import { color, typo } from '../../styles'

import { ReactComponent as One } from './ratingIcons/1.0.svg';
import { ReactComponent as Zero } from './ratingIcons/0.0.svg';
import { ReactComponent as DotOne } from './ratingIcons/0.1.svg';
import { ReactComponent as DotTwo } from './ratingIcons/0.2.svg';
import { ReactComponent as DotThree } from './ratingIcons/0.3.svg';
import { ReactComponent as DotFour } from './ratingIcons/0.4.svg';
import { ReactComponent as DotFive } from './ratingIcons/0.5.svg';
import { ReactComponent as DotSix } from './ratingIcons/0.6.svg';
import { ReactComponent as DotSeven } from './ratingIcons/0.7.svg';
import { ReactComponent as DotEight } from './ratingIcons/0.8.svg';
import { ReactComponent as DotNine } from './ratingIcons/0.9.svg';

export const SizedOne = styled(One)`
  height: 1.3rem;
  margin-right: 0.3rem;
`;

export const SizedZero = styled(Zero)`
  height: 1.3rem;
  margin-right: 0.3rem;
`;

export const SizedDotOne = styled(DotOne)`
  height: 1.3rem;
  margin-right: 0.3rem;
`;

export const SizedDotTwo = styled(DotTwo)`
  height: 1.3rem;
  margin-right: 0.3rem;
`;

export const SizedDotThree = styled(DotThree)`
  height: 1.3rem;
  margin-right: 0.3rem;
`;

export const SizedDotFour = styled(DotFour)`
  height: 1.3rem;
  margin-right: 0.3rem;
`;

export const SizedDotFive = styled(DotFive)`
  height: 1.3rem;
  margin-right: 0.3rem;
`;

export const SizedDotSix = styled(DotSix)`
  height: 1.3rem;
  margin-right: 0.3rem;
`;

export const SizedDotSeven = styled(DotSeven)`
  height: 1.3rem;
  margin-right: 0.3rem;
`;

export const SizedDotEight = styled(DotEight)`
  height: 1.3rem;
  margin-right: 0.3rem;
`;

export const SizedDotNine = styled(DotNine)`
  height: 1.3rem;
  margin-right: 0.3rem;
`;

export const AverageBox = styled.span`
  display: inline-flex;
  align-items: center;
`; 

export const AverageLabel = styled.span`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 18px;
  font-weight: 600;
  ${({ big }) => big && `
    font-family: ${typo.ovFontFeature};
    font-size: 26px;
    font-weight: 600;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: ${color.ovBlue};
  `}
`;

export const AverageLabelCard = styled(AverageLabel)`
  float: left;
  font-weight: bold;
`;

export const AverageRating = styled.span`
  display: flex;
  margin: 0 5px;
  padding-top: 1px;
  ${({ big }) => big && `
    padding-top: 3px;
    svg{
      height: 1.9rem;
    }
  `}
`;

export const AverageRatingEmpty = styled.span`
  float: left;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const AverageRatingCard = styled(AverageRating)`
  display: block;
  float: left;
  margin-top: 1px;
`;