import React from 'react';
import Interpolate from '../../Interpolate';
import {
  PostItem,
  PostItemData,
  Title,
  FeaturedImage,
  Author,
  Excerpt,
  PostButton,
} from './Styled'

const Item = ({
  title,
  featuredImage,
  summary,
  author,
  slug,
  push,
}) => {
  return (
    <PostItem>
      <FeaturedImage>
        <img src={featuredImage} alt={title} />
      </FeaturedImage>
      <PostItemData>
        <Title>{title}</Title>
        <Author>{`${author.firstName} ${author.lastName}`}</Author>
        <Excerpt>
          <Interpolate body={summary} />
        </Excerpt>
        <PostButton onClick={() => push(`/blog/${slug}`)}>
          Read More
				</PostButton>
      </PostItemData>
    </PostItem>
  )
}

export default Item;
