import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import CloseButton from '../../components/CloseButton'
import ForgotPasswordForm from './formForget';
import {
  goHomeWithResetLoginRedirect,
  redirectWithResetForgotState,
  submitForgotPassword, submitForgotPasswordReset,
  submitForgotPasswordValidate
} from '../../modules/user';
import Modal from '../../components/modal';

import {
  GridContainer,
  LoginHeading,
  SmallLink,
  TextP,
  ErrorText,
  CloseButtonContainer,
  StyledLogoHorizontal,
  TextLabel,
  LogInButton
} from './Styled'
import TodoView from "./todoView";
import ResetPasswordForm from "./formReset";

const ForgotPassword = (
  {
    submitForgotPassword,
    errorMessage,
    goBackWithResetLoginRedirect,
    redirectWithResetForgotState,
    submitForgotPasswordValidate,
    submitForgotPasswordReset,
    isLinkSent,
    isTokenValid,
    resetPassMsg,
    location
  }) =>
{
  const [token] = useState(new URLSearchParams(location.search).get("token"));
  useEffect(() => {
    if (token) {
      submitForgotPasswordValidate(token);
    }
  }, [token]);

  return (
    <Modal>
      <Helmet>
        <title>Forgot Password - OfficialVisit</title>
        <meta name="description" content="Restore your password to continue exploring colleges! OfficialVisit is the leading college and college football review platform." />
      </Helmet>
      <CloseButtonContainer><CloseButton onClick={ isLinkSent ? redirectWithResetForgotState : goBackWithResetLoginRedirect }/></CloseButtonContainer>
      <GridContainer>
        { token && !isTokenValid && <div>
          <LoginHeading as="h1">Error:</LoginHeading>
          { errorMessage && <ErrorText>{errorMessage}</ErrorText> }
          <LogInButton onClick={() => redirectWithResetForgotState('/login')}>Login</LogInButton>
        </div>
        }

        { token && isTokenValid && resetPassMsg && <div>
          <LoginHeading as="h1">Password has been reset</LoginHeading>
          <TextLabel>{resetPassMsg}</TextLabel>
          <LogInButton onClick={() => redirectWithResetForgotState('/login')}>Login</LogInButton>
        </div>
        }
        { token && isTokenValid && !resetPassMsg && <div>
          <LoginHeading as="h1">Choose a new password</LoginHeading>
          { errorMessage && <ErrorText>{errorMessage}</ErrorText> }
          <ResetPasswordForm onSubmit={data => submitForgotPasswordReset({...data, token})}/>
        </div>
        }

        { !token && !isLinkSent && <div>
          <StyledLogoHorizontal/>
          <LoginHeading as="h1">Forgot your password?</LoginHeading>
          <TextLabel>No problem. Just enter your email address below — we’ll send you a link to reset it.</TextLabel>
          { errorMessage && <ErrorText>{errorMessage}</ErrorText> }
          <ForgotPasswordForm onSubmit={data => submitForgotPassword(data)} />
          <TextP><SmallLink onClick={() => redirectWithResetForgotState()}>Cancel</SmallLink></TextP>
        </div>}
        { !token && isLinkSent && <div>
          <LoginHeading as="h1">Here’s how to reset your password</LoginHeading>
          <TodoView onClick={redirectWithResetForgotState} />
        </div>}
      </GridContainer>
    </Modal>
  )
};

ForgotPassword.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  submitForgotPassword: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired
};

const mapStateToProps = ({ user }) => ({
  isFetching: user.isFetching,
  isLinkSent: user.forgotPasswordSent,
  isTokenValid: user.forgotTokenValid,
  resetPassMsg: user.resetPassMsg,
  errorMessage: user.errorMessage
});

const mapDispatchToProps = dispatch => bindActionCreators({
  submitForgotPassword,
  submitForgotPasswordValidate,
  submitForgotPasswordReset,
  goBackWithResetLoginRedirect: goHomeWithResetLoginRedirect,
  redirectWithResetForgotState,
  push
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
