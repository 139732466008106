import React from 'react';

export default function MyComponent(props) {
  return (
    <svg id="Dark_Blue_Horizontal" data-name="Dark Blue Horizontal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 305 80" width={305} height={80} {...props}>
      <defs>
        <style dangerouslySetInnerHTML={{__html: ".cls-1{fill:#f58464;}.cls-2{fill:#f26648;}.cls-3{fill:#ffe391;}.cls-4{fill:#f8a083;}.cls-5{fill:#fd7;}.cls-6{fill:#4e7da8;}.cls-7{fill:#004a7c;}.cls-8{fill:#0c6191;}.cls-9{fill:#336e9c;}.cls-10{fill:#005586;}" }} />
      </defs>
      <title>Horizontal Logo</title>
      <g id="_Group_" data-name="<Group>
        ">
        <g id="_Group_2" data-name="<Group>
          ">
          <path id="_Path_" data-name="<Path>
            " className="cls-1" d="M36,26a8.17,8.17,0,0,0-7.24,4.41l2.64,2.22a4.85,4.85,0,0,1,9.21,0l2.64-2.22A8.17,8.17,0,0,0,36,26Z" />
            <path id="_Path_2" data-name="<Path>
              " className="cls-2" d="M36,31.73a2.41,2.41,0,0,0-2.41,2.73l2.41,2,2.41-2A2.41,2.41,0,0,0,36,31.73Z" />
              <path id="_Path_3" data-name="<Path>
                " className="cls-3" d="M17.84,16.46a25.59,25.59,0,0,0-2.36,2.8h0L18,21.39a22,22,0,0,1,36,0l2.54-2.13a25.59,25.59,0,0,0-2.36-2.8h0a25.6,25.6,0,0,0-2.16-2h0L51.45,14l-.05,0-.54-.4,0,0q-.88-.63-1.81-1.19l-.08,0L48.41,12,48.3,12l-.53-.29-.09,0q-.64-.33-1.3-.63l0,0-.59-.26-.15-.06-.5-.2-.18-.07-.51-.19-.16-.06-.67-.22h0l-.69-.21-.14,0-.53-.14L42,9.49l-.48-.11-.23-.05-.49-.1-.21,0-.67-.11h0L39.17,9,39,9l-.5-.05-.26,0-.47,0-.26,0-.5,0H35.05l-.5,0-.26,0-.47,0-.26,0L33.06,9l-.21,0-.71.1h0l-.67.11-.21,0-.49.1-.23.05L30,9.49l-.22.05-.53.14-.15,0-.69.2h0l-.66.22-.16.06-.51.19-.18.07-.5.2-.15.06-.59.26,0,0q-.66.3-1.3.63l-.09,0-.53.29-.11.06-.53.31-.08,0q-.93.56-1.81,1.19l0,0-.54.4-.05,0-.54.43h0a25.61,25.61,0,0,0-2.16,2Z" />
                <path id="_Path_4" data-name="<Path>
                  " className="cls-4" d="M36,20.25a13.9,13.9,0,0,0-11.7,6.41l2.56,2.15a10.58,10.58,0,0,1,18.28,0l2.56-2.15A13.9,13.9,0,0,0,36,20.25Z" />
                  <path id="_Path_5" data-name="<Path>
                    " className="cls-5" d="M36,14.52A19.63,19.63,0,0,0,19.89,23l2.55,2.14a16.31,16.31,0,0,1,27.15,0L52.13,23A19.63,19.63,0,0,0,36,14.52Z" />
                    <path id="_Path_6" data-name="<Path>
                      " className="cls-6" d="M41.21,39.67,59.43,24.39a25.3,25.3,0,0,0-1.52-3L36.06,39.67Z" />
                      <path id="_Path_7" data-name="<Path>
                        " className="cls-7" d="M59,39.67l2.15-1.8a25.48,25.48,0,0,0,.27-3.71c0-.28,0-.55,0-.82l-7.56,6.34Z" />
                        <path id="_Path_8" data-name="<Path>
                          " className="cls-8" d="M50.09,39.67l11-9.25a25.17,25.17,0,0,0-.82-3.64L44.94,39.67Z" />
                          <path id="_Path_9" data-name="<Path>
                            " className="cls-7" d="M18.2,39.67l-7.56-6.34c0,.27,0,.55,0,.82a25.48,25.48,0,0,0,.27,3.71l2.15,1.8Z" />
                            <path id="_Path_10" data-name="<Path>
                              " className="cls-8" d="M27.08,39.67,11.73,26.79a25.17,25.17,0,0,0-.82,3.64l11,9.25Z" />
                              <path id="_Path_11" data-name="<Path>
                                " className="cls-6" d="M36,39.67,14.11,21.35a25.3,25.3,0,0,0-1.52,3L30.81,39.67Z" />
                                <polygon id="_Path_12" data-name="<Path>
                                  " className="cls-9" points="24.98 58.98 24.98 58.99 28.27 62.29 43.75 62.29 47.04 58.99 47.04 58.98 24.98 58.98" />
                                  <polygon id="_Path_13" data-name="<Path>
                                    " className="cls-6" points="36.01 70.03 41.32 64.72 30.7 64.72 36.01 70.03" />
                                    <path id="_Path_14" data-name="<Path>
                                      " className="cls-7" d="M13.21,45.3H58.8A25.21,25.21,0,0,0,60.15,42H11.87A25.21,25.21,0,0,0,13.21,45.3Z" />
                                      <path id="_Path_15" data-name="<Path>
                                        " className="cls-10" d="M17,50.93H55a25.52,25.52,0,0,0,2.48-3.31h-43A25.52,25.52,0,0,0,17,50.93Z" />
                                        <polygon id="_Path_16" data-name="<Path>
                                          " className="cls-8" points="22.54 56.55 49.48 56.55 52.79 53.24 19.23 53.24 22.54 56.55" />
                                          <path id="_Path_17" data-name="<Path>
                                            " className="cls-7" d="M10.12,44.14a27.76,27.76,0,1,1,51.79,0l4.72-4.72a31.07,31.07,0,1,0-61.23,0Z" />
                                            <polygon id="_Path_18" data-name="<Path>
                                              " className="cls-7" points="2.59 39.96 2.59 44.63 36.01 78.05 69.42 44.64 69.42 39.96 36.01 73.38 2.59 39.96" />
                                            </g>
                                            <g id="_Group_3" data-name="<Group>
                                              ">
                                              <path id="_Compound_Path_" data-name="<Compound Path>
                                                " className="cls-7" d="M74.59,48.43v-16c0-6.7,3.9-10.65,10.22-10.66S95,25.77,95,32.46v16c0,6.83-3.91,10.84-10.24,10.84S74.59,55.26,74.59,48.43ZM84.8,55c3.16,0,5.08-2.28,5.08-6.5V32.37c0-4.09-1.92-6.31-5.07-6.33s-5.06,2.22-5.06,6.33V48.52c0,4.22,1.91,6.5,5,6.5Z" />
                                                <path id="_Compound_Path_2" data-name="<Compound Path>
                                                  " className="cls-7" d="M105.76,34.78v7.77h7.76v3.67h-7.76V58.87H101.1V31h13.72v3.78Z" />
                                                  <path id="_Compound_Path_3" data-name="<Compound Path>
                                                    " className="cls-7" d="M124.19,34.78v7.77H132v3.67h-7.76V58.87h-4.66V31h13.72v3.78Z" />
                                                    <path id="_Compound_Path_4" data-name="<Compound Path>
                                                      " className="cls-7" d="M142.62,58.87H138V31h4.66Z" />
                                                      <path id="_Compound_Path_5" data-name="<Compound Path>
                                                        " className="cls-7" d="M165.53,51c0,5.73-3.43,8.29-8.47,8.29s-8.76-2.6-8.76-9V39.46c0-6.35,3.62-8.88,8.78-8.88s8.44,2.6,8.44,8.26v2.35H161.1V38.72c0-3.16-1.87-4.4-3.93-4.4-2.41,0-4.09,1.24-4.09,5.05v11c0,3.89,1.68,5.2,4.06,5.2,2.23,0,4-1.29,4-4.44V48.69h4.43Z" />
                                                        <path id="_Compound_Path_6" data-name="<Compound Path>
                                                          " className="cls-7" d="M175.93,58.87h-4.66V31h4.66Z" />
                                                          <path id="_Compound_Path_7" data-name="<Compound Path>
                                                            " className="cls-7" d="M193.38,53.07h-7l-1.18,5.79h-4.83L187,31h5.89l6.55,27.86h-4.83Zm-.73-3.56L189.9,36.08l-2.75,13.44Z" />
                                                            <path id="_Compound_Path_8" data-name="<Compound Path>
                                                              " className="cls-7" d="M217.28,58.86H203.89V31h4.48V55h8.91Z" />
                                                              <path id="_Compound_Path_9" data-name="<Compound Path>
                                                                " className="cls-7" d="M241.75,22.21l-7.63,36.66H228l-7.64-36.66h5.2L231.07,52l5.47-29.75Z" />
                                                                <path id="_Compound_Path_10" data-name="<Compound Path>
                                                                  " className="cls-7" d="M249.76,58.87H245.1V31h4.66Z" />
                                                                  <path id="_Compound_Path_11" data-name="<Compound Path>
                                                                    " className="cls-7" d="M271.33,37.73v2.36H267V37.61c0-2.12-1.37-3.33-3.7-3.33-2.18,0-3.4,1.16-3.4,3.1v1.1c0,5.84,11.6,3.15,11.6,12.64V52.2c0,4.63-2.93,7.11-8.23,7.11s-8.15-2.7-8.15-7.2V49.69h4.34v2.51c0,2.2,1.38,3.39,3.8,3.39,2.24,0,3.59-1.12,3.59-3.22v-1.1c0-6.26-11.53-3.69-11.53-12.74V37.45c0-4.45,2.94-6.87,8-6.87C268.63,30.58,271.33,33.22,271.33,37.73Z" />
                                                                    <path id="_Compound_Path_12" data-name="<Compound Path>
                                                                      " className="cls-7" d="M281.6,58.87h-4.66V31h4.66Z" />
                                                                      <path id="_Compound_Path_13" data-name="<Compound Path>
                                                                        " className="cls-7" d="M296.66,34.89v24H292v-24h-5.94V31h16.54v3.88Z" />
                                                                      </g>
                                                                    </g>
                                                                  </svg>
  );
}
