import React from 'react';

import { 
  SizedOne, 
  SizedZero,
  SizedDotOne, 
  SizedDotTwo, 
  SizedDotThree, 
  SizedDotFour,
  SizedDotFive,
  SizedDotSix,
  SizedDotSeven,
  SizedDotEight,
  SizedDotNine,
  AverageLabelCard,
  AverageRatingCard,
  AverageRatingEmpty
} from './Styled';

// Generated Rating List

const getRatingComponent = (rating) => {
  const wholeValue = parseInt(rating + "");
  let displayArr = [];
  for (var n = 0; n < wholeValue; n++) {
    displayArr[n] = <SizedOne key={"-rating1-" + n }/>
  }
  const decimalValueTimes10 = parseInt(10 * ( parseFloat(rating) - wholeValue));
  switch(decimalValueTimes10) {
    case 1:
      displayArr[n] = <SizedDotOne key={"rating0-" + n }/>
      break;
    case 2:
      displayArr[n] = <SizedDotTwo key={"rating0-" + n }/>
      break;
    case 3:
      displayArr[n] = <SizedDotThree key={"rating0-" + n }/>
      break;
    case 4:
      displayArr[n] = <SizedDotFour key={"rating0-" + n }/>
      break;
    case 5:
      displayArr[n] = <SizedDotFive key={"rating0-" + n }/>
      break;
    case 6:
      displayArr[n] = <SizedDotSix key={"rating0-" + n }/>
      break;
    case 7:
      displayArr[n] = <SizedDotSeven key={"rating0-" + n }/>
      break;
    case 8:
      displayArr[n] = <SizedDotEight key={"rating0-" + n }/>
      break;
    case 9:
      displayArr[n] = <SizedDotNine key={"rating0-" + n }/>
      break;
    default:
      break;
  }
  if(decimalValueTimes10){  
    for (var m = 0; m < 4 - wholeValue; m++) {
      displayArr.push(<SizedZero key={"rating0"+ (wholeValue-m)}/>)
    }
  }else{
    for (m = 0; m < 5 - wholeValue; m++) {
      displayArr.push(<SizedZero key={"rating0"+ (wholeValue-m)}/>)
    }
  }
  return displayArr.map(item => item);
}

let AverageRatingScore = ({ 
  average_rating
}) => {
  return (
    <span>
      {(!!average_rating) ? 
        <AverageLabelCard>{average_rating}</AverageLabelCard> : 
        <AverageRatingEmpty>Not enough reviews</AverageRatingEmpty>
      }
      { average_rating !== 0 && <AverageRatingCard>{getRatingComponent(average_rating)}</AverageRatingCard>}
    </span>
  );
};


export default AverageRatingScore;
