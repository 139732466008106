import React from 'react';

// Styles
import { 
	PostAuthorBioContainer
} from './Styled';

const PostAuthorBio = ({ 
	bio
}) =>{
  return (
		<PostAuthorBioContainer>
			{bio}
		</PostAuthorBioContainer>
	)
}

export default PostAuthorBio;