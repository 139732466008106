import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import find from 'lodash/find';
import reject from 'lodash/reject';

import CloseButton from '../../../components/CloseButton'

// Styles
import {
  LocationFilter,
  LocationFilterHeader,
  CategorySection,
  CategorySectionHeader,
  CategoryList,
  CategoryItem2,
  CategoryItem3,
  CategoryFilterFooter,
  ScrollableSections,
  Overlay,
  FiltersModal,
  StyledButton
} from './Styled';

const selected = (filters, {ID}) => !!find(filters, {ID});
const selectedSelectAll = (filter, tag) => tag ? !!filter[tag.ID] : false;

const toggleSelectAll = (filter, setFilter) => (tag) => {
  const res = {};
  res[tag.ID] = !filter[tag.ID];
  setFilter({...filter, ...res})
};

const toggleTags = (filters, setFilters) => (tag) => {
  let result = filters;
  if (find(filters, { ID: tag.ID })) {
    result = reject(filters, { ID: tag.ID });

    if(tag.subtags) {
      result = reject(result, (subtag) => find(tag.subtags, { ID: subtag.ID}));
    }

  } else {
    result = [
      ...filters,
      tag,
    ];

    if (tag.subtags) {
      result = [
        ...result,
        ...tag.subtags,
      ]
    }
  }

  setFilters(result);
};

const useToTop = (...watch) => {
  useEffect(() => {
    window.scrollTo(0 ,0);
  }, watch);
};

const SelectAllButton = ({tag, toggle, select, toggleSelect}) => {
  return(
    <StyledButton selected={select} onClick={()=>{toggle(tag); toggleSelect(tag)}}>
      {select ? "Unselect All" : "Select All"}
    </StyledButton>
  )
};

const LocationsFilter = forwardRef(({ onToggleShow, tags, filters, onChange, show }, ref) => {
  useToTop(filters);
  const [ internalFilters, setFilters ] = useState(filters);
  const [ selectAll, setSelectAll ] = useState({});

  useImperativeHandle(ref, () => ({
    clearFilters() {
      setFilters([]);
      setSelectAll({});
    }
  }));

  const toggle = toggleTags(internalFilters, setFilters);
  const toggleSelAll = toggleSelectAll(selectAll, setSelectAll);
  return (
    <FiltersModal isOpen={show} style={Overlay}>
      <LocationFilter>
        <LocationFilterHeader>
          <h4 onClick={() => { setFilters([]); setSelectAll({}); } }>Clear all</h4>
          <CloseButton className={'btn-close'} onClick={ () => onToggleShow(false) }/>
        </LocationFilterHeader>
        <ScrollableSections>
          {tags && tags.map(tag =>
            <CategorySection key={`category-${tag.ID}`}>
              <CategorySectionHeader>
                <h2>{`${tag.title}`}</h2>
                <SelectAllButton
                  toggleSelect={toggleSelAll}
                  select={selectedSelectAll(selectAll, tag)}
                  toggle={toggle}
                  tag={tag}/>
              </CategorySectionHeader>

              { ['Food/drink'].indexOf(tag.title) !== -1 ? (
                <CategoryList>
                  {tag.subtags && tag.subtags.filter(tag => tag.title === "Dining hall").map(subtag =>
                    <CategoryItem3 key={`subcategory-${subtag.ID}`}>
                      <label><input
                        key={subtag.ID}
                        type='checkbox'
                        checked={selected(internalFilters, subtag)}
                        onChange={() => toggle(subtag)}
                      />{`${subtag.title}`}</label>
                    </CategoryItem3>
                  )}
                  {tag.subtags && tag.subtags.filter(tag => tag.title !== "Dining hall").sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0)).map(subtag =>
                    <CategoryItem3 key={`subcategory-${subtag.ID}`}>
                      <label><input
                        key={subtag.ID}
                        type='checkbox'
                        checked={selected(internalFilters, subtag)}
                        onChange={() => toggle(subtag)}
                      />{`${subtag.title}`}</label>
                    </CategoryItem3>
                  )}
                </CategoryList>)
                : (<CategoryList>
                    {tag.subtags && tag.subtags.map(subtag =>
                      <CategoryItem2 key={`subcategory-${subtag.ID}`}>
                        <label><input
                          key={subtag.ID}
                          type='checkbox'
                          checked={selected(internalFilters, subtag)}
                          onChange={() => toggle(subtag)}
                        />{`${subtag.title}`}</label>
                      </CategoryItem2>
                    )}
                  </CategoryList>)
              }
            </CategorySection>
          )}
        </ScrollableSections>
        <CategoryFilterFooter>
          <button className='btn-cancel' onClick={() => onToggleShow(false)}>Cancel</button>
          <button className='btn-apply' onClick={() => onChange(internalFilters) || onToggleShow(false)}>Apply Filters</button>
        </CategoryFilterFooter>
      </LocationFilter>
    </FiltersModal>
  )
});

export default LocationsFilter;
