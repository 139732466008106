import React, { Fragment, useState } from 'react';
import ReactMapboxGl, { ZoomControl, Marker } from 'react-mapbox-gl';
import Spinner from 'react-spinkit';

import { MapCard, MapStyles } from './Styled';
import LocationMarker from './LocationMarker';
import Img from "react-image";

const Map = ReactMapboxGl({
  accessToken: "pk.eyJ1Ijoib2ZmaWNpYWx2aXNpdDEiLCJhIjoiY2swam4wNGpoMDk4NTNtbXJ0Nnl3c3ByYyJ9.8Gl-fuYt3G-zSWQNW1UYaA"
});

const LocationsMap = ({
  center,
  isFetching,
  attractions,
  college_url_slug,
  clickable_markers,
  hoverCard
}) => {
  const [viewport, setViewport] = useState({ zoom: 13 });

  const [full] = useState(false);
  const [lastOpened, setLastOpened] = useState(0);

  const _onViewportChange = (updated) => {
    setViewport({
      ...viewport,
      width: updated._container.clientWidth,
      height: updated._container.clientHeight,
      longitude: updated.getCenter().lng,
      latitude: updated.getCenter().lat,
      zoom: updated.getZoom(),
    });
  };

  if (center !== undefined &&  viewport.longitude === undefined) { // initialize cords when center comes
    setViewport({
      ...viewport,
      longitude: center[0],
      latitude: center[1],
    });
  }

  return (
    <MapCard full={full}>
      {isFetching && <Spinner fadeIn="none" name="cube-grid" />}
      {center &&
        <Map
          key={`is-full-${full}`}
          // eslint-disable-next-line
          style='mapbox://styles/officialvisit1/ck0jnj9af6i691dl7vuzu7elf'
          containerStyle={MapStyles}
          center={[viewport.longitude, viewport.latitude]}
          zoom={[viewport.zoom]}
          onMoveEnd={_onViewportChange}
          onZoomEnd={_onViewportChange}
          onPitchEnd={_onViewportChange}
          fitBoundsOptions='linear'
          renderChildrenInPortal={true}
        >
          <ZoomControl zoomDiff={1} />

          { attractions && attractions.length > 1 && (<Fragment>
            <Marker
              style={ { color: '#ff44ff', cursor: 'pointer' } }
              coordinates={ center }
              >
              <Img
                style={ { height: '4rem' } }
                src={ 'https://i.imgur.com/MK4NUzI.png' }
                loader={ <Spinner fadeIn="none" name="cube-grid" /> }
                decode={ false }
              />
            </Marker>
          </Fragment>)
          }

          {attractions && attractions.map(attraction =>
            <LocationMarker
              key={`marker-${attraction.ID}`}
              clickable={clickable_markers}
              college_url_slug={college_url_slug}
              lastOpened={lastOpened}
              setLastOpened={setLastOpened}
              {...attraction}
              hoverCard={hoverCard}
            />)}

        </Map>
      }
    </MapCard>
  )
};
export default LocationsMap;
