import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux'; 
import { connect } from 'react-redux';
import { submitLogout } from '../../modules/user';

const TestLogout = ({submitLogout}) =>{

  useEffect(() => {
    submitLogout()
  }, []);

  return (<text>ok</text>)
};

TestLogout.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  submitLogout: PropTypes.func.isRequired
};

const mapStateToProps = ({ user }) => ({
  isFetching: user.isFetching,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  submitLogout
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TestLogout)