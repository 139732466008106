import React, { useRef, useEffect } from 'react';
import styled from 'styled-components'; // Styled components
import { Button, Heading } from 'rebass';
import { color, typo } from '../../styles';
import Dropzone from 'react-dropzone';

const RegisterHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
  line-height: 24px;
`;

const DropzoneOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${color.ovTransparentBlue};
  text-align: center;
  color: ${color.ovWhite};
  font-family: ${typo.ovFontFeature};
  font-size: 32px;
  padding-top: 180px;
  border: 4px dashed ${color.ovBlue};
  border-radius: 0px;
  z-index: 2;
`;

const CenterPane = styled.div`
  grid-column: span 12;
  padding-top: 180px;
  text-align: center;
`;

const OVButton = styled(Button)`
  display: inline-block;
  font-family: ${typo.ovFontFeature};
  background: ${color.ovYellow};
  color: ${color.ovWhite};
  cursor: pointer;
  padding: 2px 40px;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.95;
  letter-spacing: normal;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(5, 5, 5, 0.16);
  border: solid 1px ${color.ovGreyDark};
  transition: all .1s;
  &:hover, &:focus {
    text-decoration: none;
    background: ${color.ovBlueLight};
    border-color: ${color.ovBlueLight};
    transition: all .1s;
  }
`;

const DragAndDropOption = styled.div`
  display: none;
  @media (min-width: 960px) {
    display: block;
  }
`;

const RegisterPictureDropZone = props => {
  const dropzoneRef = useRef(null);
  const { dropzoneActive, onToggleDropZone, handleDropPicture, handleDragEnter, handleDragLeave } = props;

  useEffect(() => {
    if (props.picture) onToggleDropZone(false);
  }, [props.picture]);

  return (
    <Dropzone
      disableClick
      ref={dropzoneRef}
      style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
      accept="image/jpeg, image/png"
      maxSize={8500000} // 4MB

      onDrop={handleDropPicture}

      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      {dropzoneActive && <DropzoneOverlay />}
      <CenterPane>
        <DragAndDropOption>
          <RegisterHeading as="h1">Drag and Drop</RegisterHeading>
          <RegisterHeading as="h1">- or -</RegisterHeading>
        </DragAndDropOption>
        <OVButton onClick={() => dropzoneRef.current.open()}>Select Picture</OVButton>
      </CenterPane>
    </Dropzone>
  )
};

export default RegisterPictureDropZone;