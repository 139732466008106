import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { withLifecycleActions } from "react-redux-lifecycle";
// Redux stuff
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { listCollegesIfEmpty } from '../../modules/college';
import { getUserIfEmpty } from '../../modules/user';
import { getAverageCollege } from '../../modules/home';
// Styles stuff
import { Grid, Col, Row } from 'react-bootstrap';
// Components
import Search from '../search';
import BigButton from '../../components/bigButton';
import BigTitle from '../../components/bigTitle';
import HomeBigRankingCard from '../../components/HomeBigRankingCard';
import HomeTopConference from '../../components/HomeTopConference';
import HomeLargestStadiums from '../../components/HomeLargestStadiums'
import HomeWarmestClimates from '../../components/HomeWarmestClimates'
import backgroundSrc from '../../assets/img/home-bg.jpg';

import {
  ImageInfo,
  ImageText,
  HeaderHome,
  ExploreContainer,
  TopRanksContainer,
  BigButtonCol,
  HomeBigCardButton
} from './Styled';

const Home = ({ push, isAuthenticated, collegeName, collegeCity, collegeState, colleges, isFetchingAverageColleges, topAverageColleges, getTopAverageColleges }) => {
  useEffect(() => {
    if ((!topAverageColleges || !topAverageColleges.length) && !isFetchingAverageColleges) {
      getTopAverageColleges();
    }
  }, [colleges]);

  return (
    <div>
      <Helmet>
        <title>OfficialVisit: Explore College, Football, and Coach Reviews</title>
        <meta name="description" content="Explore rankings, reviews, statistics, majors, and campus life at colleges and FBS college football teams and coaches. Help others learn more about your former college or discover the college of your dreams." />
      </Helmet>
      <HeaderHome bgUrl={backgroundSrc}>
        <Grid>
          <Row>
            <Col xs={12} smOffset={1} sm={10} mdOffset={1} md={10} lgOffset={0} lg={12}>
              <Search />
            </Col>
          </Row>
        </Grid>
        { collegeName &&
          <ImageInfo>
            <ImageText>{collegeName}</ImageText>
            <ImageText>{collegeCity}, {collegeState}</ImageText>
          </ImageInfo>
        }
      </HeaderHome>
      <ExploreContainer>

        {/* Explore OV */}
        <Grid>
          <Row>
            <Col sm={12}>
              <BigTitle bigTitleLabel="Explore OfficialVisit"/>
            </Col>
            <BigButtonCol sm={12}>
              <BigButton bigButtonType="colleges" bigButtonLabel="Colleges"/>
              <BigButton bigButtonType="coaches" bigButtonLabel="Coaches"/>
            </BigButtonCol>
          </Row>
        </Grid>

        {/* Top Conferences */}
        <TopRanksContainer>
          <Grid>
            <Row>
              <Col sm={12}>
                <BigTitle bigTitleLabel="View colleges in the top conferences" />
              </Col>
            </Row>
            <Row>
              <HomeTopConference />
            </Row>
          </Grid>
        </TopRanksContainer>

        {/* Top academic institutions */}
        <Grid>
          <Row>
            <Col xs={12}>
              <HomeBigRankingCard type="College Rankings" title="The top academic institutions creating America’s next generation of leaders."/>
            </Col>
          </Row>
        </Grid>

        {/* Warmest Climates */}
        <TopRanksContainer>
          <Grid>
            <Row>
              <Col sm={12}>
                <BigTitle bigTitleLabel="Discover the warmest climates" />
              </Col>
            </Row>
            <Row>
              <HomeWarmestClimates />
            </Row>
            <Row>
              <Col sm={12}>
                <HomeBigCardButton onClick={() => push('/warmest-colleges')}>
                  View all
                  <span> ></span>
                </HomeBigCardButton>
              </Col>
            </Row>
          </Grid>
        </TopRanksContainer>

        {/* Largests Stadiums */}
        <TopRanksContainer>
          <Grid>
            <Row>
              <Col sm={12}>
                <BigTitle bigTitleLabel="Find the largest stadiums" />
              </Col>
            </Row>
            <Row>
              <HomeLargestStadiums />
            </Row>
            <Row>
              <Col sm={12}>
                <HomeBigCardButton onClick={() => push('/largest-cfb-stadiums')}>
                  View all
                  <span> ></span>
                </HomeBigCardButton>
              </Col>
            </Row>
          </Grid>
        </TopRanksContainer>

      </ExploreContainer>
    </div>
  )
};

Home.propTypes = {
  getTopAverageColleges: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  collegeName: PropTypes.string.isRequired,
  collegeCity: PropTypes.string.isRequired,
  collegeState: PropTypes.string.isRequired,
};

const mapStateToProps = ({ user, home, college }) => ({
  colleges: college.colleges,
  isAuthenticated: user.isAuthenticated,
  collegeName: home.collegeName,
  collegeCity: home.collegeCity,
  collegeState: home.collegeState,
  topAverageColleges: home.topAverageColleges,
  isFetchingAverageColleges: home.isFetchingAverageColleges
});

const mapDispatchToProps = dispatch => bindActionCreators({
  listCollegesIfEmpty,
  getUserIfEmpty,
  push,
  getTopAverageColleges: () => getAverageCollege([124, 123, 21])
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withLifecycleActions({ componentWillMount: [listCollegesIfEmpty] })(Home));
