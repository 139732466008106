
import React from 'react';

import {
  ReviewerInfoContent,
} from './StyledItem';

const ReviewerInfo = (review) => {
  const { user, user: { college }, isCollegeReview, is_anonymous } = review;
  // Get school association
  // graduated       = is_alumni === true
  // current student = is_student === true
  // former student  = !is_alumni && !is_student

  // school_common student
  // Former school_common student
  // school_common alumnus
  let schoolAssociation = college && `
    ${
      !user.is_alumni && !user.is_student
        ? 'Former'
        : ''
    }
    ${college.name}
    ${
      user.is_alumni 
        ? 'alumnus'
        : 'student'
    }
  `;

  if (user.role === 'recruit') {
    schoolAssociation = `
      Prospective student
    `
  } else if (user.role === 'fan') {
    // Note: Since a fan did not graduate or attend a college, the school_common should be the user’s favorite team.
    schoolAssociation = `
      ${college && college.name} fan 
    `
  }

  const classOf = user.last_year && `
    ${user.last_year}
  `;

  const major = user.major && `
    ${user.major}
  `;
  const sport = user.sport_position && `
    ${user.sport_position}
  `;
  return (
    <ReviewerInfoContent>
      <div>{schoolAssociation}</div>
      <div>{classOf}</div>
      {sport && is_anonymous && !isCollegeReview ? <div>{sport}</div> : <div>{major}</div>}
      {sport && (isCollegeReview || !is_anonymous) && <div className="sport-position">{sport}</div>}
    </ReviewerInfoContent>
  )
};

export default ReviewerInfo;