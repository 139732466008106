// eslint-disable-next-line
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { toggleEdit, editCollege, getCollegeReviewsAndMajorsIfEmpty } from '../../modules/college';
import { groupedMajorsSelector } from '../../modules/college/selectors';

import MajorsDetail from './MajorsDetail';

const mapStateToProps = ({ college, user }) => ({
  college: college.college,
  majors: groupedMajorsSelector({ college }),
  isEditing: college.isEditing,
  averageReview: college.averageReview,
  isFetchingCollege: college.isFetchingCollege,
  reviews: college.reviews,
  isFetchingReviews: college.isFetchingReviews,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleEdit,
  editCollege,
  getCollegeReviewsAndMajorsIfEmpty,
  push,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MajorsDetail)
