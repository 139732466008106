import styled from 'styled-components';

export const ItemForm = styled.div`
 padding: ${props => props.editing ? '1.5rem' : '0'}
`;

export const Add = styled.div`
  display: block;
  height: 12rem;

  ::after {
    display: ${props => props.emptyState ? 'flex' : 'none'};
    cursor: pointer;
    content: '+';
    margin-left: 0;
    margin-right: 0;
    border: 2px dashed;
    height: 12rem;
    font-size: 5.5rem;
    justify-content: center;
    line-height: 11rem;
    font-weight: bold;
    text-align: ;
    border-radius: 1.5rem;
  }
  
  :hover {
    &::after {
      display: flex;
    }
  }
`;

export const Cancel = styled.a`
  width: 100%
  cursor: pointer;
  display: inline-block;
  font-size: 1.5rem;
  margin-top: 3rem;
  text-align: right;
`;