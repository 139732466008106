import React from 'react';
import { Helmet } from "react-helmet";
import default_bkg from '../../assets/img/claim-bg.jpg';

import {
  ClaimHeader,
  ClaimHeaderTitle,
  ClaimBar,
  ClaimBarTitle,
  ClaimContent,
  ClaimContentTitle,
  ClaimContentParagraph
} from './Styled';

const ClaimPage = () => {
  return(
    <div>
      <Helmet>
        <title>Claim Your College - OfficialVisit</title>
        <meta name="description" content="Claim your college's profile on OfficialVisit! Learn how we can help improve your brand, drive applications, and increase donations." />
      </Helmet>
      <ClaimHeader bgUrl={default_bkg}>
        <ClaimHeaderTitle>
          Tell your college story.
          <span>Your experience.</span>
          <span>Your people.</span>
          <span>Your location.</span>
        </ClaimHeaderTitle>
      </ClaimHeader>
      <ClaimBar>
        <ClaimBarTitle>
          Claim your college profile
        </ClaimBarTitle>
      </ClaimBar>
      <ClaimContent>
        <ClaimContentTitle>Use our tools and platform to tell your story.</ClaimContentTitle>

        <ClaimContentTitle>Experience</ClaimContentTitle>
        <ClaimContentParagraph>Help prospective students, visitors, and fans of all types learn about the experiences unique to 
        your college.</ClaimContentParagraph>

        <ClaimContentTitle>People</ClaimContentTitle>
        <ClaimContentParagraph>Help visitors learn more about your coaches on our unbiased platform.</ClaimContentParagraph>

        <ClaimContentTitle>Location</ClaimContentTitle>
        <ClaimContentParagraph>Your location is unique.  Help visitors learn more about your campus and surrounding area prior 
        to visiting campus.</ClaimContentParagraph>

        <ClaimContentTitle>Contact Us</ClaimContentTitle>
        <ClaimContentParagraph>Contact our team at colleges@officialvisit.com to learn more about the different ways we can help build your brand and tell your story.</ClaimContentParagraph>

      </ClaimContent>
    </div>
  )
};

export default ClaimPage;