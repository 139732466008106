import { color, typo, bp } from '../../../styles'
import styled from 'styled-components';
import { Heading } from 'rebass';
import { PencilAlt } from 'styled-icons/fa-solid/PencilAlt';
import { Delete } from 'styled-icons/material/Delete';

export const LocationsCardCointainer = styled.div`
  
  justify-content: space-around;
	width: 100%;
  min-height: 103px;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0,0,0, 0.16);
  border: solid 1px #777777;
  background-color: ${color.ovWhite};
  padding: 7px 16px;
  margin-bottom: 8px;
  transform: scale(1);
  transition: all .1s;
  cursor: pointer;
  &:hover{
  	transition: all .1s;
   	transform: scale(1.04);
  }
`;

export const LocationsCardTitile = styled(Heading)`
	font-family: ${typo.ovFontFeature};
	font-size: 28px;
  font-weight: 300;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlue};
  margin-bottom: 5px;
  width: 90%
  @media ${bp.tabletPortrait} {
    width: 85%;
  }
`;

export const LocationsCardFilters = styled.p`
	font-family: ${typo.ovFontSerif};
	font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #777777;
  width: 90%
`;

export const StyledButton = styled.button`
  
  cursor: pointer;
  align-self: center;
  text-align: center;
  align-item: center;
  height: 19px;
  width: 19px;
  background-color: transparent;
  border: solid 1px #777777;
  border-radius: 5px;
  transform: scale(1);
  transition: all .1s;
  cursor: pointer;
  &:hover{
  	transition: all .1s;
   	transform: scale(1.04);
  }
  `;

export const StyledEdit = styled(PencilAlt)`
  width: 11px;
  margin-bottom: 3.5px;
  margin-left: -2.85px;
  color: ${color.ovGrey};
  @media ${bp.phoneOnly} {
    top: 17px;
  }
`;

export const StyledRemove = styled(Delete)`
  width: 16px;
  margin-left: -5px;
  margin-bottom: 7px;
  color: ${color.ovGrey};
  @media ${bp.phoneOnly} {
    top: 17px;
  }
`;

export const ButtonsContainer = styled.div`
  
  display: flex;
  position: absolute;
  cursor: pointer;
  justify-content: flex-end;
  height: 20px;
  width: 95%;
  border: none;
  background-color: transparent;
  @media ${bp.phoneOnly} {
    width: 95%;
    height: 50px;
  }
  @media ${bp.tabletLandUp} {
    width: 95%;
    height: 50px;
    align-self: flex-start;
    align-content: start;
  }
`;

export const Buttons = styled.div`
  
  cursor: pointer;
  text-align: center;
  height: 20px;
  width: 12%;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  border: none;
  @media ${bp.phoneOnly} {
    justify-content: space-between;
    flex-direction: column;
    margin-right: 2%
    height: 100%;
    width: 10%;
  }
  @media ${bp.tabletLandUp} {
    width: 45px;
    margin-right: 2%
  }
`;
