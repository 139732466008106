import { push } from "connected-react-router";
import find from 'lodash/find';
import { submitLogout } from '../user';
import { contentService } from '../../services';
import * as actions from './actions';
import * as selectors from './selectors';

// for PhotoGallery that get contents from outside
export const setContents = (contents) => {
  return async (dispatch) => {
    return dispatch(actions.setContentsSuccess(contents));
  }
};

export const deleteContent = (content) => {
  return async (dispatch, getState) => {
    try {
      const { user: { user: { role } } } = getState();
      const res = await contentService().deleteContent(content.ID, role);
      return dispatch(actions.deleteContentSuccess(res.data.data));
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }
  }
};

export const getUserId = (contentId) => {
  return async (dispatch, getState) => {
    if (!contentId) return;
    const { content: { contents } } = getState();

    // First check that the item has no user so far
    const item = find(contents, { ID: contentId });
    if (item.user) return;

    dispatch(actions.requestGetUserId())
    try {
      const { status, data: { data: user } } = await contentService().getUserByContentId(contentId);
      if (status === 200) {
        return dispatch(actions.getUserIdSuccess({ contentId, user }));
      }
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401 || e.response.status === 404) {
        dispatch(actions.getUserIdFailure('There was a problem fetching the requested profile'))
        // return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }
  }
};

const selectContent = direction => (dispatch, getState) => {
  const { content: { contents, selectedFilters, selectedContentIndex } } = getState();
  const tagged = selectors.taggedContent(contents, selectedFilters);
  const index = (selectedContentIndex + direction) % tagged.length;
  dispatch(actions.selectContentIndex(index));
  dispatch(getUserId(tagged[index].ID));
};

export const selectNextIndex = () => selectContent(1);

export const selectPrevIndex = () => selectContent(-1);

export const openContentByFilter = (base, filter) => (dispatch, getState) => e => {
  const { content: { contents } } = getState();
  if (!contents || !contents.length) return;

  if (Array.isArray(filter)) {
    filter.forEach(f => dispatch(addFilter(f)));
  } else {
    dispatch(addFilter(filter));
  }

  dispatch(push(`${ base }/content`));
  dispatch(actions.toggleLightboxOpen());

  let ID = contents && contents[0].ID;
  if (filter) {
    ID = selectors.getFirstByTag(contents, filter).ID;
  }

  dispatch(getUserId(ID));
};

export const addFilter = (filter) =>
{
  return (dispatch, getState) => {
    const { contribute: {content_tags} } = getState();
    if (!content_tags || !content_tags.length || !filter) return;

    if (!filter.display_name) {
      const realFilter = content_tags.find(t => t.ID === filter.ID || t.name === filter.name);
      if (realFilter.ID) return dispatch(actions.addFilterAction(realFilter));
    }

    dispatch(actions.addFilterAction(filter));
  };
};
