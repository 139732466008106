import React from 'react';
import { withRouter } from 'react-router';
import CollegeDetailsEditForm from '../../containers/collegeDetails/edit';
import AverageReview from '../reviews/AverageReview';
import Spinner from 'react-spinkit';

import {
  FullWithContent,
  OVButtonRight,
  CollegeBadge,
  BigTitleGrey,
  DetailsSubHeading,
  BarDivider,
  CollegeNav,
  MidTitleButton,
  MidTitleButtonLink,
  MidTitleButtonLinkWithScrollTo,
  TitleContainer,
} from '../CollegeDetailsStyled';

const CollegeHeader = ({
  isFetching,
  isEditing,
  toggleEdit,
  editCollege,

  college,
  averageReview,

  role,
  push,
  match,
}) => {
  const path = match.url.split('/').pop();

  return (
    <FullWithContent>
      {isFetching && <Spinner fadeIn="none" name="cube-grid" />}
      {isEditing && <CollegeDetailsEditForm onSubmit={data => editCollege(college.ID, data)} onCancel={toggleEdit} />}

      {!isFetching && !isEditing && college.ID &&
        <TitleContainer>
          {role === 'admin' && toggleEdit && (<OVButtonRight onClick={toggleEdit}>Edit</OVButtonRight>)}
          <CollegeBadge>
            <BigTitleGrey bigTitleLabel={`${college.name} ${college.nickname}`} />
            <DetailsSubHeading as="h2">
              {college.formal_name} <BarDivider>|</BarDivider> {`${college.address.city},  ${college.address.state}`}
            </DetailsSubHeading>
            <DetailsSubHeading as="h2">
              <AverageReview averageReview={averageReview.average_rating} count={averageReview.count} /> {averageReview.count} reviews
          </DetailsSubHeading>
            <CollegeNav>
              <MidTitleButton>
                <MidTitleButtonLink className={path === college.url_slug && 'selected'} onClick={() => push(`/college/${college.url_slug}`)}>
                  Overview
                </MidTitleButtonLink>
              </MidTitleButton>
              <MidTitleButton>
                {path === college.url_slug
                  ? (
                    <MidTitleButtonLinkWithScrollTo smooth={true} hashSpy={true} spy={true} to="reviews">
                      Reviews
                    </MidTitleButtonLinkWithScrollTo>
                  )
                  : (
                    <MidTitleButtonLink onClick={() => push(`/college/${college.url_slug}#reviews`)}>
                      Reviews
                    </MidTitleButtonLink>
                  )
                }
              </MidTitleButton>
              <MidTitleButton>
                <MidTitleButtonLink className={path === 'majors' && 'selected'} onClick={() => push(`/college/${college.url_slug}/majors`)}>
                  Majors
                </MidTitleButtonLink>
              </MidTitleButton>
              <MidTitleButton>
                <MidTitleButtonLink className={(path === 'attractions' && 'selected') || (!isNaN(path) && 'selected')} onClick={() => push(`/college/${college.url_slug}/attractions`)}>
                  Location
                </MidTitleButtonLink>
              </MidTitleButton>
            </CollegeNav>
          </CollegeBadge>
        </TitleContainer>
      }
    </FullWithContent>
  );

}
export default withRouter(CollegeHeader);
