import styled from 'styled-components';
import { color, typo, bp } from '../../../styles';

export const CollegeCardBarBox = styled.div`
  height: 52px;
  display: grid;
  grid-template-columns: 52px auto;
  border-bottom: solid 0.5px ${color.ovGreyDarker};
`;

export const CollegeCardTitle = styled.div`
  font-family: ${typo.ovFontFeature};
  font-size: 28px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlack};
  margin: 0;
  padding-left: 17px;
  display: flex;
  align-content: center;
  line-height: 1;
  text-transform: capitalize;
  align-items: center;
  @media ${bp.phoneOnly} {
    font-size: 18px;
  }
  @media ${bp.tabletPortUp} {
    font-size: 22px;
  }
  @media ${bp.tabletLandUp} {
    font-size: 28px;
  }
  span{
    cursor: pointer;
    color: ${color.ovBlue};
    padding-left: 6px;
    display: contents;
    &:hover{
      opacity: .8;
    }
  }
`;