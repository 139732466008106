import { push } from 'connected-react-router';
import { wizardService,collegeService } from "../services";
import { setProfile, submitLogout} from "./user";
import { resetLoginRedirect } from "./user/actions";
export const SET_USER_DATA = "wizard/SET_USER_DATA";

export const LIST_SPORTS_REQUESTED = "wizard/LIST_SPORTS_REQUESTED";
export const LIST_SPORTS_SUCCESS = "wizard/LIST_SPORTS_SUCCESS";
export const LIST_SPORTS_FAILURE = "wizard/LIST_SPORTS_FAILURE";
export const LIST_MAJORS_REQUESTED = "wizard/LIST_MAJORS_REQUESTED";
export const LIST_MAJORS_SUCCESS = "wizard/LIST_MAJORS_SUCCESS";
export const LIST_MAJORS_FAILURE = "wizard/LIST_MAJORS_FAILURE";
export const LIST_CONCENTRATIONS = "wizard/LIST_CONCENTRATIONS";
export const LIST_POSITIONS = "wizard/LIST_POSITIONS";
export const CREATE_PROFILE_REQUESTED = "wizard/CREATE_PROFILE_REQUESTED";
export const CREATE_PROFILE_SUCCESS = "wizard/CREATE_PROFILE_SUCCESS";
export const CREATE_PROFILE_FAILURE = "wizard/CREATE_PROFILE_FAILURE";

const initialState = {
  registration_data: {},
  majors: [],
  sports: [],
  result: {},
  concentrations_list: [],
  positions_list: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        registration_data: action.payload
      };
    case LIST_MAJORS_SUCCESS:
      return {
        ...state,
        majors: action.payload
      };
    case LIST_CONCENTRATIONS:
      return {
        ...state,
        concentrations_list: action.payload
      };
    case LIST_SPORTS_SUCCESS:
      return {
        ...state,
        sports: action.payload
      };
    case LIST_POSITIONS:
      return {
        ...state,
        positions_list: action.payload
      };
    case CREATE_PROFILE_SUCCESS:
      return {
        ...state,
        result: action.payload
      };
    default:
      return state;
  }
};

export const setUserData = data => ({
  type: SET_USER_DATA,
  payload: data
});

export const listSportsIfEmpty = () => {
  return (dispatch, getState) => {
    return getState().wizard.sports.length === 0 && dispatch(listSports());
  };
};

export const listSports = () => {
  return async dispatch => {
    dispatch(listSportsRequest());

    try {
      const res = await collegeService().getSports();
      return dispatch(listSportsSuccess(res.data.data));
    } catch (e) {
      dispatch(listSportsFailure());
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText);
      }
    } finally {
      return;
    }
  };
};

const listSportsRequest = () => ({
  type: LIST_SPORTS_REQUESTED
});

const listSportsSuccess = data => ({
  type: LIST_SPORTS_SUCCESS,
  payload: data
});

const listSportsFailure = data => ({
  type: LIST_SPORTS_FAILURE
});

export const listMajors = college_id => {
  return async dispatch => {
    dispatch(listMajorsRequest());

    try {
      const res = await collegeService().getMajors(college_id);
      return dispatch(listMajorsSuccess(res.data.data));
    } catch (e) {
      dispatch(listMajorsFailure());
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText);
      }
    } finally {
      return;
    }
  };
};

const listMajorsRequest = () => ({
  type: LIST_MAJORS_REQUESTED
});

const listMajorsSuccess = data => ({
  type: LIST_MAJORS_SUCCESS,
  payload: data
});

const listMajorsFailure = data => ({
  type: LIST_MAJORS_FAILURE
});

export const listConcentrations = (majors, majorsLabel) => {
  return (dispatch) => {
    const majorConcentrations = majors
          .filter(e => (`${e.subject} (${e.degree_abbr})`) === majorsLabel)
          .map(e => ({label: e.specialization,value: e.ID}));

      // Moves N/A to the first position
      majorConcentrations.sort(function (a) {return a.label === "N/A" ? -1 : 0;});
      dispatch(listConcentrationsSuccess(majorConcentrations));
  }
}

const listConcentrationsSuccess = data => ({
  type: LIST_CONCENTRATIONS,
  payload: data
});

export const listPositions = (sports, sportLabel) => {
  return (dispatch) => {
    const sportsPositions = sports
        .filter(e => (e.sport) === sportLabel)
        .map(e => ({ label: e.position, value: e.ID }));
    dispatch(listPositionsSuccess(sportsPositions));
  }
}

const listPositionsSuccess = data => ({
  type: LIST_POSITIONS,
  payload: data
});

export const createProfile = (data) => {
  return async (dispatch, getState) => {
    dispatch(createProfileRequest());

    const { user, user: { loginRedirect } } = getState();

    let req = {};

    switch (user.user.role) {
      case "athlete":
        req = {
          "is_alumni": data.graduated || false,
          "favorite_teams": [data.favoriteteam.value],
          "semesters": [{
            "college_refer": data.college.value,
            "major_refer": data.major.value,
            "start_year": data.first_year.value,
            "start_quarter": data.first_semester.value,
            "end_year": data.second_year.value,
            "end_quarter": data.second_semester.value
          }],
          "sport_positions": [data.sport.value,data.position.value]
       }
       break;

      case "student":
        req = {
          "is_alumni": data.graduated || false,
          "favorite_teams": [data.favoriteteam.value],
          "semesters": [{
            "college_refer": data.college.value,
            "major_refer": data.major.value,
            "start_year": data.first_year.value,
            "start_quarter": data.first_semester.value,
            "end_year": data.second_year.value,
            "end_quarter": data.second_semester.value
          }]
       }
       break;

      case "recruit":
        req = {
          "favorite_teams": [data.favoriteteam.value],
          "sport_positions": [data.sport.value,data.position.value],
          "gpa": parseFloat(data.gpa),
          "sat": parseInt(data.sat),
          "highschool": data.school.value
        }
        break;

      case "fan":
        req = {

        }
        req = {
          "is_ticket_holder": data.seasonticketholder || false,
          "is_donor": data.athleticdepartmentdonor || false,
          "favorite_teams": [data.favoriteteam.value],
          "location": {
            "address": {
              "address_line_1": data.location
            }
          }
        }
        break;

      default:
        req = {
          "is_ticket_holder": data.seasonticketholder || false,
          "is_donor": data.athleticdepartmentdonor || false,
          "favorite_teams": [data.favoriteteam.value],
          "location": {
            "address": {
              "address_line_1": data.location
            }
          }
        }
    }

    try {
      const res = await wizardService().finishRegister(req);
      dispatch(createProfileSuccess(res.data.data));
      dispatch(setProfile(res.data.data));
      dispatch(resetLoginRedirect());
      return dispatch(loginRedirect ? push(loginRedirect) : push('/'));
    } catch (e) {
      dispatch(createProfileFailure());
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText);
      }
    } finally {
      return;
    }
  };
};

const createProfileRequest = () => ({
  type: CREATE_PROFILE_REQUESTED
});

const createProfileSuccess = data => ({
  type: CREATE_PROFILE_SUCCESS,
  payload: data
});

const createProfileFailure = data => ({
  type: CREATE_PROFILE_FAILURE
});

