import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { getAttraction, getAttractionContents, getAttractionReviews } from '../../modules/attraction/attraction';
import { getCollegeReviewsAndMajorsIfEmpty } from '../../modules/college';
import { isFetchingSelector, attractionReviewSelector } from '../../modules/attraction/selectors';

import AttractionDetails from './AttractionDetails'
import { getUserIfEmpty, requestLogin } from "../../modules/user";
import { withRouter } from "react-router";
import { withLifecycleActions } from "react-redux-lifecycle";

const mapStateToProps = (state) => ({
  college: state.college.college,
  colleges: state.college.colleges,
  attraction: state.attraction.attraction,
  contents: state.attraction.contents,
  averageReview: state.college.averageReview,
  isFetching: isFetchingSelector(state),
  reviews: attractionReviewSelector(state),
  isFetchingCollege: state.college.isFetchingCollege,
  isFetchingReviews: state.attraction.isFetchingReviews,
  isFetchingContent: state.attraction.isFetchingContent,
  isAuthenticated: state.user.isAuthenticated,
  isSubmittingReview: state.attractionReview.isSubmittingReview,
  role: state.user.user.role,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getUserIfEmpty,
  getCollegeReviewsAndMajorsIfEmpty, // includes listCollegesIfEmpty
  getAttraction,
  getAttractionContents,
  getAttractionReviews,
  requestLogin,
  push,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLifecycleActions({
  componentWillMount: [getUserIfEmpty, getCollegeReviewsAndMajorsIfEmpty, getAttraction ]
})(AttractionDetails)));