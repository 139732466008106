import React, { useEffect, Fragment, useState } from 'react';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';

// Components
import Spinner from 'react-spinkit';
import { PageContainer } from '../../../components';
import CollegeList from '../../../components/CollegeList'

import { PageContent, BigTitleGrey, DetailsSubHeading, FullWithContent, LoadMoreButtonContainer, LoadMoreButton, LoadMoreButtonLabel } from '../Styled';

const AverageOutStateTuition = ({ address, outStateTuition }) => (
  <DetailsSubHeading as="h3">{address.city}, {address.state} | Out-of-State Tuition: ${outStateTuition.toLocaleString()} </DetailsSubHeading>
);

const CollegeOutStateTuition = ({ isFetching, listCollegesIfEmpty, paginate, count, push }) => {

  const [page, setPage] = useState(1);
  const colleges = paginate(page);

  useEffect(() => {
    if (!colleges || !colleges.length) listCollegesIfEmpty();
  }, [colleges]);

  return (
    <div>
      <Helmet>
        <title>Most AFFORDABLE Out-of-State Colleges - OfficialVisit</title>
        <meta name="description" content="Discover FBS colleges with the lowest out-of-state tuition prices. Air Force, Army West Point, BYU, Southern Miss, Eastern Michigan, Northern Illinois, Arkansas State, Western Michigan, Akron…" />
      </Helmet>
      {isFetching
        ? (
          <PageContainer>
            <PageContent>
              <Spinner fadeIn="none" name="cube-grid" />
            </PageContent>
          </PageContainer>
        )

        : (

          <Fragment>
            <FullWithContent>
              <BigTitleGrey bigTitleLabel="Most Affordable Out-of-State Colleges" />
              <DetailsSubHeading as="h2">
                Colleges are listed from least to most expensive out-of-state tuitions
                </DetailsSubHeading>
            </FullWithContent>
            <PageContainer>
              <PageContent>
                <CollegeList colleges={colleges} rankingComponent={(college) => <AverageOutStateTuition address={college.address} outStateTuition={college.outStateTuition} />} push={push} />

                {colleges && colleges.length < count && (
                  <LoadMoreButtonContainer>
                    <LoadMoreButton onClick={() => setPage(page + 1)}>
                      <LoadMoreButtonLabel>Load More</LoadMoreButtonLabel>
                    </LoadMoreButton>
                  </LoadMoreButtonContainer>
                )}
              </PageContent>
            </PageContainer>
          </Fragment>
        )
      }
    </div>
  )
}

CollegeOutStateTuition.propTypes = {
  colleges: PropTypes.array,
  isFetching: PropTypes.bool,
  listCollegesIfEmpty: PropTypes.func,
  paginate: PropTypes.func,
  count: PropTypes.number,
  push: PropTypes.func
}

export default CollegeOutStateTuition;
