import React from 'react';

import {
  InputContainer,
  StyledSearchIcon,
  StyledInput
} from './Styled'

let LocationsListFilterForm = ({ value, onChange }) => {
  return (
    <InputContainer>
      <StyledInput value={value} onChange={(e) => onChange(e.target.value)} placeholder={'Search'} type={'text'} />
      <StyledSearchIcon />
    </InputContainer>
  );
};

export default LocationsListFilterForm;
