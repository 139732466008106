import React from "react";
import RoleSelForm from "./form";
import Modal from '../../components/modal'
import CloseButton from '../../components/CloseButton'
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";

import {
  GridContainer,
  CloseButtonContainer
} from './Styled'

const RoleSelector = props => {
  
  const close = () => {
    props.backLogin();
  }

  return (
    <Modal>
      <CloseButtonContainer>
        <CloseButton onClick={close}/>
      </CloseButtonContainer>
      <GridContainer>
        <div>
          <RoleSelForm />
        </div>
      </GridContainer>
    </Modal>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { backLogin: () => push("/login") },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps,
)(RoleSelector);
