import { apiEndpoint } from '../config/app';
import createRestApiClient from '../utils/createRestApiClient';

export default () => {
  // Adds token to client if it is passed in
  const token = localStorage.getItem('ovSessToken');
  const config = token ? { 
    baseURL: apiEndpoint, 
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/x-www-form-urlencoded'
    } 
  } : { baseURL: apiEndpoint };

  const client = createRestApiClient().withConfig(config);
  return {
    newContribution: (contribution) => client.request({
      method: 'POST',
      url: '/a/contribution',
      data: contribution
    }),

    listContentTags: () => client.request({
      method: 'GET',
      url: '/api/contenttag'
    }),

    tagContent: (content_id, tags, contributable_id) => client.request({
      method: 'PUT',
      url: '/a/content/' + content_id + '/tag',
      data: { tags, contributable_id }
    })

// 
//     getContribution: (id) => client.request({
//       method: 'GET',
//       url: '/api/college/' + id
//     }),
// 
//     updateContribution: (id, data) => client.request({
//       method: 'PUT',
//       url: '/api/college/' + id,
//       data
//     })
  };
};
