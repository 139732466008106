import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { OVButton } from '../../components';
import { Field, reduxForm } from 'redux-form';
import { Col, Row } from "react-bootstrap";

// Imported Styles
import {
  StyledInputSmall,
  PageContent,
  ButtonMarginTop,
  ButtonMarginRight,
  CheckboxContainer,
  CheckboxLabel,
  CheckboxWarning,
  ErrorMessage
} from './StyledItem';

const Checkbox = ({ input, label, meta: { touched, active, error, warning }}) => (
    <div className="flex items-center mv4 w-100">
      <CheckboxContainer
        {...input}
        className="mr2"
        type="checkbox"
        checked={input.value}
      />
      <div className="sans-serif">{label}</div>
      <CheckboxWarning>
        {touched && ((error && <ErrorMessage>{error}</ErrorMessage>) || (warning && <ErrorMessage>{warning}</ErrorMessage>))}
      </CheckboxWarning>
    </div>
);

const inputField = ({ input, label, type, meta: { touched, active, error } }) => (
  <div >
    <StyledInputSmall {...input} placeholder={label} type={type} />
    {touched && !active && error && <div>{error}</div>}
  </div>
);

let ReviewTitleForm = props => {
  const { handleSubmit, onCancel } = props
  return <PageContent>
    <form id='review-title-form' onSubmit={handleSubmit}>
      <div>
        <div>
          <label htmlFor="title">Title: </label>
          <Field label="Great all-around school" name="title" component={inputField} type="text" />
        </div>
      </div>
      <Row>
        <Col xs={1}>
          <Field
            id="is_anonymous"
            name="is_anonymous"
            component={Checkbox}
            type="checkbox"
          />
        </Col>
        <Col xs={9}>
          <CheckboxLabel>
            Anonymous review
          </CheckboxLabel>
        </Col>
      </Row>
      <ButtonMarginTop>
        <ButtonMarginRight><OVButton  to='' onClick={onCancel}>Back</OVButton></ButtonMarginRight>
        <OVButton  to='' onClick={handleSubmit}>Next</OVButton>
      </ButtonMarginTop>
    </form>
  </PageContent>
}

ReviewTitleForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};
ReviewTitleForm = reduxForm({
  // a unique name for the form
  form: 'reviewTitle'
})(ReviewTitleForm)

ReviewTitleForm = connect(
  ({ review }) => ({
    initialValues: {
      title: review.title,
      is_anonymous: review.is_anonymous
    } // pull initial values from account reducer
  }),
  { }
)(ReviewTitleForm)

export default ReviewTitleForm