import styled from 'styled-components';
import { Heading } from 'rebass';
import { color, typo, bp } from '../../styles';
// OV plus logo
import { ReactComponent as OVPlus } from '../../assets/icons/ov_plus.svg';

export const ListContainer = styled.div`
`;

export const ListItem = styled.div`
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 70px 89px auto;
  grid-gap: 2rem;
  background-color: ${color.ovWhite};
  transition: all .1s;
  border-bottom: 1px solid ${color.ovGrey};
  cursor: pointer;

  @media ${bp.phoneOnly} {
    grid-template-columns: 70px 70px auto;
    padding-bottom: 4rem;
    grid-gap: 1rem;
  }
`;

export const ItemIndex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & > h1 {
    color: ${ color.ovBlack };
    font-size: 40px;
    font-weight: 500;
    margin: 0;

    @media ${bp.phoneOnly} {
      font-size: 36px;
      height: 70px;
      line-height: 70px;
    }
  }
`;

export const ItemPic = styled.div`
  width: 89px;
  height: 89px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${color.ovGreyDarker};
  border-radius: 50%;

  @media ${bp.phoneOnly} {
    width: 70px;
    height: 70px;
    padding: 0;
  }
`;

export const ItemMeta = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
`;

export const ItemHeading = styled(Heading)`
  color: ${ color.ovBlack };
  font-family: ${ typo.ovFontFeature };
  font-size: 40px;
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
  line-height: .8;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 1rem;

  @media ${bp.phoneOnly} {
    font-size: 36px;
  }
`;

export const ItemInfo = styled.div`
  
`;

export const ImgStyle = {
  //objectFit: 'cover',
  height: '49px',
  width: '49px',
  imageOrientation: 'from-image',
};

export const OVrankingTag = styled.div`
  padding: 0 3px;
  height: 21px;
  border-radius: 2px;
  border: solid 1px ${color.ovGrey};
  font-family: ${typo.ovFontFeature};
  font-size: 16px;
  font-weight: 600;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: ${color.ovGrey};
`;

export const StyledLogo = styled(OVPlus)`
	width: 14px;
	height: auto;
  padding-right: 2px;
	path, polygon{
    fill: ${color.ovBlue};
  }
`;