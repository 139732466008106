import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import find from 'lodash/find';
import reject from 'lodash/reject';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {
  PageContainer,
  ListContainer,
  MapContainer,
  EighthPartPageContent,
  QuarterPartPageContent,
  ScrollbarContainer,
  SearchContainer,
  LocationsListTotal,
  ExploreLocationsFilter,
  FiltersBoxClearButton, 
  LocationsListContainer,
  OpenMapButton,
  MapButtonContainer,
  ModalStyled,
  AttractionCardTitile,
  AttractionCardTitleBox,
  AttractionCardText,
  AttractionCardTextBox,
  OVButtonRight,
  OVButtonleft,
  PageContent,
} from './Styled'

import CollegeHeaderLite from '../../components/CollegeHeader/CollegeHeaderLite';
import LocationsFiltersList from '../../components/Locations/LocationsFiltersList';
import LocationsCard from '../../components/Locations/LocationsCard';
import LocationsAdd from '../../components/Locations/LocationsAdd';
import LocationsMap from '../../components/Locations/LocationsMap';
import LocationsFilter from '../../components/Locations/LocationsFilter'
import LocationsListFilterForm from "./filter";
import debounce from "lodash/debounce";
import Modal from '../../components/modal'

const toggleTags = (filters, setFilters) => (tag) => {
  let result;
  if (find(filters, { ID: tag.ID })) {
    result = reject(filters, { ID: tag.ID });
  } else {
    result = [
      ...filters,
      tag,
    ];
  }
  setFilters(result);
};

const applyFilters = list => (tags, search) => list && list.reduce((memo, item) => {
  if (!tags.length && !search) return list;

  let matches = true;

  if (search) {
    matches = matches && item.title.toLowerCase().includes(search.toLowerCase());
  }

  if (tags.length && item.tags) {
    matches = matches && item.tags.some(({ ID }) => find(tags, { ID }));
  }

  if (matches) memo.push(item);
  return memo;
}, []);

const useGetCollege = (getCollegeReviewsAndMajorsIfEmpty, college) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      getCollegeReviewsAndMajorsIfEmpty();
      didMountRef.current = true;
    }
  }, [college]);
};


const Attractions = ({
  role,
  college,
  isFetching,
  averageReview,

  attractions,
  tags,

  deleteAttraction,
  getAttractions,
  getCollege,
  push,
  getUserIfEmpty,
}) => {

  useGetCollege(getCollege, college);

  useEffect(() => {
    if (!role) {
      getUserIfEmpty();
    }
    if (college.ID && !isFetching && !attractions) {
      getAttractions(college.ID);
    }
  }, [isFetching, college, attractions, getAttractions]);
  const locationsFilterRef = useRef();

  const [filters, setFilters] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [openMap, setOpenMap] = useState(false);

  const [hoverCard, setHoverCard] = useState("");

  const [show, toggleShow] = useState(false);

  const [deleteShowModal, toggleDeleteModal] = useState(false);

  const debouncedSetSearchFilter = debounce(setSearchFilter, 500);

  const [attractionById, setAttractionById] = useState('')

  const filtered = applyFilters(attractions)(filters, searchFilter);
  const toggle = toggleTags(filters, setFilters);

  const handleAttractionById = attraction => {
    setAttractionById(attraction);
    toggleDeleteModal(!deleteShowModal);
  }

  const handleDelete = () => {
    deleteAttraction(attractionById);
    toggleDeleteModal(!deleteShowModal);
  }

  const metaTitle = college.name + ' Campus Life - OfficialVisit';
  const metaDescription = 'Discover all that ' + college.name + ' and its surrounding area have to offer. Housing, dining, local attractions, football facilities, and much more!';
  return (
    <div>
      {college.name && <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>}
      <CollegeHeaderLite
        isFetching={isFetching}
        openMap={openMap}
        college={college}
        averageReview={averageReview}

        role={role}
        push={push}
      />

      <LocationsFilter
        tags={tags}
        filters={filters}
        onChange={setFilters}
        onToggleShow={toggleShow}
        show={show}
        ref={locationsFilterRef}
      />

      <LocationsFiltersList
        tags={tags}
        filters={filters}
        onToggle={toggle}
        onToggleShow={toggleShow}
        openMap={openMap}
      />
      <PageContainer>
        <QuarterPartPageContent>
          <MapButtonContainer openMap={openMap}>
            <OpenMapButton onClick={()=> setOpenMap(!openMap)}>
              {!openMap ? "Launch Map" : "Collapse"}
            </OpenMapButton>
          </MapButtonContainer>
          <SearchContainer openMap={openMap}>
            {filtered && (<ExploreLocationsFilter>
              <LocationsListFilterForm value={searchValue} onChange={(val) => { debouncedSetSearchFilter(val); setSearchValue(val);}}/>
              <FiltersBoxClearButton onClick={()=>{setFilters([]); setSearchValue(''); debouncedSetSearchFilter(''); locationsFilterRef.current.clearFilters()}}>Clear Filters</FiltersBoxClearButton>
            </ExploreLocationsFilter>)}
            {filtered && (<LocationsListTotal>
              Explore <span>{filtered.length}</span> places and attractions that make {college.name} unique
            </LocationsListTotal>)}
          </SearchContainer>
          <LocationsListContainer openMap={openMap}>
            <ScrollbarContainer>
              <ListContainer>
                {role === 'admin' &&
                  <LocationsAdd
                    onClick={() => push(`/admin/attractions/new`)}
                  />
                }
                {filtered && filtered.map(attraction =>
                  <LocationsCard
                    key={`att-${attraction.ID}`}
                    ID={attraction.ID}
                    role={role}
                    title={attraction.title}
                    about={attraction.about}
                    average_review={attraction.average_review}
                    tags={attraction.tags}
                    onClick={() => push(`/college/${college.url_slug}/attractions/${attraction.ID}`)}
                    onEdit={() => push(`/admin/attractions/${attraction.ID}`)}
                    onDelete={() => handleAttractionById(attraction)}
                    onMouseEnter={() => setHoverCard(attraction.title)}
                    onMouseLeave={() => setHoverCard("")}
                  />
                )}
              </ListContainer>
            </ScrollbarContainer>
          </LocationsListContainer>
        </QuarterPartPageContent>
        {
          deleteShowModal && (
            <Modal>
              <ModalStyled>
                <AttractionCardTitleBox>
                  <AttractionCardTitile> Delete permanently</AttractionCardTitile>
                </AttractionCardTitleBox>

                <AttractionCardTextBox>
                  <AttractionCardTextBox>
                    <AttractionCardText>This attraction will be permanently deleted.</AttractionCardText>
                    <AttractionCardText>This cannot be undone.</AttractionCardText>
                  </AttractionCardTextBox>
                </AttractionCardTextBox>
                <PageContent>
                  <OVButtonRight onClick={() => toggleDeleteModal(!deleteShowModal)}>Cancel</OVButtonRight>
                  <OVButtonleft onClick={() => handleDelete() }>Delete</OVButtonleft>
                </PageContent>
              </ModalStyled>
            </Modal>
          )
        }
              
        <EighthPartPageContent>
          <MapContainer openMap={openMap}>
            <LocationsMap
              center={college && college.location && college.location.geo_json.coordinates}
              isFetching={isFetching}
              attractions={filtered}
              college_url_slug={college.url_slug}
              clickable_markers={true}
              canExpand
              hoverCard={hoverCard}
            />
          </MapContainer>
        </EighthPartPageContent>
      </PageContainer>
    </div>
  )
};

Attractions.propTypes = {
  college: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  averageReview: PropTypes.object.isRequired,
  role: PropTypes.string,
  attractions: PropTypes.array,
  tags: PropTypes.array,

  deleteAttraction: PropTypes.func,
  getAttractions: PropTypes.func,
  getCollege: PropTypes.func,
  push: PropTypes.func,
};

export default Attractions;