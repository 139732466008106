import React from 'react';
import PropTypes from 'prop-types';

import{	
	HeaderContainer,
	UserNameContainer
} from './Styled'

const Header = ({
	user,
}) => (
	<HeaderContainer>
		<UserNameContainer>{user.first_name} {user.last_name}’s top schools</UserNameContainer>
	</HeaderContainer>
);

Header.propTypes = {
	user: PropTypes.shape({
		first_name: PropTypes.string.isRequired,
		last_name: PropTypes.string.isRequired,
	}).isRequired,
}

export default Header;