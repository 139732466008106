import { apiEndpoint } from '../config/app';
import createRestApiClient from '../utils/createRestApiClient';

export default () => {
  // Adds token to client if it is passed in
  const token = localStorage.getItem('ovSessToken');
  const config = token ? { 
    baseURL: apiEndpoint, 
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/x-www-form-urlencoded'
    } 
  } : { baseURL: apiEndpoint };
  const client = createRestApiClient().withConfig(config);
  return {
    getOtherUser: (id) => {
      return client.request({
        method: 'GET',
        url: '/api/users/' + id
      })
    },
    getOtherUserProfile: (id) => {
      return client.request({
        method: 'GET',
        url: '/api/users/' + id + '/profile'
      })
    },
    getOtherUserReviews: (id) => {
      return client.request({
        method: 'GET',
        url: '/api/users/' + id + '/reviews'
      })
    },
    getOtherUserContents: (id) => {
      return client.request({
        method: 'GET',
        url: '/api/users/' + id + '/contents'
      })
    },
  };
};
