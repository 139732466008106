import styled, { css } from 'styled-components';
import { bp } from '../../../styles';
export const ModalText = styled.span`
  color: #000000;
  font-family: Marbach
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: 0.4px;
  margin: 0 0 35px;
  text-align: left;
  width: 602px;
  @media ${bp.phoneOnly} {
    width: 100%;
    padding: 0 20px 0 0;
  }
`;

export const ModalTitle = styled.span`
  color: #000000;
  font-family: Molde;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  font-stretch: condensed;
  height: 36px;
  line-height: 36px;
  letter-spacing: normal;
  margin: 5px 0 19px;
  padding-right: 25px;
  text-align: left;
  width: 446px;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%
`;

export const CustomModal = styled.div`
  .overlay {
    background: rgba(0, 0, 0, 0.35);
    zIndex: 9999999;
  }

  .content {
    width: 100%;
    height: 100%;
    inset: 0;
    padding: 5px 5px 0 36.2px;
    borderRadius: 5px;
    boxShadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    background: #ffffff;
    display: flex;
    flexDirection: column;
  }
  
  @media ${bp.tabletLandUp} {
    .overlay {
      background: rgba(0, 0, 0, 0.35);
      zIndex: 9999999;
    }
    .content {
      width: 703.2px;
      height: fit-content;
      marginLeft: calc(50% - 351.6px);
      marginTop: 15%;
      inset: 0;
      padding: 5px 5px 0 36.2px;
      borderRadius: 5px;
      boxShadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
      background: #ffffff;
      display: flex;
      flexDirection: column;
    }
  }
`;
