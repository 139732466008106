import { color, bp, typo } from '../../styles';
import styled from 'styled-components';
import { Heading } from 'rebass';
import { ArrowDropDown} from 'styled-icons/material/ArrowDropDown';
import { ArrowDropUp} from 'styled-icons/material/ArrowDropUp';
import { ReactComponent as One } from './ratingIcons/1.0.svg';
import { ReactComponent as Zero } from './ratingIcons/0.0.svg';

export const DownArrow = styled(ArrowDropDown)`
  display: inline;
  height: 2.5rem;
  position: relative;
  top: -0.4rem;
  left: -0.4rem;
`;

export const UpArrow = styled(ArrowDropUp)`
  display: inline;
  height: 2.5rem;
  position: relative;
  top: -0.3rem;
  left: -0.4rem;
`;

export const Comment = styled.p`
  grid-column: 2 / 4;

  color: ${color.ovBlack};
  font-family: ${typo.ovFontSerif};
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-bottom: 2rem;

  padding-top: 0.2rem;
  padding-right: 2em;

  @media ${bp.phoneOnly} {
    padding-right: 1rem;
    grid-column: 2 / 4;
  }
`;

export const SmallReviewBox = styled.div`
  display: grid;
  grid-template-columns: 4rem 12rem 1fr;

  @media ${bp.phoneOnly} {
    margin-left: 2rem;
    grid-template-columns: 2rem 12rem 1fr;
  }
`;

export const ReviewLabel = styled(Heading)`
  grid-column: 2 / 3;

  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
  line-height: 1;
  display: inline;
  @media ${bp.phoneOnly} {
    grid-column: 2 / 3;
  }
`;

export const SmallReviewList = styled.div`
  grid-column: auto;
  @media ${bp.phoneOnly} {
    grid-column: 3 / 4;
  }
`;

export const SmallSizedOne = styled(One)`
  height: 1.4rem;
  margin-right: 0.2rem;
`;

export const SmallSizedZero = styled(Zero)`
  height: 1.4rem;
  margin-right: 0.2rem;
`;