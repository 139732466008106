import React from 'react';
// Rediux stuff
import { Field, reduxForm } from 'redux-form';
// Components
import StyledInput from '../../components/styledInput';

import {
  ErrorMessage,
  NextButton,
  CheckboxContainer,
  CheckboxLabel,
  CheckboxWarning,
  TermsAndConditionsBox
} from './Styled';

// InputField
const inputField = ({ input, label, type, meta: { touched, active, error, warning } }) => (
  <div >
    <StyledInput {...input} placeholder={label} type={type} />
    {touched && ((error && <ErrorMessage>{error}</ErrorMessage>) || (warning && <ErrorMessage>{warning}</ErrorMessage>))}
  </div>
);
// end of InputField

// 
// Checkbox
const Checkbox = ({ input, label, meta: { touched, active, error, warning }}) => (
    <div className="flex items-center mv4 w-100">
      <CheckboxContainer
        {...input}
        className="mr2"
        type="checkbox"
        checked={input.value}
      />
      <div className="sans-serif">{label}</div>
      <CheckboxWarning>
        {touched && ((error && <ErrorMessage>{error}</ErrorMessage>) || (warning && <ErrorMessage>{warning}</ErrorMessage>))}
      </CheckboxWarning>
    </div>
);
// end of Checkbox
// 
// Validation
const required = value => value ? undefined : 'Required';

let Step2 = props => {
  const {
    handleSubmit,
    currentStep
  } = props;

  // Will hide the component if the current step is not equal to 2
  if (currentStep !== 2) { return null }

  return (
    <form onSubmit={handleSubmit}>
       <Field 
        name="location" 
        label="Location" 
        component={inputField} 
        type="text" 
        validate={[required]}
      />
      <TermsAndConditionsBox>
        <Field
          id="seasonticketholder"
          name="seasonticketholder"
          component={Checkbox}
          type="checkbox"
        />
        <CheckboxLabel>
          I am a season ticket holder
        </CheckboxLabel>
      </TermsAndConditionsBox>
      <TermsAndConditionsBox>
        <Field
          id="athleticdepartmentdonor"
          name="athleticdepartmentdonor"
          component={Checkbox}
          type="checkbox"
        />
        <CheckboxLabel>
          I am an athletic department donor
        </CheckboxLabel>
      </TermsAndConditionsBox>
      <div>
        <NextButton type="submit">Sign Up</NextButton>
      </div>
    </form>
  )
}

Step2 = reduxForm({
  // a unique name for the form
  form: 'FanForm',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true
})(Step2)

export default Step2