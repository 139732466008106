import React from 'react';
import PropTypes from 'prop-types';

import { onComponentDidMount } from 'react-redux-lifecycle';
import { isMobile } from '../../config/app';
import styled from 'styled-components';
import Img from 'react-image';
import Lightbox from 'react-images';
import Popup from 'reactjs-popup';
import Spinner from 'react-spinkit';
import { Heading } from 'rebass';
import { PageContainer, FloatRightCloseButton, CloseButton, OVButton } from '../../components';
import { color, bp, typo } from '../../styles';


import './style.css';


/////////////////////////////////////////
// For the Images
/////////////////////////////////////////
const ImageContainer = styled.div`
  grid-column: span 3;

  background: ${color.ovWhite};
  vertical-align : top;
  overflow: hidden;
  display: inline-block;
  border-radius: 1px;
  text-align: center;
  border: 2px solid ${color.ovWhite};
  position: relative;
  cursor: pointer;

  @media ${bp.phoneOnly} {
    grid-column: span 6;

    height: 100px;
    width: 100%;
    border: 2px solid ${color.ovGreyLight};
  }
  @media ${bp.tabletPortUp} {
    height: 100px;
    width: 130px;
    margin-bottom: 14px;
  }
  @media ${bp.tabletLandUp} {
    height: 154px;
    width: 200px;
    margin-bottom: 18px;
  }
  @media ${bp.desktopUp} {
    height: 176px;
    width: 230px;
    margin-bottom: 18px;
  }
  @media ${bp.wideUp} {
    height: 230px;
    width: 300px;
    margin-bottom: 20px;
  }

`;

const ImgStyle = {
  objectFit: 'cover',
  height: '100%',
  width: '100%',
  imageOrientation: 'from-image'
}


//////////////////////////////////////////

const captionContainer = {
  cursor: 'pointer'
};

const CloseBar = styled.div`
  background: ${color.ovGreyDark};
  height: 36px;
  width: 100%;
  position: absolute;
  top: 62px;
`;

const MarginRightContainer = styled.span`
  margin-right: 20px;
`;

const OVButtonMarginRight = ({ onClick, children }) => <MarginRightContainer onClick={onClick}><OVButton>{children}</OVButton></MarginRightContainer>

const MarginBottomContainer = styled.div`
  margin-bottom: 10px;
`;

const OVButtonMarginBottom = ({ onClick, children }) => <MarginBottomContainer onClick={onClick}><OVButton>{children}</OVButton></MarginBottomContainer>

const AbsBackgroundContainer = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  background: ${color.ovWhite};
`;

const ImageCloseButton = ({ onClick, children }) => <AbsBackgroundContainer onClick={onClick}><CloseButton>{children}</CloseButton></AbsBackgroundContainer>

const ImageCaption = ({push, user, caption, isFetching}) => {
  const userCaption = isFetching ? 'Loading...' : user && user.ID ? `Uploaded by ${user.first_name} ${user.last_name}` : '';
  // if both present then split by '|' else one is empty
  const title = caption && userCaption ? caption + " | " + userCaption : caption + userCaption;
  return (
    <div style={captionContainer} onClick={() => user && user.ID ? push(`/user/${user.ID}`) : null}>
      <span>{title}</span>
    </div>
  )
};

const SubHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 24px;
  margin-bottom: 40px;

  @media ${bp.phoneOnly} {
    font-size: 18px;
  }
`;

const Modal = styled.div`
  background-color: ${color.ovWhite};
  padding: 15px;
  border: 3px solid ${color.ovBlue};
`;

/////////////////////////////////////////
// For the Filter Section
/////////////////////////////////////////

const FilterContainer = styled.div`
  grid-column: span 12;
  margin: 20px 0;
  display: inline-block;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  
`;

const FilterRow = styled.div`
  grid-column: span 12;
  
`;

//////////////////////////////////////////

const manageInitialState = ({
  contents,
  goBack,
}) => {
  // redirect to college details page if there are no contents
  if (!contents || contents.length === 0) {
    goBack();
  }

  return { type: 'NOOP' };
};

const AttractionContent = ({
  match,
  push,
  contents,
  isManaging,
  isFetching,
  toggleLightboxOpen,
  toggleManage,
  lightboxOpen,
  selectNextIndex,
  selectPrevIndex,
  selectContentIndex,
  selectedContentIndex,
  deleteContent,
  getUserId,
  _user
}) => {
  // Creates a new array for Lightbox component, with just the image src and the caption (with the user who uploaded the photo)
  const lightboxImageSet = contents
    .map(({ ID, caption, src, src_md, user}) => ({
      ID,
      src,
      src_md,
      user,
      caption: (<ImageCaption user={user} caption={caption} push={push} isFetching={isFetching} />)
    }));

  return (
    <div>
      <Lightbox
        className="content-lightbox"
        images={lightboxImageSet}
        isOpen={lightboxOpen}
        currentImage={selectedContentIndex}
        onClickPrev={selectPrevIndex}
        onClickNext={selectNextIndex}
        onClose={toggleLightboxOpen}
        spinner={() => <Spinner fadeIn="none" name="cube-grid" />}
        spinnerColor={'white'}
        backdropClosesModal={true}
      />
      <CloseBar>
        <FloatRightCloseButton onClick={() => push('/college/' + match.params.url_slug + '/attractions/' + match.params.id)} />
      </CloseBar>
      <PageContainer>
        <FilterContainer>
          <FilterRow>
            {!isManaging ? <OVButton onClick={toggleManage}>Manage</OVButton> : <OVButton onClick={toggleManage}>Finish</OVButton>}
          </FilterRow>
        </FilterContainer>
        {/* If the there is filtered content to show, do so   */
          lightboxImageSet && lightboxImageSet.length ? lightboxImageSet.map((content, i) =>
            <ImageContainer onClick={() => { !isManaging && selectContentIndex(i); !isManaging && toggleLightboxOpen(); selectContentIndex(i); getUserId(lightboxImageSet[i].ID); }} key={'image_cont_' + i}>
              {isManaging &&
                <Popup trigger={<ImageCloseButton />} modal>
                  {
                    close => (
                      <Modal>
                        <SubHeading>Are you sure you want to delete this photo?</SubHeading>
                        {!isMobile ? <OVButtonMarginRight onClick={close}>Cancel</OVButtonMarginRight> : <OVButtonMarginBottom onClick={close}>Cancel</OVButtonMarginBottom>}
                        <OVButton onClick={() => { deleteContent(content); close(); }}>Delete</OVButton>
                      </Modal>
                    )
                  }
                </Popup>}
              <Img
                key={'img_' + i}
                src={content.src_md}
                alt={content.caption}
                style={ImgStyle}
                loader={<Spinner fadeIn="none" name="cube-grid" />}
              />
            </ImageContainer>) : <span />
        }
      </PageContainer>
    </div>
  )
};

AttractionContent.propTypes = {
  contents: PropTypes.array,
  lightboxOpen: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  toggleLightboxOpen: PropTypes.func.isRequired,
  selectNextIndex: PropTypes.func.isRequired,
  selectPrevIndex: PropTypes.func.isRequired,
  selectContentIndex: PropTypes.func.isRequired,
  selectedContentIndex: PropTypes.number.isRequired,
  isManaging: PropTypes.bool.isRequired,
  toggleManage: PropTypes.func.isRequired,
  deleteContent: PropTypes.func.isRequired,
  getUserId: PropTypes.func.isRequired,
};

export default onComponentDidMount(manageInitialState)(AttractionContent)
