import { Button, Heading } from 'rebass';
import styled from 'styled-components';
import { color, typo, bp } from '../../../styles';

export const CenterPane = styled.div`
  grid-column: span 12;

  padding-top: 180px;
  text-align: center;
`;

export const LeftPane = styled.div`
  grid-column: span 12;

  @media ${bp.tabletLandUp} {
    grid-column: span 4;
  }

  padding: 25px;
  overflow: none;
`;

export const RightPane = styled.div`
  grid-column: span 12;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: min-content;
  grid-auto-rows: min-content;

  overflow-y: auto;
  overflow-x: hidden;

  background: ${color.ovGreyLight};
  height: 100%;

  -webkit-box-shadow: inset rgba(0, 0, 0, 0.47) 4px 0px 4px -4px;
  -moz-box-shadow: inset rgba(0, 0, 0, 0.47) 4px 0px 4px -4px;
  box-shadow: inset rgba(0, 0, 0, 0.47) 4px 0px 4px -4px;

  @media ${bp.tabletLandUp} {
    grid-column: span 8;
    height: 79.8vh;
  }
`;

export const ContributeHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 32px;
  margin-bottom: 20px;
  text-align: center;
  line-height: 24px;
`;

export const DropzoneOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${color.ovTransparentBlue};
  text-align: center;
  color: ${color.ovWhite};
  font-family: ${typo.ovFontFeature};
  font-size: 32px;
  padding-top: 180px;
  border: 4px dashed ${color.ovBlue};
  border-radius: 0px;
  z-index: 2;
`;

export const ButtonContainer = styled.div`
  margin-bottom: 10px;
  text-align: right;
`;

export const OVButton = styled(Button)`
  display: inline-block;
  font-family: ${typo.ovFontFeature};
  background: ${color.ovYellow};
  color: ${color.ovWhite};
  cursor: pointer;
  padding: 2px 40px;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.95;
  letter-spacing: normal;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(5, 5, 5, 0.16);
  border: solid 1px ${color.ovGreyDark};
  transition: all .1s;
  &:hover, &:focus {
    text-decoration: none;
    background: ${color.ovBlueLight};
    border-color: ${color.ovBlueLight};
    transition: all .1s;
  }
`;

export const OVModalButton = styled(OVButton)`
  display: block;
`;

export const FileItem = styled.div`
  grid-column: span 12;
  background: ${color.ovWhite};
  width: 100%;
  border-bottom: 1px solid ${color.ovGreyLight};
  border-left: 1px solid ${color.ovGreyLight};
  border-radius: 2px;
  padding: 0px;


  display: grid;
  grid-template-rows: min-content;
  grid-auto-rows: min-content;

  @media ${bp.phoneOnly} {
    grid-template-columns: repeat(12, 1fr);
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    border-left: none;
    border-bottom: none;
    border-top: 2px solid ${color.ovGreyLight};
  }
  @media ${bp.tabletPortUp} {
    grid-template-columns: 120px repeat(12, 1fr);
  }
  @media ${bp.tabletLandUp} {
    grid-template-columns: 180px repeat(12, 1fr);
  }
  @media ${bp.desktopUp} {
    grid-template-columns: 250px repeat(12, 1fr);
  }
  @media ${bp.wideUp} {
    grid-template-columns: 300px repeat(12, 1fr);
  }
`;

export const BaseFileItem = styled.div`
  grid-column: span 12;
  background: ${color.ovWhite};
  width: 100%;
  border: 2px dashed ${color.ovGrey};
  border-radius: 2px;
  padding: 12px;

  /* Hide on Mobile */
  display: none;
  @media ${bp.tabletLandUp} {
    display: inline-block;
  }
`;

/////////////////////////////////////////
// For the Preview Image
/////////////////////////////////////////
export const ImageContainer = styled.div`
  grid-column: span 2;

  background: ${color.ovGreyLight};
  vertical-align : top;
  overflow: hidden;
  display: inline-block;
  border-radius: 1px;
  text-align: center;
  border: 1px solid ${color.ovGrey};
  margin-right: 18px;
  position: relative;

  @media ${bp.phoneOnly} {
    grid-column: span 12;

    height: 100px;
    width: 100%;
    margin-right: 0px;
  }
  @media ${bp.tabletPortUp} {
    height: 120px;
    width: 120px;
    margin-left: 25px
  }
  @media ${bp.tabletLandUp} {
    height: 180px;
    width: 180px;
    margin-left: 0px
  }
  @media ${bp.desktopUp} {
    height: 250px;
    width: 250px;
  }
  @media ${bp.wideUp} {
    height: 300px;
    width: 300px;
  }

`;

export const ImgStyle = {
  maxHeight: '100%',
  maxWidth: '100%',
  width: 'auto',
  height: 'auto',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
  imageOrientation: 'from-image'
}

//////////////////////////////////////////

export const InputContainer = styled.div`
  margin-bottom: 18px;
`;

export let StyledInput = styled.input`
  display: inline-block;
  width: 100%;
  height: 35px;
  border: solid 3px ${color.ovBlue};
  padding-left: 13px;
  background-color: ${color.ovWhite};

  font-family: ${typo.ovFontFeature};
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlue};

  &::placeholder{
    font-family: ${typo.ovFontFeature};
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: ${color.ovGrey};
  }
`;

export const FileHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 24px;
  margin-bottom: 4px;
  text-align: left;
`;

export const BaseFileHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 24px;
  margin-bottom: 4px;
  text-align: center;
`;

export const TagList = styled.ul`
  grid-column: span 6;

  @media ${bp.desktopUp} {
    grid-column: span 5;
  }

  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-bottom: 8px;
`;

export const Tag = styled.li`
  border: none;
  border-radius: 2px;
  background-color: transparent;

  font-family: ${typo.ovFontFeature};
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  display: block;
  width: 107px;
  margin-bottom: 2px;
  padding: 4px 5px;

  cursor: pointer;
`;

export const SelectedTag = styled(Tag)`
  background-color: ${color.ovBlue};
  color: ${color.ovWhite};

`;

export const FileItemFormContainer = styled.div`
  grid-column: span 12;

  @media ${bp.tabletPortUp} {
    grid-column: span 10;
  }

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: min-content;
  grid-auto-rows: min-content;
`;

export const FileCloseButtonContainer = styled.div`
  grid-column: span 1;
`;

export const InputSet = styled.div`
  grid-column: span 11;
  display: inline-block;

  width: 100%;
`;
