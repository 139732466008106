import React from 'react';
import styled from 'styled-components';
import { color, bp } from '../../styles';

const ModalContainer = styled.div`
  background-color: rgba(255,255,255, 0.6);
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  z-index: 9999999999;
  padding-top: 50px;
  overflow: auto;
  display: flex;
  justify-content: center;
  @media ${bp.tabletLandscape} {
    /* min-height: calc(100vh + 60px); */
  }
  @media ${bp.tabletLandUp} {
    min-height: 100vh;
  }
  @media ${bp.phoneOnly} {
    padding-top: 0px;
    /* min-height: calc(100vh + 140px);  */
  }
`;

const ModalContent = styled.div`
  position: fixed;
  background-color: ${color.ovWhite};
  margin: 50px 0;
  border: solid 1px ${color.ovGreyDarker};
  @media ${bp.phoneOnly} {
    width: 100%;
    min-height: 100vh;
    border: none;
    margin: 0;
  }
  @media ${bp.tabletPortUp}{
    width: 579px;
  }
  @media ${bp.tabletLandUp}{
    width: 679px;
  }
  
`;

const ModalFixed = ({ 
    children
  }) =>{

  return (
    <ModalContainer>
      <ModalContent>
        {children}
      </ModalContent>
    </ModalContainer>
  )
} 

export default ModalFixed