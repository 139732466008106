import styled from 'styled-components';
import { color, typo, bp } from '../../../../styles';

export const StyledSelectedList = styled.div`

  > span {
    display: inline-flex;
    height: 28px;
    align-items: center;

    padding-left: 12px;
    margin-bottom: 8px;
    margin-right: 5px;
    border-radius: 11px;
    border: solid 1px ${color.ovBlue};
    background-color: ${color.ovBlue};

    font-size: 18px;
    font-weight: 600;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    
    > i {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-left: 1px solid #ffffff;
      height: 22px;
      width: 30px;
      margin-left: 12px
      :after {
        display: block;
        content: "x";

        font-size: 18px;
        font-weight: 400;
        font-style: normal;
        color: #ffffff;

      }
    }
  }
`
