import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Rediux stuff
import { Field, reduxForm } from 'redux-form';
import { Col, Row } from 'react-bootstrap';
// Components
import StyledInput from '../../components/styledInput';
import PlacesAutocomplete from '../../components/PlacesAutocomplete';

import {
  TitleHeading,
  ErrorMessage,
  NextButton
} from './Styled';

// InputField
const inputField = ({ step, input, label, type, meta: { touched, active, error, warning } }) => (
  <div >
    <StyledInput {...input} placeholder={label} type={type} step={step} />
    {touched && ((error && <ErrorMessage>{error}</ErrorMessage>) || (warning && <ErrorMessage>{warning}</ErrorMessage>))}
  </div>
);
// end of InputField

const placesField = ({ input, label, type, meta: { touched, active, error, warning } }) => (
  <div >
    <PlacesAutocomplete
      {...input}
      placeholder={label}
    />
    {touched && ((error && <ErrorMessage>{error}</ErrorMessage>) || (warning && <ErrorMessage>{warning}</ErrorMessage>))}
  </div>
)
// end of placesField

// 
// Validation
const required = value => value ? undefined : 'Required';

let Step1 = props => {

  const [isFocused, toogleFocused] = useState(false);

  const {
    handleSubmit,
    currentStep
  } = props;
  
  // Will hide the component if the current step is not equal to 1
  if (currentStep !== 1) { return null }
  return (
    <form onSubmit={handleSubmit}>
      <TitleHeading>Which high school did you attend?</TitleHeading>
      <Field
        onFocus={() => toogleFocused(true)} 
        name="school" 
        label={`${isFocused ? "Type to search for your high school..." : "Current school"}`} 
        component={placesField} 
        type="text"
        validate={[required]}
      />
      <Field 
        name="graduationyear" 
        label="High School Graduation Year" 
        component={inputField} 
        type="number" 
        validate={[required]}
      />
      <Row>
        <Col xs={6}>
          <Field 
            name="sat" 
            label="SAT" 
            component={inputField} 
            type="number"
            step="0.01"
          />
        </Col>
        <Col xs={6}>
          <Field 
            name="gpa" 
            label="GPA" 
            component={inputField} 
            type="number"
            step="0.01"
          />
        </Col>
      </Row>
      <div>
        <NextButton type="submit">Next</NextButton>
      </div>
    </form>
  )
}

Step1.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

Step1 = reduxForm({
  // a unique name for the form
  form: 'RecruitForm',
  destroyOnUnmount: false
})(Step1)

export default Step1;
