import React from 'react';
import styled from 'styled-components';


const Container12 = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
`;

export default ({children}) => (
  <Container12>
    { children }
  </Container12>
)