import { push } from "connected-react-router";
import differenceBy from 'lodash/differenceBy';
import filter from 'lodash/reject';
import sortBy from 'lodash/fp/sortBy';

import { toast } from 'react-toastify';

import { submitLogout } from '../user';

import * as actions from './actions';
import * as upload from "../upload";
import { updateCoachDefaultPhoto } from "./actions";
import { listCollegesIfEmpty, mapReviewsWithUserCollege } from "../college";
import { coachService } from "../../services";

export const listCoaches = () => {
  return async (dispatch) => {
    dispatch(actions.listCoachesRequest());

    try {
      const res = await coachService().getCoaches();
      return dispatch(actions.listCoachesSuccess(res.data.data));
    } catch (e) {
      // log user out if unauthorized
      dispatch(actions.listCoachesFailure())
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    } finally {
      return;
    }
  }
}

export const listCoachesIfEmpty = () => {
  return (dispatch, getState) => {
    return dispatch(listCoaches())
  }
}

export const searchCoaches = ({ year, title, college, name }, page, sortBy, dir) => {
  return async (dispatch) => {
    dispatch(actions.searchCoachesRequest({ year, title, college, name }));

    try {
      const res = await coachService().searchCoaches({
          year: year && year.join(),
          title: title && title.join(),
          college: college && college.join(),
          name
        }, page, 20, sortBy, dir);

      const { data, count } = res.data;
      return dispatch(actions.searchCoachesSuccess({ data, count }));
    } catch (e) {
      dispatch(actions.searchCoachesFailure(e));
      console.error('searchCoaches: ERROR', e);
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    } finally {
      return;
    }
  }
}

export const getCoachTitles = () => {
  return async (dispatch) => {
    dispatch(actions.getCoachTitlesRequest());

    try {
      const res = await coachService().getCoachTitles();
      return dispatch(actions.getCoachTitlesSuccess(res.data.data));
    } catch (e) {
      dispatch(actions.getCoachTitlesFailure(e));
      console.error('getCoachTitles: ERROR', e);
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    } finally {
      return;
    }
  }
}

export const getCoachProfileBySlug = (coachSlug) => {
  return async dispatch => {
    dispatch(actions.requestGetCoachProfile())
    try {
      const { status, data: { data } } = await coachService().getCoachProfileBySlug(coachSlug);

      if (status === 200) {
        dispatch(actions.getCoachProfileSuccess(data));
        dispatch(coachingHistory(data.coached_seasons));
      }

      // load reviews
      dispatch(getCoachReviewsByCoachID(data.ID));
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401 || e.response.status === 404) {
        dispatch(actions.getCoachProfileFailure('There was a problem fetching the requested profile'))
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }
  }
}

export const getCoachProfileByID = (coachID) => {
  return async dispatch => {
    dispatch(actions.requestGetCoachProfile())
    try {
      const { status, data: { data } } = await coachService().getCoachProfileByID(coachID);

      if (status === 200) {
        dispatch(actions.getCoachProfileSuccess(data));
        dispatch(coachingHistory(data.coached_seasons));
      }
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401 || e.response.status === 404) {
        dispatch(actions.getCoachProfileFailure('There was a problem fetching the requested profile'))
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }
  }
}

export const getCoachProfileIfEmpty = () => {
  return (dispatch, getState) => {
    const { coach, router } = getState();
    const pathEntity = router.location.pathname.split("/").pop();

    if (coach.coachProfile.url_slug !== pathEntity) {
      dispatch(getCoachProfileBySlug(pathEntity));
    }
  }
}

export const getAdminCoachProfileIfEmpty = () => {
  return (dispatch, getState) => {
    const { coach, router } = getState();
    const pathEntity = router.location.pathname.split("/").pop();

    if (!pathEntity || pathEntity === 'new') return;
    else if (coach.coachProfile.ID !== pathEntity) dispatch(getCoachProfileByID(pathEntity));
  }
};

export const getCoachAverageVerifiedReviewByCoachID = (coachID) => {
  return async (dispatch, getState) => {
    dispatch(actions.getCoachAverageVerifiedReviewRequest());
    try {
        const res = await coachService().getAverageReview(coachID);
        return dispatch(actions.getCoachAverageVerifiedReviewSuccess(res.data.data));
    } catch (e) {
      dispatch(actions.getCoachAverageVerifiedReviewFailure());
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }
  }
};

export const getCoachAverageVerifiedReviews = () => {
  return async (dispatch, getState) => {
    dispatch(actions.getCoachAverageVerifiedReviewsRequest());
    try {
        const res = await coachService().getAverageReviews();
        return dispatch(actions.getCoachAverageVerifiedReviewsSuccess(res.data.data));
    } catch (e) {
      dispatch(actions.getCoachAverageVerifiedReviewsFailure());
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }
  }
};

export const getCoachReviewsByCoachID = (coachID) => {
  return async (dispatch, getState) => {
    // need to get list of colleges first
    await dispatch(listCollegesIfEmpty());

    const { user, college: { colleges } } = getState();

    dispatch(actions.getCoachReviewsRequest());
    try {
      const res = user.isAuthenticated
        ? await coachService().getAuthReviews(coachID)
        : await coachService().getApiReviews(coachID);

      const reviews = mapReviewsWithUserCollege(res.data.data, colleges);
      return dispatch(actions.getCoachReviewsSuccess(reviews));
    } catch (e) {
      dispatch(actions.getCoachReviewsFailure());
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }
  }
};

export const getCoachinHistory = data => {
  const result = [];
  const items = sortBy('year')(data);
  let prevYear;
  let prevKey;
  let item;

  items.forEach(season => {
    const key = `${season.name || season.team_name}-${season.title_0}-${season.title_1}`;
    if (prevKey !== key) {
      if (item) {
        item.end = prevYear;
      }

      item = {
        start: season.year,
        name: season.name || season.team_name,
        title0: season.title_0,
        title1: season.title_1
      };

      result.push(item);
    }

    prevKey = key;
    prevYear = season.year;
  });
  if (item) item.end = prevYear;

  return result.reverse();
};

export const coachingHistory = data => (dispatch) => dispatch(actions.getCoachHistory(getCoachinHistory(data)));

/*
ID:
coachName: "Diego Martinez"
coachedSeasons:
  id:
  teamName: "Diego Team"
  title0: "OOAA"
  year: "2011"
*/
export const updateCoach = (id, data, deletedSeasons) => {
  return async (dispatch) => {
    dispatch(actions.requestUpdateCoach())

    // console.log('update Coach', id, data, deletedSeasons);
    const dataTranslate = {
      display_name: data.coachName,
      url_slug: data.coachUrlSlug
    };

    try {
      const profilePromise = coachService().updateCoach(id, dataTranslate);
      const seasonsPromises = data.coachedSeasons.map(({ id: _id, year, teamId, title0, title1 }) => coachService().saveCoachedSeason(_id, {
        coach_refer: id,
        year: parseInt(year, 10),
        team_refer: teamId,
        title_0: title0,
        title_1: title1,
      }));

      const deletedPromises = deletedSeasons.map(({ id }) => coachService().deleteCoachedSeason(id));

      const { data: { data: coach } } = await profilePromise;
      await Promise.all(seasonsPromises);
      await Promise.all(deletedPromises);

      dispatch(actions.updateCoachSuccess(coach)); // This actually doesn't care
      dispatch(push('/admin/coaches'));
      toast('Coach updated!', { type: toast.TYPE.SUCCESS });

    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText);
        toast('There was an error updating the coach', { type: toast.TYPE.ERROR });
        return dispatch(actions.updateCoachFailure(e.response.statusText));
      }
    } finally {
      return;
    }
  }
}

export const createCoach = (data) => {
  return async (dispatch) => {
    dispatch(actions.requestCreateCoach())

    // console.log('create Coach', data);
    const dataTranslate = {
      display_name: data.coachName,
      url_slug: data.coachUrlSlug
    };

    try {
      const { data: { data: coach } } = await coachService().createCoach(dataTranslate);

      const seasonsPromises = data.coachedSeasons.map(({ year, teamId, title0, title1 }) => coachService().saveCoachedSeason(null, {
        coach_refer: coach.id,
        year: parseInt(year, 10),
        team_refer: teamId,
        title_0: title0,
        title_1: title1,
      }));

      coach.coached_seasons = await Promise.all(seasonsPromises);

      dispatch(actions.createCoachSuccess(coach)); // This actually doesn't care
      dispatch(push('/admin/coaches'));
      toast('Coach created!', { type: toast.TYPE.SUCCESS });
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
        toast('There was an error creating the coach', { type: toast.TYPE.ERROR });
        return dispatch(actions.createCoachFailure(e.response.statusText));
      }
    } finally {
      return;
    }
  }
};

export const saveCoach = (id, data, seasons) => {
  // Mark seasons that have been removed
  const deletedSeasons = differenceBy(seasons, filter(data.coachedSeasons, id => !id), 'id');

  return async (dispatch) => {
    if (id) dispatch(updateCoach(id, data, deletedSeasons));
    else dispatch(createCoach(data));
  }
}

export const visibleCoaches = (coaches, filter) => {
  if (filter && filter.values && filter.values.text) {
    return coaches.filter(coaches => {
      return coaches.display_name.toLowerCase().includes(filter.values.text.toLowerCase());
    });
  }
  return coaches;
}

export const submitCoachPhoto = () => {
  return async (dispatch, getState) => {
    // eslint-disable-next-line
    const { upload: { accepted: [picture], next }, coach: { coachProfile } } = getState();

    dispatch(upload.startUpload());
    try {
      const res = await coachService().uploadCoachPhoto(coachProfile.ID, picture, upload.uploadProgress);
      dispatch(updateCoachDefaultPhoto(res.data.data));
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    } finally {
      dispatch(upload.finishUpload());
      dispatch(upload.cleanDropState());
    }
  }
};

export const getTargetCoachesByContributionsIfEmpty = (targetID, contributionsArray) => {
  return async (dispatch, getState) => {
    const { coach } = getState();

    if (coach.isFetchingTargetCoaches || targetID === undefined || (Object.keys(coach.targetCoaches).length > 0 && coach.targetId === targetID))
      return;

    dispatch(actions.getTargetCoachesRequest(targetID));

    // Gets all the coaches IDs
    const ids =
      // eslint-disable-next-line
      contributionsArray.map((contribution) => {
        if (contribution.contributable_type === "coaches") {
          return contribution.contributable_id
        }
      }).filter((v, i, a) => a.indexOf(v) === i).filter(Boolean);

    try {
      const promiseCoaches = ids.map((id) => {
        return coachService().getCoachProfileByID(id);
      });

      const res = await Promise.all(promiseCoaches);

      const coachesResult = {};

      res.forEach((coach) => {
        if (coach.status === 200) {
          coachesResult[coach.data.data.ID] = coach.data.data;
        }
      });

      return dispatch(actions.getTargetCoachesSuccess({ coaches: coachesResult, targetId: targetID }));

    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401 || e.response.status === 404) {
        dispatch(actions.getTargetCoachesFailure('There was a problem fetching the requested coach'))
        console.log(e.response.status, e.response.statusText)
      } else {
        console.log(e.response.statusText)
      }
    }
  }
};
