import React from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import CollegeSmallCard from '../../../components/College/CollegeSmallCard';
import CollegeAbout from '../../../components/College/CollegeAbout';

import {
  CollegeSmallCardRow,
  CollegeSmallCardTop,
  CollegeSmallCardTitleSmall,
  CollegeSmallCardValue,
  CollegeCompositeRating,
  CollegeRanking,
  CollegeRankingPosition,
  CollegeRankingTotal,
  CollegeTableColumnLabel,
  CollegeTableColumnValue,
  CollegeTableColumnRanking,
  CollegeTable,
  CollegeTableItem,
  CollegeTableRow,
  OVplusIcon
} from '../StyledUI';

import AverageReview from '../../../components/reviews/AverageReview';

const CollegeStats = ({ header, college, funding, weather, web, enrollment, about, averageReview, push, ranks }) => {
  return (
    <div>
      <CollegeSmallCardRow>

        <CollegeSmallCard>
          <CollegeSmallCardTop>
            <CollegeSmallCardTitleSmall>
              Average Alumni Rating
            </CollegeSmallCardTitleSmall>
            <CollegeSmallCardValue>
              <AverageReview big averageReview={averageReview.average_rating} count={averageReview.count} />
            </CollegeSmallCardValue>
          </CollegeSmallCardTop>
        </CollegeSmallCard>

        <CollegeSmallCard>
          <CollegeSmallCardTop>
            <CollegeSmallCardTitleSmall>
              <OVplusIcon/>
              <span>OV Composite+ Academic Rating</span>
            </CollegeSmallCardTitleSmall>
            <CollegeSmallCardValue>
              <CollegeCompositeRating>{ranks[0] && ranks[0].data[1] ? ranks[0].data[1].value : "-"}</CollegeCompositeRating>
              <CollegeRanking onClick={() => push('/college-rankings')}>
                <CollegeRankingPosition>{header ? header.value : "-"}</CollegeRankingPosition>
                <CollegeRankingTotal> of {header ? header.total : "-"}</CollegeRankingTotal>
              </CollegeRanking>
            </CollegeSmallCardValue>
          </CollegeSmallCardTop>
        </CollegeSmallCard>

      </CollegeSmallCardRow>

      <CollegeAbout content={about[0] ? about[0].data[0].value : "-"} title={college.formal_name} college={college.name}/>

      <CollegeTable>
        {/* funding */}
        {funding[0] &&
        <CollegeTableItem>
          {funding[0].data.map((item, index)=>{
            return(
              <CollegeTableRow key={index}>
                <CollegeTableColumnLabel>{item.label}</CollegeTableColumnLabel>
                <CollegeTableColumnValue>{item.value}</CollegeTableColumnValue>
              </CollegeTableRow>
            )
          })}
        </CollegeTableItem>
        }

        {/* enrollment & weather*/}
        <CollegeTableItem>
          {enrollment[0] && enrollment[0].data.map((item, index)=>{
            const formattedValue = item.value
            return(
              <CollegeTableRow key={index}>
                <CollegeTableColumnLabel>{item.label}</CollegeTableColumnLabel>
                <CollegeTableColumnValue>
                  {formattedValue.toLocaleString('EN')}
                  <CollegeTableColumnRanking onClick={() => push('/largest-colleges')}>
                    <CollegeRankingPosition>{item.rank}</CollegeRankingPosition>
                    <CollegeRankingTotal> of {item.total}</CollegeRankingTotal>
                  </CollegeTableColumnRanking>
                </CollegeTableColumnValue>
                
              </CollegeTableRow>
            )
          })}
        

          {weather[0] && weather[0].data.map((item, index)=>{
            return(
              <CollegeTableRow key={index}>
                <CollegeTableColumnLabel>{item.label}</CollegeTableColumnLabel>
                <CollegeTableColumnValue>
                  {item.value}°F
                  <CollegeTableColumnRanking onClick={() => push('/warmest-colleges')}>
                    <CollegeRankingPosition>{item.rank}</CollegeRankingPosition>
                    <CollegeRankingTotal> of {item.total}</CollegeRankingTotal>
                  </CollegeTableColumnRanking>
                </CollegeTableColumnValue>
                
              </CollegeTableRow>
            )
          })}
        </CollegeTableItem>

        {/* website*/}
        <CollegeTableItem>
          {web[0] && web[0].data.map((item, index)=>{
            return(
              <CollegeTableRow key={index}>
                <CollegeTableColumnLabel>{item.label}</CollegeTableColumnLabel>
                <CollegeTableColumnValue>
                  <a target="_blank" href={item.value} rel="noopener noreferrer">
                    {item.label === "Twitter" && "View on Twitter"}
                    {item.label === "Facebook" && "View on Facebook"}
                    {item.label === "LinkedIn" && "View on Linkedin"}
                    {item.label === "Website" && item.value}
                  </a>
                </CollegeTableColumnValue>
              </CollegeTableRow>
            )
          })}
        </CollegeTableItem>

      </CollegeTable>
      
    </div>
  )
};

const mapDispatchToProps = dispatch => bindActionCreators({
  push
}, dispatch)

export default connect(null, mapDispatchToProps)(CollegeStats)
