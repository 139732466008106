
const size = {
  phoneMax: '599px',
  tabletPortrait: '600px',
  tabletLandscape: '992px',
  desktop: '1200px',
  wide: '1800px'
}

export const bp = {
  'phoneOnly': `(max-width: ${size.phoneMax})`,
  'tabletPortUp': `(min-width: ${size.tabletPortrait})`,
  'tabletLandUp': `(min-width: ${size.tabletLandscape})`,
  'desktopUp': `(min-width: ${size.desktop})`,
  'wideUp': `(min-width: ${size.wide})`
};

export default { bp, size }

