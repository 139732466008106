import { color, typo } from '../../styles';
import styled from 'styled-components';
import { bp } from '../../styles';

export const CoachHeaderBox = styled.div`
  grid-column: span 12;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(${color.ovBlack}, 0.16);
  background-color: ${color.ovWhite};
  overflow: hidden;
  text-align: center;
  position: relative;
`;

export const CoachHeaderBkg = styled.div`
  width: 100%;
  height: 175px;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-image: ${props => `url("${props.bgUrl}")`};
`;

export const CoachHeaderData = styled.div`
  text-align: center; 
  margin-top: 55px;
  padding: 0 10px 5px 10px;
  @media ${bp.phoneOnly} {
    text-align: left;
    padding: 0 10px 5px 10px;
  }
`;

export const CoachHeaderProfile = styled.div`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  z-index: 9;
  top: 35px;
  width: 181px;
  height: 181px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${props => `url("${props.bgUrl}")`};
  margin: 0 auto;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
`;

export const CoachHeaderName = styled.h1`
  font-size: 40px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  line-height: 0.53;
  letter-spacing: normal;
  text-align: center;
  font-family: ${typo.ovFontFeature};
  color: ${color.ovBlack};
  margin: 15px 0 0 0;
  @media ${bp.phoneOnly} {
    text-align: left;
  }
`;

export const CoachHeaderTitle = styled.h3`
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  font-family: ${typo.ovFontSerif};
  color: ${color.ovBlack};
  margin-bottom: 20px;
  @media ${bp.phoneOnly} {
    text-align: left;
  }
`;

export const CoachHeaderReviews = styled.p`
  font-size: 15px;
  font-weight: 600;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: ${typo.ovFontFeature};
  color: ${color.ovGrey};
  @media ${bp.phoneOnly} {
    text-align: left;
  }
`;

export const ClaimCoachProfileContainer = styled.div`
  display: ${({ mobileOnly }) => mobileOnly ? 'flex' : 'none'};
  justify-content: flex-start;
  margin: 0 0 20px;
  

  @media ${bp.tabletLandUp} {
    display: ${({ mobileOnly }) => !mobileOnly ? 'flex' : 'none'};
    margin-top: 4.7px;
    justify-content: flex-end;
    width: 100%;
  }
`;

export const ClaimCoachProfileText = styled.div`
  color: #000000;
  cursor: pointer;
  font-family: Molde;
  font-size: 18px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  height: 22px;
  letter-spacing: normal;
  line-height: 1.22;
  padding: 3.6px 10px 0.4px;
  text-align: center;
  text-decoration: underline;
  width: fit-content;
`;

export const Separator = styled.div`
  border-left: 1px solid #000000;
  height: 18px;
  margin: 4px 0 0;
  width: 1px;
`;

