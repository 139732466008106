import React, { Fragment } from 'react';

import{
	Body,
	Title,
	FeaturedImage,
	Date,
} from './Styled'

import Categories from './Categories';
import Author from './Author';
import Interpolate from '../Interpolate';

const Post = ({
	title,
	publishedDate,
	featuredImage,
	body,
	categories,
	author,
}) =>{
	return (
		<Fragment>
			<FeaturedImage>
				<img src={featuredImage} alt={title} />
			</FeaturedImage>
			<Title>{title}</Title>
			<Date>{publishedDate}</Date>
			<Body><Interpolate body={body} /></Body>
			<Categories categories={categories} />
			<Author 
				{...author}
			/>
		</Fragment>
	)
}

export default Post;