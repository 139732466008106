import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
// Styles stuff

// Components
import Spinner from 'react-spinkit';
import { PageContainer } from '../../components';
import LocationCard from '../../components/Locations/LocationsCard'
import ModalFixed from '../../components/modal/ModalFixed'

import {
  PageContent,
  PageContentButton,
  TitleContainer,
  AboutHeading,
  AboutHr,
  SearchResultContainer,
  AddCoachButton,
  StyledPlus,
  AddCoachButtonLabel,
  LoadMoreButtonContainer,
  LoadMoreButton,
  LoadMoreButtonLabel,
  ModalStyled,
  AttractionCardTitleBox,
  AttractionCardTitile,
  AttractionCardTextBox,
  AttractionCardText,
  OVButtonRight,
  OVButtonleft,
} from './Styled';

const AdminAttractions = ({
  match,
  isAuthenticated,
  isFetchingCoaches,
  requestLogin,
  attractions,
  role,
  push,
  getAllAttractionsIfEmpty,
  getAttractionsTags,
  getUserIfEmpty,
  deleteAttraction
}) =>{
  if (!isAuthenticated){
    return requestLogin(match.url)
  }
  useEffect(() => {
    if (!role) {
      getUserIfEmpty()
    }
    if (Object.keys(attractions) < 1 ) {
      getAllAttractionsIfEmpty();
      getAttractionsTags();
    }
  })
  const attractionsList = Object.keys(attractions).map(item => attractions[item]);
  const [deleteShowModal, toggleDeleteModal] = useState(false);
  const [attractionById, setAttractionById] = useState('')

  const handleAttractionById = attraction => {
    setAttractionById(attraction);
    toggleDeleteModal(!deleteShowModal);
  }

  const handleDelete = () => {
    deleteAttraction(attractionById);
    toggleDeleteModal(!deleteShowModal);
  }
  return (
    <div>
      { isFetchingCoaches &&
        <PageContainer>
          <PageContent>
            <Spinner fadeIn="none" name="cube-grid" />
          </PageContent>
        </PageContainer>
      }
      { !isFetchingCoaches &&
        <PageContainer>
          <PageContent>
            <TitleContainer>
              <AboutHeading>Search results</AboutHeading>
              <AboutHr />
            </TitleContainer>
            <div>
              <SearchResultContainer>
                { attractionsList && attractionsList.map((attraction, i)=>{
                  const {title, about, average_review, ID} = attraction;
                  return ( 
                    <LocationCard
                      role={role}
                      key={i}
                      title={title}
                      average_review={average_review}
                      about={about}
                      onClick={() => push('/admin/attractions/' + ID)}
                      onEdit={() => push('/admin/attractions/' + ID)}
                      onDelete={() => handleAttractionById(attraction)}
                    />
                  )
                })}

              <LoadMoreButtonContainer>
                  <LoadMoreButton>
                    <LoadMoreButtonLabel>Load More</LoadMoreButtonLabel>
                  </LoadMoreButton>
                </LoadMoreButtonContainer>

              </SearchResultContainer>
            </div>
              {
                deleteShowModal && (
                  <ModalFixed>
                    <ModalStyled>
                      <AttractionCardTitleBox>
                        <AttractionCardTitile> Delete permanently</AttractionCardTitile>
                      </AttractionCardTitleBox>

                      <AttractionCardTextBox>
                        <AttractionCardTextBox>
                          <AttractionCardText>This attraction will be permanently deleted.</AttractionCardText>
                          <AttractionCardText>This cannot be undone.</AttractionCardText>
                        </AttractionCardTextBox>
                      </AttractionCardTextBox>
                      <PageContentButton>
                        <OVButtonRight onClick={() => toggleDeleteModal(!deleteShowModal)}>Cancel</OVButtonRight>
                        <OVButtonleft onClick={() => handleDelete()}>Delete</OVButtonleft>
                      </PageContentButton>
                    </ModalStyled>
                  </ModalFixed>
                )
              }
            <AddCoachButton onClick={() => push('/admin/attractions/new')}>
              <AddCoachButtonLabel>Add Attraction <StyledPlus/></AddCoachButtonLabel>
            </AddCoachButton>
          </PageContent>
        </PageContainer>
      }
    </div>
  )
};
AdminAttractions.propTypes = {
  match: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  isFetchingCoaches: PropTypes.func,
  requestLogin: PropTypes.func,
  attractions: PropTypes.object,
  push: PropTypes.func,
  getAllAttractionsIfEmpty: PropTypes.func,
  getAttractionsTags: PropTypes.func,
}

export default AdminAttractions;