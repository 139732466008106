import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import CoachReviewsItem from '../../components/reviews/CoachItem';
import CoachReviewsFilters from '../../components/reviews/CoachFilters';
import { pageSelector } from "../../modules/college/selectors";
import { bindActionCreators } from "redux";
import { requestLogin } from "../../modules/user";
import { connect } from "react-redux";

import {
  ListContainerCoaches,
  PageContent,
  LoadMoreButtonContainer,
  LoadMoreButton,
  LoadMoreButtonLabel,
  ReviewsFiltersHeader,
  ReviewsFiltersHeaderTitle,
  ReviewsFiltersHeaderButton
} from './StyledList';

const applyFilters = list => filter => list && list.reduce((memo, item) => {
  let matches = [];
  let hasAnyFilter = [];

  if (filter['stars']) {
    hasAnyFilter.push(filter['stars']['1'] || filter['stars']['2'] || filter['stars']['3'] || filter['stars']['4'] || filter['stars']['5']);

    let gmatch = false;
    gmatch |= filter['stars']['1'] && item.reviews[0].rating >= 0 && item.reviews[0].rating <= 1;
    gmatch |= filter['stars']['2'] && item.reviews[0].rating > 1 && item.reviews[0].rating <= 2;
    gmatch |= filter['stars']['3'] && item.reviews[0].rating > 2 && item.reviews[0].rating <= 3;
    gmatch |= filter['stars']['4'] && item.reviews[0].rating > 3 && item.reviews[0].rating <= 4;
    gmatch |= filter['stars']['5'] && item.reviews[0].rating > 4 && item.reviews[0].rating <= 5;

    if (gmatch) matches.push(gmatch);
  }

  if (filter['reviewer_status']) {
    hasAnyFilter.push(filter['reviewer_status']['current'] || filter['reviewer_status']['alumni']);
    let gmatch = false;

    gmatch |= filter['reviewer_status']['current'] && item.user.is_student;
    gmatch |= filter['reviewer_status']['alumni'] && item.user.is_alumni;

    if (gmatch) matches.push(gmatch);
  }

  hasAnyFilter.push(filter['verified']);
  if (filter['verified'] && item.user.is_verified) {
    matches.push(true);
  }

  const hasAnyFilterTrueCount = hasAnyFilter.filter(item => !!item).length;
  if (!hasAnyFilterTrueCount) return list;

  if (hasAnyFilterTrueCount === matches.length) {
    memo.push(item)
  }

  return memo;
}, []);

const CoachReviewsList = ({
  reviews,
  isFetchingReviews,
  isAuthenticated,
  currentUserRole,
  onUserClick,
  paginate,
  count,
  requestLogin,
  match
}) =>{
  let [filters, setFilters] = useState({});

  const [page, setPage] = useState(1);
  let filtered = applyFilters(reviews)(filters);
  const reviewsPaginated = pageSelector(filtered, paginate, true)(page);

  if (!isAuthenticated) {
    setFilters = function () {
      return requestLogin(match.url);
    }
  }

  const initialValue = {
    stars: {},
    reviewer_status: {},
    filter: {
      stars: {},
      reviewer_status: {}
    }
  };
  return (
    <ListContainerCoaches>
      <ReviewsFiltersHeader>
        <ReviewsFiltersHeaderTitle>Review Filters</ReviewsFiltersHeaderTitle>
        <ReviewsFiltersHeaderButton onClick={()=>{setFilters(initialValue)}}>Clear All</ReviewsFiltersHeaderButton>
      </ReviewsFiltersHeader>
      <CoachReviewsFilters
        reviewsFilter={filters}
        setReviewsFilter={setFilters}
        onChange={() => { setPage(1); }}
        count={reviewsPaginated ? reviewsPaginated.length : 0}
        countTotal={filtered < count ? filtered.length : count}
        isAuthenticated={isAuthenticated}
      />
      { !isFetchingReviews && reviews.length && <PageContent>
        { reviewsPaginated.map((review,i) => {
          return (
            <CoachReviewsItem
              key={"reviewitem-"+i}
              id={review.ID}
              user={review.user}
              currentUserRole={currentUserRole}
              reviews={review.reviews}
              is_anonymous={review.is_anonymous}
              title={review.title}
              createdAt={review.created_at}
              onUserClick={id => onUserClick(id)}
            />
          )
        })}
        {!isAuthenticated &&
          <LoadMoreButtonContainer>
              <LoadMoreButton onClick={() => requestLogin(match.url)}>
                <LoadMoreButtonLabel>Load More</LoadMoreButtonLabel>
              </LoadMoreButton>
            </LoadMoreButtonContainer>
        }
        {isAuthenticated && reviewsPaginated && reviewsPaginated.length < count && reviewsPaginated.length !== 0 && (
          <LoadMoreButtonContainer>
            <LoadMoreButton onClick={() => setPage(page + 1)}>
              <LoadMoreButtonLabel>Load More</LoadMoreButtonLabel>
            </LoadMoreButton>
          </LoadMoreButtonContainer>
        )}
      </PageContent> }
      { isFetchingReviews  && <PageContent>
        <Spinner fadeIn="none" name="cube-grid" />
      </PageContent> }
    </ListContainerCoaches>
  )
};

CoachReviewsList.propTypes = {
  reviews: PropTypes.array.isRequired,
  isFetchingReviews: PropTypes.bool.isRequired,
  onUserClick: PropTypes.func.isRequired,
  currentUserRole: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  requestLogin
}, dispatch);

export default connect(null, mapDispatchToProps)(CoachReviewsList)
