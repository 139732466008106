import styled from 'styled-components';
import { color, bp, typo } from '../../styles'
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Search as SearchIcon } from "styled-icons/material/Search";
import { Heading } from 'rebass';
import Scroll from 'react-scroll';
var Link = Scroll.Link;

export const EighthPartPageContent = styled.div`
  grid-column: span 12;
  grid-row-start: 1;
  @media ${bp.phoneOnly} {
    grid-row-start: 1;
  }
  @media ${bp.tabletPortUp} {
    grid-row-start: 1;
  }
  @media ${bp.tabletLandUp} {
    grid-column: span 7;
    grid-row-start: 1;
    grid-column-start: 6;
    grid-column-end: 13;
  }
  @media ${bp.desktopUp} {
    grid-column-start: 5;
    grid-column-end: 13;
  }

  @media ${bp.wideUp} {
    grid-column-start: 5;
    grid-column-end: 13;
  }
`;

export const QuarterPartPageContent = styled.div`
  display: flex;
  flex-direction: column;
  
  grid-column: span 12;
  @media ${bp.phoneOnly} {
    grid-row-start: 2;
  }
  @media ${bp.tabletPortUp} {
    grid-row-start: 2;
  }
  @media ${bp.tabletLandUp} {
    grid-column: span 5;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 6;
  }
  @media ${bp.desktopUp} {
    grid-column-start: 1;
    grid-column-end: 5;
  }

  @media ${bp.wideUp} {
    grid-column-start: 1;
    grid-column-end: 5;
  }
`;

export const PageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 0px;
  padding-bottom: 0px;

  @media ${bp.phoneOnly} {
    grid-template-columns: auto;
    margin-top: -42px;
    height: auto;
    position: relative;
  }

  @media ${bp.tabletPortUp} {
    width:  100%;
    margin: -42px auto 0rem auto;
    height: auto;
  }

  @media ${bp.tabletLandUp} {
    width: 100%;
    margin: -42px auto 0rem auto;
    height: calc(100vh - 289px);
  }

  @media ${bp.desktopUp} {
    width:  100%;
    margin: -42px auto 0rem auto;
  }

  @media ${bp.wideUp} {
    width:  100%;
    margin: -42px auto 0rem auto;
  }
  
`;

export const ListContainer = styled.div`
  grid-column: span 6;
  @media ${bp.phoneOnly} {
    padding: 0 2.2rem;
    grid-column: 1;
    height: auto;
    margin-top: 10px;
  }
  @media ${bp.tabletPortUp} {
    grid-column: 1;
    height: auto;
    margin-top: 10px;
  }
  @media ${bp.tabletLandUp} {
    padding: 20px;
    height: calc(100vh - 289px);
    margin-top: 0px;
  }
 
`;

export const MapContainer = styled.div`
  grid-column: span 6;
  @media ${bp.phoneOnly} {
    visibility: ${props => props.openMap ? "visible" : "hidden"};
    grid-column: 1;
    grid-row: 1;
    position: absolute;
    z-index: 987;
    width: 100%;
    height: calc(100vh - 120px);
  }
`;

export const LocationsListContainer = styled.div`
  flex-grow: 1;
  @media ${bp.phoneOnly} {
    display: ${props => props.openMap ? "none" : "block"};
  }
  @media ${bp.tabletLandUp} {
    height: 10vh;
  }
`;

export const ScrollbarContainer = styled(PerfectScrollbar)`  
  @media ${bp.phoneOnly} {
    overflow-y: visible;
  }
  @media ${bp.tabletPortUp} {
    overflow-y: visible;
    padding: 0 20px 20px 20px;
  }
  @media ${bp.tabletLandUp} {
    overflow-y: hidden;
  }
  .ps__rail-y{
    opacity: 1;
    .ps__thumb-y{
      background-color: ${color.ovGreyDark};
    }
  }
`;

export const SearchContainer = styled.div`
  margin-top: 15px;
  @media ${bp.phoneOnly} {
    padding: 0 10px;
    display: ${props => props.openMap ? "none" : "block"};
  }
  @media ${bp.tabletPortUp} {
    padding: 0 20px;
  }
`;

export const InputContainer = styled.div`
  flex-grow: 1;
  margin-right: 20px;
  margin-bottom: -22px;
`;

export const StyledSearchIcon = styled(SearchIcon)`
  position: relative;
  top: -26px;
  left: 6px;
  height: 22px;
  width: 22px;
  color: ${color.ovBlue};
`;

export let StyledInput = styled.input`
  display: inline-block;
  width: 100%;
  height: 32px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.5px ${color.ovGreyLight};
  background-color: ${color.ovWhite};
  padding-left: 32px;
  padding-top: 3px;
  outline: none;
  font-size: 16px;
  &::placeholder{
    font-family: ${typo.ovFontFeature};
    font-size: 18px;
    font-weight: 300;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #95989a;
  }
`;

export const ExploreLocationsFilter = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

export const FiltersBoxClearButton = styled.button`
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.5px #707070;
  background-color: #ffffff;
  font-family: ${typo.ovFontFeature};
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  font-size: 20px;
  text-align: center;
  color: #777777;
  cursor: pointer;
`;

export const LocationsListTotal = styled.div`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
  font-size: 25px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  span{
    color: ${color.ovBlue};
  }
  @media ${bp.phoneOnly} {
    font-size: 16px;
  }
`;

export const MapButtonContainer = styled.div`
  display: none; 
  @media ${bp.phoneOnly} {
    display: block;
    padding: 0 10px;
    margin-top: 15px;
    margin-bottom: -15px;
    position: ${props => props.openMap ? "absolute" : "relative"};
    top: ${props => props.openMap ? "0" : "auto"};
    z-index: 988;
  }
`;

export const OpenMapButton = styled.span`
  display: inline-block;
  width: 100%;
  flex: ${({ isMore }) => isMore ? '0' : '1'};
  height: 34px;
  border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.5px ${color.ovGreyDarker};
  background-color: ${color.ovWhite};
  font-family: ${typo.ovFontFeature};
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: condensed;
  line-height: 32px;
  letter-spacing: normal;
  text-align: center;
  white-space: nowrap;
  color: ${color.ovGreyDarker};
  padding: 0 13px;
  cursor: pointer;
  transition: all .1s;
  margin: 0 15px 11px 0;
  &:hover, &.selected{
		background-color: ${color.ovBlue};
		color: ${color.ovWhite};
		transition: all .1s;
  }

  @media ${bp.phoneOnly} {
    font-size: 18px;

    margin: 0 1rem 11px 0;
    padding: 0 1rem;
  }
`;

export const ModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 50vh;
  box-shadow: 0 3px 6px 0 rgba(0,0,0, 0.16);
  border: solid 1px #777777;
  background-color: ${color.ovWhite}; 
  padding: 7px 16px;
  cursor: pointer;
`;

export const AttractionCardTitile = styled(Heading)`
	font-family: ${typo.ovFontFeature};
  font-size: 40px;
  font-weight: 300;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: ${color.ovGreyDarker};
`;

export const AttractionCardText = styled.div`
  height: 8vh;
	font-family: ${typo.ovFontFeature};
  font-size: 25px;
  font-weight: 300;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovGreyDarker};
`;

export const AttractionCardTitleBox = styled.div`
  border-bottom: 1px solid ${color.ovGreyLight};
  padding: 15px 23px;
`;

export const AttractionCardTextBox = styled.div`
  border-bottom: 1px solid ${color.ovGreyLight};
`;

export const OVButton = styled(Link)`
  border: 3px solid ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 16px;
  background: ${color.ovBlue};
  border-radius: 0px;
  color: ${color.ovWhite};
  cursor: pointer;
  padding: 2px 40px;
  margin: 0px 10px;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.22;
  letter-spacing: 1px;
  &:hover, &:focus {
    text-decoration: none;
    background: ${color.ovBlueLight};
    border-color: ${color.ovBlueLight};
  }
`;

export const OVButtonRight = styled(OVButton)`
  float: right;
`;

export const OVButtonleft = styled(OVButton)`
  float: right;
  background: ${color.ovRedLight}
  border: 3px solid ${color.ovRedLight};
  &:hover, &:focus {
    text-decoration: none;
    background: ${color.ovRed};
    border-color: ${color.ovRed};
  }
`;
export const PageContent = styled.div`
  grid-column: span 12;
`;

export const CenterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
