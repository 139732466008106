import delay from 'lodash/delay';
import { push, goBack } from 'connected-react-router';

import { userByIdSelector } from '../../modules/user/selectors';
import { authService, userService, contentService } from '../../services';
import * as actions from './actions';
import * as upload from '../upload';
import { forgotPasswordSuccess } from "./actions";
import { forgotPasswordValidateSuccess } from "./actions";
import { forgotPasswordResetSuccess } from "./actions";

export const submitRegister = (data) => {
  return async dispatch => {
    dispatch(actions.requestRegister());

    try {
      const res = await authService().register(data.role, data.email, data.password, data.firstName, data.lastName, data.birthday.value, data.notificationsEnabled);

      if (res.status === 201) {
        // success in registering and redirect to login
        dispatch(actions.registerSuccess());

        dispatch(actions.loginRequest());

        try {
          const res2 = await authService().login(data.email, data.password);

          if (res2.status === 200) {
            dispatch(loginDuringRegistrationSuccess(res2.data.token));
            return dispatch(push(`/signup-picture`))
          }
        } catch (res) {
          // Display error message
          return dispatch(actions.loginFailure('There was a problem verifying these credentials'));
        }
      }

    } catch (e) {
      return dispatch(actions.registerFailure('That email is already in use'));
    }
  }
};

const loginSuccessThunk = (token) => {
  return async (dispatch, getState) => {
    const { user: { loginRedirect } } = getState();

    // store in session storage for over page reloads
    localStorage.setItem('ovSessToken', token);

    dispatch(getUser());

    dispatch(actions.loginSuccess());
    dispatch(actions.resetLoginRedirect());

    return dispatch(loginRedirect ? push(loginRedirect) : goBack());
  }
};

const loginDuringRegistrationSuccess = (token) => {
  return async (dispatch) => {

    // store in session storage for over page reloads
    localStorage.setItem('ovSessToken', token);

    dispatch(getUser());

    return dispatch(actions.loginSuccess());
  }
};

export const submitLogin = (data) => {
  return async dispatch => {
    dispatch(actions.loginRequest());

    try {
      const res = await authService().login(data.email, data.password);

      if (res.status === 200) {
        return dispatch(loginSuccessThunk(res.data.token));
      }
    } catch (res) {
      // Display error message
      return dispatch(actions.loginFailure('There was a problem verifying these credentials'));
    }
  }
};

export const submitLogout = () => {
  return (dispatch, getState) => {
    const { user: { loginBackRedirect } } = getState();

    // clear session storage
    localStorage.removeItem('ovSessToken');

    dispatch(actions.logoutSubmitted());

    return dispatch(loginBackRedirect ? push(loginBackRedirect) : push('/'));
  }
};

export const requestLogin = (redirect, backRedirect) => {
  return dispatch => {
    dispatch(actions.requestLoginRedirect(redirect, backRedirect));
    dispatch(push('/login'));
  }
};

export const goHomeWithResetLoginRedirect = () => {
  return (dispatch, getState) => {
    const { user: { loginRedirect, loginBackRedirect } } = getState();
    dispatch(actions.resetLoginRedirect());
    dispatch(loginBackRedirect ? push(loginBackRedirect) : loginRedirect ? push(loginRedirect) : goBack());
  }
};

export const redirectWithResetForgotState = (url) => {
  return (dispatch) => {
    dispatch(actions.forgotPasswordStateReset());
    if (url === '/login') {
      dispatch(requestLogin('/','/'));
    }
    dispatch(url ? push(url) : goBack());
  }
};

export const submitForgotPassword = (data) => {
  return async dispatch => {
    dispatch(actions.forgotPasswordRequest());

    try {
      const res = await authService().forgotPassword(data.email);

      if (res.status === 200) {
        return dispatch(forgotPasswordSuccess());
      }
    } catch (e) {
      // Display error message
      return dispatch(actions.forgotPasswordFailure('There was a problem verifying these credentials'));
    }
  }
};

export const submitForgotPasswordValidate = (token) => {
  return async dispatch => {
    dispatch(actions.forgotPasswordValidateRequest());

    try {
      const res = await authService().forgotPasswordValidateToken(token);

      if (res.status === 200) {
        return dispatch(forgotPasswordValidateSuccess());
      }
    } catch (e) {
      switch (e.response.status) {
        case 401:
          return dispatch(actions.forgotPasswordValidateFailure('This token does not exists or it has been expired'));
        default:
          return dispatch(actions.forgotPasswordValidateFailure('We have an error. Please contact the support'));
      }
    }
  }
};

export const submitForgotPasswordReset = (data) => {
  return async dispatch => {
    dispatch(actions.forgotPasswordResetRequest());

    if (data.password !== data.confirm_password) {
      return dispatch(actions.forgotPasswordResetFailure('Passwords is not equal'))
    }

    try {
      const res = await authService().forgotPasswordResetPassword(data.password, data.token);

      if (res.status === 200) {
        return dispatch(forgotPasswordResetSuccess('You can login now with the new password.'));
      }
    } catch (e) {
      switch (e.response.status) {
        case 401:
          return dispatch(actions.forgotPasswordResetFailure('This token does not exists or it has been expired'));
        default:
          return dispatch(actions.forgotPasswordValidateFailure('We have an error. Please contact the support'));
      }
    }
  }
};

export const getUser = () => {
  return async dispatch => {
    dispatch(actions.requestGetUser());

    try {
      const res = await userService().getUser();

      if (res.status === 200) {
        dispatch(actions.getUserSuccess(res.data.data));
        dispatch(getProfile());
      }
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401 || e.response.status === 404) {
        dispatch(actions.getUserFailure('There was a problem fetching your User'))
        return dispatch(submitLogout());
      } else{
        console.log(e.response.statusText)
      }
    }
  }
};

export const getUserIfEmpty = () => {
  return (dispatch, getState) => {
    const { user } = getState();
    return user.isAuthenticated && !user.isFetching && !user.user.email && dispatch(getUser())
  }
};

export const setProfile = (data) => {
  return async dispatch => {
    return dispatch(actions.getProfileSuccess(data));
  }
};

export const getProfile = () => {
  return async dispatch => {
    dispatch(actions.requestGetProfile())

    try {
      const res = await userService().getUserProfile();

      if (res.status === 200) {
        return dispatch(actions.getProfileSuccess(res.data.data));
      }
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401 || e.response.status === 404) {
        dispatch(actions.getProfileFailure('There was a problem fetching your profile'))
        return dispatch(submitLogout());
      } else{
        console.log(e.response.statusText)
      }
    }
  }
};

export const getContributions = () => {
  return async dispatch => {
    dispatch(actions.requestGetUserContributions());

    try {
      const reviews = await userService().getUserReviews();
      const contents = await userService().getUserContents();

      let contributionsArray = [];

      if (reviews.status === 200) contributionsArray = contributionsArray.concat(reviews.data.data);
      if (contents.status === 200) contributionsArray = contributionsArray.concat(contents.data.data);

      // sort the array descending
      contributionsArray = contributionsArray.sort((a,b) => new Date(b.created_at) - new Date(a.created_at));

      return dispatch(actions.getUserContributionsSuccess(contributionsArray));
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401 || e.response.status === 404) {
        dispatch(actions.getUserContributionsFailure('There was a problem fetching your profile'));
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }
  }
};

export const getContributionsIfEmpty = () => {
  return async (dispatch, getState) => {
    const { user } = getState();
    if(user.isAuthenticated && user.contributions === false){
      return dispatch(getContributions())
    }
  }
};

export const getProfileIfEmpty = () => {
  return (dispatch, getState) => {
    const { user } = getState();
    if(user.isAuthenticated && user.profile && !user.profile.ID){
      return dispatch(getProfile())
    }
  }
};

export const editUser = (data) => {
  return async (dispatch, getState) => {
    dispatch(actions.requestEditUser());

    const emailUpdated = getState().user.user.email !== data.email

    const dataFormating = {
      first_name: data.first_name,
      last_name: data.last_name,
      display_name: data.display_name,
      email: data.email
    };

    try {
      const res = await userService().editUser(dataFormating);
      // Force logout if email is updated
      if (emailUpdated) {
        dispatch(actions.editUserSuccess(res.data.data));
        return dispatch(submitLogout());
      } else {
        return dispatch(actions.editUserSuccess(res.data.data));
      }
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401 || e.response.status === 404) {
        dispatch(actions.editUserFailure('There was a problem updating your profile'))
        return dispatch(submitLogout());
      } else{
        console.log(e.response.statusText)
      }
    }
  }
};

export const editProfile = (data) => {
  return async (dispatch) => {
    dispatch(actions.requestEditProfile())

    const dataFormating = {
      about_me: data.about_me,
      affiliation: data.affiliation && [data.affiliation.value],
      is_ticket_holder: data.is_ticket_holder,
      is_donor: data.is_donor
    };

    try {
      const res = await userService().editUserProfile(dataFormating);
      return dispatch(actions.editProfileSuccess(res.data.data));
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401) {
        dispatch(actions.editProfileFailure('There was a problem updating your profile'))
        return dispatch(submitLogout());
      } else{
        console.log(e.response.statusText)
      }
    }
  }
};

export const submitUserAvatar = () => {
  return async (dispatch, getState) => {
    // dispatch(requestNewContribution())

    const { upload: { accepted: [picture], next } } = getState();

    dispatch(upload.startUpload());
    try {
      const { data: { data: [ content ] } } = await contentService().uploadSingleFile(picture, upload.uploadProgress);
      // associate content upload as user avatar
      // eslint-disable-next-line
      const res = await userService().setUserAvatar(content.ID);

      // Clear next path
      if (next ) {
        dispatch(upload.setNextPath(null));
        dispatch(actions.requestGetUser());
        delay(() => dispatch(getUser()), 1000 );
      }

    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401) {
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }
  }
};

export const toggleEditUserPicture = () => {
  return (dispatch) => {
    dispatch(upload.setNextPath('/profile'));
    dispatch(push('/profile-picture'));
  }
};

// Requires Admin privileges
export const listUsers = () => {
  return async dispatch => {
    dispatch(actions.requestListUsers())

    try {
      const res = await userService().listUsers();

      if (res.status === 200) {
        return dispatch(actions.listUsersSuccess(res.data.data));
      }

    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401 || e.response.status === 404) {
        dispatch(actions.listUsersFailure('There was a problem fetching your User'))
        return dispatch(submitLogout());
      } else if (e.response.status === 403 ) {
        console.log('No admin rights', e.response);
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }
  }
};

export const toggleUserVerified = (ID) => {
  return async (dispatch, getState) => {

    // This is going to do an optimistic update
    dispatch(actions.requestToggleUserVerified(ID));

    try {
      const user = userByIdSelector(ID)(getState());
      const res = await userService().setUserVerified(ID, user.is_verified);

      if (res.status === 200) {
        return dispatch(actions.toggleUserVerifiedSuccess(res.data.data));
      }

    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401 || e.response.status === 404) {
        dispatch(actions.toggleUserVerifiedFailure('There was a problem virifying User', ID));
        return dispatch(submitLogout());
      } else if (e.response.status === 403) {
        console.log('No admin rights', e.response);
        return dispatch(submitLogout());
      } else {
        console.log(e.response.statusText)
      }
    }
  }
};

export const updateTopSchools = (list) => {
  const sorted = list.map(({ ID: college_refer }, order) => ({
    college_refer,
    order,
  }));

  return async dispatch => {
    dispatch(actions.requestUpdateTopSchools(sorted))
    try {
      const res = await userService().updateProfileTopSchools({ top_schools: sorted });
      if (res.status === 200) {
        return dispatch(actions.updateTopSchoolsSuccess(res.data.data));
      }
    } catch (e) {
      // log user out if unauthorized
      if (e.response.status === 401 || e.response.status === 404) {
        return dispatch(submitLogout());
      } else {
        dispatch(actions.updateTopSchoolsFailure('There was a problem fetching your profile', e.response.statusText));
        console.log(e.response.statusText)
      }
    }
  }
};

export const updateUploadPhotoAgreement = () => {
  return async (dispatch, getState) => {
    return dispatch(actions.setUploadPhotoAgreement())
  }
};
