import React from 'react'
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { color, typo } from '../../styles';
import Select from 'react-select/lib/Async';

import places from '../../services/map';

// Styled component based on styledSelect... but this is ASYNC
const StyledSelect = styled(Select)`
  height: 59px;
  margin-bottom: 17px;
  .react-select__control {
    width: 100%;
    height: 59px;
    border: solid 1px ${color.ovBlue};
    padding: 0 10px 0 15px;
    border-radius: 10px;
    background-color: ${color.ovWhite};
    font-family: ${typo.ovFontFeature};
    font-size: 25px;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: ${color.ovBlue};
    outline: none;
    margin-bottom: 23px;
  }

  .react-select__indicator-separator{
    display: none;
  }

  .react-select__value-container {
    display: block;
    width: 100%;
    padding-left: 10px;
    background-color: ${color.ovWhite};
    padding-top: 0;
    padding-bottom: 0;
    height: 57px;
    position: static;
  }

  .react-select__single-value {
    color: ${color.ovBlue};
    margin-left: 0;
    margin-right: 0;
    max-width: calc(100% - 85px);
  }

  .react-select__indicator {
    color: ${color.ovBlue};
  }

  .react-select__menu {
    display: inline-block;
    border-radius: 10px;
    border: solid 1px ${color.ovBlue};
    width: 100%;
    top: 51px;
    left: 0;
    background-color: ${color.ovWhite};
  }

  .react-select__option {
    font-family: ${typo.ovFontFeature};
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
  }
`;

const PlacesAutocomplete = props => {
  let { value: { value }, onBlur, ...rest } = props; // Removed onBlur since it was cleaning the selection...
  value = value && (typeof value === 'string')
    ? {value, label: value}
    : value;
  const options = value && [value];
  return (
    <StyledSelect
      key={`PLACES-SELECT-${rest.name}`}
      className="react-select-container"
      classNamePrefix="react-select"

      {...rest}
      value={value}
      cacheOptions
      defaultOptions={options}

      loadOptions={async (query) => {
        // This could return a promise
        const results = await places.query(query);
        return results;
      }}

      onChange={selected => {
        props.onChange && props.onChange(selected.value);
      }}
    />
  )
};

PlacesAutocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }), 
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      coordinates: PropTypes.array,
    }),
    PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
  ])
}
export default PlacesAutocomplete;