import React from 'react';
import styled from 'styled-components';
import { color, typo} from '../../styles';

let StyledInputContainer = styled.input`
  display: inline-block;
  width: 100%;
  height: 59px;
  border: solid 1px ${color.ovBlue};
  padding: 15px 0 15px 25px;
  border-radius: 10px;
  background-color: ${color.ovWhite};
  font-family: ${typo.ovFontFeature};
  font-size: 25px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlue};
  outline: none;
  margin-bottom: 22px;
  &::placeholder{
    font-family: ${typo.ovFontFeature};
    font-size: 25px;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: ${color.ovGrey};
  }
  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button{
    -webkit-appearance: none; 
    margin: 0;
  }
`;

const StyledInput = (props) => {
  const {
    innerRef,
    children,
    input,
    placeholder,
    type,
    onChange,
    onClick,
    label,
    ...rest
  } = props;
  return (
    <StyledInputContainer innerRef={innerRef} {...input} {...rest} placeholder={placeholder} type={type} label={label} onClick={onClick} onChange={onChange}>
      {children}
    </StyledInputContainer>
  )
};

export default StyledInput