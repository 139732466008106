import styled from 'styled-components';
import { color, typo} from '../../../styles';
import Logo from '../../Logo';

export const Header = styled.div`
  max-width: 646px;
  margin: 0 auto;
  margin-bottom: 83px;
`;

export const LogoContainer = styled.div`
  width: 70px;
  margin: 0 auto;
`;

export const StyledLogo = styled(Logo)`
	float: left;
	width: 26px;
	height: auto;
	path, polygon{
      fill: ${color.ovBlue};
    }
`;

export const BlogName = styled.span`
	font-size: 28px;
  width: 44px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 0.89;
  letter-spacing: normal;
  text-align: right;
  font-family: ${typo.ovFontFeature};
	color: ${color.ovBlue};
	display: inline-block;
`;