import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import List, { Container, Header, Form } from '../../components/TopSchools';
import { Grid } from 'react-bootstrap';

const MAX = 10;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const onDragEnd = (items, setItems, updateProfile) => result => {
  if (!result.destination) {
    return;
  }

  const sorted = reorder(
    items,
    result.source.index,
    result.destination.index
  );

  setItems(sorted);
  updateProfile(sorted);
}

const addSchool = (items, setItems, updateProfile) => item => {
  if (items.length >= MAX ) return;

  // If already exists, do nothing
  if (items.find(({ID}) => ID === item.ID)) return;

  const result = [...items, item];
  setItems(result);
  updateProfile(result);
};

const removeSchool = (items, setItems, updateProfile) => ID => {
  const response = window && window.confirm('Are you sure?');
  if (!response) return;
  const result = items.filter(item => item.ID !== ID);
  setItems(result);
  updateProfile(result);
} 

const TopSchools = ({ isFetching, fetchProfile, updateProfile, user, profile }) => {

  useEffect(() => {
    if (!user.ID) {
      fetchProfile();
    }
  }, [user]);

  const [items, setItems] = useState(profile.top_schools);
  // const [size, setSize] = useState(5);
  // onSelectSize={({target: {value}}) => setSize(value)}
  let sorted = (items || profile.top_schools);
  // if (sorted) sorted = sorted.slice(0, size);
  return (
    <Grid>
      <Helmet>
        <title>My Top Schools - OfficialVisit</title>
        <meta name="description" content="Return to your list of top schools to help narrow down your college decision!" />
      </Helmet>
      {
        isFetching
          ? (
            <div>Loading...</div>
          )
          : (
            <Container>
              <Header user={user} />
              <List
                items={sorted}
                onDragEnd={onDragEnd(sorted, setItems, updateProfile)}
                onRemove={removeSchool(sorted, setItems, updateProfile)}
              />
              { sorted.length < MAX &&
                <Form onAddSchool={addSchool(sorted, setItems, updateProfile)} emptyState={!sorted.length}/>
              }
            </Container>
          )
      }
    </Grid>
  )
};

export default TopSchools;