import React from 'react';
import styled from 'styled-components';
import { Button } from 'rebass';
import { color, typo } from '../styles'


const OVButton = styled(Button)`
  border: 3px solid ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 16px;
  background: ${color.ovBlue};
  border-radius: 0px;
  color: ${color.ovWhite};
  cursor: pointer;
  padding: 2px 20px;

  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.22;
  letter-spacing: 1px;

  &:hover, &:focus {
    text-decoration: none;
    background: ${color.ovBlueLight};
    border-color: ${color.ovBlueLight};
  }
`;

export default ({ children, onClick }) => <OVButton onClick={onClick}>{children}</OVButton>;