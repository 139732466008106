import React from 'react';
import Img from 'react-image';
import Spinner from 'react-spinkit';

// College Logos
const svgsContext = require.context('../../assets/brands', true, /\.svg$/);
const svgPaths = svgsContext.keys();

export const Logos = svgPaths.reduce((memo, key) => {
  memo[key.replace(/(\.\/)|(\.svg)/g, '')] = svgsContext(key);
  return memo;
}, {});

const CollegeBrands = ({ name, style }) => (
  <Img
    style={style}
    src={[Logos[name.replace(/\s/gi, '_').replace(/[()']/gi, '').toLowerCase()], 'https://i.imgur.com/bb2ETd2.png?1']}
    loader={<Spinner fadeIn="none" name="cube-grid" />}
    decode={false}
  />
);

export default CollegeBrands;
