import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux'; 
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Heading, Flex, Box } from 'rebass';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import CollegeSearchFilterForm from './searchFilter';
import { listCollegesIfEmpty, getCollegeByID, visibleColleges } from '../../modules/college';
import { addRecentSearch } from '../../modules/home'
import { color, typo } from '../../styles'

const Result = styled(Box)`
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
  &:last-child{
    margin-bottom: 0px;
  }
  &:hover {
    transition: .1s;
    padding-left: 2px
  };
`;

const SearchContainer = styled.div`
  margin: 0 -15px;
  position: relative;
`;

const ScrollbarContainer = styled(PerfectScrollbar)`
  max-height: 220px;
  overflow-y: hidden;
  .ps__rail-y{
    opacity: 1;
    .ps__thumb-y{
      background-color: ${color.ovYellow}
    }
  }
`;

const ResultHeading = styled(Heading)`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontSerif};
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  padding: 10px 0;
`;

const ResultTitle = styled(Heading)`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
  font-size: 25px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.04;
  letter-spacing: normal;
  text-align: left;
  padding: 10px 0 20px 0;
`;

const ResultsContainer = styled(Flex)`
  display: inline-block;
  border: solid 2px ${color.ovBlue};
  padding: 10px 10px 10px 31px;
  background-color: ${color.ovWhite};
  border-radius: 0 0 10px 10px;
  position: absolute;
  z-index: 10;
  max-height: 250px;
  width: 100%;
  overflow-y: hidden;
  margin-top: 1.5rem;
`;

class Search extends Component {
  render(){
    const { colleges, searchForm, addRecentSearch, recentSearches, isMainSearchFocused, push, onSelect } = this.props;
    return(
      <SearchContainer>
        <CollegeSearchFilterForm/>
        { searchForm && searchForm.values ? 
          <ResultsContainer> 
            <ScrollbarContainer>
              { colleges.length ? '' : <ResultTitle as='h3'>No matching results</ResultTitle> }
              { colleges.map((college) => {
                return (
                  <Result 
                    key={college.ID} 
                    onClick={ () => {
                      if (onSelect) return onSelect(college);
                      addRecentSearch(college.name, college.url_slug)
                      ;push('/college/' + college.url_slug);
                    }}
                    width={1} 
                  >
                    <span><ResultHeading as='h3'>{college.name}</ResultHeading></span>
                  </Result>
                );
              })}
            </ScrollbarContainer>
          </ResultsContainer> :
          recentSearches.length && isMainSearchFocused ? 
          <ResultsContainer> 
            <ScrollbarContainer>
              <ResultTitle as='h3'>Recent Search</ResultTitle>
              { recentSearches.map((recentSearch) => {
                return (
                  <Result 
                    key={'recent' + recentSearch.url} 
                    onClick={ () => {push('/college/' + recentSearch.url); } }
                    url={recentSearch.url}
                    width={1} 
                  >
                    <span><ResultHeading as='h3'>{recentSearch.name}</ResultHeading></span>
                  </Result>
                );
              })}
            </ScrollbarContainer>
          </ResultsContainer> : ''
        }
    </SearchContainer>
    )
  }
}

Search.propTypes = {
  colleges: PropTypes.array.isRequired,
  isFetchingCollege: PropTypes.bool.isRequired,
  getCollegeByID: PropTypes.func.isRequired,
  listCollegesIfEmpty: PropTypes.func.isRequired,
  addRecentSearch: PropTypes.func.isRequired,
  recentSearches: PropTypes.array.isRequired,
  isMainSearchFocused: PropTypes.bool.isRequired,

  onSelect: PropTypes.func,
};

const mapStateToProps = ({ college, form, home}) => ({
  colleges: visibleColleges(college.colleges, form.collegeSearchFilter),
  searchForm: form.collegeSearchFilter,
  isFetchingCollege: college.isFetchingCollege,
  recentSearches: home.recentSearches,
  isMainSearchFocused: home.isMainSearchFocused
})

const mapDispatchToProps = dispatch => bindActionCreators({
  listCollegesIfEmpty,
  getCollegeByID, 
  addRecentSearch,
  push
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Search)