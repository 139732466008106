export const OPEN_MODAL = 'contribute/OPEN_MODAL';
export const MODAL_CLOSE_AND_CONTRIBUTION_RESET = 'contribute/MODAL_CLOSE_AND_CONTRIBUTION_RESET';

export const NEW_CONTRIBUTION_REQUESTED = 'contribute/NEW_CONTRIBUTION_REQUESTED';
export const NEW_CONTRIBUTION = 'contribute/NEW_CONTRIBUTION';
export const NEW_CONTRIBUTION_FAILURE = 'contribute/NEW_CONTRIBUTION_FAILURE';
export const CONTRIBUTION_FILE_UPLOAD_FINISH = 'contribute/CONTRIBUTION_FILE_UPLOAD_FINISH';

export const ATTRACTION_CONTRIBUTION_REQUEST = 'contribute/ATTRACTION_CONTRIBUTION_REQUEST';
export const ATTRACTION_CONTRIBUTION_SUCCESS = 'contribute/ATTRACTION_CONTRIBUTION_SUCCESS';
export const ATTRACTION_CONTRIBUTION_FAILURE = 'contribute/ATTRACTION_CONTRIBUTION_FAILURE';

export const CONTRIB_ITEM_DESC_UPDATE = 'contribute/CONTRIB_ITEM_DESC_UPDATE';
export const CONTRIB_ITEM_REMOVE = 'contribute/CONTRIB_ITEM_REMOVE';
export const PROGRESS_UPDATED = 'contribute/PROGRESS_UPDATED';

export const LIST_CONTENT_TAGS = 'contribute/LIST_CONTENT_TAGS';
export const CONTENT_TAG_SELECT = 'contribute/CONTENT_TAG_SELECT';
export const CONTENT_TAG_DESELECT = 'contribute/CONTENT_TAG_DESELECT';
