import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from "lodash/debounce";

import { isMobile } from '../../config/app';
import Img from 'react-image';
import Lightbox from 'react-images';
import Popup from 'reactjs-popup';
import Spinner from 'react-spinkit';
import { OVButton } from '../../components';

import './style.css';
import {
  captionContainer,
  CloseBar, ModalCloseButton,
  GalleryModal,
  ImageCloseButton,
  ImageContainer,
  Modal, OVButtonMarginBottom,
  OVButtonMarginRight, ImgStyle,
  SubHeading, GalleryPageContainer, GalleryGridContainer
} from "./Styled";
import { bindActionCreators } from "redux";
import {
  deleteContent,
  selectContentIndex,
  selectNextIndex, selectPrevIndex, setContents,
  toggleLightboxOpen,
  toggleManage
} from "../../modules/content";
import { connect } from "react-redux";


/////////////////////////////////////////

const ImageCaption = ({ caption, user }) => {
  let title = caption;
  if (title && user.ID) title += " | ";
  title += user.ID ? `Uploaded by ${user.first_name} ${user.last_name}` : '';

  return (
    <div style={ captionContainer }>
      <span>{ title }</span>
    </div>
  )
};

const PhotoGallery = ({
  contents,
  user,
  contentContents,
  setContents,
  isManaging,
  toggleLightboxOpen,
  toggleManage,
  lightboxOpen,
  selectNextIndex,
  selectPrevIndex,
  selectContentIndex,
  selectedContentIndex,
  deleteContent,

  show,
  setShow,
  contentIndex,
  setContentIndex,
}) => {

  useEffect(() => {
      if (show) {
        setContents(contents)
      }
    }, [show] // fires on changed show
  );

  useEffect(() => { // other effect
      if (contentIndex >= 0) {
        selectContentIndex(contentIndex);
        if (!lightboxOpen) toggleLightboxOpen();
      }
    }, [contentIndex] // fires on changed show
  );

  // on GalleryModal close
  const CloseGallery = () => {
    setShow(false);
    setContentIndex(-1);
    // delete deleted items from original array
    if (contents.length !== contentContents.length) { // contentContents was copy of contents
      let j = contentContents.length - 1;
      for (let i = contents.length - 1; i >= 0; i--) {
        // remove until it would be the same ID
        if (contents[i].ID !== contentContents[j].ID) {
          contents.splice(i, 1);
        } else if (j > 0) {
          j--; // move with i to next item
        }
      }
    }
    // clear content.contents
    setContents([]);
  };

  // Creates a new array for Lightbox component, with just the image src and the caption (with the user who uploaded the photo)
  const lightboxImageSet = contentContents
    .map(({ ID, contrib_item, src, src_md }) => ({
      ID,
      src,
      src_md,
      caption: (<ImageCaption caption={ contrib_item.description } user={ user }/>)
    }));

  return (
    <GalleryModal
      isOpen={ show }
      onRequestClose={ CloseGallery }
      contentLabel="Upload Modal"
      shouldCloseOnOverlayClick={ true }
      shouldCloseOnEsc={ true }>
      <Lightbox
        className="content-lightbox"
        images={ lightboxImageSet }
        isOpen={ lightboxOpen }
        currentImage={ selectedContentIndex }
        onClickPrev={ selectPrevIndex }
        onClickNext={ selectNextIndex }
        onClose={ debounce(toggleLightboxOpen, 100) } // fire twice on close by backdrop... why?
        spinner={ () => <Spinner fadeIn="none" name="cube-grid"/> }
        spinnerColor={ 'white' }
        backdropClosesModal={ true }
      />
      <CloseBar>
        { !isManaging ? <OVButton onClick={ toggleManage }>Manage</OVButton> :
          <OVButton onClick={ toggleManage }>Finish</OVButton> }
        <ModalCloseButton onClick={ CloseGallery }/>
      </CloseBar>
      <GalleryPageContainer>
        <GalleryGridContainer>
          {/* If the there is filtered content to show, do so   */
            lightboxImageSet && lightboxImageSet.length ? lightboxImageSet.map((content, i) =>
              <ImageContainer onClick={ () => {
                !isManaging && selectContentIndex(i);
                !isManaging && toggleLightboxOpen();
                selectContentIndex(i);
              } } key={ 'image_cont_' + i }>
                { isManaging &&
                <Popup trigger={ <ImageCloseButton/> } modal>
                  {
                    close => (
                      <Modal>
                        <SubHeading>Are you sure you want to delete this photo?</SubHeading>
                        { !isMobile ? <OVButtonMarginRight onClick={ close }>Cancel</OVButtonMarginRight> :
                          <OVButtonMarginBottom onClick={ close }>Cancel</OVButtonMarginBottom> }
                        <OVButton onClick={ () => {
                          deleteContent(content);
                          close();
                        } }>Delete</OVButton>
                      </Modal>
                    )
                  }
                </Popup> }
                <Img
                  key={ 'img_' + content.ID }
                  src={ content.src_md }
                  alt={ content.caption }
                  style={ ImgStyle }
                  loader={ <Spinner fadeIn="none" name="cube-grid"/> }
                />
              </ImageContainer>) : <span/>
          }
        </GalleryGridContainer>
      </GalleryPageContainer>
    </GalleryModal>
  )
};

PhotoGallery.propTypes = {
  contents: PropTypes.array,
  user: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  lightboxOpen: PropTypes.bool.isRequired,
  toggleLightboxOpen: PropTypes.func.isRequired,
  selectNextIndex: PropTypes.func.isRequired,
  selectPrevIndex: PropTypes.func.isRequired,
  selectContentIndex: PropTypes.func.isRequired,
  selectedContentIndex: PropTypes.number.isRequired,
  isManaging: PropTypes.bool.isRequired,
  toggleManage: PropTypes.func.isRequired,
  deleteContent: PropTypes.func.isRequired,

  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  contentIndex: PropTypes.number.isRequired,
  setContentIndex: PropTypes.func.isRequired,
};

const mapStateToProps = ({ content }) => ({
  contentContents: content.contents,
  lightboxOpen: content.lightboxOpen,
  selectedContentIndex: content.selectedContentIndex,
  isManaging: content.isManaging,
  isFetching: content.isFetching,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleLightboxOpen,
  toggleManage,
  selectNextIndex,
  selectPrevIndex,
  selectContentIndex,
  setContents,
  deleteContent,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PhotoGallery);
