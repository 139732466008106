import React, {Component} from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import BigYellowButton from '../../components/bigYellowButton'
import { Search as SearchIcon } from 'styled-icons/material/Search'
import { color, typo, bp } from '../../styles'

const InputContainer = styled.div`
  margin-bottom: -22px;
  float: left;
  width: 70%;
  @media ${bp.tabletPortUp} {
    width: 80%;
  }
  @media ${bp.tabletLandUp} {
    width: 86%;
  }
  @media ${bp.desktopUp} {
    width: 90%;
  }
`;

const Button = styled.span`
  float: right;
`;

const StyledSearchIcon = styled(SearchIcon)`
  position: relative;
  top: 0px;
  left: 2px;
  height: 32px;
  width: 32px;
  color: ${color.ovWhite};
`;

let StyledInput = styled.input`
  display: inline-block;
  width: 100%;
  height: 76px;
  border: solid 2px ${color.ovGrey};
  padding: 22px 31px;
  background-color: ${color.ovWhite};
  font-family: ${typo.ovFontFeature};
  font-size: 25px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovGrey};
  border-radius: 10px;
  outline: none;
  &::placeholder{
    font-family: ${typo.ovFontFeature};
    font-size: 25px;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: ${color.ovGrey};
  }
  &:active, &:focus, &.filled{
    border-radius: 10px;
    border: solid 2px ${color.ovBlue};
  }
  &.filled{
    border-radius: 10px 10px 0 0;
  }
`;

const inputField = ({ input, label, type, meta: { dirty, touched, active, error } }) => (
  <InputContainer >
    <StyledInput {...input} placeholder={label} type={type} className={dirty && 'filled'} autoComplete="off"/>
    {touched && !active && error && <div>{error}</div>}
  </InputContainer>
);


class CoachesSearchFilterForm extends Component {
  render(){
    const {handleSubmit} = this.props;
    return(
      <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={12}>
          <Field
            type="text"
            name="text"
            component={inputField}
            label="Search coaches"
          />
          <Button type="submit">
            <BigYellowButton><StyledSearchIcon/></BigYellowButton>
          </Button>
        </Col>
      </Row>
    </form>
    )
  }
}

CoachesSearchFilterForm = reduxForm({
  // a unique name for the form
  form: 'coachSearchFilter'
})(CoachesSearchFilterForm)

export default CoachesSearchFilterForm;
