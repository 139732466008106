import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import college from './college';
import upload from './upload';
import contribute from './contribute';
import user from './user';
import otherUser from './otherUser';
import content from './content';
import review from './review';
import home from './home';
import coach from './coach';
import wizard from './wizard';
import blog from './blog';
import attraction from './attraction';
import attractionReview from './attraction/reviews';


export default combineReducers({
  form: formReducer,
  college,
  upload,
  contribute,
  user,
  otherUser,
  content,
  review,
  attractionReview,
  home,
  coach,
  wizard,
  blog,
  attraction,
})
