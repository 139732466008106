import styled from 'styled-components';
import { color, bp, typo } from '../../styles';
import { Heading, Button } from 'rebass';
import { ReactComponent as One } from './ratingIcons/1.0.svg';
import { ReactComponent as Zero } from './ratingIcons/0.0.svg';
import { ReactComponent as CheckCircle } from '../../assets/icons/verified.svg'; 
import { UserCircle } from 'styled-icons/fa-solid/UserCircle';
import { X } from "styled-icons/feather/X";

export const Title = styled(Heading)`
  grid-column: 2 / 5;
  grid-row: 2 / 3;

  color: ${color.ovBlack};
  font-family: ${typo.ovFontSerif};
  font-size: 2.8rem;
  line-height: 1;

  font-size: 23px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  min-height: 60px;
  align-items: center;
  display: grid;
  @media ${bp.phoneOnly} {
    grid-row: 5;
    grid-column: 1 / span 12;
    padding: 16px 5px;
  }
`;

export const ReviewTypeLabel = styled(Heading)`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
  font-size: 2rem;
  margin-left: 1.4rem;
  margin-bottom: 1.4rem;
  line-height: 1;

  @media ${bp.phoneOnly} {
    font-size: 1.8rem;
    margin-left: 0rem;
  }
`;

export const ProfileIcon = styled.div`
  width: 80px;
  height: 80px;
  padding: 3px;
  margin-bottom: -20px;
  border-radius: 100px;
  border: 2px solid ${color.ovBlue};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: auto;

  ${UserCircle} {
    margin: -2px;
    height: 74px;
    width: 74px;
    @media ${bp.phoneOnly} {
      margin: -3px;
      height: 56px;
      width: 56px;
    }
  }

  @media ${bp.phoneOnly} {
    width: 60px;
    height: 60px;
  }
`;

export const ReviewBox = styled.div`
  grid-column: span 12;
  display: grid;
  grid-template-columns: 35% auto;
  grid-template-rows: 4rem 4rem minmax(1fr, 10rem) 1fr;
  word-break: break-word;
  padding: 2rem 2rem;
  border-bottom: 2px solid ${color.ovGrey};
  position: relative;
  &:last-child {
    border-bottom: none;
  }
  @media ${bp.phoneOnly} {
    padding: 2rem 1.5rem;
  }
`;

export const ReviewDeleteButton = styled(X)`
  position: absolute;
  right: 0;
  display: inline;
  color: ${color.ovRed};
  width: 30px;
  cursor: pointer;
  z-index: 1;
  transition: all .1s;
  &:hover{
    color: ${color.ovBlue};
    transition: all .1s;
  }
`;

export const ReviewerPane = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 5;
  padding: 0 0 5rem;
  position: relative;

  @media ${bp.phoneOnly} {
    display: grid;
    grid-column: 1 / span 12;
    grid-template-columns: 70px auto;
  }
`;

export const ReviewerName = styled(Heading)`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
  font-size: 1.8rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  line-height: 1.8rem;
  cursor: ${props => props.is_anonymous ? "default" : "pointer"};
  @media ${bp.phoneOnly} {
    grid-column: 2 / span 12;
    grid-row: 1;
  }
`;

export const ImgStyle = {
  objectFit: 'cover',
  height: '100%',
  width: '100%',
  imageOrientation: 'from-image',
}

export const ImageContainer = styled.div`
  width: 80px;
  vertical-align : middle;
  overflow: hidden;
  cursor: ${props => props.is_anonymous ? "default" : "pointer"};
  @media ${bp.phoneOnly} {
    width: 60px;
    grid-row: 1 / 3;
    grid-column: 1;
  }
`;

export const ReviewedAt = styled.p`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.2rem;
  color: ${color.ovBlack};
  font-family: ${typo.ovFontSerif};
  font-style: italic;
  padding-top: 8px;
  @media ${bp.phoneOnly} {
    grid-column: 6 / span 8; 
    grid-row: 4; 
    top: auto;
    padding-top: 2px;
  }
`;

export const ExpandContainer = styled.div`
  grid-column: 2 / 5;
  grid-row: 3 / 5;
  padding-top: 2rem;
  @media ${bp.phoneOnly} {
    padding-right: 1em;
    grid-column: 1 / span 12;
    grid-row: 6;
    padding-left: .5rem;
  }
`;

export const ExpandContainerCollege = styled(ExpandContainer)`
  grid-row: auto;
  @media ${bp.phoneOnly} {
    grid-row: 7;
  }
`;

export const ReviewHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 2.8rem;
  margin-bottom: 1.4rem;
  line-height: 1;

  @media ${bp.phoneOnly} {
    font-size: 2.2rem;
  }
`;

export const OVRatingDisplay = styled.div`
  grid-column: 2 / 5;
  grid-row: 1 / 2;
  margin-bottom: 2rem;
  @media ${bp.phoneOnly} {
    grid-column: 1 / span 8;
    grid-row: 4;
    margin-bottom: 0;
    padding-left: .5rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const OVButton = styled(Button)`

  display: inline-block;
  text-align: center;

  border: .2rem solid ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
  background: ${color.ovWhite};
  border-radius: 0px;
  color: ${color.ovBlack};
  cursor: pointer;

  padding: 1px 41px 3px;

  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;

  &:hover, &:focus {
    text-decoration: none;
    color: ${color.ovBlue};
    border-color: ${color.ovBlue};
  }
`;

export const OVRatingNumber = styled(Heading)`
  display: inline;
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  margin-right: 1.4rem;

  @media ${bp.phoneOnly} {
  font-size: 2rem;
    margin-right: 1rem;
  }
`;

export const Brief = styled.p`
  grid-column: 2 / 5;
  grid-row: 2 / 3;
  color: ${color.ovBlack};
  font-family: ${typo.ovFontSerif};
  font-size: 16px;
  line-height: 1.8rem;
  padding-top: 1rem;
  padding-right: 2em;

  @media ${bp.phoneOnly} {
    grid-column: 1 / span 12;
    grid-row: 5;
    padding-left: .5rem;
  }
`;

export const BriefCollege = styled(Brief)`
  grid-row: 3 / 4;
  min-height: 60px;
  align-items: center;
  display: grid;
  @media ${bp.phoneOnly} {
    grid-row: 6;
  }
`;

////////////////////////////////////
/// Inner Review Components

export const StyledCheck = styled(CheckCircle)`
  width: 27px;
  color: ${color.ovBlack};
  margin-right: 8px;
  position: relative;
  top: 7px;
  margin-left: 4px;
`;

// Generated Rating List

export const SizedOne = styled(One)`
  height: 2rem;
  margin-right: 0.3rem;

  @media ${bp.phoneOnly} {
    height: 1.6rem;
    margin-right: 0.2rem;
  }
`;

export const SizedZero = styled(Zero)`
  height: 2rem;
  margin-right: 0.3rem;

  @media ${bp.phoneOnly} {
    height: 1.6rem;
    margin-right: 0.2rem;
  }
`;

export const ReviewerInfoContent = styled.div`
  padding: .5rem;
  color: ${color.ovBlack};
  font-family: Molde;
  font-size: 11px;

  > .sport-position {
    display: inline-block;
    padding: .5rem 0;
    margin-top: .5rem;
    border-top: 1px solid ${color.ovBlack};
  }

  @media ${bp.phoneOnly} {
    padding: 0;
    grid-column: 2 / span 12;
  }
`;