import React from 'react';
import { X } from 'styled-icons/octicons';
import Button from '../Button';
import Select from '../Select/Multi';
import ListInput from '../ListInput';

import {
  FiltersContainer,
  FiltersBoxHeader,
  FiltersBox,
  FiltersGroupBox,
  FiltersGroupTitle,
} from './Styled'
import { filter, times } from 'lodash-es';
import { useCallback } from 'react';

const years = times(80, (i) => {
  const value = new Date().getFullYear() - i;
  return {
    value,
    label: value
  }
});

const mapColleges = (colleges) => colleges && colleges.map((college) => ({
  value: college.ID,
  label: college.name,
}));

const mapTitles = (titles) => titles && titles.map((title) => ({
  value: title,
  label: title,
}));

const Filters = ({ filters, isFetching, colleges, titles, onChange, onClearFilters, onToggleFilters }) => {
  const handleFilterChange = useCallback((field, { value }) => {
    const newFilters = {
      ...filters,
      [field]: value
    };
    onChange(newFilters);
  }, [filters, onChange]);

  const collegeItems = mapColleges(colleges);
  const titleItems = mapTitles(titles);

  return (
    <FiltersContainer>
      <FiltersBoxHeader>
        <FiltersGroupBox>
          <a onClick={onClearFilters}>Clear all</a>
          <X onClick={onToggleFilters} />
        </FiltersGroupBox>
      </FiltersBoxHeader>
      <FiltersBox>
        <FiltersGroupBox>
          <FiltersGroupTitle>Year</FiltersGroupTitle>
          <Select
            name="year"
            value={filters.year}
            onChange={handleFilterChange}
            placeholder="Select year"
            isSearchable={false}
            options={years}
          />
        </FiltersGroupBox>

        <FiltersGroupBox>
          <FiltersGroupTitle>Job Title</FiltersGroupTitle>
          <ListInput
            name="title"
            value={filters.title}
            onChange={handleFilterChange}
            placeholder="Enter a position"
            isFetching={isFetching}
            items={titleItems}
          />
        </FiltersGroupBox>


        <FiltersGroupBox>
          <FiltersGroupTitle>College</FiltersGroupTitle>
          <ListInput
            name="college"
            value={filters.college}
            onChange={handleFilterChange}
            placeholder="Enter a college"
            isFetching={isFetching}
            items={collegeItems}
          />
        </FiltersGroupBox>
      </FiltersBox >
    </FiltersContainer>
  )
}

export default Filters;