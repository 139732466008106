import React from 'react';

// Styles
import { 
  LocationsCardCointainer,
  StyledPlus,
  LocationsAddTitile,
  StyledPlusContainer,
} from './Styled';

const LocationsAdd = ({ 
  onClick, onMouseEnter, onMouseLeave, role
}) => {
	//const tagsLength = tags.length;
	// hack for prevent error while attraction is null

	return(
	  <LocationsCardCointainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick}>
      <StyledPlusContainer><StyledPlus/></StyledPlusContainer><LocationsAddTitile>Add Attraction</LocationsAddTitile> 
	  </LocationsCardCointainer>
  )
};

export default LocationsAdd;