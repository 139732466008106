import { color, typo } from '../../../styles'
import styled from 'styled-components';
import { Heading } from 'rebass';
import { Plus } from 'styled-icons/fa-solid/Plus';

export const LocationsCardCointainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  min-height: 103px;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0,0,0, 0.16);
  border: dashed 1px #777777;
  background-color: ${color.ovWhite};
  padding: 7px 16px;
  margin-bottom: 8px;
  transform: scale(1);
  transition: all .1s;
  cursor: pointer;
  &:hover{
  	transition: all .1s;
   	transform: scale(1.04);
  }
`;

export const LocationsAddTitile = styled(Heading)`
	font-family: ${typo.ovFontFeature};
	font-size: 28px;
  font-weight: 300;
  font-style: normal;
  text-align: right;
  line-height: 1;
  margin-top: 25px;
  letter-spacing: normal;
  margin-bottom: auto;
  width: 60%;
  color: ${color.ovGrey};
`;

export const StyledPlusContainer = styled.div`
  width: 35%;
  color: ${color.ovGrey};
  right: 1px;
`;


export const StyledPlus = styled(Plus)`
  width: 30%;
  color: ${color.ovGrey};
  right: 1px;
  top: 32px;
`;
