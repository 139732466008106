
// Styles stuff
import styled from 'styled-components';
import { color, typo, bp } from '../../styles';
import { X } from 'styled-icons/feather/X';
import { PencilAlt } from 'styled-icons/fa-solid/PencilAlt';
import { Delete } from 'styled-icons/material/Delete';
import { MapMarkerAlt } from 'styled-icons/fa-solid/MapMarkerAlt';


// Styled components

const AddRowButton = styled.button`
  height: 39px;
  border-radius: 10px;
  border: solid 1px ${color.ovBlue};
  background-color: ${color.ovWhite};
  transition: all .1s;
  text-align: center;
  padding: 8px 20px;
  position: relative;
  margin: 1rem 1rem 1rem 0;
  width: 130px;
  &:hover{
    background-color: ${color.ovBlue};
    color: ${color.ovWhite};
    transition: all .1s;
  }
`;
export const PageContent = styled.div`
  grid-column: span 12;
  background-color: ${color.ovWhite};
  border: 1px solid ${color.ovGrey};
  padding: 25px;
  margin: 10px 0;
  height: auto;
`;

export const DiscardButton = styled.button`
  height: 76px;
  border-radius: 10px;
  border: solid 1px ${color.ovYellow};
  background-color: ${color.ovRed};
  transition: all .1s;
  text-align: center;
  padding: 4px 50px;
  font-size: 30px;
  color: ${color.ovWhite};
  margin-bottom: 10px;
  min-width: 210px;
  margin-left: 1rem;
  &:hover{
    background-color: ${color.ovBlue};
    color: ${color.ovWhite};
    transition: all .1s;
  }

  @media ${bp.phoneOnly} {
    height: 48px;
  }

  &:focus {
    outline: none !important;
  }
`;
export const ButtonLabel = styled.p`
  font-family: ${typo.ovFontFeature};
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: center;
  padding: 0;
  @media ${bp.phoneOnly} {
    font-size: 22px;
  }
`;
export const SaveButton = styled(DiscardButton)`
  margin-top: 2%;
  border: solid 1px ${color.ovGrey};
  background-color: ${color.ovYellow};
  color: ${color.ovBlue};
`;
export const ProfileHeading = styled.div`
  position: relative; 
  width: 50%;
  margin: 0 auto;
  input{
    text-align: left;
    padding: 15px 25px;
    &::placeholder{
      text-align: left;
    }
  }
  @media ${bp.phoneOnly} {
    width: 95%;
  }
`;
export const EditButton = styled.button`
  cursor: pointer;
  align-self: center;
  text-align: center;
  height: 38px;
  background-color: transparent;
  border: none;
  ${ProfileHeading} &{
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 15px;
    height: 58px;
  }
`;
export const CoachLabel = styled.span`
  display: inline;
  color: ${color.ovBlack};
  font-family: ${typo.ovFontSerif};
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  align-self: center;
`;

export const ProfileSubHeading = styled(CoachLabel)`
  text-align: center;
  display: block;
`;
export const ErrorMessage = styled.div`
  display: block;
  color: ${color.ovRed};
  margin-top: -17px;
  text-align: left;
  padding: 10px 0;
`;

export const StyledClose = styled(X)`
  width: 13px;
  color: ${color.ovBlue};
  margin-right: 8px;
  position: absolute;
  right: 7px;
  top: 12px;
  ${AddRowButton}:hover &{
    color: ${color.ovWhite};
  }
  @media ${bp.phoneOnly} {
    top: 17px;
  }
`;

export const StyledEdit = styled(PencilAlt)`
  width: 13px;
  color: ${color.ovGrey};
  @media ${bp.phoneOnly} {
    top: 17px;
  }
`;

export const StyledRemove = styled(Delete)`
  margin-left: 5px;
  width: 17px;
  color: ${color.ovGrey};
  @media ${bp.phoneOnly} {
    top: 17px;
  }
`;
export const DeleteButton = styled(AddRowButton)`
  width: auto;
`;

export let AttractionCardInput = styled.textarea`
  display: inline-block;
  width: 100%;
  height: 235px;
  border-radius: 10px;
  padding: 10px 25px 10px 25px;
  background-color: ${color.ovWhite};
  margin-bottom: 20px;
  resize: none;
  font-family: ${typo.ovFontFeature};
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  outline: none;
  border: solid 1px ${color.ovBlue};
  color: ${color.ovBlue};

  &::placeholder{
    font-family: ${typo.ovFontFeature};
    font-size: 25px;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: ${color.ovGrey};
  }
  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button{
    -webkit-appearance: none; 
    margin: 0;
  }
`;

export const LocationsCardFilters = styled.p`
  margin-top: 3%;
	font-family: ${typo.ovFontSerif};
	font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #777777;
`;

export const CategoryTag = styled.span`
	height: 23px;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px ${color.ovBlue};
  background-color: ${color.ovWhite};
  font-family: ${typo.ovFontFeature};
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: ${color.ovBlue};
  padding: 3px 5px 0px 8px;
  margin: 0 10px 10px 0;
  display: inline-block;
`;

export const AttractionAddress = styled.div`
	text-align: left; 
	font-family: ${typo.ovFontSerif};
	font-size: 15px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${color.ovBlack};
  margin: 8px 0;
`;

export const StyledMarker = styled(MapMarkerAlt)`
	color: ${color.ovBlue};
	height: 15px;
	padding-right: 5px;
`;
