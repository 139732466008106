import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { getAttractions, deleteAttraction } from '../../modules/attraction/attraction';
import { attractionListSelector, isFetchingSelector, attractionTagsSelector } from '../../modules/attraction/selectors';

import { getCollegeReviewsAndMajorsIfEmpty } from '../../modules/college';
import { getUserIfEmpty } from "../../modules/user";

import Attractions from './Attractions';

const mapStateToProps = (state) => ({
  college: state.college.college,
  averageReview: state.college.averageReview,
  attractions: attractionListSelector(state.college.college && state.college.college.ID)(state),
  tags: attractionTagsSelector(state),
  isFetching: isFetchingSelector(state),
  role: state.user.user.role,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getAttractions,
  getCollege: getCollegeReviewsAndMajorsIfEmpty,
  deleteAttraction,
  push,
  getUserIfEmpty,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Attractions)