import React from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { listCollegesIfEmpty } from '../../modules/college';
import { searchCoaches, getCoachTitles } from '../../modules/coach';

import Coaches from './Coaches';
import { debounce } from 'lodash-es';

const mapStateToProps = ({ college, coach, form, user }) => ({
  colleges: college.colleges,
  isFetchingCollege: college.isFetchingCollege,
  coaches: coach.coaches,
  count: coach.coachesCount,
  titles: coach.titles,
  isFetchingCoaches: coach.isFetchingCoaches,
  isAuthenticated: user.isAuthenticated
});

const mapDispatchToProps = dispatch => bindActionCreators({
  listCollegesIfEmpty,
  searchCoaches,
  getCoachTitles,
  push,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Coaches);
