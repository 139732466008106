import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, typo, bp } from '../../styles';

const BigYellowButtonContainer = styled.button`
  height: 76px;
  width: 100%;
  border-radius: 10px;
  border: solid 1px ${color.ovGreyDark};
  background-color: ${color.ovYellow};
  transition: all .1s;
  text-align: center;
  padding: 16px 20px;
  
  &:hover{
    background-color: ${color.ovBlue};
    color: ${color.ovWhite};
    transition: all .1s;
  }
`;

const BigYellowButtonLabel = styled.p`
  font-family: ${typo.ovFontFeature};
  font-size: 25px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: center;

  @media ${bp.phoneOnly} {
    font-size: 22px;
    color: ${color.ovWhite}
  }
`;

const BigYellowButton = ({ 
    bigYellowButtonLinkOnClick,
    bigYellowButtonLabel,
    children
  }) =>{

  return (
    <BigYellowButtonContainer onClick={bigYellowButtonLinkOnClick}>
      <BigYellowButtonLabel>
        {bigYellowButtonLabel}
        {children}
      </BigYellowButtonLabel>
    </BigYellowButtonContainer>
  )
} 

BigYellowButton.propTypes = {
  bigYellowButtonLinkOnClick: PropTypes.func,
  bigYellowButtonImageSrc: PropTypes.string,
  bogYellowButtonLabel: PropTypes.string,
};

export default BigYellowButton