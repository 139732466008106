import React from 'react';
import { uniqBy } from 'lodash';
import CollegeCard from '../../components/College/CollegeCard';

import { PageContainer } from '../../components';
import { ReviewsItem } from '../../components';

import {PhotoTitle, PhotoDescription} from './Styled'
import UserContributionPhotoBox from "./UserContributionPhotoBox";
import CoachReviewsItem from "../../components/reviews/CoachItem";

import { collegeNameSelector, collegeSlugSelector, collegeTeamSelector, collegeTeamSlugSelector } from '../../modules/college/selectors'
import { attractionNameSelector, attractionReferSelector, attractionSlugSelector } from '../../modules/attraction/selectors';
import { coachNameSelector, coachSlugSelector } from '../../modules/coach/selectors';
import { contributionsByTypeSelector } from '../../modules/user/selectors';

const UserContributions = ({ 
  contributions, 
  user,
  colleges,
  targetAttractions,
  targetCoaches,
  push
}) => {
  const contributionsByType = contributions && contributionsByTypeSelector(contributions);

  return (
    <PageContainer>
      {contributionsByType && contributionsByType.colleges && contributionsByType.colleges.map((contribution, index) => {
        if (contribution.reviews) {
          return (
            <CollegeCard 
              type="review" collegeCardTitle={`${user.display_name} reviewed`}
              key={index}
              name={collegeNameSelector(contribution, colleges)}
              handleClick={()=> push('/college/' + collegeSlugSelector(contribution, colleges))} >
                <ReviewsItem
                  id={user.ID}
                  currentUserRole={user.role}
                  key={index}
                  user={contribution.user}
                  reviews={contribution.reviews}
                  title={contribution.title}
                  is_anonymous={contribution.is_anonymous}
                  createdAt={contribution.created_at}
                  onUserClick={() => push(`/user/${contribution.user.ID}`)} />
            </CollegeCard>
          );
        };
        if (contribution.contents) {
          return (
            <CollegeCard 
              type="photos"
              key={index}
              collegeCardTitle={`${user.display_name} uploaded photos to`}
              name={collegeNameSelector(contribution, colleges)}
              handleClick={()=> push('/college/' + collegeSlugSelector(contribution, colleges))} >
              <PhotoTitle>{contribution.title}</PhotoTitle>
              <PhotoDescription>{contribution.description}</PhotoDescription> 
              <UserContributionPhotoBox contents={contribution.contents} user={user}/>
            </CollegeCard>
          )
        }
      })}
      { contributionsByType && contributionsByType.teams && colleges && contributionsByType.teams.map((contribution, index) => {
        if (contribution.reviews) {
          return (
            <CollegeCard 
              type="review" collegeCardTitle={`${user.display_name} reviewed`}
              key={index}
              name={collegeTeamSelector(contribution, colleges)}
              handleClick={()=> push('/college/' + collegeTeamSlugSelector(contribution, colleges))} >
              <ReviewsItem
                id={user.ID}
                currentUserRole={user.role}
                key={index}
                user={contribution.user}
                reviews={contribution.reviews}
                title={contribution.title}
                is_anonymous={contribution.is_anonymous}
                createdAt={contribution.created_at}
                onUserClick={() => push(`/user/${contribution.user.ID}`)}
              />
            </CollegeCard>
          )
        }
      })}
      { contributionsByType && contributionsByType.attractions && targetAttractions && contributionsByType.attractions.map((contribution, index) => {
        if (contribution.reviews) {
          return (
            <CollegeCard 
              type="review"
              key={index}
              collegeCardTitle={`${user.display_name} reviewed`}
              name={attractionNameSelector(contribution, targetAttractions)}
              handleClick={()=> push(
                '/college/' 
                + attractionReferSelector(contribution, targetAttractions, colleges) 
                + '/attractions/' 
                + attractionSlugSelector(contribution, targetAttractions))} >
              <ReviewsItem
                id={user.ID}
                currentUserRole={user.role}
                key={index}
                user={contribution.user}
                reviews={contribution.reviews}
                title={contribution.title}
                is_anonymous={contribution.is_anonymous}
                createdAt={contribution.created_at}
                onUserClick={() => push(`/user/${contribution.user.ID}`)}
              />
            </CollegeCard>
          );
        };
        if (contribution.contents) {
          return (
            <CollegeCard 
              type="photos"
              key={index}
              collegeCardTitle={`${user.display_name} uploaded photos to`}
              name={attractionNameSelector(contribution, targetAttractions)}
              handleClick={()=> push(
                '/college/'
                + attractionReferSelector(contribution, targetAttractions, colleges)
                + '/attractions/'
                + attractionSlugSelector(contribution, targetAttractions))} >
              <PhotoTitle>{contribution.title}</PhotoTitle>
              <PhotoDescription>{contribution.description}</PhotoDescription>
              <UserContributionPhotoBox contents={contribution.contents} />
            </CollegeCard>
          )
        };
      })}
      {contributionsByType && contributionsByType.coaches && targetCoaches && contributionsByType.coaches.map((contribution, index) => {
        if (contribution.reviews) {
          return (
            <CollegeCard 
              type="review"
              key={index}
              collegeCardTitle={`${user.display_name} reviewed`}
              name={coachNameSelector(contribution, targetCoaches)}
              handleClick={()=> push('/coach/' + coachSlugSelector(contribution, targetCoaches))} >
              <CoachReviewsItem
                id={user.ID}
                currentUserRole={user.role}
                key={index}
                user={contribution.user}
                reviews={contribution.reviews}
                is_anonymous={contribution.is_anonymous}
                title={contribution.title}
                createdAt={contribution.created_at}
                onUserClick={() => push(`/user/${contribution.user.ID}`)}
              />
            </CollegeCard>
          )
        }
      })}
    </PageContainer>
  )
};

export default UserContributions;