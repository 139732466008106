import React, { useState, useRef, useEffect } from 'react';
import delay from 'lodash/delay';

import PropTypes from 'prop-types';
import HomeCard from '../HomeCard';
import { isMobile } from '../../config/app';

import {
  Wrapper,
  ContainerStyle,
  ListContainer,
  CardItem,
  HandlerLeft,
  HandlerRight,
} from './Styled';

const scrollTo = (direction = 'right', node, count, toggleHandlers) => {
  // eslint-disable-next-line
  const { height, width } = node.getBoundingClientRect();
  const itemWidth = width / (isMobile ? 2 : 3);

  const amount = direction === 'right'
    ? itemWidth
    : itemWidth * -1;

  node.scrollBy(amount, 0);

  delay(() => {
    toggleHandlers({
      left: node.scrollLeft > 60,
      right: (node.scrollLeft + width) < (itemWidth * count - 60),
    })
  }, 300)
}

const HomeCollegesSlider = ({ isFetching, topColleges, push }) => {
  const container = useRef(null);
  const [ bounds, setBounds ] = useState({height: 0, width: 0});
  const [ handlers, toggleHandlers ] = useState({ left: false, right: true });

  useEffect(() => {
    const { height, width} = container.current.getBoundingClientRect();
    setBounds({ height: height - 30, width});
  });

  return (
    <Wrapper style={{ height: bounds.height }}>
      {handlers.left && <HandlerLeft onClick={() => scrollTo('left', container.current, topColleges.length, toggleHandlers)}/> }
      <div ref={container} style={ContainerStyle}>
        <ListContainer length={topColleges ? topColleges.length : 0}>
          {!isFetching && topColleges && topColleges.map((college) => (
            <CardItem key={college.url_slug}>
              <HomeCard
                name={college.name}
                logo={college.name}
                bkg={college.name}
                url_slug={college.url_slug}
                type="college"
                average_rating={college.average_rating}
                push={push}
              />
            </CardItem>
          ))}
        </ListContainer>
      </div>
      {handlers.right && <HandlerRight onClick={() => scrollTo('right', container.current, topColleges.length, toggleHandlers)} /> }
    </Wrapper>
  );
}

HomeCollegesSlider.propTypes = {
  topColleges: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.any,
    bkg: PropTypes.string,
    url_slug: PropTypes.string,
    type: PropTypes.string,
    average_rating: PropTypes.number,
  })),
  push: PropTypes.func,
  isFetching: PropTypes.bool,
};

export default HomeCollegesSlider;
