import React, { useState } from 'react';
import AverageReview from '../reviews/AverageReview';
import default_bkg from '../../assets/img/profile-bg.jpg';
import CoachPictureContainer from "../../components/CoachPicture";
import CoachProfileModal from './CoachProfileModal';
import { isMobile, isDesktop, isTabletLand, isTabletPort, isWide } from '../../config/app';
import {
  CoachHeaderBox,
  CoachHeaderBkg,
  CoachHeaderData,
  CoachHeaderProfile,
  CoachHeaderName,
  CoachHeaderTitle,
  CoachHeaderReviews,
  ClaimCoachProfileContainer,
  ClaimCoachProfileText,
  Separator,
} from './Styled';

const ClaimProfile = ({ mobileOnly, coachName, onToogleClaimCoachProfile, onToogleCoachReview }) => (
  <ClaimCoachProfileContainer mobileOnly={mobileOnly}>
    <ClaimCoachProfileText onClick={() => onToogleClaimCoachProfile(true)}>
      I'm {coachName}
    </ClaimCoachProfileText>
    <Separator />
    <ClaimCoachProfileText onClick={() => onToogleCoachReview(true)}>
      Submit a Review
    </ClaimCoachProfileText>
  </ClaimCoachProfileContainer>
);

const CoachHeader = ({
  coachName,
  coachProfilePicture,
  coachHistoryTitle,
  coachHistoryName,
  coachHistoryEnd,
  ProfileBkgUrl,
  averageReview,
  role
}) => {
  const [isClaimCoachProfileOpen, toogleClaimCoachProfile] = useState(false)
  const [isCoachReviewOpen, toogleCoachReview] = useState(false)
  return(
    <CoachHeaderBox>
      <CoachHeaderBkg bgUrl={ProfileBkgUrl ? ProfileBkgUrl : default_bkg}/>
      { role === "admin" && (
        <CoachPictureContainer/>
      )}
      <CoachHeaderProfile bgUrl={coachProfilePicture ? coachProfilePicture : "https://i.imgur.com/bb2ETd2.png?1"}/>
      
      <CoachProfileModal isClaimCoachProfileOpen={isClaimCoachProfileOpen} onToogleClaimCoachProfile={toogleClaimCoachProfile} isCoachReviewOpen={isCoachReviewOpen} onToogleCoachReview={toogleCoachReview}/>
      
      <ClaimProfile
        coachName={coachName}
        onToogleClaimCoachProfile={toogleClaimCoachProfile}
        onToogleCoachReview={toogleCoachReview}
      />
      
      <CoachHeaderData>
        <CoachHeaderName>{coachName}</CoachHeaderName>
        <CoachHeaderTitle>
          {coachHistoryEnd} {coachHistoryTitle} - {coachHistoryName}
        </CoachHeaderTitle>
        <AverageReview averageReview={averageReview.average_rating} count={averageReview.reviews_count} big/>
        <CoachHeaderReviews>Average verified player rating</CoachHeaderReviews>
      </CoachHeaderData>
     
      <ClaimProfile
        mobileOnly
        coachName={coachName}
        onToogleClaimCoachProfile={toogleClaimCoachProfile}
        onToogleCoachReview={toogleCoachReview}
      />
    </CoachHeaderBox>
  )
};

export default CoachHeader;