import React from 'react';
import { FullWidthPageContainer } from '../../components';

import {
  ContactPageBox,
  ContactPageContent,
  ContactPageBarBox,
  ContactPageTitle,
  ContactParagraph,
  ContactTitle
} from './Styled';
import { Helmet } from "react-helmet";

const ContactPage = () =>{
  return(
    <FullWidthPageContainer>
      <Helmet>
        <title>Contact Us - OfficialVisit</title>
        <meta name="description" content="We would love to hear from you! Here are the ways to get in contact with OfficialVisit." />
      </Helmet>
      <ContactPageBox>
        <ContactPageBarBox>
          <ContactPageTitle>
            Contact Us
          </ContactPageTitle>
        </ContactPageBarBox>
        <ContactPageContent>
          <ContactParagraph>We would love to hear from you!</ContactParagraph>

          <ContactTitle>General inquiries</ContactTitle>
          <ContactParagraph>For all general inquiries, contact us at <a href="mailto:info@officialvisit.com">info@officialvisit.com</a>.  This mailbox is frequently monitored by
          our team.</ContactParagraph>

          <ContactTitle>Reviews/Verification</ContactTitle>
          <ContactParagraph>For questions about the reviews featured on OfficialVisit, or to verify your review, contact us at <a href="mailto:reviews@officialvisit.com">reviews@officialvisit.com</a>.</ContactParagraph>
        </ContactPageContent>
      </ContactPageBox>
    </FullWidthPageContainer>
  )
}

export default ContactPage;