import { ENV } from './env';

export const isProduction = ENV === 'production';
export const isDevelopment = ENV === 'development';
export const isClient = typeof window !== 'undefined';
export const isMobile = window.innerWidth <= 599;
export const isTabletPort = window.innerWidth >= 600 && window.innerWidth < 900;
export const isTabletLand = window.innerWidth >= 900 && window.innerWidth < 1200;
export const isDesktop = window.innerWidth >= 1200 && window.innerWidth < 1800;
export const isWide = window.innerWidth >= 1800;

export const apiEndpoint = isDevelopment ? 'http://localhost:8080' : process.env.REACT_APP_API_URL;
// Replace with 'UA-########-#' or similar to enable tracking
export const trackingID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE;

console.log("GAnalytics tracing ID: ", trackingID);
