import React from 'react';
import styled from 'styled-components';
import { color, typo, bp } from '../../styles';
import Select from "react-select";

let StyledSelectContainer = styled(Select)`
  height: 59px;
  margin-bottom: 17px;
  .react-select__control {
    width: 100%;
    height: 59px;
    border: solid 1px ${color.ovBlue};
    padding: 0 10px 0 15px;
    border-radius: 10px;
    background-color: ${color.ovWhite};
    font-family: ${typo.ovFontFeature};
    font-size: 25px;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: ${color.ovBlue};
    outline: none;
    margin-bottom: 23px;
  }

  .react-select__control--is-disabled{
    border: solid 1px ${color.ovGrey};
    color: ${color.ovGrey};
    .react-select__indicator {
      color: ${color.ovGrey};
    }
  }

  .react-select__indicator-separator{
    display: none;
  }

  .react-select__value-container {
    display: block;
    width: 100%;
    padding-left: 10px;
    background-color: ${color.ovWhite};
    padding-top: 0;
    padding-bottom: 0;
    height: 57px;
    position: static;
  }

  .react-select__single-value {
    color: ${color.ovBlue};
    margin-left: 0;
    margin-right: 0;
    max-width: calc(100% - 85px);
  }

  .react-select__indicator {
    color: ${color.ovBlue};
  }

  .react-select__menu {
    display: inline-block;
    border-radius: 10px;
    border: solid 1px ${color.ovBlue};
    width: 100%;
    top: 51px;
    left: 0;
    background-color: ${color.ovWhite};
    @media ${bp.phoneOnly} {
      max-height: 150px;
    }
  }

  .react-select__menu-list{
    @media ${bp.phoneOnly} {
      max-height: 150px;
    }
  }

  .react-select__option {
    font-family: ${typo.ovFontFeature};
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    @media ${bp.phoneOnly} {
      max-height: 150px;
    }
  }
`;

const StyledSelect = ({ 
    children,
    value,
    options,
    onChange,
    placeholder,
    label,
    defaultValue,
    isDisabled,
    input
  }) =>{

  return (
    <StyledSelectContainer 
      value={value}
      options={options}
      onChange={onChange}
      className="react-select-container"
      classNamePrefix="react-select"
      placeholder={placeholder}
      label={label}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      {...input}
    >
      {children}
    </StyledSelectContainer>
  )
} 

export default StyledSelect