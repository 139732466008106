export const CONTENTS_SET = 'content/CONTENTS_SET';
export const CONTENT_DELETED = 'content/CONTENT_DELETED';
export const CONTENT_INDEX_SELECTED = 'content/CONTENT_INDEX_SELECTED';

export const setContentsSuccess = (contents) => ({
  type: CONTENTS_SET,
  payload: contents
});

export const deleteContentSuccess = (payload) => ({
  type: CONTENT_DELETED,
  payload
});

export const selectContentIndex = (contentIndex) => ({
  type: CONTENT_INDEX_SELECTED,
  payload: contentIndex
});

export const GET_USER_BY_CONTENT_ID_REQUESTED = 'content/GET_USER_BY_CONTENT_ID_REQUESTED';
export const GET_USER_BY_CONTENT_ID_SUCCESS = 'content/GET_USER_BY_CONTENT_ID_SUCCESS';
export const GET_USER_BY_CONTENT_ID_FAILURE = 'content/GET_USER_BY_CONTENT_ID_FAILURE';

export const requestGetUserId = () => ({
  type: GET_USER_BY_CONTENT_ID_REQUESTED
});

export const getUserIdSuccess = (data) => ({
  type: GET_USER_BY_CONTENT_ID_SUCCESS,
  payload: data
});

export const getUserIdFailure = (message) => ({
  type: GET_USER_BY_CONTENT_ID_FAILURE,
  payload: message
});

export const FILTER_ADD = 'content/FILTER_ADD';
export const FILTER_REMOVE = 'content/FILTER_REMOVE';
export const FILTERS_SHOW_TOGGLE = 'content/FILTERS_SHOW_TOGGLE';

export const addFilterAction = (tag) => ({
  type: FILTER_ADD,
  payload: tag
});

export const removeFilter = (tag) => ({
  type: FILTER_REMOVE,
  payload: tag
});

export const toggleShowFilters = () => ({
  type: FILTERS_SHOW_TOGGLE
});

export const MANAGE_TOGGLE = 'content/MANAGE_TOGGLE';
export const LIGHTBOX_TOGGLE_OPEN = 'content/LIGHTBOX_TOGGLE_OPEN';

export const toggleLightboxOpen = () => ({
  type: LIGHTBOX_TOGGLE_OPEN
});

export const toggleManage = () => ({
  type: MANAGE_TOGGLE
});

