import React from 'react';
import PropTypes from 'prop-types';
import CollegeLogo from '../CollegeLogo';

import {
  ListContainer,
  ListItem,
  ItemIndex,
  ItemPic,
  ImgStyle,
  ItemMeta,
  ItemHeading,
  ItemInfo,
  OVrankingTag,
  StyledLogo
} from './Styled';

const CollegeList = ({ colleges, rankingComponent, push, type}) => {

  return(
    <ListContainer>
      {colleges && colleges.map((college) => (
        <ListItem key={`CollegeList-${college.ID}` } onClick={() => { push(`/college/${college.url_slug}`)}} >
          <ItemIndex>
            <h1>{college.college_rank}</h1>
            {type === "ovranking" && 
              college.Stats.map((stat, index)=>{
                return stat["title"] === "ranks" && <OVrankingTag key={index}><StyledLogo/>{stat.data[1] ? stat.data[1].value : "-"}</OVrankingTag>
              })
            }
          </ItemIndex>
          <ItemPic>
            <CollegeLogo
              style={ImgStyle}
              name={college.name}
            />
          </ItemPic>
          <ItemMeta>
            <ItemHeading>{college.name}</ItemHeading>
            <ItemInfo>{rankingComponent(college)}</ItemInfo>
          </ItemMeta>
        </ListItem>
      ))}
    </ListContainer>
  )
};

CollegeList.propTypes = {
  colleges: PropTypes.arrayOf(PropTypes.shape({
    ID: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    logo: PropTypes.any,
  })),

  rankingComponent: PropTypes.func.isRequired, // Ranking description render prop
};

export default CollegeList;
