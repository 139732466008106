import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Item from './Item';
import { ListManager } from './Styled';

const List = ({
  items,
  onDragEnd,
  onRemove,
}) => (
    <ListManager>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            // style={getListStyle(snapshot.isDraggingOver)}
            >
              {items && items.map((item, i) => (
                <Draggable key={item.ID} draggableId={`draggable-${item.ID}`} index={i}>

                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Item id={item.ID} name={item.name} featuredImage={item.image} logo={item.logo} onRemove={onRemove}/>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </ListManager>
  );

export default List;