import { MAPBOX_KEY } from '../config/env';

const mbxClient = require('@mapbox/mapbox-sdk');
const geocodingService = require('@mapbox/mapbox-sdk/services/geocoding');


const client = mbxClient({ accessToken: MAPBOX_KEY });
const geocoding = geocodingService(client);

export default {

  query(query) {

    return geocoding.forwardGeocode({
        query,
        countries: ["US"],
        //types: ["region", "district", "locality", "place"],
      })
      .send()
      
      .then(mapi => {
        const { body: { features } } = mapi;
        return features.map(place => ({
          value: { value: place.place_name, coordinates: place.center },
          label: place.place_name,
        }))
      });

  }
};