import React from 'react';
import { color, typo, bp } from '../../styles';
import { Search as SearchIcon } from 'styled-icons/material/Search';
import { Close as CloseIcon } from 'styled-icons/material/Close';
import styled, {keyframes} from 'styled-components';
import { Menu } from 'styled-icons/feather/Menu';
import { LogoHorizontal } from '../../components';
import { Link } from 'react-router-dom';

export const showNavBar = keyframes`
  from {
    transform: translateY(-65px);
  }

  to {
    transform: translateY(0);
  }
`;

export const Navbar = styled.nav.attrs({})`
  display: inline-block;
  min-height: 48px;
  width: 100%;
  background: ${color.ovWhite};
  padding: 10px 10px 5px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.47) 0px 1px 2px 0px;
  -moz-box-shadow: rgba(0, 0, 0, 0.47) 0px 1px 2px 0px;
  box-shadow: rgba(0, 0, 0, 0.47) 0px 1px 2px 0px;
  position: absolute;
  z-index: 999;
  &.home{
    background-color: transparent;
    box-shadow: none;
  }
  &.fixed-nav{
    position: fixed;
    top: 0px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.47) 0px 1px 2px 0px;
    -moz-box-shadow: rgba(0, 0, 0, 0.47) 0px 1px 2px 0px;
    box-shadow: rgba(0, 0, 0, 0.47) 0px 1px 2px 0px;
    background: ${color.ovWhite};
    animation: ${showNavBar} .2s linear;
  }
`;

export const Logo = styled(LogoHorizontal)`
  display: inline-block;
  position: relative;
  width: 156px;
  height: 100%;
  cursor: pointer;
  padding: 3.5px 10px;
  ${Navbar}.unfixed-nav.home &{
    path, polygon{
      fill: ${color.ovWhite};
    }
  }
`;

export const LogoLink = () => (
  <Link to="/">
    <Logo />
  </Link>
)

export const MenuIcon = styled(Menu)`
  display: none;
  cursor: pointer;
  color: ${color.ovBlue};
  height: 30px;
  width: 29px;
  margin: 5px;
  float: right;
  &:hover, &:focus {
    text-decoration: none;
    color: ${color.ovBlueLight};
  }
  @media ${bp.phoneOnly} {
    display: inline-block;
  }
  ${Navbar}.unfixed-nav.home &{
    color: ${color.ovWhite};
  }
`;

export const NavGroup = styled.div`
  display: block;
  z-index: 2;
  float: right;
  @media ${bp.phoneOnly} {
    display: ${p => p.show ? 'inline-block' : 'none'};
    position: absolute;
    padding: 8px;
    background: #fff;
    border: 2px solid ${color.ovBlue};
    right: 0;
    top: 64px;
    width: 150px;
    border-radius: 0px 0px 0px 4px;
    border-right: none;
    ${Navbar}.form-open &{
      display: none;
      height: 180px;
    }
  }
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  display: block; 
  padding: 14px 16px;
  float: right;
  color: ${color.ovGrey};
  font-size: ${typo.navLinkSize};
  font-family: ${typo.ovFontFeature};
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  &:hover, &:focus {
    text-decoration: none;
    color: ${color.ovBlue};
  }
  ${Navbar}.unfixed-nav.home &{
    color: ${color.ovWhite};
    &:hover, &:focus {
      color: ${color.ovYellow};
    }
    @media ${bp.phoneOnly} {
      color: ${color.ovBlue};
    }
  }
`;

export const NavLinkButton = styled(Link)`
  float: right;
  border-radius: 2px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: ${color.ovYellow};
  font-size: 18px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  line-height: 0.56;
  letter-spacing: normal;
  font-family: ${typo.ovFontFeature};
  text-align: center;
  color: ${color.ovWhite};
  margin: 12px 16px 8px 10px;
  padding: 4px 15px 7px;
  line-height: 0.8;
  text-decoration: none;
  transition: all .1s;
  &:hover, &:focus, &:active{
    text-decoration: none;
    transition: all .1s;
    color: ${color.ovWhite};
    background-color: ${color.ovBlue};
  }
`;

export const NavLinkA = styled.a`
  text-decoration: none;
  display: block; 
  padding: 14px 16px;
  float: right;
  color: ${color.ovGrey};
  font-size: ${typo.navLinkSize};
  font-family: ${typo.ovFontFeature};
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  cursor: pointer;
  &:hover, &:focus {
    text-decoration: none;
    color: ${color.ovBlue};
  }
  ${Navbar}.unfixed-nav.home &{
    color: ${color.ovWhite};
    &:hover, &:focus {
      color: ${color.ovYellow};
    }
    @media ${bp.phoneOnly} {
      color: ${color.ovBlue};
    }
  }
`;

export const FalseInputContainer = styled.div`
  float: right;
  position: relative;
  @media ${bp.phoneOnly} {
    float: none;
    position: relative;
  }
`;

export const FalseInput = styled.div`
  width: 100%;
  @media ${bp.tabletPortUp} {
    width: 158px;
  }
  height: 28px;
  border-radius: 7px;
  border: solid 1px ${color.ovGrey};
  margin: 11px 0;
  padding: 0px 0 0 31px;
  cursor: text;
  &:before{
    content: "Search";
    font-family: ${typo.ovFontFeature};
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: ${color.ovGrey};
    position: absolute;
  }
  ${Navbar}.unfixed-nav.home &{
    display: none;
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  top: 14px;
  left: 5px;
  height: 22px;
  width: 22px;
  color: ${color.ovBlue};
  display: none;
  @media ${bp.phoneOnly} {
    top: 4px;
  }
  ${Navbar}.fixed-nav.home &{
    display: block;
  }
  ${Navbar}.landing &{
    display: block;
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  z-index: 99;
  top: 30px;
  right: 30px;
  height: 22px;
  width: 22px;
  color: ${color.ovBlack};
  cursor: pointer;
`;

export const showSearchNavbar = keyframes`
  from {
    transform: translateY(-150px);
  }

  to {
    transform: translateY(0);
  }
`;

export const SearchNavbarFormContainer = styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0px;
  left: 0;
  display: none;
  ${Navbar}.form-open &{
    display: block;
    animation: ${showSearchNavbar} .2s linear;
  }
`;