import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from '../../styles'

const H1 = styled.h1`
  font-family: Molde;
  font-size: 40px;
  font-weight: 100;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlue};
  margin: 0 0 17px 0;
`;

const BigTitle = ({
  bigTitleLabel,
  className
}) => {
  return(
    <H1 className={className}>{bigTitleLabel}</H1>
  )
}

BigTitle.propTypes = {
  bigTitleLabel: PropTypes.string
};

export default BigTitle