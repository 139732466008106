import styled from 'styled-components';
import { color, typo } from '../../../../styles'

export const PostAuthorContainer = styled.div`
	padding: 25px 0 0 0;
`;

export const PostAuthorTitle = styled.p`
	font-size: 34px;
	font-weight: 300;
	font-style: normal;
	font-stretch: condensed;
	line-height: 0.74;
	letter-spacing: normal;
	text-align: left;
	font-family: ${typo.ovFontFeature};
	color: ${color.ovBlack}; 
`;

export const AuthorPicContainer = styled.div`
	width: 88px;
	height: 88px;
	border-radius: 22px;
	overflow: hidden;
	border: solid 1px ${color.ovGrey};
	img{
		width: 100%;
		height: 100%;
	}
`;

export const PostAuthorMetaContainer = styled.div`
	display: grid;
	grid-template-columns: 100px auto;
   	grid-template-rows: 1fr;
   	padding: 10px 0;
`;

export const AuthorMetaName = styled.p`
	font-size: 24px;
	font-weight: 600;
	font-style: normal;
	font-stretch: condensed;
	line-height: 1;
	letter-spacing: normal;
	text-align: left;
	font-family: ${typo.ovFontFeature};
	color: ${color.ovBlack};
	margin: 10px 0 0 0;
`;

export const AuthorMetaTitle = styled.p`
	font-size: 18px;
	font-weight: 600;
	font-style: normal;
	font-stretch: condensed;
	line-height: 1.39;
	letter-spacing: normal;
	text-align: left;
	font-family: ${typo.ovFontFeature};
	color: ${color.ovBlack};
`;

export const PostAuthorBioContainer = styled.p`
	font-size: 18px;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.61;
	letter-spacing: normal;
	text-align: left;
	font-family: ${typo.ovFontSerif};
	color: ${color.ovBlack};
	margin-top: 13px;
`;