import * as types from './actionsTypes';

export const setCoach = (coachObject) => ({
  type: types.SET_COACH,
  payload: coachObject.value
});

export const reviewAnotherCoach = () => ({
  type: types.REVIEW_ANOTHER_COACH
});

export const deleteAdminReviewRequest = () => ({
  type: types.DELETE_ADMIN_REVIEW_REQUESTED
});

export const deleteAdminReviewSuccess = (id) => ({
  type: types.DELETE_ADMIN_REVIEW_SUCCESS,
  payload: id
});

export const deleteAdminReviewFailure = () => ({
 type: types.DELETE_ADMIN_REVIEW_FAILURE
});

export const nextStage = () => ({
  type: types.NEXT_STAGE
})

export const previousStage = () => ({
  type: types.PREV_STAGE
})

export const setTitle = (data) => ({
  type: types.SET_TITLE,
  payload: data.title
})

export const setAnonymous = (data) => ({
  type: types.SET_ANONYMOUS,
  payload: data.is_anonymous
})

export const setAnswer = (answer) => ({
  type: types.SET_ANSWER,
  payload: answer
})

export const setComment = (commentAndIndex) => ({
  type: types.SET_COMMENT,
  payload: commentAndIndex
})

export const getReviewCoachesRequest = () => ({
  type: types.GET_REVIEW_COACHES_REQUESTED
})

export const getReviewCoachesSuccess = (data) => ({
  type: types.GET_REVIEW_COACHES_SUCCESS,
  payload: data
})

//const getReviewCoachesFailure = () => ({
//  type: types.GET_REVIEW_COACHES_FAILURE
//})

export const setupReviewQuestions = (isCurrentCollegeStudent, role) => ({
  type: types.SETUP_REVIEW_QUESTIONS,
  payload: {
    isCurrentCollegeStudent,
    role
  },
})