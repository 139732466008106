import React, { useState, useEffect } from 'react';
//import PropTypes from 'prop-types';

import StyledInput from '../../components/styledInput'
import StyledSelect from '../../components/styledSelect/SmallSelect'
import CollegeSelect from '../../components/styledSelect'
import PlacesAutocomplete from '../../components/PlacesAutocomplete';
import { 
ErrorMessage,
AttractionCardInput,
} from './Styled';


export const inputField = ({ input, label, type, meta: { touched, active, error, warning } }) => {
  return (
    <div>
      <StyledInput
        {...input}
        placeholder={label}
        type={type}
      />
      {touched && ((error && <ErrorMessage>{error}</ErrorMessage>) || (warning && <ErrorMessage>{warning}</ErrorMessage>))}
    </div>
  );
};
 

export const textArea = ({ input, label, type }) => (
  <AttractionCardInput
    { ...input }
    type={type}
    placeholder={label}
  />
);

export const selectField = ({ input, options, clearable, meta: { touched, active, error }, label }) => {
  const selected = options && options.find(o => o.value === input.value);
 
  return (
    <div>
      <StyledSelect
        instanceId={input.name}
        {...input}
        value={selected}
        options={options}
        clearable={clearable}
        onBlur={() => input.onBlur}
        placeholder={label}
      />
      {touched && !active && error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};


export const collegeField = ({ input, options, clearable, collegeRefer, meta: { touched, active, error }, label }) => {
  const selected = options && options.find(o => o.value === input.value);
 
  return (
    <div>
      <CollegeSelect
        instanceId={input.name}
        {...input}
        value={selected || collegeRefer}
        options={options}
        clearable={clearable}
        onBlur={() => input.onBlur}
        placeholder={label}
      />
      {touched && !active && error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};

export const placesField = ({ input, label, type, meta: { touched, active, error, warning }, initialValues }) => {
  useEffect(() => {
    if (initialValues && !inputValue) {
      setInitialValue(initialValues)
    };
  }, [initialValues]);

  const [initialValue, setInitialValue] = useState(false);
  const [inputValue, setInputValue] = useState(false);

  const handleInput = () => {
    setInitialValue(false);
    setInputValue(true);
  }
  return (
    <div >
      {initialValue && !inputValue?
        <PlacesAutocomplete
        {...input}
        value={{value: initialValues}}
        placeholder={label}
        onFocus={() => handleInput()}
        /> : (!initialValue || inputValue) && (
          <div>
            <PlacesAutocomplete
              {...input}
              placeholder={label}
            />
          </div>
        )
      }
      
      {touched && ((error && <ErrorMessage>{error}</ErrorMessage>) || (warning && <ErrorMessage>{warning}</ErrorMessage>))}
    </div> 
  )
}
// end of InputField

const stateName = state => (
  state.match(/[a-zA-Z\s]+/)[0]
);

const postalCode = state => (
  /\d+/.test(state) ? state.match(/\d+/)[0] : ''
);

export const parseLocation = (location, time) => {
  const splitLocation = location.value && location.value.split(/,/);
  let address;
  if (splitLocation.length === 2 ) {
    const [state, country] = splitLocation
    address = {
      address_line_1: null, 
      address_line_2: null,
      city: null,
      CreatedAt: time,
      UpdatedAt: null,
      DeletedAt: null,
      state,
      postal_code: null,
      country,
    };
  };

  if (splitLocation.length === 3 ) {
    const [city, state, country] = splitLocation
    address = {
      address_line_1: null, 
      address_line_2: null,
      city,
      CreatedAt: time,
      UpdatedAt: null,
      DeletedAt: null,
      state,
      postal_code: null,
      country,
    };
  };

  if (splitLocation.length === 4 ) {
    const [address1, city, state, country] = splitLocation
    address = {
      address_line_1: address1, 
      address_line_2: null,
      city,
      CreatedAt: time,
      UpdatedAt: null,
      DeletedAt: null,
      state: stateName(state),
      postal_code: postalCode(state),
      country,
    };
  };

  if (splitLocation.length === 5 ) {
    const [address1, address2, city, state, country] = splitLocation
    address = {
      address_line_1: address1, 
      address_line_2: address2,
      city,
      CreatedAt: time,
      UpdatedAt: null,
      DeletedAt: null,
      state: stateName(state),
      postal_code: postalCode(state),
      country,
    };
  };
  
  return {
    address,
    geo_json: {
      type: 'Point',
      coordinates: location.coordinates,
    },
    target_type: 'attractions',
  };
};

export const findCollegeRefer = (id, colleges) => {
  const collegeRefer = colleges.find(college => college.ID === id);
  return {label: collegeRefer.formal_name, value: collegeRefer.ID};
};

export const fixAddress = address => {
  const {address_line_1, address_line_2, city, state, postal_code, country } = address;
  return `${address_line_1 && address_line_1 + ','} ${address_line_2 && address_line_2 + ','} ${city}, ${state} ${postal_code}, ${country}`;
};