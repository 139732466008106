import styled from 'styled-components';
import { color, typo } from '../../../styles';

export const CollegeCoachCardBox = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0, 0.16);
  border: solid 1px rgba(0,0,0, 0.16);
  background-color: ${color.ovWhite};
  padding: 11px 7px;
  min-height: 45px;
  align-self: start;
  display: grid;
  grid-template-columns: 95px auto;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  line-height: 1;
  font-family: ${typo.ovFontFeature};
  position: relative;
  cursor: pointer;
  transition: all .1s;
  &:hover{
      transition: all .1s;
      transform: scale(1.04);
  }
`;

export const CollegeCoachCardData = styled.div`
  position: relative;
`;

export const CollegeCoachCardBoxPicture = styled.div`
  border-radius: 50%;
  background-image: ${props => `url("${props.bgURL}")`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 75px;
  background-color: ${color.ovGreyLight};
  margin-right: 20px;
`;

export const CollegeCoachCardName = styled.div`
  font-size: 25px;
  color: ${color.ovBlue};
  margin-top: 5px;
`;
export const CollegeCoachCardTitle = styled.div`
  font-size: 16px;
  color: ${color.ovBlack};
`;
export const CollegeCoachCardReviews = styled.div`
  position: absolute;
  bottom: 0px;
`;

export const CollegeCoachCardSpan = styled.span`
  color: ${color.ovGrey};
`;

