import styled from 'styled-components';
import { color, typo, bp } from '../../../styles';

export const CollegeAboutBox = styled.div`
  display: grid;
  grid-template-columns: 128px auto;
  padding: 20px 0;
  grid-gap: 16px;
  @media ${bp.phoneOnly} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`;

export const CollegeAboutLogo = styled.div`
  border-radius: 5px;
  border: solid 1px ${color.ovGreyDark};
  height: 103px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${bp.phoneOnly} {
    width: 50%;
    margin: 0 auto;
  }
`;

export const CollegeAboutTitle = styled.div`
  font-family: ${typo.ovFontFeature};
  color: ${color.ovBlack};
  font-size: 25px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  line-height: 1.8;
`;

export const CollegeAboutText = styled.div`
  max-height: 60px;
	overflow: hidden;
  transition: height 1s linear;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  font-family: ${typo.ovFontSerif};
  color: ${color.ovBlack};
	&[data-show=true]{
    max-height: none;
    overflow: visible;
    transition: height 1s linear;
  }
`;

export const CollegeAboutReadMore = styled.a`
  font-family: ${typo.ovFontSerif};
  color: ${color.ovBlue};
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  cursor: pointer;
`;

export const ImgStyle = {
  height: '100%',
  maxWidth: '128px',
  width: 'auto',
  padding: '10px',
  imageOrientation: 'from-image',
};

