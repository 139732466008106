import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPost } from '../../../modules/blog/blog';
import { isFetchingSelector, currentPostSelector } from '../../../modules/blog/selectors';

import Post from './Post';
import { requestLogin } from "../../../modules/user";

const mapStateToProps = (state) => ({
  isFetching: isFetchingSelector(state),
  post: currentPostSelector(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getPost,
  requestLogin,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Post)