import React from 'react';
import CollegeCardBar from '../CollegeCardBar';

import {
  CollegeCardBox,
  CollegeCardBarContent
} from './Styled';

const CollegeCard = ({
  collegeCardTitle,
  children,
  type,
  name,
  handleClick
}) => {
  return(
    <CollegeCardBox>
      <CollegeCardBar collegeCardTitle={collegeCardTitle} type={type} name={name} handleClick={handleClick}/>
      <CollegeCardBarContent>
        {children}
      </CollegeCardBarContent>
    </CollegeCardBox>
  )
};

export default CollegeCard;