import React from 'react';

import Spinner from 'react-spinkit';
import { PageContainer } from '../../components';

import {
  PageContent,
} from './styled';

const Loader = () => (
  <PageContainer>
    <PageContent>
      <Spinner fadeIn="none" name="cube-grid" />
    </PageContent>
  </PageContainer>
);

export default Loader;
