import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import _ from 'lodash';

// Redux stuff
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { listCoachesIfEmpty, visibleCoaches } from '../../modules/coach';
import { listCollegesIfEmpty, visibleColleges } from '../../modules/college';
import { withLifecycleActions } from 'react-redux-lifecycle';
// Components
import { PageContainer } from '../../components';
import Spinner from 'react-spinkit';
import CollegeCoachSearchFilterForm from './searchFilter';

import ResultsPage from './resultsPage';

import {
  FullWithContent,
  PageContent,
  FilterContainer,
  FilterNav,
  MidTitleButton,
  MidTitleButtonLink,
  TitleContainer,
  AboutHeading,
  NoResultsHeading,
  AboutHr,
  ResultsContainer
} from './Styled'

const filterList = (filter, _coaches, _colleges) => {
  const coaches = _(_coaches)
    .map(c => ({ ...c, type: 'coach' }))
    .sortBy('display_name')
    .value();

  const colleges = _(_colleges)
    .map(c => ({ ...c, type: 'college', display_name: c.formal_name }))
    .sortBy('display_name')
    .value();

  switch (filter) {
    case 'coaches':
      return coaches;

    case 'colleges':
      return colleges;

    default: {
      const all = _([
        ...coaches,
        ...colleges,
      ])
      .sortBy('display_name')
      .value();

      return all;
    }
  }
};

const PAGE_SIZE = 20;
const getPage = (page, content, size = PAGE_SIZE) => {
  const chunks = _(content)
    .slice(0, (page * size) + size)
    .value();

  return chunks || [];
};

const getPageCount = (content, size = PAGE_SIZE) => Math.round(content.length / size);

// End Styled components
//
class SearchResult extends Component {

  constructor() {
    super();
    this.state = {
      showFilteredContent: "all",
      filteredList: [],

      page: 0,
    }

    this.handleShowAll = this.handleShowAll.bind(this);
    this.handleShowOnlyCoaches = this.handleShowOnlyCoaches.bind(this);
    this.handleShowOnlyColleges = this.handleShowOnlyColleges.bind(this);
    this.handleLoadNextPage = this.handleLoadNextPage.bind(this);
  };

  handleShowAll() {
    const { filteredCoaches, filteredColleges } = this.props;
    const filteredList = filterList('all', filteredCoaches, filteredColleges);
    this.setState({
      showFilteredContent: "all",
      filteredList,
      page: 0,
    });
  }

  handleShowOnlyCoaches() {
    const { filteredCoaches, filteredColleges } = this.props;
    const filteredList = filterList('coaches', filteredCoaches, filteredColleges);
    this.setState({
      showFilteredContent: "coaches",
      filteredList,
      page: 0,
    });
  }

  handleShowOnlyColleges() {
    const { filteredCoaches, filteredColleges } = this.props;
    const filteredList = filterList('colleges', filteredCoaches, filteredColleges);
    this.setState({
      showFilteredContent: "colleges",
      filteredList,
      page: 0,
    });
  }

  handleLoadNextPage() {
    this.setState(prev => ({ page: prev.page + 1 }));
  }

  componentDidUpdate(prev) {
    const { filteredCoaches, filteredColleges } = this.props;
    if (prev.filteredCoaches !== filteredCoaches || prev.filteredColleges !== filteredColleges) {
      const { showFilteredContent } = this.state;
      const filteredList = filterList(showFilteredContent, filteredCoaches, filteredColleges);
      this.setState({ filteredList });
    }
  }

  componentDidMount(){
    if(window.location.hash === "#colleges"){
      this.handleShowOnlyColleges();
    }
    if(window.location.hash === "#coaches"){
      this.handleShowOnlyCoaches();
    }
  }

  render(){
    const {
      filteredCoaches,
      filteredColleges,
      isFetchingCoaches,
      isFetchingCollege,
      searchForm,
      push
    } = this.props;
    const filteredCollegesNotEmpty = filteredColleges && Object.keys(filteredColleges).length !== 0;

    const filteredCoachesNotEmpty = filteredCoaches && Object.keys(filteredCoaches).length !== 0;

    const { page, filteredList } = this.state;
    const currentPage = getPage(page, filteredList);

    return(
      <div>
        <Helmet>
          <title>Search - OfficialVisit</title>
          <meta name="description" content="Search colleges, college football teams, or college football coaches to learn more about each based on reviews from former students and players!" />
        </Helmet>
        <FullWithContent>
          <TitleContainer>
            <FilterContainer>
              <CollegeCoachSearchFilterForm/>
              <FilterNav>

                <MidTitleButton>
                  <MidTitleButtonLink
                    className={
                      (this.state.showFilteredContent === "all" && "selected")
                      + ' ' +
                      ( (!filteredCollegesNotEmpty && filteredCoachesNotEmpty) || (filteredCollegesNotEmpty && !filteredCoachesNotEmpty) || (!filteredCoachesNotEmpty && !filteredCollegesNotEmpty) ? "disable" : "active")
                    }
                      onClick={filteredCollegesNotEmpty && filteredCoachesNotEmpty ? this.handleShowAll : null}
                    >
                    All results
                  </MidTitleButtonLink>
                </MidTitleButton>

                <MidTitleButton>
                  <MidTitleButtonLink
                    className={
                      (this.state.showFilteredContent === "colleges" && "selected")
                      + ' ' +
                      (filteredCollegesNotEmpty ? "active" : "disable")}
                    onClick={filteredCollegesNotEmpty ? this.handleShowOnlyColleges : null}
                    >
                    Colleges
                  </MidTitleButtonLink>
                </MidTitleButton>

                <MidTitleButton>
                  <MidTitleButtonLink
                    className={
                      (this.state.showFilteredContent === "coaches" && "selected")
                      + ' ' +
                      (filteredCoachesNotEmpty ? "active" : "disable")}
                    onClick={filteredCoachesNotEmpty ? this.handleShowOnlyCoaches : null}
                    >
                    Coaches
                  </MidTitleButtonLink>
                </MidTitleButton>

              </FilterNav>
            </FilterContainer>
          </TitleContainer>
        </FullWithContent>
        <PageContainer>
          <PageContent>
            <TitleContainer>
              <AboutHeading>{searchForm && searchForm.values ? `Search Result for ${searchForm.values.text}` : "All Colleges and Coaches"}</AboutHeading>
              <AboutHr />
            </TitleContainer>
            { (isFetchingCollege || isFetchingCoaches) ?  <Spinner fadeIn="none" name="cube-grid" /> :
            <ResultsContainer>
              <div>
                <ResultsPage
                  filter={this.state.showFilteredContent}
                  list={currentPage}
                  push={push}
                  current={page}
                  pages={getPageCount(filteredList)}
                  onLoadNext={this.handleLoadNextPage}
                />
                {!filteredCoachesNotEmpty && !filteredCollegesNotEmpty &&
                  <TitleContainer>
                    <NoResultsHeading>Sorry, no results were found.</NoResultsHeading>
                  </TitleContainer>
                }
                {(this.state.showFilteredContent === "colleges" && !filteredCollegesNotEmpty && filteredCoachesNotEmpty) &&
                  <TitleContainer>
                    <NoResultsHeading>Sorry, no results were found in Colleges.</NoResultsHeading>
                  </TitleContainer>
                }
                {(this.state.showFilteredContent === "coaches" && !filteredCoachesNotEmpty && filteredCollegesNotEmpty) &&
                  <TitleContainer>
                    <NoResultsHeading>Sorry, no results were found in Coaches.</NoResultsHeading>
                  </TitleContainer>
                }
              </div>
            </ResultsContainer>
             }
          </PageContent>
        </PageContainer>
      </div>
    )
  }
}

SearchResult.propTypes = {
  listCoachesIfEmpty: PropTypes.func.isRequired,
  isFetchingCoaches: PropTypes.bool.isRequired,
  isFetchingCollege: PropTypes.bool.isRequired
};

const mapStateToProps = ({ user, form, coach, college }) => ({
  searchForm: form.collegeCoachSearchFilter,
  filteredCoaches: visibleCoaches(coach.coaches, form.collegeCoachSearchFilter),
  filteredColleges: visibleColleges(college.colleges, form.collegeCoachSearchFilter),
  isFetchingCoaches: coach.isFetchingCoaches,
  isFetchingCollege: college.isFetchingCollege,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  listCoachesIfEmpty,
  listCollegesIfEmpty,
  push,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withLifecycleActions({ componentWillMount: [listCoachesIfEmpty, listCollegesIfEmpty]})(SearchResult))
