import styled from 'styled-components';

import { Heading, Button } from 'rebass';
import BigTitle from '../../components/bigTitle'
import MidTitle from '../../components/MidTitle';
import { Edit } from 'styled-icons/fa-solid/Edit';

import Scroll from 'react-scroll';

import { color, typo, bp } from '../../styles'

var Link = Scroll.Link;

export const PageContent = styled.div`
  grid-column: span 12;
  position: relative;
`;

export const CoachPageContent = styled.div`
  grid-column: span 12;
  padding: 10px 20px;
  border-top: 1px solid ${color.ovGrey};
`;

export const AboutContainer = styled.div`
  background: ${color.ovWhite};
  border: solid 2px ${color.ovGrey};
  padding: 14px 8px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 29px auto auto;
  position: relative;
  height: 100%;
  max-height: 736px;
  overflow: hidden;
  transition: height 1s linear;
  &[data-show=true]{
    max-height: none;
    overflow: visible;
    transition: height 1s linear;
    padding-bottom: 80px;
  }
`;

export const LeaveReviewContainer = styled.div`
  grid-template-rows: auto auto;
  min-height: 178px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: ${color.ovWhite};
  padding: 15px 11px;
  background-image: url(${props => props.bgUrl});
  background-size: 50%;
  background-position: right bottom;
  background-repeat: no-repeat;
`;

export const AboutHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: .8;
  letter-spacing: normal;
  text-align: left;
  padding: 0 10px 10px 10px;
  @media ${bp.phoneOnly} {
    font-size: 1.8rem;
  }
`;

export const LeaveReviewHeading = styled(Heading)`
  font-family: ${typo.ovFontFeature};
  font-size: 20px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovBlack};
  padding: 0 30px 30px 0;
`;

export const LeaveReviewButton = styled.button`
  font-family: ${typo.ovFontFeature};
  height: 29px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(5, 5, 5, 0.16);
  background-color: ${color.ovYellow};
  font-size: 18px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${color.ovWhite};
`;

export const TitleContainer = styled.div`
  grid-column: span 12;
`;

export const AboutHr = styled.div`
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid ${color.ovGrey};
  margin: 5px 0 0;
  padding: 0;
`;

///

export const DetailsSubHeading = styled(Heading)`
  font-family: ${typo.ovFontFeature};
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovGreyDark};
`;

export const CollegeBadge = styled.div`
  margin-bottom: 0px;
  position: relative;
`;

export const StyledPageContent = styled(PageContent)`
  cursor: pointer;
  text-align: center;
`;

export const OVButtonRight = styled(Button)`
  border: 1px solid ${color.ovGreyDark};
  font-family: ${typo.ovFontFeature};
  font-size: 16px;
  background: ${color.ovYellow};
  border-radius: 4px;
  color: ${color.ovWhite};
  cursor: pointer;
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  box-shadow: 0 3px 6px 0 rgba(5, 5, 5, 0.16);
  padding: 5px 35px;
  margin: 10px 0 0 0;
  transition: all .1s;
  &:hover, &:focus {
    text-decoration: none;
    transition: all .1s;
    background: ${color.ovBlueLight};
    border-color: ${color.ovBlueLight};
  }
  ${StyledPageContent}:hover &{
    text-decoration: none;
    transition: all .1s;
    background: ${color.ovBlueLight};
    border-color: ${color.ovBlueLight};
  }
`;

export const StyledEdit = styled(Edit)`
  width: 90px;
  color: ${color.ovYellow};
  margin: 20px auto;
  position: relative;
  top: 0px;
  display: block;
  transition: all .1s;
  ${StyledPageContent}:hover &{
    color: ${color.ovBlue};
    transition: all .1s;
  }
`;

export const HalfPageContent = styled.div`
  grid-column: span 12;
  @media ${bp.tabletLandUp} {
    grid-column: span 6;
  }
`;

export const ReviewsPageContent = styled.div`
  grid-column: span 12;
  text-align: center;
  position: relative;
  top: 0px;
  height: 267px;
  @media ${bp.tabletLandUp} {
    grid-column: span 4;
    position: sticky;
    position: -webkit-sticky;
    top: 85px;
  }
`;

export const MostPageContentLeft = styled.div`
  grid-column: span 12;

  @media ${bp.tabletLandUp} {
    grid-column: span 8;
  }
`;

// About and Coaches display
export const CoachSeason = styled(Heading)`
  color: ${color.ovBlack};
  font-family: ${typo.ovFontFeature};
  font-size: 1.6rem;
  line-height: 1;
  padding: 0 1rem;
  margin: 1rem 0;

  @media ${bp.phoneOnly} {
    font-size: 2rem;
  }
`;

export const CoachSeasonTitle = styled.h4`
  text-align: left;
  padding: 0 1rem;
  font-family: ${typo.ovFontFeature};
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 0.94;
  letter-spacing: normal;
  color: ${color.ovBlack};
  margin-bottom: 22px;
`;


export const CoachContainer = styled.div`
  display:block;
`;

export const CoachItem = styled.div`
  cursor: pointer;
  display:grid;
  padding: 1.2rem 1rem 0.2rem;
  border-radius: .2rem;
  grid-template-columns: 60% 40%;
  &:hover {
    background: ${color.ovTransparentBlue};
  }
  p{
    padding: 0 10px 0 0;
  }
`;

export const CoachLabel = styled.span`
  display: inline;
  color: ${color.ovBlack};
  font-family: ${typo.ovFontSerif};
  font-size: 15px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
`;


export const CoachText = styled(CoachLabel)`
  border-bottom: 1px solid black;
  line-height: 1.4;
`;

export const FullWithContent = styled.div`
  background-color: ${color.ovWhite};
  margin-top: -38px;
  min-height: 158px;
  border-bottom: solid 1px ${color.ovGreyDark};
  padding: 20px 20px 0 20px;
  margin-bottom: 42px;
`;

export const BigTitleGrey = styled(BigTitle)`
  color: ${color.ovGreyDarker};
  margin-bottom: 5px;
`;

export const BarDivider = styled.span`
  display: inline-block;
  padding: 0 18px;
`;

export const CollegeNav = styled.div`
  margin: 20px 0 6px 0;
`;
export const MidTitleButton = styled(MidTitle)`
  display: inline;
`;

export const MidTitleButtonLinkWithScrollTo = styled(Link)`
  border-right: 1px solid ${color.ovBlue};
  padding: 0 15px;
  cursor: pointer;
  &.selected{
  border-bottom: 2px solid ${color.ovBlue};
  }
  &:hover{
    text-decoration: none;
    border-bottom: 2px solid ${color.ovBlue};
  }
`;
export const MidTitleButtonLink = styled.a`
  border-right: 1px solid ${color.ovBlue};
  padding: 0 15px;
  cursor: pointer;
  &.selected{
  border-bottom: 2px solid ${color.ovBlue};
  }
  &:hover{
    text-decoration: none;
    border-bottom: 2px solid ${color.ovBlue};
  }
`;
