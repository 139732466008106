import React, { useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  getOtherUserIfEmpty,
  getOtherProfileIfEmpty,
  getOtherContributionsIfEmpty
} from '../../modules/otherUser';
import { listCollegesIfEmpty } from '../../modules/college';
import UserContent from '../profile/userContent';
import Loader from '../profile/loader';
import UserContributions from './UserContributions';
import { getTargetAttractionsByContributionsIfEmpty } from "../../modules/attraction/attraction";
import { withLifecycleActions } from "react-redux-lifecycle";
import { getTargetCoachesByContributionsIfEmpty } from "../../modules/coach";
import { requestLogin } from "../../modules/user";

const Users = ({
  profile,
  user,
  isFetching,
  push,
  isAuthenticated,
  isFetchingReviews,
  contributions,
  colleges,
  attractions,
  coaches,
  getTargetAttractionsByContributionsIfEmpty,
  getTargetCoachesByContributionsIfEmpty,
}) => {
  // Loader
  if (isFetching) {
    return (
      <Loader/>
    )
  }

  const [isLoaded, setIsLoaded] = useState(false);
  // load attractions and coaches on contributions loaded
  useEffect(() => {
    if (contributions.length && !isLoaded) {
      getTargetAttractionsByContributionsIfEmpty(user.ID, contributions);
      getTargetCoachesByContributionsIfEmpty(user.ID, contributions);
    } 

    if (attractions !== false && coaches !== false) {
      setIsLoaded(true);
    }
  }, [contributions, attractions, coaches]);

  return (
    <div>
      <Fragment>
        <UserContent
          user={ user }
          profile={ profile }
        />
        { !isLoaded && <Loader/> }
        { isLoaded &&
        <UserContributions
          user={ user }
          contributions={ contributions }
          push={ push }
          isAuthenticated={ isAuthenticated }
          isFetchingReviews={ isFetchingReviews }
          targetAttractions={ attractions }
          colleges={ colleges }
          targetCoaches={ coaches }
        /> }
      </Fragment>
    </div>
  )
};

Users.propTypes = {
  user: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  requestLogin: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  getOtherUserIfEmpty: PropTypes.func.isRequired,
  getOtherProfileIfEmpty: PropTypes.func.isRequired,
  listCollegesIfEmpty: PropTypes.func.isRequired,
  getTargetAttractionsByContributionsIfEmpty: PropTypes.func.isRequired,
  getTargetCoachesByContributionsIfEmpty: PropTypes.func.isRequired,
  getOtherContributionsIfEmpty: PropTypes.func.isRequired
};

const mapStateToProps = ({ user, otherUser, attraction, coach, college }) => ({
  user: otherUser.targetUser,
  profile: otherUser.targetProfile,
  isAuthenticated: user.isAuthenticated,
  isFetching: otherUser.isFetching,
  contributions: otherUser.targetUserContributions,
  colleges: college.colleges,
  isFetchingReviews: college.isFetchingReviews,
  attractions: attraction.targetAttractions,
  coaches: coach.targetCoaches,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getOtherUserIfEmpty,
  getOtherProfileIfEmpty,
  listCollegesIfEmpty,
  getTargetAttractionsByContributionsIfEmpty,
  getTargetCoachesByContributionsIfEmpty,
  getOtherContributionsIfEmpty,
  push,
  requestLogin
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)((withLifecycleActions({
  componentWillMount: [getOtherUserIfEmpty, getOtherProfileIfEmpty],
  componentDidMount: [listCollegesIfEmpty, getOtherContributionsIfEmpty]
}))(Users));