const LightenDarkenColor = (col, amt) => {
  var usePound = false;
  if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
  }
  var num = parseInt(col,16);

  var r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if  (r < 0) r = 0;

  var b = ((num >> 8) & 0x00FF) + amt;
  if (b > 255) b = 255;
  else if  (b < 0) b = 0;

  var g = (num & 0x0000FF) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
  
}

/**
 * Colors
 */
const ovBlue = '#005587';
const ovBlueLight = LightenDarkenColor("#005587", 25);
const ovGreyLight = '#E6E6E6';
const ovGrey = '#a8a8a8';
const ovGreyDark = '#c6c6c6';
const ovGreyDarker = '#707070';
const ovWhite = '#ffffff';
const ovTransparentBlue = 'RGBA(14, 75, 120, 0.50)';
const ovRed = '#DD6159';
const ovRedLight = '#d7473d';
const ovYellow = '#fcd068';

const ovBlack = '#000';



export default { 
  ovBlue,
  ovBlueLight,
  ovGreyLight,
  ovGreyDark,
  ovGreyDarker,
  ovGrey,
  ovWhite,
  ovTransparentBlue,
  ovRed,
  ovRedLight,
  ovBlack,
  ovYellow
};
