import styled from 'styled-components';
import { Search as SearchIcon } from 'styled-icons/material/Search';
import { color, typo, bp } from '../../../styles';

export const StyledInput = styled.input`
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 5px;
  background-color: #ffffff;

  ${({ value }) => value 
    ? `
      border: none;
      border-bottom: solid 0.5px #707070;
      border-radius: 0;
    `
    : `
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border: solid 0.5px #707070;
      border-radius: 5px;
    `}

  outline: none;
  font-size: 16px;

  &::placeholder{
    font-size: 18px;
    font-weight: 600;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #95989a;
  }
`;

export const StyledResultList = styled.ul`
  padding: 10px 5px;
  list-style: none;
  max-height: 300px;
  overflow-y: auto;

  > li {
    font-size: 18px;
    font-weight: 600;
    font-stretch: condensed;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${color.ovBlue};
    cursor: pointer;

    margin-bottom: 5px;
  }
`;


export const StyledSelectedList = styled.div`

  > span {
    display: inline-flex;
    // height: 28px;
    align-items: center;

    padding-left: 12px;
    margin-bottom: 8px;
    margin-right: 5px;
    border-radius: 11px;
    border: solid 1px ${color.ovBlue};
    background-color: ${color.ovBlue};

    font-size: 18px;
    font-weight: 600;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    
    > i {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-left: 1px solid #ffffff;
      height: 22px;
      width: 30px;
      margin-left: 12px
      :after {
        display: block;
        content: "x";

        font-size: 18px;
        font-weight: 400;
        font-style: normal;
        color: #ffffff;

      }
    }
  }
`
