import loadImage from 'blueimp-load-image';
import { CONTRIB_ITEM_REMOVE, MODAL_CLOSE_AND_CONTRIBUTION_RESET, NEW_CONTRIBUTION } from './contribute/actionsTypes';
import { LOGOUT_SUBMITTED } from './user';

export const FILES_DROP = 'upload/FILES_DROP'
export const DRAG_ENTER = 'upload/DRAG_ENTER'
export const DRAG_LEAVE = 'upload/DRAG_LEAVE'
export const CLEAN_DROP_STATE = 'upload/CLEAN_DROP_STATE'
export const DROPPED_FILE_REMOVE = 'upload/DROPPED_FILE_REMOVE'
export const UPLOAD_START = 'upload/UPLOAD_START'
export const MULTI_FILE_UPLOAD_FINISH = 'upload/MULTI_FILE_UPLOAD_FINISH'
export const PROGRESS_UPDATED = 'upload/PROGRESS_UPDATED'
export const NEXT_PATH = 'upload/NEXT_PATH'

const initialState = {
  accepted: [],
  isUploading: false,
  progress: 0,
  dropzoneActive: false
}

export const fileCountLimit = 15;

export default (state = initialState, action) => {
  switch (action.type) {

    case FILES_DROP:
      return {
        ...state,
        accepted: [...state.accepted, ...action.payload.accepted],
        dropzoneActive: false
      }

    case DRAG_ENTER:
      return {
        ...state,
        dropzoneActive: true
      }

    case DRAG_LEAVE:
      return {
        ...state,
        dropzoneActive: false
      }

    case DROPPED_FILE_REMOVE:
      return {
        ...state,
        accepted: [
          ...state.accepted.slice(0, action.payload),
          ...state.accepted.slice(action.payload + 1)
        ]
      }

    case CLEAN_DROP_STATE:
      return {
        ...state,
        dropzoneActive: false,
        accepted: [],
      }

    case UPLOAD_START:
      return {
        ...state,
        isUploading: true
      }

    case MULTI_FILE_UPLOAD_FINISH:
      return {
        ...state,
        accepted: [],
        isUploading: false
      }

    case PROGRESS_UPDATED:
      return {
        ...state,
        progress: action.payload
      }

    case MODAL_CLOSE_AND_CONTRIBUTION_RESET:
      return {
        ...state,
        accepted: [],
      }

    case NEW_CONTRIBUTION:
      return {
        ...state,
        isUploading: false
      }

    case NEXT_PATH:
      return {
        ...state,
        next: action.payload
      }

    case LOGOUT_SUBMITTED:
      return initialState;

    default:
      return state
  }
}

// Gets orientation of a file
const getOrientation = (file, callback) => {
  var reader = new FileReader();
  reader.onload = function(e) {

    var view = new DataView(e.target.result);
    if (view.getUint16(0, false) !== 0xFFD8)
    {
      return callback(-2);
    }
    var length = view.byteLength, offset = 2;
    while (offset < length)
    {
      if (view.getUint16(offset+2, false) <= 8) return callback(-1);
      var marker = view.getUint16(offset, false);
      offset += 2;
      if (marker === 0xFFE1)
      {
        if (view.getUint32(offset += 2, false) !== 0x45786966)
        {
          return callback(-1);
        }

        var little = view.getUint16(offset += 6, false) === 0x4949;
        offset += view.getUint32(offset + 4, little);
        var tags = view.getUint16(offset, little);
        offset += 2;
        for (var i = 0; i < tags; i++)
        {
          if (view.getUint16(offset + (i * 12), little) === 0x0112)
          {
            return callback(view.getUint16(offset + (i * 12) + 8, little));
          }
        }
      }
      else if ((marker & 0xFF00) !== 0xFF00)
      {
        break;
      }
      else
      {
        offset += view.getUint16(offset, false);
      }
    }
    return callback(-1);
  };
  reader.readAsArrayBuffer(file);
}

// Dispatches a single file drop
// todo: remove contributable_id & contributable_type from here to make it more explicit for submitAttractionContentContribution
const singleFileDrop = (file, collegeId) => ({
  type: FILES_DROP,
  payload:{
    accepted: [file],
    contributable_id: collegeId,
    contributable_type: collegeId && 'colleges'
  }
})

// Uses loadImage to correct orientation and creates a new file to dispatch
const singleFileOrientDrop = (file, collegeId /* optional */) => {
  return async (dispatch) => {
    await loadImage(
      file,
      (img) => {
        if(img.type === "error") {
          console.log("Error loading image " + file);
        } else {
          img.toBlob((blob) => {
            const preview = window.URL.createObjectURL(blob)
            const newFile = new File([blob], file.name, {
              type: file.type,
              lastModified: file.lastModified,
              size: file.size,
              webkitRelativePath: file.webkitRelativePath
            });

            newFile.preview = preview;

            return dispatch(singleFileDrop(newFile, collegeId))
          },file.type);
        }
      },
      {
        orientation: true
      }
    );
  }
}

// On Drop handler for react-dropzone
export const handleDrop = (accepted, rejected) => {
  return async (dispatch, getState) => {
    const { college, upload } = getState();

    // Make sure that we dont add more than the file limit
    let files = accepted.filter((file, i) => upload.accepted.length + i + 1 <= fileCountLimit );

    // Gets orientation of each file and dispaches the single file drop
    // with or without orientation correction
    try {
      files.forEach(async (file) => {
        await getOrientation(file, (o) => {
          return o === 1 ?
          dispatch(singleFileDrop(file, college.college.ID)) :
          dispatch(singleFileOrientDrop(file, college.college.ID))
        })
      })
    } catch (e) {
      console.log(e)
    }
  }
}

// On Drop handler for react-dropzone
export const handleDropPicture = (accepted, rejected) => {
  return async (dispatch, getState) => {
    const { upload } = getState();

    // Make sure that we dont add more than the file limit
    let files = accepted.filter((file, i) => upload.accepted.length + i + 1 <= fileCountLimit);

    // Gets orientation of each file and dispaches the single file drop
    // with or without orientation correction
    try {
      files.forEach(async (file) => {
        await getOrientation(file, (o) => {
          return o === 1 ?
            dispatch(singleFileDrop(file)) :
            dispatch(singleFileOrientDrop(file))
        })
      })
    } catch (e) {
      console.log(e)
    }
  }
}

export const handleDragEnter = () => ({
  type: DRAG_ENTER
})

export const handleDragLeave = () => ({
  type: DRAG_LEAVE
})

export const cleanDropState = () => ({
  type: CLEAN_DROP_STATE
});

/**
 * When a user removes a file from the accepted files list before uploading
 * @param  {[type]} index index of file to remove
 * @return {[type]}       [description]
 */
export const removeDroppedFile = (index) => {
  return (dispatch, getState) => {

    dispatch({
      type: CONTRIB_ITEM_REMOVE,
      payload: index
    });

    removePreviewLink(getState().upload.accepted[index]);

    return dispatch({
      type: DROPPED_FILE_REMOVE,
      payload: index
    });
  }
}

/**
 * Removes the image blob link to free up memory
 * @param  {[type]} file [description]
 * @return {[type]}      [description]
 */
export const removePreviewLink = (file) => {
  if (file) {
    window.URL.revokeObjectURL(file.preview);
  }
}

export const startUpload = () => ({
  type: UPLOAD_START
})

export const finishUpload = () => {
  return (dispatch, getState) => {

    const { upload } = getState();

    for(const file in upload.accepted) {
      removePreviewLink(file);
    }

    return dispatch({ type: MULTI_FILE_UPLOAD_FINISH });
  }
}

export const uploadProgress = (e) => ({
  type: PROGRESS_UPDATED,
  payload: e
})

export const setNextPath = (path) => ({
  type: NEXT_PATH,
  payload: path,
});

/**
 * Takes an array of files and uploads them
 * @param  {[]Files} data files to be uploaded
 * @return {[type]}      [description]
 */
// export const triggerUpload = (data) => {
//   return async (dispatch, getState) => {
//     dispatch(startUpload());
//
//     const { college } = getState();
//
//     try {
//       // data needs to be in array format
//       const res = await contentService().uploadFiles(data, college.college.ID, uploadProgress);
//       return dispatch(finishUpload(res.data.data));
//     } catch (e) {
//       console.error(e.stack);
//     } finally {
//       return;
//     }
//   }
// }
