import React, { useState } from 'react';
import { CoachHistoryContainer, HisotryItem } from './Styled';

// item = {
//   start,
//   end,
//   name,
//   title0,
//   title1,
// };

const CoachHistory = ({ history, isBottom }) => {
  const [isExpanded, setExpand] = useState(false);

  return (
    <CoachHistoryContainer isBottom={isBottom} isExpanded={isExpanded} >
      {isExpanded && !!history && history.map((item) => (
        <HisotryItem>
          <div>
            <label>Year:</label><span>{item.start} - {item.end ? item.end : 'Present'}</span>
          </div>
          <div>
            {item.name && (<><label>Team:</label><span>{item.name}</span></>)}
          </div>
          <div>
            <label>Job Title:</label><span>{`${item.title0}${(item.title1 && `, ${item.title1}`) || ''}`}</span>
          </div>
        </HisotryItem>
      ))}

      <a data-action onClick={(e) => e.stopPropagation() || setExpand(!isExpanded)}>{isExpanded ? 'Less' : 'Expand History'}</a>
    </CoachHistoryContainer>
  )
};

export default CoachHistory;