import React from 'react';
import PostAuthorPic from './AuthorPic';
import PostAuthorMeta from './AuthorMeta';
import PostAuthorBio from './AuthorBio';

// Styles
import {
  PostAuthorContainer,
  PostAuthorTitle,
  PostAuthorMetaContainer
} from './Styled';

const Author = ({
  firstName,
  lastName,
  profileImage,
  title,
  bio
}) => {
  return (
    <PostAuthorContainer>
      <PostAuthorTitle>About the author</PostAuthorTitle>
      <PostAuthorMetaContainer>
        <PostAuthorPic name={`${firstName} ${lastName}`} imgUrl={profileImage} />
        <PostAuthorMeta name={`${firstName} ${lastName}`} title={title} />
      </PostAuthorMetaContainer>
      <PostAuthorBio bio={bio} />
    </PostAuthorContainer>
  )
};

export default Author;