import styled from 'styled-components';
import { color, bp } from '../../styles'

export const CollegeHeaderLiteBox = styled.div`
  background-color: ${color.ovWhite};
  margin-top: -38px;
  min-height: 103px;
  border-bottom: solid 1px ${color.ovGreyDark};
  padding: 20px 20px 0 20px;
  @media ${bp.phoneOnly} {
    display: ${props => props.openMap ? "none" : "block"};
  }
`;

export const CollegeNav = styled.div`
  margin: 11px 0 6px 0;
`;