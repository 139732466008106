import React from 'react';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux'; 
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';

import RegisterForm from './form';
import { setUserData } from '../../modules/wizard';
import { push } from 'connected-react-router';
import Modal from '../../components/modal'
import CloseButton from '../../components/CloseButton'

import {
  GridContainer,
  RegisterHeading,
  ErrorText,
  CloseButtonContainer,
  StyledLogoHorizontal,
  TextP,
  SmallLink
} from './Styled'
import { goHomeWithResetLoginRedirect } from "../../modules/user";

const Register = ({setUserData, errorMessage, push, goBackWithResetLoginRedirect}) => {

  const onSubmit = compose(
    () => push('/signup-role'),
    setUserData,
    ({ 
      email,
      password,
      firstName,
      lastName,
      birthyear: { value: year },
      birthmonth: { value: month },
      birthdate: { value: day },
    }) => ({ email, password, firstName, lastName, birthday: `${year}-${month}-${day}`}),
  );

  return (
    <Modal>
      <Helmet>
        <title>Sign Up - OfficialVisit</title>
        <meta name="description" content="Create a free account and start exploring colleges! OfficialVisit is the leading college and college football review platform." />
      </Helmet>
    <CloseButtonContainer>
      <CloseButton onClick={ goBackWithResetLoginRedirect }/>
    </CloseButtonContainer>
      <GridContainer>
        <div>
          <StyledLogoHorizontal/>
          <RegisterHeading as="h1">Sign up  -  it’s free!</RegisterHeading>
          { errorMessage && <ErrorText>{errorMessage}</ErrorText> }
          <RegisterForm onSubmit={onSubmit} />
          <TextP>Already have an account? <SmallLink to="/login">Log in</SmallLink>.</TextP> 
        </div>
      </GridContainer>
    </Modal>
  )
};

Register.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  setUserData: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired
};

const mapStateToProps = ({ user, ...state }) => ({
  isFetching: user.isFetching,
  errorMessage: user.errorMessage,
});


const mapDispatchToProps = dispatch => bindActionCreators({
  setUserData,
  push,
  goBackWithResetLoginRedirect: goHomeWithResetLoginRedirect,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Register)
