import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {
  DownArrow,
  UpArrow,
  Comment,
  SmallReviewBox,
  ReviewLabel,
  SmallReviewList,
  SmallSizedOne,
  SmallSizedZero
} from './StyledSmallItem'

const getSmallRatingComponent = (rating, id) => {
  const wholeValue = parseInt(rating + "");
  let displayArr = [];
  for (var n = 0; n < wholeValue; n++) {
    displayArr[n] = <SmallSizedOne key={id + "-rating1-" + n }/>
  }
  for (var m = 0; m < 5 - wholeValue; m++) {
    displayArr.push(<SmallSizedZero key={id + "rating0"+ (wholeValue-m)}/>)
  }
  return displayArr.map(item => item);
}

class CoachesSmallReviewItem extends Component{
  constructor() {
    super();
    this.state = {
      showComment: false
    }
  }

  handleShowCommentClick() {
    this.setState({
      showComment: !this.state.showComment
    });
  }

  render(){
    const { 
      label,
      review
    } = this.props;

    return(
      <SmallReviewBox>
        <ReviewLabel>{label}</ReviewLabel>
        <SmallReviewList onClick={() => this.handleShowCommentClick()} >
          {getSmallRatingComponent(review.rating, review.ID)}
          { review.comment !== "" && !this.state.showComment&& <DownArrow /> }
          { review.comment !== "" && this.state.showComment && <UpArrow /> }
        </SmallReviewList>
        { review.comment !== "" && this.state.showComment && <Comment>{review.comment}</Comment>}
      </SmallReviewBox>
    )
  }
}

CoachesSmallReviewItem.propTypes = {
  review: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

export default CoachesSmallReviewItem