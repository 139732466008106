import React from 'react';

// Styles
import { 
	AuthorMetaName,
	AuthorMetaTitle
} from './Styled';

const PostAuthorMeta = ({ 
	title,
	name
}) =>{
  return (
    <div>
      <AuthorMetaName>{name}</AuthorMetaName>
      <AuthorMetaTitle>{title}</AuthorMetaTitle>
    </div>
  )
}

export default PostAuthorMeta;