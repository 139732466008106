import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';

export const attractionBase = ({ attraction }) => attraction;
export const routerBase = ({ router }) => router;

export const isFetchingSelector = createSelector(
  attractionBase,
  ({ isFetching }) => isFetching,
);

export const attractionListSelector = (collegeId) => createSelector(
  attractionBase,
  ({ attractions }) => {
    return attractions[collegeId];
  }
);

export const attractionSelectorByRouter = createSelector(
  attractionBase,
  (attraction) => {
    if (attraction.attraction) {
      const { ID, title, about, tags, location: { address: { address_line_1, address_line_2, city, state, country, postal_code} }} = attraction.attraction
      return {ID, title, about, tags, address: `${address_line_1}, ${address_line_2 && address_line_2 +','} ${city}, ${state} ${postal_code}, ${country}`};
    }
  }
);

export const attractionTagsSelector = createSelector(
  attractionBase,
  ({ tags }) => {
    if (!tags) return;

    const tree = sortBy(tags.reduce((memo, tag) => {
      if (tag.parent_id) {
        const parent = tags.find(parent => parent.ID === tag.parent_id);
        parent.subtags = uniqBy([
          ...(parent.subtags || []),
          tag
        ], 'ID');
      } else {
        memo.push(tag);
      }

      return memo;
    }, []), 'order');

    return tree;
  }
);

export const attractionReviewSelector = createSelector(
  attractionBase,
  ({ reviews }) => reviews,
);

export const attractionSelector = createSelector(
  attractionBase,
  ({ attraction }) => attraction,
);

export const attractionTargetSelector = (contribution, targetAttractions) => {
  const { contributable_type: type, contributable_id: id} = contribution;
  if (type === "attractions" && targetAttractions && targetAttractions[id]) {
    return targetAttractions[id];
  };
};

export const attractionNameSelector = (contribution, targetAttractions) => {
  const attraction = attractionTargetSelector(contribution, targetAttractions);
  if (attraction) {
    return attraction.title;
  };
};

export const attractionReferSelector = (contribution, targetAttractions, colleges) => {
  const attraction = attractionTargetSelector(contribution, targetAttractions);
  if (colleges.length) {
    const [college] = colleges.filter(college => college.ID === attraction.college_refer);
    return college && college.url_slug ? college.url_slug : "air-force";
  };
};

export const attractionSlugSelector = (contribution, targetAttractions) => {
  const attraction = attractionTargetSelector(contribution, targetAttractions);
  if (attraction) {
    return attraction.ID;
  };
};
