import React, { useState } from 'react';
import AverageReview from '../../reviews/AverageReview';
import CoachHistory from './CoachHistory';
import { CoachCardWrapper, CoachCardContainer, ImageWrapper, DataWrapper, UserIcon, ReviewsWrapper } from './Styled';
import { averageVerifiedReviewRatingSelector, totalReviewCountSelector } from '../../../modules/coach/selectors';
import { getCoachinHistory } from '../../../modules/coach';

// const COACH_HISTORY = [
//   {
//     year: 2018,
//     name: 'Great team',
//     title_0: 'Quarterback Cool',
//   },
//   {
//     year: 2019,
//     name: 'Great team',
//     title_0: 'Quarterback Cool',
//   }
// ];

const CURRENT_YEAR = new Date().getFullYear();
const CoachCard = ({ coach, push }) => {
  const coachPic = coach.default_photo && coach.default_photo.src_th;
  const totalReviews = totalReviewCountSelector(coach);
  const averageReview = averageVerifiedReviewRatingSelector(coach);
  const history = coach.coached_seasons && getCoachinHistory(coach.coached_seasons);
  const position = history && history[0];
  return (
    <CoachCardWrapper onClick={() => push('/coach/' + coach.url_slug)}>
      <CoachCardContainer>
        <ImageWrapper>
          {coachPic
            ? <img src={coachPic} />
            : <UserIcon />
          }
        </ImageWrapper>

        <DataWrapper>
          <h3>{coach.display_name}</h3>
          {position && <h4>{`${position.end !== CURRENT_YEAR ? 'Former' : ''} ${position.title0}${(position.title1 && `, ${position.title1}`) || ''}`} · {position.name}</h4>}

          <CoachHistory history={history} />

          <ReviewsWrapper>
            <AverageReview averageReview={averageReview} count={totalReviews} /> <span>{!!totalReviews && `${totalReviews} reviews`} </span>
          </ReviewsWrapper>
        </DataWrapper>
      </CoachCardContainer>
      <CoachHistory history={history} isBottom/>
    </CoachCardWrapper>
  )
};

export default CoachCard;