export const acceptanceRateGroupLabelMap = {
  acceptance_rate: "Acceptance Rate"
};

export const deadlineGroupLabelMap = {
  early_action_deadline: "Early Action",
  application_deadline: "Regular Decision"
};

export const appGroupLabelMap = {
  common_app_accept: "Accepts Common App",
  coalition_app_accept: "Accepts Coalition App"
};

export const considereationsGroupLabelMap = {
  hs_gpa: "High school GPA",
  hs_rank: "High school rank",
  hs_transcript: "High school transcript",
  college_prep_courses: "College prep courses",
  sat_act_submission: "SAT/ACT",
  recommendations: "Recommendations"
};

export const earlyDeadlineGroupLabelMap = {
  early_action_deadline: "Early action deadline",
  early_decision_deadline: "Early decision deadline"
};

export default acceptanceRateGroupLabelMap;