import React, {useEffect, useState, useRef} from 'react';
import CollegeLogo from '../../CollegeLogo';
import CollegeBrand from '../../CollegeBrand';

import {
  CollegeAboutBox,
  CollegeAboutLogo,
  CollegeAboutTitle,
  CollegeAboutText,
  CollegeAboutReadMore,
  ImgStyle
} from './Styled';

const CollegeAbout = ({
  title,
  team,
  college,
  content
}) => {

  const [show, setShow] = useState(false);
	const [height, setHeight] = useState(0);
	const StatsContainerHeight = useRef(null);

	useEffect(() => {
    setHeight(StatsContainerHeight.current && StatsContainerHeight.current.clientHeight);
  })

  return(
    <CollegeAboutBox>
      <CollegeAboutLogo>
        {team  &&
          <CollegeLogo
            style={ImgStyle}
            name={team}
          />
        }
        {college  &&
          <CollegeBrand
            style={ImgStyle}
            name={college}
          />
        }
      </CollegeAboutLogo>
      <div>
        <CollegeAboutTitle>{title}</CollegeAboutTitle>
        <CollegeAboutText data-show={show}>
          <div ref={StatsContainerHeight}>
            {content}
          </div>
        </CollegeAboutText>
        { height > 60 &&
          <CollegeAboutReadMore onClick={() => setShow(!show)}>
            {!show ? `Read more...` : `Read less`}
          </CollegeAboutReadMore>
        } 
      </div>
    </CollegeAboutBox>
  )
};

export default CollegeAbout;