// eslint-disable-next-line
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { cleanDropState, handleDropPicture, handleDragEnter, handleDragLeave } from '../../modules/upload';
import { submitUserAvatar } from '../../modules/user';

import RegisterPictureContainer from './RegisterPictureContainer';

const pictureSelector = (state) => state.accepted && state.accepted[0] && state.accepted[0].preview;
const nextPathSelector = (upload, role) => upload.next || `signup-${role}`;

const mapStateToProps = ({ upload, user }) => ({
  picture: pictureSelector(upload),
  dropzoneActive: upload.dropzoneActive,
  isUploading: upload.isUploading,
  nextPath: nextPathSelector(upload, user.user.role),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  cleanDropState,
  handleDropPicture,
  handleDragEnter,
  handleDragLeave,
  // removeDroppedFile,

  submitUserAvatar,
  push,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPictureContainer)