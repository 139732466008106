import styled from 'styled-components';
import { color } from '../../../styles';

export const CollegeCardBox = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(${color.ovBlack}, 0.16);
  background-color: ${color.ovWhite};
  overflow: hidden;
  margin-bottom: 17px;
  grid-column: 12 span;
`;

export const CollegeCardBarContent = styled.div`
  padding: 18px 14px;
`;
