import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SearchNavbar from '../searchNavbar'
import { submitLogout } from '../../modules/user';

import {
  Navbar,
  LogoLink,
  MenuIcon,
  NavGroup,
  NavLink,
  NavLinkButton,
  NavLinkA,
  FalseInputContainer,
  FalseInput,
  StyledSearchIcon,
  StyledCloseIcon,
  SearchNavbarFormContainer
} from './Styled';

class NavBar extends Component{
  constructor() {
    super();
    this.state = {
      toggledOn: false
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.falseInputRef = React.createRef();
  }

  handleScroll() {
    this.setState({
      scroll: window.scrollY
    });
  }

  componentDidMount() {
    const el = document.querySelector('nav');
    this.setState({
      top: el.offsetTop, 
      height: el.offsetHeight}
    );
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate() {
    if (this.state.formClicked && this.props.location.pathname === "/search") {
      this.setState({
        formClicked: false
      });
    }
  }

  handleMenuClick() {
    this.setState({
      toggledOn: !this.state.toggledOn
    });
  }

  handleNavLinkClick() {
    this.setState({
      toggledOn: false
    });
  }

  handleFormFocus() {
    this.setState({
      toggledOn: false,
      formClicked: true,
    });
  }

  handleFormClose() {
    this.setState({
      formClicked: false
    });
  }

  navStyles() {
    return this.state.toggledOn ? { display: 'inline-block !important' } : {};
  }

  render(){;
    const { submitLogout, isAuthenticated, user } = this.props;
    const { formClicked } = this.state;
    const pathName = this.props.location.pathname === '/' ? 'home' : 'landing';
    const isSearchPage = this.props.location.pathname === '/search' && 'searchPage';
    const fixedNav = this.state.scroll > '200' ? "fixed-nav" : "unfixed-nav";
    const formOpen = formClicked ? "form-open" : 'form-closed';
    return(
      <Navbar className={`${pathName} ${fixedNav} ${formOpen} ${isSearchPage}`}>
        <LogoLink />
        <MenuIcon onClick={() => this.handleMenuClick()} />
        {/* Search box */}
        <SearchNavbarFormContainer>
          {/* Close Search box */}
          <StyledCloseIcon onClick={() => this.handleFormClose()}/>
          <SearchNavbar focused={formClicked}/>
        </SearchNavbarFormContainer>
        <NavGroup show={this.state.toggledOn}>
          {/* False input to open Search box */}
          {!isSearchPage && 
            <FalseInputContainer>
              <FalseInput onClick={() => this.handleFormFocus()} placeholder="Search" ref={this.falseInputRef}/>
              <StyledSearchIcon/>
            </FalseInputContainer>
          }
          { !isAuthenticated &&
            <NavLinkButton to="/signup" onClick={() => this.handleNavLinkClick()} >Sign up</NavLinkButton>
          }
          { isAuthenticated ? 
            <NavLinkA onClick={() => { this.handleNavLinkClick(); submitLogout(); }} >Logout</NavLinkA> :
            <NavLink to="/login" onClick={() => this.handleNavLinkClick()} >Login</NavLink>
          }
          { isAuthenticated && 
            <NavLink to="/profile" onClick={() => this.handleNavLinkClick()} >Profile</NavLink>
          }
          <NavLink to="/coaches" onClick={() => this.handleNavLinkClick()} >Coaches</NavLink>
          <NavLink to="/colleges" onClick={() => this.handleNavLinkClick()} >Colleges</NavLink>
          { (isAuthenticated && user.role === 'recruit') && 
            <NavLink to="/top-schools" onClick={() => this.handleNavLinkClick()} >
              My Top Schools
            </NavLink>
          }
        </NavGroup>    
      </Navbar>
    )
  }
}

const mapStateToProps = ({ user }) => ({
  isFetching: user.isFetching,
  isAuthenticated: user.isAuthenticated,
  user: user.user
})

const mapDispatchToProps = dispatch => bindActionCreators({
  submitLogout
}, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar))