import styled from 'styled-components';
import { color, bp, typo } from '../../../styles';

export const FilterBox = styled.div`
  font-size: 18px;
  color: ${color.ovBlack};
`;

export const checkmarkStyle = {
  width: '15px',  
  height: '15px'
}

export const OptionsBox = styled.div`
  overflow: hidden;
  &[data-show=true]{
    transition: all .1s;
    height: auto;
    min-height: 30px;
  }
  &[data-show=false]{
    height: 0px;
    min-height: 0px;
    transition: all .1s;
  }
`;

export const Option = styled.div`
  font-size: 15px;
  color: ${color.ovGrey};
  display: grid;
`;

export const OptionCheckbox = styled(Option)`
  grid-template-columns: auto 16px;
`;

export const CheckboxContainer = styled.input`
  margin: 0 .7rem 0 0 !important;
  border: solid 1px black;
  border-radius: none;
  cursor: pointer;
  @media ${bp.phoneOnly} {
    margin: 0 !important;
  }
`;

export const FilterHeaderBox = styled.div`
  display: grid;
  grid-template-columns: auto 30px;
`;

export const FilterTitle = styled.div`
  font-size: 18px;
  color: ${props => props.show ? color.ovBlack : color.ovGrey};
  cursor: pointer;
  &:hover{
    opacity: .5;
  }
`;

export const FilterClose = styled.div`
  display: ${props => props.show ? 'block' : 'none'};
  cursor: pointer;
  &:hover{
    opacity: .5;
  }
`;

export const FilterTitlePlus = styled.span`
  overflow: hidden;
  display: inline-block;
  height: 19px;
  &[data-show=false]{
    width: 24px;
    padding-right: 16px;
    transition: all .1s;
  }
  &[data-show=true]{
    width: 0;
    padding: 0;
    transition: all .1s;
  }
`;

export const OptionRangeBox = styled(Option)`
`;

export const RangeValues = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
`;

export const RangeSliderBox = styled.div`
  padding: 20px 23px 9px 13px;

  .input-range__track--active{
    background: ${color.ovBlue};
    border-color: ${color.ovBlue};
  }

  .input-range__label-container{
    left: auto;
    top: -8px;
    transform: translateX(-50%);
    right: calc(50% - 3px);
    &:after{
      content: '${props => props.subfixUnit}';
    }
    &:before{
      content: '${props => props.prefixUnit}';
    }
  }

  .input-range__label{
    color: ${color.ovGrey};
    font-family: ${typo.ovFontFeature};
    font-weight: 300;
    font-stretch: condensed;
    font-style: normal;
    letter-spacing: normal;
    font-size: 12px;
  }

  .input-range__label--max, .input-range__label--min{
    display: none;
  }

  .input-range__slider {
    width: 18px;
    height: 18px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 0.5px #707070;
    background-color: ${color.ovWhite};
    margin-top: -10px;
    &:active{
      transform: scale(1);
      background-color: ${color.ovGreyLight};
    }
  }

  .input-range__track,
  .input-range__slider-container {
    transition: none;
  }
`;