import styled from 'styled-components';
import { color, bp, typo } from '../../styles';

export const CardContainer = styled.div`
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0, 0.16);
    display: grid;
    grid-template-rows: 91px auto;
    height: 150px;
    cursor: pointer;
    transition: all .1s;
    margin-bottom: 2rem;
    &:hover{
        transition: all .1s;
        transform: scale(1.04);
    }
`;
export const ItemPhoto = styled.div`
    width: 100%;
    position: relative;
    background: ${props => `url("${props.bgUrl}") no-repeat center`};
    background-size: cover;
`;
export const PhotoGradient = styled.div`
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0.1), #ffffff);
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-top: solid 1px ${color.ovGreyDarker};
    border-left: solid 1px ${color.ovGreyDarker};
    border-right: solid 1px ${color.ovGreyDarker};
    @media ${bp.phoneOnly} {
        border: none;
    }
`;
export const ItemLogo = styled.div`
    width: 45px;
    height: 45px;
    position: absolute;
    left: 7px;
    bottom: 10px;
    z-index: 2;
    display: flex;
    img{
        max-width: 45px;
        max-height: 45px;
    }
`;
export const ItemInfo = styled.div`
    width: 100%;
    background: white;
    border: solid 1px ${color.ovGreyDarker};
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 4px 7px;
    @media ${bp.phoneOnly} {
        border: none;
    }
`;
export const ItemName = styled.div`
    width: 100%;
    font-family: ${typo.ovFontFeature};
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.08;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
export const ItemRating = styled.div`
    width: 100%;
    padding-top: 2px;
`;