import styled from 'styled-components';
import { Heading, Button } from 'rebass';
import { Link } from 'react-scroll';

import MidTitle from './MidTitle';
import BigTitle from './bigTitle'
import { color, bp, typo } from '../styles'

export const FullWithContent = styled.div`
  background-color: ${color.ovWhite};
  margin-top: -38px;
  min-height: 158px;
  border-bottom: solid 1px ${color.ovGreyDark};
  padding: 20px 20px 0 20px;
`;

export const HalfPageContent = styled.div`
  grid-column: span 12;
  @media ${bp.tabletLandUp} {
    grid-column: span 8;
  }
`;

export const PageContent = styled.div`
  grid-column: span 12;
  background-color: ${color.ovWhite};
  border: 1px solid ${color.ovGrey};
  padding: 25px;
  margin: 10px 0;
`;

export const StyledPageContent = styled(PageContent)`
  cursor: pointer;
`;

export const OVButtonRight = styled(Button)`
  border: 1px solid ${color.ovGreyDark};
  font-family: ${typo.ovFontFeature};
  font-size: 16px;
  background: ${color.ovYellow};
  border-radius: 4px;
  color: ${color.ovWhite};
  cursor: pointer;
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  box-shadow: 0 3px 6px 0 rgba(5, 5, 5, 0.16);
  padding: 5px 35px;
  margin: 10px 0 0 0;
  transition: all .1s;
  &:hover, &:focus {
    text-decoration: none;
    transition: all .1s;
    background: ${color.ovBlueLight};
    border-color: ${color.ovBlueLight};
  }
  ${StyledPageContent}:hover &{
    text-decoration: none;
    transition: all .1s;
    background: ${color.ovBlueLight};
    border-color: ${color.ovBlueLight};
  }
`;

export const CollegeBadge = styled.div`
  margin-bottom: 0px;
  position: relative;
`;

export const BigTitleGrey = styled(BigTitle)`
  color: ${color.ovGreyDarker};
  margin-bottom: 5px;
  line-height: 1;
`;

export const DetailsSubHeading = styled(Heading)`
  font-family: ${typo.ovFontFeature};
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovGreyDark};
`;

export const BarDivider = styled.span`
  display: inline-block;
  padding: 0 18px;
`;

export const CollegeNav = styled.div`
  margin: 20px 0 6px 0;
`;

export const MidTitleButton = styled(MidTitle)`
  display: inline;
  &:last-child a{
    border-right: none
  }
`;

export const MidTitleButtonLink = styled.a`
  border-right: 1px solid ${color.ovBlue};
  padding: 0 15px;
  cursor: pointer;
  &.selected{
    border-bottom: 2px solid ${color.ovBlue};
  }
  &:hover{
    text-decoration: none;
    border-bottom: 2px solid ${color.ovBlue};
  }
`;

export const MidTitleButtonLinkWithScrollTo = styled(Link)`
  border-right: 1px solid ${color.ovBlue};
  padding: 0 15px;
  cursor: pointer;
  &.selected{
  border-bottom: 2px solid ${color.ovBlue};
  }
  &:hover{
    text-decoration: none;
    border-bottom: 2px solid ${color.ovBlue};
  }
`;

export const TitleContainer = styled.div`
  grid-column: span 12;
`;

export const AboutHeading = styled(Heading)`
  color: ${color.ovBlue};
  font-family: ${typo.ovFontFeature};
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: .8;
  letter-spacing: normal;
  text-align: left;
  padding: 0 10px;
  @media ${bp.phoneOnly} {
    padding: 0 0px;
  }
`;

export const AboutHr = styled.div`
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid ${color.ovGrey};
  margin: 5px 0 0;
  padding: 0;
`;

export const LandingContainer = styled.div`
  padding-top: 1rem;
`;