import styled from 'styled-components';
import { color, bp } from '../../styles'
import { Button } from 'rebass';

export const ButtonContainer = styled.div`
	display: flex;
  margin-bottom: 1rem;
	@media ${bp.phoneOnly} {
		display: none;
	}
`;

export const TabButton = styled(Button)` 
  height: 28px;
  flex-grow: 1;

  border-style: solid;
  border-width: 1px 0 1px 1px;
  border-color: ${color.ovGrey};

  &:last-child {
    border-width: 1px;
  }

  background-color: ${color.ovWhite};
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  font-family: Molde;
  line-height: 0.6;
  letter-spacing: normal;
  text-align: center;
  color: ${color.ovBlack};
  cursor: pointer;
  border-radius: 0px;

  &:hover{
    background-color: ${color.ovBlack};
    color: ${color.ovWhite};
  }

  box-shadow: ${props => props.selected && `inset 2px 2px 0 ${color.ovGrey}, inset -2px -2px 0 ${color.ovGrey}`}
`;