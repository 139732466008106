import { times } from 'lodash-es';
import React from 'react';
import { PaginationWrapper } from "./Styled";

const PAGE_SIZE = 20;
const CHUNK_SIZE = 3;

const getChunkPages = (current) => times(CHUNK_SIZE, (i) => i + current * CHUNK_SIZE);

const Pagination = ({ count, current, onChange }) => {
  const currentIndex = current - 1;
  const pageCount = Math.ceil(count / PAGE_SIZE);
  const chunks = Math.ceil(pageCount / 3);
  const currentChunk = Math.floor(currentIndex / 3);
  const chunkPages = getChunkPages(currentChunk);
  const prevPage = current > 1 && current - 1;
  const nextPage = current < pageCount && current + 1;

  return (
    <>
      {pageCount > 1 && (
        <PaginationWrapper>
          {prevPage
            ? <a data-prev onClick={() => onChange(prevPage)}>PREV</a>
            : <a data-prev />
          }

          {chunkPages.map((p) => p < pageCount && <a data-active={p === currentIndex} onClick={() => onChange(p + 1)}>{p + 1}</a>)}

          {currentChunk < chunks - 1 && (
            <><span>···</span><a onClick={() => onChange(pageCount)}>{pageCount}</a></>
          )}
          
          {pageCount > 3 && (
            <>
              {nextPage 
                ? <a data-next onClick={() => onChange(nextPage)}>NEXT</a>
                : <a data-next />
              }
            </>
          )}
        </PaginationWrapper>
      )}
    </>
  )
};

export default Pagination;