import { createSelector } from 'reselect';

export const blog = ({ blog }) => blog;

export const postSelector = (post) => {
  if ( !post ) return null;

  const {
    created,
    published,
    slug,
    featured_image: featuredImage,
    author,
    tags,
    categories,
    title,
    body,
    summary,
  } = post;
  
  const {
    first_name: firstName,
    last_name: lastName,
    email,
    bio,
    title: authorTitle,
    profile_image: profileImage,
  } =  author;

  return {
    created,
    published,
    slug,
    featuredImage,
    author: {
      firstName,
      lastName,
      email,
      bio,
      title: authorTitle,
      profileImage,
    },
    tags,
    categories,
    title,
    body,
    summary,
  }
};

export const isFetchingSelector = createSelector(
  blog,
  ({ isFetching }) => isFetching,
);

export const currentPostSelector = createSelector(
  blog,
  ({ post }) => postSelector(post),
);

export const postListSelector = createSelector(
  blog,
  ({ posts }) => posts && posts.map(postSelector)
);

export const categoriesSelector = createSelector(
  blog,
  ({ categories }) => categories,
);

export const categorySelector = createSelector(
  blog,
  ({ category }) => category || '',
);
