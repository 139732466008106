import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'react-image'
import Spinner from 'react-spinkit';
import { Camera } from 'styled-icons/fa-solid/Camera';
import { color, bp, typo } from '../../styles'


const PaneContainer = styled.div`
  display: grid;
  margin-bottom: 22px;
  grid-template-columns: 60% 20% 20%;
  max-height: 258px;
  grid-template-rows: 100%;
  overflow: hidden;
  
  @media ${bp.phoneOnly} {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    max-height: none;
    margin-bottom: -22px;
  }
`;

const RightPaneSection = styled.div`
  width: 100%;
  height: 50%;
  grid-column: span 1;
  grid-auto-rows: 1fr;

`;


const ImgStyle = {
  objectFit: 'cover',
  height: '100%',
  width: '100%',
  imageOrientation: 'from-image'
}

const ImageContainerLarge = styled.div`
  width: 100%;
  height: 100%;
  vertical-align : middle;
  text-align: center;
  overflow: hidden;
  cursor: pointer;

  border-top: solid 2px ${color.ovWhite};
  border-left: solid 2px ${color.ovWhite};
  border-bottom: solid 2px ${color.ovWhite};

  @media ${bp.phoneOnly} {
    width: auto;
    height: auto;
    border-bottom: solid 2px ${color.ovWhite};
    border-right: solid 2px ${color.ovWhite};
  }
`;

const ImageContainerSmall = styled.div`
  width: 100%;
  height: 100%;
  vertical-align : middle;
  overflow: hidden;
  cursor: pointer;

  border-left: solid 2px ${color.ovWhite};
  border-right: solid 0px ${color.ovWhite};
`;

const ImageContainerSmallTop = styled(ImageContainerSmall)`
  border-top: solid 2px ${color.ovWhite};
  border-bottom: solid 2px ${color.ovWhite};
`;

const ImageContainerSmallMiddle = styled(ImageContainerSmall)`
  border-top: solid 1px ${color.ovWhite};
  border-bottom: solid 1px ${color.ovWhite};
`;

const ImageContainerSmallBottom = styled(ImageContainerSmall)`
  border-top: solid 1px ${color.ovWhite};
  border-bottom: solid 2px ${color.ovWhite};
`;

const StyledCamera = styled(Camera)`
  width: 20px;
  color: ${color.ovWhite};
  margin-right: 8px;
  position: relative;
  top: -2px;
`;

const ImageLink = styled.a`
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  font-family: ${typo.ovFontFeature};
  font-size: 23px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.17;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovWhite};
  color: ${color.ovWhite};
  &:hover {
    color: ${color.ovWhite};
    text-decoration: none;
  }
`;

const ImageLargeLink = styled(ImageLink)`
  /*z-index: 1;*/
  top: -48px;
  left: 18px;
  margin-top: -25px 0;
`;

const ImageSmallLink = styled(ImageLink)`
  /*z-index: 1;*/
  top: -28px;
  left: 8px;
  /*Weird fix for taking care of adding vertical size to image container*/
  margin-top: -20px;
  /*Hide overflow issue on small tablets*/
  display: none;
  @media ${bp.tabletLandUp} {
    /*Weird fix for taking care of adding vertical size to image container*/
    display: inherit;
  }
`;

const ImageBox = styled.div`
  @media ${bp.phoneOnly} {
    display: none;
  }
`;

const ImagePane = ({
  contentLarge = {},
  contentSmall0 = {},
  contentSmall1 = {},
  contentSmall2 = {},
  contentLargePhotoOnClick,
  contentLargeLinkOnClick,
  contentSmall0PhotoOnClick,
  contentSmall0LinkOnClick,
  contentSmall1PhotoOnClick,
  contentSmall1LinkOnClick,
  contentSmall2PhotoOnClick,
  contentSmall2LinkOnClick,
  contentLargeLinkText,
  contentSmall0LinkText,
  contentSmall1LinkText,
  contentSmall2LinkText, }) =>{

  return (
    <PaneContainer>
      <div>
        <ImageContainerLarge onClick={contentLargePhotoOnClick}>
          <Img
            key={'img-l'}
            style={ImgStyle}
            src={[contentLarge.src_lg,'https://i.imgur.com/JA1O93Z.png']}
            alt={contentLarge.ID}
            loader={<Spinner fadeIn="none" name="cube-grid" />}
          />
        </ImageContainerLarge>
        { contentLargeLinkText && <ImageLargeLink onClick={contentLargeLinkOnClick} ><StyledCamera />{contentLargeLinkText}</ImageLargeLink> }
      </div>
      <ImageBox>
        <ImageContainerSmallTop onClick={contentSmall0PhotoOnClick}>
          <Img
            key={'img-sm0'}
            style={ImgStyle}
            src={[contentSmall0.src_md,'https://i.imgur.com/JA1O93Z.png']}
            alt={contentSmall0.ID}
            loader={<Spinner fadeIn="none" name="cube-grid" />}
          />
        </ImageContainerSmallTop>
        { contentSmall0LinkText && <ImageSmallLink onClick={contentSmall0LinkOnClick} ><StyledCamera />{contentSmall0LinkText}</ImageSmallLink> }
      </ImageBox>
      <ImageBox>
        <RightPaneSection>
          <ImageContainerSmallMiddle onClick={contentSmall1PhotoOnClick}>
            <Img
              key={'img-sm1'}
              style={ImgStyle}
              src={[contentSmall1.src_md,'https://i.imgur.com/JA1O93Z.png']}
              alt={contentSmall1.ID}
              loader={<Spinner fadeIn="none" name="cube-grid" />}
            />
          </ImageContainerSmallMiddle>
          { contentSmall1LinkText && <ImageSmallLink onClick={contentSmall1LinkOnClick} ><StyledCamera />{contentSmall1LinkText}</ImageSmallLink> }
        </RightPaneSection>
        <RightPaneSection>
          <ImageContainerSmallBottom onClick={contentSmall2PhotoOnClick}>
            <Img
              key={'img-sm2'}
              style={ImgStyle}
              src={[contentSmall2.src_md,'https://i.imgur.com/JA1O93Z.png']}
              alt={contentSmall2.ID}
              loader={<Spinner fadeIn="none" name="cube-grid" />}
            />
          </ImageContainerSmallBottom>
          { contentSmall2LinkText && <ImageSmallLink onClick={contentSmall2LinkOnClick} ><StyledCamera />{contentSmall2LinkText}</ImageSmallLink> }
        </RightPaneSection>
      </ImageBox>
    </PaneContainer>
  )
}

ImagePane.propTypes = {
  contentLarge: PropTypes.object.isRequired,
  contentSmall0: PropTypes.object.isRequired,
  contentSmall1: PropTypes.object.isRequired,
  contentSmall2: PropTypes.object.isRequired,
  contentLargePhotoOnClick: PropTypes.func.isRequired,
  contentLargeLinkOnClick: PropTypes.func.isRequired,
  contentSmall0PhotoOnClick: PropTypes.func.isRequired,
  contentSmall0LinkOnClick: PropTypes.func.isRequired,
  contentSmall1PhotoOnClick: PropTypes.func.isRequired,
  contentSmall1LinkOnClick: PropTypes.func.isRequired,
  contentSmall2PhotoOnClick: PropTypes.func.isRequired,
  contentSmall2LinkOnClick: PropTypes.func.isRequired,
  contentLargeLinkText: PropTypes.string,
  contentSmall0LinkText: PropTypes.string,
  contentSmall1LinkText: PropTypes.string,
  contentSmall2LinkText: PropTypes.string,
};

export default ImagePane
