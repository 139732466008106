import React, { useRef, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { color, typo, bp } from '../../styles'
import BigYellowButton from '../../components/bigYellowButton'

const InputContainer = styled.div`
  margin-bottom: -22px;
  float: left;
  width: 60%;
  @media ${bp.tabletPortUp} {
    width: 75%;
  }
  @media ${bp.desktopUp} {
    width: 80%;
  }
`;

const Button = styled.span`
  width: 35%;
  @media ${bp.tabletPortUp} {
    width: 20%;
  }
  @media ${bp.desktopUp} {
    width: 15%;
  }
  float: right;
`;

let StyledInput = styled.input`
  display: inline-block;
  width: 100%;
  height: 76px;
  border: solid 2px ${color.ovGrey};
  padding: 22px 31px;
  background-color: ${color.ovWhite};
  font-family: ${typo.ovFontFeature};
  font-size: 25px;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: ${color.ovGrey};
  border-radius: 10px;
  outline: none;
  &::placeholder{
    font-family: ${typo.ovFontFeature};
    font-size: 25px;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    color: ${color.ovGrey};
  }
  &:active, &:focus, &.filled{
    border-radius: 10px;
    border: solid 2px ${color.ovBlue};
  }
  &.filled{
    border-radius: 10px 10px 0 0;
  }
`;

const inputField = ({ input, label, type, focused, meta: { dirty, touched, active, error } }) => {
  const inputEl = useRef(null);
  useEffect(() => {
    const { current } = inputEl;
    if (current && current.focus ) current.focus();
  }, [focused]);

  return (
    <InputContainer >
      <StyledInput innerRef={x => inputEl.current = x} {...input} placeholder={label} type={type} className={active && 'active'} autoComplete="off"/>
      {touched && !active && error && <div>{error}</div>}
    </InputContainer>
  );
};

let CollegeSearchNavBarFilterForm = ({
  focused,
  handleSubmit
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={12}>
          <Field
            type="text"
            name="text"
            component={inputField}
            focused={focused}
            label="Search" 
          />
          <Button type="submit"><BigYellowButton bigYellowButtonLabel="Search"/></Button>
        </Col>
      </Row>
    </form>
  );
};

CollegeSearchNavBarFilterForm = reduxForm({
  // a unique name for the form
  form: 'collegeSearchNavbarFilter'
})(CollegeSearchNavBarFilterForm)

export default CollegeSearchNavBarFilterForm;
