import React, { useState, useCallback } from "react";
import { StyledResultList } from "./Styled";

const Item = () => {

}

const ListResult = ({ items, selected, onSelect }) => {
  const hanldeAdd = useCallback((item) => {
    if (selected && !selected.find(({ value }) => value === item.value)) {
      onSelect([...selected, item]);
    }
  }, [selected, onSelect]);

  return (
    <StyledResultList>
      {items && items.map((item) => (
        <li onClick={() => hanldeAdd(item)}>{item.label}</li>
      ))}
    </StyledResultList>
  )
};

export default ListResult;
